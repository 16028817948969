(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('EmailAprovacao', EmailAprovacao);

    EmailAprovacao.$inject = ['$resource', 'DateUtils'];

    function EmailAprovacao($resource, DateUtils) {
        var resourceUrl = 'api/email-aprovacao/:param';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false },
        });
    }
})();