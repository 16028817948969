(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('NewInscricaoQrController', NewInscricaoQrController);

    NewInscricaoQrController.$inject = ['$timeout', 'Auth', '$http', '$state', '$sessionStorage', 'LoginService', '$rootScope', 'CidadesMDJsonService',
        'CursoTradicionalGeral', 'LinkVendedorService', 'ValidaInscricaoTrad', 'ConfirmacaoTradicionalService', 'ConsultaAlunoPlay2018', 'Inscricao', 'InscricaoColegio', 'InscricaoPulseira'
    ];

    function NewInscricaoQrController($timeout, Auth, $http, $state, $sessionStorage, LoginService, $rootScope, CidadesMDJsonService,
        CursoTradicionalGeral, LinkVendedorService, ValidaInscricaoTrad, ConfirmacaoTradicionalService, ConsultaAlunoPlay2018, Inscricao, InscricaoColegio, InscricaoPulseira) {
        var vm = this;

        //$state.go('home');

        //ConfirmacaoTradicionalService.open();
        //vm.isSaving = true;
        vm.erroForm = true;
        vm.user = null;
        vm.inscricao = {};
        vm.troca = troca;
        vm.alterInfo = alterInfo;
        vm.alteraEtapa = alteraEtapa;
        vm.showMessage = showMessage;
        vm.fecharAlert = fecharAlert;
        vm.validaInscricao = validaInscricao;
        vm.redirect = redirect;
        vm.login = login;
        vm.recuperarSenha = recuperarSenha;

        vm.etapa = 1;
        vm.estadosSigla = null;
        vm.cidadesNatalDisponiveis = null;
        vm.cidadesDisponiveis = null;
        vm.colegiosDisponiveis = null;

        var limite = new Date(2009, 0, 1);
        // console.log(limite);

        vm.inscricao.recebeuIndicacao = 'NAO';
        vm.inscricao.codPessoaIndicacao = '';
        vm.inscricao.indicacaoTexto = '';

        vm.inscricao.possuiNecessidadesEspeciais = '';
        vm.inscricao.necessidadesEspeciaisTexto = '';

        vm.escolaridades = [
            { value: "Cursando 1º Ano", descricao: "Cursando 1º Ano" },
            { value: "Cursando 2º Ano", descricao: "Cursando 2º Ano" },
            { value: "Cursando 3º Ano", descricao: "Cursando 3º Ano" },
            { value: "Cursando 4º Ano", descricao: "Cursando 4º Ano" },
            { value: "Ensino Médio Concluído", descricao: "Ensino Médio Concluído" },
        ];

        vm.inscricao.idps = '63';
        vm.poloEad = false;
        vm.polos = [{ id: 1, cidade: "Cascavel", estado: "PR" }];
        vm.poloEscolhido = vm.polos[0];
        vm.inscricao.polo = 1;

        // cursos
        $http.post("api/get-modalidades-ativas-tradicional")
            .success(function(result) {
                vm.cursos = result;
                // console.log(vm.cursos);
            })
            .error(function(status) {
                console.log(status);
            });


        loadColegios();

        function loadColegios() {
            $http.get("/api/colegio-plays")
                .success(function(data) {
                    vm.colegios = [];
                    for (var i = 0; i < data.length; i++) {
                        var a = data[i];
                        if (a.vendedor != 0) {
                            vm.colegios.push(a);
                        }
                    }

                    //console.log(vm.colegios);
                    vm.cidadesColegio = [];
                    var temp = [];
                    vm.cidadeColegioSelecionada = 'CASCAVEL';
                    for (var i = 0; i < vm.colegios.length; i++) {
                        var c = vm.colegios[i];
                        if (!vm.cidadesColegio.includes(c.cidade)) {
                            vm.cidadesColegio.push(c.cidade);
                        }
                        if (c.cidade == vm.cidadeColegioSelecionada) {
                            temp.push(c);
                        }
                    }
                    vm.colegiosDisponiveis = temp;
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        loadCidadesByJSON();

        function loadCidadesByJSON() {
            vm.cidades = CidadesMDJsonService;
            vm.estadosSigla = [];

            for (var i = 0; i < vm.cidades.length; i++) {
                var e = vm.cidades[i];
                if (!vm.estadosSigla.includes(e.uf)) {
                    vm.estadosSigla.push(e.uf);
                }
            }

            vm.estadoNatalSelecionado = 'PR';
            vm.inscricao.estadoNatal = vm.estadoNatalSelecionado;

            vm.estadoSelecionado = 'PR';
            vm.inscricao.estado = vm.estadoSelecionado;

            var temp = [];
            for (var k = 0; k < vm.cidades.length; k++) {
                var e = vm.cidades[k];
                if (e.uf == vm.estadoNatalSelecionado) {
                    temp.push(e);
                }
            }
            vm.cidadesDisponiveis = temp;
            vm.cidadesNatalDisponiveis = temp;
        }

        /** Valida indicacao */
        if (location.href.includes('?')) {
            var query = location.href.slice(1);
            var partes = query.split('?');
            var chaveValor = partes[1].split('=');
            var valor = chaveValor[1];
            setaVendedorLink(valor);
        } else {
            setaVendedorLink();
        }

        vm.lgpd = false;

        function alteraEtapa(idProxima) {

            var valida = validaDados(idProxima);

            if (valida == "OK") {
                switch (idProxima) {
                    case 2:
                        // aqui vamos consultar o codigo no banco de dados.
                        ConsultaAlunoPlay2018.query({
                            tipo: 'INSCRICAO',
                            valor: vm.inscricao.codigo,
                            idps: vm.inscricao.idps
                        }, function(data) {
                            if (data.length > 0) {
                                if (data[0].inscricao.codigo !== null) {
                                    // achou alguem tem que barrar.
                                    showMessage('alert alert-play', data[0].inscricao.nome.split(' ')[0] + ' já está utilizando este código, tente um código diferente ou acesse o <i><b ui-sref="painelAdministrativo">painel do participante</b></i>');
                                } else {
                                    // codigo null avança
                                    troca(idProxima);
                                }
                            } else {
                                // não achou avança
                                troca(idProxima);
                            }
                        }, function(status) {
                            showMessage('alert alert-play', "Houve um erro ao processar o código da inscrição");
                        });
                        break;
                    case 3:
                        save();

                    default:
                        troca(idProxima);
                        break;
                }
            } else {
                showMessage('alert alert-play', valida);
            }



            // if (idProxima == 0 || idProxima == 19) {
            //     troca(10);
            // } else {

            //     if (idProxima < vm.etapa) {
            //         troca(idProxima);
            //     } else {
            //         
            //     }
            // }
        }

        function troca(idProxima) {
            // console.log(idProxima);
            vm.etapa = idProxima;
        }

        function criarUsuarioAluno() {

            var user = {
                id: null,
                login: vm.inscricao.telefone1,
                email: vm.inscricao.email,
                firstName: vm.inscricao.nome,
                lastName: vm.inscricao.senha
            }

            $http.post("api/create-user-tradicional", user)
                .success(function(d) {
                    sessionStorage.setItem('user-aluno', JSON.stringify(d));
                    vm.user = d;
                })
                .error(function(status) {
                    console.log(status);
                });

        }

        function save() {

            //validaInscricao(idProxima);

            vm.isSaving = true;

            vm.inscricao.dataProva = new Date(2024, 9, 8);
            vm.inscricao.horaProva = "13";
            vm.inscricao.dataCriacao = new Date();
            vm.inscricao.usuarioCriacao = "site";
            vm.inscricao.dataAlteracao = null;
            vm.inscricao.usuarioAlteracao = null;

            vm.inscricao.tipoInscricao = "CONVIDADO";

            vm.inscricao.telefone2 = vm.inscricao.telefone1;
            // vm.inscricao.instituicaoConclusao = vm.instituicaoSelected.id;
            vm.inscricao.instituicaoConclusao = 3421;

            // vm.inscricao.estadoNatal = vm.naturalidade.uf;
            // vm.inscricao.cidadeNatal = vm.naturalidade.cidade;
            // vm.inscricao.estado = vm.residencia.uf;
            // vm.inscricao.cidade = vm.residencia.cidade;

            vm.inscricao.canhoto = false;

            if (vm.inscricao.anoConclusao > 2024) {
                vm.inscricao.treineiro = true;
            } else {
                vm.inscricao.treineiro = false;
            }

            if (vm.user != null) {
                vm.inscricao.userId = vm.user.id;
            }
            vm.lgpd = true;
            //console.log(vm.inscricao);
            Inscricao.save(vm.inscricao, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {

            vm.inscricaoColegio = {};
            vm.inscricaoColegio.codigoInscricao = result.codigo;
            vm.inscricaoColegio.codigoColegio = vm.colegioSelecionado.id;
            vm.inscricaoColegio.idps = result.idps;
            vm.inscricaoColegio.dataCriacao = new Date();
            vm.inscricaoColegio.usuarioCriacao = 'siteColegio';
            vm.inscricaoColegio.dataAlteracao = new Date();
            vm.inscricaoColegio.usuarioAlteracao = 'siteColegio';

            InscricaoColegio.save(vm.inscricaoColegio, function(col) {
                console.log(col);
            }, function(status) {
                console.log(status);
            });

            vm.inscricaoPulseira = {};
            vm.inscricaoPulseira.codigoInscricao = result.codigo;
            vm.inscricaoPulseira.codigoPulseira = result.codigo;
            vm.inscricaoPulseira.dataCriacao = new Date();
            vm.inscricaoPulseira.usuarioCriacao = 'siteColegio';
            vm.inscricaoPulseira.dataAlteracao = new Date();
            vm.inscricaoPulseira.usuarioAlteracao = 'siteColegio';
            vm.inscricaoPulseira.idps = result.idps;

            InscricaoPulseira.save(vm.inscricaoPulseira, function(pul) {
                console.log(pul);
            }, function(status) {
                console.log(status);
            });

            vm.isSaving = false;
            sessionStorage.setItem('inscricao', JSON.stringify(result));
            ConfirmacaoTradicionalService.open();
            $state.go('home');
        }

        function onSaveError() {
            vm.isSaving = false;
            vm.etapa = 2;
            showMessage('não foi possivel salvar sua inscrição;')
        }

        function alterInfo(opcao, i) {
            // estado natal
            if (opcao == 1) {
                vm.inscricao.estadoNatal = vm.estadoNatalSelecionado;
                var temp = [];
                for (var k = 0; k < vm.cidades.length; k++) {
                    var e = vm.cidades[k];
                    if (e.uf == vm.estadoNatalSelecionado) {
                        temp.push(e);
                    }
                }
                vm.cidadesNatalDisponiveis = temp;
            }

            //cidade natal
            if (opcao == 2) {
                vm.inscricao.cidadeNatal = vm.cidadeNatalSelecionado;
            }

            // estado que mora
            if (opcao == 3) {
                vm.inscricao.estado = vm.estadoSelecionado;
                var temp2 = [];
                for (var l = 0; l < vm.cidades.length; l++) {
                    var f = vm.cidades[l];
                    if (f.uf == vm.estadoSelecionado) {
                        temp2.push(f);
                    }
                }
                vm.cidadesDisponiveis = temp2;
            }

            //cidade que mora
            if (opcao == 4) {
                vm.inscricao.cidade = vm.cidadeSelecionado;
            }

            // cidade do colegio
            if (opcao == 5) {
                //console.log(vm.cidadeColegioSelecionada);
                var temp = [];
                for (var i = 0; i < vm.colegios.length; i++) {
                    var c = vm.colegios[i];
                    if (c.cidade == vm.cidadeColegioSelecionada) {
                        temp.push(c);
                    }
                }
                vm.colegiosDisponiveis = temp;
            }
            // colegio selecionado
            if (opcao == 6) {
                console.log(vm.colegioSelecionado);
                setaVendedorLink(vm.colegioSelecionado.vendedor);
            }

            //escolaridade
            if (opcao == 7) {
                vm.inscricao.escolaridade = vm.escolaridadeSelecionada.descricao;
            }
            //primeira opção de curso
            if (opcao == 8) {
                vm.inscricao.idProcessoSeletivo = i.idProcesso;
                vm.inscricao.idps = i.idps;
                vm.inscricao.idCurso1 = i.idCurso;
                vm.inscricao.idModalidadeCurso1 = i.id;
                vm.inscricao.idAreaInteresseOpcao1 = i.idAreaInteresse;
            }

            // segunda opção de curso
            if (opcao === 9) {
                if (i.id == vm.inscricao.idModalidadeCurso1) {
                    showMessage('alert alert-play', "A segunda opção de curso deve ser diferente da primeira.");
                } else {
                    vm.inscricao.idCurso2 = i.idCurso;
                    vm.inscricao.idModalidadeCurso2 = i.id;
                    vm.inscricao.idAreaInteresseOpcao2 = i.idAreaInteresse;
                }
            }
            //console.log(vm.inscricao);
        }

        function validaDados(i) {
            /* vamos validar os dados da inscricao confiorme etapa */
            if (i == 2) {
                if (vm.inscricao.codigo == null || vm.inscricao.codigo == "") {
                    return "O código não pode ser vazio";
                } else {
                    if (vm.inscricao.codigo.length != 5) {
                        return "O código deve conter 5 dígitos";
                    }
                }
            }
            if (i == 3) {
                if (vm.inscricao.email == null || vm.inscricao.email == "") {
                    return "Por favor, informe um e-mail válido";
                } else {
                    if (!vm.inscricao.email.indexOf("@")) {
                        return "Por favor, informe um e-mail válido";
                    }
                }

                if (vm.inscricao.senha == null || vm.inscricao.senha == "") {
                    return "A senha não pode ser vazia";
                } else {
                    if (vm.inscricao.senha.length <= 5) {
                        return "A senha deve conter no mínimo 6 dígitos";
                    }
                }

                if (vm.inscricao.confirmaSenha == null || vm.inscricao.confirmaSenha == "") {
                    return "Por favor confirme sua senha.";
                } else {
                    if (vm.inscricao.confirmaSenha !== vm.inscricao.senha) {
                        return "As senhas inseridas não conferem";
                    }
                }

                if (vm.inscricao.nome == null || vm.inscricao.nome == "") {
                    return "O nome deve ser preenchido.";
                } else {
                    if (vm.inscricao.nome.length <= 3 || !vm.inscricao.nome.includes(" ")) {
                        return "Por favor, insira seu nome completo. Ex: FULANO SILVA";
                    }
                }
                if (vm.inscricao.telefone1 == null || vm.inscricao.telefone1 == "") {
                    return "O CELULAR parece estar incorreto";
                } else {
                    if (vm.inscricao.telefone1.length < 10) {
                        vm.inscricao.telefone1 = "";
                        return "O CELULAR deve conter código de àrea + número de telefone.";
                    }
                }

                if (vm.inscricao.dataNascimento > limite) {
                    return "Por favor, verifique sua data de nascimento";
                }

                // if (vm.inscricao.cpf == null || vm.inscricao.cpf == "") {
                //     return "O CPF informado é inválido.";
                // } else {
                //     if (vm.inscricao.cpf.length < 11) {
                //         return "O campo CPF deve ter ao menos 11 dígitos";
                //     }
                // }

                // if (vm.naturalidade == null) {
                //     return "Por favor informe a cidade que você nasceu.";
                // }
                // if (vm.instituicaoSelected == null || vm.instituicaoSelected == "") {
                //     return "Por favor, selecione o colégio que estuda ou concluiu o Ensino Médio.";
                // }

                // if (vm.inscricao.escolaridade == null || vm.inscricao.escolaridade == "") {
                //     return "Por favor, selecione seu grau de escolaridade.";
                // }

                if (vm.inscricao.cidadeNatal == null || vm.inscricao.cidadeNatal == undefined || vm.inscricao.cidadeNatal == "") {
                    return "Por favor, verifique a cidade onde nasceu, o sistema não identificou a seleção.";
                }

                if (vm.inscricao.cidade == null || vm.inscricao.cidade == undefined || vm.inscricao.cidade == "") {
                    return "Por favor, verifique a cidade onde mora, o sistema não identificou a seleção.";
                }

                if (vm.colegioSelecionado == null || vm.colegioSelecionado == undefined) {
                    return "Por favor, verifique o colegio que estuda, o sistema não identificou a seleção.";
                }


                if (vm.inscricao.anoConclusao == null || vm.inscricao.anoConclusao == "" || vm.inscricao.anoConclusao == 0) {
                    return "Por favor, informe o ano que concluiu ou irá concluir o Ensino Médio.";
                }

                if (vm.inscricao.idCurso1 == null || vm.inscricao.idCurso1 == "") {
                    return "Por favor, selecione um curso para a primeira opção.";
                }

                if (vm.inscricao.idCurso2 == null || vm.inscricao.idCurso2 == "") {
                    return "Por favor, selecione um curso para a segunda opção.";
                } else {
                    if (vm.inscricao.idCurso2 == vm.inscricao.idCurso1) {
                        return "A segunda opção de curso deve ser diferente da primeira.";
                    }
                }

                if (vm.inscricao.possuiNecessidadesEspeciais == "" || vm.inscricao.possuiNecessidadesEspeciais == null) {
                    return "Por favor, informe se possui ou não necessidades especiais.";
                }
                if (vm.inscricao.possuiNecessidadesEspeciais == "SIM") {
                    if (vm.inscricao.necessidadesEspeciaisTexto == "" || vm.inscricao.necessidadesEspeciaisTexto == null) {
                        return "Por favor, informe qual a necessidade especial.";
                    }
                }
            }
            vm.erroForm = false;
            return 'OK';
        }

        function validaInscricao(etapa) {

            var msg = "OK";

            if (vm.inscricao.documento == null || vm.inscricao.documento == undefined) {
                vm.inscricao.documento = "";
            }

            // if (vm.inscricao.cpf == null || vm.inscricao.cpf == "") {
            //     msg = "O CPF informado é inválido.";
            // } else {
            //     if (vm.inscricao.cpf.length < 11) {
            //         msg = "O campo CPF deve ter ao menos 11 dígitos";
            //     }
            // }

            if (vm.inscricao.email == null || vm.inscricao.email == "") {
                msg = "Por favor, informe um e-mail válido";
            } else {
                if (!vm.inscricao.email.indexOf("@")) {
                    msg = "Por favor, informe um e-mail válido";
                }
            }

            if (msg == "OK") {
                ValidaInscricaoTrad.get({ email: vm.inscricao.email, cpf: vm.inscricao.cpf },
                    function(result) {
                        vm.inscricaoValidada = result;
                        if (vm.inscricaoValidada.email == true) {
                            showMessage('alert-info', "Identificamos uma inscricão com este email, favor acessar o painel do candidato.");
                            troca(20);
                        } else if (vm.inscricaoValidada.cpf == true) {
                            showMessage('alert-info', "Identificamos uma inscricão com este CPF, por favor entre em contato para mais informações - 45 3036 3664");
                            troca(20);
                        } else {
                            criarUsuarioAluno();
                            vm.erroForm = false;
                        }
                    },
                    function(status) {
                        console.log(status);
                    });
            } else {
                showMessage("alert-play", msg);
            }

        }

        function setaVendedorLink(valor) {
            //console.log(valor);
            if (valor != null && valor != undefined) {
                vm.interno = true;
                vm.vendLink = true;
                LinkVendedorService.query({ codigo: valor }, function(result) {
                    //console.log(result);
                    vm.inscricao.recebeuIndicacao = result.tipo;
                    vm.inscricao.codPessoaIndicacao = result.codigo;
                    vm.inscricao.indicacaoTexto = result.nome;
                    vm.vendedorLink = result.link;
                    vm.vendedorNumero = result.numero;
                    vm.vendedorEmail = result.email;

                });
            } else {
                vm.interno = false;
                vm.vendLink = false;
                vm.inscricao.recebeuIndicacao = 'NAO';
                vm.inscricao.codPessoaIndicacao = '055861';
                vm.inscricao.indicacaoTexto = 'INSTITUCIONAL';
                vm.vendedorLink = 'https://wa.me/5545988429444';
                vm.vendedorNumero = "45 98842-9444";
                vm.vendedorEmail = "vestibular@univel.br";
            }
        }

        function redirect(link) {
            $state.go(link);
        }

        function login(event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function() {
                vm.authenticationError = false;
                //$uibModalInstance.close();
                if ($state.current.name === 'register' ||
                    $state.current.name === 'finishReset' ||
                    $state.current.name === 'requestReset') {
                    $state.go('home');
                } else {

                    if ($state.current.name === 'home' ||
                        $state.current.name === 'activate' ||
                        $state.current.name === 'home-tradicional' ||
                        $state.current.name === 'resultado-vestibular-end' ||
                        $state.current.name === 'new-inscricao-tradicional'
                    ) {
                        $state.go('painelAdministrativo');
                    }
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function() {
                vm.authenticationError = true;
            });
        }

        function recuperarSenha() {
            $http.post("/api/recupera-senha-email", vm.emailRecuperar)
                .success(function(data) {
                    troca(1);
                })
                .error(function(status) {

                    troca(1);
                });
        }

        /* Referente ao sistema de mensagens */
        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }



        /** REFERENTE AO MD AUTOCOMPLETE */

        vm.querySearch = querySearch;
        vm.selectedItemChange = selectedItemChange;
        vm.searchTextChange = searchTextChange;

        function querySearch(query, array) {

            var results = query ? array.filter(createFilterFor(query)) : array,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChange(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChange(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterFor(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {
                return state.nome.includes(lowercaseQuery);
            };

        }


        /*   referente aos estados   */

        vm.querySearchEstado = querySearchEstado;
        vm.selectedItemChangeEstado = selectedItemChangeEstado;
        vm.searchTextChangeEstado = searchTextChangeEstado;

        function querySearchEstado(query) {

            var results = query ? vm.cidades.filter(createFilterForEstado(query)) : vm.cidades,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangeEstado(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangeEstado(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForEstado(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {

                return state.cidade.toUpperCase().includes(lowercaseQuery);
            };

        }

        /*   referente aos MORADAS   */

        vm.querySearchReside = querySearchReside;
        vm.selectedItemChangeReside = selectedItemChangeReside;
        vm.searchTextChangeReside = searchTextChangeReside;

        function querySearchReside(query) {

            var results = query ? vm.cidades.filter(createFilterForReside(query)) : vm.cidades,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangeReside(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangeReside(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForReside(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {

                return state.cidade.toUpperCase().includes(lowercaseQuery);
            };

        }
    }
})();