(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConfirmacaoUpdateInscricaoTradicionalUserController', ConfirmacaoUpdateInscricaoTradicionalUserController);

    ConfirmacaoUpdateInscricaoTradicionalUserController.$inject = ['$timeout', 'DateUtils', '$scope', '$state', '$stateParams', '$sessionStorage'];

    function ConfirmacaoUpdateInscricaoTradicionalUserController($timeout, DateUtils, $scope, $state, $stateParams, $sessionStorage) {

        $state.go('home-tradicional');

        var vm = this;

        vm.redirect = redirect;
        vm.dados = undefined;

        loadCandidato();

        function loadCandidato() {
            vm.dados = JSON.parse(sessionStorage.getItem('candidato-vestibular-2018'));
            sessionStorage.removeItem('candidato-vestibular-2018');
        }



        function redirect(value) {
            $state.go(value);
        }
    }
})();