(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ResultadoVestibularOnlineController', ResultadoVestibularOnlineController);

    ResultadoVestibularOnlineController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', '$sessionStorage', 'IndicacaoConsulta', 'param'];

    function ResultadoVestibularOnlineController($scope, $rootScope, $state, $timeout, Auth, $uibModalInstance, $sessionStorage, IndicacaoConsulta, param) {
        var vm = this;

        // variables
        vm.load = false;
        vm.selected = false;

        console.log(param.data);

        load();

        function load() {
            if (param.data.linguaPortuguesa == null) { param.data.linguaPortuguesa = 0 };
            if (param.data.matematica == null) { param.data.matematica = 0 };
            if (param.data.biologia == null) { param.data.biologia = 0 };
            if (param.data.fisica == null) { param.data.fisica = 0 };
            if (param.data.quimica == null) { param.data.quimica = 0 };
            if (param.data.geografica == null) { param.data.geografica = 0 };
            if (param.data.historia == null) { param.data.historia = 0 };
            if (param.data.redacao == null) { param.data.redacao = 0 };
            if (param.data.pontuacao == null) { param.data.pontuacao = 0 };

            vm.dados = param.data;

        }

        vm.fechar = function() {
            $uibModalInstance.close();
        }

    }
})();