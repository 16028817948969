(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('consulta-prova-vestiba', {
            parent: 'app',
            url: '/consulta-prova-vestiba',
            data: {
                authorities: ['ROLE_UNIVEL_PLAY'],
                pageTitle: 'consulta prova vestiba'
            },
            views: {
                'content@': {
                    templateUrl: 'app/play/provas/provas.html',
                    controller: 'ConsultaProvasController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();