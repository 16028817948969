(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PoloSituacaoController', PoloSituacaoController);

    PoloSituacaoController.$inject = ['PoloSituacao', 'Principal', 'PoloSituacaoAlter'];

    function PoloSituacaoController(PoloSituacao, Principal, PoloSituacaoAlter) {

        var vm = this;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
            });
            PoloSituacao.query(function(result) {
                vm.infos = result;
            })
        }


        vm.ativar = function(polo) {
            PoloSituacaoAlter.update({
                idPolo: polo.id,
                situacao: 'ATIVO',
                login: vm.account.login
            }, function(result) {
                getAccount();
            }, function(status) {
                console.log(status);
            });
        };

        vm.bloquear = function(polo) {
            PoloSituacaoAlter.update({
                idPolo: polo.id,
                situacao: 'BLOCKED',
                login: vm.account.login
            }, function(result) {
                getAccount();
            }, function(status) {
                console.log(status);
            });
        };

    }
})();