(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('GameRankController', GameRankController);

    GameRankController.$inject = ['$timeout', 'Principal', 'GameRankService'];

    function GameRankController($timeout, Principal, GameRankService) {
        var vm = this;

        vm.rank = [];
        GameRankService.query(
            function(data) {
                console.log(data);
                vm.rank = data;
            },
            function(status) {
                console.log(status);
            }
        )
    }
})();