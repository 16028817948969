(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlanoEnsinoFileDeleteController',PlanoEnsinoFileDeleteController);

    PlanoEnsinoFileDeleteController.$inject = ['$uibModalInstance', 'entity', 'PlanoEnsinoFile'];

    function PlanoEnsinoFileDeleteController($uibModalInstance, entity, PlanoEnsinoFile) {
        var vm = this;

        vm.planoEnsinoFile = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PlanoEnsinoFile.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
