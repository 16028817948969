(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaDiplomaDadosCadastraisController', MatriculaDiplomaDadosCadastraisController);

    MatriculaDiplomaDadosCadastraisController.$inject = ['$timeout', '$rootScope', '$scope', '$state', '$stateParams', '$http', '$sessionStorage', 'CidadesMDJsonService', 'MatriculaDiplomaService', 'ModalImagemService'];

    function MatriculaDiplomaDadosCadastraisController($timeout, $rootScope, $scope, $state, $stateParams, $http, $sessionStorage, CidadesMDJsonService, MatriculaDiplomaService, ModalImagemService) {
        var vm = this;

        vm.avancar = avancar;
        vm.change = change;
        vm.fecharAlert = fecharAlert;
        vm.alterInfo = alterInfo;
        vm.upload = upload;
        vm.imagemAlterada = false;
        vm.getCEP = buscaCEP;

        vm.load = false;

        function init() {

            loadColegios();
            loadCidadesByJSON();


            vm.matricula = JSON.parse(sessionStorage.getItem('user-matric-diploma'));
            console.log(vm.matricula);

            if (vm.matricula.academico.etapa == 'CANDIDATO') {
                $state.go('matriculaDiplomaDadosPais');
            }
            if (vm.matricula.academico.etapa == 'PAIS') {
                $state.go('matriculaDiplomaResponsavel');
            }
            if (vm.matricula.academico.etapa == 'RESPONSAVEL') {
                $state.go('matriculaDiplomaPlanoPagamento');
            }
            if (vm.matricula.academico.etapa == 'PLANOPAGAMENTO') {
                $state.go('matriculaDiplomaContrato');
            }
            if (vm.matricula.academico.etapa == 'CONTRATO') {
                $state.go('matriculaDiplomaBoleto');
            }





            if (vm.matricula.academico.cidadeNatal != null) {
                var b = vm.matricula.academico.cidadeNatal + ' - ' + vm.matricula.academico.estadoNatal;
                for (var i = 0; i < vm.cidades.length; i++) {
                    var element = vm.cidades[i];
                    var a = element.cidade + ' - ' + element.uf;

                    if (a == b) {
                        vm.naturalidade = element;
                    }
                }
            }

            if (vm.matricula.academico.dataNascimento != null) {
                var dateString = vm.matricula.academico.dataNascimento;
                var year = dateString.substring(0, 4);
                var month = dateString.substring(5, 7);
                var day = dateString.substring(8, 10);

                var date = new Date(year, month - 1, day);

                vm.matricula.academico.dataNascimento = date;
            }


            if (vm.matricula.academico.sexo == null) {
                vm.matricula.academico.sexo = 'Masculino';
            }
            if (vm.matricula.academico.estadoCivil == null) {
                vm.matricula.academico.estadoCivil = 'Solteiro';
            }
            if (vm.matricula.academico.raca == null) {
                vm.matricula.academico.raca = 'Amarela';
            }


        }


        function upload() {
            ModalImagemService.open();
        }

        $rootScope.$on('cropImage', function(event, value) {
            if (value != null && value != undefined) {
                vm.matricula.academico.foto = value.replace("data:image/png;base64,", "");
                vm.imagemAlterada = true;
            } else {

            }
        });

        init();

        function avancar() {
            vm.load = true;
            var valida = verificaCampos();

            if (valida == 'OK') {
                vm.matricula.academico.instituicaoConclusao = vm.instituicaoSelected.id;
                vm.matricula.academico.etapa = 'CANDIDATO';
                vm.matricula.academico.tipoMatricula = 'EGRESSO';
                vm.matricula.academico.pgtoOnline = 'SIM';
                vm.matricula.academico.estadoNatal = vm.naturalidade.uf;
                vm.matricula.academico.cidadeNatal = vm.naturalidade.cidade;

                sessionStorage.setItem('user-matric-diploma', JSON.stringify(vm.matricula));
                MatriculaDiplomaService.save(
                    vm.matricula,
                    function(data) {
                        vm.load = false;
                        sessionStorage.setItem('user-matric-diploma', JSON.stringify(data));
                        $state.go('matriculaDiplomaDadosPais');

                    },
                    function(status) {
                        console.log(status);
                    }
                );
            } else {
                showMessage('alert-danger', valida);
                vm.load = false;
            }
        }

        function alterInfo(opcao, i) {
            if (opcao == 3) {
                vm.estado = i;
                vm.matricula.academico.estadoNatal = i.sigla;
                vm.cidades = i.cidades;

                loadCidadesByJSON();
            }

            if (opcao == 4) {
                vm.matricula.academico.cidadeNatal = i;
            }
        }

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function verificaCampos() {

            if (vm.matricula.academico.nome == null || vm.matricula.academico.nome == "") {
                return "O campo NOME não pode estar vazio.";
            }
            // VALIDA SE O NOME TEM PELO MENOS DOIS CARACTERES E PELO MENOS UM ESPAÇO
            if (vm.matricula.academico.nome.length <= 2 || !vm.matricula.academico.nome.includes(" ")) {
                return "O campo NOME deve receber no mínimo um sobrenome.";
            }

            if (vm.matricula.academico.email == null || vm.matricula.academico.email == "") {
                return "O campo EMAIL não pode estar vazio.";
            }
            // VALIDA SE O NOME TEM PELO MENOS DOIS CARACTERES E PELO MENOS UM ESPAÇO
            if (!vm.matricula.academico.email.includes("@")) {
                return "O campo EMAIL não está correto.";
            }

            if (vm.matricula.academico.cpf == null || vm.matricula.academico.cpf == "") {
                return "O campo CPF não pode ser vazio";
            }
            if (vm.matricula.academico.cpf.length < 11) {
                return "O campo CPF deve ter ao menos 11 dígitos";
            }

            if (vm.matricula.academico.telefone1 == null || vm.matricula.academico.telefone1 == "") {
                vm.matricula.academico.telefone1 = vm.matricula.academico.telefone2;
            }

            if (vm.matricula.academico.telefone2 == null || vm.matricula.academico.telefone2 == "") {
                return "O campo CELULAR não pode ser vazio";
            }
            if (vm.matricula.academico.telefone2.length < 10) {
                return "O campo CELULAR deve ter ao menos 10 dígitos";
            }

            if (vm.matricula.academico.nacionalidade == null || vm.matricula.academico.nacionalidade == "") {
                return "O campo NACIONALIDADE não pode ser vazio";
            }
            if (vm.matricula.academico.nacionalidade.length <= 2) {
                return "O campo NACIONALIDADE precisa ter no mínimo 2 caracteres";
            }

            if (vm.matricula.academico.rg == null || vm.matricula.academico.rg == "") {
                return "O campo RG não pode ser vazio";
            }

            if (vm.matricula.academico.cep == null || vm.matricula.academico.cep == "") {
                return "O campo CEP não pode ser vazio";
            }
            if (vm.matricula.academico.cep.length < 7) {
                return "O campo CEP deve ter ao menos 7 dígitos";
            }

            if (vm.matricula.academico.uf == null || vm.matricula.academico.uf == "") {
                return "O campo UF não pode ser vazio";
            }
            if (vm.matricula.academico.uf.length < 2) {
                return "O campo UF deve ter ao menos 2 dígitos";
            }

            if (vm.matricula.academico.cidade == null || vm.matricula.academico.cidade == "") {
                return "O campo CIDADE não pode ser vazio";
            }
            if (vm.matricula.academico.cidade.length < 2) {
                return "O campo CIDADE deve ter ao menos 2 dígitos";
            }

            if (vm.matricula.academico.bairro == null || vm.matricula.academico.bairro == "") {
                return "O campo BAIRRO não pode ser vazio";
            }
            if (vm.matricula.academico.bairro.length < 2) {
                return "O campo BAIRRO deve ter ao menos 2 dígitos";
            }

            if (vm.matricula.academico.logradouro == null || vm.matricula.academico.logradouro == "") {
                return "O campo LOGRADOURO não pode ser vazio";
            }
            if (vm.matricula.academico.logradouro.length < 2) {
                return "O campo LOGRADOURO deve ter ao menos 2 dígitos";
            }

            if (vm.matricula.academico.numero == null || vm.matricula.academico.numero == "") {
                vm.matricula.academico.numero = 1;
            }
            if (vm.matricula.academico.numero.length < 1) {
                vm.matricula.academico.numero = 1;
            }

            if (vm.matricula.academico.numero == 0) {
                vm.matricula.academico.numero = 1;
            }

            if (vm.instituicaoSelected == null) {
                return "O campo Instituição de Conclusão do Ensino Médio precisa ser preenchido.";
            }

            if (vm.naturalidade == null) {
                return "Por favor informe a cidade que você nasceu.";
            }

            return "OK";
        };


        function change() {
            for (var i = 0; i < vm.estados.length; i++) {
                var estado = vm.estados[i];

                if (estado.sigla == vm.estado) {
                    vm.cidades = estado.cidades;
                    break;
                }
            }
        }


        function buscaCEP() {

            //console.log(vm.matricula.cep);
            vm.cepsearch = true;
            if (vm.matricula.academico.cep !== null && vm.matricula.academico.cep !== undefined) {
                $http.post("/api/getCepInfos", { cep: vm.matricula.academico.cep })
                    .success(function(data) {

                        vm.matricula.academico.uf = data.uf;
                        vm.matricula.academico.cidade = data.localidade;
                        vm.matricula.academico.bairro = data.bairro;
                        vm.matricula.academico.logradouro = data.logradouro;
                        vm.cepsearch = false;
                    })
                    .error(function(status) {
                        console.log(status);
                        vm.cepsearch = false;
                    });
            }
        }


        /** REFERENTE AO MD AUTOCOMPLETE */

        vm.querySearch = querySearch;
        vm.selectedItemChange = selectedItemChange;
        vm.searchTextChange = searchTextChange;

        function querySearch(query) {

            var results = query ? vm.allStates.filter(createFilterFor(query)) : vm.allStates,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChange(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChange(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterFor(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {

                return (state.nome.toUpperCase().replace('COLEGIO', '').replace('ESTADUAL', '').replace('ESCOLA', '') + '-' + state.cidade).includes(lowercaseQuery);


                //return state.nome.includes(lowercaseQuery);
            };

        }


        /*   referente aos estados   */

        vm.querySearchEstado = querySearchEstado;
        vm.selectedItemChangeEstado = selectedItemChangeEstado;
        vm.searchTextChangeEstado = searchTextChangeEstado;

        function querySearchEstado(query) {

            var results = query ? vm.cidades.filter(createFilterForEstado(query)) : vm.cidades,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangeEstado(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangeEstado(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForEstado(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {

                return state.cidade.toUpperCase().includes(lowercaseQuery);
            };

        }

        function loadCidadesByJSON() {
            vm.cidades = CidadesMDJsonService;
        }

        function loadColegios() {
            $http.post("/api/get-all-colegios", vm.obj)
                .success(function(data) {
                    vm.colegios = data;

                    vm.allStates = vm.colegios;

                    if (vm.matricula.academico.instituicaoConclusao != null) {
                        for (var i = 0; i < vm.colegios.length; i++) {
                            var element = vm.colegios[i];

                            if (vm.matricula.academico.instituicaoConclusao == element.id) {
                                vm.instituicaoSelected = element;
                            }
                        }
                    }
                })
                .error(function(status) {
                    console.log(status);
                });
        }
    }
})();