(function () {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('GetRelatoriosByTipoService', GetRelatoriosByTipoService);

    GetRelatoriosByTipoService.$inject = ['$resource', 'DateUtils'];

    function GetRelatoriosByTipoService($resource, DateUtils) {
        var resourceUrl = 'api/relatorios-cpe/tipo/:tipo';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: true }
        });
    }
})();