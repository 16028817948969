(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoColegioDialogController', InscricaoColegioDialogController);

    InscricaoColegioDialogController.$inject = ['$timeout', 'Principal', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'InscricaoColegio', 'ColegioPlay'];

    function InscricaoColegioDialogController($timeout, Principal, $scope, $stateParams, $uibModalInstance, entity, InscricaoColegio, ColegioPlay) {
        var vm = this;
        vm.account = null;
        vm.inscricaoColegio = entity;
        vm.inscricaoColegio.idps = '54';
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.cidades = [];
        vm.colegiosCidade = [];
        vm.cidadeSelecionada = cidadeSelecionada;
        vm.colegioSelecionado = colegioSelecionado;



        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                //console.log(account);

            });

            ColegioPlay.query(function(result) {
                vm.colegios = result;
                console.log(result);
                onload();
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            var agora = new Date();
            if (vm.inscricaoColegio.id !== null) {
                vm.inscricaoColegio.dataAlteracao = agora;
                vm.inscricaoColegio.usuarioAlteracao = vm.account.login;
                vm.inscricaoColegio.codigoColegio = vm.colSelect.id;
                InscricaoColegio.update(vm.inscricaoColegio, onSaveSuccess, onSaveError);
            } else {
                vm.inscricaoColegio.codigoColegio = vm.colSelect.id;
                vm.inscricaoColegio.idps = '54';
                vm.inscricaoColegio.dataCriacao = agora;
                vm.inscricaoColegio.usuarioCriacao = vm.account.login;
                vm.inscricaoColegio.dataAlteracao = agora;
                vm.inscricaoColegio.usuarioAlteracao = vm.account.login;

                InscricaoColegio.save(vm.inscricaoColegio, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:inscricaoColegioUpdate', result);
            vm.inscricaoColegio.codigoInscricao = "";
            document.getElementById("field_codigoInscricao").focus();
            vm.travaColegio = true;
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataCriacao = false;
        vm.datePickerOpenStatus.dataAlteracao = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        jQuery(document).on('keydown', 'input.insc', function(ev) {
            if (ev.which === 13) {
                this.style.backgroundColor = '#EFF';
                save();
                return false;
            }
        });

        function onload() {
            var arrayT = new Array();

            vm.isSaving = true;
            for (var i = 0; i < vm.colegios.length; i++) {
                const element = vm.colegios[i];

                if (i == 0) {
                    arrayT.push(element);
                } else {
                    var salva = true;
                    for (var a = 0; a < arrayT.length; a++) {
                        const teste = arrayT[a];
                        if (teste.cidade == element.cidade) {
                            salva = false;
                        }
                    }

                    if (salva) {
                        arrayT.push(element);
                    }
                }

            }
            vm.cidades = arrayT;
            vm.isSaving = false;
        }

        function colegioSelecionado() {
            vm.colSelect = vm.colSelecionado;

            //vm.colSelecionado = null;

            document.getElementById("field_codigoInscricao").focus();
        }

        function cidadeSelecionada() {
            vm.cidadeSelect = vm.cidadeCol.cidade;
            var array = [];

            vm.isSaving = true;
            for (var i = 0; i < vm.colegios.length; i++) {
                const element = vm.colegios[i];
                if (element.cidade == vm.cidadeSelect) {
                    array.push(element);
                }
            }
            vm.colegiosCidade = array;
            console.log(vm.colegiosCidade);
            vm.isSaving = false;
        }
        vm.querySearch = querySearch;
        vm.selectedItemChange = selectedItemChange;
        vm.searchTextChange = searchTextChange;

        function querySearch(query) {

            var results = query ? vm.colegiosCidade.filter(createFilterFor(query)) : vm.colegiosCidade,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChange(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChange(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterFor(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {
                return (state.nome.indexOf(lowercaseQuery) === 0);
            };

        }
    }
})();