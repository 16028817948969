(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoAgendadoDialogController', InscricaoAgendadoDialogController);

    InscricaoAgendadoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'InscricaoAgendado', 'Curso', 'Polo'];

    function InscricaoAgendadoDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, InscricaoAgendado, Curso, Polo) {
        var vm = this;

        vm.inscricaoAgendado = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.cursos = Curso.query();
        vm.polos = Polo.query();
        vm.horarioProva = [
            { 'value': 'H07', 'descricao': '07:00', 'id': 7 },
            { 'value': 'H08', 'descricao': '08:00', 'id': 8 },
            { 'value': 'H09', 'descricao': '09:00', 'id': 9 },
            { 'value': 'H10', 'descricao': '10:00', 'id': 10 },
            { 'value': 'H11', 'descricao': '11:00', 'id': 11 },
            { 'value': 'H12', 'descricao': '12:00', 'id': 12 },
            { 'value': 'H13', 'descricao': '13:00', 'id': 13 },
            { 'value': 'H14', 'descricao': '14:00', 'id': 14 },
            { 'value': 'H15', 'descricao': '15:00', 'id': 15 },
            { 'value': 'H16', 'descricao': '16:00', 'id': 16 },
            { 'value': 'H17', 'descricao': '17:00', 'id': 17 },
            { 'value': 'H18', 'descricao': '18:00', 'id': 18 },
            { 'value': 'H19', 'descricao': '19:00', 'id': 19 },
            { 'value': 'H20', 'descricao': '20:00', 'id': 20 },
            { 'value': 'H21', 'descricao': '21:00', 'id': 21 },
            { 'value': 'H22', 'descricao': '22:00', 'id': 22 }
        ];

        //console.log(vm.cursos);
        //console.log(vm.polos);
        //console.log(vm.inscricaoAgendado);


        /*$(document).ready(function() {
            $('#datepicker').datepicker({ beforeShowDay: noSunday });

            function noSunday(date) { var day = date.getDay(); return [(day > 0), '']; };
        });*/






        vm.disabled = desative;

        vm.dateOptions = {
            'year-format': "'yy'",
            'starting-day': 2,
            'showWeeks': false
        };

        function desative(date, mode) {
            console.log(date);

            return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
        };

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.inscricaoAgendado.id !== null) {
                InscricaoAgendado.update(vm.inscricaoAgendado, onSaveSuccess, onSaveError);
            } else {
                InscricaoAgendado.save(vm.inscricaoAgendado, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:inscricaoAgendadoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataProva = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();