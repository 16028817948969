(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('inscricao', {
                parent: 'entity',
                url: '/inscricao',
                data: {
                    authorities: ['ROLE_UNIVEL_PLAY'],
                    pageTitle: 'Inscrições Univel Play'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/inscricao/inscricaos.html',
                        controller: 'InscricaoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('inscricao-detail', {
                parent: 'entity',
                url: '/inscricao/{id}',
                data: {
                    authorities: ['ROLE_UNIVEL_PLAY'],
                    pageTitle: 'Inscricao'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/inscricao/inscricao-detail.html',
                        controller: 'InscricaoDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Inscricao', function($stateParams, Inscricao) {
                        return Inscricao.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'inscricao',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('inscricao-detail.edit', {
                parent: 'inscricao-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inscricao/inscricao-dialog.html',
                        controller: 'InscricaoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Inscricao', function(Inscricao) {
                                return Inscricao.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('inscricao.new', {
                parent: 'inscricao',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inscricao/inscricao-dialog.html',
                        controller: 'InscricaoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    nome: null,
                                    email: null,
                                    telefone: null,
                                    dataNascimento: null,
                                    cidadeNatal: null,
                                    estadoNatal: null,
                                    codigo: null,
                                    dataCriacao: null,
                                    usuarioCriacao: null,
                                    dataAlteracao: null,
                                    usuarioAlteracao: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('inscricao', null, { reload: 'inscricao' });
                    }, function() {
                        $state.go('inscricao');
                    });
                }]
            })
            .state('inscricao.edit', {
                parent: 'inscricao',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inscricao/inscricao-dialog.html',
                        controller: 'InscricaoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Inscricao', function(Inscricao) {
                                return Inscricao.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('inscricao', null, { reload: 'inscricao' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('inscricao.delete', {
                parent: 'inscricao',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SUPER_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inscricao/inscricao-delete-dialog.html',
                        controller: 'InscricaoDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Inscricao', function(Inscricao) {
                                return Inscricao.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('inscricao', null, { reload: 'inscricao' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();