(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('CheckinplayDialogController', CheckinplayDialogController);

    CheckinplayDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Checkinplay', 'InscricaoPulseiraConsulta', 'Principal'];

    function CheckinplayDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Checkinplay, InscricaoPulseiraConsulta, Principal) {
        var vm = this;

        vm.checkinplay = entity;
        vm.clear = clear;
        vm.localizado = null;
        vm.falhaPulseira = null;

        Principal.identity().then(function(account) {
            vm.user = account.login;
            vm.isAuthenticated = Principal.isAuthenticated;
        });

        $timeout(function() {
            angular.element('.pul').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        // vm.validaFront = function() {
        //     vm.localizado = false;
        //     vm.pessoaLocalizada = null;
        //     vm.falhaPulseira = vm.pulseira;

        //     console.log(vm.pulseira);

        //     for (var i = 0; i < vm.candidatos.length; i++) {
        //         var e = vm.candidatos[i];

        //         if (vm.pulseira == e.codigo) {
        //             vm.localizado = true;
        //             vm.pessoaLocalizada = e;
        //             console.log("Achou: " + e.nome);
        //         }
        //     }

        //     var send = {
        //         inscricao: vm.pulseira,
        //         pulseira: vm.pulseira,
        //         createdData: null,
        //         createdUser: vm.user,
        //         localizado: null
        //     }

        //     if (vm.localizado) {
        //         send.localizado = true;
        //     } else {
        //         send.localizado = false;
        //     }

        //     console.log(send);

        //     Checkinplay.save(send,
        //         function(data) {
        //             vm.pulseira = "";
        //         },
        //         function(status) {
        //             console.log(status);
        //             vm.pulseira = "";
        //         });
        // }

        jQuery(document).on('keydown', 'input.pul', function(ev) {
            if (ev.which === 13) {
                // vm.validaFront();
                vm.falhaPulseira = vm.pulseira;

                InscricaoPulseiraConsulta.query({ pulseira: vm.pulseira, idps: '54' },
                    function(painel) {

                        console.log(painel);



                        if (painel.inscricaoPulseira == null || painel.inscricaoPulseira == undefined) {
                            var send = {
                                inscricao: vm.falhaPulseira,
                                pulseira: vm.falhaPulseira,
                                idps: '54',
                                createdData: null,
                                createdUser: vm.user,
                                localizado: false
                            }

                            Checkinplay.save(send,
                                function(data) {
                                    console.log("2");
                                    vm.pessoaLocalizada = painel.inscricao;
                                },
                                function(status) {
                                    console.log(status);
                                });

                            vm.localizado = false;
                        } else {
                            var send = {
                                inscricao: painel.inscricaoPulseira.codigoInscricao,
                                pulseira: painel.inscricaoPulseira.codigoPulseira,
                                idps: painel.inscricaoPulseira.idps,
                                createdData: null,
                                createdUser: vm.user,
                                localizado: true
                            }

                            Checkinplay.save(send,
                                function(data) {
                                    console.log("1");
                                    vm.pessoaLocalizada = painel.inscricao;
                                    vm.localizado = true;
                                },
                                function(status) {
                                    console.log(status);
                                });
                        }
                    },
                    function(status) {
                        console.log(status);
                    });

                vm.pulseira = "";

            }
        });
    }
})();