(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PoloController', PoloController);

    PoloController.$inject = ['Polo'];

    function PoloController(Polo) {

        var vm = this;

        vm.polos = [];

        loadAll();

        function loadAll() {
            Polo.query(function(result) {
                vm.polos = result;
                vm.searchQuery = null;
            });
        }
    }
})();
