(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConsultaProvasController', ConsultaProvasController);

    ConsultaProvasController.$inject = ['ConsultaProvaPainelService'];

    function ConsultaProvasController(ConsultaProvaPainelService) {

        var vm = this;

        vm.prova = "";
        vm.localizados = [];
        vm.consultar = consultar;

        vm.idps = "54";


        function consultar() {

            ConsultaProvaPainelService.query({ prova: vm.prova, idps: vm.idps },
                function(data) {
                    vm.localizados = data;
                },
                function(status) {
                    console.log(status);
                })
        }

    }
})();