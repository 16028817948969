(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('SalvaLeadCalculaNotaEnem', SalvaLeadCalculaNotaEnem);

    SalvaLeadCalculaNotaEnem.$inject = ['$resource', 'DateUtils'];

    function SalvaLeadCalculaNotaEnem($resource, DateUtils) {
        var resourceUrl = '/api/salva-lead-calcula-nota-enem';

        return $resource(resourceUrl, {}, {
            'send': { method: 'POST', isArray: false }
        });
    }
})();