(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('LeadPosPoloService', LeadPosPoloService);

    LeadPosPoloService.$inject = ['$resource', 'DateUtils'];

    function LeadPosPoloService($resource, DateUtils) {
        var resourceUrl = 'api/leadPosPolo';

        return $resource(resourceUrl, {}, {
            'save': { method: 'post', isArray: false }
        });
    }
})();