(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoProvaOnlineController', InscricaoProvaOnlineController);

    InscricaoProvaOnlineController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'InscricaoAgendadoUser', 'CursosPorPolo', '$sessionStorage', 'IndicacaoService', 'PolosAtivos', 'DatasPorPolo', 'ConsultaHorarioPolo', 'ValidaInscricao', 'LoginService', 'ValidaHorariosRM', 'ValidaProvaOnlineService'];

    function InscricaoProvaOnlineController($timeout, $scope, $rootScope, $state, $stateParams, InscricaoAgendadoUser, CursosPorPolo, $sessionStorage, IndicacaoService, PolosAtivos, DatasPorPolo, ConsultaHorarioPolo, ValidaInscricao, LoginService, ValidaHorariosRM, ValidaProvaOnlineService) {
        var vm = this;

        // functions
        vm.save = save;
        vm.openIndicacao = openIndicacao;
        vm.buscaCursos = buscaCursos;
        vm.login = login;
        vm.fecharAlert = fecharAlert;

        // variables
        vm.inscricaoAgendado = {};
        vm.inscricaoAgendado.possuiNecessidadesEspeciais = 'NAO';
        vm.inscricaoAgendado.possuiIndicacao = 'NAO';
        vm.inscricaoAgendado.interno = false;
        vm.poloEad = false;

        if (location.href.includes('?')) {

            var query = location.href.slice(1);
            var partes = query.split('?');
            var chaveValor = partes[1].split('=');
            var valor = chaveValor[1];
            setaVendedorLink(valor);
        }

        // controle de etapass
        vm.etapa = 1;

        vm.recebeu_indicacao_texto = null;
        vm.necessidades_especiais_texto = null;

        vm.inscricaoAgendado.provaOnline = true;

        vm.inscricaoAgendado.dataProva = new Date();
        vm.inscricaoAgendado.horaProva = "H12";

        function openIndicacao() {
            IndicacaoService.open(vm.inscricaoAgendado.possuiIndicacao);
        }

        $rootScope.$on('selecionou', function(event, value) {
            if (value != null && value != undefined) {
                vm.inscricaoAgendado.codPessoaIndicacao = value.codPessoa;
                vm.inscricaoAgendado.indicacaoTexto = value.nome;
                vm.inscricaoAgendado.possuiIndicacao = value.possuiIndicacao;
            } else {
                vm.inscricaoAgendado.possuiIndicacao = "NAO";
                vm.inscricaoAgendado.indicacaoTexto = null;
                vm.inscricaoAgendado.codPessoaIndicacao = null;
            }
        });

        function buscaCursos() {
            vm.cursos = CursosPorPolo.query({
                id: vm.poloEscolhido.id
            });

            if (vm.poloEscolhido.id !== 1) {
                vm.poloEad = true;
            } else {
                vm.poloEad = false;
            }
        }

        vm.polos = PolosAtivos.query({
            page: 0,
            size: 50
        });

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }
        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.keyup = function(keyEvent) {
            if (keyEvent.ctrlKey && keyEvent.keyCode == 73) {

                vm.interno = !vm.interno;

                vm.inscricaoAgendado.interno = vm.interno;
            }
        };


        function validaInscricao(etapa) {

            ValidaInscricao.query({ email: vm.inscricaoAgendado.email, cpf: vm.inscricaoAgendado.cpf },
                function(result) {
                    vm.inscricaoValidada = result;
                    console.log(vm.inscricaoValidada);
                    if (vm.inscricaoValidada.existe == true) {
                        if (vm.inscricaoValidada.pendente == true) {
                            if (vm.inscricaoValidada.email == true) {
                                showMessage('alert-info', "Identificamos uma inscricão com este email, favor autenticar no sistema pelo Acesso Restrito na parte inferior da página.");
                            } else {
                                showMessage('alert-info', "Identificamos uma inscricão com este CPF, por favor entre em contato para mais informações - 45 3036 3664");
                            }
                        } else {
                            vm.inscricaoAgendado.id = vm.inscricaoValidada.idInsc;
                            vm.etapa = etapa;
                        }
                    } else {
                        vm.etapa = etapa;
                    }
                },
                function(status) {
                    console.log(status);
                });
        }

        vm.avancaEtapa = function(etapa) {

            var v = verificaCampos(etapa);
            if (v == 'OK') {
                if (etapa == 2) {
                    validaInscricao(etapa);
                } else {
                    vm.etapa = etapa;
                }
            } else {
                showMessage('alert alert-danger', v);
            }
        }

        vm.alterInfo = function(op, info) {

            if (op === 1) {
                vm.poloEscolhido = info;
                vm.inscricaoAgendado.polo = vm.poloEscolhido;
                buscaCursos();
            }

            if (op === 2) {
                vm.inscricaoAgendado.horaProva = info.value;
            }

            if (op === 3) {
                vm.inscricaoAgendado.curso = info;
            }
        }

        function verificaCampos(etapa) {

            if (etapa == 2) {
                // VALIDA SE O NOME ESTA VAZIO -- JA TEM O REQUIRED, MAS VALIDAÇÃO NUNCA É DEMAIS
                if (vm.inscricaoAgendado.nome == null || vm.inscricaoAgendado.nome == "") {
                    return "O campo NOME não pode estar vazio.";
                }
                // VALIDA SE O NOME TEM PELO MENOS DOIS CARACTERES E PELO MENOS UM ESPAÇO

                if (vm.inscricaoAgendado.nome.indexOf(" ") == -1) {
                    return "Por favor, insira seu NOME COMPLETO. Ex: FULANO SILVA";
                }

                if (vm.inscricaoAgendado.nome.length <= 3) {
                    return "Por favor, insira seu NOME COMPLETO. Ex: FULANO SILVA";
                }

                if (vm.inscricaoAgendado.cpf == null || vm.inscricaoAgendado.cpf == "") {
                    return "O CPF informado é inválido.";
                }
                if (vm.inscricaoAgendado.cpf.length < 11) {
                    return "O campo CPF deve ter ao menos 11 dígitos";
                }

                if (vm.inscricaoAgendado.telefone == null || vm.inscricaoAgendado.telefone == "") {
                    return "O campo TELEFONE não pode ser vazio";
                }
                if (vm.inscricaoAgendado.telefone.length < 10) {
                    return "O campo TELEFONE deve ter ao menos 10 dígitos";
                }

                if (vm.inscricaoAgendado.email == null || vm.inscricaoAgendado.email == "") {
                    return "O campo EMAIL não pode ser vazio";
                }
                if (!vm.inscricaoAgendado.email.indexOf("@")) {
                    return "O campo EMAIL não está correto";
                }
            }

            if (etapa == 3) {

                if (vm.poloEscolhido == null) {
                    return "Por favor selecione em qual polo deseja realizar a prova";
                }

                if (vm.inscricaoAgendado.curso == null) {
                    return "Por favor selecione o curso que deseja na lista";
                }

            }

            if (vm.inscricaoAgendado.recebeu_indicacao == undefined) {
                vm.inscricaoAgendado.recebeu_indicacao = 'NAO';
            }
            if (vm.inscricaoAgendado.possuiNecessidadesEspeciais == undefined) {
                vm.inscricaoAgendado.possuiNecessidadesEspeciais = 'NAO';
            }

            vm.inscricaoAgendado.nome = vm.inscricaoAgendado.nome.toUpperCase();
            vm.inscricaoAgendado.email = vm.inscricaoAgendado.email.toUpperCase();

            if (vm.inscricaoAgendado.necessidadesEspeciaisTexto != null) {
                vm.inscricaoAgendado.necessidadesEspeciaisTexto = vm.inscricaoAgendado.necessidadesEspeciaisTexto.toUpperCase();
            }

            if (vm.inscricaoAgendado.indicacaoTexto != null) {
                vm.inscricaoAgendado.indicacaoTexto = vm.inscricaoAgendado.indicacaoTexto.toUpperCase();
            }
            return "OK";
        };


        function save() {

            vm.inscricaoAgendado.provaOnline = true;
            vm.inscricaoAgendado.inscricaoPlay = false;
            vm.inscricaoAgendado.senha = vm.inscricaoAgendado.cpf;

            showMessage('invi', '');
            vm.isSaving = true;

            vm.inscricaoAgendado.dataProva = new Date();
            vm.inscricaoAgendado.horaProva = "H12";

            sessionStorage.setItem('prova-online-inscricao', JSON.stringify(vm.inscricaoAgendado));

            if (vm.inscricaoAgendado.id != null) {
                InscricaoAgendadoUser.update(vm.inscricaoAgendado, onSaveSuccess, onSaveError);
            } else {
                InscricaoAgendadoUser.save(vm.inscricaoAgendado, onSaveSuccess, onSaveError);
            }
            console.log(vm.inscricaoAgendado);
        }

        function onSaveSuccess(result) {

            sessionStorage.setItem('prova-online-inscricao', JSON.stringify(result));

            /* Vamos validar se já tem uma prova online em andamento */
            ValidaProvaOnlineService.query(result,
                function(r) {
                    var e = null;
                    for (var i = 0; i < r.length; i++) {
                        e = r[i];
                    }

                    if (e != null) {
                        console.log("ACHEI UMA PROVA EM ANDAMENTO");
                        console.log(e);
                        sessionStorage.removeItem('prova-online-inscricao');
                        sessionStorage.setItem('prova-online', JSON.stringify(e));
                    }
                },
                function(status) {
                    console.log(status);
                });

            $state.go('termoProvaOnline');
            vm.isSaving = false;
        }

        function onSaveError(error) {
            console.log(error);
            showMessage('alert alert-info', 'Desculpe, aconteceu algo de errado na sua inscrição, entre em contato pelos telefones no final da página para solucionar');
            vm.isSaving = false;
        }

        function login() {
            LoginService.open();
            $state.go('painelAdministrativo');
        }

        function setaVendedorLink(valor) {
            vm.interno = true;
            vm.vendLink = true;
            switch (valor) {

                case '03827':
                    vm.inscricaoAgendado.possuiIndicacao = 'FUNCIONARIO';
                    vm.inscricaoAgendado.codPessoaIndicacao = '03827';
                    vm.inscricaoAgendado.indicacaoTexto = 'ALINE DELLA PASQUA GARRIDO';
                    break;

                case '04213':
                    vm.inscricaoAgendado.possuiIndicacao = 'FUNCIONARIO';
                    vm.inscricaoAgendado.codPessoaIndicacao = '04213';
                    vm.inscricaoAgendado.indicacaoTexto = 'CARLOS HENRIQUE ARAUJO LEMES';
                    break;

                case '03893':
                    vm.inscricaoAgendado.possuiIndicacao = 'FUNCIONARIO';
                    vm.inscricaoAgendado.codPessoaIndicacao = '03893';
                    vm.inscricaoAgendado.indicacaoTexto = 'CLAUDINEIA ALVES DA SILVA';
                    break;

                case '03997':
                    vm.inscricaoAgendado.possuiIndicacao = 'FUNCIONARIO';
                    vm.inscricaoAgendado.codPessoaIndicacao = '03997';
                    vm.inscricaoAgendado.indicacaoTexto = 'FELIPE ANDRE SCALSSAVARA WAGNER';
                    break;

                case '03993':
                    vm.inscricaoAgendado.possuiIndicacao = 'FUNCIONARIO';
                    vm.inscricaoAgendado.codPessoaIndicacao = '03993';
                    vm.inscricaoAgendado.indicacaoTexto = 'LUIS EDUARDO DE OLIVEIRA';
                    break;

                case '03965':
                    vm.inscricaoAgendado.possuiIndicacao = 'FUNCIONARIO';
                    vm.inscricaoAgendado.codPessoaIndicacao = '03965';
                    vm.inscricaoAgendado.indicacaoTexto = 'TANIA PARTEKA';
                    break;

                case '04656':
                    vm.inscricaoAgendado.possuiIndicacao = 'FUNCIONARIO';
                    vm.inscricaoAgendado.codPessoaIndicacao = '04656';
                    vm.inscricaoAgendado.indicacaoTexto = 'MAYARA DE FREITAS';
                    break;

                case '055861':
                    vm.inscricaoAgendado.possuiIndicacao = 'NAO';
                    vm.inscricaoAgendado.codPessoaIndicacao = '055861';
                    vm.inscricaoAgendado.indicacaoTexto = 'INSTITUCIONAL';
                    break;

                default:
                    vm.interno = false;
                    vm.vendLink = false;
                    vm.inscricaoAgendado.possuiIndicacao = 'NAO';
                    vm.inscricaoAgendado.codPessoaIndicacao = '';
                    vm.inscricaoAgendado.indicacaoTexto = '';
                    break;
            }
        }
    }
})();