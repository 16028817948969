(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlanoAulaDeleteController',PlanoAulaDeleteController);

    PlanoAulaDeleteController.$inject = ['$uibModalInstance', 'entity', 'PlanoAula'];

    function PlanoAulaDeleteController($uibModalInstance, entity, PlanoAula) {
        var vm = this;

        vm.planoAula = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PlanoAula.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
