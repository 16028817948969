(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('HorarioPoloDetailController', HorarioPoloDetailController);

    HorarioPoloDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'HorarioPolo'];

    function HorarioPoloDetailController($scope, $rootScope, $stateParams, previousState, entity, HorarioPolo) {
        var vm = this;

        vm.horarioPolo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:horarioPoloUpdate', function(event, result) {
            vm.horarioPolo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
