(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('QrCodeController', QrCodeController);

    QrCodeController.$inject = ['$scope', '$rootScope', '$state', '$sessionStorage'];

    function QrCodeController($scope, $rootScope, $state, $sessionStorage) {
        var vm = this;
        console.log("Veio para o QRREADER");

        //variaveis
        vm.request = JSON.parse(sessionStorage.getItem('request-qr-reader'));
        console.log(vm.request);

        vm.leitura = "";
        console.log("letura -> " + vm.leitura);


        init();

        function init() {
            vm.scanner = new Instascan.Scanner({
                video: document.getElementById('preview'),
                mirror: true,
                scanPeriod: 1
            });


            vm.scanner.addListener('scan', function(content, image) {
                $('.scans').html('<h2>Scans</h2><a href="' + content + '" target="_blank">' + content + '</a>');
                vm.leitura = content;
                console.log("letura -> " + vm.leitura);
                vm.scanner.stop();


                vm.request.retorno = vm.leitura;
                sessionStorage.setItem('request-qr-reader', JSON.stringify(vm.request));
                $state.go(vm.request.callback);
            });

            Instascan.Camera.getCameras().then(function(cameras) {
                console.log("buscando cameras");

                vm.cameras = cameras;
                if (cameras.length > 0) {
                    var ine = "<h2>Cameras</h2><ul>";
                    var cam = null;
                    for (var index = 0; index < cameras.length; index++) {
                        const element = cameras[index];
                        console.log(element.name);
                        ine = ine + '<li><button type="button"><span>' + element.name + '</span></button></li>';
                        cam = element;
                    }
                    ine = ine + "</ul>";
                    vm.activeCameraId = cam.id;
                    vm.scanner.start(cam);
                    $('.cameras').html(ine);
                    $('.infos').html(cam.name);

                } else {
                    console.error('No cameras found.');
                    $('.infos').html('No cameras found.');
                }
            }).catch(function(e) {
                console.error(e);
                $('.infos').html(e);
            });
        }
    }

})();