(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('TesteProvaController', TesteProvaController);

    TesteProvaController.$inject = ['$scope', '$state', '$http', '$sessionStorage', 'QuestaoProvaService', 'SalvaRespostaSelecionada', 'FinalProvaService', 'FinalProvaOnline', 'SessaoProvaOnlineService', 'ProvaOnline'];

    function TesteProvaController($scope, $state, $http, $sessionStorage, QuestaoProvaService, SalvaRespostaSelecionada, FinalProvaService, FinalProvaOnline, SessaoProvaOnlineService, ProvaOnline) {
        var vm = this;

        vm.prova = {};
        vm.prova = JSON.parse(sessionStorage.getItem('prova-online'));
        vm.perguntas = JSON.parse(sessionStorage.getItem('prova-online-questao-aluno'));
        vm.respostasAluno = JSON.parse(sessionStorage.getItem('respostas-aluno'));

        console.log(vm.prova);
        console.log(vm.perguntas);

        if (vm.perguntas == null) {
            QuestaoProvaService.query(vm.prova, function(result) {
                console.log(result);
                vm.temp = result;
                /*for (var i = 0; i < result.length; i++) {
                    var e = result[i];
                    vm.temp.push({ id: (i + 1), questao: e });
                }*/

                if (vm.respostasAluno == null) {
                    // como não tem vamos para o banco de dedos
                    console.log("Não tem respostas");
                    FinalProvaService.query(vm.prova,
                        function(result) {
                            vm.respostasAluno = result;
                            vm.perguntas = vm.temp;
                            sessionStorage.setItem('prova-online-questao-aluno', JSON.stringify(vm.perguntas));
                            sessionStorage.setItem('respostas-aluno', JSON.stringify(vm.respostasAluno));
                        },
                        function(status) {
                            console.log(status);
                        });
                }


            }, function(state) {
                console.log(state);
            });
        }
    }
})();