(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ImportPlanoAulaByEnsino', ImportPlanoAulaByEnsino);

    ImportPlanoAulaByEnsino.$inject = ['$resource', 'DateUtils'];

    function ImportPlanoAulaByEnsino($resource, DateUtils) {
        var resourceUrl = '/api/plano-aulas/import-by-ensino/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();