(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('OnibusPlayService', OnibusPlayService);

    OnibusPlayService.$inject = ['$resource', 'DateUtils'];

    function OnibusPlayService($resource, DateUtils) {
        var resourceUrl = 'api/get-onibus-play';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true }
        });
    }
})();