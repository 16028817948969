(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ValidaHorariosRM', ValidaHorariosRM);

    ValidaHorariosRM.$inject = ['$resource'];

    function ValidaHorariosRM($resource) {
        var resourceUrl = 'api/valida-horarios-rm/:dataProva/:idps';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }
})();