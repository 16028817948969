(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('LeadsPainelService', LeadsPainelService);

    LeadsPainelService.$inject = ['$resource', 'DateUtils'];

    function LeadsPainelService($resource, DateUtils) {
        var resourceUrl = '/api/busca-leads-painel';

        return $resource(resourceUrl, {}, {
            'query': { method: 'post', isArray: false }
        });
    }
})();