(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('DataPoloSave', DataPoloSave);

    DataPoloSave.$inject = ['$resource', 'DateUtils'];

    function DataPoloSave($resource, DateUtils) {
        var resourceUrl = 'api/data-polos/save/:idPolo/:dia/:motivo';

        return $resource(resourceUrl, {}, {
            'save': { method: 'GET', isArray: false }
        });
    }
})();