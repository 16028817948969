(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ColegioPlayDetailController', ColegioPlayDetailController);

    ColegioPlayDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ColegioPlay'];

    function ColegioPlayDetailController($scope, $rootScope, $stateParams, previousState, entity, ColegioPlay) {
        var vm = this;

        vm.colegioPlay = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:colegioPlayUpdate', function(event, result) {
            vm.colegioPlay = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
