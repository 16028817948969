(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('PoloSituacao', PoloSituacao);

    PoloSituacao.$inject = ['$resource', 'DateUtils'];

    function PoloSituacao($resource, DateUtils) {
        var resourceUrl = 'api/polo-situacao';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();