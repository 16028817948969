(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConfirmacaoInscricaoTradicionalUserInternaController', ConfirmacaoInscricaoTradicionalUserInternaController);

    ConfirmacaoInscricaoTradicionalUserInternaController.$inject = ['$timeout', 'DateUtils', '$scope', '$state', '$stateParams', '$sessionStorage'];

    function ConfirmacaoInscricaoTradicionalUserInternaController($timeout, DateUtils, $scope, $state, $stateParams, $sessionStorage) {
        var vm = this;

        $state.go('home-tradicional');

        vm.redirect = redirect;
        vm.dados = undefined;

        loadCandidato();

        function loadCandidato() {
            vm.dados = JSON.parse(sessionStorage.getItem('candidato-vestibular-2018-interna'));
            sessionStorage.removeItem('candidato-vestibular-2018-interna');
        }



        function redirect(value) {
            $state.go(value);
        }
    }
})();