(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('EditaInscController', EditaInscController);

    EditaInscController.$inject = ['$scope', '$http', '$uibModalInstance', '$sessionStorage', 'param', 'CursosAgendadoGeral', 'Polo', 'InscricaoAgendado', 'TrocaCursoAgService', 'CursoModalidade'];

    function EditaInscController($scope, $http, $uibModalInstance, $sessionStorage, param, CursosAgendadoGeral, Polo, InscricaoAgendado, TrocaCursoAgService, CursoModalidade, Curso) {
        var vm = this;

        vm.inscricaoAgendado = param.data;
        console.log(vm.inscricaoAgendado);

        if (vm.inscricaoAgendado.dataNascimento != null) {
            var ano = vm.inscricaoAgendado.dataNascimento.substring(0, 4);
            var mes = vm.inscricaoAgendado.dataNascimento.substring(5, 7) - 1;
            var dia = vm.inscricaoAgendado.dataNascimento.substring(8, 10);
            vm.inscricaoAgendado.dataNascimento = new Date(ano, mes, dia);;
        }

        CursoModalidade.get({ id: vm.inscricaoAgendado.idModalidade }, function(data) {
            vm.modalidadeSelecionada = data.id;
            console.log(vm.modalidadeSelecionada);

            $http.post("/api/get-modalidades-ativas-agendado").success(function(data) {
                vm.modalidades = data;
                // console.log(vm.modalidades);
            }).error(function(status) { console.log(status); });

        }, function(status) { console.log(status); });

        vm.save = save;
        vm.clear = clear;

        // AQUI COMEÇA A FUNÇÂO DO CURSO
        $http.post("/api/cursos/get-cursos-novos").success(function(data) { vm.cursos = data; }).error(function(status) { console.log(status); });

        vm.polos = Polo.query();

        vm.horarioProva = [
            { 'value': 'H07', 'descricao': '07:00', 'id': 7 },
            { 'value': 'H08', 'descricao': '08:00', 'id': 8 },
            { 'value': 'H09', 'descricao': '09:00', 'id': 9 },
            { 'value': 'H10', 'descricao': '10:00', 'id': 10 },
            { 'value': 'H11', 'descricao': '11:00', 'id': 11 },
            { 'value': 'H12', 'descricao': '12:00', 'id': 12 },
            { 'value': 'H13', 'descricao': '13:00', 'id': 13 },
            { 'value': 'H14', 'descricao': '14:00', 'id': 14 },
            { 'value': 'H15', 'descricao': '15:00', 'id': 15 },
            { 'value': 'H16', 'descricao': '16:00', 'id': 16 },
            { 'value': 'H17', 'descricao': '17:00', 'id': 17 },
            { 'value': 'H18', 'descricao': '18:00', 'id': 18 },
            { 'value': 'H19', 'descricao': '19:00', 'id': 19 },
            { 'value': 'H20', 'descricao': '20:00', 'id': 20 },
            { 'value': 'H21', 'descricao': '21:00', 'id': 21 },
            { 'value': 'H22', 'descricao': '22:00', 'id': 22 }
        ];

        //console.log(vm.cursos);
        //console.log(vm.polos);
        //console.log(vm.inscricaoAgendado);

        vm.trocaCurso = function() {

            var r = confirm("Atenção esta opção irá trocar o curso de matricula do usuario, sem alterar o RM, não é permitido altertar de modalidade (EAD|PRESENCIAL) por aqui, deseja continuar?");

            if (r) {

                TrocaCursoAgService.query({
                    curso: vm.modalidadeSelecionada.id,
                    insc: vm.inscricaoAgendado.id
                }, function(data) {
                    console.log(data);
                    alert("alterado curso para inscricao");
                    clear();
                }, function(status) {
                    if (status == null || status == "") {
                        alert('Desculpe, não foi possível alterar o curso.');
                        clear();
                    }
                });
            }
        }

        function save() {


            var r = confirm("Atenção esta opção irá cancelar a inscrição atual do RM, será preciso lançar a nota novamente, deseja continuar?");
            if (r) {
                vm.isSaving = true;

                vm.inscricaoAgendado.dataProva = new Date();

                if (vm.inscricaoAgendado.codPessoa == null || vm.inscricaoAgendado.codPessoa == "") {
                    vm.inscricaoAgendado.codPessoa = 0;
                }

                for (var h = 0; h < vm.modalidades.length; h++) {
                    var g = vm.modalidades[h];
                    if (g.id == vm.modalidadeSelecionada) {
                        vm.modalidadeSelecionada = g;
                    }
                }

                console.log(vm.modalidadeSelecionada);

                vm.inscricaoAgendado.idCursoNovo = vm.modalidadeSelecionada.idCurso;
                vm.inscricaoAgendado.idModalidade = vm.modalidadeSelecionada.id;
                vm.inscricaoAgendado.nomeModalidade = vm.modalidadeSelecionada.modalidade;
                vm.inscricaoAgendado.idps = vm.modalidadeSelecionada.idps;

                for (var i = 0; i < vm.cursos.length; i++) {
                    var e = vm.cursos[i];
                    if (e.id == vm.modalidadeSelecionada.idCurso) {
                        vm.inscricaoAgendado.curso = e;
                    }
                }

                console.log(vm.inscricaoAgendado);

                if (vm.inscricaoAgendado.id !== null) {
                    $http.post("/api/nova-inscricao-agendada-update", vm.inscricaoAgendado)
                        .success(function(result) {
                            $scope.$emit('vestibularApp:inscricaoAgendadoUpdate', result);
                            $uibModalInstance.close(result);
                            vm.isSaving = false;
                        }).error(function(status) {
                            console.log(status);
                            vm.isSaving = false;
                        })
                } else {
                    $http.post("/api/nova-inscricao-agendada", vm.inscricaoAgendado)
                        .success(function(result) {
                            $scope.$emit('vestibularApp:inscricaoAgendadoUpdate', result);
                            $uibModalInstance.close(result);
                            vm.isSaving = false;
                        }).error(function(status) {
                            console.log(status);
                            vm.isSaving = false;
                        })
                }
            }
        }

        // function onSaveSuccess(result) {
        //     $scope.$emit('vestibularApp:inscricaoAgendadoUpdate', result);
        //     $uibModalInstance.close(result);
        //     vm.isSaving = false;
        // }

        // function onSaveError() {
        //     vm.isSaving = false;
        // }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();