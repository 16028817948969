(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('TurmaDisciplinaDetailController', TurmaDisciplinaDetailController);

    TurmaDisciplinaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TurmaDisciplina'];

    function TurmaDisciplinaDetailController($scope, $rootScope, $stateParams, previousState, entity, TurmaDisciplina) {
        var vm = this;

        vm.turmaDisciplina = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:turmaDisciplinaUpdate', function(event, result) {
            vm.turmaDisciplina = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
