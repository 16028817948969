(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PainelAdministrativoController', PainelAdministrativoController);

    PainelAdministrativoController.$inject = ['$state', 'LoginService', '$http', 'Principal', 'Auth', 'BuscaAgendadoPainelService', 'BuscaTradicionalPainelService', 'CursoModalidade',
        'BuscaIdAreaCursoTradService', 'Polo', 'Curso', 'ProcessoSeletivo', 'CidadesJsonService', 'InscricaoAgendadoUser', 'ProvaOnlineCompl', 'ProvaOnline', 'BuscaIdpsNovaProva'
    ];

    function PainelAdministrativoController($state, LoginService, $http, Principal, Auth, BuscaAgendadoPainelService, BuscaTradicionalPainelService, CursoModalidade,
        BuscaIdAreaCursoTradService, Polo, Curso, ProcessoSeletivo, CidadesJsonService, InscricaoAgendadoUser, ProvaOnlineCompl, ProvaOnline, BuscaIdpsNovaProva) {
        var vm = this;

        // variables
        vm.account = null;
        vm.aprovado = false;
        vm.loaded = false;
        vm.edit = false;
        vm.inscIncompleta = false;
        vm.provaPendente = false;
        vm.naoTemProva = false;
        vm.load = false;

        vm.resultadoPlay = null;
        vm.resultadoAgendado = null;

        vm.cursos = [];

        vm.escolaridade = [{
                value: "1º ano do ensino médio",
                descricao: "Cursando 1º Ano"
            },
            {
                value: "2º ano do Ensino Médio",
                descricao: "Cursando 2º Ano"
            },
            {
                value: "3º ano do Ensino Médio",
                descricao: "Cursando 3º Ano"
            },
            {
                value: "4º ano do Ensino Médio",
                descricao: "Cursando 4º Ano"
            },
            {
                value: "Já conclui o Ensino Médio",
                descricao: "Ensino Médio Concluído"
            },
        ];

        // functions
        vm.logout = sair;
        vm.alterInfo = alterInfo;
        vm.fecharAlert = fecharAlert;
        vm.saveInscricao = save;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        //sessionStorage
        vm.ip = JSON.parse(sessionStorage.getItem('ip-user-vestibular'));
        console.log("IP ATUAL:" + vm.ip);
        setTimeout(getIpUser(), 1000);


        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                //console.log(vm.account);
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.account.authorities.length == 1) {
                    loadAgendado();
                    loadTrad();
                    vm.load = false;
                } else {
                    vm.load = false;
                }

                vm.tipoPainel = JSON.parse(sessionStorage.getItem('tipo-painel'));
                console.log("Painel:" + vm.tipoPainel);

                if (vm.account.authorities.includes('ROLE_PROFESSOR')) {
                    vm.tipoPainel = 'PROFESSOR';
                } else {
                    if (vm.account.authorities.includes('ROLE_ADMIN')) {
                        vm.tipoPainel = 'ADMIN';
                    } else {
                        if (vm.tipoPainel == null || vm.tipoPainel == undefined) {
                            vm.tipoPainel = 'AGENDADO';
                        }
                    }
                }
            });
        }

        // validação de liberação de botão
        vm.dataAtual = new Date();
        //console.log(vm.dataAtual);

        vm.dataResultado = new Date(2023, 10, 21, 1, 0, 0);
        //console.log(vm.dataResultado);

        vm.dataProvaValida = new Date(2023, 10, 17, 16, 0, 0);
        //console.log(vm.dataProvaValida);

        if (vm.dataAtual.getTime() >= vm.dataResultado.getTime()) {
            vm.liberaResultado = true;
        } else {
            vm.liberaResultado = false;
        }
        //console.log(vm.liberaResultado);


        if (vm.dataAtual.getTime() >= vm.dataProvaValida.getTime()) {
            vm.liberaPosProva = true;
        } else {
            vm.liberaPosProva = false;
        }
        //console.log(vm.liberaPosProva);


        function getIpUser() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                }, function(status) {
                    return status;
                });
            }
        };

        function loadAgendado() {
            vm.obj = {
                'email': vm.account.login,
                'celular': null,
                'cpf': null,
                'codigo': null,
                'processo': null,
                'idps': null,
                'idPolo': null
            };

            //console.log(vm.obj);


            BuscaAgendadoPainelService.query(vm.obj,
                function(data) {
                    // console.log("agendadoPainel: ");
                    // console.log(data);
                    if (data.provas != null && data.provas.length != 0) {
                        for (var index = 0; index < data.provas.length; index++) {
                            var element = data.provas[index];
                            if (element.tipo == 'AGENDADO' || element.tipo == 'PRESENCIAL' || element.tipo == 'TRADICIONAL') {
                                if (
                                    element.situacao == 'PENDENTE' ||
                                    element.situacao == 'ANONIMA' ||
                                    element.situacao == 'PRESENCIAL' ||
                                    element.situacao == 'ANDAMENTO' ||
                                    element.situacao == 'APROVADO' ||
                                    element.situacao == 'APROVADOONLINE' ||
                                    element.situacao == 'REPROVADOONLINE'
                                ) {
                                    vm.provaPendente = true;
                                }
                            }
                            if (data.inscricaoAgendado.provaOnline) {
                                var b = new Date();
                                var c = new Date(element.dataAlteracao);
                                b.setHours(b.getHours() - 2);
                                if (c <= b) {
                                    if (element.situacao == "ANDAMENTO") {
                                        element.situacao = "REPROVADO";
                                        element.situacaoObj = "REPROVADO";
                                        element.obs = "FINALIZADO POIS FICOU MAIS DE 2 HORAS SEM ACESSAR APÓS INICIAR A PROVA";
                                        ProvaOnline.update(element,
                                            function(r) {
                                                data.provas[index] = r;
                                            });
                                    }
                                }
                            }
                        }
                    } else {
                        vm.naoTemProva = true;
                    }
                    vm.agendadoPainel = data;
                    vm.agendadoPainel.curso = Curso.get({ id: vm.agendadoPainel.inscricaoAgendado.idCursoNovo });
                    vm.agendadoPainel.polo = Polo.get({ id: vm.agendadoPainel.inscricaoAgendado.polo.id });
                    //console.log(vm.agendadoPainel);
                },
                function(status) {
                    console.log(status);
                });
        }

        function loadTrad() {
            vm.obj = {
                'email': vm.account.login,
                'celular': null,
                'cpf': null,
                'codigo': null,
                'processo': null,
                'idps': null,
                'idPolo': null
            };

            BuscaTradicionalPainelService.query(vm.obj,
                function(data) {
                    for (var i = 0; i < data.inscricao.length; i++) {
                        var a = data.inscricao[i];
                        data.inscricao[i].curso = Curso.get({ id: a.idCurso1 });
                        data.inscricao[i].curso2 = Curso.get({ id: a.idCurso2 });
                        data.inscricao[i].modalidade1 = CursoModalidade.get({ id: a.idModalidadeCurso1 });
                        data.inscricao[i].modalidade2 = CursoModalidade.get({ id: a.idModalidadeCurso2 });
                        data.inscricao[i].polo = Polo.get({ id: a.polo });
                        data.inscricao[i].processo = ProcessoSeletivo.get({ id: a.idProcessoSeletivo });
                    }

                    vm.tradicional = data;
                    console.log(vm.tradicional);
                },
                function(status) {
                    console.log(status);
                });
        }

        function sair() {
            vm.resultadoPlay = null;
            vm.resultadoAgendado = null;
            vm.agendadoPainel = null;
            Auth.logout();
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        /* Referente ao sistema de mensagens */
        function showMessage(css, msg) {

            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var z = document.getElementById("error-message");
            var message = '<div class="box-ajuda-text div-alert-error ' + css + '">' + msg + '</div>';
            x.innerHTML = message;

            if (css == 'invi') {
                x.style.display = "none";
                y.style.display = "none";
                z.style.display = "none";
            } else {
                x.style.display = "block";
                y.style.display = "block";
                x.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        vm.realizarProva = function(prova) {
            sessionStorage.setItem('prova-online', JSON.stringify(prova));
            $state.go('termoProvaOnline');
        }

        vm.verResultado = function(processo, p) {
            vm.obj = {
                'email': null,
                'celular': null,
                'cpf': null,
                'codigo': vm.agendadoPainel.inscricaoAgendado.codPessoa,
                'processo': null,
                'idps': p.idps,
                'idPolo': vm.agendadoPainel.inscricaoAgendado.polo.id
            }

            //console.log(vm.obj);

            $http.post("/api/get-resultado-by-info", vm.obj)
                .success(function(data) {
                    vm.resultado = data;
                    vm.abreModal(vm.resultado);
                })
                .error(function(status) {
                    if (status == null || status == "") {
                        showMessage('text-error', 'Desculpe, não foi possível encontrar seu resultado, por favor entre em contato com a Univel para solucionar.')
                    }
                });
        }

        vm.verResultadoTradicional = function(inscricao) {

            var hoje = new Date();
            var date = new Date(inscricao.dataProva.substring(0, 4), inscricao.dataProva.substring(5, 7) - 1, inscricao.dataProva.substring(8, 10));
            // console.log(hoje);
            // console.log(date);
            if (hoje < date) {
                showMessage('text-error', 'Desculpe, não é possível exibir o resultado antes da realização da prova.')
            } else {
                vm.obj = {
                    'email': null,
                    'celular': null,
                    'cpf': null,
                    'codigo': inscricao.codPessoa,
                    'processo': "TRADICIONAL",
                    'idps': inscricao.idps,
                    'idPolo': 1
                }

                vm.resultadoTradicional = {};
                vm.resultadoTradicional.inscricao = inscricao;

                $http.post("/api/busca-resultado-tradicional-2022", vm.obj)
                    .success(function(data) {
                        //console.log(data);
                        if (data.treineiro.includes("T")) {
                            data.treineiro = true;
                        } else {
                            data.treineiro = false;
                        }
                        data.processo = 'TRADICIONAL';
                        vm.resultadoTradicional.resultado = data;
                        vm.abreModal(vm.resultadoTradicional);
                    })
                    .error(function(status) {
                        if (status == null || status == "") {
                            showMessage('text-error', 'Desculpe, não foi possível encontrar seu resultado, por favor entre em contato com a Univel para solucionar.')
                        }
                    });
            }



            //}
        }

        vm.abreModal = function(dados) {
            if (dados.resultado.linguaPortuguesa == null) {
                dados.resultado.linguaPortuguesa = 0;
            };
            if (dados.resultado.matematica == null) {
                dados.resultado.matematica = 0;
            };
            if (dados.resultado.biologia == null) {
                dados.resultado.biologia = 0;
            };
            if (dados.resultado.fisica == null) {
                dados.resultado.fisica = 0;
            };
            if (dados.resultado.quimica == null) {
                dados.resultado.quimica = 0;
            };
            if (dados.resultado.geografica == null) {
                dados.resultado.geografica = 0;
            };
            if (dados.resultado.historia == null) {
                dados.resultado.historia = 0;
            };
            if (dados.resultado.redacao == null) {
                dados.resultado.redacao = 0;
            };
            if (dados.resultado.pontuacao == null) {
                dados.resultado.pontuacao = 0;
            };

            vm.dados = dados;
            console.log(vm.dados);


            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.setAttribute('data-target', '#modalAgendado');
            a.setAttribute('data-toggle', 'modal');
            a.click();

            vm.modal = true;

        }

        vm.matricular = function(processo, p) {
            /**
             *  NOVA FASE
             *  temos varias provas aparecendo de varios IDPS
             *  quando clica em matricular, busca o IDPS atual do curso independente;
             *  manda direto pra matricula já validada a situação;
             */

            var dados = {};
            dados.codPessoa = vm.agendadoPainel.inscricaoAgendado.codPessoa;
            dados.codVestibular = vm.agendadoPainel.inscricaoAgendado.id;
            dados.codPolo = vm.agendadoPainel.inscricaoAgendado.polo.id;
            dados.curso1 = vm.agendadoPainel.curso;
            dados.processo = processo;
            dados.codCursoRm = vm.agendadoPainel.curso.codCursoRm;
            dados.prova = p.id;
            CursoModalidade.get({ id: vm.agendadoPainel.inscricaoAgendado.idModalidade },
                function(result) {
                    dados.modalidade1 = result;
                    //console.log(dados);
                    // aqui vamos buscar um cursoProcessoSeletivo habilitado para o curso do Aluno
                    $http.get("/api/get-idareainteresse-curso-novo/" + result.idCurso + "/" + result.modalidade)
                        .success(function(data) {
                            //console.log(data);
                            if (data.situacao.includes("ENCERRADO")) {
                                showMessage('text-blue', 'Consulte a disponibilidade do curso com seu consultor ou vestibular no Whatsapp.');
                            } else if (data.situacao.includes("AGUARDANDO")) {
                                showMessage('text-blue', 'O período de matrículas para o curso ainda não iniciou. Qualquer dúvida, entre em contato com seu consultor.');
                            } else {
                                dados.idps = data.idps;
                                dados.idAreaInteresse = data.idAreaInteresse;
                                dados.nomeCursoRm = data.nomeRm;
                                sessionStorage.setItem('cod-pessoa', JSON.stringify(dados));
                                redirectMatric();
                            }
                        }, function(status) {
                            showMessage('text-error', "não foi possivel recuperar os dados do curso requerido para algum processo seletivo atual");
                        });
                },
                function(status) {
                    showMessage('text-error', "não foi possivel recuperar os dados do curso requerido para algum processo seletivo atual");
                });
        }

        vm.matricularTradicional = function() {
            var dados = vm.resultadoTradicional.resultado;
            showMessage('text-blue', 'Buscando dados, por favor aguarde.');
            if (dados != null) {
                showMessage('text-blue', 'Validando resultados, por favor aguarde.');
                if (dados.resultado.includes("DESCLASSIFICADO")) {
                    showMessage('text-blue', 'Não é possível realizar a matrícula sem uma classificação.');
                } else {
                    showMessage('text-blue', 'Identificando processo seletivo, por favor aguarde.');
                    dados.codPessoa = vm.resultadoTradicional.inscricao.codPessoa;
                    dados.codVestibular = vm.resultadoTradicional.inscricao.id;
                    dados.codPolo = vm.resultadoTradicional.inscricao.polo.id;
                    dados.processo = "TRADICIONAL";
                    dados.dataNascimento = vm.resultadoTradicional.inscricao.dataNascimento;
                    dados.cidadeNatal = vm.resultadoTradicional.inscricao.cidadeNatal;
                    dados.estadoNatal = vm.resultadoTradicional.inscricao.estadoNatal;
                    dados.instituicaoConclusao = vm.resultadoTradicional.inscricao.instituicaoConclusao;

                    showMessage('text-blue', 'Validando cursos por favor aguarde.');
                    /* AQUI TEM QUE ATUALIZAR PRA PASSAR A MODALIDADE OU O CODCURSORM*/
                    var validaCurso1 = null;
                    $http.get("/api/get-idareainteresse-curso-novo-trad/" + vm.resultadoTradicional.inscricao.modalidade1.idCurso + "/" + vm.resultadoTradicional.inscricao.modalidade1.modalidade)
                        .success(function(data) {
                            validaCurso1 = data;
                            if (validaCurso1 != null) {
                                if (validaCurso1.id != null) {
                                    if (validaCurso1.situacao == "ENCERRADO") {
                                        showMessage('text-blue', 'Consulte a disponibilidade do curso ' + validaCurso1.nomeRm + ' com seu consultor ou vestibular no Whatsapp.');
                                        validaCurso1 = "ENCERRADO";
                                    } else if (validaCurso1.situacao == "AGUARDANDO") {
                                        showMessage('text-blue', 'O período de matrículas para o curso ' + validaCurso1.nomeRm + ' ainda não iniciou. Qualquer dúvida, entre em contato com seu consultor.');
                                        validaCurso1 = "AGUARDANDO";
                                    } else {
                                        dados.curso1 = vm.resultadoTradicional.inscricao.curso;
                                        dados.idAreaInteresse1 = validaCurso1.idAreaInteresse;
                                        dados.nomeCursoRm1 = validaCurso1.nomeRm;
                                        dados.idps = validaCurso1.idps;
                                        dados.modalidade1 = validaCurso1;
                                        validaCurso1 = "OK";
                                    }
                                } else {
                                    showMessage('text-error', 'Não localizamos uma oferta do curso ' + validaCurso1.nomeRm + ' para o processo seletivo atual.');
                                    validaCurso1 = "ERRO";
                                }
                            } else {
                                showMessage('text-error', 'Não localizamos uma oferta do curso ' + validaCurso1.nomeRm + ' para o processo seletivo atual.');
                                validaCurso1 = "ERRO";
                            }
                            console.log(validaCurso1);
                        }, function(status) {
                            showMessage('text-error', "não foi possivel recuperar os dados do curso requerido para algum processo seletivo atual");
                        });


                    /* AQUI TEM QUE ATUALIZAR PRA PASSAR A MODALIDADE OU O CODCURSORM*/
                    var validaCurso2 = null;
                    $http.get("/api/get-idareainteresse-curso-novo-trad/" + vm.resultadoTradicional.inscricao.modalidade2.idCurso + "/" + vm.resultadoTradicional.inscricao.modalidade2.modalidade)
                        .success(function(data) {
                            validaCurso2 = data;
                            if (validaCurso2 != null) {
                                if (validaCurso2.id != null) {
                                    if (validaCurso2.situacao == "ENCERRADO") {
                                        showMessage('text-blue', 'Consulte a disponibilidade do curso ' + validaCurso2.nomeRm + ' com seu consultor ou vestibular no Whatsapp.');
                                        validaCurso2 = "ENCERRADO";
                                    } else if (validaCurso2.situacao == "AGUARDANDO") {
                                        showMessage('text-blue', 'O período de matrículas para o curso ' + validaCurso2.nomeRm + ' ainda não iniciou. Qualquer dúvida, entre em contato com seu consultor.');
                                        validaCurso2 = "AGUARDANDO";
                                    } else {
                                        dados.curso2 = vm.resultadoTradicional.inscricao.curso2;
                                        dados.idAreaInteresse2 = validaCurso2.idAreaInteresse;
                                        dados.nomeCursoRm2 = validaCurso2.nomeRm;
                                        dados.idps = validaCurso2.idps;
                                        dados.modalidade2 = validaCurso2;
                                        validaCurso2 = "OK";
                                    }
                                } else {
                                    showMessage('text-error', 'Não localizamos uma oferta do curso ' + validaCurso2.nomeRm + ' para o processo seletivo atual.');
                                    validaCurso2 = "ERRO";
                                }
                            } else {
                                showMessage('text-error', 'Não localizamos uma oferta do curso ' + validaCurso2.nomeRm + ' para o processo seletivo atual.');
                                validaCurso2 = "ERRO";
                            }
                            console.log(validaCurso2);
                        }, function(status) {
                            showMessage('text-error', "não foi possivel recuperar os dados do curso requerido para algum processo seletivo atual");
                        });


                    setTimeout(function() {
                        console.log("Timeout");
                        if (dados.curso1 == null || dados.curso1 == undefined) {
                            if (dados.curso2 == null || dados.curso2 == undefined) {
                                showMessage('text-warning', 'Não localizamos uma oferta dos cursos da inscrição  para o processo seletivo atual.');
                            } else {
                                dados.curso1 = dados.curso2;
                                dados.curso2 = null;
                            }
                        }
                        showMessage('invi', '');
                        //console.log(dados);
                        sessionStorage.setItem('cod-pessoa', JSON.stringify(dados));
                        redirectMatric();
                    }, 6000);
                }
            }
        }

        function redirectMatric() {
            //$state.go('new-matricula-inicio');
            $state.go('pre-matricula-dados-cadastrais-candidato');
        }

        vm.novaProva = function(inscricaoAgendado) {

            BuscaIdpsNovaProva.query(inscricaoAgendado, function(inscIdpsNovaProva) {
                var idpsNovaProva = inscIdpsNovaProva.idps;
                //console.log(idpsNovaProva);

                if (idpsNovaProva != inscricaoAgendado.idps) {
                    inscricaoAgendado.idps = idpsNovaProva;
                    InscricaoAgendadoUser.update(inscricaoAgendado, function(result) {
                        vm.agendadoPainel.inscricaoAgendado = result;
                    }, function(status) {
                        showMessage('text-error', 'Desculpe, não foi possível atualizar sua inscrição, por favor entre em contato com a Univel para solucionar.');

                    });
                }

                var prova = {
                    id: null,
                    inscricao: inscricaoAgendado.id,
                    email: inscricaoAgendado.email,
                    telefone: inscricaoAgendado.telefone,
                    cpf: inscricaoAgendado.cpf,
                    nota: 0,
                    situacao: 'ANONIMA',
                    nome: inscricaoAgendado.nome,
                    tempo: 3600,
                    questao: 1,
                    tempoObj: 0,
                    situacaoObj: 'ANONIMA',
                    notaObj: 0,
                    tipo: 'AGENDADO',
                    notaGeral: 0,
                    idps: idpsNovaProva,
                    senha: inscricaoAgendado.cpf
                }

                ProvaOnline.save(prova, function(r2) {
                    vm.naoTemProva = false;
                    loadAgendado();
                }, function(status) {
                    console.log(status);
                    showMessage('text-warning', 'Não foi possível criar uma prova nova, entre em contato com a Univel para solucionar.');
                });


            }, function(status) {
                showMessage('text-error', 'Desculpe, não foi possível localizar o idps da nova prova, por favor entre em contato com a Univel para solucionar.');
            });


        }

        vm.provaOnline = function() {

            var result = vm.agendadoPainel.inscricaoAgendado;

            var prova = {
                id: null,
                inscricao: result.id,
                email: result.email,
                telefone: result.telefone,
                cpf: result.cpf,
                nota: 0,
                situacao: 'ANONIMA',
                nome: result.nome,
                tempo: 3600,
                questao: 1,
                tempoObj: 0,
                situacaoObj: 'ANONIMA',
                notaObj: 0,
                tipo: 'AGENDADO',
                notaGeral: 0,
                idps: result.idps,
                senha: result.cpf
            }

            ProvaOnline.save(prova, function(r2) {
                //console.log(vm.naoTemProva);
                vm.naoTemProva = false;
                //console.log(vm.naoTemProva);
                loadAgendado();
            }, function(status) {
                console.log(status);
            });
        }

        vm.transporte = function() {

            var dados = {
                codigo: vm.tradicional.inscricao.codigo,
                telefone1: vm.tradicional.inscricao.telefone1,
                email: vm.tradicional.inscricao.email
            }

            sessionStorage.setItem("dados-confirma-transporte", JSON.stringify(dados));
            $state.go("confirma-transporte-tradicional");
        }

        vm.emiteComparecimento = function(i) {
            vm.load = true;
            $http.post('api/get-play-declaracao/', {
                    codigo: i.codigo,
                    idps: i.idps,
                    nome: i.nome,
                    cpf: i.cpf,
                    documento: i.documento
                }, {
                    responseType: 'arraybuffer'
                })
                .success(function(data) {
                    //console.log(data);
                    var file = new Blob([(data)], {
                        type: 'application/pdf'
                    });
                    var fileURL = URL.createObjectURL(file);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    a.href = fileURL;
                    a.download = i.nome + " - DeclaracaoComparecimento.pdf";
                    a.click();

                    window.URL.revokeObjectURL(fileURL);

                    vm.load = false;

                }).error(function(status) {
                    vm.load = false;
                    showMessage("text-error", "Não foi localizada declaração para sua inscrição, entre em contato com seu consultor.");
                });

        }

        // vm.teste = function() {
        //     vm.load = true;
        //     $http.post('api/teste-email-inscricao-agendada', vm.agendadoPainel.inscricaoAgendado)
        //         .success(function(data) {
        //             console.log(data);
        //         }).error(function(status) {
        //             vm.load = false;
        //             console.log(status);
        //         });
        // }

        vm.getPass = function() {
            vm.load = true;
            $http.post('api/get-play-pass/', vm.tradicional.inscricao, { responseType: 'arraybuffer' })
                .success(function(data) {
                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = "UNIVELPASS-" + vm.tradicional.inscricao.codigo;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                    vm.load = false;
                }).error(function(status) {
                    vm.load = false;
                    console.log(status);
                });
        }

        vm.getVip = function(i) {
            vm.load = true;
            i.polo = i.polo.id;
            console.log(i);
            $http.post('api/get-play-vipcard/', i, { responseType: 'arraybuffer' })
                .success(function(data) {
                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = "UNIVELVIPCARD-" + i.codigo;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                    vm.load = false;
                }).error(function(status) {
                    vm.load = false;
                    console.log(status);
                });
        }

        function save() {
            vm.edit = false;
            //console.log(vm.edit);
            var valida = validaDados();

            if (valida == "OK") {
                vm.agendadoPainel.provaOnlineCompl.colegio = vm.instituicaoSelected.nome;
                InscricaoAgendadoUser.update(vm.agendadoPainel.inscricaoAgendado,
                    function(a) {
                        vm.agendadoPainel.inscricaoAgendado = a;
                    },
                    function(d) {
                        //console.log(d);
                        showMessage('text-error', 'Não foi possível salvar sua inscricao, por favor entre em contato com vestibular@univel.br');
                    });
                ProvaOnlineCompl.update(vm.agendadoPainel.provaOnlineCompl,
                    function(b) {
                        vm.agendadoPainel.provaOnlineCompl = b;
                    },
                    function(c) {
                        //console.log(d);
                        showMessage('text-error', 'Não foi possível salvar os dados da inscricao, por favor entre em contato com vestibular@univel.br');
                    });
            } else {
                showMessage('text-error', valida);
            }
        }

        function validaDados() {
            if (vm.agendadoPainel.inscricaoAgendado.polo == null || vm.agendadoPainel.inscricaoAgendado.polo == "") {
                return "Por favor, selecione o polo que deseja estudar.";
            }
            if (vm.agendadoPainel.inscricaoAgendado.curso == null || vm.agendadoPainel.inscricaoAgendado.curso == "") {
                return "Por favor, selecione o curso que deseja estudar.";
            }
            if (vm.agendadoPainel.provaOnlineCompl.estado == null || vm.agendadoPainel.provaOnlineCompl.estado == "") {
                return "Por favor, selecione o estado que você mora.";
            }

            if (vm.agendadoPainel.provaOnlineCompl.cidade == null || vm.agendadoPainel.provaOnlineCompl.cidade == "") {
                return "Por favor, selecione a cidade que você mora.";
            }
            if (vm.instituicaoSelected == null || vm.instituicaoSelected == "") {
                return "Por favor, selecione o colégio em que estuda.";
            }

            if (vm.agendadoPainel.provaOnlineCompl.escolaridade == null || vm.agendadoPainel.provaOnlineCompl.escolaridade == "") {
                return "Por favor, selecione seu grau de escolaridade.";
            }


            return 'OK';
        }

        vm.alterInscPlay = function() {
            vm.edit = true;
            console.log(vm.edit);
        }

        function alterInfo(opcao, i) {
            if (opcao == 1) {
                vm.cursos = [];
                vm.agendadoPainel.inscricaoAgendado.polo = i;
                if (vm.agendadoPainel.inscricaoAgendado.polo.id != 1) {
                    for (var x = 0; x < vm.initCursos.length; x++) {
                        var c = vm.initCursos[x];
                        if (c.nome.includes("EAD")) {
                            vm.cursos.push(c);
                        }
                    }
                } else {
                    vm.cursos = vm.initCursos;
                }
            }

            if (opcao == 2) {
                vm.agendadoPainel.inscricaoAgendado.curso = i;
            }

            if (opcao == 3) {
                vm.estado = i;
                vm.agendadoPainel.provaOnlineCompl.estado = i.sigla;
                vm.cidades = i.cidades;
                loadCidadesByJSON();
            }

            if (opcao == 4) {
                vm.agendadoPainel.provaOnlineCompl.cidade = i;
            }

            if (opcao == 5) {
                vm.agendadoPainel.provaOnlineCompl.escolaridade = i.value;
            }
        }

        function loadCidadesByJSON() {
            //vm.estados = CidadesJsonService;
        }
        /** REFERENTE AO MD AUTOCOMPLETE */

        vm.querySearch = querySearch;
        vm.selectedItemChange = selectedItemChange;
        vm.searchTextChange = searchTextChange;

        function querySearch(query, array) {

            var results = query ? array.filter(createFilterFor(query)) : array,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() {
                    deferred.resolve(results);
                }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChange(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChange(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterFor(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {
                return state.nome.includes(lowercaseQuery);
            };

        }
    }
})();