(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('relatorio-extensao-file', {
            parent: 'entity',
            url: '/relatorio-extensao-file',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'RelatorioExtensaoFiles'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/relatorio-extensao-file/relatorio-extensao-files.html',
                    controller: 'RelatorioExtensaoFileController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('relatorio-extensao-file-detail', {
            parent: 'relatorio-extensao-file',
            url: '/relatorio-extensao-file/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'RelatorioExtensaoFile'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/relatorio-extensao-file/relatorio-extensao-file-detail.html',
                    controller: 'RelatorioExtensaoFileDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'RelatorioExtensaoFile', function($stateParams, RelatorioExtensaoFile) {
                    return RelatorioExtensaoFile.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'relatorio-extensao-file',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('relatorio-extensao-file-detail.edit', {
            parent: 'relatorio-extensao-file-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/relatorio-extensao-file/relatorio-extensao-file-dialog.html',
                    controller: 'RelatorioExtensaoFileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['RelatorioExtensaoFile', function(RelatorioExtensaoFile) {
                            return RelatorioExtensaoFile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('relatorio-extensao-file.new', {
            parent: 'relatorio-extensao-file',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/relatorio-extensao-file/relatorio-extensao-file-dialog.html',
                    controller: 'RelatorioExtensaoFileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                idRelatorio: null,
                                evidencia: null,
                                evidenciaContentType: null,
                                createdDate: null,
                                createdUser: null,
                                tamanho: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('relatorio-extensao-file', null, { reload: 'relatorio-extensao-file' });
                }, function() {
                    $state.go('relatorio-extensao-file');
                });
            }]
        })
        .state('relatorio-extensao-file.edit', {
            parent: 'relatorio-extensao-file',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/relatorio-extensao-file/relatorio-extensao-file-dialog.html',
                    controller: 'RelatorioExtensaoFileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['RelatorioExtensaoFile', function(RelatorioExtensaoFile) {
                            return RelatorioExtensaoFile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('relatorio-extensao-file', null, { reload: 'relatorio-extensao-file' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('relatorio-extensao-file.delete', {
            parent: 'relatorio-extensao-file',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/relatorio-extensao-file/relatorio-extensao-file-delete-dialog.html',
                    controller: 'RelatorioExtensaoFileDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['RelatorioExtensaoFile', function(RelatorioExtensaoFile) {
                            return RelatorioExtensaoFile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('relatorio-extensao-file', null, { reload: 'relatorio-extensao-file' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
