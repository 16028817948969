(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('GamePlayController', GamePlayController);

    GamePlayController.$inject = ['$timeout', '$scope', '$rootScope', '$state', 'OfinicasPlayService', 'ChecksGamePlayService', 'SetGameCheckModalService'];

    function GamePlayController($timeout, $scope, $rootScope, $state, OfinicasPlayService, ChecksGamePlayService, SetGameCheckModalService) {

        var vm = this;
        vm.consultar = consultar;
        vm.novo = novo;

        vm.etapa = 2;
        vm.oficinasA = [];
        vm.oficinasB = [];
        vm.oficinasC = [];
        vm.oficinasD = [];
        vm.oficinasCT = [];
        vm.oficinasAUD = [];


        consultar();

        /*function novo() {
            SetGameCheckModalService.open(vm.codigo);
        }*/

        function consultar() {
            vm.oficinasA = [];
            vm.oficinasB = [];
            vm.oficinasC = [];
            vm.oficinasD = [];
            vm.oficinasCT = [];
            vm.oficinasAUD = [];
            OfinicasPlayService.query(function(data) {
                    vm.oficinas = data;

                    for (var i = 0; i < vm.oficinas.length; i++) {
                        var element = vm.oficinas[i];

                        if (element.id_area == 1) {
                            vm.oficinasA.push(element);
                        }

                        if (element.id_area == 2) {
                            vm.oficinasB.push(element);
                        }

                        if (element.id_area == 3) {
                            vm.oficinasC.push(element);
                        }

                        if (element.id_area == 4) {
                            vm.oficinasD.push(element);
                        }

                        if (element.id_area == 5) {
                            vm.oficinasCT.push(element);
                        }

                        if (element.id_area == 6) {
                            vm.oficinasAUD.push(element);
                        }

                    }
                },
                function(status) {
                    console.log(status);
                });
        }
    }
})();