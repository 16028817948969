(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConfirmacaoInscricaoTradicionalController', ConfirmacaoInscricaoTradicionalController);

    ConfirmacaoInscricaoTradicionalController.$inject = ['$timeout', 'DateUtils', '$scope', '$state', '$stateParams', '$sessionStorage', '$uibModalInstance', 'PolosAtivos'];

    function ConfirmacaoInscricaoTradicionalController($timeout, DateUtils, $scope, $state, $stateParams, $sessionStorage, $uibModalInstance, PolosAtivos) {
        var vm = this;
        vm.redirect = redirect;

        vm.inscricao = JSON.parse(sessionStorage.getItem('inscricao'));

        function redirect() {
            sessionStorage.removeItem('prova-agendado');
            sessionStorage.removeItem('inscricao-agendado');
            $uibModalInstance.close(null);
        }
    }
})();