(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('SessaoProvaOnlineService', SessaoProvaOnlineService);

    SessaoProvaOnlineService.$inject = ['$resource', 'DateUtils'];

    function SessaoProvaOnlineService($resource, DateUtils) {
        var resourceUrl = '/api/busca-sessao-prova-online';

        return $resource(resourceUrl, {}, {
            'query': { method: 'post', isArray: false }
        });
    }
})();