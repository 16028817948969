(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlanoEnsinoFileDetailController', PlanoEnsinoFileDetailController);

    PlanoEnsinoFileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'PlanoEnsinoFile'];

    function PlanoEnsinoFileDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, PlanoEnsinoFile) {
        var vm = this;

        vm.planoEnsinoFile = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('vestibularApp:planoEnsinoFileUpdate', function(event, result) {
            vm.planoEnsinoFile = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
