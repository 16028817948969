(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('GetProvaAnonima', GetProvaAnonima);

    GetProvaAnonima.$inject = ['$resource', 'DateUtils'];

    function GetProvaAnonima($resource, DateUtils) {
        var resourceUrl = '/api/get-prova-online-anonima';

        return $resource(resourceUrl, {}, {
            'query': { method: 'post', isArray: false }
        });
    }
})();