(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('GetSalaPorCpfService', GetSalaPorCpfService);

    GetSalaPorCpfService.$inject = ['$resource', 'DateUtils'];

    function GetSalaPorCpfService($resource, DateUtils) {
        var resourceUrl = 'api/get-sala-responsavel/:cpf';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }
})();