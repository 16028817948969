(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaAgendadoPainelService', BuscaAgendadoPainelService);

    BuscaAgendadoPainelService.$inject = ['$resource', 'DateUtils'];

    function BuscaAgendadoPainelService($resource, DateUtils) {
        var resourceUrl = '/api/busca-inscricao-agendada-login';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: false }
        });
    }
})();