(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConfirmacaoInscricaoTConfirmacaoMatriculaControllerradicionalUserController', ConfirmacaoInscricaoTConfirmacaoMatriculaControllerradicionalUserController);

    ConfirmacaoInscricaoTConfirmacaoMatriculaControllerradicionalUserController.$inject = ['$timeout', 'DateUtils', '$scope', '$state', '$stateParams', '$sessionStorage'];

    function ConfirmacaoInscricaoTConfirmacaoMatriculaControllerradicionalUserController($timeout, DateUtils, $scope, $state, $stateParams, $sessionStorage) {
        var vm = this;

        vm.redirect = redirect;
        vm.dados = undefined;

        loadPreMatricula();

        function loadPreMatricula() {
            vm.dados = JSON.parse(sessionStorage.getItem('dados-matricula-confirmacao'));
            sessionStorage.removeItem('dados-matricula-confirmacao');
            sessionStorage.removeItem('cod-pessoa');
        }

        function redirect(value) {
            $state.go(value);
        }
    }
})();
