(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('OfinicasPlayService', OfinicasPlayService);

    OfinicasPlayService.$inject = ['$resource', 'DateUtils'];

    function OfinicasPlayService($resource, DateUtils) {
        var resourceUrl = 'api/get-oficinas-game';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true },
        });
    }
})();