(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlanoEnsinoLogDetailController', PlanoEnsinoLogDetailController);

    PlanoEnsinoLogDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PlanoEnsinoLog'];

    function PlanoEnsinoLogDetailController($scope, $rootScope, $stateParams, previousState, entity, PlanoEnsinoLog) {
        var vm = this;

        vm.planoEnsinoLog = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:planoEnsinoLogUpdate', function(event, result) {
            vm.planoEnsinoLog = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
