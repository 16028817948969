(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PainelTemaProvaOnlineController', PainelTemaProvaOnlineController);

    PainelTemaProvaOnlineController.$inject = ['$scope', '$state', '$http', 'BuscaTemasService', 'NovoTemaService', 'DesativaTemaService', 'AtivaTemaService', 'DataUtils'];

    function PainelTemaProvaOnlineController($scope, $state, $http, BuscaTemasService, NovoTemaService, DesativaTemaService, AtivaTemaService, DataUtils) {
        var vm = this;

        vm.adicionar = adicionar;
        vm.desativar = desativar;
        vm.ativar = ativar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        vm.novoTema = {};

        vm.temas = BuscaTemasService.query();

        function adicionar() {
            NovoTemaService.query(vm.novoTema, function(result) {
                vm.temas = result;
                vm.novoTema = {};
            }, function(status) {
                console.log(status);
            });
        }

        function desativar(id) {
            DesativaTemaService.query({ id: id }, function(result) {
                vm.temas = result;
            }, function(status) {
                console.log(status);
            });
        }

        function ativar(id) {
            AtivaTemaService.query({ id: id }, function(result) {
                vm.temas = result;
            }, function(status) {
                console.log(status);
            });
        }

        vm.setImagem = function($file, questaoProvaOnline) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        questaoProvaOnline.imagem = base64Data;
                        questaoProvaOnline.imagemContentType = $file.type;
                    });
                });
            }
        };


    }
})();