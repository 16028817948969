(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('FinalProvaOnlineDeleteController',FinalProvaOnlineDeleteController);

    FinalProvaOnlineDeleteController.$inject = ['$uibModalInstance', 'entity', 'FinalProvaOnline'];

    function FinalProvaOnlineDeleteController($uibModalInstance, entity, FinalProvaOnline) {
        var vm = this;

        vm.finalProvaOnline = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            FinalProvaOnline.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
