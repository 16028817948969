(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('HomeInscricaoTradicionalController', HomeInscricaoTradicionalController);

    HomeInscricaoTradicionalController.$inject = ['$scope', 'Principal', 'LoginService', '$state'];

    function HomeInscricaoTradicionalController($scope, Principal, LoginService, $state) {
        var vm = this;

        vm.login = LoginService.open;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.cadastro = cadastro;
        vm.play = play;
        vm.playover = playover;
        vm.login = LoginService.open;

        function cadastro() {
            $state.go('inscricao-tradicional2019');
        }

        function play() {
            $state.go('play-vestiba');
        }

        vm.agendado = function() {

            $state.go('inscricaoAgendado');
        }

        vm.resultado = function() {
            $state.go('resultado-vestibular');
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function playover() {
            document.getElementsByTagName('body')[0].style.background = 'background: url(./content/images/fundotrad2018.png)';
            document.getElementsByTagName('body')[0].style.backgroundSize = 'cover';
            document.getElementsByTagName('body')[0].style.backgroundAttachment = 'fixed';
            document.getElementsByTagName('body')[0].style.backgroundPositionY = '-70px';
        }

    }
})();