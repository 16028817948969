(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConfirmacaoNovoAgendadoController', ConfirmacaoNovoAgendadoController);

    ConfirmacaoNovoAgendadoController.$inject = ['$timeout', 'DateUtils', '$scope', '$state', '$stateParams', '$sessionStorage', 'LinkVendedorService', '$http'];

    function ConfirmacaoNovoAgendadoController($timeout, DateUtils, $scope, $state, $stateParams, $sessionStorage, LinkVendedorService, $http) {
        var vm = this;

        vm.clear = clear;
        vm.redirect = redirect;

        vm.dados = JSON.parse(sessionStorage.getItem('candidato-vestibular'));
        console.log(vm.dados);



        setaVendedorLink(vm.dados.codPessoaIndicacao);

        function setaVendedorLink(valor) {
            if (valor != null && valor != undefined && valor != "") {
                vm.interno = true;
                vm.vendLink = true;
                LinkVendedorService.query({ codigo: valor }, function(result) {
                    //console.log(result);
                    vm.vendedorLink = result.link;
                    vm.vendedorNumero = result.numero;
                    vm.vendedorEmail = result.email;

                });
            } else {
                vm.interno = false;
                vm.vendLink = false;
                vm.vendedorLink = 'https://wa.me/5545988429444';
                vm.vendedorNumero = "45 98842-9444";
                vm.vendedorEmail = "vestibular@univel.br";
            }
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function redirect(value) {
            $state.go(value);
        }

        vm.imprimeComprovante = function() {
            $http.post('api/get-comprovante-agendado/', vm.dados, { responseType: 'arraybuffer' })
                .success(function(data) {
                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = "univel-vestibular-comprovante-inscricao-" + vm.dados.cpf;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                    vm.load = false;
                }).error(function(status) {
                    vm.load = false;
                    console.log(status);
                });
        }
    }
})();