(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('Users_poloController', Users_poloController);

    Users_poloController.$inject = ['Users_polo'];

    function Users_poloController(Users_polo) {

        var vm = this;

        vm.users_polos = [];

        loadAll();

        function loadAll() {
            Users_polo.query(function(result) {
                vm.users_polos = result;
                vm.searchQuery = null;
            });
        }
    }
})();
