(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RematriculaContratoController', RematriculaContratoController);

    RematriculaContratoController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'RematriculaContratoServiceController', 'Curso', 'LoginService', '$http', '$sessionStorage', 'IntegracaoNormal', 'ConsultaContratoIdPerlet'];

    function RematriculaContratoController($timeout, $scope, $state, $stateParams, RematriculaContratoServiceController, Curso, LoginService, $http, $sessionStorage, IntegracaoNormal, ConsultaContratoIdPerlet) {
        var vm = this;
        vm.avancar = avancar;
        vm.academico = JSON.parse(sessionStorage.getItem('dados-academico'));
        vm.load = false;
        vm.aceitei = false;
        vm.ip = null;

        vm.ip = JSON.parse(sessionStorage.getItem('ip-user-vestibular'));
        console.log("IP ATUAL:" + vm.ip);
        setTimeout(getIpUser(), 1000);

        $http.post("/api/get-contrato-rematricula", vm.academico)
            .success(function(data) {
                vm.clausulas = data;
            })
            .error(function(status) {
                console.log(status);
            });

        function getIpUser() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                }, function(status) {
                    return status;
                });
            }
        };

        vm.getIp = function() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                    avancar();
                }, function(status) {
                    return status;
                });
            } else {
                avancar();
            }
        }

        function avancar() {
            vm.load = true;

            if (vm.academico.opcaoPap == true || vm.academico.opcaoPap == 1) {
                vm.academico.status = "TERMO";
            } else {
                vm.academico.status = "BOLETO";
            }

            if (vm.academico.ipAluno == null) {
                vm.academico.ipAluno = vm.ip;
            }

            /*  aqui validamos o codtipoingresso, se for 9, e estiver dentro do prazo, vai para o termo.  */
            var valida = validaTermo();
            if (vm.academico.tipoIngresso == 9 && valida == 'OPEN') {
                sessionStorage.setItem('dados-academico', JSON.stringify(vm.academico));
                $state.go('rematricula-termo-transf');
            } else {
                vm.isSaving = true;
                sessionStorage.setItem('dados-academico', JSON.stringify(vm.academico));
                //RematriculaContratoServiceController.save(vm.academico, onSaveSuccess, onSaveError);
                if (vm.academico.opcaoPap == true || vm.academico.opcaoPap == 1) {
                    vm.load = false;
                    $state.go('rematriculaPap');
                } else {
                    IntegracaoNormal.query(vm.academico,
                        function(data) {
                            vm.load = false;
                            $state.go('rematriculaBoleto');
                        },
                        function(result) {
                            console.log(result);
                        });
                }
            }
        }

        function onSaveSuccess(result) {}

        function onSaveError(result) {
            vm.load = false;
            console.log(result);
        }

        function validaTermo() {
            // vamos adaptar uma situação aqui
            // digamos que seja da campanha de super transferencia, tem que ter o termo.
            var today = new Date();
            var inicio = new Date('2021', '06', '19', '00', '00', '0000');
            var fim = new Date('2021', '07', '12', '23', '59', '0000');
            var ret = "NC";
            if (today < inicio) {
                ret = "BEFORE";
            } else {
                if (today >= fim) {
                    ret = "AFTER";
                } else {
                    ret = "OPEN";
                }
            }
            return ret;
        }
    }
})();