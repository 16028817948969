(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('NextVendedorPainelService', NextVendedorPainelService);

    NextVendedorPainelService.$inject = ['$resource', 'DateUtils'];

    function NextVendedorPainelService($resource, DateUtils) {
        var resourceUrl = '/api/next-vend-painel/:campanha';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }
})();