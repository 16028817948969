(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConsultaAlunoPlayController', ConsultaAlunoPlayController);

    ConsultaAlunoPlayController.$inject = ['$http', '$scope', '$state', 'DateUtils', 'Principal', 'ConsultaAlunoPlay2018', 'Checkinplay', 'VinculoPulseiraService', 'AlteraPulseiraService', 'Curso', 'TrocaCursoTradService'];

    function ConsultaAlunoPlayController($http, $scope, $state, DateUtils, Principal, ConsultaAlunoPlay2018, Checkinplay, VinculoPulseiraService, AlteraPulseiraService, Curso, TrocaCursoTradService) {

        var vm = this;
        vm.consultar = consultar;
        vm.checkin = check;
        vm.vincular = vincular;
        vm.getPass = getPass;
        vm.getVip = getVip;
        vm.alterarPulseira = alterarPulseira;
        vm.resetSenha = resetSenha;
        vm.trocaCurso = false;
        vm.habilitaTrocaCurso = habilitaTrocaCurso;
        vm.alterarCursoCand = alterarCursoCand;
        vm.reenviaEmailInsc = reenviaEmailInsc;
        //vm.cursos = Curso.query();

        $http.post("api/get-modalidades-ativas-tradicional")
            .success(function(result) {
                vm.cursos = result;
                console.log(vm.cursos);
            })
            .error(function(status) {
                console.log(status);
            });

        vm.tipo = 'INSCRICAO';
        vm.idpsAtivo = '63';
        vm.idps = '63';


        Principal.identity().then(function(account) {
            vm.user = account.login;
            vm.isAuthenticated = Principal.isAuthenticated;
        });

        function check(cod, idps) {
            var send = {
                inscricao: cod,
                pulseira: cod,
                createdData: null,
                createdUser: vm.user,
                idps: idps,
                localizado: true
            }

            Checkinplay.save(send, function(data) {
                alert("Check Criado com sucesso, id: " + data.id);
            }, function(status) {
                alert("Erro ao criar Check: " + status);
            });
        }

        function vincular(codigo) {
            VinculoPulseiraService.open(codigo);
        }

        function alterarPulseira(i) {
            AlteraPulseiraService.open(i);
        }

        function habilitaTrocaCurso() {
            if (vm.trocaCurso == false) {
                vm.trocaCurso = true;
            } else {
                vm.trocaCurso = false;
            }
        }

        function alterarCursoCand(opcao, modalidade, insc) {

            TrocaCursoTradService.query({
                op: opcao,
                curso: modalidade,
                insc: insc
            }, function(data) {
                vm.result = data;
                vm.trocaCurso = false;
            }, function(status) {
                console.log(status);
            });
        }

        function resetSenha(user) {
            $http.post('api/reset-senha', user)
                .success(function(data) {
                    console.log(data);
                }).error(function(status) {
                    console.log(status);
                });
        }

        function getPass(i) {
            $http.post('api/get-play-pass/', i, { responseType: 'arraybuffer' })
                .success(function(data) {
                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = "UNIVELPASS-" + i.codigo;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }).error(function(status) {
                    console.log(status);
                });
        }

        function getVip(i) {
            vm.load = true;
            i.polo = i.polo.id;
            console.log(i);
            $http.post('api/get-play-vipcard/', i, { responseType: 'arraybuffer' })
                .success(function(data) {
                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = "UNIVELVIPCARD-" + i.codigo;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                    vm.load = false;
                }).error(function(status) {
                    vm.load = false;
                    console.log(status);
                });
        }

        vm.sendRDPost = function(i) {
            $http.post('api/send-rd/', i)
                .success(function(data) {
                    alert("Enviado Com Sucesso");
                }).error(function(status) {
                    console.log(status);
                });
        }

        function reenviaEmailInsc(painel) {
            //console.log(insc);
            $http.post('api/play/reenvia-email-insc', painel)
                .success(function(data) {
                    console.log(data);
                }).error(function(status) {
                    console.log(status);
                });
        }

        function consultar() {
            ConsultaAlunoPlay2018.query({
                tipo: vm.tipo,
                valor: vm.valor,
                idps: vm.idps
            }, function(data) {
                console.log(data);
                vm.result = data;
            }, function(status) {
                console.log(status);
            });
        }

        vm.emiteComparecimento = function(p) {
            $http.post('api/get-play-declaracao/', {
                    codigo: p.codigo,
                    idps: p.idps,
                    nome: p.nome,
                    cpf: p.cpf,
                    documento: p.documento
                }, {
                    responseType: 'arraybuffer'
                })
                .success(function(data) {
                    console.log(data);
                    var file = new Blob([(data)], {
                        type: 'application/pdf'
                    });
                    var fileURL = URL.createObjectURL(file);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    a.href = fileURL;
                    a.download = p.nome + " - DeclaracaoComparecimento.pdf";
                    a.click();

                    window.URL.revokeObjectURL(fileURL);

                    vm.load = false;

                }).error(function(status) {
                    alert("Você não pode emitir a declaração de comparecimento!!");
                });

        }
    }
})();