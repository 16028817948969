(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaHistoricoPlano', BuscaHistoricoPlano);

    BuscaHistoricoPlano.$inject = ['$resource', 'DateUtils'];

    function BuscaHistoricoPlano($resource, DateUtils) {
        var resourceUrl = '/api/busca-hist-plano/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();