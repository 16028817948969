(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('AvaliaPlanoEstagioController', AvaliaPlanoEstagioController);

    AvaliaPlanoEstagioController.$inject = ['$state', 'LoginService', '$http', 'Principal', 'Auth', 'BuscaItensPlanoService', 'BuscaBBPlanoService', 'PlanoEnsinoObs', 'BuscaObsPlanoService', 'PlanoEnsino', 'PlanoEnsinoLog', 'BuscaDiscsPlanoIdService'];

    function AvaliaPlanoEstagioController($state, LoginService, $http, Principal, Auth, BuscaItensPlanoService, BuscaBBPlanoService, PlanoEnsinoObs, BuscaObsPlanoService, PlanoEnsino, PlanoEnsinoLog, BuscaDiscsPlanoIdService) {
        var vm = this;

        vm.plano = {};
        vm.insereObs = insereObs;
        vm.removeObs = removeObs;
        vm.saveObs = saveObs;
        vm.salvarPlano = salvarPlano;
        vm.loading = false;

        vm.bBasica = [];
        vm.bCompl = [];
        vm.bApro = [];

        vm.listaObs = [];
        vm.disciplinasPlano = [];
        vm.obs = {};

        vm.cordenador = false;
        vm.nucleo = false;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.account.authorities.includes("ROLE_COORDENADOR")) {
                    if (vm.account.authorities.includes("ROLE_NUCLEO")) {
                        vm.nucleo = true;
                    } else {
                        vm.cordenador = true;
                    }
                }
                start();
            });
        };

        function start() {
            vm.loading = true;
            var temp = JSON.parse(sessionStorage.getItem('plano-ensino-correcao'));
            console.log(temp);
            BuscaItensPlanoService.query({ id: temp.id },
                function(itens) {
                    for (var i = 0; i < itens.length; i++) {
                        var e = itens[i];

                        if (e.tipo == 'COMPETENCIA') { temp.competencias = e.texto; };
                        if (e.tipo == 'EMENTA') { temp.ementa = e.texto; };
                        if (e.tipo == 'INSTAVA1B') { temp.inst1b = e.texto; };
                        if (e.tipo == 'INSTAVA2B') { temp.inst2b = e.texto; };
                        if (e.tipo == 'BBASICA') { temp.bBasica = e.texto; };
                        if (e.tipo == 'BCOMPL') { temp.bCompl = e.texto; };
                        if (e.tipo == 'BAPRO') { temp.bApro = e.texto; };

                        if (e.tipo == 'CONT1') { temp.cont1 = e.texto; };
                        if (e.tipo == 'MET1') { temp.met1 = e.texto; };

                    };


                    /*BuscaBBPlanoService.query({ id: temp.id }, function(livros) {
                        for (var i = 0; i < livros.length; i++) {
                            var l = livros[i];
                            if (l.tipo == 'BASICA') { vm.bBasica.push(l); };
                            if (l.tipo == 'COMPLEMENTAR') { vm.bCompl.push(l); };
                        }
                    }, function(status) { console.log(status) });
                    */

                    BuscaDiscsPlanoIdService.query({ id: temp.id }, function(turmas) {
                        vm.disciplinasPlano = turmas;
                        //console.log(vm.disciplinasPlano);
                    }, function(status) { console.log(status) });


                    BuscaObsPlanoService.query({ id: temp.id }, function(obs) {
                        for (var h = 0; h < obs.length; h++) {
                            vm.listaObs.push(obs[h]);
                        }
                    }, function(status) { console.log(status) });

                    vm.plano = temp
                    vm.loading = false;
                },
                function(status) { console.log(status); });
        }

        function insereObs(item) {
            vm.obs.tipo = item;
            vm.obs.texto = "Prezado(a) " + vm.plano.professor + ", para o campo " + item + " é necessário:";
            vm.obs.idPlano = vm.plano.id;
            vm.obs.userCriacao = vm.account.login;
            vm.obs.etapa = "PENDENTE";
        }

        function saveObs() {

            if (vm.account == null) {
                alert('Não foi possível recuperar a sessão do usuário, por favor atualize a página para prosseguir.');
            } else {
                PlanoEnsinoObs.save(vm.obs,
                    function(data) {
                        vm.listaObs.push(data);
                        insereLog({
                            idPlano: vm.plano.id,
                            item: "OBS",
                            operacao: "CREATE",
                            valor: data.id + " - " + data.tipo + " - " + data.texto,
                            valorAnt: "",
                            user: vm.account.login
                        });
                    },
                    function(status) { console.log(status); });
            }
        }

        function removeObs(obs) {
            for (var o = 0; o < vm.listaObs.length; o++) {
                var e = vm.listaObs[o];

                if (obs.id == e.id) {
                    vm.listaObs.splice(o, 1);
                }

                PlanoEnsinoObs.delete({ id: obs.id });

                insereLog({
                    idPlano: vm.plano.id,
                    item: "OBS",
                    operacao: "DELETE",
                    valor: obs.id + " - " + obs.tipo + " - " + obs.texto,
                    valorAnt: "",
                    user: vm.account.login
                });
            }
        }


        function salvarPlano(status) {
            var etapaAnt = vm.plano.etapa;
            vm.plano.etapa = status;
            PlanoEnsino.update(vm.plano, function(data) {
                insereLog({
                    idPlano: vm.plano.id,
                    item: "PLANO",
                    operacao: "UPDATE",
                    valor: vm.plano.etapa,
                    valorAnt: etapaAnt,
                    user: vm.account.login
                });
            });
            $state.go('plano-de-ensino');
        }

        function insereLog(log) {
            PlanoEnsinoLog.save(log);
        }
    }
})();