(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('SugestaoNew', SugestaoNew);

    SugestaoNew.$inject = ['$resource', 'DateUtils'];

    function SugestaoNew($resource, DateUtils) {
        var resourceUrl = 'api/sugestaos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.data_envio = DateUtils.convertLocalDateFromServer(data.data_envio);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.data_envio = DateUtils.convertLocalDateToServer(copy.data_envio);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST'
            }
        });
    }
})();