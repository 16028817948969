(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaDpAuthController', MatriculaDpAuthController);

    MatriculaDpAuthController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$http', '$sessionStorage'];

    function MatriculaDpAuthController($timeout, $scope, $state, $stateParams, $http, $sessionStorage) {
        var vm = this;

        vm.load = false;

        // Autenticação
        vm.autentica = autenticar;

        sessionStorage.removeItem('user-matric-dp');

        function autenticar() {
            var infos = {
                'login': vm.username,
                'password': vm.password
            };

            vm.load = true;

            $http.post("/api/login", infos)
                .success(function(data) {
                    console.log(data);
                    if (data.authorized) {
                        vm.load = false;
                        sessionStorage.setItem('user-matric-dp', JSON.stringify(data));
                        $state.go("matriculaDpDisciplinas");
                    } else {
                        alert("Usuário ou senha incorretos, confira seus dados ou tente novamente");
                        vm.load = false;
                    }
                })
                .error(function(status) {
                    console.log(status);
                    vm.load = false;
                    alert("Desculpe não foi possível acessar o servidor, tente novamente mais tarde");
                });
        }
    }
})();