(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('AlteraPulseiraController', AlteraPulseiraController);

    AlteraPulseiraController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', '$sessionStorage', 'param', 'Principal', 'AlteraPulseiraService', 'InscricaoPulseira', 'Inscricao'];

    function AlteraPulseiraController($scope, $rootScope, $state, $timeout, Auth, $uibModalInstance, $sessionStorage, param, Principal, AlteraPulseiraService, InscricaoPulseira, Inscricao) {
        var vm = this;

        vm.fechar = fechar;

        vm.inscPul = {};
        vm.inscPul = param.data.inscricaoPulseira;

        vm.insc = param.data.inscricao;

        console.log(vm.inscPul);
        console.log(vm.insc);

        Principal.identity().then(function(account) {
            vm.user = account.login;
            vm.isAuthenticated = Principal.isAuthenticated;
        });

        vm.salvar = salve;

        function salve() {
            var agora = new Date();

            vm.inscPul.codigoInscricao = vm.pulseira;
            vm.inscPul.codigoPulseira = vm.pulseira;
            vm.inscPul.usuarioAlteracao = vm.user;
            vm.inscPul.dataAlteracao = agora;


            vm.insc.codigo = vm.pulseira;
            Inscricao.update(vm.insc, function(data) {}, function(status) {});

            InscricaoPulseira.update(vm.inscPul, function(data) {
                alert("Alterado com sucesso, id: " + data.id);
                $uibModalInstance.close(null);

            }, function(status) {
                alert("Erro ao alterar: " + status);
                $uibModalInstance.close(null);
            });

        }

        function fechar() {
            $uibModalInstance.close(null);
        }


    }
})();