(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ColegioByInscService', ColegioByInscService);

    ColegioByInscService.$inject = ['$resource', 'DateUtils'];

    function ColegioByInscService($resource, DateUtils) {
        var resourceUrl = 'api/inscricao-colegios-insc/:cod/:idps';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }
})();