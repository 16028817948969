(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaRespostasQuestaoService', BuscaRespostasQuestaoService);

    BuscaRespostasQuestaoService.$inject = ['$resource', 'DateUtils'];

    function BuscaRespostasQuestaoService($resource, DateUtils) {
        var resourceUrl = '/api/busca-respostas-questao/:idQuestao';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();