(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('rematriculaDadosCadastrais', {
            parent: 'app',
            url: '/rematricula-dados-cadastrais',
            data: {
                //authorities: ['ROLE_ADMIN'], 
                pageTitle: 'Rematrícula - Dados Cadastrais'
            },
            views: {
                'content@': {
                    templateUrl: 'app/rematricula/rematricula-dadoscadastrais/rematriculaDadosCadastrais.html',
                    controller: 'RematriculaDadosCadastraisController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();