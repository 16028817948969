(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoColegioController', InscricaoColegioController);

    InscricaoColegioController.$inject = ['$scope', '$state', '$http', 'InscricaoColegio', 'ColegioPlay'];

    function InscricaoColegioController($scope, $state, $http, InscricaoColegio, ColegioPlay) {
        var vm = this;

        vm.inscricaoColegios = [];
        vm.cidadeSelecionada = cidadeSelecionada;
        vm.colegioSelecionado = colegioSelecionado;

        //loadAll();

        function loadAll() {
            InscricaoColegio.query(function(result) {
                vm.inscricaoColegios = result;
            });
        }


        ColegioPlay.query(function(result) {
            vm.colegios = result;
            //console.log(result);
            onload();
        });

        function onload() {
            var arrayT = new Array();

            vm.isSaving = true;
            for (var i = 0; i < vm.colegios.length; i++) {
                const element = vm.colegios[i];

                if (i == 0) {
                    arrayT.push(element);
                } else {
                    var salva = true;
                    for (var a = 0; a < arrayT.length; a++) {
                        const teste = arrayT[a];
                        if (teste.cidade == element.cidade) {
                            salva = false;
                        }
                    }

                    if (salva) {
                        arrayT.push(element);
                    }
                }

            }
            vm.cidades = arrayT;
            vm.isSaving = false;
        }

        function cidadeSelecionada() {
            vm.cidadeSelect = vm.cidadeCol.cidade;
            var array = [];

            vm.isSaving = true;
            for (var i = 0; i < vm.colegios.length; i++) {
                const element = vm.colegios[i];
                if (element.cidade == vm.cidadeSelect) {
                    array.push(element);
                }
            }
            vm.colegiosCidade = array;
            // console.log(vm.colegiosCidade);
        }


        function colegioSelecionado() {
            $http.get('api/play/lista-inscritos-colegio/' + vm.colSelecionado.id + '/' + vm.idpsCol)
                .success(function(data) {
                    var lt = [];

                    for (var i = 0; i < data.length; i++) {
                        var a = data[i];
                        if (vm.turno == "todos") {
                            lt.push(a)
                        } else {
                            if (a.turno == vm.turno) {
                                lt.push(a)
                            }
                        }
                    }

                    vm.lista = lt;
                    vm.total = vm.lista.length;
                }).error(function(status) {
                    console.log(status);
                });
        }


    }
})();