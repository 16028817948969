(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ProvaSalaDialogController', ProvaSalaDialogController);

    ProvaSalaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProvaSala', 'SalasPlayService', 'Principal'];

    function ProvaSalaDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, ProvaSala, SalasPlayService, Principal) {
        var vm = this;

        vm.provaSala = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.salas = SalasPlayService.query();

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.sala = vm.provaSala.codigoSala;
        vm.prova = vm.provaSala.codigoProva;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            vm.provaSala.codigoProva = vm.prova;
            vm.provaSala.codigoSala = vm.sala;
            vm.provaSala.dataCriacao = new Date();
            vm.provaSala.usuarioCriacao = vm.account.login;

            if (vm.provaSala.id !== null) {
                ProvaSala.update(vm.provaSala, onSaveSuccess, onSaveError);
                vm.prova = "";
            } else {
                ProvaSala.save(vm.provaSala, onSaveSuccess, onSaveError);
                vm.prova = "";
            }
        }

        jQuery(document).on('keydown', 'input.insc', function(ev) {
            if (ev.which === 13) {
                this.style.backgroundColor = '#EFF';
                save();
                return false;
            }
        });



        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:provaSalaUpdate', result);
            /*$uibModalInstance.close(result);*/
            vm.prova = "";
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.prova = "";
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.data_criacao = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();