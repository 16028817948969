(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('new-inscricao-tradicional-qr', {
                parent: 'app',
                url: '/inscricao-play-colegio',
                data: {
                    //authorities: ['ROLE_USER'],
                    pageTitle: 'Inscrição Vestibular Tradicional'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/inscricaoTradicional/new_inscricao_qr/insc.html',
                        controller: 'NewInscricaoQrController',
                        controllerAs: 'vm'
                    }
                }
            });
    }

})();