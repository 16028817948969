(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('confirma-transporte-tradicional', {
                parent: 'entity',
                url: '/confirma-transporte-tradicional',
                data: {
                    pageTitle: 'Univel Play - Transporte'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/inscricaoTradicional/confirmaTransporte/confirmaTransporte.html',
                        controller: 'ConfirmaTransporteController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                }
            });
    }

})();