(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('CursoPsDialogController', CursoPsDialogController);

    CursoPsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CursoPs', 'PSAtivos', 'PolosAtivos', 'Curso', 'Principal'];

    function CursoPsDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, CursoPs, PSAtivos, PolosAtivos, Curso, Principal) {
        var vm = this;

        vm.cursoPs = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });


        Principal.identity().then(function(account) {
            vm.account = account;
            vm.isAuthenticated = Principal.isAuthenticated;
        });


        if (vm.cursoPs.id == null) {
            vm.cursoPs.situacao = "ATIVO";
        }


        PSAtivos.query(function(result) {
            vm.processos = result;
        }, function(status) {
            console.log(status);
        });

        PolosAtivos.query(function(result) {
            vm.polos = result;
        }, function(status) {
            console.log(status);
        });

        Curso.query(function(result) {
            vm.cursos = result;
        }, function(status) {
            console.log(status);
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.cursoPs.dataAlteracao = new Date();
            vm.cursoPs.usuarioAlteracao = vm.account.login;
            vm.cursoPs.idCurso = vm.cursoSelecionado.id;
            vm.cursoPs.descricao = vm.cursoSelecionado.nomeRm;
            if (vm.cursoPs.id !== null) {
                CursoPs.update(vm.cursoPs, onSaveSuccess, onSaveError);
            } else {
                vm.cursoPs.dataCriacao = new Date();
                vm.cursoPs.usuarioCriacao = vm.account.login;
                CursoPs.save(vm.cursoPs, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:cursoPsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataCriacao = false;
        vm.datePickerOpenStatus.dataAlteracao = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();