(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('calcula-enem', {
            parent: 'app',
            url: '/calcular-nota-do-enem',
            data: {
                authorities: [],
                pageTitle: 'Univel - Enem'
            },
            views: {
                'content@': {
                    templateUrl: 'app/enem/calcula-nota/calcula-nota-enem.html',
                    controller: 'CalculaNotaEnemController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();