(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('viewPlanoProfessor', {
            parent: 'app',
            url: '/view-plano-professor',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_PROFESSOR'],
                pageTitle: 'Plano de Curso'
            },
            views: {
                'content@': {
                    templateUrl: 'app/plano-ensino/view/view.html',
                    controller: 'ViewPlanoProfessorController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();