(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaOpcoesPlanoPagamento', BuscaOpcoesPlanoPagamento);

    BuscaOpcoesPlanoPagamento.$inject = ['$resource', 'DateUtils'];

    function BuscaOpcoesPlanoPagamento($resource, DateUtils) {
        var resourceUrl = '/api/busca-opcoes-plano-pagamento';

        return $resource(resourceUrl, {}, {
            'send': { method: 'POST', isArray: true }
        });
    }
})();