(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .controller('ConfirmaTransporteController', ConfirmaTransporteController);

        ConfirmaTransporteController.$inject = ['$timeout', '$scope', '$stateParams', 'Inscricao', '$http', 'OnibusPlayService', '$state', '$sessionStorage', 'Principal', 'GetCandidatoPlayService'];

    function ConfirmaTransporteController($timeout, $scope, $stateParams, Inscricao, $http, OnibusPlayService, $state, $sessionStorage, Principal, GetCandidatoPlayService) {
        var vm = this;
        vm.save = save;
        vm.load = loadCandidatoPlay;
        vm.avancar = avancar;

        /*
            etapa 1 pede os dados -> codigo e data de nascimento
            etapa 2 cidade
            etapa 3 o local de partida
            etapa 4 deu certo
            etapa 5 você não é convidado
        */

        vm.etapa = 1;
        vm.dados = JSON.parse(sessionStorage.getItem('dados-confirma-transporte'));
        console.log(vm.dados);

        if (vm.dados == null || vm.dados == undefined){
            vm.etapa = 1;
        } else {
            loadCandidatoPlay();            
        }

        /* 1986-06-24 6019
        */

        function avancar(etapa) {
            
            if (vm.emailConf == null || vm.emailConf == undefined || vm.emailConf == ""){
                alert("Por favor confirme seu endereço de e-mail.");
            }else{
                if (vm.inscricao.documento == null || vm.inscricao.documento == undefined || vm.inscricao.documento == ""){
                    alert("Por favor informe o número do seu RG");
                }else{    
                    vm.etapa = etapa;
                }
            }
        }

        function loadCandidatoPlay() {
            GetCandidatoPlayService.query({codPlay: vm.dados.codigo, telefone : vm.dados.telefone1}, function(result) {
                vm.inscricao = result;               
                console.log(vm.inscricao);
                if (vm.inscricao != null){
                    if (vm.inscricao.tipoInscricao == "CONVIDADO"){
                        if(vm.inscricao.transporteCidade == 0){                            
                            loadOnibus();
                            vm.etapa = 2;
                        }else{
                            showMessage('alert-warning', "Seu transporte já foi confirmado, para mais detalhes acesse o portal do candidato"); 
                        }
                    }else{
                        showMessage('alert-warning', "Transporte exclusivo para concluintes do ensino médio.");
                    }
                }else{
                    showMessage('alert-warning', "Não encontrado, por favor confira seus dados e tente novamente.");
                }
            }, function(status) {
                console.log(status);
            });
        }
        
        //loadOnibus();

        function loadOnibus() {
            OnibusPlayService.query(function(result) {
                vm.onibus = result;
            });
        }

        function save() {
            vm.isSaving = true;

            if(vm.emailConf != vm.inscricao.email){
                vm.inscricao.email = vm.emailConf;
            }

            vm.inscricao.transporte = "UNIVEL";
            vm.inscricao.transporteCidade = vm.onibusSelecionado.id;

            console.log(vm.inscricao);
            Inscricao.update(vm.inscricao, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.etapa = 5;
        }

        function onSaveError(status) {
            console.log(status);
        }

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        
        /** REFERENTE AO MD AUTOCOMPLETE */

        vm.querySearch = querySearch;
        vm.selectedItemChange = selectedItemChange;
        vm.searchTextChange = searchTextChange;

        function querySearch(query, array) {

            var results = query ? array.filter(createFilterFor(query)) : array,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChange(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChange(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterFor(query) {
            var lowercaseQuery = angular.uppercase(query);
            console.log(lowercaseQuery);
            return function filterFn(state) {
                return state.endereco.toUpperCase().replace('COLEGIO','').replace('ESTADUAL', '').replace('ESCOLA', '').includes(lowercaseQuery);
            };

        }
    }
})();