(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatrizCurricularDetailController', MatrizCurricularDetailController);

    MatrizCurricularDetailController.$inject = ['$http', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MatrizCurricular', 'Principal', 'MatrizCurricularDisciplina', 'MatrizTurma'];

    function MatrizCurricularDetailController($http, $scope, $rootScope, $stateParams, previousState, entity, MatrizCurricular, Principal, MatrizCurricularDisciplina, MatrizTurma) {
        var vm = this;

        vm.matrizCurricular = entity;
        vm.previousState = previousState.name;
        vm.buscaDiscMatriz = buscaDiscMatriz;
        vm.buscaTurmasMatriz = buscaTurmasMatriz;
        vm.buscaSTurmaDisc = buscaSTurmaDisc;
        vm.criarNovaDisc = criarNovaDisc;
        vm.consultarDisc = consultarDisc;
        vm.addDisc = addDisc;
        vm.removeDisc = removeDisc;
        vm.salvarDiscs = salvarDiscs;
        vm.sincronizaDiscsRM = sincronizaDiscsRM;
        vm.sincronizaTurmasRM = sincronizaTurmasRM;
        vm.disciplinaSelecionada = [];
        vm.resultadoDisc = [];

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                buscaDiscMatriz();
                buscaTurmasMatriz();
            });
        };

        function criarNovaDisc() {

            var obj = {
                codColigada: vm.matrizCurricular.codColigada,
                codCurso: vm.matrizCurricular.codCurso,
                codHabilitacao: vm.matrizCurricular.codHabilitacao,
                codGrade: vm.matrizCurricular.codGrade,
                codPeriodo: vm.novaDisc.codPeriodo,
                codDisc: vm.novaDisc.codDisc,
                tipoDisc: "B",
                idPerlet: vm.matrizCurricular.idPerlet,
                codPerlet: vm.matrizCurricular.codPerlet,
                createdDate: new Date(),
                alterDate: new Date(),
                createdUser: vm.account.login,
                alterUser: vm.account.login,
                status: "PENDENTE",
                nome: vm.novaDisc.nomeDisc,
                idMatriz: vm.matrizCurricular.id,
                order: vm.novaDisc.order
            };

            console.log(obj);


            $http.post('api/matriz-saveEduDisciplina-rm', vm.novaDisc)
                .success(function(data) {

                    MatrizCurricularDisciplina.save(obj);
                    buscaDiscMatriz();
                }).error(function(status) {
                    console.log(status);
                    alert('Não foi possível salvar a disiciplina no RM.');
                });
        }

        function buscaDiscMatriz() {

            $http.get('api/matriz-getdiscs-id/' + vm.matrizCurricular.id)
                .success(function(data) {
                    vm.disciplinas = data;
                    //console.log(data);
                    vm.aba = 1;
                }).error(function(status) {
                    console.log(status);
                });


        }

        function buscaTurmasMatriz() {
            $http.get('api/matriz-getturmas-id/' + vm.matrizCurricular.id)
                .success(function(data) {
                    vm.turmasMatriz = data;
                    //console.log(data);
                }).error(function(status) {
                    console.log(status);
                });
        }

        function buscaSTurmaDisc(id) {
            $http.get('api/matriz-sturmadisc-id/' + id)
                .success(function(data) {
                    console.log(data);
                    vm.disciplinasTurma = data;
                }).error(function(status) {
                    console.log(status);
                });
        }

        function addDisc(item) {
            if (item.codPeriodo == null || item.codPeriodo == undefined || item.codPeriodo == "") {
                alert("informe o periodo da disciplina");
            } else {
                if (item.order == null || item.order == undefined || item.order == "") {
                    alert("informe a ordem da disciplina");
                } else {

                    var obj = {
                        codColigada: vm.matrizCurricular.codColigada,
                        codCurso: vm.matrizCurricular.codCurso,
                        codHabilitacao: vm.matrizCurricular.codHabilitacao,
                        codGrade: vm.matrizCurricular.codGrade,
                        codPeriodo: item.codPeriodo,
                        codDisc: item.codDisc,
                        tipoDisc: "B",
                        idPerlet: vm.matrizCurricular.idPerlet,
                        codPerlet: vm.matrizCurricular.codPerlet,
                        createdDate: new Date(),
                        alterDate: new Date(),
                        createdUser: vm.account.login,
                        alterUser: vm.account.login,
                        status: "PENDENTE",
                        nome: item.nomeDisc,
                        idMatriz: vm.matrizCurricular.id,
                        order: item.order
                    };
                    vm.discsRM = null;
                    MatrizCurricularDisciplina.save(obj, function(data) { buscaDiscMatriz(); }, function(status) { console.log(status); });
                }
            }
        }

        function removeDisc(id) {
            MatrizCurricularDisciplina.delete({ id: id }, function(data) { buscaDiscMatriz(); }, function(status) { console.log(status); });
        }

        function removeDisc(id) {
            MatrizTurma.delete({ id: id }, function(data) { buscaTurmasMatriz(); }, function(status) { console.log(status); });
        }

        function salvarDiscs() {

            // lança tudo no RM e atualiza o status.

            // for (var i = 0; i < vm.disciplinaSelecionada.length; i++) {
            //     var e = vm.disciplinaSelecionada[i];

            //     var obj = {
            //         codColigada: "1",
            //         codCurso: vm.matrizCurricular.codCurso,
            //         codHabilitacao: vm.matrizCurricular.codHabilitacao,
            //         codGrade: vm.matrizCurricular.codGrade,
            //         codPeriodo: e.codPeriodo,
            //         codDisc: e.codDisc,
            //         tipoDisc: "B",
            //         idPerlet: vm.matrizCurricular.idPerlet,
            //         codPerlet: vm.matrizCurricular.codPerlet,
            //         status: "PENDENTE",
            //         nome: e.nomeDisc,
            //         idMatriz: vm.matrizCurricular.id,
            //         createdDate: new Date(),
            //         alterDate: new Date(),
            //         createdUser: vm.account.login,
            //         alterUser: vm.account.login
            //     };

            //     if (obj.id !== null) {
            //         MatrizCurricularDisciplina.update(obj);
            //     } else {
            //         MatrizCurricularDisciplina.save(obj);
            //     }
            // }
            // buscaDiscMatriz();
        }

        function consultarDisc() {

            var obj = {
                disciplina: vm.consultaDisc.disc,
                curso: vm.consultaDisc.curso,
                tipoUser: "TODOS"
            }

            $http.post('api/matriz-buscadiscs-rm', obj)
                .success(function(data) {
                    //console.log(data);
                    vm.discsRM = data;
                    vm.passo3 = true;
                }).error(function(status) {
                    console.log(status);
                });
        }

        function sincronizaDiscsRM() {
            $http.post('api/matriz-sinc-sgrade-rm', vm.matrizCurricular)
                .success(function(data) {
                    buscaDiscMatriz();
                }).error(function(status) {
                    console.log(status);
                });
        }

        function sincronizaTurmasRM() {
            $http.post('api/matriz-sinc-turmas-rm', vm.matrizCurricular)
                .success(function(data) {
                    buscaTurmasMatriz();
                }).error(function(status) {
                    console.log(status);
                });
        }
    }
})();