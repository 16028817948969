(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('BuscaDisciplinasMatriculaDp', BuscaDisciplinasMatriculaDp);

    BuscaDisciplinasMatriculaDp.$inject = ['$resource', 'DateUtils'];

    function BuscaDisciplinasMatriculaDp($resource, DateUtils) {
        var resourceUrl = 'api/get-aluno-matric-dp';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true }
        });
    }
})();