(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('ConfirmaNomeService', ConfirmaNomeService);

    ConfirmaNomeService.$inject = ['$uibModal', '$rootScope'];

    function ConfirmaNomeService($uibModal, $rootScope) {
        var service = {
            open: open,
            getRetorno: retorno
        };

        var modalInstance = null;
        var retorno = null;

        var resetModal = function() {
            modalInstance = null;
        };

        return service;

        function open(nome1, nome2) {
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/matricula/pre-matricula/confirmaNome/confirmaNome.html',
                controller: 'ConfirmaNomeController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    param: function() {
                        return { 'nome1': nome1, 'nome2': nome2 };
                    }
                }
            });
            modalInstance.result.then(function(selectedItem) {
                retorno = selectedItem;
                modalInstance = null;
            }, function() {
                modalInstance = null;
            });
        }
    }
})();