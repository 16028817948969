(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('ConsultaAlunoPlay2018', ConsultaAlunoPlay2018);

    ConsultaAlunoPlay2018.$inject = ['$resource', 'DateUtils'];

    function ConsultaAlunoPlay2018($resource, DateUtils) {
        var resourceUrl = 'api/play/consulta/:tipo/:valor/:idps';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();