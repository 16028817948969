(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ConsultaProvasService', ConsultaProvasService);

    ConsultaProvasService.$inject = ['$resource', 'DateUtils'];

    function ConsultaProvasService($resource, DateUtils) {
        var resourceUrl = 'api/consulta-prova-online/:campo/:valor/:limit/:idps/:branco';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();