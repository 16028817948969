(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ProvaOnlineFinalizacaoController', ProvaOnlineFinalizacaoController);

    ProvaOnlineFinalizacaoController.$inject = ['$scope', '$state', '$http'];

    function ProvaOnlineFinalizacaoController($scope, $state, $http) {
        var vm = this;

        vm.finalizar = finalizar;

        init();

        vm.painelAdm = function(tipo) {
            sessionStorage.setItem('tipo-painel', JSON.stringify(tipo));
            $state.go('painelAdministrativo');
        }

        function init() {

            vm.prova = JSON.parse(sessionStorage.getItem('prova-online'));
            sessionStorage.removeItem('tema');
            sessionStorage.removeItem('prova-online');
            sessionStorage.removeItem('prova-online-questao-aluno');
            sessionStorage.removeItem('respostas-aluno');


            // setTimeout(function() {
            //     window.open('https://vestibular.univel.br/#/painel-administrativo', '_self', '');
            // }, 10000);
        }

        function finalizar() {
            $state.go('painelAdministrativo');
        }
    }
})();