(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoAgendadoInternaUserController', InscricaoAgendadoInternaUserController);

    InscricaoAgendadoInternaUserController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'InscricaoAgendadoUser', 'CursosPorPolo', '$sessionStorage', 'IndicacaoService', 'PolosAtivos', 'DatasPorPolo', 'ConsultaHorarioPolo', 'ValidaInscricao', 'LoginService'];

    function InscricaoAgendadoInternaUserController($timeout, $scope, $rootScope, $state, $stateParams, InscricaoAgendadoUser, CursosPorPolo, $sessionStorage, IndicacaoService, PolosAtivos, DatasPorPolo, ConsultaHorarioPolo, ValidaInscricao, LoginService) {
        var vm = this;

        // functions
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.isSaturday = saturday;
        vm.openIndicacao = openIndicacao;
        vm.buscaCursos = buscaCursos;
        vm.login = login;

        // variables
        vm.inscricaoAgendado = {};
        vm.inscricaoAgendado.possuiNecessidadesEspeciais = 'NAO';
        vm.inscricaoAgendado.possuiIndicacao = 'NAO';
        vm.inscricaoAgendado.interno = false;
        vm.maxDate = new Date(2018, 8, 28);
        vm.poloEad = false;
        vm.interno = true;

        vm.datePickerOpenStatus = {};
        vm.recebeu_indicacao_texto = null;
        vm.necessidades_especiais_texto = null;

        //arrays        
        vm.horariosDefault = [{ 'value': 'H07', 'descricao': '07:00', 'id': 7 },
            { 'value': 'H08', 'descricao': '08:00', 'id': 8 },
            { 'value': 'H09', 'descricao': '09:00', 'id': 9 },
            { 'value': 'H10', 'descricao': '10:00', 'id': 10 },
            { 'value': 'H11', 'descricao': '11:00', 'id': 11 },
            { 'value': 'H12', 'descricao': '12:00', 'id': 12 },
            { 'value': 'H13', 'descricao': '13:00', 'id': 13 },
            { 'value': 'H14', 'descricao': '14:00', 'id': 14 },
            { 'value': 'H15', 'descricao': '15:00', 'id': 15 },
            { 'value': 'H16', 'descricao': '16:00', 'id': 16 },
            { 'value': 'H17', 'descricao': '17:00', 'id': 17 },
            { 'value': 'H18', 'descricao': '18:00', 'id': 18 },
            { 'value': 'H19', 'descricao': '19:00', 'id': 19 },
            { 'value': 'H20', 'descricao': '20:00', 'id': 20 },
            { 'value': 'H21', 'descricao': '21:00', 'id': 21 },
            { 'value': 'H22', 'descricao': '22:00', 'id': 22 }
        ];


        function login() {
            LoginService.open();
            $state.go('painelAdministrativo');
        }

        function openIndicacao() {
            IndicacaoService.open(vm.inscricaoAgendado.possuiIndicacao);
        }

        $rootScope.$on('selecionou', function(event, value) {
            if (value != null && value != undefined) {
                vm.inscricaoAgendado.codPessoaIndicacao = value.codPessoa;
                vm.inscricaoAgendado.indicacaoTexto = value.nome;
                vm.inscricaoAgendado.possuiIndicacao = value.possuiIndicacao;
            } else {
                vm.inscricaoAgendado.possuiIndicacao = "NAO";
                vm.inscricaoAgendado.indicacaoTexto = null;
                vm.inscricaoAgendado.codPessoaIndicacao = null;
            }
        });

        function areDatesEqual(date1, date2) {
            return date1 == date2.toLocaleDateString()
        }

        function buscaCursos() {
            vm.cursos = CursosPorPolo.query({
                id: vm.poloEscolhido.id
            });

            vm.datas = DatasPorPolo.query({
                id: vm.poloEscolhido.id
            });

            if (vm.poloEscolhido.id !== 1) {
                vm.poloEad = true;
            } else {
                vm.poloEad = false;
            }
        }

        vm.polos = PolosAtivos.query({
            page: 0,
            size: 50
        });

        function saturday() {
            showMessage('invi', 'Os polos parceiros não possuem atendimento no Sábado');
            vm.horariosProva = new Array();
            var data = vm.inscricaoAgendado.dataProva.toString();
            var validateIE = validaDataInternetExplorer(vm.inscricaoAgendado.dataProva);
            if (!validateIE) {
                showMessage('alert-warning', 'Este polo não terá atendimento nesta data.');
            } else {
                if (data.substring(0, 3) == "Sun") {
                    showMessage('alert-warning', 'Desculpe, Não existe expediente aos domingos.');
                } else {
                    if (vm.poloEad) {
                        if (data.substring(0, 3) == "Sat") {
                            showMessage('alert-warning', 'Os polos parceiros não possuem atendimento no final de semana.');
                        } else {
                            popula('NORMALPOLO');
                        }
                    } else {
                        if (data.substring(0, 3) == "Sat") {
                            popula('SABADO');
                        } else {
                            popula('NORMAL');
                        }
                    }
                }
            }
        }

        function validaDataInternetExplorer(d) {
            var dy2 = d.getDate();
            var ms2 = d.getMonth() + 1;
            var yr2 = d.getFullYear();
            if (ms2 < 10) {
                ms2 = '0' + ms2;
            }
            if (dy2 < 10) {
                dy2 = '0' + dy2;
            }
            var dia2 = dy2 + '/' + ms2 + '/' + yr2;
            for (var i = 0; i < vm.datas.length; i++) {
                if (vm.datas[i] === dia2) {
                    return false;
                }
            }
            return true;
        }


        function popula(situacao) {

            if (situacao == 'NORMAL') {
                for (var i = 0; i < vm.horariosDefault.length; i++) {
                    var element = vm.horariosDefault[i];

                    if ((element.id > 7 && element.id < 12) || (element.id > 13 && element.id < 22)) {
                        vm.horariosProva.push(element);
                    }

                }
            }

            if (situacao == 'SABADO') {
                for (var i = 0; i < vm.horariosDefault.length; i++) {
                    var element = vm.horariosDefault[i];

                    if ((element.id > 7 && element.id < 12)) {
                        vm.horariosProva.push(element);
                    }
                }
            }

            if (situacao == 'ESPECIAL') {
                for (var i = 0; i < vm.horariosDefault.length; i++) {
                    var element = vm.horariosDefault[i];
                    if ((element.id > 7 && element.id < 12) || (element.id > 13 && element.id < 20)) {
                        vm.horariosProva.push(element);
                    }
                }
            }

            if (situacao == 'NORMALPOLO') {
                for (var i = 0; i < vm.horariosDefault.length; i++) {
                    var element = vm.horariosDefault[i];
                    if (element.id > 12 && element.id < 22) {
                        vm.horariosProva.push(element);
                    }
                }
            }

            consultaBloqueados();

        }

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
        }

        function consultaBloqueados() {
            var dt = vm.inscricaoAgendado.dataProva;
            var dy = dt.getDate();
            var ms = dt.getMonth() + 1;
            var yr = dt.getFullYear();

            if (ms < 10) {
                ms = '0' + ms;
            }

            if (dy < 10) {
                dy = '0' + dy;
            }

            var dia = yr + '-' + ms + '-' + dy;
            ConsultaHorarioPolo.query({ idPolo: vm.poloEscolhido.id, dia: dia },
                function(result) {
                    vm.horariosBloqueados = result;
                    for (var j = 0; j < vm.horariosBloqueados.length; j++) {
                        var bl = vm.horariosBloqueados[j];
                        for (var k = 0; k < vm.horariosProva.length; k++) {
                            var hp = vm.horariosProva[k];
                            if (bl.hora == hp.value) {
                                vm.horariosProva.splice(k, 1);
                            }
                        };
                    };
                });
        }

        function getMinDate() {
            var atual = new Date();
            var matricDate = new Date(2017, 9, 16);

            if (atual > matricDate) {
                var minDateAt = new Date(atual.getFullYear(), atual.getMonth(), atual.getDate());
            } else {
                var minDateAt = matricDate;
            }

            return minDateAt;
        }




        vm.dateOptions = {
            minDate: getMinDate(),
            dateDisabled: myDisabledDates,
            showWeeks: false,
            showButtonPanel: false
        }

        function myDisabledDates(dateAndMode) {
            var date = dateAndMode.date,
                mode = dateAndMode.mode;
            var isRealDate = false;

            if (vm.datas == undefined) {
                showMessage('alert-danger', 'Por favor, selecione um Polo antes de selecionar a Data.');
            }

            for (var i = 0; i < vm.datas.length; i++) {
                if (areDatesEqual(vm.datas[i], date)) {
                    isRealDate = true;
                }
            }



            return ((dateAndMode.mode === 'day' && (dateAndMode.date.getDay() === 0)) || isRealDate);
        }

        function disabled(date, mode) {
            return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
        };

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save() {
            var verify = verificaCampos();

            if (vm.inscricaoAgendado.recebeu_indicacao == undefined) {
                vm.inscricaoAgendado.recebeu_indicacao = 'NAO';
            }
            if (vm.inscricaoAgendado.possuiNecessidadesEspeciais == undefined) {
                vm.inscricaoAgendado.possuiNecessidadesEspeciais = 'NAO';
            }

            vm.inscricaoAgendado.nome = vm.inscricaoAgendado.nome.toUpperCase();
            vm.inscricaoAgendado.email = vm.inscricaoAgendado.email.toUpperCase();

            if (vm.inscricaoAgendado.necessidadesEspeciaisTexto != null) {
                vm.inscricaoAgendado.necessidadesEspeciaisTexto = vm.inscricaoAgendado.necessidadesEspeciaisTexto.toUpperCase();
            }


            if (vm.inscricaoAgendado.indicacaoTexto != null) {
                vm.inscricaoAgendado.indicacaoTexto = vm.inscricaoAgendado.indicacaoTexto.toUpperCase();
            }

            vm.inscricaoAgendado.polo = vm.poloEscolhido;


            if (verify != "OK") {
                showMessage('alert-warning', verify);
            } else {
                showMessage('invi', '');
                ValidaInscricao.query({ email: vm.inscricaoAgendado.email, cpf: vm.inscricaoAgendado.cpf },
                    function(result) {
                        // valida se ja existe uma inscricao para o cara
                        if (result.existe == true) {
                            // se existe verifica se esta pendente
                            if (result.pendente == true) {
                                // se esta pendente deve aparecer no painel para acompanhamento
                                if (result.email == true) {
                                    // significa que o email bateu então pode autenticar
                                    showMessage('alert-info', "Identificamos uma inscricão com este email, favor autenticar no sistema pela AREA RESTRITA na parte inferior para visualizar");
                                } else {
                                    //o email nao bateu mas o cpf sim, deve entrar em contato com a univel para regularizar
                                    showMessage('alert-info', "Identificamos uma inscricão com este CPF, por favor entre em contato para mais informações - 3036 3664");
                                }
                            } else {
                                // se não esta pendente deve-se atualizar a inscricao 
                                vm.inscricaoAgendado.id = result.idInsc;
                                console.log(vm.inscricaoAgendado);
                                vm.isSaving = true;
                                sessionStorage.setItem('candidato-vestibular', JSON.stringify(vm.inscricaoAgendado));
                                InscricaoAgendadoUser.update(vm.inscricaoAgendado, onSaveSuccess, onSaveError);
                            }
                        } else {
                            vm.inscricaoAgendado.interno = true;
                            vm.isSaving = true;
                            sessionStorage.setItem('candidato-vestibular', JSON.stringify(vm.inscricaoAgendado));
                            InscricaoAgendadoUser.save(vm.inscricaoAgendado, onSaveSuccess, onSaveError);

                        }
                    },
                    function(status) {
                        console.log(status);
                    });
            }
        }

        function onSaveSuccess(result) {
            console.log(vm.inscricaoAgendado);

            //$state.go('confirmacao-inscricao-agendado');
            //$scope.$emit('vestibularApp:inscricaoAgendadoUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError(error) {
            console.log(error);
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataProva = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.mensagem = "";


        function verificaCampos() {

            // VALIDA SE O NOME ESTA VAZIO -- JA TEM O REQUIRED, MAS VALIDAÇÃO NUNCA É DEMAIS
            if (vm.inscricaoAgendado.nome == null || vm.inscricaoAgendado.nome == "") {
                return "O campo NOME não pode estar vazio.";
            }
            // VALIDA SE O NOME TEM PELO MENOS DOIS CARACTERES E PELO MENOS UM ESPAÇO
            if (vm.inscricaoAgendado.nome.length <= 3 || !vm.inscricaoAgendado.nome.indexOf(" ")) {
                return "Por favor, insira seu NOME COMPLETO. Ex: FULANO SILVA";
            }

            if (vm.inscricaoAgendado.cpf == null || vm.inscricaoAgendado.cpf == "") {
                return "O CPF informado é inválido.";
            }
            if (vm.inscricaoAgendado.cpf.length < 11) {
                return "O campo CPF deve ter ao menos 11 dígitos";
            }

            if (vm.inscricaoAgendado.telefone == null || vm.inscricaoAgendado.telefone == "") {
                return "O campo TELEFONE não pode ser vazio";
            }
            if (vm.inscricaoAgendado.telefone.length < 10) {
                return "O campo TELEFONE deve ter ao menos 10 dígitos";
            }

            if (vm.inscricaoAgendado.email == null || vm.inscricaoAgendado.email == "") {
                return "O campo EMAIL não pode ser vazio";
            }
            if (!vm.inscricaoAgendado.email.indexOf("@")) {
                return "O campo EMAIL não está correto";
            }

            return "OK";
        };
    }
})();