(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('DataPoloDelete', DataPoloDelete);

    DataPoloDelete.$inject = ['$resource', 'DateUtils'];

    function DataPoloDelete($resource, DateUtils) {
        var resourceUrl = 'api/data-polos/delete/:id';

        return $resource(resourceUrl, {}, {
            'delete': { method: 'GET', isArray: false }
        });
    }
})();