(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlanoPagamentoMatriculaDiplomaController', PlanoPagamentoMatriculaDiplomaController);

    PlanoPagamentoMatriculaDiplomaController.$inject = ['$state', 'MatriculaDiplomaService', 'BuscaOpcoesPlanoPagamento'];

    function PlanoPagamentoMatriculaDiplomaController($state, MatriculaDiplomaService, BuscaOpcoesPlanoPagamento) {
        var vm = this;
        vm.save = save;
        vm.planos = null;

        init();

        function init() {
            vm.matricula = JSON.parse(sessionStorage.getItem('user-matric-diploma'));
            vm.load = true;
            vm.aceitei = false;

            BuscaOpcoesPlanoPagamento.send(vm.matricula.academico,
                function(data) {
                    vm.planos = data;
                    console.log(vm.planos);

                    vm.load = false;
                },
                function(status) {
                    console.log(status);
                });
        }

        function save(item) {
            vm.matricula.academico.etapa = "PLANOPAGAMENTO";
            vm.matricula.academico.codPlanoPgto = item.codPlanoPagamento;
            MatriculaDiplomaService.save(vm.matricula,
                function(data) {
                    sessionStorage.setItem('user-matric-diploma', JSON.stringify(data));
                    $state.go('matriculaDiplomaContrato');
                },
                function(status) {
                    console.log(status);
                }
            );
        }
    }
})();