(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('avaliaPlanoEnsino', {
            parent: 'app',
            url: '/plano-de-ensino-avaliacao',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_COORDENADOR'],
                pageTitle: 'Plano de Ensino de Curso'
            },
            views: {
                'content@': {
                    templateUrl: 'app/plano-ensino/disciplina/avalia/avalia.html',
                    controller: 'AvaliaPlanoEnsinoController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();