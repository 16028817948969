(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RematriculaAuthController', RematriculaAuthController);

    RematriculaAuthController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'RematriculaAuthServiceController', 'Curso', 'LoginService', '$http', '$sessionStorage'];

    function RematriculaAuthController($timeout, $scope, $state, $stateParams, RematriculaAuthServiceController, Curso, LoginService, $http, $sessionStorage) {
        var vm = this;

        vm.load = false;

        // Autenticação
        vm.autentica = autenticar;
        vm.habilitado = "";
        vm.msg = true;
        verificaDisponibilidade();

        function autenticar() {

            var infos = {
                'login': vm.username,
                'password': vm.password
            };

            vm.load = true;

            $http.post("/api/login", infos)
                .success(function(data) {
                    if (data.authorized) {
                        $http.post("/api/getInfoByRA", vm.username)
                            .success(function(data) {

                                if (data.chavePK == null) {
                                    alert("Infelizmente sua rematrícula não está liberada, entre em contato com a secretaria geral pelo telefone (045) 3036 - 3636");
                                    vm.load = false;
                                    return;
                                }
                                sessionStorage.setItem('dados-academico', JSON.stringify(data));

                                switch (data.status) {
                                    case "DISCIPLINAS":
                                        $state.go("rematriculaDisciplinas");
                                        break;
                                    case "TERMOCONSENTIMENTO":
                                        $state.go("rematricula-termo-consentimento");
                                        break;
                                    case "CONTRATO":
                                        $state.go("rematriculaContrato");
                                        break;
                                    case "TERMO":
                                        $state.go("rematriculaPap");
                                        break;
                                    case "BOLETO":
                                        $state.go("rematriculaBoleto");
                                        break;
                                    case "FINALIZADO":
                                        $state.go("rematriculaBoleto");
                                        break;
                                    default:
                                        $state.go("rematriculaDadosCadastrais");
                                        break;
                                }
                            })
                            .error(function(status) {
                                console.log(status);
                            });
                    } else {
                        alert("Usuário ou senha incorretos, confira seus dados ou tente novamente");
                        vm.load = false;
                    }
                })
                .error(function(status) {
                    console.log(status);
                    vm.load = false;
                    alert("Desculpe não foi possível acessar o servidor, tente novamente mais tarde");
                });

        }


        function verificaDisponibilidade() {
            var today = new Date();
            var inicio = new Date('2020', '11', '14', '08', '00', '0000');
            vm.habilitado = "OPEN";

            if (today < inicio) {
                vm.habilitado = "BEFORE";
            } else {
                vm.habilitado = "OPEN";
            }
        }

    }
})();