(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('HorarioPoloController', HorarioPoloController);

    HorarioPoloController.$inject = ['$http', '$state', 'HorarioPolo', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'PolosAtivos', 'ConsultaHorarioPoloMes', 'ConsultaRangePolo'];

    function HorarioPoloController($http, $state, HorarioPolo, ParseLinks, AlertService, paginationConstants, pagingParams, PolosAtivos, ConsultaHorarioPoloMes, ConsultaRangePolo) {

        var vm = this;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.dia = false;

        vm.polos = PolosAtivos.query();

        vm.consultar = function() {
            ConsultaHorarioPoloMes.query({ idPolo: vm.poloSel.id },
                function(result) {
                    vm.listaHorariosPolo = result;
                },
                function(status) {
                    console.log(status);
                });
            ConsultaRangePolo.query({ idPolo: vm.poloSel.id },
                function(result) {
                    vm.listaDispPolo = result;
                },
                function(status) {
                    console.log(status);
                });
        }

        vm.bloquearHorarioRange = function(id) {
            $http.get("/api/altera-disp-polo/" + vm.poloSel.id + "/" + id + "/F")
                .success(function(data) {
                    vm.listaDispPolo = data;
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        vm.liberarHorarioRange = function(id) {
            $http.get("/api/altera-disp-polo/" + vm.poloSel.id + "/" + id + "/T")
                .success(function(data) {
                    vm.listaDispPolo = data;
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        vm.inserirVinculo = function() {

            var dia = vm.horarioPolo.dia.getDate() + 1;
            var mes = vm.horarioPolo.dia.getMonth() + 1;
            var ano = vm.horarioPolo.dia.getFullYear();
            if (mes < 10) { mes = "0" + mes; }
            if (dia < 10) { dia = "0" + dia; }

            vm.horarioPolo.idPolo = vm.poloSel.id;
            vm.horarioPolo.dia = ano + "-" + mes + "-" + dia;
            /*new Date(ano, mes, dia);*/

            console.log(vm.horarioPolo);

            HorarioPolo.save(vm.horarioPolo, function(result) {
                vm.horarioPolo = null;
                ConsultaHorarioPoloMes.query({ idPolo: vm.poloSel.id },
                    function(result) {
                        vm.listaHorariosPolo = result;
                    },
                    function(status) {
                        console.log(status);
                    });
            });
        }

        vm.deleteVinculo = function(id) {
            $http.get("/api/delete-block-hr/" + vm.poloSel.id + "/" + id)
                .success(function(result) {
                    vm.listaHorariosPolo = result;
                    /*ConsultaHorarioPoloMes.query({ idPolo: vm.poloSel.id },
                        function(result) {
                            vm.listaHorariosPolo = result;
                        },
                        function(status) {
                            console.log(status);
                        });*/
                })
                .error(function(status) {
                    console.log(status);
                });
        }


        /*function tratarDados(lista) {
            for (var p = 0; p < lista.length; p++) {
                var polo = lista[p];
                var listaVinc = consultaHorariosPorPolo(polo.id);

                var newPolo = {
                    id: polo.id,
                    cidade: polo.cidade,
                    email1: polo.email1,
                    telefone1: polo.telefone1,
                    lista: listaVinc
                };
                vm.final.push(newPolo);
            }
        }*/


        /*function consultaHorariosPorPolo(id) {
            var retorno =
                return retorno;
        }*/

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        /*vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        loadAll();

        function loadAll() {
            HorarioPolo.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.horarioPolos = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }*/
    }
})();