(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RespostaProvaOnlineDetailController', RespostaProvaOnlineDetailController);

    RespostaProvaOnlineDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RespostaProvaOnline'];

    function RespostaProvaOnlineDetailController($scope, $rootScope, $stateParams, previousState, entity, RespostaProvaOnline) {
        var vm = this;

        vm.respostaProvaOnline = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:respostaProvaOnlineUpdate', function(event, result) {
            vm.respostaProvaOnline = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
