(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('inscricao-tradicional2019-interna', {
                parent: 'entity',
                url: '/inscricao-tradicional2019-interna6516519851098132106500222',
                data: {
                    //authorities: ['ROLE_USER'],
                    pageTitle: 'Inscrição Vestibular Tradicional'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/inscricaoTradicional/new_inscricao_interna/inscricaoTradicional.html',
                        controller: 'InscricaoTradicional2019InternaController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                }
            });
    }

})();