(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('CheckinplayController', CheckinplayController);

    CheckinplayController.$inject = ['Checkinplay'];

    function CheckinplayController(Checkinplay) {

        var vm = this;
        
        vm.checkinplays = [];
        vm.itemsPerPage = 30;

        //loadAll();

        function loadAll() {
            Checkinplay.query({
                page: 0,
                size: vm.itemsPerPage
            }, function(result) {
                vm.checkinplays = result;
                vm.searchQuery = null;
            });
        }
    }
})();