(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('ConsultaMatriculaService', ConsultaMatriculaService);

    ConsultaMatriculaService.$inject = ['$resource', 'DateUtils'];

    function ConsultaMatriculaService($resource, DateUtils) {
        var resourceUrl = 'api/pre-matricula/consulta/:tipo/:valor/:idPerlet';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();