(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PortariaContratoDetailController', PortariaContratoDetailController);

    PortariaContratoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PortariaContrato'];

    function PortariaContratoDetailController($scope, $rootScope, $stateParams, previousState, entity, PortariaContrato) {
        var vm = this;

        vm.portariaContrato = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:portariaContratoUpdate', function(event, result) {
            vm.portariaContrato = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
