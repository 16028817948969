(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('prova-online', {
                parent: 'entity',
                url: '/prova-online',
                data: {
                    authorities: ['ROLE_SUPER_VESTIBULAR'],
                    pageTitle: 'ProvaOnlines'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/prova-online/prova-onlines.html',
                        controller: 'ProvaOnlineController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('prova-online-detail', {
                parent: 'prova-online',
                url: '/prova-online/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ProvaOnline'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/prova-online/prova-online-detail.html',
                        controller: 'ProvaOnlineDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'ProvaOnline', function($stateParams, ProvaOnline) {
                        return ProvaOnline.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'prova-online',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('prova-online-detail.edit', {
                parent: 'prova-online-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prova-online/prova-online-dialog.html',
                        controller: 'ProvaOnlineDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProvaOnline', function(ProvaOnline) {
                                return ProvaOnline.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('prova-online.new', {
                parent: 'prova-online',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prova-online/prova-online-dialog.html',
                        controller: 'ProvaOnlineDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    inscricao: null,
                                    email: null,
                                    telefone: null,
                                    cpf: null,
                                    tema: null,
                                    titulo: null,
                                    texto: null,
                                    dataCriacao: null,
                                    dataCorrecao: null,
                                    nota: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('prova-online', null, { reload: 'prova-online' });
                    }, function() {
                        $state.go('prova-online');
                    });
                }]
            })
            .state('prova-online.edit', {
                parent: 'prova-online',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prova-online/prova-online-dialog.html',
                        controller: 'ProvaOnlineDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProvaOnline', function(ProvaOnline) {
                                return ProvaOnline.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('prova-online', null, { reload: 'prova-online' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('prova-online.delete', {
                parent: 'prova-online',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prova-online/prova-online-delete-dialog.html',
                        controller: 'ProvaOnlineDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ProvaOnline', function(ProvaOnline) {
                                return ProvaOnline.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('prova-online', null, { reload: 'prova-online' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();