(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('VinculoPulseiraController', VinculoPulseiraController);

    VinculoPulseiraController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', '$sessionStorage', 'param', 'Principal', 'Checkinplay', 'InscricaoPulseira'];

    function VinculoPulseiraController($scope, $rootScope, $state, $timeout, Auth, $uibModalInstance, $sessionStorage, param, Principal, Checkinplay, InscricaoPulseira) {
        var vm = this;

        vm.codigo = param.data;

        Principal.identity().then(function(account) {
            vm.user = account.login;
            vm.isAuthenticated = Principal.isAuthenticated;
        });

        vm.salvar = salve;

        function salve() {
            var agora = new Date();

            var send = {
                codigoInscricao: vm.codigo,
                codigoPulseira: vm.pulseira,
                dataCriacao: agora,
                usuarioCriacao: vm.user,
                dataAlteracao: agora,
                usuarioAlteracao: vm.user
            }

            InscricaoPulseira.save(send,
                function(data) {
                    alert("Salvo com sucesso, id: " + data.id);
                    $uibModalInstance.close(null);

                },
                function(status) {
                    alert("Erro ao alterar: " + status);
                    $uibModalInstance.close(null);
                });
        }


    }
})();