(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('RematriculaPapService', RematriculaPapService);

    RematriculaPapService.$inject = ['$resource', 'DateUtils'];

    function RematriculaPapService($resource, DateUtils) {
        var resourceUrl = 'api/rematricula-pap-vencimentos/:cpf';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();