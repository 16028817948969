(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaOnlineComplementoController', MatriculaOnlineComplementoController);

    MatriculaOnlineComplementoController.$inject = ['$state', 'MatriculaOnlineServiceController', '$http'];

    function MatriculaOnlineComplementoController($state, MatriculaOnlineServiceController, $http) {
        var vm = this;

        vm.salvar = save;

        vm.ip = null;
        vm.ip = JSON.parse(sessionStorage.getItem('ip-user-vestibular'));
        console.log("IP ATUAL:" + vm.ip);

        setTimeout(getIpUser(), 1000);

        function getIpUser() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                }, function(status) {
                    return status;
                });
            }
        };

        init();

        function init() {
            vm.load = false;
            vm.aceitei = false;
            loadColegios();
            setTimeout(loadDadosCandidato(), 1000);
        }

        function loadDadosCandidato() {
            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", vm.resultadoRM)
                .success(function(data) {
                    vm.matricula = data;
                    switch (vm.matricula.etapa) {
                        case 'INICIO':
                            $state.go('new-matricula-inicio');
                            break;

                            // case 'CANDIDATO':
                            //     $state.go('new-matricula-complemento');
                            //     break;

                        case 'COMPLEMENTO':
                            $state.go('new-matricula-filiacao');
                            break;

                        case 'PAIS':
                            $state.go('new-matricula-consentimento');
                            break;

                        case 'TERMOCONSENTIMENTO':
                            $state.go('new-matricula-plano-pagamento');
                            break;

                        case 'PLANOPAGAMENTO':
                            $state.go('new-matricula-contrato');
                            break;
                        case 'CONTRATO':
                            $state.go('new-matricula-boleto');
                            break;

                        default:

                            if (vm.matricula.instituicaoConclusao != null) {
                                for (var i = 0; i < vm.colegios.length; i++) {
                                    var element = vm.colegios[i];

                                    if (vm.matricula.instituicaoConclusao == element.id) {
                                        vm.instituicaoSelected = element;
                                    }
                                }
                            }

                            console.log(vm.matricula);
                            break;
                    }
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function save() {
            vm.load = true;
            var valida = verificaCampos();
            if (valida == "OK") {
                vm.matricula.etapa = "COMPLEMENTO";
                vm.matricula.ipAluno = vm.ip;
                vm.matricula.instituicaoConclusao = vm.instituicaoSelected.id;
                MatriculaOnlineServiceController.save(vm.matricula, onSaveSuccess, onSaveError);
                $state.go('new-matricula-filiacao');
            } else {
                showMessage('alert-danger', valida);
            }
        }

        function onSaveSuccess(result) {
            vm.load = false;
            $state.go('new-matricula-filiacao');
        }

        function onSaveError(result) {
            vm.load = false;
            console.log(result);
        }

        function verificaCampos() {
            if (vm.notfind == true) {
                vm.instituicaoSelected = {};
                vm.instituicaoSelected.id = 3421; // NAO ENCONTREI MINHA ESCOLA
            } else {
                if (vm.instituicaoSelected == null) {
                    return "O campo Instituição de Conclusão do Ensino Médio precisa ser preenchido.";
                }
            }

            if (vm.matricula.anoConclusao == null || vm.matricula.anoConclusao == undefined || vm.matricula.anoConclusao == 0) {
                return "Por favor informe o Ano que concluiu o Ensino Médio";
            }

            return "OK";
        };

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        /** REFERENTE AO MD AUTOCOMPLETE DOS COLEGIOS */
        function loadColegios() {
            $http.post("/api/get-all-colegios", vm.obj)
                .success(function(data) {
                    vm.colegios = data;

                    vm.allStates = vm.colegios;

                    if (vm.matricula.instituicaoConclusao != null) {
                        for (var i = 0; i < vm.colegios.length; i++) {
                            var element = vm.colegios[i];

                            if (vm.matricula.instituicaoConclusao == element.id) {
                                vm.instituicaoSelected = element;
                            }
                        }
                    }
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        vm.querySearch = querySearch;
        vm.selectedItemChange = selectedItemChange;
        vm.searchTextChange = searchTextChange;

        function querySearch(query) {

            var results = query ? vm.allStates.filter(createFilterFor(query)) : vm.allStates,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChange(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChange(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterFor(query) {
            //var str = accentFold(query).toUpperCase();
            var str = query.toUpperCase();
            return function filterFn(state) {
                //return (state.nome.toUpperCase().replace('COLEGIO', '').replace('ESTADUAL', '').replace('ESCOLA', '') + '-' + state.cidade).includes(str);~
                return accentFold(state.nome).toUpperCase().includes(str);
            };
        }

        function accentFold(inStr) {
            return inStr.replace(/([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
                function(str, a, c, e, i, n, o, s, u, y, ae) {
                    if (a) return 'a';
                    if (c) return 'c';
                    if (e) return 'e';
                    if (i) return 'i';
                    if (n) return 'n';
                    if (o) return 'o';
                    if (s) return 's';
                    if (u) return 'u';
                    if (y) return 'y';
                    if (ae) return 'ae';
                }
            );
        }

    }
})();