(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PreMatriculaDadosCadastraisPaisController', PreMatriculaDadosCadastraisPaisController);

    PreMatriculaDadosCadastraisPaisController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'PreMatriculaDadosCadastraisServiceController', 'LoginService', '$http', '$sessionStorage', 'Principal', '$resource', 'CidadesMDJsonService'];

    function PreMatriculaDadosCadastraisPaisController($timeout, $scope, $state, $stateParams, PreMatriculaDadosCadastraisServiceController, LoginService, $http, $sessionStorage, Principal, $resource, CidadesMDJsonService) {
        var vm = this;

        // functions
        vm.clear = clear;
        vm.salvar = save;
        vm.altera_endereco_Pai = altera_endereco_pai;
        vm.altera_endereco_Mae = altera_endereco_mae;
        vm.load = false;
        vm.change = change;
        vm.changeMae = changeMae;
        vm.alterInfo = alterInfo;
        vm.buscaCEPMae = buscaCEPMae;
        vm.buscaCEPPai = buscaCEPPai;

        // variables
        vm.matricula = {};
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.account = null;
        vm.cepsearch = false;
        vm.inserePai = false;

        //vm.isSaving = true;
        //vm.isSaving = false;

        // chamada de funções..
        getAccount();

        // functions
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

                setTimeout(loadDadosCandidato(), 1500);
            });
        }


        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function showErrorForm(valida) {
            vm.msgError = valida.msg;
            document.getElementById('error-campo').style.display = "block";
            document.getElementById(valida.campo).classList.add('inputForm-error');
            setTimeout(function() {
                document.getElementById('error-campo').style.display = "none";
                document.getElementById(valida.campo).classList.remove('inputForm-error');
            }, 5000);
        }

        function loadDadosCandidato() {
            var resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            // console.log(resultadoRM);
            vm.isSaving = true;
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", resultadoRM)
                .success(function(data) {
                    vm.matricula = data;

                    switch (vm.matricula.etapa) {
                        case 'INICIO':
                            $state.go('pre-matricula-dados-cadastrais-candidato');
                            break;

                        case 'PAIS':
                            $state.go('pre-matricula-dados-cadastrais-responsavel');
                            break;

                        case 'RESPONSAVEL':
                            $state.go('pre-matricula-consentimento');
                            break;

                        case 'TERMOCONSENTIMENTO':
                            $state.go('pre-matricula-plano-pagamento');
                            break;
                        case 'PLANOPAGAMENTO':
                            $state.go('pre-matricula-contrato');
                            break;
                        case 'CONTRATO':
                            $state.go('pre-matricula-boleto');
                            break;

                        default:

                            if (vm.matricula.falecidoMae != null) {
                                if (vm.matricula.falecidoMae == "true") {
                                    vm.matricula.falecidoMae = 'sim';
                                } else {
                                    vm.matricula.falecidoMae = 'nao';
                                }
                            }

                            if (vm.matricula.falecidoPai != null) {
                                if (vm.matricula.falecidoPai == "true") {
                                    vm.matricula.falecidoPai = 'sim';
                                } else {
                                    vm.matricula.falecidoPai = 'nao';
                                }
                            }

                            if (vm.matricula.etapa == "CANDIDATO" || vm.matricula.etapa == "PAIS" || vm.matricula.etapa == "RESPONSAVEL" || vm.matricula.etapa == "CONTRATO") {

                                if (vm.matricula.dataNascimentoPai != null) {

                                    var dateStringPai = vm.matricula.dataNascimentoPai;
                                    var yearPai = dateStringPai.substring(0, 4);
                                    var monthPai = dateStringPai.substring(5, 7);
                                    var dayPai = dateStringPai.substring(8, 10);
                                    var datePai = new Date(yearPai, monthPai - 1, dayPai);

                                    vm.matricula.dataNascimentoPai = datePai;

                                }

                                if (vm.matricula.dataNascimentoMae != null) {
                                    var dateStringMae = vm.matricula.dataNascimentoMae;
                                    var yearMae = dateStringMae.substring(0, 4);
                                    var monthMae = dateStringMae.substring(5, 7);
                                    var dayMae = dateStringMae.substring(8, 10);
                                    var dateMae = new Date(yearMae, monthMae - 1, dayMae);

                                    vm.matricula.dataNascimentoMae = dateMae;
                                }


                                if (vm.matricula.cidadeNatalMae != null) {
                                    var b = vm.matricula.cidadeNatalMae + ' - ' + vm.matricula.estadoNatalMae;
                                    console.log(b);
                                    for (var i = 0; i < vm.cidadesMae.length; i++) {
                                        var element = vm.cidadesMae[i];
                                        var a = element.cidade + ' - ' + element.uf;

                                        if (a == b) {
                                            console.log(a);
                                            vm.naturalidadeMae = element;
                                        }
                                    }
                                }

                                if (vm.matricula.cidadeNatalPai != null) {
                                    var b = vm.matricula.cidadeNatalPai + ' - ' + vm.matricula.estadoNatalPai;
                                    console.log(b);
                                    for (var i = 0; i < vm.cidadesPai.length; i++) {
                                        var element = vm.cidadesPai[i];
                                        var a = element.cidade + ' - ' + element.uf;

                                        if (a == b) {
                                            console.log(a);
                                            vm.naturalidadePai = element;
                                        }
                                    }
                                }
                            }
                            vm.isSaving = false;
                            break;
                    }
                })
                .error(function(status) {
                    vm.isSaving = false;
                    console.log(status);
                });
        }

        function altera_endereco_pai() {
            if (vm.endereco_pai) {
                vm.matricula.cepPai = vm.matricula.cep;
                vm.matricula.ufPai = vm.matricula.uf;
                vm.matricula.cidadePai = vm.matricula.cidade;
                vm.matricula.bairroPai = vm.matricula.bairro;
                vm.matricula.logradouroPai = vm.matricula.logradouro;
                vm.matricula.numeroPai = vm.matricula.numero;
                vm.matricula.complementoPai = vm.matricula.complemento;
            } else {
                vm.matricula.cepPai = "";
                vm.matricula.ufPai = "";
                vm.matricula.cidadePai = "";
                vm.matricula.bairroPai = "";
                vm.matricula.logradouroPai = "";
                vm.matricula.numeroPai = "";
                vm.matricula.complementoPai = "";
            }
        }

        function altera_endereco_mae() {
            if (vm.endereco_mae) {
                vm.matricula.cepMae = vm.matricula.cep;
                vm.matricula.ufMae = vm.matricula.uf;
                vm.matricula.cidadeMae = vm.matricula.cidade;
                vm.matricula.bairroMae = vm.matricula.bairro;
                vm.matricula.logradouroMae = vm.matricula.logradouro;
                vm.matricula.numeroMae = vm.matricula.numero;
                vm.matricula.complementoMae = vm.matricula.complemento;
            } else {
                vm.matricula.cepMae = "";
                vm.matricula.ufMae = "";
                vm.matricula.cidadeMae = "";
                vm.matricula.bairroMae = "";
                vm.matricula.logradouroMae = "";
                vm.matricula.numeroMae = "";
                vm.matricula.complementoMae = "";
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function verificaCampos() {

            if (vm.matricula.nomeMae == null || vm.matricula.nomeMae == "") {
                //return "Dados da Mãe - o campo NOME não pode ser vazio";
                return { campo: "nomeMae", msg: "Dados da Mãe - verifique o campo nome" }
            }

            if (vm.matricula.nomeMae.length <= 2 || !vm.matricula.nomeMae.includes(" ")) {
                //return "O campo NOME deve receber no mínimo um sobrenome.";
                return { campo: "nomeMae", msg: "Dados da Mãe - informe o nome completo." }
            }

            if (vm.matricula.nacionalidadeMae == null || vm.matricula.nacionalidadeMae == "") {
                //return "Dados da Mãe - o campo NACIONALIDADE não pode ser vazio";
                return { campo: "nacionalidadeMae", msg: "Dados da Mãe - verifique o campo nacionalidade" }
            }
            if (vm.matricula.dataNascimentoMae == null || vm.matricula.dataNascimentoMae == "") {
                //return "Dados da Mãe - o campo DATA NASCIMENTO não pode ser vazio";]
                return { campo: "dataNascimentoMae", msg: "Dados da Mãe - verifique o campo data de nascimento" }
            }

            if (vm.matricula.falecidoMae == null) {
                //return "Dados da Mãe - o campo FALECIDO deve ser preenchido";
                return { campo: "falecidoMae", msg: "Dados da Mãe - verifique o campo falecido" }
            }
            if (vm.naturalidadeMae == null) {
                //return "Dados da Mãe - o campo CIDADE EM QUE NASCEU deve ser preenchido";
                return { campo: "naturalidadeMae", msg: "Dados da Mãe - verifique a cidade de nascimento" }
            }

            if (vm.matricula.falecidoMae == 'nao') {
                if (vm.matricula.cepMae == null || vm.matricula.cepMae == "") {
                    //return "Dados da Mãe - o campo CEP não pode ser vazio";
                    return { campo: "cepMae", msg: "Dados da Mãe - verifique o cep do endereço" }
                }
                if (vm.matricula.ufMae == null || vm.matricula.ufMae == "") {
                    //return "Dados da Mãe - o campo UF não pode ser vazio";
                    return { campo: "ufMae", msg: "Dados da Mãe - verifique o estado do endereço" }
                }
                if (vm.matricula.cidadeMae == null || vm.matricula.cidadeMae == "") {
                    //return "Dados da Mãe - o campo CIDADE não pode ser vazio";
                    return { campo: "cidadeMae", msg: "Dados da Mãe - verifique a cidade do endereço" }
                }
                if (vm.matricula.bairroMae == null || vm.matricula.bairroMae == "") {
                    //return "Dados da Mãe - o campo BAIRRO não pode ser vazio";
                    return { campo: "bairroMae", msg: "Dados da Mãe - verifique o bairro do endereço" }
                }
                if (vm.matricula.logradouroMae == null || vm.matricula.logradouroMae == "") {
                    //return "Dados da Mãe - o campo LOGRADOURO não pode ser vazio";
                    return { campo: "logradouroMae", msg: "Dados da Mãe - verifique a rua ou avenida do endereço" }
                }
                if (vm.matricula.numeroMae == null || vm.matricula.numeroMae == "") {
                    //return "Dados da Mãe - o campo NUMERO não pode ser vazio";
                    return { campo: "numeroMae", msg: "Dados da Mãe - verifique o numero do endereço" }
                }
                if (vm.matricula.numeroMae == 0) {
                    vm.matricula.numeroMae = 1;
                }
            }

            if (vm.inserePai == true) {

                if (vm.matricula.nomePai == null || vm.matricula.nomePai == "") {
                    //return "Dados do Pai - o campo NOME não pode ser vazio";
                    return { campo: "nomePai", msg: "Dados do Pai - informe o nome completo." }
                }
                if (vm.matricula.nomePai.length <= 2 || !vm.matricula.nomePai.includes(" ")) {
                    //return "O campo NOME deve receber no mínimo um sobrenome.";
                    return { campo: "nomePai", msg: "Dados do Pai - informe o nome completo." }
                }
                if (vm.matricula.nacionalidadePai == null || vm.matricula.nacionalidadePai == "") {
                    //return "Dados do Pai - o campo NACIONALIDADE não pode ser vazio";
                    return { campo: "nacionalidadePai", msg: "Dados do Pai - selecione a nacionalidade." }
                }
                if (vm.matricula.dataNascimentoPai == null || vm.matricula.dataNascimentoPai == "") {
                    //return "Dados do Pai - o campo DATA NASCIMENTO não pode ser vazio";
                    return { campo: "dataNascimentoPai", msg: "Dados do Pai - informe a data de nascimento." }
                }
                if (vm.matricula.falecidoPai == null) {
                    //return "Dados do Pai - o campo FALECIDO deve ser preenchido";
                    return { campo: "falecidoPai", msg: "Dados do Pai - verifique o campo falecido" }
                }
                if (vm.naturalidadePai == null) {
                    //return "Dados do Pai - o campo CIDADE EM QUE NASCEU deve ser preenchido";
                    return { campo: "naturalidadePai", msg: "Dados do Pai - verifique a cidade de nascimento" }
                }

                if (vm.matricula.falecidoPai == 'nao') {
                    if (vm.matricula.cepPai == null || vm.matricula.cepPai == "") {
                        //return "Dados do Pai - o campo CEP não pode ser vazio";
                        return { campo: "cepPai", msg: "Dados do Pai - verifique o cep do endereço" }
                    }
                    if (vm.matricula.ufPai == null || vm.matricula.ufPai == "") {
                        //return "Dados do Pai - o campo UF não pode ser vazio";
                        return { campo: "ufPai", msg: "Dados do Pai - verifique o estado do endereço" }
                    }
                    if (vm.matricula.cidadePai == null || vm.matricula.cidadePai == "") {
                        //return "Dados do Pai - o campo CIDADE não pode ser vazio";
                        return { campo: "cidadePai", msg: "Dados do Pai - verifique a cidade do endereço" }
                    }
                    if (vm.matricula.bairroPai == null || vm.matricula.bairroPai == "") {
                        //return "Dados do Pai - o campo BAIRRO não pode ser vazio";
                        return { campo: "bairroPai", msg: "Dados do Pai - verifique o bairro do endereço" }
                    }
                    if (vm.matricula.logradouroPai == null || vm.matricula.logradouroPai == "") {
                        //return "Dados do Pai - o campo LOGRADOURO não pode ser vazio";
                        return { campo: "logradouroPai", msg: "Dados do Pai - verifique a rua ou avenida do endereço" }
                    }
                    if (vm.matricula.numeroPai == null || vm.matricula.numeroPai == "") {
                        //return "Dados do Pai - o campo NUMERO não pode ser vazio";
                        return { campo: "numeroPai", msg: "Dados do Pai - verifique o numero do endereço" }
                    }

                    if (vm.matricula.numeroPai == 0) {
                        vm.matricula.numeroPai = 1;
                    }

                }
            }

            return "OK";

        }

        function alterInfo(opcao, i) {
            if (opcao == 1) {
                vm.estadoPai = i;
                vm.matricula.estadoNatalPai = i.sigla;
                vm.cidades = i.cidades;
            }

            if (opcao == 2) {
                vm.matricula.cidadeNatalPai = i;
            }

            if (opcao == 3) {
                vm.estadoMae = i;
                vm.matricula.estadoNatalMae = i.sigla;
                vm.cidadesMae = i.cidades;
            }

            if (opcao == 4) {
                vm.matricula.cidadeNatalMae = i;
            }

        }

        function save() {
            vm.isSaving = true;
            vm.load = true;
            vm.matricula.etapa = "PAIS";
            var valida = verificaCampos();
            console.log(valida);
            if (valida == "OK") {
                if (vm.inserePai == true) {
                    vm.matricula.estadoNatalPai = vm.naturalidadePai.uf;
                    vm.matricula.cidadeNatalPai = vm.naturalidadePai.cidade;
                }

                vm.matricula.estadoNatalMae = vm.naturalidadeMae.uf;
                vm.matricula.cidadeNatalMae = vm.naturalidadeMae.cidade;

                if (vm.matricula.falecidoMae == 'sim') {
                    vm.matricula.falecidoMae = true;
                } else {
                    vm.matricula.falecidoMae = false;
                }

                if (vm.matricula.falecidoPai == 'sim') {
                    vm.matricula.falecidoPai = true;
                } else {
                    vm.matricula.falecidoPai = false;
                }
                console.log(vm.matricula);
                PreMatriculaDadosCadastraisServiceController.save(vm.matricula, onSaveSuccess, onSaveError);
            } else {
                vm.isSaving = false;
                vm.load = false;
                showErrorForm(valida);
            }
        }

        function onSaveSuccess(result) {
            vm.load = false;
            vm.isSaving = false;
            $state.go('pre-matricula-dados-cadastrais-responsavel');
        }

        function onSaveError(status) {
            vm.load = false;
            vm.isSaving = false;
            console.log(status);
            showErrorForm({ campo: "erro", msg: "Ocorreu algum problema ao processar os dados, por favor entre em contato com seu consultor." });
        }

        function buscaCEPPai() {
            vm.cepsearch = true;
            if (vm.matricula.cepPai !== null && vm.matricula.cepPai !== undefined) {
                $http.post("/api/getCepInfos", { cep: vm.matricula.cepPai })
                    .success(function(data) {

                        console.log(data);

                        vm.matricula.ufPai = data.uf;
                        vm.matricula.cidadePai = data.localidade;
                        vm.matricula.bairroPai = data.bairro;
                        vm.matricula.logradouroPai = data.logradouro;
                        vm.cepsearch = false;
                    })
                    .error(function(status) {
                        console.log(status);
                        vm.cepsearch = false;
                    });
            }
        }

        function buscaCEPMae() {
            vm.cepsearch = true;
            if (vm.matricula.cepMae !== null && vm.matricula.cepMae !== undefined) {
                $http.post("/api/getCepInfos", { cep: vm.matricula.cepMae })
                    .success(function(data) {

                        console.log(data);

                        vm.matricula.ufMae = data.uf;
                        vm.matricula.cidadeMae = data.localidade;
                        vm.matricula.bairroMae = data.bairro;
                        vm.matricula.logradouroMae = data.logradouro;
                        vm.cepsearch = false;
                    })
                    .error(function(status) {
                        console.log(status);
                        vm.cepsearch = false;
                    });
            }
        }

        function change() {

            for (var i = 0; i < vm.estados.length; i++) {
                var estado = vm.estados[i];

                if (estado.sigla == vm.estadoPai) {
                    vm.cidades = estado.cidades;
                    break;
                }
            }
        }

        function changeMae() {

            for (var i = 0; i < vm.estados.length; i++) {
                var estado = vm.estados[i];

                if (estado.sigla == vm.estadoMae) {
                    vm.cidadesMae = estado.cidades;
                    break;
                }
            }
        }

        /*   referente aos estados   */

        vm.querySearchMae = querySearchMae;
        vm.selectedItemChangeMae = selectedItemChangeMae;
        vm.searchTextChangeMae = searchTextChangeMae;

        function querySearchMae(query) {

            var results = query ? vm.cidadesMae.filter(createFilterForMae(query)) : vm.cidadesMae,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangeMae(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangeMae(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForMae(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {

                return state.cidade.toUpperCase().includes(lowercaseQuery);
            };

        }

        vm.querySearchPai = querySearchPai;
        vm.selectedItemChangePai = selectedItemChangePai;
        vm.searchTextChangePai = searchTextChangePai;

        function querySearchPai(query) {

            var results = query ? vm.cidadesPai.filter(createFilterForPai(query)) : vm.cidadesPai,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangePai(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangePai(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForPai(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {

                return state.cidade.toUpperCase().includes(lowercaseQuery);
            };

        }

        vm.cidadesPai = CidadesMDJsonService;
        vm.cidadesMae = CidadesMDJsonService;
    }
})();