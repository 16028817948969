(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ContratoMatriculaAntigoController', ContratoMatriculaAntigoController);

    ContratoMatriculaAntigoController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'LoginService', '$http', '$sessionStorage'];

    function ContratoMatriculaAntigoController($timeout, $scope, $state, $stateParams, LoginService, $http, $sessionStorage) {
        var vm = this;
        vm.load = false;
        vm.buscarContrato = buscarContrato;

        function buscarContrato() {
            var url = 'api/rematricula-getAlunoBycodperlet/' + vm.ra.replace(".", "-") + "/" + vm.codPerlet.replace("/", "-");
            $http.get(url)
                .success(function(data) {
                    console.log(data);
                    vm.academico = data;

                    $http.post('api/rematricula-getcontrato-antigo', vm.academico, { responseType: 'arraybuffer' })
                        .success(function(data) {

                            var file = new Blob([(data)], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);

                            var a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";

                            a.href = fileURL;
                            a.download = vm.academico.nome + " - Contrato.pdf";
                            a.click();

                            window.URL.revokeObjectURL(fileURL);
                            vm.load = false;
                        }).error(function(status) {
                            console.log(status);
                        });
                }).error(function(status) {
                    console.log(status);
                });
        }
    }
})();