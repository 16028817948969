(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlanoEnsinoDeleteController',PlanoEnsinoDeleteController);

    PlanoEnsinoDeleteController.$inject = ['$uibModalInstance', 'entity', 'PlanoEnsino'];

    function PlanoEnsinoDeleteController($uibModalInstance, entity, PlanoEnsino) {
        var vm = this;

        vm.planoEnsino = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PlanoEnsino.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
