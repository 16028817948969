(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ProvaOnlineDetailController', ProvaOnlineDetailController);

    ProvaOnlineDetailController.$inject = ['$scope', '$rootScope', '$state', 'previousState', 'entity', 'ProvaOnline', 'BuscaTemaIDService', 'Principal', 'CorrecaoRedacaoService', '$http'];

    function ProvaOnlineDetailController($scope, $rootScope, $state, previousState, entity, ProvaOnline, BuscaTemaIDService, Principal, CorrecaoRedacaoService, $http) {
        var vm = this;

        vm.provaOnline = entity;
        vm.previousState = previousState.name;
        vm.save = save;
        vm.enviar = enviarParaCorrecao;
        vm.corrigir = validarCorrecao;
        vm.enviarNotaRm = enviarNotaRm;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                console.log(vm.account);
            });
        }

        if (vm.provaOnline.tipo == 'AGENDADO') {
            BuscaTemaIDService.query({ id: vm.provaOnline.tema }, function(result) {
                vm.tema = result[0];
            });
        }


        function save() {
            vm.provaOnline.userCorrecao = vm.account.login;
            vm.provaOnline.dataCorrecao = new Date();
            ProvaOnline.update(vm.provaOnline, onSaveSuccess, onSaveError);
        }

        var unsubscribe = $rootScope.$on('vestibularApp:provaOnlineUpdate', function(event, result) {
            vm.provaOnline = result;
        });

        $scope.$on('$destroy', unsubscribe);

        function onSaveSuccess() {
            $state.go(vm.previousState);
        }

        function onSaveError() {
            alert('falha ao atualizar a prova online.');
        }

        function enviarNotaRm() {

            $http.post("/api/envia-nota-rm", vm.provaOnline)
                .success(function(result) {
                    alert("processo finalizado, verifique a nota no RM");
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        String.prototype.replaceAll = function(search, replacement) {
            var target = this;
            return target.split(search).join(replacement);
        };

        function enviarParaCorrecao() {

            var p = {
                texto: vm.provaOnline.texto.replace(/(\r\n|\n|\r)/gm, "").replaceAll('"', "'"),
                titulo: vm.provaOnline.titulo,
                tipo: vm.provaOnline.tipo,
                tema: vm.provaOnline.tema,
                nome: vm.provaOnline.nome,
                email: vm.provaOnline.email,
                cpf: vm.provaOnline.cpf,
                telefone: vm.provaOnline.telefone,
                inscricao: vm.provaOnline.inscricao,
                id: vm.provaOnline.id
            }

            $http.post("/api/createRedacaoCorrecao", p)
                .success(function(redacao) {
                    // console.log("Redacao Enviada: ");
                    // console.log(p);
                    // console.log("Retorno: ");
                    // console.log(redacao);
                    if (redacao.id !== null) {
                        vm.provaOnline.uuid = redacao.id;
                        ProvaOnline.update(vm.provaOnline);
                        $state.go('prova-online');
                    } else {
                        alert(redacao);
                    }
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        String.prototype.replaceAll = function(search, replacement) {
            var target = this;
            return target.split(search).join(replacement);
        };

        function validarCorrecao() {
            var infos = "";
            var nota = 0;
            CorrecaoRedacaoService.query(vm.provaOnline,
                function(result) {
                    console.log(result);

                    for (var i = 0; i < result.corrections.length; i++) {
                        var e = result.corrections[i];

                        infos = infos + "(" + e.competence_1_rate + ") - " + e.competence_1_comment + " \n\n " +
                            "(" + e.competence_2_rate + ") - " + e.competence_2_comment + " \n\n " +
                            "(" + e.competence_3_rate + ") - " + e.competence_3_comment + " \n\n " +
                            "(" + e.competence_4_rate + ") - " + e.competence_4_comment + " \n\n " +
                            "(" + e.competence_5_rate + ") - " + e.competence_5_comment + " \n\n " +
                            "(" + e.nota + ") - " + e.general_comment;
                        nota = e.nota;
                    }

                    vm.provaOnline.obs = vm.provaOnline.obs + " -> " + infos;
                    vm.provaOnline.nota = nota;

                    if (nota < 400) {
                        vm.provaOnline.situacao = 'REPROVADOONLINE';
                    } else {
                        vm.provaOnline.situacao = 'APROVADOONLINE';
                    }

                    save();
                },
                function(status) {
                    console.log(status);
                }
            )
        }

    }
})();