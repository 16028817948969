(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('IntegracaoNormal', IntegracaoNormal);

    IntegracaoNormal.$inject = ['$resource', 'DateUtils'];

    function IntegracaoNormal($resource, DateUtils) {
        var resourceUrl = 'api/rematricula-final';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: false }
        });
    }
})();