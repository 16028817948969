(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ConsultaHorarioPolo', ConsultaHorarioPolo);

    ConsultaHorarioPolo.$inject = ['$resource', 'DateUtils'];

    function ConsultaHorarioPolo($resource, DateUtils) {
        var resourceUrl = 'api/horario-polos/:idPolo/:dia';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();