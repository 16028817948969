(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('SendLeadService', SendLeadService);

SendLeadService.$inject = ['$resource'];

    function SendLeadService($resource) {
        var resourceUrl = 'https://www.univel.br/leads/admin/dao/lead_trad.php';

        return $resource(resourceUrl, {}, {
            'save': {
                method: 'POST',
                headers: {'Authorization':'Basic 123456789'},
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();