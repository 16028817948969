(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('QuestaoProvaOnlineDialogController', QuestaoProvaOnlineDialogController);

    QuestaoProvaOnlineDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'QuestaoProvaOnline', 'BuscaRespostasQuestaoService', 'RespostaProvaOnline', 'DeleteRespostasQuestaoService'];

    function QuestaoProvaOnlineDialogController($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, QuestaoProvaOnline, BuscaRespostasQuestaoService, RespostaProvaOnline, DeleteRespostasQuestaoService) {
        var vm = this;

        vm.questaoProvaOnline = entity;

        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;

        if (vm.questaoProvaOnline == null) {
            vm.questaoProvaOnline.id = null;
            vm.questaoProvaOnline.tipo = "OBJETIVA";
            vm.questaoProvaOnline.categoria = "CONHECIMENTOSGERAIS";
            vm.questaoProvaOnline.nivel = 0;
        } else {
            if (vm.questaoProvaOnline.id != null) {
                carregaAlternativas();
            }
        }

        function carregaAlternativas() {
            BuscaRespostasQuestaoService.query({ idQuestao: vm.questaoProvaOnline.id }, function(result) {
                vm.respostasInseridas = result;
            }, function(status) {
                console.log(status);
            });
        }


        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.questaoProvaOnline.id !== null) {
                QuestaoProvaOnline.update(vm.questaoProvaOnline, onSaveSuccess, onSaveError);
            } else {
                QuestaoProvaOnline.save(vm.questaoProvaOnline, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {

            /*DeleteRespostasQuestaoService.query({ idQuestao: vm.questaoProvaOnline.id }, function(result) {
                for (var i = 0; i < vm.respostasInseridas.length; i++) {
                    var e = vm.respostasInseridas[i];

                    if (vm.respostaProvaOnline.id !== null) {
                        RespostaProvaOnline.update(vm.respostaProvaOnline, function(result) { console.log(result) }, function(status) { console.log(status) });
                    } else {
                        RespostaProvaOnline.save(vm.respostaProvaOnline, function(result) { console.log(result) }, function(status) { console.log(status) });
                    }
                }
            }, function(status) {
                console.log(status);
            });*/


            $scope.$emit('vestibularApp:questaoProvaOnlineUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError(status) {
            console.log(status);
            vm.isSaving = false;
        }


        vm.setImagem = function($file, questaoProvaOnline) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        questaoProvaOnline.imagem = base64Data;
                        questaoProvaOnline.imagemContentType = $file.type;
                    });
                });
            }
        };



        // relacionado ao cadastro de respostas na questão
        vm.cadastroResposta = {};
        vm.cadastroResposta.valor = '0';
        vm.respostasInseridas = [];
        vm.limpaCadastroResposta = limpaCadastroResposta;
        vm.salvaAlternativaQuestao = insereAlternativa;
        vm.removeAlternativa = removeAlternativa;
        vm.editAlternativa = editAlternativa;

        function limpaCadastroResposta() {
            vm.cadastroResposta = {};
            vm.cadastroResposta.valor = '0';
        }

        function insereAlternativa() {

            if (vm.questaoProvaOnline.id == null) {
                QuestaoProvaOnline.save(vm.questaoProvaOnline,
                    function(result) {
                        vm.questaoProvaOnline = result;
                        RespostaProvaOnline.save({
                            id: null,
                            idQuestao: vm.questaoProvaOnline.id,
                            descricao: vm.cadastroResposta.descricao,
                            valor: vm.cadastroResposta.valor
                        }, function(result) {
                            console.log(result);
                            vm.cadastroResposta = {};
                            vm.cadastroResposta.valor = '0';

                            carregaAlternativas();
                        }, function(status) { console.log(status) });
                    },
                    function(status) {
                        console.log(status);
                    });
            } else {
                RespostaProvaOnline.save({
                    id: null,
                    idQuestao: vm.questaoProvaOnline.id,
                    descricao: vm.cadastroResposta.descricao,
                    valor: vm.cadastroResposta.valor
                }, function(result) {
                    console.log(result);
                    vm.cadastroResposta = {};
                    vm.cadastroResposta.valor = '0';

                    carregaAlternativas();
                }, function(status) { console.log(status) });
            }
        }

        function removeAlternativa(item) {


            RespostaProvaOnline.delete(item, function(result) {
                carregaAlternativas();
            }, function(status) { console.log(status) });

            /*for (var i = 0; i < vm.respostasInseridas.length; i++) {
                var e = vm.respostasInseridas[i];

                if (e.descricao == item.descricao && e.valor == item.valor) {
                    vm.respostasInseridas.splice(i, 1);
                }

            }*/
        }

        function editAlternativa(item) {

            if (item.id !== null) {
                RespostaProvaOnline.update(item, function(result) { carregaAlternativas() }, function(status) { console.log(status) });
            } else {
                item.idQuestao = vm.questaoProvaOnline.id;
                RespostaProvaOnline.save(item, function(result) { console.log(result) }, function(status) { console.log(status) });
            }

        }

    }
})();