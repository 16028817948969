(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ChecksGamePlayService', ChecksGamePlayService);

    ChecksGamePlayService.$inject = ['$resource', 'DateUtils'];

    function ChecksGamePlayService($resource, DateUtils) {
        var resourceUrl = 'api/get-checks-game/:codigo';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false },
        });
    }
})();