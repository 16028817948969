(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConfirmaNomeController', ConfirmaNomeController);

    ConfirmaNomeController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', '$sessionStorage', 'IndicacaoConsulta', 'param'];

    function ConfirmaNomeController($scope, $rootScope, $state, $timeout, Auth, $uibModalInstance, $sessionStorage, IndicacaoConsulta, param) {
        var vm = this;

        // variables
        vm.load = false;
        vm.selected = false;
        vm.nome1 = param.nome1;
        vm.nome2 = param.nome2;

        console.log(vm.nome1);
        console.log(vm.nome2);

        $scope.$watch('vm.selecionado', function(newvalue) {
            $rootScope.$emit('selecionou', newvalue);
        });

        // functions
        vm.confirm = confirm;
        vm.selecionando = selecionando;
        vm.fechar = fechar;

        function selecionando(nome) {
            console.log(nome);
            vm.selecionado = nome;
        }

        function confirm() {
            $uibModalInstance.close(vm.selecionado);
        }

        function fechar() {
            vm.selecionado = null;
            $uibModalInstance.close(null);
        }
    }
})();