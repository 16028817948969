(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConfirmacaoInscricaoTradicionalPlayController', ConfirmacaoInscricaoTradicionalPlayController);

    ConfirmacaoInscricaoTradicionalPlayController.$inject = ['$timeout', 'DateUtils', '$scope', '$state', '$stateParams', '$sessionStorage'];

    function ConfirmacaoInscricaoTradicionalPlayController($timeout, DateUtils, $scope, $state, $stateParams, $sessionStorage) {
        var vm = this;

        $state.go('home-tradicional');

        vm.redirect = redirect;
        vm.dados = undefined;

        loadCandidato();

        function loadCandidato() {
            vm.dados = JSON.parse(sessionStorage.getItem('candidato-vestibular-2018'));
            sessionStorage.removeItem('candidato-vestibular-2018');
        }

        setTimeout(function() {
            $state.go('play-vestiba');
        }, 5000);

        function redirect(value) {
            $state.go(value);
        }
    }
})();