(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ValoresCursoDeleteController',ValoresCursoDeleteController);

    ValoresCursoDeleteController.$inject = ['$uibModalInstance', 'entity', 'ValoresCurso'];

    function ValoresCursoDeleteController($uibModalInstance, entity, ValoresCurso) {
        var vm = this;

        vm.valoresCurso = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ValoresCurso.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
