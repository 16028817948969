(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ConsultaHorarioPoloMes', ConsultaHorarioPoloMes);

    ConsultaHorarioPoloMes.$inject = ['$resource', 'DateUtils'];

    function ConsultaHorarioPoloMes($resource, DateUtils) {
        var resourceUrl = 'api/horario-polos-dash/:idPolo';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();