(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('CursoPsController', CursoPsController);

    CursoPsController.$inject = ['$state', 'CursoPs', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'PSAtivos', 'ConsultaCursosByidps', 'PolosAtivos'];

    function CursoPsController($state, CursoPs, ParseLinks, AlertService, paginationConstants, pagingParams, PSAtivos, ConsultaCursosByidps, PolosAtivos) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        PSAtivos.query(function(result) {
            vm.processos = result;
        }, function(status) {
            console.log(status);
        });

        PolosAtivos.query(function(result) {
            vm.polos = result;
        }, function(status) {
            console.log(status);
        });

        vm.consultar = function() {

            if (vm.processoSelecionado == null){
                showMessage('alert-warning', 'Por favor selecione um Processo Seletivo.')
            }else{
                if (vm.poloSelecionado == null){
                    showMessage('alert-warning', 'Por favor selecione um polo.')
                }else{
                    ConsultaCursosByidps.query({ idps: vm.processoSelecionado.idps, idPolo: vm.poloSelecionado.id },
                        function(result) {
                            vm.cursoPs = result;
                        },
                        function(status) {
                            console.log(status);
                        });    
                }
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

    }
})();
