(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaIdAreaCursoTradService', BuscaIdAreaCursoTradService);

    BuscaIdAreaCursoTradService.$inject = ['$resource', 'DateUtils'];

    function BuscaIdAreaCursoTradService($resource, DateUtils) {
        var resourceUrl = '/api/get-idareainteresse-curso-tradicional/:idps/:idCurso';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }
})();