(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('IndicacaoServicePlay', IndicacaoServicePlay);

    IndicacaoServicePlay.$inject = ['$uibModal', '$rootScope'];

    function IndicacaoServicePlay($uibModal, $rootScope) {
        var service = {
            open: open,
            getRetorno: retorno
        };

        var modalInstance = null;
        var retorno = null;

        var resetModal = function() {
            modalInstance = null;
        };

        return service;

        function open(indica) {
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/inscricaoTradicional/new_inscricao_by_play/indicacao.html',
                controller: 'IndicacaoController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    param: function() {
                        return { 'data': indica };
                    }
                }
            });
            modalInstance.result.then(function(selectedItem) {
                retorno = selectedItem;
                modalInstance = null;
            }, function() {
                modalInstance = null;
            });
        }
    }
})();