(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaDiscsPlanoIdService', BuscaDiscsPlanoIdService);

        BuscaDiscsPlanoIdService.$inject = ['$resource', 'DateUtils'];

    function BuscaDiscsPlanoIdService($resource, DateUtils) {
        var resourceUrl = '/api/busca-disc-plano-id/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();