(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaDiplomaBoletoController', MatriculaDiplomaBoletoController);

    MatriculaDiplomaBoletoController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$http', '$sessionStorage', 'Financeiro', 'ConsultaFinanceiroAberto'];

    function MatriculaDiplomaBoletoController($timeout, $scope, $state, $stateParams, $http, $sessionStorage, Financeiro, ConsultaFinanceiroAberto) {
        var vm = this;

        vm.matricula = JSON.parse(sessionStorage.getItem('user-matric-diploma'));
        vm.load = false;
        vm.gerarBoleto = gerarBoleto;
        vm.gerarContrato = gerarContrato;
        vm.gerarPgto = gerarPgto;
        vm.liberado = true;
        vm.pgtos = null;

        var today = new Date();
        var inicio = new Date('2021', '06', '19', '00', '00', '0000');
        //var inicio = new Date('2021', '06', '15', '00', '00', '0000');
        var fim = new Date('2021', '07', '12', '23', '59', '0000');
        
        console.log(inicio);
        console.log(fim);

        if (today < inicio) {
            vm.habilitado = "BEFORE";
        } else {
            if (today >= fim) {
                vm.habilitado = "AFTER";
            } else {
                vm.habilitado = "OPEN";
            }
        }

        if (vm.matricula.tipoIngresso == 1){
            if (vm.habilitado == 'OPEN') {
                vm.habilitadoN = false;    
            }else{
                vm.habilitadoN = true;    
            }
        }else{
            vm.habilitadoN = true;
        }

        console.log(vm.habilitado);

        loadFinanceiroCandidato();

        function loadFinanceiroCandidato() {
            ConsultaFinanceiroAberto.query(vm.matricula.academico,
                function(result) {
                    vm.pgtos = result;
                    console.log(result);
                    for (var i = 0; i < result.length; i++) {
                        var e = result[i];

                        if (e.statusCielo == 2 || e.statusCielo == 7 || e.statusCielo == 1 || e.statusCielo == 0 || e.statusCielo == 10) {
                            vm.liberado = false
                        }
                    }
                });
        }

        function gerarBoleto() {

            vm.load = true;

            $http.post('/api/matricula-diploma-getboleto/', vm.matricula, { responseType: 'arraybuffer' })
                .success(function(data) {
                    console.log(data);

                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    a.href = fileURL;
                    a.download = vm.matricula.academico.nome + " - Boleto.pdf";
                    a.click();

                    window.URL.revokeObjectURL(fileURL);
                    vm.load = false;


                }).error(function(status) {
                    console.log(status);
                });;

        }


        function gerarContrato() {

            vm.load = true;

            $http.post('/api/matricula-diploma-getcontrato/', vm.matricula, { responseType: 'arraybuffer' })
                .success(function(data) {

                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    a.href = fileURL;
                    a.download = vm.matricula.academico.nome + " - Contrato.pdf";
                    a.click();

                    window.URL.revokeObjectURL(fileURL);

                    vm.load = false;


                }).error(function(status) {
                    console.log(status);
                });;

        }

        function gerarPgto() {
            vm.liberado = false;
            $http.post("/api/createcheckout", vm.matricula.academico)
                .success(function(result) {
                    console.log(result);

                    Financeiro.get({ id: result.orderNumber.substring(3) },
                        function(f) {

                            var a = result.settings.checkoutUrl.split("/");
                            var len = a.length;

                            f.idCheckOutCielo = a[len - 1];

                            Financeiro.update(f, function(result) {
                                //console.log(result);
                            }, function(status) {
                                console.log(status);
                            });
                        });


                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = result.settings.checkoutUrl;
                    a.click();
                    vm.load = false;
                })
                .error(function(status) {
                    console.log(status);
                });
        }

    }
})();