(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ProvaOnlineQuestoesController', ProvaOnlineQuestoesController);

    ProvaOnlineQuestoesController.$inject = ['$scope', '$state', '$http', '$sessionStorage', 'QuestaoProvaService', 'SalvaRespostaSelecionada', 'FinalProvaService', 'FinalProvaOnline', 'SessaoProvaOnlineService', 'ProvaOnline'];

    function ProvaOnlineQuestoesController($scope, $state, $http, $sessionStorage, QuestaoProvaService, SalvaRespostaSelecionada, FinalProvaService, FinalProvaOnline, SessaoProvaOnlineService, ProvaOnline) {
        var vm = this;

        vm.questaoAtual = 1;
        vm.tempo = 10740;
        vm.trocaQuestao = trocaQuestao;
        vm.iniciarProva = iniciarProva;
        vm.buscarSessao = buscarSessao;
        vm.fecharAlert = fecharAlert;

        vm.prova = null;

        vm.perguntas = [];
        vm.respostasAluno = [];
        vm.respostaSelecionada == null

        vm.waitTroca = false;

        vm.liberado = false;
        vm.finalizado = false;

        vm.etapa = 1

        init();


        function init() {

            /* PRIMEIRAMENTO DEVEMOS BUSCAR AS QUESTÕES NO BANCO DE DADOS  */
            /* não tem como o cara chegar aqui sem ter feito a redação, entao tem que ter a prova do cara na sessão */

            vm.prova = JSON.parse(sessionStorage.getItem('prova-online'));
            vm.listaQuestao = JSON.parse(sessionStorage.getItem('prova-online-questao-aluno'));
            vm.respostasAluno = JSON.parse(sessionStorage.getItem('respostas-aluno'));

            /** vamos valida a prova do cara, tem que ter, se nao tiver deu pau */
            if (vm.prova != null) {
                /** o cara veio recarregado, vamos alocar as informações */
                console.log('carregado');
                /** tempo de prova objetiva decorrida */
                if (vm.prova.tempoObj != null && vm.prova.tempoObj !== 0) {
                    vm.tempo = vm.prova.tempoObj;
                    console.log('reutilizou o tempo');
                }

                /** questão que o aluno parou: operador ou item.id (não é o id da questão e sim a ordenação) */
                if (vm.prova.questao !== null && vm.prova.questaoAtual !== 0) {
                    vm.questaoAtual = vm.prova.questao;
                    console.log('pegou o index da questao');
                }

                /** vamos verificar se já temos as questoes na sessão (recarregamento) */
                if (vm.listaQuestao != null) {
                    /** se tiver vamos colocar no array principal */

                    console.log("Tem questao na sessao!");

                    vm.perguntas = vm.listaQuestao;
                    sessionStorage.setItem('prova-online-questao-aluno', JSON.stringify(vm.perguntas));
                    setTimeout(function() {
                        document.getElementById("Q" + vm.questaoAtual).style.display = "block";
                    }, 500);

                    // verifica se tem as respostas na sessão
                    if (vm.respostasAluno == null) {
                        // como não tem vamos para o banco de dedos

                        console.log("Não tem respostas");

                        FinalProvaService.query(vm.prova,
                            function(result) {
                                vm.respostasAluno = result;
                                sessionStorage.setItem('respostas-aluno', JSON.stringify(vm.respostasAluno));

                                // vamos correr o array para resposta atual da questao nova
                                for (var y = 0; y < vm.respostasAluno.length; y++) {
                                    var e = vm.respostasAluno[y];

                                    if (e.operador == vm.questaoAtual) {
                                        vm.respostaSelecionada = e.idResposta;
                                    }
                                }

                                vm.liberado = true;
                            },
                            function(status) {
                                console.log(status);
                            });
                    } else {
                        // como tem vamos apenas sincronizar
                        console.log("Tem respostas");
                        for (var y = 0; y < vm.respostasAluno.length; y++) {
                            var e = vm.respostasAluno[y];

                            if (e.operador == vm.questaoAtual) {
                                vm.respostaSelecionada = e.idResposta;
                            }
                        }

                        vm.liberado = true;
                    }
                } else {
                    /** como nao possui vamos para o banco de dados */
                    console.log("Não tem Questão");
                    QuestaoProvaService.query(vm.prova, function(result) {
                        //console.log(result);
                        /*for (var i = 0; i < result.length; i++) {
                            var e = result[i];
                            vm.temp.push({ id: (i + 1), questao: e });
                        }
                        vm.perguntas = vm.temp;*/
                        vm.perguntas = result;
                        sessionStorage.setItem('prova-online-questao-aluno', JSON.stringify(vm.perguntas));

                        setTimeout(function() {
                            document.getElementById("Q" + vm.questaoAtual).style.display = "block";
                        }, 500);

                        // verifica se tem as respostas na sessão
                        if (vm.respostasAluno == null) {
                            // como não tem vamos para o banco de dedos
                            console.log("Não tem respostas");
                            FinalProvaService.query(vm.prova,
                                function(result) {
                                    vm.respostasAluno = result;
                                    sessionStorage.setItem('respostas-aluno', JSON.stringify(vm.respostasAluno));

                                    // vamos correr o array para resposta atual da questao nova
                                    for (var y = 0; y < vm.respostasAluno.length; y++) {
                                        var e = vm.respostasAluno[y];

                                        if (e.operador == vm.questaoAtual) {
                                            vm.respostaSelecionada = e.idResposta;
                                        }
                                    }

                                    vm.liberado = true;
                                },
                                function(status) {
                                    console.log(status);
                                });
                        } else {
                            // como tem vamos apenas sincronizar
                            console.log("Tem respostas");
                            for (var y = 0; y < vm.respostasAluno.length; y++) {
                                var e = vm.respostasAluno[y];

                                if (e.operador == vm.questaoAtual) {
                                    vm.respostaSelecionada = e.idResposta;
                                }
                            }

                            vm.liberado = true;
                        }

                    }, function(state) {
                        console.log(state);
                    });
                }
            } else {
                vm.etapa = 2;
                vm.prova = {};
            }
        }

        function trocaQuestao(idQuestaoAtual, idProxima, idQuestaoAtualBanco) {

            vm.waitTroca = true;

            //console.log(idQuestaoAtual + "-" + idProxima);

            // verifica se nao ta voltando pra 0
            if (idProxima == 0) {
                // se ta volta pra 1
                idProxima = 1;
            } else {
                //senão segue o baile
                //verifica se nao ta avançando para a 31
                if (idProxima > 30) {
                    //se ele tá vamo manter ele na 30
                    idProxima = idQuestaoAtual;

                    // VALIDA SE A QUESTÃO JA TEM NO ARRAY DE RESPOSTAS
                    var valida = false;
                    var respostaAtual = null;
                    var positionAntiga = null;

                    for (var x = 0; x < vm.respostasAluno.length; x++) {
                        var e = vm.respostasAluno[x];

                        if (e.operador == vm.questaoAtual) {
                            valida = true;
                            respostaAtual = e;
                            positionAntiga = x;
                        }
                    }

                    if (valida) {
                        // SE JA TEM VAMOS VER SE ELA MUDOU

                        if (respostaAtual.idResposta != vm.respostaSelecionada) {

                            // SE ELA MUDOU VAMOS SALVAR NO BANCO E MUDAR TUDO
                            respostaAtual.idResposta = vm.respostaSelecionada;

                            FinalProvaOnline.update(respostaAtual,
                                function(result) {
                                    //console.log(result);
                                    // removendo a resposta antiga da questao atual
                                    vm.respostasAluno.splice(positionAntiga, 1);
                                    // add a resposta nova da questao atual
                                    vm.respostasAluno.push(result);
                                    // salvando o array
                                    sessionStorage.setItem('respostas-aluno', JSON.stringify(vm.respostasAluno));

                                    vm.waitTroca = false;
                                    enviar();
                                },
                                function(status) {
                                    console.log(status)
                                    vm.waitTroca = false;
                                    vm.questaoAtual = idQuestaoAtual;
                                    alert('Não foi possível salvar a resposta da questao 30, aguarde alguns segundos e tente finalizar novamente');
                                });
                        } else {
                            // a resposta atual nao mudou entao finaliza;
                            vm.waitTroca = false;
                            enviar();
                        }

                    } else {
                        // AINDA NAO TEM RESPOSTA PARA ESSA QUESTAO

                        var temp = {
                            id: null,
                            idProva: vm.prova.id,
                            idQuestao: idQuestaoAtualBanco,
                            idResposta: vm.respostaSelecionada,
                            operador: vm.questaoAtual
                        }

                        SalvaRespostaSelecionada.query(temp,
                            function(result) {
                                //console.log(result);

                                // add a resposta nova da questao atual
                                vm.respostasAluno.push(result);
                                // salvando o array
                                sessionStorage.setItem('respostas-aluno', JSON.stringify(vm.respostasAluno));

                                // alterando a questão
                                vm.waitTroca = false;
                                enviar();
                            },
                            function(status) {
                                console.log(status)
                                vm.waitTroca = false;
                                vm.questaoAtual = idQuestaoAtual;
                                alert('Não foi possível salvar a resposta da questao 30, aguarde alguns segundos e tente avançar novamente');
                            });
                    }
                } else {
                    if (idProxima > idQuestaoAtual) {
                        // ele avançou

                        if (vm.respostaSelecionada == null) {
                            vm.waitTroca = false;
                            alert('Por favor selecione alguma alternativa.');
                        } else {

                            // VALIDA SE A QUESTÃO JA TEM NO ARRAY DE RESPOSTAS
                            var valida = false;
                            var respostaAtual = null;
                            var positionAntiga = null;

                            for (var x = 0; x < vm.respostasAluno.length; x++) {
                                var e = vm.respostasAluno[x];

                                if (e.operador == vm.questaoAtual) {
                                    valida = true;
                                    respostaAtual = e;
                                    positionAntiga = x;
                                }
                            }

                            if (valida) {
                                // SE JA TEM VAMOS VER SE ELA MUDOU

                                if (respostaAtual.idResposta != vm.respostaSelecionada) {

                                    // SE ELA MUDOU VAMOS SALVAR NO BANCO E MUDAR TUDO
                                    respostaAtual.idResposta = vm.respostaSelecionada;

                                    FinalProvaOnline.update(respostaAtual,
                                        function(result) {
                                            //console.log(result);
                                            // removendo a resposta antiga da questao atual
                                            vm.respostasAluno.splice(positionAntiga, 1);
                                            // add a resposta nova da questao atual
                                            vm.respostasAluno.push(result);
                                            // salvando o array
                                            sessionStorage.setItem('respostas-aluno', JSON.stringify(vm.respostasAluno));


                                            // vamos correr o array para resposta atual da questao nova
                                            for (var y = 0; y < vm.respostasAluno.length; y++) {
                                                var e = vm.respostasAluno[y];

                                                if (e.operador == idProxima) {
                                                    vm.respostaSelecionada = e.idResposta;
                                                }
                                            }

                                            // alterando a questão
                                            vm.waitTroca = false;
                                            vm.questaoAtual = idProxima;
                                        },
                                        function(status) {
                                            console.log(status)
                                            vm.waitTroca = false;
                                            vm.questaoAtual = idQuestaoAtual;
                                            alert('Não foi possível salvar a resposta selecionada, aguarde alguns segundos e tente avançar novamente');
                                        });
                                } else {
                                    // a resposta atual nao mudou entao vamos carregar a nova
                                    // vamos correr o array para resposta atual da questao nova
                                    for (var y = 0; y < vm.respostasAluno.length; y++) {
                                        var e = vm.respostasAluno[y];

                                        if (e.operador == idProxima) {
                                            vm.respostaSelecionada = e.idResposta;
                                        }
                                    }

                                    // alterando a questão
                                    vm.waitTroca = false;
                                    vm.questaoAtual = idProxima;
                                }

                            } else {
                                // AINDA NAO TEM RESPOSTA PARA ESSA QUESTAO

                                var temp = {
                                    id: null,
                                    idProva: vm.prova.id,
                                    idQuestao: idQuestaoAtualBanco,
                                    idResposta: vm.respostaSelecionada,
                                    operador: vm.questaoAtual
                                }

                                SalvaRespostaSelecionada.query(temp,
                                    function(result) {
                                        //console.log(result);

                                        // add a resposta nova da questao atual
                                        vm.respostasAluno.push(result);
                                        // salvando o array
                                        sessionStorage.setItem('respostas-aluno', JSON.stringify(vm.respostasAluno));

                                        // vamos correr o array para resposta atual da questao nova
                                        for (var y = 0; y < vm.respostasAluno.length; y++) {
                                            var e = vm.respostasAluno[y];

                                            if (e.operador == idProxima) {
                                                vm.respostaSelecionada = e.idResposta;
                                            }
                                        }

                                        // alterando a questão
                                        vm.waitTroca = false;
                                        vm.questaoAtual = idProxima;
                                    },
                                    function(status) {
                                        console.log(status)
                                        vm.waitTroca = false;
                                        vm.questaoAtual = idQuestaoAtual;
                                        alert('Não foi possível salvar a resposta selecionada, aguarde alguns segundos e tente avançar novamente');
                                    });
                            }
                        }
                    } else {
                        // ta voltando
                        for (var x = 0; x < vm.respostasAluno.length; x++) {
                            var e = vm.respostasAluno[x];

                            if (e.operador == idProxima) {
                                vm.respostaSelecionada = e.idResposta;
                            }
                        }
                        vm.waitTroca = false;
                        vm.questaoAtual = idProxima;
                    }
                }
            }
        }

        /* CONTADOR DO TEMPO DE PROVA */
        function startCountdown() {

            // Se o tempo não for zerado
            if (vm.tempo >= 1) {
                // Pega a parte inteira dos minutos
                var min = parseInt(vm.tempo / 60);
                var comp = ' minutos.';
                // Formata o número menor que dez, ex: 08, 07, ...
                if (min < 10) {
                    min = "0" + min;
                    min = min.substr(0, 2);
                }

                if (min == "01") {
                    comp = ' minuto.';
                }

                var horaImprimivel = min + comp;
                $("#tempoRestante").html(horaImprimivel);

                setTimeout(function() {
                    salvarSessao();
                    $("#tempoRestante").html(horaImprimivel);
                    vm.tempo = (vm.tempo - 60);
                    startCountdown();
                }, 60000);

            } else {
                enviar();
            }

        }

        /* ENVIA A PROVA */
        function enviar() {
            vm.tempo = 0;
            vm.liberado = false;
            vm.finalizado = true;

            clearTimeout();
            clearInterval();

            vm.isSaving = true;
            vm.prova.situacaoObj = 'PENDENTE';
            vm.prova.tempoObj = vm.tempo;
            vm.prova.questao = vm.questaoAtual;
            vm.prova.dataAlteracao = new Date();

            ProvaOnline.update(vm.prova, function(result) {
                vm.isSaving = false;
                vm.prova = {};
                sessionStorage.setItem('prova-online', JSON.stringify(result));
                $state.go('provaOnline');
            }, function(status) {
                console.log(status);
            });
        }

        /* SALVA A SESSÃO PARA EVITAR A PERCA DE INFORMAÇÕES DO ALUNO */
        function salvarSessao() {

            if (vm.finalizado == true) {

            } else {

                vm.prova.situacaoObj = 'ANDAMENTO';
                vm.prova.tempoObj = vm.tempo;
                vm.prova.questao = vm.questaoAtual;
                vm.prova.dataAlteracao = new Date();
                ProvaOnline.update(vm.prova);
                sessionStorage.setItem('prova-online', JSON.stringify(vm.prova));
            }
        }

        function iniciarProva() {
            salvarSessao();
            vm.etapa = 3;
            startCountdown();
        }

        function buscarSessao() {

            var r = validaInfos();

            if (r == "OK") {
                SessaoProvaOnlineService.query(vm.prova,
                    function(result) {
                        if (result != null) {
                            if (result.situacaoObj == 'ANDAMENTO' || result.situacaoObj == 'NAOINICIADA' || result.situacaoObj == 'ANONIMA') {
                                vm.prova = result;
                                sessionStorage.setItem('prova-online', JSON.stringify(vm.prova));
                                console.log("Buscou!");
                                //console.log(vm.prova);
                                vm.etapa = 1;
                                init();
                            } else {
                                showMessage('alert-danger', 'A prova desta inscrição já foi concluída, qualquer dúvida entre em contato com vestibular@univel.br.');
                            }
                        } else {
                            showMessage('alert-danger', 'Desculpe, não foi possível recuperar os dados da sua inscricão, por favor entre em contato com vestibular@univel.br e informe o problema.');
                        }
                    },
                    function(status) {
                        showMessage('alert-danger', 'Desculpe, não foi possível recuperar os dados da sua inscricão, por favor entre em contato com vestibular@univel.br e informe o problema.');
                    });
            } else {
                showMessage('alert-danger', r);
            }
        }

        /* VALIDAÇÃO DE FORMULÁRIO E MOSTRA ERRO */
        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function validaInfos() {
            if (vm.prova.senha == null || vm.prova.senha == "") {
                return "O campo senha não pode ser vazio.";
            }

            if (vm.prova.telefone == null || vm.prova.telefone == "") {
                return "O campo TELEFONE não pode ser vazio";
            }
            if (vm.prova.telefone.length < 10) {
                return "O campo TELEFONE deve ter ao menos 10 dígitos";
            }

            if (vm.prova.email == null || vm.prova.email == "") {
                return "O campo EMAIL não pode ser vazio";
            }
            if (!vm.prova.email.indexOf("@")) {
                return "O campo EMAIL não está correto";
            }

            return "OK";
        }
    }
})();