(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('NovoAgendadoController', NovoAgendadoController);

    NovoAgendadoController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'LinkVendedorService',
        'InscricaoAgendadoUser', 'CursosPorPolo', '$http', '$sessionStorage', 'IndicacaoService', 'PolosAtivos', 'Polo',
        'DatasPorPolo', 'ConsultaHorarioPolo', 'ValidaInscricao', 'LoginService', 'ValidaHorariosRM', 'ProvaOnline', 'ConsultaRangePoloHab', 'PSAtivosAgendado', 'CursosAgendadoGeral', 'ProcessoSeletivo'
    ];

    function NovoAgendadoController($timeout, $scope, $rootScope, $state, $stateParams, LinkVendedorService,
        InscricaoAgendadoUser, CursosPorPolo, $http, $sessionStorage, IndicacaoService, PolosAtivos, Polo,
        DatasPorPolo, ConsultaHorarioPolo, ValidaInscricao, LoginService, ValidaHorariosRM, ProvaOnline, ConsultaRangePoloHab, PSAtivosAgendado, CursosAgendadoGeral, ProcessoSeletivo) {
        var vm = this;

        vm.isSaving = false;
        //vm.isSaving = true;

        // AQUI COMEÇA A FUNÇÂO DO CURSO
        $http.post("/api/cursos/get-cursos-novos").success(function(data) {
            vm.cursos = data;
            if (vm.cursoUrl != null && vm.cursoUrl != undefined) {
                for (var g = 0; g < vm.cursos.length; g++) {
                    var c = vm.cursos[g];

                    if (c.id == vm.cursoUrl) {
                        vm.cursoSelecionado = c;
                    }
                }
            }
        }).error(function(status) { console.log(status); });

        // AQUI BUSCA OS POLOS
        vm.polos = PolosAtivos.query({
            page: 0,
            size: 50
        });

        // AQUI BUSCA AS FORMAS DE INGRESSO
        vm.formasIngresso = [
            { id: 1, tipo: "Vestibular Online", descricao: "Prova Realizada sem sair de casa e com resultado na hora." },
            { id: 2, tipo: "Vestibular Presencial", descricao: "Vestibular agendado para realizar na Univel." },
            { id: 3, tipo: "Enem", descricao: "Vou usar minha nota do ENEM." },
            { id: 4, tipo: "Transferência", descricao: "Quero transferir um curso iniciado em outra instituição para a Univel." },
            { id: 5, tipo: "Segunda Graduação", descricao: "Já possuo uma graduação e vou usá-la para ingressar na Univel." }
        ];

        // AQUI SETA OS ANOS DO ENEM
        vm.anos = [
            { ano: 2023 },
            { ano: 2022 },
            { ano: 2021 },
            { ano: 2020 },
            { ano: 2019 }
        ];


        vm.inscricaoAgendado = JSON.parse(sessionStorage.getItem('candidato-vestibular'));

        if (vm.inscricaoAgendado != null) {

            if (vm.inscricaoAgendado.etapaInscricao == 6) {
                $state.go('confirmacao-novo-agendado');
            } else {
                vm.cursoSelecionado = vm.inscricaoAgendado.curso;
                vm.poloSelecionado = vm.inscricaoAgendado.polo;
                vm.modalidade = vm.inscricaoAgendado.modalidade;
                vm.ingressoSelecionado = vm.inscricaoAgendado.ingresso;

                vm.datas = DatasPorPolo.query({
                    id: vm.poloSelecionado.id
                });
                vm.horariosDefault = ConsultaRangePoloHab.query({
                    idPolo: vm.poloSelecionado.id
                });
                ProcessoSeletivo.get({ id: vm.inscricaoAgendado.modalidade.idProcesso },
                    function(result) {
                        vm.PSEscolhido = result;
                        vm.PSEscolhido.dataFinalCompare = vm.PSEscolhido.dataFinal;
                        vm.inscricaoAgendado.idps = vm.PSEscolhido.idps;

                    });

                vm.mandaPost = false;
                vm.etapa = vm.inscricaoAgendado.etapaInscricao;
            }

        } else {

            vm.inscricaoAgendado = {};
            vm.inscricaoAgendado.possuiNecessidadesEspeciais = 'NAO';
            vm.inscricaoAgendado.possuiIndicacao = 'NAO';
            vm.inscricaoAgendado.provaOnline = false;
            vm.inscricaoAgendado.interno = false;
            vm.inscricaoAgendado.anoEnem = 0;
            vm.inscricaoAgendado.enem = 0;

            vm.cursoSelecionado = null;
            vm.poloSelecionado = null;

            vm.cursoUrl = null;

            vm.inscricaoAgendado.etapaInscricao = 1;
            vm.etapa = 1;

            executaBlocoteste();
            vm.mandaPost = true;


            var url = location.href;
            console.log(url);
            if (url.includes('?')) {
                var params = url.substring(url.indexOf("?") + 1);
                console.log(params);
                var arrayCampos = params.split("&");
                console.log(arrayCampos);
                var vendUrl = null;
                for (var i = 0; i < arrayCampos.length; i++) {
                    var e = arrayCampos[i];
                    var h = e.split('=');
                    console.log(h);
                    switch (h[0]) {
                        case "id":
                            vendUrl = h[1];
                            break;
                        case "name":
                            vm.inscricaoAgendado.nome = h[1];
                            break;
                        case "email":
                            vm.inscricaoAgendado.email = h[1];
                            break;
                        case "telefone":
                            vm.inscricaoAgendado.telefone = h[1];
                            break;
                        case "cpf":
                            vm.inscricaoAgendado.cpf = h[1];
                            break;
                        case "curso":
                            vm.cursoUrl = h[1];
                            break;
                        case "formaIngresso":
                            vm.ingressoUrl = h[1];
                            for (var g = 0; g < vm.formasIngresso.length; g++) {
                                var e = vm.formasIngresso[g];

                                if (vm.ingressoUrl == e.id) {
                                    vm.ingressoSelecionado = e;
                                }
                            }
                            break;
                        case "callback":
                            vm.callback = h[1];
                            break;

                        default:
                            setaVendedorLink(vendUrl);
                            break;
                    }
                    setaVendedorLink(vendUrl);
                }

            } else {
                setaVendedorLink();
            }
        }

        // REGRA DE NEGOCIO DAS ETAPAS

        vm.voltarEtapa = function(etapa) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            vm.etapa = etapa;
        }

        vm.avancaEtapa = function(etapa) {

            var valida = "OK";
            vm.msgError = null;

            if (etapa == 1) {
                vm.inscricaoAgendado.etapaInscricao = etapa;
                vm.etapa = etapa;
            }

            if (etapa == 2) {
                valida = validaCampos(etapa);
                if (valida == "OK") {
                    ValidaInscricao.query({ email: vm.inscricaoAgendado.email, cpf: vm.inscricaoAgendado.cpf },
                        function(result) {
                            vm.inscricaoValidada = result;
                            console.log(vm.inscricaoValidada);
                            if (vm.inscricaoValidada.existe == true) {
                                if (vm.inscricaoValidada.pendente == true) {
                                    if (vm.inscricaoValidada.email == true) {
                                        showMessage('text-error', "E-mail já utilizado, clique em acompanhar minha inscrição para acessar o Portal do Candidato.");
                                    } else {
                                        showMessage('text-error', "CPF já utilizado, por favor entre em contato para mais informações - 45 3036 3664");
                                    }
                                } else {
                                    vm.inscricaoAgendado.id = vm.inscricaoValidada.idInsc;
                                    vm.inscricaoAgendado.etapaInscricao = etapa;
                                    vm.etapa = etapa;
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                    sendPostRdInteresse();
                                }
                            } else {
                                vm.inscricaoAgendado.etapaInscricao = etapa;
                                vm.etapa = etapa;
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                sendPostRdInteresse();
                            }
                        },
                        function(status) {
                            console.log(status);
                        });
                } else {
                    showErrorForm(valida);
                }
            }

            if (etapa == 3) {

                vm.inscricaoAgendado.curso = vm.cursoSelecionado;
                vm.inscricaoAgendado.idCursoNovo = vm.cursoSelecionado.id;

                // ainda não sei se vamos tratar a modalidade pelo id ou nome
                // inserir estes campos na entidade
                vm.inscricaoAgendado.modalidade = vm.modalidade;
                vm.inscricaoAgendado.idModalidade = vm.modalidade.id;
                vm.inscricaoAgendado.nomeModalidade = vm.modalidade.nome;
                vm.inscricaoAgendado.idProcessoSeletivo = vm.modalidade.idProcesso;
                vm.inscricaoAgendado.idps = vm.modalidade.idps;

                // a modalidade vem dentro do curso e temos um variável de controle para ela
                vm.inscricaoAgendado.idAreaInteresse = vm.modalidade.idAreaInteresse;
                vm.inscricaoAgendado.codCursoRm = vm.modalidade.codCursoRm;

                if (vm.modalidade.nome != 'EAD') {
                    Polo.get({ id: 1 }, function(result) {
                        vm.poloSelecionado = result;
                        vm.datas = DatasPorPolo.query({ id: 1 });
                        vm.horariosDefault = ConsultaRangePoloHab.query({ idPolo: 1 });
                        vm.inscricaoAgendado.polo = vm.poloSelecionado;

                        // o id_processo é o id da table da modalidade
                        ProcessoSeletivo.get({ id: vm.modalidade.idProcesso },
                            function(result) {
                                vm.PSEscolhido = result;
                                vm.PSEscolhido.dataFinalCompare = vm.PSEscolhido.dataFinal;
                            });

                        vm.inscricaoAgendado.etapaInscricao = etapa;
                        vm.etapa = etapa
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    });
                } else {
                    // quando escolhe o polo, busca das datas bloqueadas
                    vm.datas = DatasPorPolo.query({
                        id: vm.poloSelecionado.id
                    });

                    vm.horariosDefault = ConsultaRangePoloHab.query({
                        idPolo: vm.poloSelecionado.id
                    });

                    vm.inscricaoAgendado.polo = vm.poloSelecionado;

                    // o id_processo é o id da table da modalidade
                    ProcessoSeletivo.get({ id: vm.modalidade.idProcesso },
                        function(result) {
                            vm.PSEscolhido = result;
                            vm.PSEscolhido.dataFinalCompare = vm.PSEscolhido.dataFinal;
                        });

                    vm.inscricaoAgendado.etapaInscricao = etapa;
                    vm.etapa = etapa
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }

            }

            if (etapa == 4) {
                vm.inscricaoAgendado.ingresso = vm.ingressoSelecionado;
                vm.inscricaoAgendado.formaIngresso = vm.ingressoSelecionado.tipo;

                // valida se não escolheu data para setar padrão.
                // nessa caso contempla enem, transferencia e segunda graduação;
                // ainda não sei o que fazer em caso de transferência e de segunda grad, vai pra análise de grade? 

                if (vm.ingressoSelecionado.tipo == "Vestibular Presencial") {
                    vm.inscricaoAgendado.horaProva = vm.horaProva.idHorario;
                } else {
                    vm.inscricaoAgendado.dataProva = new Date();
                    var a = vm.inscricaoAgendado.dataProva.getHours();
                    if (a <= 9) {
                        vm.inscricaoAgendado.horaProva = 'H0' + a;
                    } else {
                        vm.inscricaoAgendado.horaProva = 'H' + a;
                    }
                    vm.inscricaoAgendado.provaOnline = true;
                }

                if (vm.ingressoSelecionado == "Enem") {
                    vm.inscricaoAgendado.anoEnem = vm.anoEnem;
                    vm.inscricaoAgendado.enem = true;
                }

                vm.inscricaoAgendado.etapaInscricao = etapa;
                vm.etapa = etapa;
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }

            if (etapa == 5) {
                valida = validaCampos(etapa);

                if (valida == "OK") {
                    vm.inscricaoAgendado.etapaInscricao = etapa;
                    sessionStorage.setItem('candidato-vestibular', JSON.stringify(vm.inscricaoAgendado));
                    vm.etapa = etapa;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                } else {
                    showErrorForm(valida);
                }
            }
            //console.log(vm.inscricaoAgendado);
        }

        function validaCampos(etapa) {

            var erros = [];

            if (etapa == 2) {

                if (vm.inscricaoAgendado.nome == null || vm.inscricaoAgendado.nome == "") {
                    erros.push({ campo: "nome", msg: "Por favor, informe seu nome completo." });
                } else {
                    if (vm.inscricaoAgendado.nome.length <= 3) {
                        erros.push({ campo: "nome", msg: "Por favor, informe seu nome completo. Ex: João Pedro da Silva" });
                    } else {
                        if (vm.inscricaoAgendado.nome.indexOf(" ") == -1) {
                            erros.push({ campo: "nome", msg: "Por favor, informe seu nome completo. Ex: João Pedro da Silva" });
                        }
                    }
                }

                if (vm.inscricaoAgendado.cpf == null || vm.inscricaoAgendado.cpf == "") {
                    erros.push({ campo: "cpf", msg: "CPF inválido. Verifique e tente novamente." });
                } else {
                    if (vm.inscricaoAgendado.cpf.length < 11) {
                        erros.push({ campo: "cpf", msg: "CPF incompleto, insira 11 dígitos." });
                    }
                }

                if (vm.inscricaoAgendado.telefone == null || vm.inscricaoAgendado.telefone == "") {
                    erros.push({ campo: "telefone", msg: "Telefone Inválido. Digite os números corretos." });
                } else {
                    if (vm.inscricaoAgendado.telefone.length < 11) {
                        erros.push({ campo: "telefone", msg: "insira 11 dígitos. 45 99999-9999" });
                    } else {
                        if (vm.inscricaoAgendado.telefone.substring(0, 1) == '0') {
                            erros.push({ campo: "telefone", msg: "Telefone Inválido. Não pode iniciar com 0." });
                        }
                    }
                }

                if (vm.inscricaoAgendado.email == null || vm.inscricaoAgendado.email == "") {
                    erros.push({ campo: "email", msg: "Email inválido. Verifique se digitou corretamente." });
                } else {
                    if (!vm.inscricaoAgendado.email.indexOf("@")) {
                        erros.push({ campo: "email", msg: "Email inválido. Verifique se digitou corretamente." });
                    }
                }

                if (vm.inscricaoAgendado.dataNascimento == null || vm.inscricaoAgendado.dataNascimento == "") {
                    erros.push({ campo: "nascimento", msg: "Por favor, informe sua data de nascimento." });
                } else {
                    var idade = calculaIdade(vm.inscricaoAgendado.dataNascimento, new Date());
                    console.log(idade);
                    if (idade < 16) {
                        erros.push({ campo: "nascimento", msg: "A data de Nascimento informada é menor que 16 anos de idade." });
                    }
                }

                if (vm.inscricaoAgendado.termo == false || vm.inscricaoAgendado.termo == undefined || vm.inscricaoAgendado.termo == null) {
                    erros.push({ campo: "termo", msg: "Por favor, aceite o termo de consentimento." });
                }
            }

            if (etapa == 5) {
                if (vm.inscricaoAgendado.nome == null || vm.inscricaoAgendado.nome == "") {
                    erros.push({ campo: "nome", msg: "Por favor, informe seu nome completo." });
                } else {
                    if (vm.inscricaoAgendado.nome.length <= 3) {
                        erros.push({ campo: "nome", msg: "Por favor, informe seu nome completo. Ex: João Pedro da Silva" });
                    } else {
                        if (vm.inscricaoAgendado.nome.indexOf(" ") == -1) {
                            erros.push({ campo: "nome", msg: "Por favor, informe seu nome completo. Ex: João Pedro da Silva" });
                        }
                    }
                }

                if (vm.inscricaoAgendado.anoConclusao == null || vm.inscricaoAgendado.anoConclusao == "") {
                    erros.push({ campo: "anoConclusao", msg: "Por favor, informe o ano de conclusão." });
                }

                if (vm.inscricaoAgendado.possuiNecessidadesEspeciais == 'SIM') {
                    if (vm.inscricaoAgendado.necessidadesEspeciaisTexto == null || vm.inscricaoAgendado.necessidadesEspeciaisTexto == "") {
                        erros.push({ campo: "necessidades_especiais_texto", msg: "Por favor, informe qual a necessidade especial." });
                    }
                }
            }

            console.log(erros);

            if (erros.length > 0) {
                return erros;
            } else {
                return "OK";
            }
        }

        function showErrorForm(valida) {

            // agora o valida é um array, bora lá.

            for (var i = 0; i < valida.length; i++) {
                var e = valida[i];
                //vm.msgError += "<p>" + valida.msg + "</p>";
                document.getElementById(e.campo).classList.add('inputForm-error');
                document.getElementById('error-' + e.campo).style.display = "block";
                document.getElementById('error-' + e.campo).innerText = e.msg;
            }

            setTimeout(function() {
                for (var i = 0; i < valida.length; i++) {
                    var e = valida[i];
                    document.getElementById('error-' + e.campo).style.display = "none";
                    document.getElementById(e.campo).classList.remove('inputForm-error');
                    document.getElementById('error-' + e.campo).innerText = "";
                }
            }, 4000);

            // vm.msgError = valida.msg;
            // document.getElementById('error-' + valida.campo).style.display = "block";
            // document.getElementById(valida.campo).classList.add('inputForm-error');
        }

        vm.salvar = function() {
            vm.inscricaoAgendado.idCursoNovo = vm.cursoSelecionado.id;
            vm.inscricaoAgendado.idModalidade = vm.inscricaoAgendado.idModalidade;
            vm.inscricaoAgendado.idProcessoSeletivo = vm.PSEscolhido.id;
            vm.inscricaoAgendado.formaIngresso = vm.ingressoSelecionado.tipo;
            vm.inscricaoAgendado.inscricaoPlay = false;

            vm.isSaving = true;

            //console.log(vm.inscricaoAgendado);
            $http.post("/api/nova-inscricao-agendada", vm.inscricaoAgendado)
                .success(function(result) {

                    var prova = {
                        id: null,
                        inscricao: result.id,
                        email: result.email,
                        telefone: result.telefone,
                        cpf: result.cpf,
                        nota: 0,
                        situacao: 'ANONIMA',
                        nome: result.nome,
                        tempo: 3600,
                        questao: 1,
                        tempoObj: 0,
                        situacaoObj: 'ANONIMA',
                        notaObj: 0,
                        tipo: null,
                        notaGeral: 0,
                        idps: result.idps,
                        senha: result.cpf
                    }

                    if (result.provaOnline == true) {
                        prova.tipo = 'AGENDADO';
                    } else {
                        prova.tipo = 'PRESENCIAL';
                    }

                    console.log(prova);

                    ProvaOnline.save(prova, function(r2) {
                        console.log(r2);
                        // veio direto do online então vamos fazer uma adaptacao
                        if (vm.inscricaoAgendado.etapaInscricao != 5 && vm.inscricaoAgendado.formaIngresso == "Vestibular Online") {
                            result.etapaInscricao = 7;
                            sessionStorage.setItem('candidato-vestibular', JSON.stringify(result));
                            sessionStorage.setItem('prova-online', JSON.stringify(r2));
                            $state.go('termoProvaOnline');
                            vm.isSaving = false;
                        }

                    }, function(status) {
                        console.log(status);
                    });

                    // aqui valida se ta vendo do save final
                    if (vm.inscricaoAgendado.etapaInscricao == 5) {
                        // como estava no 5 entao vai pro confirmação
                        result.etapaInscricao = 6;
                        sessionStorage.setItem('candidato-vestibular', JSON.stringify(result));
                        $state.go('confirmacao-novo-agendado');
                        vm.isSaving = false;
                    }

                }).error(function(status) {
                    console.log(status);
                    showMessage('text-error', 'Desculpe, aconteceu algo de errado na sua inscrição, entre em contato pelos telefones no final da página para solucionar');
                    vm.isSaving = false;
                });
        }

        vm.realizarProva = function() {
            //  AAAAAA o que fazer aqui? precisa de um numero de inscrição....

            // 1º levantar quais campos eu tenho e se consigo salvar a insc e a prova.
            // 2° se faltar alguma coisa tem que contornar em algum momento
            // 3° realizar a prova via painel ou via egresso? EGRESSO!! manda por get? session? SESSION!!!!!!!!!

            vm.inscricaoAgendado.ingresso = vm.ingressoSelecionado;
            vm.inscricaoAgendado.formaIngresso = vm.ingressoSelecionado.tipo;

            if (vm.ingressoSelecionado.tipo == "Vestibular Online") {
                vm.inscricaoAgendado.dataProva = new Date();
                var a = vm.inscricaoAgendado.dataProva.getHours();
                if (a <= 9) {
                    vm.inscricaoAgendado.horaProva = 'H0' + a;
                } else {
                    vm.inscricaoAgendado.horaProva = 'H' + a;
                }
                vm.inscricaoAgendado.provaOnline = true;
                vm.inscricaoAgendado.anoConclusao = 0;
            }
            vm.salvar();
        }

        function sendPostRdInteresse() {
            console.log("Aqui vai o interesse para o RD.");
            var a = vm.inscricaoAgendado;
            a.provaOnline = false;
            if (vm.mandaPost == true) {
                $http.post("/api/post-rd-interesse-agendado", a);
            }
        }

        function setaVendedorLink(valor) {
            // console.log(valor);
            if (valor != null && valor != undefined) {
                vm.interno = true;
                vm.vendLink = true;
                LinkVendedorService.query({ codigo: valor }, function(result) {
                    //console.log(result);
                    vm.inscricaoAgendado.possuiIndicacao = result.tipo;
                    vm.inscricaoAgendado.codPessoaIndicacao = result.codigo;
                    vm.inscricaoAgendado.indicacaoTexto = result.nome;
                    vm.vendedorLink = result.link;
                    vm.vendedorNumero = result.numero;
                    vm.vendedorEmail = result.email;

                });
            } else {
                vm.interno = false;
                vm.vendLink = false;
                vm.inscricaoAgendado.possuiIndicacao = 'NAO';
                vm.inscricaoAgendado.codPessoaIndicacao = '';
                vm.inscricaoAgendado.indicacaoTexto = '';
                vm.vendedorLink = 'https://wa.me/5545988429444';
                vm.vendedorNumero = "45 98842-9444";
                vm.vendedorEmail = "vestibular@univel.br";
            }

            // console.log(vm.vendedorLink);
            // console.log(vm.vendedorEmail);
            // console.log(vm.vendedorNumero);
        }

        // METODOS DE VALIDAÇÂO DE DATA
        vm.openCalendar = openCalendar;
        vm.isSaturday = saturday;
        vm.datePickerOpenStatus = {};

        vm.painelAdm = function(tipo) {
            sessionStorage.setItem('tipo-painel', JSON.stringify(tipo));
            $state.go('painelAdministrativo');
        }

        function areDatesEqual(date1, date2) {
            return date1 == date2.toLocaleDateString()
        }

        function saturday() {
            showMessage('invi', '');
            vm.horariosProva = [];
            var data = vm.inscricaoAgendado.dataProva.toString();

            var erros = [];

            var validateIE = validaDataInternetExplorer(vm.inscricaoAgendado.dataProva);

            if (!validateIE) {
                showMessage('text-error', 'Este polo não terá atendimento nesta data.');
            } else {

                if (validaRecesso()) {
                    showMessage('text-error', 'Nesta data a Univel estará em recesso, agendamentos disponíveis a partir de 06/01/2025, ou então pode realizar o vestibular online.');
                } else {
                    // VALIDANDO SE É DOMINGO
                    if (data.substring(0, 3) == "Sun") {
                        showMessage('text-error', 'Desculpe, Não existe expediente aos domingos.');
                    } else {
                        /*VALIDANDO SE É POLO OU MATRIZ*/
                        if (vm.poloEad) {
                            //É POLO, VERIFICANDO SE É FINAL DE SEMANA
                            if (data.substring(0, 3) == "Sat") {
                                //COMO É FINAL DE SEMANA NÃO TEMOS ATENDIMENTO.
                                showMessage('text-error', 'Os polos parceiros não possuem atendimento no final de semana.');
                            } else {
                                if (vm.inscricaoAgendado.dataProva > vm.PSEscolhido.dataFinalCompare) {
                                    showMessage('text-error', formatDate(vm.PSEscolhido.dataFinal) + " é a data limite para " + vm.PSEscolhido.nome);
                                } else {
                                    if (validaHorarioEspecial()) {
                                        popula('ESPECIAL');
                                    } else {
                                        popula('NORMAL');
                                    }
                                }
                            }
                        } else {
                            if (vm.inscricaoAgendado.dataProva > vm.PSEscolhido.dataFinalCompare) {
                                showMessage('text-error', formatDate(vm.PSEscolhido.dataFinal) + " é a data limite para " + vm.PSEscolhido.nome);
                            } else {
                                if (data.substring(0, 3) == "Sat") {
                                    popula('SABADO');
                                } else {
                                    if (validaHorarioEspecial()) {
                                        popula('ESPECIAL');
                                    } else {
                                        popula('NORMAL');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        function validaRecesso() {
            if ((vm.inscricaoAgendado.dataProva >= new Date(2024, 11, 20)) && (vm.inscricaoAgendado.dataProva <= new Date(2025, 0, 6))) {
                return true;
            } else {
                return false;
            }
        }

        function validaHorarioEspecial() {
            if ((vm.inscricaoAgendado.dataProva >= new Date(2025, 0, 6)) && (vm.inscricaoAgendado.dataProva <= new Date(2025, 1, 16))) {
                return true;
            } else {
                return false;
            }
        }

        function popula(situacao) {
            ValidaHorariosRM.query({ dataProva: vm.inscricaoAgendado.dataProva, idps: vm.inscricaoAgendado.idps });

            if (situacao == 'NORMAL') {
                for (var i = 0; i < vm.horariosDefault.length; i++) {
                    var element = vm.horariosDefault[i];
                    if ((element.idHorario > 7 && element.idHorario < 12) || (element.idHorario > 13 && element.idHorario < 22)) {
                        vm.horariosProva.push(element);
                    }
                }
            }

            if (situacao == 'SABADO') {
                for (var i = 0; i < vm.horariosDefault.length; i++) {
                    var element = vm.horariosDefault[i];
                    if ((element.idHorario > 7 && element.idHorario < 12)) {
                        vm.horariosProva.push(element);
                    }
                }
            }

            if (situacao == 'ESPECIAL') {
                for (var i = 0; i < vm.horariosDefault.length; i++) {
                    var element = vm.horariosDefault[i];
                    if ((element.idHorario > 7 && element.idHorario < 12) || (element.idHorario > 13 && element.idHorario < 20)) {
                        vm.horariosProva.push(element);
                    }
                }
            }

            consultaBloqueados();
        }

        function consultaBloqueados() {
            var dt = vm.inscricaoAgendado.dataProva;
            var dy = dt.getDate();
            var ms = dt.getMonth() + 1;
            var yr = dt.getFullYear();

            if (ms < 10) {
                ms = '0' + ms;
            }

            if (dy < 10) {
                dy = '0' + dy;
            }

            var dia = yr + '-' + ms + '-' + dy;
            ConsultaHorarioPolo.query({ idPolo: vm.poloSelecionado.id, dia: dia },
                function(result) {
                    vm.horariosBloqueados = result;
                    for (var j = 0; j < vm.horariosBloqueados.length; j++) {
                        var bl = vm.horariosBloqueados[j];
                        for (var k = 0; k < vm.horariosProva.length; k++) {
                            var hp = vm.horariosProva[k];
                            if (bl.hora == hp.idHorario) {
                                vm.horariosProva.splice(k, 1);
                            }
                        };
                    };
                });
        }

        function validaDataInternetExplorer(d) {
            var dy2 = d.getDate();
            var ms2 = d.getMonth() + 1;
            var yr2 = d.getFullYear();

            if (ms2 < 10) {
                ms2 = '0' + ms2;
            }

            if (dy2 < 10) {
                dy2 = '0' + dy2;
            }

            var dia2 = dy2 + '/' + ms2 + '/' + yr2;

            for (var i = 0; i < vm.datas.length; i++) {
                if (vm.datas[i] === dia2) {
                    return false;
                }
            }
            return true;
        }

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {

            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var z = document.getElementById("error-message");
            var message = '<div class="box-ajuda-text div-alert-error ' + css + '">' + msg + '</div>';
            x.innerHTML = message;

            if (css == 'invi') {
                x.style.display = "none";
                y.style.display = "none";
                z.style.display = "none";
            } else {
                x.style.display = "block";
                y.style.display = "block";
                z.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function getMinDate() {
            var atual = new Date();
            var matricDate = new Date(2019, 9, 7);

            if (atual > matricDate) {
                var minDateAt = new Date(atual.getFullYear(), atual.getMonth(), atual.getDate());
            } else {
                var minDateAt = matricDate;
            }

            return minDateAt;
        }

        vm.dateOptions = {
            minDate: getMinDate(),
            dateDisabled: myDisabledDates,
            showWeeks: false,
            showButtonPanel: false
        }

        function myDisabledDates(dateAndMode) {
            var date = dateAndMode.date
            var mode = dateAndMode.mode;
            var isRealDate = false;

            if (vm.datas == undefined) {
                showMessage('text-error', 'Por favor, selecione um Polo antes de selecionar a Data.');
            }

            if (vm.cursoSelecionado == null) {
                showMessage('text-error', 'Por favor, selecione um Curso antes de selecionar a Data.');
            }
            for (var i = 0; i < vm.datas.length; i++) {
                if (areDatesEqual(vm.datas[i], date)) {
                    isRealDate = true;
                }
            }

            return ((dateAndMode.mode === 'day' && (dateAndMode.date.getDay() === 0)) || isRealDate);
        }

        function disabled(date, mode) {
            return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
        };

        vm.datePickerOpenStatus.dataProva = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.openIndicacao = function() {
            IndicacaoService.open(vm.inscricaoAgendado.possuiIndicacao);
        }

        $rootScope.$on('selecionou', function(event, value) {
            if (value != null && value != undefined) {
                vm.inscricaoAgendado.codPessoaIndicacao = value.codPessoa;
                vm.inscricaoAgendado.indicacaoTexto = value.nome;
                vm.inscricaoAgendado.possuiIndicacao = value.possuiIndicacao;
            } else {
                vm.inscricaoAgendado.possuiIndicacao = "NAO";
                vm.inscricaoAgendado.indicacaoTexto = null;
                vm.inscricaoAgendado.codPessoaIndicacao = null;
            }
        });

        function calculaIdade(nascimento, hoje) {
            return Math.floor(Math.ceil(Math.abs(nascimento.getTime() - hoje.getTime()) / (1000 * 3600 * 24)) / 365.25);
        }

        function formatDate(dateString) {
            var dia = dateString.getDate();
            var mes = dateString.getMonth();
            var ano = dateString.getFullYear();

            return dia + "/" + (mes + 1) + "/" + ano;
            // var last = ano + '-' + (mes + 1) + '-' + dia;
            // var thisDate = last.split('-');
            // var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
            // return newDate;
        }

        function executaBlocoteste() {
            // BLOCO DE TESTE DEV
            // vm.inscricaoAgendado.nome = 'MAURICIO OURIQUES TONETO';
            // vm.inscricaoAgendado.cpf = '08726252902';
            // vm.inscricaoAgendado.telefone = '45999023808';
            // vm.inscricaoAgendado.email = 'mauricio.toneto@univel.br';
            // vm.inscricaoAgendado.dataNascimento = new Date(1997, 0, 12);

            // vm.inscricaoAgendado.possuiNecessidadesEspeciais = "SIM";
            // vm.inscricaoAgendado.necessidadesEspeciaisTexto = "Aqui descrevemos as necessidades do candidato.";

            //vm.inscricaoAgendado.dataProva = new Date();
            //vm.inscricaoAgendado.horaProva = "H09";

            // vm.cursoSelecionado = { id: 1, nome: "Administração", tipo: "Bacharelado", modalidade: [{ id: 1, nome: "Matutino", idps: 23, idAreaInteresse: 123, tags: ['Presencial', '10 Semestres'] }, { id: 2, nome: "Noturno", idps: 23, idAreaInteresse: 123, tags: ['Presencial', '10 Semestres'] }, { id: 3, nome: "EAD", idps: 22, idAreaInteresse: 123, tags: ['EAD', '10 Semestres'] }] };
            // vm.inscricaoAgendado.curso = vm.cursoSelecionado;
            // vm.poloSelecionado = { id: 1, nome: "Cascavel", cidade: "Cascavel", rua: "Avenita Tito Mufatto", numero: 2317, bairro: "Santa Cruz" };
            // vm.inscricaoAgendado.polo = vm.poloSelecionado;
            // vm.modalidade = { id: 1, nome: "Matutino", idProcesso: 23, idps: 60, idAreaInteresse: 123, tags: ['Presencial', '10 Semestres'] };
            // vm.inscricaoAgendado.modalidade = vm.modalidade;
            // vm.ingressoSelecionado = { id: 2, tipo: "Vestibular Presencial", descricao: "Vestibular agendado para realizar na Univel" };
            // vm.inscricaoAgendado.ingresso = vm.ingressoSelecionado;
            // vm.inscricaoAgendado.tipoIngresso = vm.ingressoSelecionado.tipo;

            // vm.datas = DatasPorPolo.query({
            //     id: vm.poloSelecionado.id
            // });

            // vm.horariosDefault = ConsultaRangePoloHab.query({
            //     idPolo: vm.poloSelecionado.id
            // });


            // ProcessoSeletivo.get({ id: 23 },
            //     function(result) {
            //         vm.PSEscolhido = result;
            //         vm.PSEscolhido.dataFinalCompare = vm.PSEscolhido.dataFinal;
            //         vm.inscricaoAgendado.idps = vm.PSEscolhido.idps;
            //     });

            // vm.etapa = 3;
            //FIM BLOCO TESTE DEV
        }

        vm.onlyWeekendsPredicate = function(date) {
            var day = date.getDay();
            return day !== 0;
        }

        vm.minDateProva = new Date();

    }
})();