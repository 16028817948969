(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaDpDisciplinasController', MatriculaDpDisciplinasController);

    MatriculaDpDisciplinasController.$inject = ['SimulaParcelaMatriculaDp', 'DeleteParcelaMatriculaDp', 'InsertPropostaMatriculaDp', 'BuscaPropostaMatriculaDp', 'AlteraPropostaMatriculaDp', 'BuscaDisciplinasMatriculaDp', '$http'];

    function MatriculaDpDisciplinasController(SimulaParcelaMatriculaDp, DeleteParcelaMatriculaDp, InsertPropostaMatriculaDp, BuscaPropostaMatriculaDp, AlteraPropostaMatriculaDp, BuscaDisciplinasMatriculaDp, $http) {
        var vm = this;

        buscaIp();

        function buscaIp() {
            $(function() {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?",
                    function(json) {
                        vm.ip = json.ip;
                    }
                );
            });
        }

        vm.load = false;
        vm.simulado = false;
        vm.finalizado = false;
        vm.possuiAD = false;
        vm.possuiDP = false;
        vm.parcelasGeradas = null;
        vm.listaVazia = false;
        vm.aceitoSimulacao = false;
        vm.propostasDisponiveis = null;
        vm.motivocanc = "";

        vm.dpDisponiveis = [];
        vm.adianDisponiveis = [];
        vm.adapDisponiveis = [];
        vm.disciplinasSelecionadas = [];

        vm.valorTotalSelecionadas = 0;
        // max horas
        vm.valorMaxDisc = 15;
        // total disc
        vm.totalDiscUtilizadas = 0;
        vm.totalCredUtilizadas = 0;

        vm.coresSimulacao = ['#43CD80', '#B0E2FF', '#C1CDCD', '#F5F5DC', '#98FB98', '#87CEFA', '#8470FF', '#D3D3D3', '#FFDAB9'];
        vm.user = JSON.parse(sessionStorage.getItem('user-matric-dp'));


        validaFin();
        //LoadAll();

        vm.selecionar = selecionar;
        vm.remover = remover;
        vm.enviarProposta = enviarProposta;
        vm.enviaPropostaSimuladaPainel = enviaPropostaSimuladaPainel;
        vm.simular = simular;
        vm.cancelarSimulacao = cancelarSimulacao;
        vm.cancelarProposta = cancelarProposta;
        vm.excluirProposta = excluirProposta;
        vm.voltar = voltar;
        vm.alterTab = alterTab;
        vm.sendExt = sendExt;


        function validaFin() {
            //LoadAll();
            vm.load = true;
            $http.post("/api/get-valida-inadimplencia-matric-dp", vm.user.login)
                .success(function(data) {
                    if (data.message == "OK") {
                        LoadAll();
                    } else {
                        showMessage('alert-danger', data.message);
                    }
                    finalizaLoad();
                })
                .error(function(status) {
                    finalizaLoad();
                    showMessage('alert-danger', 'Desculpe, não foi possível carregar as informações no momento');
                });
        }

        function LoadAll() {

            vm.load = true;
            alterTab('PROP');

            BuscaPropostaMatriculaDp.query({ user: vm.user.login },
                function(data) {
                    //console.log(data);
                    if (data.length == 0) {} else {
                        vm.propostasDisponiveis = data;
                        finalizaLoad();
                    }
                },
                function(status) {
                    console.log(status);
                    finalizaLoad();
                });



            BuscaDisciplinasMatriculaDp.query(vm.user.login,
                function(data) {

                    console.log(data);

                    if (data.length == 0) {
                        vm.dpDisponiveis = data;
                        vm.adianDisponiveis = data;
                        vm.adapDisponiveis = data;
                        showMessage('alert-danger', 'Desculpe, não localizamos nenhuma disciplina disponível para matrícula');
                        setTimeout(function() {
                            showMessage('invi', '');
                        }, 5000);
                        finalizaLoad();
                    } else {

                        vm.loadDisc = true;

                        for (var i = 0; i < data.length; i++) {
                            var element = data[i];

                            element.ipAluno = vm.ip;

                            if (element.codServico == "2") {
                                vm.dpDisponiveis.push(element);
                                vm.possuiDP = true;
                            }
                            if (element.codServico == "3") {
                                vm.adapDisponiveis.push(element);
                                vm.possuiDP = true;
                            }
                            if (element.codServico == "4") {
                                vm.adianDisponiveis.push(element);
                                vm.possuiAD = true;
                            }
                        }
                        vm.meses_div = data[0].mesesDiv;
                        vm.ra = data[0].ra;
                        vm.totalDiscUtilizadas = data[0].discTotal;
                        vm.totalCredDisp = data[0].creditosDisp;
                        vm.loadDisc = false;
                        finalizaLoad();
                    }
                },
                function(status) {
                    console.log(status);
                    vm.listaVazia = true;
                    if (vm.listaVazia == true) {
                        showMessage('alert-danger', 'Desculpe, não localizamos nenhuma disciplina disponível para matrícula');
                        setTimeout(function() {
                            showMessage('invi', '');
                        }, 5000);
                    }
                    finalizaLoad();
                });
        }

        function finalizaLoad() {
            vm.load = false;
        }

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
        }

        function selecionar(codDisc, codStatus) {
            switch (codStatus) {
                case "2":
                    for (var i = 0; i < vm.dpDisponiveis.length; i++) {
                        var disc = vm.dpDisponiveis[i];
                        if (disc.pendCodDisc == codDisc) {
                            vm.valorTotalSelecionadas += disc.valorAdp;
                            vm.totalCredUtilizadas++;
                            vm.disciplinasSelecionadas.push(disc);
                            vm.dpDisponiveis.splice(i, 1);
                        }
                    }
                    break;

                case "3":
                    for (var i = 0; i < vm.adapDisponiveis.length; i++) {
                        var disc = vm.adapDisponiveis[i];
                        if (disc.pendCodDisc == codDisc) {
                            vm.valorTotalSelecionadas += disc.valorAdp;
                            vm.totalCredUtilizadas++;
                            vm.disciplinasSelecionadas.push(disc);
                            vm.adapDisponiveis.splice(i, 1);
                        }
                    }
                    break;

                case "4":
                    for (var i = 0; i < vm.adianDisponiveis.length; i++) {
                        var disc = vm.adianDisponiveis[i];
                        if (disc.pendCodDisc == codDisc) {
                            vm.valorTotalSelecionadas += disc.valorAdp;
                            vm.totalDiscUtilizadas++;
                            vm.disciplinasSelecionadas.push(disc);
                            vm.adianDisponiveis.splice(i, 1);
                        }
                    }
                    break;
            }

            simular();
        }

        function remover(codDisc, codServico) {
            for (var i = 0; i < vm.disciplinasSelecionadas.length; i++) {
                var disc = vm.disciplinasSelecionadas[i];
                if (disc.pendCodDisc == codDisc) {

                    if (disc.codServico == "2") {
                        vm.dpDisponiveis.push(disc);
                        vm.totalCredUtilizadas--;
                        vm.disciplinasSelecionadas.splice(i, 1);
                    }

                    if (disc.codServico == "3") {
                        vm.adapDisponiveis.push(disc);
                        vm.totalCredUtilizadas--;
                        vm.disciplinasSelecionadas.splice(i, 1);
                    }

                    if (disc.codServico == "4") {
                        vm.adianDisponiveis.push(disc);
                        vm.totalDiscUtilizadas--;
                        vm.disciplinasSelecionadas.splice(i, 1);
                    }
                }
            }
        }

        function enviaPropostaSimuladaPainel(proposta) {
            vm.propostasSimuladas.push(proposta);
            enviarProposta();
        }

        function enviarProposta() {
            vm.load = true;
            InsertPropostaMatriculaDp.query(vm.propostasSimuladas,
                function(data) {
                    vm.load = false;
                    vm.propostasGeradas = data;
                    vm.disciplinasDisponiveis = null;
                    vm.disciplinasSelecionadas = null;
                    vm.valorTotalSelecionadas = null;
                    vm.finalizado = true;
                    vm.simulado = false;
                },
                function(status) {
                    vm.load = false;
                    console.log(status);
                });
        }

        function sendExt(proposta) {
            vm.load = true;
            var a = [];
            a.push(proposta);
            InsertPropostaMatriculaDp.query(a,
                function(data) {
                    vm.load = false;
                    vm.propostasGeradas = data;
                },
                function(status) {
                    vm.load = false;
                    console.log(status);
                });
        }


        function simular() {

            vm.valida = true;
            if (vm.disciplinasSelecionadas.length < 1) {
                vm.valida = false;
                showMessage('alert-danger', 'É preciso selecionar ao menos uma disciplina para Simular');
            }
            if (vm.possuiDP == true) {
                if (vm.totalCredUtilizadas > vm.totalCredDisp) {
                    vm.valida = false;
                    showMessage('alert-danger', 'Você excedeu a quantidade limite de disciplinas no período letivo. Favor verificar no Controle de Matrícula');
                    vm.alterTab('DP');
                }
            }
            if (vm.possuiAD == true) {
                if (vm.totalDiscUtilizadas > vm.valorMaxDisc) {
                    vm.valida = false;
                    showMessage('alert-danger', 'Você excedeu o limite de disciplinas em curso para o período letivo. Favor verificar no Controle de Matrículas.');
                    vm.alterTab('ADIAN');
                }
            }

            if (vm.valida == true) {
                vm.load = true;
                console.log(vm.disciplinasSelecionadas);
                SimulaParcelaMatriculaDp.query(vm.disciplinasSelecionadas,
                    function(data) {
                        vm.load = false;
                        vm.propostasSimuladas = data;
                        vm.simulado = true;
                    },
                    function(status) {
                        console.log(status);
                    });
            }

            setTimeout(function() {
                showMessage('invi', '');
            }, 4000);
        }

        function cancelarSimulacao() {
            vm.load = true;
            DeleteParcelaMatriculaDp.query(vm.propostasSimuladas,
                function(data) {

                    for (var i = 0; i < vm.disciplinasSelecionadas.length; i++) {
                        var disc = vm.disciplinasSelecionadas[i];
                        remover(disc.codDisc, disc.codServico);
                    }

                    vm.disciplinasDisponiveis = data;
                    vm.meses_div = data[0].mesesDiv;
                    vm.ra = data[0].ra;
                    vm.disciplinasSelecionadas = [];
                    vm.valorTotalSelecionadas = 0;
                    vm.load = false;
                    vm.simulado = false;

                    vm.totalDiscUtilizadas = vm.disciplinasDisponiveis[0].discTotal;
                    vm.totalCredUtilizadas = 0;
                },
                function(status) {
                    vm.load = false;
                    console.log(status);
                });
        }

        function cancelarProposta(proposta) {

            vm.load = true;

            if (vm.motivocanc == "") {
                showMessage('alert-info', 'Por favor preencha o motivo para reprovação');
            } else {
                AlteraPropostaMatriculaDp.query({ id: proposta.id, status: "CANCELADA", obs: vm.motivocanc, user: proposta.codUsuario },
                    function(data) {
                        vm.load = false;
                        LoadAll();
                    },
                    function(status) {
                        console.log(status);
                    });
            }
        }

        function excluirProposta(proposta) {
            vm.load = true;
            $http.post("/api/exclui-proposta-matric-dp", proposta)
                .success(function(data) {
                    LoadAll();
                })
                .error(function(status) {
                    finalizaLoad();
                    showMessage('alert-danger', 'Desculpe, não foi possível excluir a proposta.');
                });
        }

        function voltar() {
            vm.simulado = false;
            vm.finalizado = false;

            vm.aceitoSimulacao = false;

            vm.propostasDisponiveis = null;
            vm.disciplinasDisponiveis = null;
            vm.disciplinasSelecionadas = [];
            vm.valorTotalSelecionadas = 0;
            vm.parcelasGeradas = null;
            vm.listaVazia = false;
            vm.totalDiscUtilizadas = 0;
            vm.valorMaxDisc = 15;
            vm.totalCredUtilizadas = 0;

            LoadAll();
        }


        function alterTab(str) {

            var color = "black";
            var bgColor = "#fff";
            var border = "1px solid #ddd";
            var borderBottom = "block";
            var borderRadius = "0px";

            document.getElementById('prop-active').style.color = color;
            document.getElementById('prop-active').style.backgroundColor = bgColor;
            document.getElementById('prop-active').style.border = border;
            document.getElementById('prop-active').style.borderBottom = borderBottom;
            document.getElementById('prop-active').style.borderRadius = borderRadius;
            document.getElementById('dp-active').style.color = color;
            document.getElementById('dp-active').style.backgroundColor = bgColor;
            document.getElementById('dp-active').style.border = border;
            document.getElementById('dp-active').style.borderBottom = borderBottom;
            document.getElementById('dp-active').style.borderRadius = borderRadius;
            document.getElementById('adian-active').style.color = color;
            document.getElementById('adian-active').style.backgroundColor = bgColor;
            document.getElementById('adian-active').style.border = border;
            document.getElementById('adian-active').style.borderBottom = borderBottom;
            document.getElementById('adian-active').style.borderRadius = borderRadius;
            // document.getElementById('res-active').style.color = color;
            // document.getElementById('res-active').style.backgroundColor = bgColor;
            // document.getElementById('res-active').style.border = border;
            // document.getElementById('res-active').style.borderBottom = borderBottom;
            // document.getElementById('res-active').style.borderRadius = borderRadius;
            document.getElementById('adap-active').style.color = color;
            document.getElementById('adap-active').style.backgroundColor = bgColor;
            document.getElementById('adap-active').style.border = border;
            document.getElementById('adap-active').style.borderBottom = borderBottom;
            document.getElementById('adap-active').style.borderRadius = borderRadius;

            if (str == 'PROP') {
                document.getElementById('prop-active').style.color = "white";
                document.getElementById('prop-active').style.backgroundColor = "#337ab7";
                document.getElementById('prop-active').style.border = "1px solid #337ab7";
                document.getElementById('prop-active').style.borderBottom = "none";
                document.getElementById('prop-active').style.borderRadius = "0px";
                document.getElementById('prop-active').style.marginBottom = "-1px";
            }

            if (str == 'DP') {
                document.getElementById('dp-active').style.color = "#a94442";
                document.getElementById('dp-active').style.backgroundColor = "#f2dede";
                document.getElementById('dp-active').style.border = "1px solid #f2dede";
                document.getElementById('dp-active').style.borderBottom = "none";
                document.getElementById('dp-active').style.borderRadius = "0px";
                document.getElementById('dp-active').style.marginBottom = "-1px";
            }

            // if (str == 'RES') {
            //     document.getElementById('res-active').style.color = "#006400";
            //     document.getElementById('res-active').style.backgroundColor = "#dff0d8";
            //     document.getElementById('res-active').style.border = "1px solid #dff0d8";
            //     document.getElementById('res-active').style.borderBottom = "none";
            //     document.getElementById('res-active').style.borderRadius = "0px";
            //     document.getElementById('res-active').style.marginBottom = "-1px";
            // }

            if (str == 'ADIAN') {
                document.getElementById('adian-active').style.color = "#8a6d3b";
                document.getElementById('adian-active').style.backgroundColor = "#fcf8e3";
                document.getElementById('adian-active').style.border = "1px solid #fcf8e3";
                document.getElementById('adian-active').style.borderBottom = "none";
                document.getElementById('adian-active').style.borderRadius = "0px";
                document.getElementById('adian-active').style.marginBottom = "-1px";
            }

            if (str == 'ADAP') {
                document.getElementById('adap-active').style.color = "#7d708f";
                document.getElementById('adap-active').style.backgroundColor = "#d9edf7";
                document.getElementById('adap-active').style.border = "1px solid #d9edf7";
                document.getElementById('adap-active').style.borderBottom = "none";
                document.getElementById('adap-active').style.borderRadius = "0px";
                document.getElementById('adap-active').style.marginBottom = "-1px";
            }
            vm.tab = str;
        }
    }
})();