(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ModalImagemController', ModalImagemController);

    ModalImagemController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', '$sessionStorage', 'IndicacaoConsulta', 'param'];

    function ModalImagemController($scope, $rootScope, $state, $timeout, Auth, $uibModalInstance, $sessionStorage, IndicacaoConsulta, param) {
        var vm = this;

        // variables
        vm.load = false;
        vm.selected = false;
        vm.selecionado = null;

        vm.cropper = {};
        vm.cropper.sourceImage = null;
        vm.cropper.croppedImage = null;
        vm.bounds = {};
        vm.bounds.left = 0;
        vm.bounds.right = 0;
        vm.bounds.top = 0;
        vm.bounds.bottom = 0;

        $scope.$watch('vm.selecionado', function(newvalue) {
            $rootScope.$emit('cropImage', newvalue);
        });

        // functions
        vm.confirm = confirm;
        vm.fechar = fechar;


        function confirm() {
            vm.selecionado = vm.cropper.croppedImage;
            $uibModalInstance.close(vm.selecionado);
        }

        function fechar() {
            vm.selecionado = null;
            $uibModalInstance.close(null);
        }
    }
})();