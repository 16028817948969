(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('CarregaIPuserService', CarregaIPuserService);

    CarregaIPuserService.$inject = ['$resource', 'DateUtils'];

    function CarregaIPuserService() {
        return null;
    }
})();