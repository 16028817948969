(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('inscricao-colegio', {
                parent: 'entity',
                url: '/inscricao-colegio',
                data: {
                    authorities: ['ROLE_UNIVEL_PLAY'],
                    pageTitle: 'InscricaoColegios'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/inscricao-colegio/inscricao-colegios.html',
                        controller: 'InscricaoColegioController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('inscricao-colegio-detail', {
                parent: 'entity',
                url: '/inscricao-colegio/{id}',
                data: {
                    authorities: ['ROLE_UNIVEL_PLAY'],
                    pageTitle: 'InscricaoColegio'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/inscricao-colegio/inscricao-colegio-detail.html',
                        controller: 'InscricaoColegioDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'InscricaoColegio', function($stateParams, InscricaoColegio) {
                        return InscricaoColegio.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'inscricao-colegio',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('inscricao-colegio-detail.edit', {
                parent: 'inscricao-colegio-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inscricao-colegio/inscricao-colegio-dialog.html',
                        controller: 'InscricaoColegioDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['InscricaoColegio', function(InscricaoColegio) {
                                return InscricaoColegio.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('inscricao-colegio.new', {
                parent: 'inscricao-colegio',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inscricao-colegio/inscricao-colegio-dialog.html',
                        controller: 'InscricaoColegioDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    codigoInscricao: null,
                                    codigoColegio: null,
                                    dataCriacao: null,
                                    usuarioCriacao: null,
                                    dataAlteracao: null,
                                    usuarioAlteracao: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('inscricao-colegio', null, { reload: 'inscricao-colegio' });
                    }, function() {
                        $state.go('inscricao-colegio');
                    });
                }]
            })
            .state('inscricao-colegio.edit', {
                parent: 'inscricao-colegio',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inscricao-colegio/inscricao-colegio-dialog.html',
                        controller: 'InscricaoColegioDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['InscricaoColegio', function(InscricaoColegio) {
                                return InscricaoColegio.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('inscricao-colegio', null, { reload: 'inscricao-colegio' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('inscricao-colegio.delete', {
                parent: 'inscricao-colegio',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SUPER_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inscricao-colegio/inscricao-colegio-delete-dialog.html',
                        controller: 'InscricaoColegioDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['InscricaoColegio', function(InscricaoColegio) {
                                return InscricaoColegio.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('inscricao-colegio', null, { reload: 'inscricao-colegio' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();