(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('DataPoloController', DataPoloController);

    DataPoloController.$inject = ['PolosAtivos', 'DataPoloDelete', 'DataPoloSave', 'DataPolo'];

    function DataPoloController(PolosAtivos, DataPoloDelete, DataPoloSave, DataPolo) {

        var vm = this;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.dia = false;

        init();

        function init() {
            vm.final = new Array();
            PolosAtivos.query(
                function(result) {
                    tratarDados(result);
                },
                function(status) {
                    console.log(status);
                }
            );

            console.log(vm.final);
        }


        function tratarDados(lista) {
            for (var p = 0; p < lista.length; p++) {
                var polo = lista[p];
                var listaVinc = consultaDatasPolo(polo.id);
                vm.final.push({
                    id: polo.id,
                    cidade: polo.cidade,
                    email1: polo.email1,
                    telefone1: polo.telefone1,
                    lista: listaVinc
                });
            }
        }


        function consultaDatasPolo(id) {
            var retorno = DataPolo.query({ idPolo: id });
            return retorno;
        }


        vm.removeDataPolo = function(item) {
            DataPoloDelete.delete({
                id: item.id
            });
            init();
        }

        vm.inserirVinculo = function() {

            var dia = vm.dataEscolhida.getDate();
            var mes = vm.dataEscolhida.getMonth() + 1;
            var ano = vm.dataEscolhida.getFullYear();
            if (mes < 10) { mes = "0" + mes; }
            if (dia < 10) { dia = "0" + dia; }
            var final = ano + "-" + mes + "-" + dia;
            var salvar = {
                idPolo: vm.poloEscolhido.id,
                dia: final,
                motivo: vm.motivo
            };
            DataPoloSave.save(salvar,
                function() {
                    vm.dataEscolhida = null;
                    vm.motivo = '';
                });
            init();
        }


        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();