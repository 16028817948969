(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaOnlineBoletoController', MatriculaOnlineBoletoController);

    MatriculaOnlineBoletoController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'Curso', 'LoginService', '$http', '$sessionStorage', 'Financeiro', 'ConsultaFinanceiroAberto'];

    function MatriculaOnlineBoletoController($timeout, $scope, $state, $stateParams, Curso, LoginService, $http, $sessionStorage, Financeiro, ConsultaFinanceiroAberto) {
        var vm = this;

        //vm.matricula = JSON.parse(sessionStorage.getItem('dados-matricula-contrato'));
        vm.matricula = null;
        vm.load = false;
        vm.gerarContrato = gerarContrato;
        vm.startCountdown = startCountdown;
        vm.liberado = true;
        vm.pgtos = null;

        vm.pixGerado = false;
        vm.pagarPix = false;

        vm.tempo = new Number();
        vm.tempo = 120;

        init();

        function init() {
            loadDadosCandidato();
        }


        function loadDadosCandidato() {
            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", vm.resultadoRM)
                .success(function(data) {
                    vm.matricula = data;
                    console.log(vm.matricula);
                    // vamos consultar se existe algum pagamento para esta matrícula;
                    loadFinanceiroAluno(vm.matricula);
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function loadFinanceiroAluno(aluno) {
            $http.post("/api/busca-financeiro-matricula-adiantamento", aluno)
                .success(function(result) {
                    vm.pgtos = result;
                    console.log(vm.pgtos);
                    for (var i = 0; i < result.length; i++) {
                        var e = result[i];

                        if (e.statusCielo == 2 || e.statusCielo == 7 || e.statusCielo == 1 || e.statusCielo == 0 || e.statusCielo == 10) {
                            vm.liberado = false
                        }
                    }
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function gerarContrato() {

            vm.load = true;

            $http.post('api/pre-matricula-getcontrato/', vm.matricula, { responseType: 'arraybuffer' })
                .success(function(data) {

                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    a.href = fileURL;
                    a.download = vm.matricula.nome + " - Contrato.pdf";
                    a.click();

                    window.URL.revokeObjectURL(fileURL);

                    vm.load = false;


                }).error(function(status) {
                    console.log(status);
                });;

        }

        vm.baixarBoleto = function(idLan) {
            vm.blockCampos = true;
            $http.post('api/adiantamento/getboleto', { idLan: idLan }, { responseType: 'arraybuffer' })
                .success(function(data) {
                    //console.log(data);
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = vm.matricula.nome + " - " + idLan + ".pdf";
                    vm.blockCampos = false;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }).error(function(status) {
                    console.log(status);
                });
        }

        vm.pegarPix = function(idBoleto, codFilial) {
            vm.blockCampos = true;
            $http.post("/api/adiantamento/getpix", {
                    idBoleto: idBoleto,
                    codFilial: codFilial
                }).success(function(result) {
                    vm.imgPix = result.data;
                    vm.modal = true;
                    vm.blockCampos = false;
                    startCountdown();
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        vm.pagarOnline = function(item) {
            $http.post("/api/createcheckoutadiantamento", vm.matricula)
                .success(function(result) {
                    console.log(result);
                    var a = result.settings.checkoutUrl.split("/");
                    var len = a.length;

                    item.idCheckOutCielo = a[len - 1];

                    Financeiro.update(item, function(result) {
                        //console.log(result);
                    }, function(status) {
                        console.log(status);
                    });

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = result.settings.checkoutUrl;
                    a.click();
                    vm.load = false;
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        /* CONTADOR DO TEMPO DE QRCODE */
        function startCountdown() {
            vm.tempo = 120;

            if (vm.tempo >= 1) {
                var min = parseInt(vm.tempo / 60);
                var comp = ' minutos.';
                if (min < 10) {
                    min = "0" + min;
                    min = min.substr(0, 2);
                }
                if (min == "01") {
                    comp = ' minuto.';
                }
                var horaImprimivel = min + comp;
                console.log(new Date() + "-" + horaImprimivel + "-" + vm.tempo);
                $("#tempoRestante").html(horaImprimivel);

                setTimeout(function() {
                    $("#tempoRestante").html(horaImprimivel);
                    vm.tempo = (vm.tempo - 60);
                    console.log(vm.tempo);
                    startCountdown();
                }, 60000);

            } else {
                clearTimeout();
                clearInterval();
                console.log(new Date() + "-" + vm.tempo);
                location.reload();
            }
        }


    }
})();