(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaOnlineConsentimentoController', MatriculaOnlineConsentimentoController);

    MatriculaOnlineConsentimentoController.$inject = ['$state', 'MatriculaOnlineServiceController', '$http'];

    function MatriculaOnlineConsentimentoController($state, MatriculaOnlineServiceController, $http) {
        var vm = this;
        vm.avancar = avancar;
        vm.ip = null;



        vm.ip = JSON.parse(sessionStorage.getItem('ip-user-vestibular'));
        console.log("IP ATUAL:" + vm.ip);
        setTimeout(getIpUser(), 1000);

        function getIpUser() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                }, function(status) {
                    return status;
                });
            }
        };

        vm.getIp = function() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                    avancar();
                }, function(status) {
                    return status;
                });
            } else {
                avancar();
            }
        }

        function avancar() {
            vm.load = true;
            save();
        }

        init();

        function init() {
            vm.load = false;
            vm.aceitei = false;
            loadDadosCandidato();
        }

        function loadDadosCandidato() {
            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", vm.resultadoRM)
                .success(function(data) {
                    vm.matricula = data;
                    switch (vm.matricula.etapa) {
                        case 'INICIO':
                            $state.go('new-matricula-inicio');
                            break;

                        case 'CANDIDATO':
                            $state.go('new-matricula-complemento');
                            break;

                        case 'COMPLEMENTO':
                            $state.go('new-matricula-filiacao');
                            break;

                            // case 'PAIS':
                            //     $state.go('new-matricula-consentimento');
                            //     break;

                        case 'TERMOCONSENTIMENTO':
                            $state.go('new-matricula-plano-pagamento');
                            break;

                        case 'PLANOPAGAMENTO':
                            $state.go('new-matricula-contrato');
                            break;
                        case 'CONTRATO':
                            $state.go('new-matricula-boleto');
                            break;

                        default:
                            console.log(vm.matricula);
                            break;
                    }
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function save() {
            vm.matricula.etapa = "TERMOCONSENTIMENTO";
            vm.matricula.ipAluno = vm.ip;
            MatriculaOnlineServiceController.save(vm.matricula, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.load = false;
            $state.go('new-matricula-plano-pagamento');
        }

        function onSaveError(result) {
            vm.load = false;
            console.log(result);
        }
    }
})();