(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('LinkVendedorController', LinkVendedorController);

    LinkVendedorController.$inject = ['LinkVendedor'];

    function LinkVendedorController(LinkVendedor) {

        var vm = this;

        vm.linkVendedors = [];

        loadAll();

        function loadAll() {
            LinkVendedor.query(function(result) {
                vm.linkVendedors = result;
                vm.searchQuery = null;
            });
        }
    }
})();
