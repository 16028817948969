(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ProvaOnlineController', ProvaOnlineController);

    ProvaOnlineController.$inject = ['ProvaOnline', 'ConsultaProvasService'];

    function ProvaOnlineController(ProvaOnline, ConsultaProvasService) {

        var vm = this;

        vm.provaOnlines = [];
        vm.loadAllFilter = loadAllFilter;
        vm.veioDoConsulta = false;

        vm.limitConsulta = "25";
        vm.campoConsulta = "INICIO";
        vm.idpsConsulta = "ATIVOS";
        vm.brancoConsulta = 'NC';

        vm.testeCand = JSON.parse(sessionStorage.getItem('insc-prova-online-detail'));
        console.log(vm.testeCand);


        if (vm.testeCand == null) {
            loadAllFilter("INICIO", "INICIO", 25, "ATIVOS", "NC");
        } else {
            vm.veioDoConsulta = true;
            if (vm.testeCand.id == null) {
                if (vm.testeCand.cpf == null) {
                    loadAllFilter("INICIO", "INICIO", 25, "ATIVOS", "NC");
                } else {
                    loadAllFilter("CPF", vm.testeCand.cpf, 25, "ATIVOS", "NC");
                }
            } else {
                loadAllFilter("INSCRICAO", vm.testeCand.id, 25, "ATIVOS", "NC");
            }
        }

        /*loadAll();

        function loadAll() {
            ProvaOnline.query(function(result) {
                vm.provaOnlines = result;
                console.log(vm.provaOnlines);
                vm.searchQuery = null;
            });
        }*/

        function loadAllFilter(campo, valor, limit, idps, branco) {
            sessionStorage.removeItem('insc-prova-online-detail');
            ConsultaProvasService.query({ campo: campo, valor: valor, limit: limit, idps: idps, branco: branco },
                function(result) {
                    vm.provaOnlines = result;
                    //console.log(vm.provaOnlines);
                    vm.searchQuery = null;
                },
                function(status) {
                    console.log(status);
                }
            );
        }
    }
})();