(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('AppConfigFinDetailController', AppConfigFinDetailController);

    AppConfigFinDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AppConfigFin'];

    function AppConfigFinDetailController($scope, $rootScope, $stateParams, previousState, entity, AppConfigFin) {
        var vm = this;

        vm.appConfigFin = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:appConfigFinUpdate', function(event, result) {
            vm.appConfigFin = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
