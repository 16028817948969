(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('BuscaPropostaMatriculaDp', BuscaPropostaMatriculaDp);

    BuscaPropostaMatriculaDp.$inject = ['$resource', 'DateUtils'];

    function BuscaPropostaMatriculaDp($resource, DateUtils) {
        var resourceUrl = 'api/consulta-proposta-ra/:user';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();