(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('AlteraPropostaMatriculaDp', AlteraPropostaMatriculaDp);

    AlteraPropostaMatriculaDp.$inject = ['$resource', 'DateUtils'];

    function AlteraPropostaMatriculaDp($resource, DateUtils) {
        var resourceUrl = 'api/altera-proposta-matric-dp/:id/:status/:obs/:user';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();