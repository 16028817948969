(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('DatasPorPolo', DatasPorPolo);

    DatasPorPolo.$inject = ['$resource', 'DateUtils'];

    function DatasPorPolo($resource, DateUtils) {
        var resourceUrl = 'api/datasPorPolo/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataProva = DateUtils.convertLocalDateFromServer(data.dataProva);
                    }
                    return data;
                }
            }
        });
    }
})();