(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaDiariosByUser', BuscaDiariosByUser);

    BuscaDiariosByUser.$inject = ['$resource', 'DateUtils'];

    function BuscaDiariosByUser($resource, DateUtils) {
        var resourceUrl = '/api/busca-diario-for-user';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true }
        });
    }
})();