(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RematriculaTermoController', RematriculaTermoController);

        RematriculaTermoController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'RematriculaContratoServiceController', 'Curso', 'LoginService', '$http', '$sessionStorage', 'IntegracaoNormal'];

    function RematriculaTermoController($timeout, $scope, $state, $stateParams, RematriculaContratoServiceController, Curso, LoginService, $http, $sessionStorage, IntegracaoNormal) {
        
        var vm = this;
        vm.avancar = avancar;
        vm.academico = JSON.parse(sessionStorage.getItem('dados-academico'));
        vm.load = false;
        vm.aceitei = false;
        vm.ip = null;
        vm.presencial = true;

        function avancar() {
            vm.load = true;
            vm.academico.status = "BOLETO";
            sessionStorage.setItem('dados-academico', JSON.stringify(vm.academico));
            RematriculaContratoServiceController.save(vm.academico, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            IntegracaoNormal.query(vm.academico,
                function(data) {
                    vm.load = false;
                    $state.go('rematriculaBoleto');
                },
                function(result) {
                    console.log(result);
                });
        }

        function onSaveError(result) {
            vm.load = false;
            console.log(result);
        }
    }
})();