(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RematriculaDisciplinasController', RematriculaDisciplinasController);

    RematriculaDisciplinasController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'RematriculaDadosCadastraisServiceController', 'Curso', 'LoginService', '$http', '$sessionStorage'];

    function RematriculaDisciplinasController($timeout, $scope, $state, $stateParams, RematriculaDadosCadastraisServiceController, Curso, LoginService, $http, $sessionStorage) {
        var vm = this;

        vm.academico = JSON.parse(sessionStorage.getItem('dados-academico'));

        vm.disciplinas;
        vm.aceitei = false;
        $http.post("/api/getDisciplinasByRa", vm.academico)
            .success(function(data) {
                vm.disciplinas = data;
            })
            .error(function(status) {
                console.log(status);
            });


        vm.avancar = avancar;

        function avancar() {
            sessionStorage.setItem('disciplinas-academico', JSON.stringify(vm.disciplinas));

            vm.academico.status = "CONTRATO";
            RematriculaDadosCadastraisServiceController.save(vm.academico,
                function(data2) {
                    $state.go('rematriculaContrato');
                },
                function(result2) {
                    console.log(result2);
                });
        }
    }
})();