(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaDpPropostasController', MatriculaDpPropostasController);

    MatriculaDpPropostasController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$http', '$sessionStorage', 'BuscaPropostasPendentes', 'AlteraPropostaMatriculaDp', 'AprovaPropostaPendente', 'Principal', 'BuscaControleFinPropostas', 'GeraFinanPropostas'];

    function MatriculaDpPropostasController($timeout, $scope, $state, $stateParams, $http, $sessionStorage, BuscaPropostasPendentes, AlteraPropostaMatriculaDp, AprovaPropostaPendente, Principal, BuscaControleFinPropostas, GeraFinanPropostas) {
        var vm = this;

        vm.load = false;

        vm.propostasDisponiveis = null;
        vm.coresSimulacao = ['#43CD80', '#B0E2FF', '#C1CDCD', '#F5F5DC', '#98FB98', '#87CEFA', '#8470FF', '#D3D3D3', '#FFDAB9'];

        vm.aprovarProposta = aprovarProposta;
        vm.reprovarProposta = reprovarProposta;
        vm.campoConsulta = "STATUS";
        vm.valorConsulta = "PENDENTE";
        vm.anoConsulta = new Date().getFullYear();
        vm.limiteConsulta = "10";
        vm.motivocanc = "";
        vm.loadAll = LoadAll;
        vm.loadFinanceiro = loadFinanceiro;
        vm.gerarFinanceiro = gerarFinanceiro;

        vm.validaCH = 'NAO';
        vm.validaQTD = 'NAO';

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                console.log(vm.account);
                vm.etapa = 1;
                //LoadAll();
            });
        }

        function LoadAll() {

            vm.load = true;
            vm.validaCH = 'NAO';
            vm.validaQTD = 'NAO';

            vm.valorConsulta = vm.valorConsulta.replace(".", "");

            BuscaPropostasPendentes.query({
                    campo: vm.campoConsulta,
                    valor: vm.valorConsulta,
                    ano: vm.anoConsulta,
                    limite: vm.limiteConsulta
                },
                function(data) {
                    //console.log(data);
                    vm.propostasDisponiveis = data;
                    vm.load = false;
                },
                function(status) {
                    console.log(status);
                    vm.load = false;
                });

        }

        function loadFinanceiro() {

            vm.load = true;

            if (vm.campoConsulta == "DATA") {
                //vm.valorConsulta = vm.valorConsulta.getFullYear() + '-' + vm.valorConsulta.getMonth() + '-' + vm.valorConsulta.getDate();
                vm.valorFinal = vm.valorConsulta.toISOString().split('T')[0];
            } else {
                vm.valorFinal = vm.valorConsulta;
            }

            //console.log(vm.campoConsulta);
            //console.log(vm.valorFinal);
            //console.log(vm.anoConsulta);
            //console.log(vm.limiteConsulta);

            vm.valorFinal = vm.valorFinal.replace(".", "");

            BuscaControleFinPropostas.query({
                    campo: vm.campoConsulta,
                    valor: vm.valorFinal,
                    ano: vm.anoConsulta,
                    limite: vm.limiteConsulta
                },
                function(data) {
                    console.log(data);
                    vm.financeiro = data;
                    vm.load = false;
                },
                function(status) {
                    console.log(status);
                    vm.load = false;
                });

        }

        function gerarFinanceiro() {

            if (vm.idProposta != null && vm.idProposta != "" && vm.idProposta != undefined) {

                var a = confirm('Deseja realmente regerar as parcelas no rm desta proposta? ');

                if (a) {
                    GeraFinanPropostas.query({ id: vm.idProposta },
                        function(data) {
                            console.log(data);
                            alert("Operação finalizada");
                        },
                        function(status) {
                            console.log(status);
                            alert("Erro ao executar operação");
                        });
                } else {
                    alert("Operação cencelada");
                }
            } else {
                alert("verifique o id da proposta e tente novamente.");
            }


        }

        function reprovarProposta(proposta) {
            vm.load = true;

            if (vm.motivocanc == "") {
                alert("Por favor preencha o motivo para reprovação")
            } else {
                AlteraPropostaMatriculaDp.query({ id: proposta.id, status: "INDEFERIDA", obs: vm.motivocanc, user: vm.account.login },
                    function(data) {
                        alert("Finalizado indeferimento da prosposta:" + proposta.ra + " - " + proposta.disciplina);
                        LoadAll();
                    },
                    function(status) {
                        console.log(status);
                        LoadAll();
                    });
            }
        }

        function aprovarProposta(proposta) {
            console.log(proposta);
            if (proposta.codStatusOriginal == "130") {
                alert("Não é possível aprovar proposta com Status de Análise, favor alterar o CodStatus")
            } else {
                proposta.codStatus = proposta.codStatusOriginal;
                proposta.userSec = vm.account.login;
                AprovaPropostaPendente.query(proposta,
                    function(data) {
                        alert("Finalizado aprovação da prosposta:" + proposta.ra + " - " + proposta.disciplina);
                        vm.closeModal();
                        LoadAll();
                    },
                    function(status) {
                        alert(status);
                        LoadAll();
                    });
            }
        }

        vm.closeModal = function() {
            console.log("Entrou no Close");
            var modal = document.getElementById("modalProposta");
            var btn = document.createElement("button");
            modal.appendChild(btn);
            btn.style.display = "none";
            btn.setAttribute('data-dismiss', 'modal');
            btn.click();
            vm.modal = false;
        }

        vm.abreModal = function(proposta) {
            vm.propostaModal = proposta;
            //console.log(vm.propostaModal);
            vm.modal = true;

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.setAttribute('data-target', '#modalProposta');
            a.setAttribute('data-toggle', 'modal');
            a.click();
        }
    }
})();