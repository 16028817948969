(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaCodPerletAtivo', BuscaCodPerletAtivo);

    BuscaCodPerletAtivo.$inject = ['$resource', 'DateUtils'];

    function BuscaCodPerletAtivo($resource, DateUtils) {
        var resourceUrl = '/api/plano-aulas/busca-codperlet-ativos';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true }
        });
    }
})();