(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RelatorioPesquisaDetailController', RelatorioPesquisaDetailController);

    RelatorioPesquisaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RelatorioPesquisa'];

    function RelatorioPesquisaDetailController($scope, $rootScope, $stateParams, previousState, entity, RelatorioPesquisa) {
        var vm = this;

        vm.relatorioPesquisa = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:relatorioPesquisaUpdate', function(event, result) {
            vm.relatorioPesquisa = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
