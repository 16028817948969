(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatrizTurmaDisciplinaDeleteController',MatrizTurmaDisciplinaDeleteController);

    MatrizTurmaDisciplinaDeleteController.$inject = ['$uibModalInstance', 'entity', 'MatrizTurmaDisciplina'];

    function MatrizTurmaDisciplinaDeleteController($uibModalInstance, entity, MatrizTurmaDisciplina) {
        var vm = this;

        vm.matrizTurmaDisciplina = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MatrizTurmaDisciplina.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
