(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('TermoProvaOnlineController', TermoProvaOnlineController);

    TermoProvaOnlineController.$inject = ['$scope', '$state', '$http'];

    function TermoProvaOnlineController($scope, $state, $http) {
        var vm = this;

        vm.avancar = avancar;
        vm.ip = null;

        vm.ip = JSON.parse(sessionStorage.getItem('ip-user-vestibular'));
        console.log("IP ATUAL:" + vm.ip);
        setTimeout(getIpUser(), 1000);

        function getIpUser() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                }, function(status) {
                    return status;
                });
            }
        };

        vm.getIp = function() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                    avancar();
                }, function(status) {
                    return status;
                });
            } else {
                avancar();
            }
        }

        function avancar() {
            vm.aceite = {
                ip: vm.ip,
                data: new Date()
            }

            sessionStorage.setItem('aceite', JSON.stringify(vm.aceite));
            $state.go('provaOnline');
        }
    }
})();