(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('Users_poloDialogController', Users_poloDialogController);

    Users_poloDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Users_polo', 'User', 'PolosAtivos'];

    function Users_poloDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Users_polo, User, PolosAtivos) {
        var vm = this;

        vm.users_polo = entity;
        vm.clear = clear;
        vm.save = save;
        vm.selecionaUser = selecionaUser;
        vm.selecionaPolo = selecionaPolo;

        vm.users = User.query();
        vm.polos = PolosAtivos.query();


        console.log(vm.users);
        console.log(vm.polos);

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function selecionaUser(user) {
            vm.users_polo.user_id = user.id;
            vm.user_selecionado = user;
        }

        function selecionaPolo(polo) {
            vm.users_polo.polo_id = polo.id;
            vm.polo_selecionado = polo;
        }

        function save() {
            vm.isSaving = true;
            if (vm.users_polo.id !== null) {
                Users_polo.update(vm.users_polo, onSaveSuccess, onSaveError);
            } else {
                Users_polo.save(vm.users_polo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:users_poloUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();