(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RelatorioExtensaoFileDialogController', RelatorioExtensaoFileDialogController);

    RelatorioExtensaoFileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'RelatorioExtensaoFile', 'Principal'];

    function RelatorioExtensaoFileDialogController($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, RelatorioExtensaoFile, Principal) {
        var vm = this;

        vm.relatorioExtensaoFile = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function save() {
            vm.isSaving = true;

            vm.relatorioExtensaoFile.createdDate = new Date();
            vm.relatorioExtensaoFile.createdUser = vm.account.login;
            vm.relatorioExtensaoFile.tamanho = vm.byteSize(vm.relatorioExtensaoFile.evidencia);

            if (vm.relatorioExtensaoFile.id !== null) {
                RelatorioExtensaoFile.update(vm.relatorioExtensaoFile, onSaveSuccess, onSaveError);
            } else {
                RelatorioExtensaoFile.save(vm.relatorioExtensaoFile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:relatorioExtensaoFileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.setEvidencia = function($file, relatorioExtensaoFile) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        relatorioExtensaoFile.evidencia = base64Data;
                        relatorioExtensaoFile.evidenciaContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.createdDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();