(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaIdpsNovaProva', BuscaIdpsNovaProva);

    BuscaIdpsNovaProva.$inject = ['$resource', 'DateUtils'];

    function BuscaIdpsNovaProva($resource, DateUtils) {
        var resourceUrl = '/api/busca-idps-nova-prova';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: false }
        });
    }
})();