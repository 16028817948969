(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlanoAulaController', PlanoAulaController);

    PlanoAulaController.$inject = ['$state', 'PlanoAula', 'Principal', 'BuscaPlanosForPlanoAula', 'BuscaCodPerletAtivo', 'BuscaPlanoAulaUser', 'ImportPlanoAulaByEnsino',
        'BuscaDiscsPlanoIdService', 'PlanoEnsinoDisciplina', 'BuscaDiscPlano', 'BuscaDiscCadastradas'
    ];

    function PlanoAulaController($state, PlanoAula, Principal, BuscaPlanosForPlanoAula, BuscaCodPerletAtivo, BuscaPlanoAulaUser, ImportPlanoAulaByEnsino,
        BuscaDiscsPlanoIdService, PlanoEnsinoDisciplina, BuscaDiscPlano, BuscaDiscCadastradas) {

        var vm = this;

        vm.importarPlanosRM = importplanosrm;
        vm.consultaPlanos = consultaPlanos;
        vm.consultaPlanosRM = consultaPlanosRM;
        vm.validaDiscsPlanoSelecionado = validaDiscsPlanoSelecionado;
        vm.removeDiscPlano = removeDiscPlano;
        vm.addDiscPlano = addDiscPlano;
        vm.refresh = refresh;
        vm.planos = null;
        vm.planosRm = null;
        vm.user = {};
        vm.consulta = {};
        vm.consultaRm = {};

        function refresh() {
            consultaPlanos();
            consultaPlanosRM();
        }

        getAccount();

        function getAccount() {
            vm.load = true;
            Principal.identity().then(function(account) {
                vm.load = false;
                vm.account = account;
                vm.accountConsulta = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                console.log(vm.account);
                getContexto();
                vm.etapa = 2;
            });
        };


        function getContexto() {
            vm.load = true;
            BuscaCodPerletAtivo.query(function(result) {
                vm.consulta.codPerlet = result[0];
                console.log(vm.consulta);
                vm.load = false;
                consultaPlanos();
            }, function(status) {
                vm.load = false;
                alert('Não foi possível carregar o contexto.');
            });
        }

        function consultaPlanos() {
            vm.load = true;
            vm.consulta.curso = "TODOS";
            vm.consulta.disciplina = "TODOS";
            vm.consulta.etapa = "APROVADOC";
            vm.consulta.user = vm.accountConsulta;

            BuscaPlanosForPlanoAula.query(vm.consulta, function(result) {
                vm.planos = result;
                vm.load = false;
                console.log(vm.planos);
            }, function(status) {
                vm.load = false;
                console.log(status);
            });
        }


        function consultaPlanosRM() {
            vm.load = true;
            vm.consulta.user = vm.accountConsulta;
            BuscaPlanoAulaUser.query(vm.consulta, function(result) {
                vm.planosRm = result;
                console.log(vm.planosRm);
                vm.load = false;
            }, function(status) {
                vm.load = false;
                console.log(status);
            });

        }

        function validaDiscsPlanoSelecionado() {
            BuscaDiscsPlanoIdService.query({ id: vm.planoSelecionado.id }, function(turmas) {
                vm.disciplinasPlano = turmas;
                console.log(vm.disciplinasPlano);

                // aqui tem que buscar as turmas semelhantes no RM IDPERLET / NOME
                buscaDiscsPlanoRM();

            }, function(status) { console.log(status) });


        }

        function buscaDiscsPlanoRM() {
            var obj = {
                disciplina: vm.planoSelecionado.disciplina,
                curso: "TODOS",
                tipoUser: vm.planoSelecionado.tipoPlano
            }
            console.log(obj);

            vm.resultadoDisc = [];
            BuscaDiscPlano.query(obj,
                function(data) {
                    BuscaDiscCadastradas.query(
                        function(discs) {
                            for (var i = 0; i < data.length; i++) {
                                var d = data[i];
                                if (!discs.includes(d.idTurmaDisc)) {
                                    vm.resultadoDisc.push(d);
                                }
                            }
                        },
                        function(status) {
                            console.log(status);
                        }
                    );
                    console.log(vm.resultadoDisc);
                },
                function(status) {
                    console.log(status);
                }
            );
        }

        function removeDiscPlano(id) {
            PlanoEnsinoDisciplina.delete({ id: id },
                function(data) {
                    alert("disciplina removida com sucesso.");
                    validaDiscsPlanoSelecionado();
                },
                function(status) {
                    alert("houve um erro ao remover a disciplina, tente novamente mais tarde.");
                });
        }

        function addDiscPlano(item) {

            var obj = {
                createdDate: new Date(),
                createdUser: vm.planoSelecionado.createdUser,
                disciplina: item.disciplina,
                idPerlet: vm.planoSelecionado.idPerlet,
                idPlano: vm.planoSelecionado.id,
                turma: item.codTurma,
                codDisc: item.idTurmaDisc
            }

            PlanoEnsinoDisciplina.save(obj,
                function(data) {
                    validaDiscsPlanoSelecionado();
                },
                function(status) {
                    alert("houve um erro ao adicionar a disciplina, tente novamente mais tarde.");
                });
        }

        function importplanosrm() {
            vm.load = true;
            ImportPlanoAulaByEnsino.query({ id: vm.planoSelecionado.id }, function(result) {
                vm.load = false;
                vm.etapa = 3;
                consultaPlanosRM();
            }, function(status) {
                vm.load = false;
                console.log(status);
            });
        }
    }
})();