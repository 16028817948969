(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('CorrecaoRedacaoService', CorrecaoRedacaoService);

        CorrecaoRedacaoService.$inject = ['$resource'];

    function CorrecaoRedacaoService($resource) {
        var resourceUrl = 'api/buscaNotaProvaRedacaoOnline';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: false }
        });
    }
})();