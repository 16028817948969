(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConfirmacaoInscricaoTradicionalController', ConfirmacaoInscricaoTradicionalController);

    ConfirmacaoInscricaoTradicionalController.$inject = ['$timeout', 'DateUtils', '$scope', '$state', '$stateParams', '$sessionStorage', '$uibModalInstance', 'PolosAtivos'];

    function ConfirmacaoInscricaoTradicionalController($timeout, DateUtils, $scope, $state, $stateParams, $sessionStorage, $uibModalInstance, PolosAtivos) {
        var vm = this;
        vm.redirect = redirect;
        vm.dados = undefined;
        vm.mobile = validaMobile();

        //loadCandidato();

        function loadCandidato() {
            vm.prova = JSON.parse(sessionStorage.getItem('prova-agendado'));
            vm.inscricao = JSON.parse(sessionStorage.getItem('inscricao-agendado'));

            //sessionStorage.removeItem('insc-trad-2020');

            if (vm.dados.tipoInteresse == '1') {
                vm.titulo = "Vestibular 2020";
                vm.texto = "Prezado(a) " + vm.dados.nome + ",";
                vm.texto2 = "Sua inscrição para o Vestibular Univel 2020 foi concluída com sucesso!";
                vm.texto3 = "Nós encaminhamos uma confirmação para o email: " + vm.dados.email + ".";
                vm.texto4 = "Não esqueça que a prova será no dia 04/10/2019, com início às 13H, traga seu documento com foto e o comprovante de inscrição anexado no email de confirmação.";
            }

            if (vm.dados.tipoInteresse == '2') {
                vm.titulo = "Univel Play 2019";
                vm.texto = "Prezado(a) " + vm.dados.nome + ",";
                vm.texto2 = "Sua inscrição para o Univel Play 2019 foi concluída com sucesso!";
                vm.texto3 = "Nós encaminhamos uma confirmação para o email: " + vm.dados.email + ".";
                vm.texto4 = "Não esqueça que o evento será no dia 04/10/2019, com início às 09H, traga seu documento com foto e o comprovante de inscrição anexado no email de confirmação.";
            }

            if (vm.dados.tipoInteresse == '3') {
                vm.titulo = "Play + Vestibular 2020";
                vm.texto = "Prezado(a) " + vm.dados.nome + ",";
                vm.texto2 = "Sua inscrição para o Play + Vestibular 2020 foi concluída com sucesso!";
                vm.texto3 = "Nós encaminhamos uma confirmação para o email: " + vm.dados.email + ".";
                vm.texto4 = "Não esqueça que o evento será no dia 04/10/2019, com início às 09H e provas às 13H, traga seu documento com foto e o comprovante de inscrição anexado no email de confirmação.";
            }

            if (vm.dados.tipoInteresse == '4') {
                vm.titulo = "Vestibular Agendado 2020";
                vm.texto = "Prezado(a) " + vm.dados.nome + ",";
                vm.texto2 = "Sua inscrição para o Vestibular Agendado 2020 foi concluída com sucesso!";
                vm.texto3 = "Nós encaminhamos uma confirmação para o email: " + vm.dados.email + ".";
                vm.texto4 = "Sua prova está marcada para dia: " + formatData(vm.dados.dataProva) + " às " + vm.dados.horaProva.substring(1, 3) + "H, traga seu documento com foto.";
            }

            if (vm.dados.tipoInteresse == '5') {
                vm.titulo = "Univel Play 2019";
                vm.texto = "Prezado(a) " + vm.dados.nome + ",";
                vm.texto2 = "Sua inscrição para o Univel Play 2019 foi concluída com sucesso!";
                vm.texto3 = "Nós encaminhamos uma confirmação para o email: " + vm.dados.email + ".";
                vm.texto4 = "Não esqueça que o evento será no dia 05/10/2019, com início às 09H, traga seu documento com foto e o comprovante de inscrição anexado no email de confirmação.";
            }

            if (vm.dados.tipoInteresse == '6') {
                vm.titulo = "Play + Vestibular 2020";
                vm.texto = "Prezado(a) " + vm.dados.nome + ",";
                vm.texto2 = "Sua inscrição para o Play + Vestibular 2020 foi concluída com sucesso!";
                vm.texto3 = "Nós encaminhamos uma confirmação para o email: " + vm.dados.email + ".";
                vm.texto4 = "Não esqueça que o evento será no dia 05/10/2019, com início às 09H, traga seu documento com foto e o comprovante de inscrição anexado no email de confirmação.";
            }

            if (vm.dados.tipoInteresse == '7') {
                vm.titulo = "Confirmação de transporte!";
                vm.texto = "Prezado(a) " + vm.dados.nome + ",";
                vm.texto2 = "seu transporte para essa aventura está garantido.";
                vm.texto3 = "Enviamos a confirmação para o seu e-mail: " + vm.dados.email + ".";
                vm.texto4 = "Não esqueça que o evento será no dia 04/10/2019, com início às 09H e provas às 13H, traga seu documento com foto e o comprovante de inscrição anexado no email de confirmação.";
            }

        }

        function formatData(data) {
            var dia = data.substring(8, 10);
            var diaF = (dia.length == 1) ? '0' + dia : dia;
            var mes = data.substring(5, 7);
            var mesF = (mes.length == 1) ? '0' + mes : mes;
            var anoF = data.substring(0, 4);
            return diaF + "/" + mesF + "/" + anoF;
        }

        function validaMobile() {
            if (navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/webOS/i) ||
                navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPod/i) ||
                navigator.userAgent.match(/BlackBerry/i) ||
                navigator.userAgent.match(/Windows Phone/i)
            ) {
                return "MOBILE";
            } else {
                return "PC";
            }
        }

        function redirect(value) {
            $uibModalInstance.close(null);
            $state.go(value);
        }
    }
})();