(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('AvaliaPlanoEnsinoController', AvaliaPlanoEnsinoController);

    AvaliaPlanoEnsinoController.$inject = ['$state', 'LoginService', '$http', 'Principal', 'Auth', 'BuscaItensPlanoService', 'BuscaBBPlanoService', 'PlanoEnsinoObs', 'BuscaObsPlanoService', 'PlanoEnsino', 'PlanoEnsinoLog', 'BuscaDiscsPlanoIdService'];

    function AvaliaPlanoEnsinoController($state, LoginService, $http, Principal, Auth, BuscaItensPlanoService, BuscaBBPlanoService, PlanoEnsinoObs, BuscaObsPlanoService, PlanoEnsino, PlanoEnsinoLog, BuscaDiscsPlanoIdService) {
        var vm = this;

        vm.plano = {};
        vm.insereObs = insereObs;
        vm.removeObs = removeObs;
        vm.saveObs = saveObs;
        vm.salvarPlano = salvarPlano;
        vm.loading = false;

        vm.bBasica = [];
        vm.bCompl = [];
        vm.bApro = [];

        vm.listaObs = [];
        vm.disciplinasPlano = [];
        vm.obs = {};

        vm.cordenador = false;
        vm.nucleo = false;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.account.authorities.includes("ROLE_COORDENADOR")) {
                    if (vm.account.authorities.includes("ROLE_NUCLEO")) {
                        vm.nucleo = true;
                    } else {
                        vm.cordenador = true;
                    }
                }
                start();
            });
        };

        function start() {
            vm.loading = true;
            var temp = JSON.parse(sessionStorage.getItem('plano-ensino-correcao'));
            console.log(temp);
            BuscaItensPlanoService.query({ id: temp.id },
                function(itens) {
                    for (var i = 0; i < itens.length; i++) {
                        var e = itens[i];

                        if (e.tipo == 'COMPETENCIA') { temp.competencias = e.texto; };
                        if (e.tipo == 'EMENTA') { temp.ementa = e.texto; };
                        if (e.tipo == 'RECINOV1') { temp.recursos1 = e.texto; };
                        if (e.tipo == 'AS1') { temp.contAs1 = e.texto; };
                        if (e.tipo == 'AS2') { temp.contAs2 = e.texto; };
                        if (e.tipo == 'INSTAVA1B') { temp.inst1b = e.texto; };
                        if (e.tipo == 'INSTAVA2B') { temp.inst2b = e.texto; };
                        if (e.tipo == 'BBASICA') { temp.bBasica = e.texto; };
                        if (e.tipo == 'BCOMPL') { temp.bCompl = e.texto; };
                        if (e.tipo == 'BAPRO') { temp.bApro = e.texto; };
                        if (e.tipo == 'OBSG') { temp.obsg = e.texto; };


                        if (e.tipo == 'CONT1') { temp.cont1 = e.texto; };
                        if (e.tipo == 'CONT2') { temp.cont2 = e.texto; };
                        if (e.tipo == 'CONT3') { temp.cont3 = e.texto; };
                        if (e.tipo == 'CONT4') { temp.cont4 = e.texto; };
                        if (e.tipo == 'CONT5') { temp.cont5 = e.texto; };
                        if (e.tipo == 'CONT6') { temp.cont6 = e.texto; };
                        if (e.tipo == 'CONT7') { temp.cont7 = e.texto; };
                        if (e.tipo == 'CONT8') { temp.cont8 = e.texto; };
                        if (e.tipo == 'CONT9') { temp.cont9 = e.texto; };
                        if (e.tipo == 'CONT10') { temp.cont10 = e.texto; };
                        if (e.tipo == 'CONT11') { temp.cont11 = e.texto; };
                        if (e.tipo == 'CONT12') { temp.cont12 = e.texto; };
                        if (e.tipo == 'CONT13') { temp.cont13 = e.texto; };
                        if (e.tipo == 'CONT14') { temp.cont14 = e.texto; };
                        if (e.tipo == 'CONT15') { temp.cont15 = e.texto; };
                        if (e.tipo == 'CONT16') { temp.cont16 = e.texto; };
                        if (e.tipo == 'CONT17') { temp.cont17 = e.texto; };
                        if (e.tipo == 'CONT18') { temp.cont18 = e.texto; };
                        if (e.tipo == 'CONT19') { temp.cont19 = e.texto; };
                        if (e.tipo == 'CONT20') { temp.cont20 = e.texto; };
                        if (e.tipo == 'CONT21') { temp.cont21 = e.texto; };
                        if (e.tipo == 'CONT22') { temp.cont22 = e.texto; };
                        if (e.tipo == 'CONT23') { temp.cont23 = e.texto; };
                        if (e.tipo == 'CONT24') { temp.cont24 = e.texto; };
                        if (e.tipo == 'CONT25') { temp.cont25 = e.texto; };
                        if (e.tipo == 'CONT26') { temp.cont26 = e.texto; };
                        if (e.tipo == 'CONT27') { temp.cont27 = e.texto; };
                        if (e.tipo == 'CONT28') { temp.cont28 = e.texto; };
                        if (e.tipo == 'CONT29') { temp.cont29 = e.texto; };
                        if (e.tipo == 'CONT30') { temp.cont30 = e.texto; };
                        if (e.tipo == 'CONT31') { temp.cont31 = e.texto; };
                        if (e.tipo == 'CONT32') { temp.cont32 = e.texto; };
                        if (e.tipo == 'CONT33') { temp.cont33 = e.texto; };
                        if (e.tipo == 'CONT34') { temp.cont34 = e.texto; };
                        if (e.tipo == 'CONT35') { temp.cont35 = e.texto; };
                        if (e.tipo == 'CONT36') { temp.cont36 = e.texto; };
                        if (e.tipo == 'CONT37') { temp.cont37 = e.texto; };
                        if (e.tipo == 'CONT38') { temp.cont38 = e.texto; };
                        if (e.tipo == 'CONT39') { temp.cont39 = e.texto; };
                        if (e.tipo == 'CONT40') { temp.cont40 = e.texto; };
                        if (e.tipo == 'CONT41') { temp.cont41 = e.texto; };
                        if (e.tipo == 'CONT42') { temp.cont42 = e.texto; };
                        if (e.tipo == 'CONT43') { temp.cont43 = e.texto; };
                        if (e.tipo == 'CONT44') { temp.cont44 = e.texto; };
                        if (e.tipo == 'CONT45') { temp.cont45 = e.texto; };
                        if (e.tipo == 'CONT46') { temp.cont46 = e.texto; };
                        if (e.tipo == 'CONT47') { temp.cont47 = e.texto; };
                        if (e.tipo == 'CONT48') { temp.cont48 = e.texto; };
                        if (e.tipo == 'CONT49') { temp.cont49 = e.texto; };
                        if (e.tipo == 'CONT50') { temp.cont50 = e.texto; };
                        if (e.tipo == 'CONT51') { temp.cont51 = e.texto; };
                        if (e.tipo == 'CONT52') { temp.cont52 = e.texto; };
                        if (e.tipo == 'CONT53') { temp.cont53 = e.texto; };
                        if (e.tipo == 'CONT54') { temp.cont54 = e.texto; };
                        if (e.tipo == 'CONT55') { temp.cont55 = e.texto; };
                        if (e.tipo == 'CONT56') { temp.cont56 = e.texto; };
                        if (e.tipo == 'CONT57') { temp.cont57 = e.texto; };
                        if (e.tipo == 'CONT58') { temp.cont58 = e.texto; };
                        if (e.tipo == 'CONT59') { temp.cont59 = e.texto; };
                        if (e.tipo == 'CONT60') { temp.cont60 = e.texto; };

                        if (e.tipo == 'MET1') { temp.met1 = e.texto; };
                        if (e.tipo == 'MET2') { temp.met2 = e.texto; };
                        if (e.tipo == 'MET3') { temp.met3 = e.texto; };
                        if (e.tipo == 'MET4') { temp.met4 = e.texto; };
                        if (e.tipo == 'MET5') { temp.met5 = e.texto; };
                        if (e.tipo == 'MET6') { temp.met6 = e.texto; };
                        if (e.tipo == 'MET7') { temp.met7 = e.texto; };
                        if (e.tipo == 'MET8') { temp.met8 = e.texto; };
                        if (e.tipo == 'MET9') { temp.met9 = e.texto; };
                        if (e.tipo == 'MET10') { temp.met10 = e.texto; };
                        if (e.tipo == 'MET11') { temp.met11 = e.texto; };
                        if (e.tipo == 'MET12') { temp.met12 = e.texto; };
                        if (e.tipo == 'MET13') { temp.met13 = e.texto; };
                        if (e.tipo == 'MET14') { temp.met14 = e.texto; };
                        if (e.tipo == 'MET15') { temp.met15 = e.texto; };
                        if (e.tipo == 'MET16') { temp.met16 = e.texto; };
                        if (e.tipo == 'MET17') { temp.met17 = e.texto; };
                        if (e.tipo == 'MET18') { temp.met18 = e.texto; };
                        if (e.tipo == 'MET19') { temp.met19 = e.texto; };
                        if (e.tipo == 'MET20') { temp.met20 = e.texto; };
                        if (e.tipo == 'MET21') { temp.met21 = e.texto; };
                        if (e.tipo == 'MET22') { temp.met22 = e.texto; };
                        if (e.tipo == 'MET23') { temp.met23 = e.texto; };
                        if (e.tipo == 'MET24') { temp.met24 = e.texto; };
                        if (e.tipo == 'MET25') { temp.met25 = e.texto; };
                        if (e.tipo == 'MET26') { temp.met26 = e.texto; };
                        if (e.tipo == 'MET27') { temp.met27 = e.texto; };
                        if (e.tipo == 'MET28') { temp.met28 = e.texto; };
                        if (e.tipo == 'MET29') { temp.met29 = e.texto; };
                        if (e.tipo == 'MET30') { temp.met30 = e.texto; };
                        if (e.tipo == 'MET31') { temp.met31 = e.texto; };
                        if (e.tipo == 'MET32') { temp.met32 = e.texto; };
                        if (e.tipo == 'MET33') { temp.met33 = e.texto; };
                        if (e.tipo == 'MET34') { temp.met34 = e.texto; };
                        if (e.tipo == 'MET35') { temp.met35 = e.texto; };
                        if (e.tipo == 'MET36') { temp.met36 = e.texto; };
                        if (e.tipo == 'MET37') { temp.met37 = e.texto; };
                        if (e.tipo == 'MET38') { temp.met38 = e.texto; };
                        if (e.tipo == 'MET39') { temp.met39 = e.texto; };
                        if (e.tipo == 'MET40') { temp.met40 = e.texto; };
                        if (e.tipo == 'MET41') { temp.met41 = e.texto; };
                        if (e.tipo == 'MET42') { temp.met42 = e.texto; };
                        if (e.tipo == 'MET43') { temp.met43 = e.texto; };
                        if (e.tipo == 'MET44') { temp.met44 = e.texto; };
                        if (e.tipo == 'MET45') { temp.met45 = e.texto; };
                        if (e.tipo == 'MET46') { temp.met46 = e.texto; };
                        if (e.tipo == 'MET47') { temp.met47 = e.texto; };
                        if (e.tipo == 'MET48') { temp.met48 = e.texto; };
                        if (e.tipo == 'MET49') { temp.met49 = e.texto; };
                        if (e.tipo == 'MET50') { temp.met50 = e.texto; };
                        if (e.tipo == 'MET51') { temp.met51 = e.texto; };
                        if (e.tipo == 'MET52') { temp.met52 = e.texto; };
                        if (e.tipo == 'MET53') { temp.met53 = e.texto; };
                        if (e.tipo == 'MET54') { temp.met54 = e.texto; };
                        if (e.tipo == 'MET55') { temp.met55 = e.texto; };
                        if (e.tipo == 'MET56') { temp.met56 = e.texto; };
                        if (e.tipo == 'MET57') { temp.met57 = e.texto; };
                        if (e.tipo == 'MET58') { temp.met58 = e.texto; };
                        if (e.tipo == 'MET59') { temp.met59 = e.texto; };
                        if (e.tipo == 'MET60') { temp.met60 = e.texto; };

                    };


                    /*BuscaBBPlanoService.query({ id: temp.id }, function(livros) {
                        for (var i = 0; i < livros.length; i++) {
                            var l = livros[i];
                            if (l.tipo == 'BASICA') { vm.bBasica.push(l); };
                            if (l.tipo == 'COMPLEMENTAR') { vm.bCompl.push(l); };
                        }
                    }, function(status) { console.log(status) });
                    */

                    BuscaDiscsPlanoIdService.query({ id: temp.id }, function(turmas) {
                        vm.disciplinasPlano = turmas;
                        //console.log(vm.disciplinasPlano);
                    }, function(status) { console.log(status) });


                    BuscaObsPlanoService.query({ id: temp.id }, function(obs) {
                        for (var h = 0; h < obs.length; h++) {
                            vm.listaObs.push(obs[h]);
                        }
                    }, function(status) { console.log(status) });

                    vm.plano = temp
                    vm.loading = false;
                },
                function(status) { console.log(status); });
        }

        function insereObs(item) {
            vm.obs.tipo = item;
            vm.obs.texto = "Prezado(a) " + vm.plano.professor + ", para o campo " + item + " é necessário:";
            vm.obs.idPlano = vm.plano.id;
            vm.obs.userCriacao = vm.account.login;
            vm.obs.etapa = "PENDENTE";
        }

        function saveObs() {

            if (vm.account == null) {
                alert('Não foi possível recuperar a sessão do usuário, por favor atualize a página para prosseguir.');
            } else {
                PlanoEnsinoObs.save(vm.obs,
                    function(data) {
                        vm.listaObs.push(data);
                        insereLog({
                            idPlano: vm.plano.id,
                            item: "OBS",
                            operacao: "CREATE",
                            valor: data.id + " - " + data.tipo + " - " + data.texto,
                            valorAnt: "",
                            user: vm.account.login
                        });
                    },
                    function(status) { console.log(status); });
            }
        }

        function removeObs(obs) {
            for (var o = 0; o < vm.listaObs.length; o++) {
                var e = vm.listaObs[o];

                if (obs.id == e.id) {
                    vm.listaObs.splice(o, 1);
                }

                PlanoEnsinoObs.delete({ id: obs.id });

                insereLog({
                    idPlano: vm.plano.id,
                    item: "OBS",
                    operacao: "DELETE",
                    valor: obs.id + " - " + obs.tipo + " - " + obs.texto,
                    valorAnt: "",
                    user: vm.account.login
                });
            }
        }


        function salvarPlano(status) {
            var etapaAnt = vm.plano.etapa;
            vm.plano.etapa = status;
            PlanoEnsino.update(vm.plano, function(data) {
                insereLog({
                    idPlano: vm.plano.id,
                    item: "PLANO",
                    operacao: "UPDATE",
                    valor: vm.plano.etapa,
                    valorAnt: etapaAnt,
                    user: vm.account.login
                });
            });
            $state.go('plano-de-ensino');
        }

        function insereLog(log) {
            PlanoEnsinoLog.save(log);
        }
    }
})();