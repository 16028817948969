(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PreMatriculaDadosCadastraisResponsavelController', PreMatriculaDadosCadastraisResponsavelController);

    PreMatriculaDadosCadastraisResponsavelController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'PreMatriculaDadosCadastraisServiceController', 'LoginService', '$http', '$sessionStorage', 'Principal', '$resource', 'CidadesMDJsonService'];

    function PreMatriculaDadosCadastraisResponsavelController($timeout, $scope, $state, $stateParams, PreMatriculaDadosCadastraisServiceController, LoginService, $http, $sessionStorage, Principal, $resource, CidadesMDJsonService) {
        var vm = this;

        // functions
        vm.clear = clear;
        vm.salvar = save;
        vm.getCEP = buscaCEP;
        vm.changeResponsavel = changeResponsavel;
        vm.load = false;
        vm.change = change;
        vm.alterInfo = alterInfo;

        // variables
        vm.matricula = {};
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.account = null;
        vm.maior_de_idade = false;

        //vm.isSaving = true;
        vm.isSaving = false;

        vm.resultadoRM = null;

        vm.matricula.falecidoPai = 'nao';
        vm.matricula.falecidoMae = 'nao';

        // chamada de funções..
        getAccount();

        // functions
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

                loadDadosCandidato();
            });
        }

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function showErrorForm(valida) {
            vm.msgError = valida.msg;
            document.getElementById('error-campo').style.display = "block";
            document.getElementById(valida.campo).classList.add('inputForm-error');
            setTimeout(function() {
                document.getElementById('error-campo').style.display = "none";
                document.getElementById(valida.campo).classList.remove('inputForm-error');
            }, 5000);
        }

        function loadDadosCandidato() {
            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", vm.resultadoRM)
                .success(function(data) {
                    vm.matricula = data;

                    switch (vm.matricula.etapa) {
                        case 'INICIO':
                            $state.go('pre-matricula-dados-cadastrais-candidato');
                            break;

                        case 'CANDIDATO':
                            $state.go('pre-matricula-dados-cadastrais-pais');
                            break;

                        case 'RESPONSAVEL':
                            $state.go('pre-matricula-consentimento');
                            break;

                        case 'TERMOCONSENTIMENTO':
                            $state.go('pre-matricula-plano-pagamento');
                            break;
                        case 'PLANOPAGAMENTO':
                            $state.go('pre-matricula-contrato');
                            break;
                        case 'CONTRATO':
                            $state.go('pre-matricula-boleto');
                            break;

                        default:
                            var year = vm.matricula.dataNascimento.substring(0, 4);
                            var month = vm.matricula.dataNascimento.substring(5, 7);
                            var day = vm.matricula.dataNascimento.substring(8, 10);
                            var nasc = new Date(year, month - 1, day);
                            var idadeAtual = idade(nasc, new Date());

                            console.log(idadeAtual);

                            if (idadeAtual >= 18) {
                                vm.maior_de_idade = true;
                                vm.responsavel = "EU";
                                vm.endereco_responsavel = true
                            } else {
                                if (vm.matricula.nomeResponsavel != null) {

                                    if (vm.matricula.nomeResponsavel == vm.matricula.nomePai) {
                                        vm.responsavel = 'PAI';
                                    }

                                    if (vm.matricula.nomeResponsavel == vm.matricula.nomeMae) {
                                        vm.responsavel = 'MAE';
                                    }

                                } else {
                                    vm.responsavel = 'MAE';
                                }
                            }
                            changeResponsavel();
                            break;
                    }
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function changeResponsavel() {
            if (vm.responsavel == "EU") {
                if (vm.matricula.cidadeNatal != null) {
                    var b = vm.matricula.cidadeNatal + ' - ' + vm.matricula.estadoNatal;
                    console.log(b);
                    for (var i = 0; i < vm.cidades.length; i++) {
                        var element = vm.cidades[i];
                        var a = element.cidade + ' - ' + element.uf;

                        if (a == b) {
                            console.log(a);
                            vm.naturalidadeResponsavel = element;
                        }
                    }
                }

                vm.matricula.nomeResponsavel = vm.matricula.nome;
                vm.matricula.nacionalidadeResponsavel = vm.matricula.nacionalidade;

                var dateString = vm.matricula.dataNascimento;
                var year = dateString.substring(0, 4);
                var month = dateString.substring(5, 7);
                var day = dateString.substring(8, 10);
                var date = new Date(year, month - 1, day);
                vm.matricula.dataNascimentoResponsavel = date;

                vm.matricula.cpfResponsavel = vm.matricula.cpf;
                vm.matricula.rgResponsavel = vm.matricula.rg;

                vm.matricula.cepResponsavel = vm.matricula.cep;
                vm.matricula.ufResponsavel = vm.matricula.uf;
                vm.matricula.cidadeResponsavel = vm.matricula.cidade;
                vm.matricula.bairroResponsavel = vm.matricula.bairro;
                vm.matricula.logradouroResponsavel = vm.matricula.logradouro;
                vm.matricula.numeroResponsavel = vm.matricula.numero;
                vm.matricula.complementoResponsavel = vm.matricula.complemento;
            }

            if (vm.responsavel == "PAI") {

                if (vm.matricula.cidadeNatalPai != null) {
                    var b = vm.matricula.cidadeNatalPai + ' - ' + vm.matricula.estadoNatalPai;
                    console.log(b);
                    for (var i = 0; i < vm.cidades.length; i++) {
                        var element = vm.cidades[i];
                        var a = element.cidade + ' - ' + element.uf;

                        if (a == b) {
                            console.log(a);
                            vm.naturalidadeResponsavel = element;
                        }
                    }
                }
                vm.matricula.nomeResponsavel = vm.matricula.nomePai;
                vm.matricula.nacionalidadeResponsavel = vm.matricula.nacionalidadePai;

                var dateString = vm.matricula.dataNascimentoPai;
                var year = dateString.substring(0, 4);
                var month = dateString.substring(5, 7);
                var day = dateString.substring(8, 10);
                var date = new Date(year, month - 1, day);
                vm.matricula.dataNascimentoResponsavel = date;

                vm.matricula.cpfResponsavel = vm.matricula.cpfPai;
                vm.matricula.rgResponsavel = vm.matricula.rgPai;

                vm.matricula.cepResponsavel = vm.matricula.cepPai;
                vm.matricula.ufResponsavel = vm.matricula.ufPai;
                vm.matricula.cidadeResponsavel = vm.matricula.cidadePai;
                vm.matricula.bairroResponsavel = vm.matricula.bairroPai;
                vm.matricula.logradouroResponsavel = vm.matricula.logradouroPai;
                vm.matricula.numeroResponsavel = vm.matricula.numeroPai;
                vm.matricula.complementoResponsavel = vm.matricula.complementoPai;
            }

            if (vm.responsavel == "MAE") {

                if (vm.matricula.cidadeNatalMae != null) {
                    var b = vm.matricula.cidadeNatalMae + ' - ' + vm.matricula.estadoNatalMae;
                    console.log(b);
                    for (var i = 0; i < vm.cidades.length; i++) {
                        var element = vm.cidades[i];
                        var a = element.cidade + ' - ' + element.uf;

                        if (a == b) {
                            console.log(a);
                            vm.naturalidadeResponsavel = element;
                        }
                    }
                }

                vm.matricula.nomeResponsavel = vm.matricula.nomeMae;
                vm.matricula.nacionalidadeResponsavel = vm.matricula.nacionalidadeMae;

                var dateString = vm.matricula.dataNascimentoMae;
                var year = dateString.substring(0, 4);
                var month = dateString.substring(5, 7);
                var day = dateString.substring(8, 10);
                var date = new Date(year, month - 1, day);
                vm.matricula.dataNascimentoResponsavel = date;

                vm.matricula.cpfResponsavel = vm.matricula.cpfMae;
                vm.matricula.rgResponsavel = vm.matricula.rgMae;

                vm.matricula.cepResponsavel = vm.matricula.cepMae;
                vm.matricula.ufResponsavel = vm.matricula.ufMae;
                vm.matricula.cidadeResponsavel = vm.matricula.cidadeMae;
                vm.matricula.bairroResponsavel = vm.matricula.bairroMae;
                vm.matricula.logradouroResponsavel = vm.matricula.logradouroMae;
                vm.matricula.numeroResponsavel = vm.matricula.numeroMae;
                vm.matricula.complementoResponsavel = vm.matricula.complementoMae;
            }

            if (vm.responsavel == "OUTRO") {
                vm.matricula.nomeResponsavel = "";
                vm.matricula.cidadeNatalResponsavel = "";
                vm.matricula.estadoNatalResponsavel = "";
                vm.matricula.nacionalidadeResponsavel = "";
                vm.matricula.dataNascimentoResponsavel = "";
                vm.matricula.cpfResponsavel = "";
                vm.matricula.rgResponsavel = "";
                vm.estado = "";
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function verificaCampos() {

            if (vm.matricula.nomeResponsavel == null || vm.matricula.nomeResponsavel == "") {
                return { campo: "nomeResponsavel", msg: "Por favor, informe o nome completo do responsavel" }
            }

            if (vm.matricula.nomeResponsavel.length <= 2 || !vm.matricula.nomeResponsavel.includes(" ")) {
                return { campo: "nomeResponsavel", msg: "Por favor, informe o nome completo do responsavel" }
            }

            if (vm.matricula.nacionalidadeResponsavel == null || vm.matricula.nacionalidadeResponsavel == "") {
                //return "O campo NACIONALIDADE não pode ser vazio";
                return { campo: "nacionalidadeResponsavel", msg: "Por favor, selecione a nacionalidade do responsável" }
            }

            if (vm.matricula.dataNascimentoResponsavel == null || vm.matricula.dataNascimentoResponsavel == "") {
                //return "O campo DATA NASCIMENTO não pode ser vazio";
                return { campo: "dataNascimentoResponsavel", msg: "Por favor, verifique a data de nascimento do responsavel" }
            }
            if (vm.matricula.cpfResponsavel == null || vm.matricula.cpfResponsavel == "") {
                //return "O campo CPF não pode ser vazio";
                return { campo: "cpfResponsavel", msg: "Por favor, verifique o cpf do responsavel" }
            }

            if (vm.matricula.nacionalidadeResponsavel == 'BRASILEIRA') {
                if (vm.matricula.rgResponsavel == null || vm.matricula.rgResponsavel == "") {
                    //return "O campo RG não pode ser vazio";
                    return { campo: "rgResponsavel", msg: "Por favor, verifique o RG do responsavel" }
                }
            } else {
                if (vm.matricula.nroRegGeral == null || vm.matricula.nroRegGeral == "") {
                    //return "O campo NRM não pode ser vazio";
                    return { campo: "nroRegGeral", msg: "Por favor, verifique o Registro Nacional Migratório do responsavel" }
                }
            }

            if (vm.naturalidadeResponsavel == null) {
                //return "O campo CIDADO ONDE NASCEU não pode ser vazio";
                return { campo: "naturalidadeResponsavel", msg: "Por favor, informe a cidade que seu responsável nasceu" }
            }

            if (vm.matricula.cepResponsavel == null || vm.matricula.cepResponsavel == "") {
                //return "O campo CEP não pode ser vazio";
                return { campo: "cepResponsavel", msg: "Por favor, informe o CEP do endereço" }
            }

            if (vm.matricula.ufResponsavel == null || vm.matricula.ufResponsavel == "") {
                //return "O campo ESTADO não pode ser vazio";
                return { campo: "ufResponsavel", msg: "Por favor, informe o Estado do endereço" }
            }

            if (vm.matricula.cidadeResponsavel == null || vm.matricula.cidadeResponsavel == "") {
                //return "O campo CIDADE não pode ser vazio";
                return { campo: "cidadeResponsavel", msg: "Por favor, informe a cidade do endereço" }
            }

            if (vm.matricula.bairroResponsavel == null || vm.matricula.bairroResponsavel == "") {
                //return "O campo BAIRRO não pode ser vazio";
                return { campo: "bairroResponsavel", msg: "Por favor, informe a bairro do endereço" }
            }

            if (vm.matricula.logradouroResponsavel == null || vm.matricula.logradouroResponsavel == "") {
                //return "O campo Rua ou Avenida não pode ser vazio";
                return { campo: "logradouroResponsavel", msg: "Por favor, informe a rua ou avenida do endereço" }
            }

            if (vm.matricula.numeroResponsavel == null || vm.matricula.numeroResponsavel == "") {
                //return "O campo NUMERO não pode ser vazio";
                return { campo: "numeroResponsavel", msg: "Por favor, informe o numero do endereço" }
            }

            return "OK";
        }

        function alterInfo(opcao, i) {
            if (opcao == 1) {
                vm.estado = i;
                vm.matricula.estadoNatalResponsavel = i.sigla;
                vm.cidades = i.cidades;
            }

            if (opcao == 2) {
                vm.matricula.cidadeNatalResponsavel = i;
            }

        }

        function save() {

            var valida = verificaCampos();
            console.log(valida);

            if (valida == "OK") {

                if (idade(new Date(vm.matricula.dataNascimentoResponsavel), new Date()) >= 18) {

                    var a = confirm(vm.matricula.nomeResponsavel + " foi selecionado(a) como responsável financeiro, deseja continuar?");

                    //console.log(a);
                    if (a) {
                        vm.isSaving = true;
                        vm.load = true;
                        vm.matricula.etapa = 'RESPONSAVEL';
                        vm.matricula.estadoNatalResponsavel = vm.naturalidadeResponsavel.uf;
                        vm.matricula.cidadeNatalResponsavel = vm.naturalidadeResponsavel.cidade;
                        PreMatriculaDadosCadastraisServiceController.save(vm.matricula, onSaveSuccess, onSaveError);
                    } else {
                        showMessage("Selecione o responsável que deseja no inicio da página");
                    }


                } else {
                    vm.load = false;
                    vm.isSaving = false;
                    showMessage('alert alert-danger', "O responsável financeiro precisa ter mais de 18 anos");
                }
            } else {
                vm.isSaving = false;
                vm.load = false;
                showErrorForm(valida);
            }
        }

        function onSaveSuccess(result) {
            vm.load = false;
            vm.isSaving = true;
            $state.go('pre-matricula-consentimento');
        }

        function onSaveError(status) {
            vm.load = false;
            vm.isSaving = false;
            console.log(status);
            showErrorForm({ campo: "erro", msg: "Ocorreu algum problema ao processar os dados, por favor entre em contato com seu consultor." });
        }

        function idade(nascimento, hoje) {
            var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
            if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) < new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate()))
                diferencaAnos--;
            return diferencaAnos;
        }


        function buscaCEP() {
            vm.cepsearch = true;
            if (vm.matricula.cepResponsavel !== null && vm.matricula.cepResponsavel !== undefined) {
                $http.post("/api/getCepInfos", { cep: vm.matricula.cepResponsavel })
                    .success(function(data) {
                        vm.matricula.ufResponsavel = data.uf;
                        vm.matricula.cidadeResponsavel = data.localidade;
                        vm.matricula.bairroResponsavel = data.bairro;
                        vm.matricula.logradouroResponsavel = data.logradouro;
                        vm.cepsearch = false;
                    })
                    .error(function(status) {
                        console.log(status);
                        vm.cepsearch = false;
                    });
            }
        }

        function change() {

            for (var i = 0; i < vm.estados.length; i++) {
                var estado = vm.estados[i];

                if (estado.sigla == vm.estado) {
                    vm.cidades = estado.cidades;
                    break;
                }
            }
        }

        vm.cidades = CidadesMDJsonService;

        vm.querySearchEstado = querySearchEstado;
        vm.selectedItemChangeEstado = selectedItemChangeEstado;
        vm.searchTextChangeEstado = searchTextChangeEstado;

        function querySearchEstado(query) {

            var results = query ? vm.cidades.filter(createFilterForEstado(query)) : vm.cidades,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangeEstado(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangeEstado(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForEstado(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {

                return state.cidade.toUpperCase().includes(lowercaseQuery);
            };

        }
    }
})();