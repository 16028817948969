(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ConsultaFinanceiroAbertoRematricula', ConsultaFinanceiroAbertoRematricula);

    ConsultaFinanceiroAbertoRematricula.$inject = ['$resource', 'DateUtils'];

    function ConsultaFinanceiroAbertoRematricula($resource, DateUtils) {
        var resourceUrl = '/api/busca-financeiro-rematricula-academico';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true }
        });
    }
})();