(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PagamentosMatriculaController', PagamentosMatriculaController);

    PagamentosMatriculaController.$inject = ['$http', '$scope', '$state', 'DateUtils', 'Principal', 'Curso', 'CidadesMDJsonService', 'PreMatriculaDadosCadastraisServiceController', 'ConsultaFinanceiroAberto'];

    function PagamentosMatriculaController($http, $scope, $state, DateUtils, Principal, Curso, CidadesMDJsonService, PreMatriculaDadosCadastraisServiceController, ConsultaFinanceiroAberto) {

        var vm = this;
        vm.buscaCEP = buscaCEP;
        vm.buscaCEPAluno = buscaCEPAluno;
        vm.matricula = {};
        vm.etapa = 1;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                console.log(vm.account);
                vm.isAuthenticated = Principal.isAuthenticated;
                $http.get('api/all-valores-cursos')
                    .success(function(data) {
                        vm.cursos = data;
                    }).error(function(status) {
                        console.log(status);
                    });

            });
        }


        // busca os boletos que foram gerados como adiantamento na tabela do financeiro interno.
        vm.consultarAdiantamento = function() {
            $http.post('api/busca-financeiro-matricula-cpf', vm.consultaCpf)
                .success(function(data) {
                    vm.listaAdiantamento = data;
                    console.log(data);
                }).error(function(status) {
                    console.log(status);
                });
        }

        // busca os boletos gerados pela matricula online.
        vm.consultar = function() {
            $http.get('api/lancamento/getVencidos/CPF/' + vm.consultaCpf)
                .success(function(data) {
                    console.log(data);
                    vm.listaFinCpf = data;
                }).error(function(status) {
                    console.log(status);
                });
        }

        // executa a função para regerar o boleto.
        vm.renovarBoleto = function(aluno) {

            var a = confirm("o vencimento atual do boleto é: " + aluno.vencimento.substring(0, 10) + ", deseja realmente atualizar para 5 dias?");

            if (a) {
                $http.get('api/atualiza-boletos-vencidos-rm/' + aluno.idLan)
                    .success(function(data) {
                        console.log(data);
                        vm.idBoleto = data;

                        vm.baixarBoleto(aluno);
                        vm.listaFinCpf = null;

                    }).error(function(status) {
                        console.log(status);
                    });
            }
        }

        // busca o candidato nos processos seletivos para gerar matricula.
        vm.consultaAluno = function() {
            $http.post('api/busca-aluno-novo-financeiro', {
                nome: vm.consulta.nome,
                cpf: vm.consulta.cpf
            }).success(function(data) {
                vm.alunosEncontrados = data;
                console.log(data);
            }).error(function(status) {
                console.log(status);
            });

        }

        // insere o aluno na variavel de matricula.
        vm.selectAluno = function() {

            vm.alunoSelecionado.momePai = "";
            vm.alunoSelecionado.momeMae = "";

            vm.matricula = vm.alunoSelecionado;

            var dia = vm.matricula.dataNascimento.substring(8, 10);
            var mes = vm.matricula.dataNascimento.substring(5, 7);
            var ano = vm.matricula.dataNascimento.substring(0, 4);

            vm.matricula.dataNascimento = new Date(ano, mes - 1, dia);
            vm.matricula.momePai = "";
            vm.matricula.momeMae = "";
        }

        // cria o lançamento e altera a matricula existente.
        vm.gerarBoleto = function() {
            var valida = validaInfos();
            if (valida == "OK") {
                vm.matricula.curso = vm.curso.codCurso;
                vm.matricula.nomeCurso = vm.curso.nomeCurso

                vm.matricula.estadoNatalResponsavel = vm.naturalidadeResponsavel.uf;
                vm.matricula.cidadeNatalResponsavel = vm.naturalidadeResponsavel.cidade;

                //vm.matricula.estadoNatal = vm.naturalidade.uf;
                //vm.matricula.cidadeNatal = vm.naturalidade.cidade;

                // e vamos de gambis
                vm.matricula.profissaoResponsavel = vm.account.login;

                if (vm.curso.nomeCurso.includes('EAD') || vm.curso.nomeCurso.includes('híbrido')) {
                    vm.matricula.codFilial = '2';
                } else {
                    vm.matricula.codFilial = '1';
                }

                vm.matricula.momePai = "";
                vm.matricula.momeMae = "";

                console.log(vm.matricula);

                $http.post('api/lanc-matric-adiantamento-interno', vm.matricula)
                    .success(function(data) {
                        console.log(data);
                        vm.matricula = {};
                        vm.consultaCpf = data.cpfResponsavel
                        vm.consultar();
                        vm.etapa = 1;
                    }).error(function(status) {
                        console.log(status);
                    });
            } else {
                alert(valida);
            }
        }

        vm.baixarBoleto = function(aluno) {
            $http.post('api/adiantamento/getboleto', { idLan: aluno.idLan }, { responseType: 'arraybuffer' })
                .success(function(data) {
                    //console.log(data);
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = aluno.nome + " - " + aluno.idLan + ".pdf";
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }).error(function(status) {
                    console.log(status);
                });
        }

        vm.pegarPix = function(idBoleto, codFilial) {
            // vm.abreModal();
            $http.post("/api/adiantamento/getpix", {
                    idBoleto: idBoleto,
                    codFilial: codFilial
                }).success(function(result) {
                    console.log(result);
                    vm.imgPix = result.data;
                    vm.abreModal();
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        vm.abreModal = function() {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.setAttribute('data-target', '#modalPix');
            a.setAttribute('data-toggle', 'modal');
            a.click();
            vm.modal = true;
        }

        function validaInfos() {
            // VALIDA SE O NOME ESTA VAZIO -- JA TEM O REQUIRED, MAS VALIDAÇÃO NUNCA É DEMAIS
            if (vm.matricula.nome == null || vm.matricula.nome == "") {
                return "O campo NOME não pode estar vazio.";
            }

            // VALIDA SE O NOME TEM PELO MENOS DOIS CARACTERES E PELO MENOS UM ESPAÇO
            if (vm.matricula.nome.length <= 2 || !vm.matricula.nome.includes(" ")) {
                return "O campo NOME deve receber no mínimo um sobrenome.";
            }

            if (vm.matricula.dataNascimento <= new Date(1954, 0, 1)) {
                return "A data de Nascimento informada ultrapassa os 70 anos de idade;";
            }

            if (vm.matricula.dataNascimento >= vm.dataNascimentoMax) {
                return "A data de Nascimento informada é menor que 16 anos de idade;";
            }

            if (vm.matricula.cpf == null || vm.matricula.cpf == "") {
                return "O campo CPF não pode ser vazio";
            }

            if (vm.matricula.cpf.length < 11) {
                return "O campo CPF deve ter ao menos 11 dígitos";
            }

            if (vm.curso == null || vm.curso == undefined) {
                return "Por favor, selecione um curso.";
            }


            if (vm.matricula.nomeResponsavel == null || vm.matricula.nomeResponsavel == "") {
                return "O campo NOME do Responsável não pode ser vazio";
            }

            if (vm.matricula.nacionalidadeResponsavel == null || vm.matricula.nacionalidadeResponsavel == "") {
                return "O campo NACIONALIDADE do Responsável não pode ser vazio";
            }
            if (vm.naturalidadeResponsavel == null) {
                return "O campo CIDADE NATAL do responsável não pode ser vazio";
            }
            if (vm.matricula.dataNascimentoResponsavel == null || vm.matricula.dataNascimentoResponsavel == "") {
                return "O campo DATA NASCIMENTO do Responsável não pode ser vazio";
            }
            if (vm.matricula.cpfResponsavel == null || vm.matricula.cpfResponsavel == "") {
                return "O campo CPF do Responsável não pode ser vazio";
            }

            if (vm.matricula.nacionalidadeResponsavel == 'BRASILEIRA') {
                if (vm.matricula.rgResponsavel == null || vm.matricula.rgResponsavel == "") {
                    return "O campo RG não pode ser vazio";
                }
            } else {
                if (vm.matricula.nroRegGeral == null || vm.matricula.nroRegGeral == "") {
                    return "O campo NRM não pode ser vazio";
                }
            }

            if (vm.matricula.cepResponsavel == null || vm.matricula.cepResponsavel == "") {
                return "O campo CEP não pode ser vazio";
            }

            if (vm.matricula.ufResponsavel == null || vm.matricula.ufResponsavel == "") {
                return "O campo ESTADO não pode ser vazio";
            }

            if (vm.matricula.cidadeResponsavel == null || vm.matricula.cidadeResponsavel == "") {
                return "O campo CIDADE não pode ser vazio";
            }

            if (vm.matricula.bairroResponsavel == null || vm.matricula.bairroResponsavel == "") {
                return "O campo BAIRRO não pode ser vazio";
            }

            if (vm.matricula.logradouroResponsavel == null || vm.matricula.logradouroResponsavel == "") {
                return "O campo Rua ou Avenida não pode ser vazio";
            }

            if (vm.matricula.numeroResponsavel == null || vm.matricula.numeroResponsavel == "") {
                return "O campo NUMERO não pode ser vazio";
            }

            return "OK";

        }

        vm.cidades = CidadesMDJsonService;
        vm.querySearchEstado = querySearchEstado;
        vm.selectedItemChangeEstado = selectedItemChangeEstado;
        vm.searchTextChangeEstado = searchTextChangeEstado;

        function querySearchEstado(query) {

            var results = query ? vm.cidades.filter(createFilterForEstado(query)) : vm.cidades,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangeEstado(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangeEstado(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForEstado(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {

                return state.cidade.toUpperCase().includes(lowercaseQuery);
            };

        }

        function buscaCEP() {
            vm.cepsearch = true;
            if (vm.matricula.cepResponsavel !== null && vm.matricula.cepResponsavel !== undefined) {
                $http.post("/api/getCepInfos", { cep: vm.matricula.cepResponsavel })
                    .success(function(data) {
                        vm.matricula.ufResponsavel = data.uf;
                        vm.matricula.cidadeResponsavel = data.localidade;
                        vm.matricula.bairroResponsavel = data.bairro;
                        vm.matricula.logradouroResponsavel = data.logradouro;
                        vm.cepsearch = false;
                    })
                    .error(function(status) {
                        console.log(status);
                        vm.cepsearch = false;
                    });
            }
        }

        function buscaCEPAluno() {
            vm.cepsearch = true;
            if (vm.matricula.cep !== null && vm.matricula.cep !== undefined) {
                $http.post("/api/getCepInfos", { cep: vm.matricula.cep })
                    .success(function(data) {
                        vm.matricula.uf = data.uf;
                        vm.matricula.cidade = data.localidade;
                        vm.matricula.bairro = data.bairro;
                        vm.matricula.logradouro = data.logradouro;
                        vm.cepsearch = false;
                    })
                    .error(function(status) {
                        console.log(status);
                        vm.cepsearch = false;
                    });
            }
        }

    }
})();