(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('GetProvaPorSalaService', GetProvaPorSalaService);

    GetProvaPorSalaService.$inject = ['$resource', 'DateUtils'];

    function GetProvaPorSalaService($resource, DateUtils) {
        var resourceUrl = 'api/provasPorSala/:idSala';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();