(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('valores-curso', {
            parent: 'entity',
            url: '/valores-curso?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ValoresCursos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/valores-curso/valores-cursos.html',
                    controller: 'ValoresCursoController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }]
            }
        })
        .state('valores-curso-detail', {
            parent: 'valores-curso',
            url: '/valores-curso/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ValoresCurso'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/valores-curso/valores-curso-detail.html',
                    controller: 'ValoresCursoDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'ValoresCurso', function($stateParams, ValoresCurso) {
                    return ValoresCurso.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'valores-curso',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('valores-curso-detail.edit', {
            parent: 'valores-curso-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/valores-curso/valores-curso-dialog.html',
                    controller: 'ValoresCursoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ValoresCurso', function(ValoresCurso) {
                            return ValoresCurso.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('valores-curso.new', {
            parent: 'valores-curso',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/valores-curso/valores-curso-dialog.html',
                    controller: 'ValoresCursoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                codCurso: null,
                                valorCurso: null,
                                valorLiquido: null,
                                valorAnt: null,
                                antAtv: null,
                                tipoLanc: null,
                                codCxa: null,
                                codCCusto: null,
                                tipoContabilLan: null,
                                codTb1Flx: null,
                                contaPartida1: null,
                                tipoPartida1: null,
                                contaPartida2: null,
                                tipoPartida2: null,
                                dataValidade: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('valores-curso', null, { reload: 'valores-curso' });
                }, function() {
                    $state.go('valores-curso');
                });
            }]
        })
        .state('valores-curso.edit', {
            parent: 'valores-curso',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/valores-curso/valores-curso-dialog.html',
                    controller: 'ValoresCursoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ValoresCurso', function(ValoresCurso) {
                            return ValoresCurso.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('valores-curso', null, { reload: 'valores-curso' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('valores-curso.delete', {
            parent: 'valores-curso',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/valores-curso/valores-curso-delete-dialog.html',
                    controller: 'ValoresCursoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ValoresCurso', function(ValoresCurso) {
                            return ValoresCurso.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('valores-curso', null, { reload: 'valores-curso' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
