(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('CursoDetailController', CursoDetailController);

    CursoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Curso', '$http', 'CursoModalidade', 'ProcessoSeletivo'];

    function CursoDetailController($scope, $rootScope, $stateParams, previousState, entity, Curso, $http, CursoModalidade, ProcessoSeletivo) {
        var vm = this;

        vm.curso = entity;

        vm.altera = alteraSituacaoModalidade;
        vm.deleteModalidade = deleteModalidade;

        ProcessoSeletivo.query({ size: 200 }, function(result) {
            vm.processos = result;
            // console.log(vm.processos);
            getModalidades();
        });



        function getModalidades() {
            $http.post("/api/get-modalidades-curso", vm.curso)
                .success(function(data) {
                    // console.log(data);

                    for (var i = 0; i < data.length; i++) {
                        var d = data[i];
                        for (var b = 0; b < vm.processos.length; b++) {
                            var p = vm.processos[b];
                            if (d.idps == p.idps) {
                                data[i].processo = p;
                            }
                        }
                    }
                    vm.modalidadesCurso = data;
                    // console.log(vm.modalidadesCurso);
                })
                .error(function(status) {
                    alert(status);
                });
        }

        function alteraSituacaoModalidade(modalidade) {
            var r = confirm("Deseja realmente alterar a situação da modalidade " + modalidade.id + " para: " + modalidade.situacao);
            if (r) {
                CursoModalidade.update(modalidade, function() {
                    getModalidades();
                }, function(status) {
                    alert("Houve um erro ao atualizar a situação da modalidade");
                })
            } else {
                alert("Operação cancelada");
            }
        }

        function deleteModalidade(id) {
            CursoModalidade.delete({ id: id }, function() {
                getModalidades();
            }, function(status) {
                alert("Erro ao deletar a modalidade");
            })
        }

    }
})();