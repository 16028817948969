(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$http'];

    function HomeController($scope, Principal, LoginService, $state, $http) {
        var vm = this;

        $state.go('novainscagendado');


        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.inverno = inverno;
        vm.tradicional = tradicional;
        vm.agendado = agendado;
        vm.rematricula = rematricula;
        vm.resultado = resultado;
        vm.online = online;
        vm.painelAdministrativo = painelAdministrativo;

        // validação de liberação de botão
        vm.dataAtual = new Date();
        //console.log(vm.dataAtual);

        vm.dataLiberaPosPorva = new Date(2023, 9, 4, 16, 50, 0);
        console.log(vm.dataLiberaPosPorva);

        if (vm.dataAtual.getTime() >= vm.dataLiberaPosPorva.getTime()) {
            vm.liberaPosPorva = true;
        } else {
            vm.liberaPosPorva = false;
        }

        console.log(vm.liberaPosPorva);

        //sessionStorage
        vm.ip = JSON.parse(sessionStorage.getItem('ip-user-vestibular'));
        console.log("IP ATUAL:" + vm.ip);
        setTimeout(getIpUser(), 1000);

        function getIpUser() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                }, function(status) {
                    return status;
                });
            }
        };

        //vm.agendado();

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        function login() {
            LoginService.open();
            // $state.go('painelDoAluno');
        }

        function painelAdministrativo(tipo) {
            sessionStorage.setItem('tipo-painel', JSON.stringify(tipo));
            $state.go('painelAdministrativo');
        }

        function agendado() {
            $state.go('inscricaoAgendado');
        }

        function resultado() {
            $state.go('resultado-vestibular');
        }

        function rematricula() {
            $state.go('rematriculaAuth');
        }

        function inverno() {
            $state.go('inscricaoInverno');
        }

        function tradicional() {
            $state.go('new-inscricao-tradicional');
        }

        function online() {
            $state.go('inscricaoProvaOnline');
        }

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                // console.log(account); ///
            });
        }

        function register() {
            $state.go('register');
        }
    }
})();