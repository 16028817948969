(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaDiplomaDadosPaisController', MatriculaDiplomaDadosPaisController);

    MatriculaDiplomaDadosPaisController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$http', '$sessionStorage', 'CidadesJsonService', 'MatriculaDiplomaService'];

    function MatriculaDiplomaDadosPaisController($timeout, $scope, $state, $stateParams, $http, $sessionStorage, CidadesJsonService, MatriculaDiplomaService) {
        var vm = this;

        // functions
        vm.salvar = save;
        vm.altera_endereco_Pai = altera_endereco_pai;
        vm.altera_endereco_Mae = altera_endereco_mae;
        vm.load = false;
        vm.alterInfo = alterInfo;
        vm.fecharAlert = fecharAlert;

        vm.etapa = 'mae';


        init();

        function init() {

            vm.matricula = JSON.parse(sessionStorage.getItem('user-matric-diploma'));
            loadCidades();

            vm.idade = calculateAge(vm.matricula.academico.dataNascimento);

            if (vm.matricula.academico.dataNascimentoMae != null) {
                vm.matricula.academico.dataNascimentoMae = devolveData(vm.matricula.academico.dataNascimentoMae);
            }
            if (vm.matricula.academico.dataNascimentoPai != null) {
                vm.matricula.academico.dataNascimentoPai = devolveData(vm.matricula.academico.dataNascimentoPai);
            }

            if (vm.matricula.academico.falecidoMae == "true") {
                vm.matricula.academico.falecidoMae = 'sim';
            } else {
                vm.matricula.academico.falecidoMae = 'nao';
            }

            if (vm.matricula.academico.falecidoPai == "true") {
                vm.matricula.academico.falecidoPai = 'sim';
            } else {
                vm.matricula.academico.falecidoPai = 'nao';
            }


            if (vm.matricula.academico.dataNascimentoPai != null) {
                var dateStringPai = vm.matricula.academico.dataNascimentoPai.toString();
                var yearPai = dateStringPai.substring(0, 4);
                var monthPai = dateStringPai.substring(5, 7);
                var dayPai = dateStringPai.substring(8, 10);
                var datePai = new Date(yearPai, monthPai - 1, dayPai);
                vm.matricula.academico.dataNascimentoPai = datePai;
            }

            if (vm.matricula.academico.dataNascimentoMae != null) {
                var dateStringMae = vm.matricula.academico.dataNascimentoMae.toString();
                var yearMae = dateStringMae.substring(0, 4);
                var monthMae = dateStringMae.substring(5, 7);
                var dayMae = dateStringMae.substring(8, 10);
                var dateMae = new Date(yearMae, monthMae - 1, dayMae);
                vm.matricula.academico.dataNascimentoMae = dateMae;
            }
        }

        function calculateAge(dobString) {
            var dob = new Date(dobString);
            var currentDate = new Date();
            var currentYear = currentDate.getFullYear();
            var birthdayThisYear = new Date(currentYear, dob.getMonth(), dob.getDate());
            var age = currentYear - dob.getFullYear();
            if (birthdayThisYear > currentDate) {
                age--;
            }
            return age;
        }

        function alterInfo(opcao, i) {
            if (opcao == 1) {
                vm.estadoPai = i;
                vm.matricula.academico.estadoNatalPai = i.sigla;
                vm.cidades = i.cidades;

                loadCidades();
            }

            if (opcao == 2) {
                vm.matricula.academico.cidadeNatalPai = i;
            }

            if (opcao == 3) {
                vm.estadoMae = i;
                vm.matricula.academico.estadoNatalMae = i.sigla;
                vm.cidadesMae = i.cidades;

                loadCidades();
            }

            if (opcao == 4) {
                vm.matricula.academico.cidadeNatalMae = i;
            }
        }

        function devolveData(dataA) {
            var year = dataA.substring(0, 4);
            var month = dataA.substring(5, 7);
            var day = dataA.substring(8, 10);
            return new Date(year, month - 1, day);
        }

        function altera_endereco_pai() {
            if (vm.endereco_pai) {
                vm.matricula.academico.cepPai = vm.matricula.academico.cep;
                vm.matricula.academico.ufPai = vm.matricula.academico.uf;
                vm.matricula.academico.cidadePai = vm.matricula.academico.cidade;
                vm.matricula.academico.bairroPai = vm.matricula.academico.bairro;
                vm.matricula.academico.logradouroPai = vm.matricula.academico.logradouro;
                vm.matricula.academico.numeroPai = vm.matricula.academico.numero;
                vm.matricula.academico.complementoPai = vm.matricula.academico.complemento;
            } else {
                vm.matricula.academico.cepPai = "";
                vm.matricula.academico.ufPai = "";
                vm.matricula.academico.cidadePai = "";
                vm.matricula.academico.bairroPai = "";
                vm.matricula.academico.logradouroPai = "";
                vm.matricula.academico.numeroPai = "";
                vm.matricula.academico.complementoPai = "";
            }
        }

        function altera_endereco_mae() {
            if (vm.endereco_mae) {
                vm.matricula.academico.cepMae = vm.matricula.academico.cep;
                vm.matricula.academico.ufMae = vm.matricula.academico.uf;
                vm.matricula.academico.cidadeMae = vm.matricula.academico.cidade;
                vm.matricula.academico.bairroMae = vm.matricula.academico.bairro;
                vm.matricula.academico.logradouroMae = vm.matricula.academico.logradouro;
                vm.matricula.academico.numeroMae = vm.matricula.academico.numero;
                vm.matricula.academico.complementoMae = vm.matricula.academico.complemento;
            } else {
                vm.matricula.academico.cepMae = "";
                vm.matricula.academico.ufMae = "";
                vm.matricula.academico.cidadeMae = "";
                vm.matricula.academico.bairroMae = "";
                vm.matricula.academico.logradouroMae = "";
                vm.matricula.academico.numeroMae = "";
                vm.matricula.academico.complementoMae = "";
            }
        }


        function verificaCampos() {


            if (vm.matricula.academico.nomePai != null && vm.matricula.academico.nomePai != "") {

                if (vm.idade >= 18) {
                    vm.matricula.academico.estadoNatalPai = 'PR';
                    vm.matricula.academico.cidadeNatalPai = 'Cascavel';
                    vm.matricula.academico.nacionalidadePai = 'Brasileiro';
                    vm.matricula.academico.dataNascimentoPai = new Date(2000, 0, 1);
                } else {
                    vm.matricula.academico.estadoNatalPai = vm.estadoPai.sigla;
                }

                if (vm.matricula.academico.nomePai == null || vm.matricula.academico.nomePai == "") {
                    return "Dados do Pai - o campo NOME não pode ser vazio";
                }

                if (vm.matricula.academico.nacionalidadePai == null || vm.matricula.academico.nacionalidadePai == "") {
                    return "Dados do Pai - o campo NACIONALIDADE não pode ser vazio";
                }
                if (vm.matricula.academico.dataNascimentoPai == null || vm.matricula.academico.dataNascimentoPai == "") {
                    return "Dados do Pai - o campo DATA NASCIMENTO não pode ser vazio";
                }

            }
            if (vm.matricula.academico.nomeMae != null && vm.matricula.academico.nomeMae != "") {

                if (vm.idade >= 18) {
                    vm.matricula.academico.estadoNatalMae = 'PR';
                    vm.matricula.academico.cidadeNatalMae = 'Cascavel';
                    vm.matricula.academico.nacionalidadeMae = 'Brasileira';
                    vm.matricula.academico.dataNascimentoMae = new Date(2000, 0, 1);
                } else {
                    vm.matricula.academico.estadoNatalMae = vm.estadoMae.sigla;
                }

                if (vm.matricula.academico.nomeMae == null || vm.matricula.academico.nomeMae == "") {
                    return "Dados da Mãe - o campo NOME não pode ser vazio";
                }
                if (vm.matricula.academico.nacionalidadeMae == null || vm.matricula.academico.nacionalidadeMae == "") {
                    return "Dados da Mãe - o campo NACIONALIDADE não pode ser vazio";
                }
                if (vm.matricula.academico.dataNascimentoMae == null || vm.matricula.academico.dataNascimentoMae == "") {
                    return "Dados da Mãe - o campo DATA NASCIMENTO não pode ser vazio";
                }
            }

            return "OK";

        }


        function loadCidades() {
            vm.estados = CidadesJsonService;

            if (vm.matricula.academico.estadoNatalPai != null) {
                for (var i = 0; i < vm.estados.length; i++) {
                    var estado = vm.estados[i];

                    if (vm.matricula.academico.estadoNatalPai.includes(estado.sigla)) {
                        vm.matricula.academico.estadoNatalPai = estado.sigla;
                        vm.cidades = estado.cidades;
                        break;
                    }

                }
                if (vm.matricula.academico.cidadeNatalPai != null) {
                    for (var i = 0; i < vm.cidades.length; i++) {
                        var cidade = vm.cidades[i];
                        if (vm.matricula.academico.cidadeNatalPai.toLowerCase() == cidade.toLowerCase().trim()) {
                            vm.matricula.academico.cidadeNatalPai = cidade;
                            break;
                        }
                    }
                }
            }

            if (vm.matricula.academico.estadoNatalMae != null) {
                for (var i = 0; i < vm.estados.length; i++) {
                    var estado = vm.estados[i];

                    if (vm.matricula.academico.estadoNatalMae.includes(estado.sigla)) {
                        vm.matricula.academico.estadoNatalMae = estado.sigla;;
                        vm.cidadesMae = estado.cidades;
                        break;
                    }
                }
                if (vm.matricula.academico.cidadeNatalMae != null) {
                    for (var i = 0; i < vm.cidadesMae.length; i++) {
                        var cidade = vm.cidadesMae[i];
                        if (vm.matricula.academico.cidadeNatalMae.toLowerCase() == cidade.toLowerCase().trim()) {
                            vm.matricula.academico.cidadeNatalMae = cidade;
                            break;
                        }
                    }
                }
            }



        }

        function save() {

            if (vm.etapa == 'mae') {
                vm.etapa = 'pai'
            } else {
                vm.load = true;
                var valida = verificaCampos();

                if (valida == "OK") {
                    vm.matricula.academico.etapa = 'PAIS';
                    if (vm.matricula.academico.falecidoPai == 'sim') {
                        vm.matricula.academico.falecidoPai = true;
                    } else {
                        vm.matricula.academico.falecidoPai = false
                    }

                    if (vm.matricula.academico.falecidoMae == 'sim') {
                        vm.matricula.academico.falecidoMae = true;
                    } else {
                        vm.matricula.academico.falecidoMae = false
                    }

                    sessionStorage.setItem('user-matric-diploma', JSON.stringify(vm.matricula));

                    MatriculaDiplomaService.save(
                        vm.matricula,
                        function(data) {
                            vm.load = false;
                            sessionStorage.setItem('user-matric-diploma', JSON.stringify(data));
                            $state.go('matriculaDiplomaResponsavel');
                        },
                        function(status) {
                            console.log(status);
                        }
                    );
                } else {
                    showMessage("alert-danger", valida);
                    vm.load = false;
                }

            }

        }

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }


    }
})();