(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoPulseiraDialogController', InscricaoPulseiraDialogController);

    InscricaoPulseiraDialogController.$inject = ['$timeout', 'Principal', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'InscricaoPulseira'];

    function InscricaoPulseiraDialogController($timeout, Principal, $scope, $stateParams, $uibModalInstance, entity, InscricaoPulseira) {
        var vm = this;
        vm.account = null;
        vm.inscricaoPulseira = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            var agora = new Date();

            if (vm.inscricaoPulseira.id !== null) {
                //vm.inscricaoPulseira.dataAlteracao = agora;
                vm.inscricaoPulseira.usuarioAlteracao = vm.account.login;
                InscricaoPulseira.update(vm.inscricaoPulseira, onSaveSuccess, onSaveError);
            } else {
                vm.inscricaoPulseira.dataCriacao = agora;
                vm.inscricaoPulseira.usuarioCriacao = vm.account.login;

                vm.inscricaoPulseira.dataAlteracao = agora;
                vm.inscricaoPulseira.usuarioAlteracao = vm.account.login;
                InscricaoPulseira.save(vm.inscricaoPulseira, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:inscricaoPulseiraUpdate', result);
            //$uibModalInstance.close(result);
            vm.inscricaoPulseira.codigoPulseira = "";
            vm.inscricaoPulseira.codigoInscricao = "";
            document.getElementById("field_codigoInscricao").focus();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataCriacao = false;
        vm.datePickerOpenStatus.dataAlteracao = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        jQuery(document).on('keydown', 'input.insc', function(ev) {
            if (ev.which === 13) {
                this.style.backgroundColor = '#EFF';
                document.getElementById("field_codigoPulseira").focus();
                return false;
            }
        });

        jQuery(document).on('keydown', 'input.pul', function(ev) {
            if (ev.which === 13) {
                this.style.backgroundColor = '#EFF';
                save();
                return false;
            }
        });
    }
})();