(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaOnlineContratoController', MatriculaOnlineContratoController);

    MatriculaOnlineContratoController.$inject = ['$state', 'MatriculaOnlineServiceController', '$http', 'GetContratoByIdps'];

    function MatriculaOnlineContratoController($state, MatriculaOnlineServiceController, $http, GetContratoByIdps) {
        var vm = this;
        vm.avancar = avancar;
        vm.ip = null;

        vm.plano = JSON.parse(sessionStorage.getItem('plano-pagamento'));

        vm.ip = JSON.parse(sessionStorage.getItem('ip-user-vestibular'));
        console.log("IP ATUAL:" + vm.ip);
        setTimeout(getIpUser(), 1000);

        function getIpUser() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                }, function(status) {
                    return status;
                });
            }
        };

        vm.getIp = function() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                    avancar();
                }, function(status) {
                    return status;
                });
            } else {
                avancar();
            }
        }

        function avancar() {
            vm.load = true;
            save();
        }

        init();

        function init() {
            vm.load = false;
            vm.aceitei = false;
            loadDadosCandidato();
        }

        function loadDadosCandidato() {
            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", vm.resultadoRM)
                .success(function(data) {
                    vm.matricula = data;
                    $http.post("/api/get-contrato-pre-matricula", vm.matricula)
                        .success(function(data) {
                            console.log(data);
                            vm.clausulas = data;
                        })
                        .error(function(status) {
                            console.log(status);
                        });


                    if (vm.matricula.nomeCurso.includes('EAD')) {
                        vm.presencial = false;
                    } else {
                        vm.presencial = true;
                    }
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function save() {
            vm.matricula.etapa = "CONTRATO";
            vm.matricula.dataContrato = null;
            vm.matricula.ipAluno = vm.ip;
            vm.matricula.presencial = vm.presencial;
            MatriculaOnlineServiceController.save(vm.matricula, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.load = false;
            vm.matricula = result;
            sessionStorage.setItem('cod-pessoa', JSON.stringify(vm.resultadoRM));
            $state.go('new-matricula-boleto');
        }

        function onSaveError(result) {
            vm.load = false;
            console.log(result);
        }
    }
})();