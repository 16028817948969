(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaOnlineInicioController', MatriculaOnlineInicioController);

    MatriculaOnlineInicioController.$inject = ['$rootScope', '$timeout', '$scope', '$state', '$stateParams', 'PolosAtivos', '$http', '$sessionStorage', 'Principal', '$resource',
        'ModalImagemService', 'CidadesMDJsonService', 'ConfirmaNomeService', 'MatriculaOnlineServiceController', 'Financeiro', 'CursoModalidade'
    ];

    function MatriculaOnlineInicioController($rootScope, $timeout, $scope, $state, $stateParams, PolosAtivos, $http, $sessionStorage, Principal, $resource,
        ModalImagemService, CidadesMDJsonService, ConfirmaNomeService, MatriculaOnlineServiceController, Financeiro, CursoModalidade) {
        var vm = this;


        vm.clear = clear;
        vm.salvar = save;
        vm.getCEP = buscaCEP;
        vm.calculaIdade = calculaIdade;
        vm.carregaCursos = carregaCursos;

        // variables
        vm.matricula = {};
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.account = null;
        vm.cursos = null;
        vm.pgtos = null;
        vm.upload = upload;
        vm.imagemAlterada = false;
        vm.cepsearch = false;
        vm.trocaResponsavel = false;
        vm.nomeTemp = "";
        vm.cursosAcademico = [];
        vm.cursoSelecionado = null;
        vm.cursoEad = false;

        vm.isSaving = false;

        vm.modal = false;

        vm.polos = PolosAtivos.query({
            page: 0,
            size: 50
        });

        // vm.validaPolo = function(curso) {
        //     if (curso.nome.includes('híbrido')) {
        //         vm.cursoEad = false;
        //         vm.matricula.polo = 1;
        //         vm.matricula.codFilial = 2;
        //         showMessage('alert alert-info', 'cursos híbridos somente serão ofertados em Cascavel');
        //     } else {
        //         if (curso.ead) {
        //             vm.matricula.codFilial = 2;
        //             vm.cursoEad = true;
        //         } else {
        //             vm.matricula.codFilial = 1;
        //             vm.cursoEad = false;
        //         }
        //     }
        //     vm.cursoSelecionado = curso;
        // }

        vm.validaPoloModalidade = function(modalidade) {
            if (modalidade.modalidade.includes('híbrido')) {
                vm.cursoEad = false;
                vm.matricula.polo = 1;
                vm.matricula.codFilial = 2;
                showMessage('alert alert-info', 'cursos híbridos somente serão ofertados em Cascavel');
            } else {
                if (modalidade.modalidade.includes('EAD')) {
                    vm.matricula.codFilial = 2;
                    vm.cursoEad = true;
                } else {
                    vm.matricula.codFilial = 1;
                    vm.cursoEad = false;
                }
            }
            vm.cursoSelecionado = modalidade;
            vm.modalidadeSelecionada = modalidade;
        }

        function upload() {
            ModalImagemService.open();
        }

        $rootScope.$on('cropImage', function(event, value) {
            if (value != null && value != undefined) {
                vm.matricula.foto = value.replace("data:image/png;base64,", "");
                vm.imagemAlterada = true;
            } else {

            }
        });

        $rootScope.$on('selecionou', function(event, value) {
            if (value != null && value != undefined) {
                console.log(value);

                vm.matricula.nome = value;
                vm.nomeTemp = value;
            }
        });

        // chamada de funções..
        getAccount();

        // functions
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.dataNascimentoMax = new Date(new Date().getFullYear() - 16, new Date().getMonth(), new Date().getDate());
                loadCidadesByJSON();
                setTimeout(loadDadosCandidato(), 1000);
            });
        }
        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function buscaCEP() {
            vm.cepsearch = true;
            if (vm.matricula.cep !== null && vm.matricula.cep !== undefined) {
                $http.post("/api/getCepInfos", { cep: vm.matricula.cep })
                    .success(function(data) {

                        console.log(data);

                        vm.matricula.uf = data.uf;
                        vm.matricula.cidade = data.localidade;
                        vm.matricula.bairro = data.bairro;
                        vm.matricula.logradouro = data.logradouro;
                        vm.cepsearch = false;
                    })
                    .error(function(status) {
                        console.log(status);
                        vm.cepsearch = false;
                    });
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.matricula.etapa = "INICIO";
            var valida = verificaCampos();

            if (valida == "OK") {
                // vm.matricula.idAreaInteresse = vm.cursoSelecionado.idAreaInteresse;
                // vm.matricula.codCursoRm = vm.cursoSelecionado.codCursoRm;
                // vm.matricula.curso = vm.cursoSelecionado.id;

                vm.matricula.idAreaInteresse = vm.modalidade.idAreaInteresse;
                vm.matricula.codCursoRm = vm.modalidade.codCursoRm;
                vm.matricula.curso = vm.modalidadeSelecionada.idCurso;
                vm.matricula.nomeCurso = vm.modalidade.nomeRm;

                vm.matricula.modalidade = vm.modalidadeSelecionada.modalidade;
                vm.matricula.idModalidade = vm.modalidadeSelecionada.id;

                vm.matricula.estadoNatal = vm.naturalidade.uf;
                vm.matricula.cidadeNatal = vm.naturalidade.cidade;

                vm.matricula.codContrato = "000000";

                if (vm.trocaResponsavel == false) {
                    vm.matricula.cidadeNatalResponsavel = vm.matricula.cidadeNatal;
                    vm.matricula.estadoNatalResponsavel = vm.matricula.estadoNatal;
                    vm.matricula.nomeResponsavel = vm.matricula.nome;
                    vm.matricula.nacionalidadeResponsavel = vm.matricula.nacionalidade;
                    vm.matricula.dataNascimentoResponsavel = vm.matricula.dataNascimento;
                    vm.matricula.cpfResponsavel = vm.matricula.cpf;
                    vm.matricula.cepResponsavel = vm.matricula.cep;
                    vm.matricula.ufResponsavel = vm.matricula.uf;
                    vm.matricula.cidadeResponsavel = vm.matricula.cidade;
                    vm.matricula.bairroResponsavel = vm.matricula.bairro;
                    vm.matricula.logradouroResponsavel = vm.matricula.logradouro;
                    vm.matricula.numeroResponsavel = vm.matricula.numero;
                    vm.matricula.complementoResponsavel = vm.matricula.complemento;
                    vm.matricula.rgResponsavel = vm.matricula.rg;
                }

                //aqui mandamos o aluno para gerar a primeira etapa da matrícula e a primeira mensalidade.
                if (vm.matricula.idBoleto == null) {
                    $http.post("/api/lanc-matric-adiantamento", vm.matricula)
                        .success(function(aluno) {
                            console.log(aluno);
                            vm.matricula = aluno;
                            vm.isSaving = false;
                            loadFinanceiroAluno(vm.matricula);
                        })
                        .error(function(status) {
                            console.log(status);
                            vm.isSaving = false;
                        });
                } else {
                    vm.isSaving = false;
                    loadFinanceiroAluno(vm.matricula);
                }
            } else {
                showMessage('alert alert-danger', valida);
            }
        }

        vm.avancar = function() {
            vm.isSaving = false;
            vm.matricula.etapa = "CANDIDATO";
            MatriculaOnlineServiceController.save(vm.matricula, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.load = false;
            vm.matricula = result;
            $state.go('new-matricula-complemento');
        }

        function onSaveError(result) {
            vm.load = false;
            console.log(result);
        }

        function verificaCampos() {
            if (vm.naturalidade == null) {
                return "Por favor informe a cidade que você nasceu.";
            }

            // VALIDA SE O NOME ESTA VAZIO -- JA TEM O REQUIRED, MAS VALIDAÇÃO NUNCA É DEMAIS
            if (vm.matricula.nome == null || vm.matricula.nome == "") {
                return "O campo NOME não pode estar vazio.";
            }

            // VALIDA SE O NOME TEM PELO MENOS DOIS CARACTERES E PELO MENOS UM ESPAÇO
            if (vm.matricula.nome.length <= 2 || !vm.matricula.nome.includes(" ")) {
                return "O campo NOME deve receber no mínimo um sobrenome.";
            }

            if (vm.matricula.nome !== vm.nomeTemp) {
                ConfirmaNomeService.open(vm.matricula.nome, vm.nomeTemp);
                return "O campo NOME foi alterado";
            }

            if (vm.matricula.dataNascimento <= new Date(1943, 0, 1)) {
                return "A data de Nascimento informada ultrapassa os 80 anos de idade;";
            }

            if (vm.matricula.dataNascimento >= vm.dataNascimentoMax) {
                return "A data de Nascimento informada é menor que 16 anos de idade;";
            }

            //calculaIdade();

            if (vm.matricula.nacionalidade == null || vm.matricula.nacionalidade == "") {
                return "O campo NACIONALIDADE não pode ser vazio";
            }
            if (vm.matricula.nacionalidade.length <= 2) {
                return "O campo NACIONALIDADE precisa ter no mínimo 2 caracteres";
            }

            if (vm.matricula.cpf == null || vm.matricula.cpf == "") {
                return "O campo CPF não pode ser vazio";
            }
            if (vm.matricula.cpf.length < 11) {
                return "O campo CPF deve ter ao menos 11 dígitos";
            }

            if (vm.matricula.cep == null || vm.matricula.cep == "") {
                return "O campo CEP não pode ser vazio";
            }
            if (vm.matricula.cep.length < 7) {
                return "O campo CEP deve ter ao menos 7 dígitos";
            }

            if (vm.matricula.uf == null || vm.matricula.uf == "") {
                return "O campo Estado não pode ser vazio";
            }
            if (vm.matricula.uf.length < 2) {
                return "O campo Estado deve ter ao menos 2 dígitos";
            }

            if (vm.matricula.cidade == null || vm.matricula.cidade == "") {
                return "O campo CIDADE não pode ser vazio";
            }
            if (vm.matricula.cidade.length < 2) {
                return "O campo CIDADE deve ter ao menos 2 dígitos";
            }

            if (vm.matricula.bairro == null || vm.matricula.bairro == "") {
                return "O campo BAIRRO não pode ser vazio";
            }
            if (vm.matricula.bairro.length < 2) {
                return "O campo BAIRRO deve ter ao menos 2 dígitos";
            }

            if (vm.matricula.logradouro == null || vm.matricula.logradouro == "") {
                return "O campo Rua ou Avenida não pode ser vazio";
            }
            if (vm.matricula.logradouro.length < 2) {
                return "O campo Rua ou Avenida deve ter ao menos 2 dígitos";
            }

            if (vm.matricula.numero == 0) {
                vm.matricula.numero = 1;
            }

            if (vm.matricula.numero == null || vm.matricula.numero == "") {
                return "O campo NUMERO DO ENDEREÇO não pode ser vazio";
            }
            if (vm.matricula.numero.length < 1) {
                return "O campo NUMERO DO ENDEREÇO deve ter ao menos 1 dgito";
            }

            if (vm.matricula.telefone1 == null || vm.matricula.telefone1 == "") {
                vm.matricula.telefone1 = vm.matricula.telefone2;
            }

            if (vm.matricula.telefone2 == null || vm.matricula.telefone2 == "") {
                return "O campo CELULAR não pode ser vazio";
            }
            if (vm.matricula.telefone2.length < 10) {
                return "O campo CELULAR deve ter ao menos 10 dígitos";
            }

            if (vm.modalidadeSelecionada == null | vm.modalidadeSelecionada == "") {
                return "Por favor selecione o curso que deseja.";
            }

            if (vm.matricula.polo == null | vm.matricula.polo == "") {
                return "Por favor selecione o polo que deseja ser atendido.";
            }

            if (vm.trocaResponsavel) {

                if (vm.naturalidadeResponsavel == null) {
                    return "O cidade de nascimento do Responsável deve ser selecionado";
                }

                if (vm.matricula.nomeResponsavel == null || vm.matricula.nomeResponsavel == "") {
                    return "O campo nome do responsáve não pode ser vazio";
                }

                if (vm.matricula.nacionalidadeResponsavel == null || vm.matricula.nacionalidadeResponsavel == "") {
                    return "O campo nacionalidade do responsável não pode ser vazio";
                }
                if (vm.matricula.dataNascimentoResponsavel == null || vm.matricula.dataNascimentoResponsavel == "") {
                    return "O campo data de nascimento do responsável não pode ser vazio";
                }

                var today = new Date();
                vm.idadeResponsável = Math.floor(Math.ceil(Math.abs(vm.matricula.dataNascimentoResponsavel.getTime() - today.getTime()) / (1000 * 3600 * 24)) / 365.25);
                if (vm.idadeResponsável < 18) {
                    return "O responsável financeiro deve ter ao menos 18 anos.";
                }

                if (vm.matricula.cpfResponsavel == null || vm.matricula.cpfResponsavel == "") {
                    return "O campo CPF do responsável não pode ser vazio";
                }

                if (vm.matricula.nacionalidadeResponsavel == 'BRASILEIRA') {
                    if (vm.matricula.rgResponsavel == null || vm.matricula.rgResponsavel == "") {
                        return "O campo RG do responsável não pode ser vazio";
                    }
                }

                if (vm.matricula.cepResponsavel == null || vm.matricula.cepResponsavel == "") {
                    return "O campo CEP do responsável não pode ser vazio";
                }

                if (vm.matricula.ufResponsavel == null || vm.matricula.ufResponsavel == "") {
                    return "O campo Estado Natal do responsável não pode ser vazio";
                }

                if (vm.matricula.cidadeResponsavel == null || vm.matricula.cidadeResponsavel == "") {
                    return "O campo Cidade Natal do responsável não pode ser vazio";
                }

                if (vm.matricula.bairroResponsavel == null || vm.matricula.bairroResponsavel == "") {
                    return "O Bairro do responsável não pode ser vazio";
                }

                if (vm.matricula.logradouroResponsavel == null || vm.matricula.logradouroResponsavel == "") {
                    return "A Rua do responsável não pode ser vazio";
                }

                if (vm.matricula.numeroResponsavel == null || vm.matricula.numeroResponsavel == "") {
                    return "O Número do responsável não pode ser vazio";
                }
            }
            return "OK";
        };

        function calculaIdade() {
            var today = new Date();
            vm.idadeAcademico = Math.floor(Math.ceil(Math.abs(vm.matricula.dataNascimento.getTime() - today.getTime()) / (1000 * 3600 * 24)) / 365.25);
            //console.log(vm.idadeAcademico);
            // validando maior idade
            if (vm.idadeAcademico >= 18) {
                // false nao troca o responsavel
                vm.trocaResponsavel = false;
            } else {
                // true troca
                vm.trocaResponsavel = true;
            }
        }

        function loadDadosCandidato() {

            vm.blockCampos = true;

            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));

            // vamos validar o contexto
            if (vm.resultadoRM.codPessoa == null || vm.resultadoRM.codPessoa == undefined || vm.resultadoRM.codPessoa == "") {
                showMessage("alert alert-danger", "Desculpe não foi possível carregar o contexto");
            };
            if (vm.resultadoRM.codVestibular == null || vm.resultadoRM.codVestibular == undefined || vm.resultadoRM.codVestibular == "") {
                showMessage("alert alert-danger", "Desculpe não foi possível carregar o contexto");
            };

            vm.processoSeletivo = vm.resultadoRM.processo;

            // ResultadoVestibularResource
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", vm.resultadoRM)
                .success(function(data) {

                    vm.matricula = data;
                    vm.nomeTemp = vm.matricula.nome;

                    switch (vm.matricula.etapa) {
                        // case 'INICIO':
                        //     $state.go('new-matricula-inicio');
                        //     break;

                        case 'CANDIDATO':
                            $state.go('new-matricula-complemento');
                            break;

                        case 'COMPLEMENTO':
                            $state.go('new-matricula-filiacao');
                            break;

                        case 'PAIS':
                            $state.go('new-matricula-consentimento');
                            break;

                        case 'TERMOCONSENTIMENTO':
                            $state.go('new-matricula-plano-pagamento');
                            break;

                        case 'PLANOPAGAMENTO':
                            $state.go('new-matricula-contrato');
                            break;
                        case 'CONTRATO':
                            $state.go('new-matricula-boleto');
                            break;

                        default:
                            break;
                    }

                    if (vm.matricula.etapa == 'INICIO') {

                        CursoModalidade.get({ id: vm.matricula.idModalidade },
                            function(modalidade) {
                                console.log(modalidade);
                                vm.cursosAcademico.push(modalidade);
                                vm.validaPoloModalidade(modalidade);
                            },
                            function(status) {
                                console.log(status);
                            });

                        // $http.get("/api/cursos/" + vm.matricula.curso)
                        //     .success(function(data) {
                        //         vm.cursoSelecionado = data;
                        //         $http.get("/api/get-idareainteresse-curso/" + vm.cursoSelecionado.id)
                        //             .success(function(result) {
                        //                 vm.cursoSelecionado.idps = result.idps;
                        //                 vm.cursoSelecionado.idAreaInteresse = result.idCursoRm;
                        //                 vm.cursoSelecionado.nomeCursoRm = result.descricao;
                        //                 vm.cursosAcademico.push(vm.cursoSelecionado);
                        //                 vm.validaPolo(vm.cursoSelecionado);
                        //             });
                        //     });
                    } else {
                        vm.matricula.codPessoa = vm.resultadoRM.codPessoa;
                        vm.matricula.idInscricao = vm.resultadoRM.codVestibular;
                        vm.matricula.polo = vm.resultadoRM.codPolo;
                        vm.matricula.idps = vm.resultadoRM.idps;

                        if (vm.processoSeletivo == 'TRADICIONAL') {
                            vm.cursosAcademico.push(vm.resultadoRM.modalidade1);
                            vm.validaPoloModalidade(vm.resultadoRM.modalidade1);

                            if (vm.resultadoRM.modalidade2 != null) {
                                vm.cursosAcademico.push(vm.resultadoRM.modalidade2);
                            }

                            vm.matricula.dataNascimento = vm.resultadoRM.dataNascimento;
                            vm.matricula.cidadeNatal = vm.resultadoRM.cidadeNatal;
                            vm.matricula.estadoNatal = vm.resultadoRM.estadoNatal;
                        } else {
                            vm.cursosAcademico.push(vm.resultadoRM.modalidade1);
                            vm.validaPoloModalidade(vm.resultadoRM.modalidade1);
                        }
                    }

                    vm.matricula.telefone2 = vm.matricula.telefone1;
                    vm.matricula.telefone1 = "";

                    if (vm.matricula.sexo == null) {
                        vm.matricula.sexo = 'Masculino';
                    }
                    if (vm.matricula.estadoCivil == null) {
                        vm.matricula.estadoCivil = 'Solteiro';
                    }
                    if (vm.matricula.raca == null) {
                        vm.matricula.raca = 'Amarela';
                    }

                    if (vm.matricula.nacionalidade == null) {
                        vm.matricula.nacionalidade = 'BRASILEIRA';
                    }

                    if (vm.matricula.cidadeNatal != null) {
                        var b = vm.matricula.cidadeNatal + ' - ' + vm.matricula.estadoNatal;
                        for (var i = 0; i < vm.cidades.length; i++) {
                            var element = vm.cidades[i];
                            var a = element.cidade + ' - ' + element.uf;
                            if (a == b) {

                                vm.naturalidade = element;
                            }
                        }
                    }

                    if (vm.matricula.dataNascimento != null) {
                        var dateString = vm.matricula.dataNascimento;
                        var year = dateString.substring(0, 4);
                        var month = dateString.substring(5, 7);
                        var day = dateString.substring(8, 10);

                        var date = new Date(year, month - 1, day);

                        vm.matricula.dataNascimento = date;

                        calculaIdade();
                    }



                    if (vm.matricula.dataNascimentoResponsavel != null) {
                        var dateString = vm.matricula.dataNascimentoResponsavel;
                        var year = dateString.substring(0, 4);
                        var month = dateString.substring(5, 7);
                        var day = dateString.substring(8, 10);

                        var date = new Date(year, month - 1, day);

                        vm.matricula.dataNascimentoResponsavel = date;
                    }



                    vm.matricula.tipoMatricula = 'CALOURO';
                    vm.matricula.pgtoOnline = 'SIM';
                    vm.matricula.pgtoPix = 'SIM';

                    if (vm.matricula.adiantamento == null || vm.matricula.adiantamento == undefined || vm.matricula.adiantamento == "") {
                        vm.matricula.adiantamento = "NEW";
                    }

                    vm.blockCampos = false;

                    // vamos validar o contexto
                    if (vm.matricula.codPessoa == null || vm.matricula.codPessoa == undefined || vm.matricula.codPessoa == "") { showMessage("alert alert-danger", "desculpe não foi possível carregar o contexto") };
                    if (vm.matricula.idInscricao == null || vm.matricula.idInscricao == undefined || vm.matricula.idInscricao == "") { showMessage("alert alert-danger", "desculpe não foi possível carregar o contexto") };
                    if (vm.matricula.idps == null || vm.matricula.idps == undefined || vm.matricula.idps == "") { showMessage("alert alert-danger", "desculpe não foi possível carregar o contexto") };

                    if (vm.matricula.idBoleto != null) {
                        loadFinanceiroAluno(vm.matricula);
                    }

                })
                .error(function(status) {
                    console.log(status);
                });
        }

        // metodo bkp para cursos
        function carregaCursos() {
            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            vm.processoSeletivo = vm.resultadoRM.processo;

            vm.cursosAcademico = [];

            if (vm.processoSeletivo == 'TRADICIONAL') {
                vm.cursosAcademico.push(vm.resultadoRM.modalidade1);
                vm.validaPoloModalidade(vm.resultadoRM.modalidade1);
                if (vm.resultadoRM.curso2 != null) {
                    vm.cursosAcademico.push(vm.resultadoRM.modalidade2);
                }
            } else {
                vm.cursosAcademico.push(vm.resultadoRM.modalidade1);
                vm.validaPoloModalidade(vm.resultadoRM.modalidade1);
            }
        }

        /*   referente à cidade de nascimento da pessoa */
        vm.querySearchEstado = querySearchEstado;
        vm.selectedItemChangeEstado = selectedItemChangeEstado;
        vm.searchTextChangeEstado = searchTextChangeEstado;

        function querySearchEstado(query) {

            var results = query ? vm.cidades.filter(createFilterForEstado(query)) : vm.cidades,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangeEstado(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangeEstado(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForEstado(query) {
            var str = accentFold(query.toUpperCase());

            return function filterFn(state) {
                return accentFold(state.cidade).toUpperCase().includes(str);
            };

        }

        function accentFold(inStr) {
            return inStr.replace(/([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
                function(str, a, c, e, i, n, o, s, u, y, ae) {
                    if (a) return 'a';
                    if (c) return 'c';
                    if (e) return 'e';
                    if (i) return 'i';
                    if (n) return 'n';
                    if (o) return 'o';
                    if (s) return 's';
                    if (u) return 'u';
                    if (y) return 'y';
                    if (ae) return 'ae';
                }
            );
        }

        function loadCidadesByJSON() {
            vm.cidades = CidadesMDJsonService;
        }

        // aqui começa a situação dos boletos
        function loadFinanceiroAluno(aluno) {
            $http.post("/api/busca-financeiro-matricula-adiantamento", aluno)
                .success(function(result) {
                    vm.pgtos = result;
                    console.log(vm.pgtos);
                    for (var i = 0; i < result.length; i++) {
                        var e = result[i];

                        if (e.statusCielo == 2 || e.statusCielo == 7 || e.statusCielo == 1 || e.statusCielo == 0 || e.statusCielo == 10) {
                            vm.liberado = false
                        }
                    }
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        vm.baixarBoleto = function(idLan) {
            vm.blockCampos = true;
            $http.post('api/adiantamento/getboleto', { idLan: idLan }, { responseType: 'arraybuffer' })
                .success(function(data) {
                    //console.log(data);
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = vm.matricula.nome + " - " + idLan + ".pdf";
                    vm.blockCampos = false;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }).error(function(status) {
                    console.log(status);
                });
        }

        vm.pegarPix = function(idBoleto, codFilial) {
            vm.blockCampos = true;
            $http.post("/api/adiantamento/getpix", {
                    idBoleto: idBoleto,
                    codFilial: codFilial
                }).success(function(result) {
                    vm.imgPix = result.data;
                    vm.modal = true;
                    vm.blockCampos = false;
                    startCountdown();
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        vm.pagarOnline = function(item) {
            $http.post("/api/createcheckoutadiantamento", vm.matricula)
                .success(function(result) {
                    console.log(result);
                    var a = result.settings.checkoutUrl.split("/");
                    var len = a.length;

                    item.idCheckOutCielo = a[len - 1];

                    Financeiro.update(item, function(result) {
                        //console.log(result);
                    }, function(status) {
                        console.log(status);
                    });

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = result.settings.checkoutUrl;
                    a.click();
                    vm.load = false;
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        /* CONTADOR DO TEMPO DE QRCODE */
        function startCountdown() {
            vm.tempo = 120;

            if (vm.tempo >= 1) {
                var min = parseInt(vm.tempo / 60);
                var comp = ' minutos.';
                if (min < 10) {
                    min = "0" + min;
                    min = min.substr(0, 2);
                }
                if (min == "01") {
                    comp = ' minuto.';
                }
                var horaImprimivel = min + comp;
                console.log(new Date() + "-" + horaImprimivel + "-" + vm.tempo);
                $("#tempoRestante").html(horaImprimivel);

                setTimeout(function() {
                    $("#tempoRestante").html(horaImprimivel);
                    vm.tempo = (vm.tempo - 60);
                    console.log(vm.tempo);
                    startCountdown();
                }, 60000);

            } else {
                clearTimeout();
                clearInterval();
                console.log(new Date() + "-" + vm.tempo);
                location.reload();
            }
        }

        // function gerarBoleto() {
        //     vm.load = true;
        //     $http.post('api/pre-matricula-getboleto/', vm.matricula, { responseType: 'arraybuffer' })
        //         .success(function(data) {
        //             var file = new Blob([(data)], { type: 'application/pdf' });
        //             var fileURL = URL.createObjectURL(file);
        //             var a = document.createElement("a");
        //             document.body.appendChild(a);
        //             a.style = "display: none";
        //             a.href = fileURL;
        //             a.download = vm.matricula.nome + " - Boleto.pdf";
        //             a.click();
        //             window.URL.revokeObjectURL(fileURL);
        //             vm.load = false;
        //         }).error(function(status) {
        //             console.log(status);
        //         });;
        // }

        // function gerarPgto() {
        //     vm.liberado = false;
        //     $http.post("/api/createcheckout", vm.matricula)
        //         .success(function(result) {
        //             console.log(result);

        //             Financeiro.get({ id: result.orderNumber.substring(3) },
        //                 function(f) {
        //                     var a = result.settings.checkoutUrl.split("/");
        //                     var len = a.length;
        //                     f.idCheckOutCielo = a[len - 1];
        //                     Financeiro.update(f, function(result) {}, function(status) {
        //                         console.log(status);
        //                     });
        //                 });

        //             var a = document.createElement("a");
        //             document.body.appendChild(a);
        //             a.href = result.settings.checkoutUrl;
        //             a.click();
        //             vm.load = false;
        //         })
        //         .error(function(status) {
        //             console.log(status);
        //         });
        // }

        // function gerarPix() {
        //     vm.salvando = true;
        //     vm.pixGerado = "BEFORE";
        //     vm.pagarPix = true;

        //     console.log("iniciando processo de gerar qrcode");

        //     vm.salvando = false;
        //     $http.post("/api/createQrCode", vm.matricula)
        //         .success(function(result) {
        //             vm.imgPix = result.data;
        //             vm.pixGerado = "ON";
        //             vm.salvando = false;
        //             LoadFinanceiro();
        //             startCountdown();
        //         })
        //         .error(function(status) {
        //             console.log(status);
        //         });
        // }


        // function change() {
        //     for (var i = 0; i < vm.estados.length; i++) {
        //         var estado = vm.estados[i];

        //         if (estado.sigla == vm.estado) {
        //             vm.cidades = estado.cidades;
        //             break;
        //         }
        //     }
        // }

        // function changeResponsavel() {
        //     vm.matricula.cepResponsavel = vm.matricula.cep;
        //     vm.matricula.ufResponsavel = vm.matricula.uf;
        //     vm.matricula.cidadeResponsavel = vm.matricula.cidade;
        //     vm.matricula.bairroResponsavel = vm.matricula.bairro;
        //     vm.matricula.logradouroResponsavel = vm.matricula.logradouro;
        //     vm.matricula.numeroResponsavel = vm.matricula.numero;
        //     vm.matricula.complementoResponsavel = vm.matricula.complemento;
        // }


    }
})();