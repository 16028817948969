(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoDialogController', InscricaoDialogController);

    InscricaoDialogController.$inject = ['$timeout', '$scope', '$http', 'Principal', '$stateParams', '$uibModalInstance', 'entity', 'Inscricao', 'ConsultaAlunoPlay2018', 'ColegioPlay',
        'InscricaoColegio', 'InscricaoPulseira', 'InscricaoPulseiraConsulta', 'ColegioByInscService', 'GetCandidatoPlayCodIntService', 'CidadesMDJsonService', 'LinkVendedorService'
    ];

    function InscricaoDialogController($timeout, $scope, $http, Principal, $stateParams, $uibModalInstance, entity, Inscricao, ConsultaAlunoPlay2018, ColegioPlay, InscricaoColegio,
        InscricaoPulseira, InscricaoPulseiraConsulta, ColegioByInscService, GetCandidatoPlayCodIntService, CidadesMDJsonService, LinkVendedorService) {
        var vm = this;


        vm.account = null;
        vm.inscricao = entity;
        vm.inscricaoCandidato = {};
        vm.inscricaoColegio = {};
        vm.inscricaoPulseira = {};
        vm.consulta = {};
        vm.consulta.idps = '63';
        vm.consultados = {};

        vm.consultaInsc = consultaInsc;
        vm.setaConsultor = setaConsultor;
        vm.clear = clear;
        vm.validaCodigo = validaCodigo;
        //vm.transfere = transfere;
        vm.useInsc = useInsc;
        vm.save = save;
        vm.change = change;
        //vm.buscaCursos = buscaCursos;
        vm.filtraCidades = filtraCidades;
        vm.filtraColegios = filtraColegios;
        vm.buscaColegioSemIsnc = buscaColegioSemIsnc;

        vm.mostraConsulta = true;
        vm.colSelect = null;

        $http.post("api/get-modalidades-ativas-tradicional")
            .success(function(result) {
                vm.cursos = result;
                //console.log(vm.cursos);
                getAccount();
            })
            .error(function(status) {
                console.log(status);
            });


        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                loadIndicacao();
            });
        }

        function loadIndicacao() {

            vm.consultores = [
                { codigo: '04656', nome: 'MAYARA DE FREITAS' },
                { codigo: '055861', nome: 'INSTITUCIONAL' },
                { codigo: '04814', nome: 'RUTE MARIA RODRIGUES COMIM' },
                { codigo: '05035', nome: 'GUSTAVO FELIPE SILVA DE OLIVEIRA' },
                { codigo: '05273', nome: 'Mario Bonato Júnior' },
                { codigo: '05287', nome: 'Tainara Santos' },
                { codigo: '05338', nome: 'Barbara Quintiliano' },
            ];

            if (vm.inscricao.id == null) {
                switch (vm.account.login) {
                    case 'mayara.freitas@univel.br':
                        vm.inscricao.recebeuIndicacao = 'FUNCIONARIO';
                        vm.inscricao.codPessoaIndicacao = '04656';
                        vm.inscricao.indicacaoTexto = 'MAYARA DE FREITAS';
                        break;

                    case 'rute.comim@univel.br':
                        vm.inscricao.recebeuIndicacao = 'FUNCIONARIO';
                        vm.inscricao.codPessoaIndicacao = '04814';
                        vm.inscricao.indicacaoTexto = 'RUTE MARIA RODRIGUES COMIM';
                        break;

                    case 'gustavo.oliveira@univel.br':
                        vm.inscricao.recebeuIndicacao = 'FUNCIONARIO';
                        vm.inscricao.codPessoaIndicacao = '05035';
                        vm.inscricao.indicacaoTexto = 'GUSTAVO FELIPE SILVA DE OLIVEIRA';
                        break;

                    case 'mario.bonato@univel.br':
                        vm.inscricao.recebeuIndicacao = 'FUNCIONARIO';
                        vm.inscricao.codPessoaIndicacao = '05273';
                        vm.inscricao.indicacaoTexto = 'Mario Bonato Júnior';
                        break;

                    case 'tainara.santos@univel.br':
                        vm.inscricao.recebeuIndicacao = 'FUNCIONARIO';
                        vm.inscricao.codPessoaIndicacao = '05287';
                        vm.inscricao.indicacaoTexto = 'Tainara Santos';
                        break;

                    case 'barbara.quintiliano@univel.br':
                        vm.inscricao.recebeuIndicacao = 'FUNCIONARIO';
                        vm.inscricao.codPessoaIndicacao = '05338';
                        vm.inscricao.indicacaoTexto = 'Barbara Quintiliano';
                        break;

                    default:
                        vm.inscricao.recebeuIndicacao = 'NAO';
                        vm.inscricao.codPessoaIndicacao = '055861';
                        vm.inscricao.indicacaoTexto = 'INSTITUCIONAL';
                        break;
                }
            }


            loadCidadesByJSON();
        }

        function loadCidadesByJSON() {
            vm.cidades = CidadesMDJsonService;
            vm.estadosSigla = [];
            for (var i = 0; i < vm.cidades.length; i++) {
                var e = vm.cidades[i];
                if (!vm.estadosSigla.includes(e.uf)) {
                    vm.estadosSigla.push(e.uf);
                }
            }
            loadColegios();
        }

        function loadColegios() {
            $http.get("/api/colegio-plays")
                .success(function(data) {
                    vm.colegiosPlay = [];

                    for (var i = 0; i < data.length; i++) {
                        var a = data[i];
                        if (vm.account.login != 'admin') {
                            if (a.vendedor == vm.inscricao.codPessoaIndicacao) {
                                vm.colegiosPlay.push(a);
                            }
                        } else {
                            vm.colegiosPlay.push(a);
                        }
                    }

                    //console.log(vm.colegiosPlay);

                    vm.cidadesQuePossuiColegio = [];
                    var temp = [];

                    vm.cidadeColegioSelecionada = 'CASCAVEL';

                    for (var i = 0; i < vm.colegiosPlay.length; i++) {
                        var c = vm.colegiosPlay[i];
                        if (!vm.cidadesQuePossuiColegio.includes(c.cidade)) {
                            vm.cidadesQuePossuiColegio.push(c.cidade);
                        }
                        if (c.cidade == vm.cidadeColegioSelecionada) {
                            temp.push(c);
                        }
                    }
                    vm.colegiosDaCidade = temp;
                    //console.log(vm.cidadesQuePossuiColegio);
                    init();
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        /**
         * Verifica se o cara é novo ou editado
         * Se for novo cadastra e segue o baile
         * Se for editado Busca a pulseira e o colegio dele
         * Quando salvar verifica os códigos e atuliza se for preciso;
         */


        function init() {
            if (vm.inscricao.id != null) {
                //console.log(vm.inscricao);
                vm.mostraConsulta = false;

                if (vm.inscricao.dataNascimento != null) {
                    var dateString = vm.inscricao.dataNascimento;
                    var year = dateString.substring(0, 4);
                    var month = dateString.substring(5, 7);
                    var day = dateString.substring(8, 10);
                    var date = new Date(year, month - 1, day);
                    vm.inscricao.dataNascimento = date;
                }

                for (var i = 0; i < vm.cursos.length; i++) {
                    var e = vm.cursos[i];
                    if (e.id == vm.inscricao.idModalidadeCurso1) {
                        vm.modalidade1 = e;
                    }
                    if (e.id == vm.inscricao.idModalidadeCurso2) {
                        vm.modalidade2 = e;
                    }
                }

                vm.cidadesNatal = [];
                for (var i = 0; i < vm.cidades.length; i++) {
                    var e = vm.cidades[i];
                    if (e.uf == vm.inscricao.estadoNatal) {
                        vm.cidadesNatal.push(e.cidade);
                    }
                }


                ColegioByInscService.query({ cod: vm.inscricao.codigo, idps: vm.inscricao.idps }, function(result) {
                    vm.inscricaoColegio = result;
                    //console.log(vm.inscricaoColegio);
                    ColegioPlay.get({ id: vm.inscricaoColegio.codigoColegio }, function(result) {
                        vm.colegio = result;
                        vm.colegioSelecionado = vm.colegio.id;
                        //console.log(vm.colegio);
                        vm.cidadeColegioSelecionada = vm.colegio.cidade;
                        //console.log(vm.cidadeColegioSelecionada);
                        var temp = [];
                        for (var i = 0; i < vm.colegiosPlay.length; i++) {
                            var c = vm.colegiosPlay[i];
                            if (!vm.cidadesQuePossuiColegio.includes(c.cidade)) {
                                vm.cidadesQuePossuiColegio.push(c.cidade);
                            }
                            if (c.cidade == vm.cidadeColegioSelecionada) {
                                temp.push(c);
                            }
                        }
                        vm.colegiosDaCidade = temp;



                    });
                }, function(status) {
                    console.log(status);
                })

                setaVendedorLink(vm.inscricao.codPessoaIndicacao);

                InscricaoPulseiraConsulta.query({ pulseira: vm.inscricao.codigo, idps: vm.inscricao.idps }, function(result) {
                    vm.inscricaoPulseira = result;
                }, function(status) {
                    console.log(status);
                });

            } else {
                vm.mostraConsulta = true;
                vm.inscricao.tipoInscricao = 'CONVIDADO';
                vm.inscricao.idps = vm.consulta.idps;
                vm.inscricao.recebeuIndicacao = 'NAO';
                vm.inscricao.possuiNecessidadesEspeciais = 'NAO';
                vm.inscricao.necessidadesEspeciaisTexto = '';
                vm.inscricao.anoConclusao = 2024;
                vm.inscricao.transporte = null;
                vm.inscricao.transporteCidade = 0;

                //vm.vestiba = 'SIM';
                vm.canhoto = false;
                vm.treineiro = false;
            }
        }

        function setaVendedorLink(valor) {
            //console.log(valor);
            if (valor != null && valor != undefined) {
                vm.interno = true;
                vm.vendLink = true;
                LinkVendedorService.query({ codigo: valor }, function(result) {
                    //console.log(result);
                    vm.inscricao.recebeuIndicacao = result.tipo;
                    vm.inscricao.codPessoaIndicacao = result.codigo;
                    vm.inscricao.indicacaoTexto = result.nome;
                    vm.vendedorLink = result.link;
                    vm.vendedorNumero = result.numero;
                    vm.vendedorEmail = result.email;

                });
            } else {
                vm.interno = false;
                vm.vendLink = false;
                vm.inscricao.recebeuIndicacao = 'NAO';
                vm.inscricao.codPessoaIndicacao = '';
                vm.inscricao.indicacaoTexto = '';
                vm.vendedorLink = 'https://wa.me/5545988429444';
                vm.vendedorNumero = "45 98842-9444";
                vm.vendedorEmail = "vestibular@univel.br";
            }
        }

        function validaInsc() {

            //console.log(vm.inscricao);

            if (vm.inscricao.possuiNecessidadesEspeciais == "SIM") {
                if (vm.inscricao.necessidadesEspeciaisTexto == "" || vm.inscricao.necessidadesEspeciaisTexto == null || vm.inscricao.necessidadesEspeciaisTexto == undefined) {
                    return "INFORME QUAL A NECESSIDADE ESPECIAL";
                }
            } else {
                vm.inscricao.necessidadesEspeciaisTexto = "";
            }

            return "OK";
        }

        function validaCodigo() {
            ConsultaAlunoPlay2018.query({
                tipo: 'INSCRICAO',
                valor: vm.inscricao.codigo,
                idps: vm.inscricao.idps
            }, function(data) {
                if (data.length > 0) {
                    if (data[0].inscricao.codigo !== null) {
                        // achou alguem tem que barrar.
                        alert(data[0].inscricao.nome.split(' ')[0] + ' já está utilizando este código, utilize o consulta play para verificar;');
                    }
                }
            }, function(status) {
                alert("Houve um erro ao processar o código da inscrição");
            });
        }

        function save() {

            var msg = validaInsc();

            if (msg == "OK") {
                var agora = new Date();
                vm.isSaving = true;

                vm.inscricao.telefone1 = vm.inscricao.telefone2;
                vm.inscricao.dataAlteracao = agora;
                vm.inscricao.usuarioAlteracao = vm.account.login;

                if (vm.inscricao.id !== null) {
                    Inscricao.update(vm.inscricao, onSaveSuccess, onSaveError);
                } else {

                    vm.inscricao.dataProva = new Date(2024, 9, 8);
                    vm.inscricao.horaProva = '13';
                    vm.inscricao.dataCriacao = agora;
                    vm.inscricao.usuarioCriacao = vm.account.login;

                    vm.inscricao.estado = 'PR';
                    vm.inscricao.cidade = 'Cascavel';
                    vm.inscricao.polo = 1;
                    vm.inscricao.instituicaoConclusao = 3421;
                    vm.inscricao.canhoto = false;
                    vm.inscricao.treineiro = false;


                    if (vm.inscricao.cpf == null || vm.inscricao.cpf == undefined) {
                        vm.inscricao.cpf == "";
                    }

                    if (vm.inscricao.documento == null || vm.inscricao.documento == undefined) {
                        vm.inscricao.documento == "";
                    }

                    if (vm.inscricao.email == null || vm.inscricao.email == "") {
                        vm.inscricao.email = 'NAOTEM';
                    }

                    //console.log(vm.inscricao);
                    Inscricao.save(vm.inscricao, onSaveSuccess, onSaveError);
                }

            } else {
                alert(msg);
            }
        }

        function onSaveSuccess(result) {

            var agora = new Date();

            if (vm.inscricao.id !== null) {
                // aqui é edit, verifica se pegou a pulseira.
                if (vm.inscricaoPulseira == null) {
                    // nao pegou vai criar uma nova
                    vm.inscricaoPulseira = {};
                    vm.inscricaoPulseira.codigoInscricao = result.codigo;
                    vm.inscricaoPulseira.codigoPulseira = result.codigo;
                    vm.inscricaoPulseira.dataAlteracao = agora;
                    vm.inscricaoPulseira.usuarioAlteracao = vm.account.login;
                    vm.inscricaoPulseira.dataCriacao = agora;
                    vm.inscricaoPulseira.usuarioCriacao = vm.account.login;
                    vm.inscricaoPulseira.idps = result.idps;
                    InscricaoPulseira.save(vm.inscricaoPulseira, function(dataPul) { console.log(dataPul); }, function(result) { console.log(result); });
                } else {
                    //pegou atualiza
                    vm.inscricaoPulseira.codigoInscricao = result.codigo;
                    vm.inscricaoPulseira.codigoPulseira = result.codigo;
                    vm.inscricaoPulseira.dataAlteracao = agora;
                    vm.inscricaoPulseira.usuarioAlteracao = vm.account.login;
                    vm.inscricaoPulseira.idps = result.idps;
                    InscricaoPulseira.update(vm.inscricaoPulseira, function(dataPul) { console.log(dataPul); }, function(result) { console.log(result); });
                }

                // verifica se encontrou o colegio
                if (vm.inscricaoColegio == null) {
                    // nao pegou cria um novo
                    vm.inscricaoColegio = {};
                    vm.inscricaoColegio.codigoInscricao = result.codigo;
                    vm.inscricaoColegio.codigoColegio = vm.colegioSelecionado;
                    vm.inscricaoColegio.idps = result.idps;
                    vm.inscricaoColegio.dataCriacao = new Date();
                    vm.inscricaoColegio.usuarioCriacao = vm.account.login;
                    vm.inscricaoColegio.dataAlteracao = new Date();
                    vm.inscricaoColegio.usuarioAlteracao = vm.account.login;
                    InscricaoColegio.save(vm.inscricaoColegio, function(col) { console.log(col); }, function(status) { alert('Houve um erro ao salvar o colegio da inscricao;') });
                } else {
                    vm.inscricaoColegio.codigoInscricao = result.codigo;
                    vm.inscricaoColegio.codigoColegio = vm.colegioSelecionado;
                    vm.inscricaoColegio.idps = result.idps;
                    vm.inscricaoColegio.dataCriacao = new Date();
                    vm.inscricaoColegio.usuarioCriacao = vm.account.login;
                    vm.inscricaoColegio.dataAlteracao = new Date();
                    vm.inscricaoColegio.usuarioAlteracao = vm.account.login;
                    InscricaoColegio.update(vm.inscricaoColegio, function(col) { console.log(col); }, function(status) { alert('Houve um erro ao salvar o colegio da inscricao;') });
                }
                $scope.$emit('vestibularApp:inscricaoUpdate', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;

            } else {
                vm.inscricaoColegio = {};
                vm.inscricaoColegio.codigoInscricao = result.codigo;
                vm.inscricaoColegio.codigoColegio = vm.colegioSelecionado;
                vm.inscricaoColegio.idps = result.idps;
                vm.inscricaoColegio.dataCriacao = new Date();
                vm.inscricaoColegio.usuarioCriacao = vm.account.login;
                vm.inscricaoColegio.dataAlteracao = new Date();
                vm.inscricaoColegio.usuarioAlteracao = vm.account.login;
                console.log(vm.inscricaoColegio);
                InscricaoColegio.save(vm.inscricaoColegio, function(col) {
                    console.log(col);
                }, function(status) {
                    alert('Houve um erro ao salvar o colegio da inscricao;')
                });


                vm.inscricaoPulseira = {};
                vm.inscricaoPulseira.codigoInscricao = result.codigo;
                vm.inscricaoPulseira.codigoPulseira = result.codigo;
                vm.inscricaoPulseira.dataCriacao = new Date();
                vm.inscricaoPulseira.usuarioCriacao = 'siteColegio';
                vm.inscricaoPulseira.dataAlteracao = new Date();
                vm.inscricaoPulseira.usuarioAlteracao = 'siteColegio';
                vm.inscricaoPulseira.idps = result.idps;

                InscricaoPulseira.save(vm.inscricaoPulseira, function(pul) {
                    console.log(pul);
                }, function(status) {
                    console.log(status);
                });

                $scope.$emit('vestibularApp:inscricaoSave', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }

        function onSaveError() {
            vm.isSaving = false;
            alert('Houve um erro ao salvar a inscrição;')
        }

        vm.alterInfo = function(op, i) {

            // curso 1
            if (op == 1) {
                // console.log(i);
                vm.inscricao.idProcessoSeletivo = i.idProcesso;
                vm.inscricao.idps = i.idps;
                vm.inscricao.idCurso1 = i.idCurso;
                vm.inscricao.idModalidadeCurso1 = i.id;
                vm.inscricao.idAreaInteresseOpcao1 = i.idAreaInteresse;
            }
            // curso 2
            if (op == 2) {
                //console.log(i);
                vm.inscricao.idCurso2 = i.idCurso;
                vm.inscricao.idModalidadeCurso2 = i.id;
                vm.inscricao.idAreaInteresseOpcao2 = i.idAreaInteresse;
            }
            // estado natal
            if (op == 3) {
                vm.cidadesNatal = [];
                for (var i = 0; i < vm.cidades.length; i++) {
                    var e = vm.cidades[i];
                    if (e.uf == vm.inscricao.estadoNatal) {
                        vm.cidadesNatal.push(e.cidade);
                    }
                }
            }

            // log cidade natal
            if (op == 4) {

            }

            // cidade colegio
            if (op == 5) {
                vm.colegiosDaCidade = [];
                for (var i = 0; i < vm.colegiosPlay.length; i++) {
                    var e = vm.colegiosPlay[i];
                    if (e.cidade == vm.cidadeColegioSelecionada) {
                        vm.colegiosDaCidade.push(e);
                    }
                }
            }

            // colegio selecionado
            if (op == 6) {
                //console.log(vm.colegioSelecionado);
            }

            //console.log(vm.inscricao);
        }

        /* OOOOOOLD */

        function change() {
            for (var i = 0; i < vm.estados.length; i++) {
                var estado = vm.estados[i];

                if (estado.sigla == vm.estado) {
                    vm.cidades = estado.cidades;
                    break;
                }
            }
        }

        function consultaInsc() {
            //console.log(vm.consulta);
            GetCandidatoPlayCodIntService.query(vm.consulta, function(result) {
                vm.consultados = result;
                //console.log(result);
            }, function(status) { console.log(status); });
        }

        function useInsc(insc) {
            vm.inscricao = insc;
            vm.mostraConsulta = false;
            init();
        }

        function buscaColegioSemIsnc() {

            if (vm.colegio == null || vm.colegio == undefined) {
                ColegioByInscService.query({ cod: vm.inscricao.codigo, idps: vm.inscricao.idps }, function(result) {
                    console.log(result);
                    vm.inscricaoColegio = result;
                    vm.colegio = ColegioPlay.get({ id: vm.inscricaoColegio.codigoColegio });
                }, function(status) {
                    console.log(status);
                });
            }
        }

        function setaConsultor() {
            vm.inscricao.recebeuIndicacao = 'FUNCIONARIO';
            vm.inscricao.codPessoaIndicacao = vm.consultorSelecionado.codigo;
            vm.inscricao.indicacaoTexto = vm.consultorSelecionado.nome;
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }


        /*   referente aos estados   */
        vm.querySearchEstado = querySearchEstado;
        vm.selectedItemChangeEstado = selectedItemChangeEstado;
        vm.searchTextChangeEstado = searchTextChangeEstado;

        function querySearchEstado(query) {
            var results = query ? vm.cidades.filter(createFilterForEstado(query)) : vm.cidades,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangeEstado(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangeEstado(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForEstado(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {

                return state.cidade.toUpperCase().includes(lowercaseQuery);
            };

        }

        function filtraCidades() {
            var temp = [];
            vm.cidadesFiltrada = [];

            for (var i = 0; i < vm.cidades.length; i++) {
                var e = vm.cidades[i];

                if (e.cidade.toUpperCase().includes(vm.consultaCidade.toUpperCase())) {
                    temp.push(e);
                }
            }
            vm.cidadesFiltrada = temp;
        }

        function filtraColegios() {
            var temp = [];
            vm.colegiosFiltrados = [];

            //console.log(vm.consultaColegio);

            for (var i = 0; i < vm.colegiosPlay.length; i++) {
                var e = vm.colegiosPlay[i];

                if (e.nome.toUpperCase().includes(vm.consultaColegio.toUpperCase())) {
                    temp.push(e);
                }
            }
            vm.colegiosFiltrados = temp;
        }
    }
})();