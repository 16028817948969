(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('academico', {
                parent: 'entity',
                url: '/academico?page&sort&search',
                data: {
                    authorities: ['ROLE_SECRETARIA'],
                    pageTitle: 'Rematrículas'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/academico/academicos.html',
                        controller: 'AcademicoController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                }
            })
            .state('academico-detail', {
                parent: 'entity',
                url: '/academico/{id}',
                data: {
                    authorities: ['ROLE_SECRETARIA'],
                    pageTitle: 'Academico'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/academico/academico-detail.html',
                        controller: 'AcademicoDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Academico', function($stateParams, Academico) {
                        return Academico.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'academico',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('academico-detail.edit', {
                parent: 'academico-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_SECRETARIA']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/academico/academico-dialog.html',
                        controller: 'AcademicoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Academico', function(Academico) {
                                return Academico.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('academico.new', {
                parent: 'academico',
                url: '/new',
                data: {
                    authorities: ['ROLE_SECRETARIA']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/academico/academico-dialog.html',
                        controller: 'AcademicoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    ra: null,
                                    nome: null,
                                    cep: null,
                                    uf: null,
                                    cidade: null,
                                    bairro: null,
                                    logradouro: null,
                                    numero: null,
                                    complemento: null,
                                    telefone1: null,
                                    telefone2: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('academico', null, { reload: 'academico' });
                    }, function() {
                        $state.go('academico');
                    });
                }]
            })
            .state('academico.edit', {
                parent: 'academico',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SECRETARIA']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/academico/academico-dialog.html',
                        controller: 'AcademicoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Academico', function(Academico) {
                                return Academico.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('academico', null, { reload: 'academico' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('academico.delete', {
                parent: 'academico',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SECRETARIA']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/academico/academico-delete-dialog.html',
                        controller: 'AcademicoDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Academico', function(Academico) {
                                return Academico.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('academico', null, { reload: 'academico' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();