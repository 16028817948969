(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaDiplomaResponsavelController', MatriculaDiplomaResponsavelController);

    MatriculaDiplomaResponsavelController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$http', '$sessionStorage', 'CidadesMDJsonService', 'MatriculaDiplomaService'];

    function MatriculaDiplomaResponsavelController($timeout, $scope, $state, $stateParams, $http, $sessionStorage, CidadesMDJsonService, MatriculaDiplomaService) {
        var vm = this;

        // functions
        vm.salvar = save;
        vm.getCEP = buscaCEP;
        vm.changeResponsavel = changeResponsavel
        vm.load = false;

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        //vm.estados = CidadesJsonService;

        vm.matricula = JSON.parse(sessionStorage.getItem('user-matric-diploma'));


        loadCidadesByJSON();

        /*  transferindo dados do academico para o responsável  */
        vm.idade = calculateAge(vm.matricula.academico.dataNascimento);

        function calculateAge(dobString) {
            var dob = new Date(dobString);
            var currentDate = new Date();
            var currentYear = currentDate.getFullYear();
            var birthdayThisYear = new Date(currentYear, dob.getMonth(), dob.getDate());
            var age = currentYear - dob.getFullYear();
            if (birthdayThisYear > currentDate) {
                age--;
            }
            return age;
        }

        if (vm.idade >= 18) {
            vm.maior_de_idade = true;
            vm.responsavel = "EU";
            vm.endereco_responsavel = true
        } else {
            if (vm.matricula.nomeResponsavel != null) {

                if (vm.matricula.nomeResponsavel == vm.matricula.nomePai) {
                    vm.responsavel = 'PAI';
                }

                if (vm.matricula.nomeResponsavel == vm.matricula.nomeMae) {
                    vm.responsavel = 'MAE';
                }

            } else {
                vm.responsavel = 'MAE';
            }
        }



        changeResponsavel();

        function changeResponsavel() {
            if (vm.responsavel == "EU") {
                if (vm.matricula.academico.cidadeNatal != null) {
                    var b = vm.matricula.academico.cidadeNatal + ' - ' + vm.matricula.academico.estadoNatal;
                    for (var i = 0; i < vm.cidades.length; i++) {
                        var element = vm.cidades[i];
                        var a = element.cidade + ' - ' + element.uf;
                        if (a == b) {
                            vm.naturalidadeResponsavel = element;
                        }
                    }
                }
                vm.matricula.academico.nomeResponsavel = vm.matricula.academico.nome;
                vm.matricula.academico.nacionalidadeResponsavel = vm.matricula.academico.nacionalidade;

                var dateString = vm.matricula.academico.dataNascimento;
                var year = dateString.substring(0, 4);
                var month = dateString.substring(5, 7);
                var day = dateString.substring(8, 10);
                var date = new Date(year, month - 1, day);
                vm.matricula.academico.dataNascimentoResponsavel = date;

                vm.matricula.academico.cpfResponsavel = vm.matricula.academico.cpf;
                vm.matricula.academico.rgResponsavel = vm.matricula.academico.rg;

                vm.matricula.academico.cepResponsavel = vm.matricula.academico.cep;
                vm.matricula.academico.ufResponsavel = vm.matricula.academico.uf;
                vm.matricula.academico.cidadeResponsavel = vm.matricula.academico.cidade;
                vm.matricula.academico.bairroResponsavel = vm.matricula.academico.bairro;
                vm.matricula.academico.logradouroResponsavel = vm.matricula.academico.logradouro;
                vm.matricula.academico.numeroResponsavel = vm.matricula.academico.numero;
                vm.matricula.academico.complementoResponsavel = vm.matricula.academico.complemento;
            }

            if (vm.responsavel == "PAI") {
                if (vm.matricula.academico.cidadeNatalPai != null) {
                    var b = vm.matricula.academico.cidadeNatalPai + ' - ' + vm.matricula.academico.estadoNatalPai;
                    for (var i = 0; i < vm.cidades.length; i++) {
                        var element = vm.cidades[i];
                        var a = element.cidade + ' - ' + element.uf;
                        if (a == b) {
                            vm.naturalidadeResponsavel = element;
                        }
                    }
                }

                vm.matricula.academico.nomeResponsavel = vm.matricula.academico.nomePai;
                vm.matricula.academico.nacionalidadeResponsavel = vm.matricula.academico.nacionalidadePai;

                var dateString = vm.matricula.academico.dataNascimentoPai;
                var year = dateString.substring(0, 4);
                var month = dateString.substring(5, 7);
                var day = dateString.substring(8, 10);
                var date = new Date(year, month - 1, day);
                vm.matricula.academico.dataNascimentoResponsavel = date;

                vm.matricula.academico.cpfResponsavel = vm.matricula.academico.cpfPai;
                vm.matricula.academico.rgResponsavel = vm.matricula.academico.rgPai;

                vm.matricula.academico.cepResponsavel = vm.matricula.academico.cepPai;
                vm.matricula.academico.ufResponsavel = vm.matricula.academico.ufPai;
                vm.matricula.academico.cidadeResponsavel = vm.matricula.academico.cidadePai;
                vm.matricula.academico.bairroResponsavel = vm.matricula.academico.bairroPai;
                vm.matricula.academico.logradouroResponsavel = vm.matricula.academico.logradouroPai;
                vm.matricula.academico.numeroResponsavel = vm.matricula.academico.numeroPai;
                vm.matricula.academico.complementoResponsavel = vm.matricula.academico.complementoPai;
            }

            if (vm.responsavel == "MAE") {
                if (vm.matricula.academico.cidadeNatalMae != null) {
                    var b = vm.matricula.academico.cidadeNatalMae + ' - ' + vm.matricula.academico.estadoNatalMae;
                    for (var i = 0; i < vm.cidades.length; i++) {
                        var element = vm.cidades[i];
                        var a = element.cidade + ' - ' + element.uf;
                        if (a == b) {
                            vm.naturalidadeResponsavel = element;
                        }
                    }
                }

                vm.matricula.academico.nomeResponsavel = vm.matricula.academico.nomeMae;
                vm.matricula.academico.nacionalidadeResponsavel = vm.matricula.academico.nacionalidadeMae;

                var dateString = vm.matricula.academico.dataNascimentoMae;
                var year = dateString.substring(0, 4);
                var month = dateString.substring(5, 7);
                var day = dateString.substring(8, 10);
                var date = new Date(year, month - 1, day);
                vm.matricula.academico.dataNascimentoResponsavel = date;

                vm.matricula.academico.cpfResponsavel = vm.matricula.academico.cpfMae;
                vm.matricula.academico.rgResponsavel = vm.matricula.academico.rgMae;

                vm.matricula.academico.cepResponsavel = vm.matricula.academico.cepMae;
                vm.matricula.academico.ufResponsavel = vm.matricula.academico.ufMae;
                vm.matricula.academico.cidadeResponsavel = vm.matricula.academico.cidadeMae;
                vm.matricula.academico.bairroResponsavel = vm.matricula.academico.bairroMae;
                vm.matricula.academico.logradouroResponsavel = vm.matricula.academico.logradouroMae;
                vm.matricula.academico.numeroResponsavel = vm.matricula.academico.numeroMae;
                vm.matricula.academico.complementoResponsavel = vm.matricula.academico.complementoMae;
            }

            if (vm.responsavel == "OUTRO") {
                vm.matricula.academico.nomeResponsavel = "";
                vm.matricula.academico.cidadeNatalResponsavel = "";
                vm.matricula.academico.estadoNatalResponsavel = "";
                vm.matricula.academico.nacionalidadeResponsavel = "";
                vm.matricula.academico.dataNascimentoResponsavel = "";
                vm.matricula.academico.cpfResponsavel = "";
                vm.matricula.academico.rgResponsavel = "";
                vm.estado = "";
            }
        }

        function verificaCampos() {

            if (vm.naturalidadeResponsavel == null) {
                return "O campo CIDADO ONDE NASCEU não pode ser vazio";
            }

            if (vm.matricula.academico.nomeResponsavel == null || vm.matricula.academico.nomeResponsavel == "") {
                return "O campo NOME não pode ser vazio";
            }

            if (vm.matricula.academico.nacionalidadeResponsavel == null || vm.matricula.academico.nacionalidadeResponsavel == "") {
                return "O campo NACIONALIDADE não pode ser vazio";
            }
            if (vm.matricula.academico.dataNascimentoResponsavel == null || vm.matricula.academico.dataNascimentoResponsavel == "") {
                return "O campo DATA NASCIMENTO não pode ser vazio";
            }
            if (vm.matricula.academico.cpfResponsavel == null || vm.matricula.academico.cpfResponsavel == "") {
                return "O campo CPF não pode ser vazio";
            }

            if (vm.matricula.academico.rgResponsavel == null || vm.matricula.academico.rgResponsavel == "") {
                return "O campo RG não pode ser vazio";
            }

            if (vm.matricula.academico.cepResponsavel == null || vm.matricula.academico.cepResponsavel == "") {
                return "O campo CEP não pode ser vazio";
            }

            if (vm.matricula.academico.ufResponsavel == null || vm.matricula.academico.ufResponsavel == "") {
                return "O campo UF não pode ser vazio";
            }

            if (vm.matricula.academico.cidadeResponsavel == null || vm.matricula.academico.cidadeResponsavel == "") {
                return "O campo CIDADE não pode ser vazio";
            }

            if (vm.matricula.academico.bairroResponsavel == null || vm.matricula.academico.bairroResponsavel == "") {
                return "O campo BAIRRO não pode ser vazio";
            }

            if (vm.matricula.academico.logradouroResponsavel == null || vm.matricula.academico.logradouroResponsavel == "") {
                return "O campo LOGRADOURO não pode ser vazio";
            }

            if (vm.matricula.academico.numeroResponsavel == null || vm.matricula.academico.numeroResponsavel == "") {
                vm.matricula.academico.numeroResponsavel = 1;
            }
            if (vm.matricula.academico.numeroResponsavel.length < 1) {
                vm.matricula.academico.numeroResponsavel = 1;
            }

            if (vm.matricula.academico.numeroResponsavel == 0) {
                vm.matricula.academico.numeroResponsavel = 1;
            }

            return "OK";
        }

        function save() {
            vm.load = true;
            var valida = verificaCampos();

            if (valida == "OK") {
                if (idade(new Date(vm.matricula.academico.dataNascimentoResponsavel), new Date()) >= 18) {
                    vm.matricula.academico.etapa = 'RESPONSAVEL';
                    vm.matricula.academico.estadoNatalResponsavel = vm.naturalidadeResponsavel.uf;
                    vm.matricula.academico.cidadeNatalResponsavel = vm.naturalidadeResponsavel.cidade;
                    sessionStorage.setItem('user-matric-diploma', JSON.stringify(vm.matricula));

                    MatriculaDiplomaService.save(vm.matricula,
                        function(data) {
                            vm.load = false;
                            sessionStorage.setItem('user-matric-diploma', JSON.stringify(data));
                            $state.go('matriculaDiplomaPlanoPagamento');
                        },
                        function(status) {
                            vm.load = false;
                            console.log(status);
                        }
                    );


                } else {
                    vm.load = false;
                    showMessage("alert-danger", "O responsável financeiro precisa ter mais de 18 anos");
                }
            } else {
                vm.load = false;
                showMessage("alert-danger", valida);
            }
        }

        function idade(nascimento, hoje) {
            var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
            if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) < new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate()))
                diferencaAnos--;
            return diferencaAnos;
        }

        function loadCidadesByJSON() {

            vm.cidades = CidadesMDJsonService;
        }

        vm.querySearchEstado = querySearchEstado;
        vm.selectedItemChangeEstado = selectedItemChangeEstado;
        vm.searchTextChangeEstado = searchTextChangeEstado;

        function querySearchEstado(query) {

            var results = query ? vm.cidades.filter(createFilterForEstado(query)) : vm.cidades,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangeEstado(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangeEstado(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForEstado(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(state) {

                return state.cidade.toUpperCase().includes(lowercaseQuery);
            };

        }

        function buscaCEP() {

            //console.log(vm.matricula.cep);
            vm.cepsearch = true;
            if (vm.matricula.academico.cepResponsavel !== null && vm.matricula.academico.cepResponsavel !== undefined) {
                $http.post("/api/getCepInfos", { cep: vm.matricula.academico.cepResponsavel })
                    .success(function(data) {

                        vm.matricula.academico.ufResponsavel = data.uf;
                        vm.matricula.academico.cidadeResponsavel = data.localidade;
                        vm.matricula.academico.bairroResponsavel = data.bairro;
                        vm.matricula.academico.logradouroResponsavel = data.logradouro;
                        vm.cepsearch = false;
                    })
                    .error(function(status) {
                        console.log(status);
                        vm.cepsearch = false;
                    });
            }
        }

    }
})();