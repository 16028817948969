(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('BuscaControleFinPropostas', BuscaControleFinPropostas);

    BuscaControleFinPropostas.$inject = ['$resource', 'DateUtils'];

    function BuscaControleFinPropostas($resource, DateUtils) {
        var resourceUrl = 'api/consulta-controle-fin-proposta/:campo/:valor/:ano/:limite';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();