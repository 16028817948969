(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('EditPlanoEstagioController', EditPlanoEstagioController);

    EditPlanoEstagioController.$inject = ['$state', 'LoginService', '$http', 'Principal', 'Auth', 'BuscaItensPlanoService', 'BuscaBBPlanoService', 'PlanoEnsinoObs',
        'BuscaObsPlanoService', 'PlanoEnsino', 'PlanoEnsinoBibliografia', 'PlanoEnsinoItens', 'PlanoEnsinoLog', 'BuscaDiscsPlanoIdService'
    ];

    function EditPlanoEstagioController($state, LoginService, $http, Principal, Auth, BuscaItensPlanoService, BuscaBBPlanoService,
        PlanoEnsinoObs, BuscaObsPlanoService, PlanoEnsino, PlanoEnsinoBibliografia, PlanoEnsinoItens, PlanoEnsinoLog, BuscaDiscsPlanoIdService) {
        var vm = this;

        vm.finalizaObs = finalizaObs;
        vm.salvarPlano = salvarPlano;
        vm.removeLivro = removeLivro;
        vm.insereAlt = insereAlt;
        vm.listaAlt = [];

        vm.loading = false;

        vm.bBasica = [];
        vm.bCompl = [];
        vm.bApro = [];

        vm.listaObs = [];
        vm.obs = {};

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                start();
            });
        };

        function insereAlt(campo, valor) {

            var obj = { plano: vm.plano.id, campo: campo, valor: valor };

            for (var i = 0; i < vm.listaAlt.length; i++) {
                var e = vm.listaAlt[i];
                if (e.campo == campo) {
                    vm.listaAlt.splice(i, 1);
                }
            }
            vm.listaAlt.push(obj);
            $('#' + campo).change(function() { document.getElementById(campo).style.backgroundColor = 'aquamarine'; });
        }

        function start() {
            vm.loading = true;
            vm.temp = JSON.parse(sessionStorage.getItem('plano-ensino-correcao'));
            BuscaItensPlanoService.query({ id: vm.temp.id },
                function(itens) {
                    vm.itensUpdate = itens;
                    for (var i = 0; i < itens.length; i++) {
                        var e = itens[i];

                        if (e.tipo == 'COMPETENCIA') { vm.temp.competencias = e.texto; };
                        if (e.tipo == 'EMENTA') { vm.temp.ementa = e.texto; };
                        if (e.tipo == 'INSTAVA1B') { vm.temp.inst1b = e.texto; };
                        if (e.tipo == 'INSTAVA2B') { vm.temp.inst2b = e.texto; };
                        if (e.tipo == 'BBASICA') { vm.temp.bBasica = e.texto; };
                        if (e.tipo == 'BCOMPL') { vm.temp.bCompl = e.texto; };
                        if (e.tipo == 'BAPRO') { vm.temp.bApro = e.texto; };
                        if (e.tipo == 'CONT1') { vm.temp.cont1 = e.texto; };
                        if (e.tipo == 'MET1') { vm.temp.met1 = e.texto; };

                    };


                    BuscaDiscsPlanoIdService.query({ id: vm.temp.id }, function(turmas) {
                        vm.disciplinasPlano = turmas;
                        //console.log(vm.disciplinasPlano);
                    }, function(status) { console.log(status) });

                    /*BuscaBBPlanoService.query({ id: vm.temp.id }, function(livros) {
                        for (var i = 0; i < livros.length; i++) {
                            var l = livros[i];
                            if (l.tipo == 'BASICA') { vm.bBasica.push(l); };
                            if (l.tipo == 'COMPLEMENTAR') { vm.bCompl.push(l); };
                        }
                    }, function(status) { console.log(status) });
                    */

                    BuscaObsPlanoService.query({ id: vm.temp.id }, function(obs) {
                        for (var h = 0; h < obs.length; h++) {
                            var i = obs[h];
                            vm.listaObs.push(i);
                            document.getElementById(i.tipo).style.backgroundColor = "yellow";
                        }
                    }, function(status) { console.log(status) });

                    switch (vm.temp.chTotal) {
                        case 35:
                            vm.temp.chTotal = "35";
                            break;
                        case 40:
                            vm.temp.chTotal = "40";
                            break;
                        case 70:
                            vm.temp.chTotal = "70";
                            break;
                        case 80:
                            vm.temp.chTotal = "80";
                            break;
                        case 100:
                            vm.temp.chTotal = "100";
                            break;
                        case 115:
                            vm.temp.chTotal = "115";
                            break;
                        case 120:
                            vm.temp.chTotal = "120";
                            break;
                    }
                    vm.plano = vm.temp

                    vm.loading = false;
                },
                function(status) { console.log(status); });
        }

        function finalizaObs(obs) {
            obs.etapa = "FINALIZADO";
            obs.userAlteracao = vm.account.login;
            PlanoEnsinoObs.update(obs, function(data) {
                for (var o = 0; o < vm.listaObs.length; o++) {
                    var e = vm.listaObs[o];
                    if (obs.id == e.id) {
                        vm.listaObs.splice(o, 1);
                    }
                }
                vm.listaObs.push(data);
            });
        }

        function salvarPlano() {
            var valida = validaCampos();
            var etapaAnt = vm.plano.etapa;

            if (valida == "OK") {

                if (vm.plano.etapa == "CORRECAOC") {
                    vm.plano.etapa = "PENDENTE";
                }

                if (vm.plano.etapa == "CORRECAON") {
                    vm.plano.etapa = "APROVADOC";
                }

                PlanoEnsino.update(vm.plano);
                insereLog({
                    idPlano: vm.plano.id,
                    item: "PLANO",
                    operacao: "UPDATE",
                    valor: vm.plano.etapa,
                    valorAnt: etapaAnt,
                    user: vm.account.login
                });
                updateCampos();
            } else {
                showMessage("alert alert-danger", valida);
            }
        }

        function removeLivro(tipo, item) {
            switch (tipo) {
                case "BASICA":
                    for (var i = 0; i < vm.bBasica.length; i++) {
                        var e = vm.bBasica[i];
                        if (e.id == item.id) {
                            vm.bBasica.splice(i, 1);
                        }
                    }
                    break;
                case "COMPLEMENTAR":
                    for (var i = 0; i < vm.bCompl.length; i++) {
                        var e = vm.bCompl[i];
                        if (e.id == item.id) {
                            vm.bCompl.splice(i, 1);
                        }
                    }
                    break;
            }

            PlanoEnsinoBibliografia.delete({ id: item.id });
        }


        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function updateCampos() {

            var valorAnt = "";
            var achou = false;

            //console.log(vm.listaAlt);
            //console.log(vm.itensUpdate);

            // CAMPOS ALTERADOS
            for (var i = 0; i < vm.listaAlt.length; i++) {
                var e = vm.listaAlt[i];
                achou = false;

                // CAMPOS ITENS
                if (vm.itensUpdate.length > 0) {

                    for (var j = 0; j < vm.itensUpdate.length; j++) {
                        var a = vm.itensUpdate[j];
                        // se achou salva o resource itens

                        if (a.tipo == e.campo) {
                            achou = true;
                            valorAnt = a.texto;
                            a.texto = e.valor;
                            a.userAlteracao = vm.account.login;
                            PlanoEnsinoItens.update(a);
                        }

                        if (achou == false) {
                            switch (e.campo) {
                                case 'CHPRATICA':
                                    achou = true;
                                    valorAnt = vm.temp.chPratica;
                                    break;
                                case 'CHPTEORICA':
                                    achou = true;
                                    valorAnt = vm.temp.chTorica;
                                    break;
                                case 'CHEXTENSAO':
                                    achou = true;
                                    valorAnt = vm.temp.chExtensao;
                                    break;
                                case 'CHAS':
                                    achou = true;
                                    valorAnt = vm.temp.chAs;
                                    break;
                                case 'CHPRESENCIAL':
                                    achou = true;
                                    valorAnt = vm.temp.chPresencial;
                                    break;
                                case 'CHEAD':
                                    achou = true;
                                    valorAnt = vm.temp.chEad;
                                    break;
                                case 'CHONLINE':
                                    achou = true;
                                    valorAnt = vm.temp.chOnline;
                                    break;
                                case 'CHTOTAL':
                                    achou = true;
                                    valorAnt = vm.temp.chTotal;
                                    break;
                                case 'PONTAS1':
                                    achou = true;
                                    valorAnt = vm.temp.pontAs1;
                                    break;
                                case 'PONTAS2':
                                    achou = true;
                                    valorAnt = vm.temp.pontAs2;
                                    break;
                                case 'PONTAB1':
                                    achou = true;
                                    valorAnt = vm.temp.pontAb1;
                                    break;
                                case 'PONTAB2':
                                    achou = true;
                                    valorAnt = vm.temp.pontAb2;
                                    break;
                                case 'PONTTRAB1':
                                    achou = true;
                                    valorAnt = vm.temp.pontTrab1;
                                    break;
                                case 'PONTTRAB2':
                                    achou = true;
                                    valorAnt = vm.temp.pontTrab2;
                                    break;
                            }
                        }
                    }

                    if (achou == false) {
                        var item = {
                            id: null,
                            tipo: e.campo,
                            idPlano: vm.temp.id,
                            texto: e.valor,
                            userAlteracao: vm.account.login,
                            userCriacao: vm.account.login,
                            dataCriacao: new Date(),
                            dataAlteracao: new Date()
                        };

                        PlanoEnsinoItens.save(item);
                    }
                } else {
                    console.log("sem o campo vamos criar");
                    var item = {
                        id: null,
                        tipo: e.campo,
                        idPlano: vm.temp.id,
                        texto: e.valor,
                        userAlteracao: vm.account.login,
                        userCriacao: vm.account.login,
                        dataCriacao: new Date(),
                        dataAlteracao: new Date()
                    };
                    PlanoEnsinoItens.save(item);
                }

                // AQUI TEM Q TER O LOG POR QUE 
                insereLog({
                    idPlano: e.plano,
                    item: e.campo,
                    operacao: "UPDATE",
                    valor: e.valor,
                    valorAnt: valorAnt,
                    user: vm.account.login
                });

            }

            $state.go('plano-de-ensino');
        }


        /*function updateCampos() {

            var valorAnt = "";
            var achou = false;

            // CAMPOS ALTERADOS
            for (var i = 0; i < vm.listaAlt.length; i++) {
                var e = vm.listaAlt[i];

                // CAMPOS ITENS
                for (var j = 0; j < vm.itensUpdate.length; j++) {
                    var a = vm.itensUpdate[j];
                    // se achou salva o resource itens
                    if (a.tipo == e.campo) {
                        achou = true;
                        valorAnt = a.texto;
                        a.texto = e.valor;
                        a.userAlteracao = vm.account.login;
                        PlanoEnsinoItens.update(a);
                    }
                }

                if (achou == false) {
                    switch (e.campo) {
                        case 'CHPRATICA':
                            valorAnt = vm.temp.chPratica;
                            break;
                        case 'CHPTEORICA':
                            valorAnt = vm.temp.chTorica;
                            break;
                        case 'CHEXTENSAO':
                            valorAnt = vm.temp.chExtensao;
                            break;
                        case 'CHAS':
                            valorAnt = vm.temp.chAs;
                            break;
                        case 'CHPRESENCIAL':
                            valorAnt = vm.temp.chPresencial;
                            break;
                        case 'CHEAD':
                            valorAnt = vm.temp.chEad;
                            break;
                        case 'CHONLINE':
                            valorAnt = vm.temp.chOnline;
                            break;
                        case 'CHTOTAL':
                            valorAnt = vm.temp.chTotal;
                            break;
                        case 'PONTAS1':
                            valorAnt = vm.temp.pontAs1;
                            break;
                        case 'PONTAS2':
                            valorAnt = vm.temp.pontAs2;
                            break;
                        case 'PONTAB1':
                            valorAnt = vm.temp.pontAb1;
                            break;
                        case 'PONTAB2':
                            valorAnt = vm.temp.pontAb2;
                            break;
                        case 'PONTTRAB1':
                            valorAnt = vm.temp.pontTrab1;
                            break;
                        case 'PONTTRAB2':
                            valorAnt = vm.temp.pontTrab2;
                            break;
                    }
                }

                // AQUI TEM Q TER O LOG POR QUE 
                insereLog({
                    idPlano: e.plano,
                    item: e.campo,
                    operacao: "UPDATE",
                    valor: e.valor,
                    valorAnt: valorAnt,
                    user: vm.account.login
                });

            }

            $state.go('plano-de-ensino');
        }*/

        function insereLog(log) {
            PlanoEnsinoLog.save(log);
        }

        function validaCampos() {

            if (vm.plano.professor == null) {
                return "O campo NOME PROFESSOR está incorreto";
            }
            if (vm.plano.chPratica == null) {
                document.getElementById('CHPRATICA').style.backgroundColor = 'yellow';
                document.getElementById('CHPRATICA').focus();
                return "O campo CH PRATICA está incorreto";
            }
            if (vm.plano.chTeorica == null) {
                document.getElementById('CHTEORICA').style.backgroundColor = 'yellow';
                document.getElementById('CHTEORICA').focus();
                return "O campo CH TEÓRICA está incorreto";
            }
            if (vm.plano.chExtensao == null) {
                document.getElementById('CHEXTENSAO').style.backgroundColor = 'yellow';
                document.getElementById('CHEXTENSAO').focus();
                return "O campo CH EXTENSÃO está incorreto";
            }
            if (vm.plano.chAs == null) {
                document.getElementById('CHAS').style.backgroundColor = 'yellow';
                document.getElementById('CHAS').focus();
                return "O campo CH AS está incorreto";
            }
            if (vm.plano.chPresencial == null) {
                document.getElementById('CHPRESENCIAL').style.backgroundColor = 'yellow';
                document.getElementById('CHPRESENCIAL').focus();
                return "O campo CH PRESENCIAL está incorreto";
            }
            if (vm.plano.chOnline == null) {
                document.getElementById('CHONLINE').style.backgroundColor = 'yellow';
                document.getElementById('CHONLINE').focus();
                return "O campo CH ONLINE está incorreto";
            }
            if (vm.plano.chEad == null) {
                document.getElementById('CHEAD').style.backgroundColor = 'yellow';
                document.getElementById('CHEAD').focus();
                return "O campo CH EAD está incorreto";
            }
            if (vm.plano.chTotal == null) {
                document.getElementById('CHTOTAL').style.backgroundColor = 'yellow';
                document.getElementById('CHTOTAL').focus();
                return "O campo CH TOTAL está incorreto";
            }
            if (vm.plano.ementa == null || vm.plano.ementa == "") {
                document.getElementById('EMENTA').style.backgroundColor = 'yellow';
                document.getElementById('EMENTA').focus();
                return "O campo EMENTA está incorreto";
            }
            if (vm.plano.competencias == null || vm.plano.competencias == "") {
                document.getElementById('COMPETENCIA').style.backgroundColor = 'yellow';
                document.getElementById('COMPETENCIA').focus();
                return "O campo COMPETÊNCIA está incorreto";
            }

            /*if (vm.plano.contAs1 == null || vm.plano.contAs1 == "") {
                document.getElementById('AS1').style.backgroundColor = 'yellow';
                document.getElementById('AS1').focus();
                return "O campo ATIVIDADES SUPERVISIONADAS 1 está incorreto";
            }
            if (vm.plano.contAs2 == null || vm.plano.contAs2 == "") {
                document.getElementById('AS2').style.backgroundColor = 'yellow';
                document.getElementById('AS2').focus();
                return "O campo ATIVIDADES SUPERVISIONADAS2 está incorreto";
            }*/
            if (vm.plano.inst1b == null || vm.plano.inst1b == "") {
                document.getElementById('ISNTAVA1B').style.backgroundColor = 'yellow';
                document.getElementById('INSTAVA1B').focus();
                return "O campo INSTRUMENTOS AVALIATIVOS 1° BIMESTRE está incorreto";
            }
            if (vm.plano.inst2b == null || vm.plano.inst2b == "") {
                document.getElementById('INSTAVA2B').style.backgroundColor = 'yellow';
                document.getElementById('INSTAVA2B').focus();
                return "O campo INSTRUMENTOS AVALIATIVOS 2° BIMESTRE está incorreto";
            }

            /*if (vm.plano.pontAs1 == null) {
                document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                document.getElementById('PONTAS1').focus();
                return "O campo PONTUAÇÃO AS 1° BIMESTRE está incorreto";
            }
            if (vm.plano.pontAs2 == null) {
                document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                document.getElementById('PONTAS2').focus();
                return "O campo PONTUAÇÃO AS 2° BIMESTRE está incorreto";
            }

            if (vm.plano.pontAb1 == null) {
                document.getElementById('PONTAB1').style.backgroundColor = 'yellow';
                document.getElementById('PONTAB1').focus();
                return "O campo PONTUAÇÂO AVALIAÇÂO 1° BIMESTRE está incorreto";
            }

            if (vm.plano.pontAb2 == null) {
                document.getElementById('PONTAB2').style.backgroundColor = 'yellow';
                document.getElementById('PONTAB2').focus();
                return "O campo PONTUAÇÂO AVALIAÇÂO 2° BIMESTRE está incorreto";
            }


            if (vm.plano.pontTrab1 == null) {
                document.getElementById('PONTTRAB1').style.backgroundColor = 'yellow';
                document.getElementById('PONTTRAB1').focus();
                return "O campo PONTUAÇÃO DE TRABALHOS 1° BIMESTRE está incorreto";
            }

            if (vm.plano.pontTrab2 == null) {
                document.getElementById('PONTTRAB2').style.backgroundColor = 'yellow';
                document.getElementById('PONTTRAB2').focus();
                return "O campo PONTUAÇÃO DE TRABALHOS 1° BIMESTRE está incorreto";
            }

            var bim1 = vm.plano.pontAb1 + vm.plano.pontAs1 + vm.plano.pontTrab1;
            var bim2 = vm.plano.pontAb2 + vm.plano.pontAs2 + vm.plano.pontTrab2;
            var as = vm.plano.pontAs1 + vm.plano.pontAs2;

            if (as > vm.limiteAs) {
                document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                return "A soma da Pontuação das AS( " + as + " ) ultrapassa o limite da Carga Horária: (" + vm.limiteAs + ").";
            }

            if (bim1 != 100) {
                document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                document.getElementById('PONTAB1').style.backgroundColor = 'yellow';
                document.getElementById('PONTTRAB1').style.backgroundColor = 'yellow';
                return "A soma da Pontuação do 1° Bimestre( " + bim1 + " ) está diferente de 100";
            }

            if (bim2 != 100) {
                document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                document.getElementById('PONTAB2').style.backgroundColor = 'yellow';
                document.getElementById('PONTTRAB2').style.backgroundColor = 'yellow';
                return "A soma da Pontuação do 2° Bimestre( " + bim2 + " ) está diferente de 100";
            }*/

            if (vm.plano.cont1  ==  null   ||   vm.plano.cont1  ==  '')   {
                document.getElementById('CONT1').style.backgroundColor = 'yellow';
                document.getElementById('CONT1').focus();
                return  'O campo CONTEÚDO PREVISTO 1 está incorreto'; 
            };
            if (vm.plano.met1  ==  null  ||  vm.plano.met1   ==   '')   { 
                document.getElementById('MET1').style.backgroundColor = 'yellow';
                document.getElementById('MET1').focus(); 
                return  'O campo METODOLOGIA PREVISTA 1 está incorreto'; 
            };

            if (vm.plano.bBasica  ==  null  ||  vm.plano.bBasica ==   '')   { 
                document.getElementById('BBASICA').style.backgroundColor = 'yellow';
                document.getElementById('BBASICA').focus(); 
                return  'O campo Bibliografia Básica está incorreto'; 
            };

            if (vm.plano.bCompl ==  null  ||  vm.plano.bCompl ==   '')   { 
                document.getElementById('BCOMPL').style.backgroundColor = 'yellow';
                document.getElementById('BCOMPL').focus(); 
                return  'O campo Bibliografia Complementar está incorreto'; 
            };

            if (vm.plano.bApro ==  null  ||  vm.plano.bApro ==   '')   { 
                document.getElementById('BAPRO').style.backgroundColor = 'yellow';
                document.getElementById('BAPRO').focus(); 
                return  'O campo Bibliografia de Aprofundamento está incorreto'; 
            };

            for (var i = 0; i < vm.listaObs.length; i++) {
                var e = vm.listaObs[i];

                if (e.etapa != "FINALIZADO") {
                    return 'Existem observações não finalizadas na lista. clique em <span style="color: lightseagreen;" class="glyphicon glyphicon-ok"></span> para finalizá-las';
                }

            }

            return "OK";
        }

    }
})();