(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ValidaInscricaoPlayService', ValidaInscricaoPlayService);

    ValidaInscricaoPlayService.$inject = ['$resource', 'DateUtils'];

    function ValidaInscricaoPlayService($resource, DateUtils) {
        var resourceUrl = 'api/inscricaos/valida/:email/:telefone';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }
})();