(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('CursoCordenadorDeleteController',CursoCordenadorDeleteController);

    CursoCordenadorDeleteController.$inject = ['$uibModalInstance', 'entity', 'CursoCordenador'];

    function CursoCordenadorDeleteController($uibModalInstance, entity, CursoCordenador) {
        var vm = this;

        vm.cursoCordenador = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CursoCordenador.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
