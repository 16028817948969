(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaDiplomaTermoController', MatriculaDiplomaTermoController);

        MatriculaDiplomaTermoController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$http', '$sessionStorage', 'MatriculaDiplomaService'];

    function MatriculaDiplomaTermoController($timeout, $scope, $state, $stateParams, $http, $sessionStorage, MatriculaDiplomaService) {
        var vm = this;

        vm.avancar = avancar;
        vm.matricula = JSON.parse(sessionStorage.getItem('user-matric-diploma'));

        vm.load = false;

        function avancar() {
            vm.load = true;
            save();
        }

        function save() {
            MatriculaDiplomaService.save(
                vm.matricula,
                function(data) {
                    vm.load = false;
                    sessionStorage.setItem('user-matric-diploma', JSON.stringify(data));
                    $state.go('matriculaDiplomaBoleto');
                },
                function(status) {
                    vm.load = false;
                    console.log(status);
                }
            );
        }
    }
})();