(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('CalculaNotaEnemController', CalculaNotaEnemController);

    CalculaNotaEnemController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$http', 'SalvaLeadCalculaNotaEnem'];

    function CalculaNotaEnemController($scope, Principal, LoginService, $state, $http, SalvaLeadCalculaNotaEnem) {
        var vm = this;

        vm.calcula = {};
        vm.salvar = salvar;
        vm.voltar = voltar;
        vm.tela = 0;

        function salvar() {
            var a = vm.calcula;
            a.total = a.nota1 + a.nota2 + a.nota3 + a.nota4 + a.nota5;
            a.enem = Math.round(a.total / 5);
            vm.calcula = a;
            vm.tela = 1;
            SalvaLeadCalculaNotaEnem.send(vm.calcula);
        }

        function voltar() {
            vm.tela = 0;
        }
    }
})();