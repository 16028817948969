(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('QuestaoProvaService', QuestaoProvaService);

    QuestaoProvaService.$inject = ['$resource', 'DateUtils'];

    function QuestaoProvaService($resource, DateUtils) {
        var resourceUrl = '/api/busca-questao-prova-online';

        return $resource(resourceUrl, {}, {
            'query': { method: 'post', isArray: true }
        });
    }
})();