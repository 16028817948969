(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlayCandidatoDeleteController',PlayCandidatoDeleteController);

    PlayCandidatoDeleteController.$inject = ['$uibModalInstance', 'entity', 'PlayCandidato'];

    function PlayCandidatoDeleteController($uibModalInstance, entity, PlayCandidato) {
        var vm = this;

        vm.playCandidato = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PlayCandidato.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
