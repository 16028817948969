(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('PSAtivos', PSAtivos);

        PSAtivos.$inject = ['$resource'];

    function PSAtivos($resource) {
        var resourceUrl = 'api/processos-ativos';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true }
        });
    }
})();