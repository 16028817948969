(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlayController', PlayController);

    PlayController.$inject = ['$timeout', '$scope', '$stateParams', '$http', '$state', '$sessionStorage'];

    function PlayController($timeout, $scope, $stateParams, $http, $state, $sessionStorage) {

        $state.go('home-tradicional');

        var vm = this;

        // functions
        vm.validate = validate;
        vm.avancar = avancar;


        function validate() {

            vm.obj = {};

            if (vm.play.confirma_dados == 'EMAIL') {
                vm.cons = {
                    'codigo': vm.play.codigo,
                    'email': vm.play.email,
                    'data_nascimento': null,
                    'celular': null,
                    'cpf': null
                }
            }

            if (vm.play.confirma_dados == 'CELULAR') {
                vm.cons = {
                    'codigo': vm.play.codigo,
                    'email': null,
                    'data_nascimento': null,
                    'celular': vm.play.celular,
                    'cpf': null
                }
            }

            if (vm.play.confirma_dados == 'DATA_NASCIMENTO') {
                vm.cons = {
                    'codigo': vm.play.codigo,
                    'email': null,
                    'data_nascimento': vm.play.data_nascimento,
                    'celular': null,
                    'cpf': null
                }
            }

            if (vm.play.confirma_dados == 'CPF') {
                vm.cons = {
                    'codigo': vm.play.codigo,
                    'email': null,
                    'data_nascimento': null,
                    'celular': null,
                    'cpf': vm.play.cpf
                }
            }

            $http.post("/api/play", vm.cons)
                .success(function(data) {
                    vm.participante = data;
                    avancar();
                })
                .error(function(status) {
                    console.log(status);
                });
        }


        function avancar() {
            sessionStorage.setItem('candidato-vestibular-2018', JSON.stringify(vm.participante));

            $state.go('inscricao-tradicional2018');
        }
    }
})();