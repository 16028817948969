(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('CursoTradicionalGeral', CursoTradicionalGeral);

    CursoTradicionalGeral.$inject = ['$resource'];

    function CursoTradicionalGeral($resource) {
        var resourceUrl = 'api/cursosTradicionalGeral';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true }
        });
    }
})();