(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('CursoPoloSave', CursoPoloSave);

    CursoPoloSave.$inject = ['$resource', 'DateUtils'];

    function CursoPoloSave($resource, DateUtils) {
        var resourceUrl = 'api/cursosPorPolo/save/:idPolo/:idCurso';

        return $resource(resourceUrl, {}, {
            'save': { method: 'GET', isArray: true }
        });
    }
})();