(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ContratoClausulaDialogController', ContratoClausulaDialogController);

    ContratoClausulaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ContratoClausula', 'PSAtivos', 'Principal'];

    function ContratoClausulaDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ContratoClausula, PSAtivos, Principal) {
        var vm = this;

        vm.contratoClausula = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
             
        Principal.identity().then(function(account) {
            vm.account = account;
            vm.isAuthenticated = Principal.isAuthenticated;
        });
               
        PSAtivos.query(function(result) {
            vm.processos = result;
        }, function(status) {
            console.log(status);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.contratoClausula.dataAlteracao = new Date();
            vm.contratoClausula.usuarioAlteracao = vm.account.login;
            if (vm.contratoClausula.id !== null) {
                ContratoClausula.update(vm.contratoClausula, onSaveSuccess, onSaveError);
            } else {
                vm.contratoClausula.data_criacao = new Date();
                vm.contratoClausula.usuarioCriacao = vm.account.login;
                ContratoClausula.save(vm.contratoClausula, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('vestibularApp:contratoClausulaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.data_criacao = false;
        vm.datePickerOpenStatus.dataAlteracao = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
