(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RematriculaPapController', RematriculaPapController);

    RematriculaPapController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'LoginService', '$http', '$sessionStorage', 'RematriculaPapService', 'RematriculaSaveParcelasService', 'RematriculaDadosCadastraisServiceController', 'RematriculaPapFinalService', 'CancelaPapEmailService'];

    function RematriculaPapController($timeout, $scope, $state, $stateParams, LoginService, $http, $sessionStorage, RematriculaPapService, RematriculaSaveParcelasService, RematriculaDadosCadastraisServiceController, RematriculaPapFinalService, CancelaPapEmailService) {
        var vm = this;

        vm.academico = JSON.parse(sessionStorage.getItem('dados-academico'));

        switch (vm.academico.turno) {
            case "1":
                vm.turnoAcademico = "Matutino";
                break;
            case "2":
                vm.turnoAcademico = "Vespertino";
                break;
            case "3":
                vm.turnoAcademico = "Noturno";
                break;

            default:
                vm.turnoAcademico = "EAD";
                break;
        }

        vm.load = true;

        vm.avancar = avancar;
        vm.cancelaPap = cancelaPap;
        vm.cancelamento = false;
        vm.anoAtual = new Date().getFullYear();


        /*BLOCO DE CAPTURA DO MES ATUAL PARA INSERÇÃO DE SEMESTRE NO TERMO*/
        vm.mesAtual = new Date().getMonth();
        if (vm.mesAtual >= 3 && vm.mesAtual <= 8) {
            vm.periodoNovo = 2;
        } else {
            vm.periodoNovo = 1;
            vm.anoAtual++;
        }
        /* FIM DO BLOCO DO MES ATUAL*/

        RematriculaPapService.query({ cpf: vm.academico.cpf },
            function(data) {

                vm.papVencimentoDB = update(data);

                vm.bolsa = vm.papVencimentoDB[0].bolsa;
                if (vm.bolsa) {
                    vm.percpgtobols = vm.papVencimentoDB[0].percentual_pgto_bolsa;
                    vm.percpgtobols_extenso = vm.papVencimentoDB[0].percentual_pgto_bolsa_extenso;
                }
                vm.perletivoOriginal = vm.papVencimentoDB[0].perLetivoOriginal;
                vm.cod_contrato = vm.papVencimentoDB[0].cod_contrato;
                vm.cod_contrato_pap = vm.papVencimentoDB[0].cod_contrato_pap.substring(0, vm.papVencimentoDB[0].cod_contrato_pap.indexOf("."));
                vm.cod_termo_pap = vm.papVencimentoDB[0].cod_termo_pap;
                vm.percent_atual = vm.papVencimentoDB[0].percentual_pap;
                vm.percent_futuro = vm.papVencimentoDB[0].percentual_pap_futuro;
                vm.percent_atual_extenso = vm.papVencimentoDB[0].percentual_pap_extenso;
                vm.percent_futuro_extenso = vm.papVencimentoDB[0].percentual_pap_futuro_extenso;

                vm.percent_bolsa_1 = vm.papVencimentoDB[0].percentual_bolsa_1;
                vm.percent_bolsa_2 = vm.papVencimentoDB[0].percentual_bolsa_2;
                vm.percent_bolsa_3 = vm.papVencimentoDB[0].percentual_bolsa_3;
                vm.percent_bolsa_4 = vm.papVencimentoDB[0].percentual_bolsa_4;

                vm.percent_bolsa_extenso_1 = vm.papVencimentoDB[0].percentual_bolsa_extenso_1;
                vm.percent_bolsa_extenso_2 = vm.papVencimentoDB[0].percentual_bolsa_extenso_2;
                vm.percent_bolsa_extenso_3 = vm.papVencimentoDB[0].percentual_bolsa_extenso_3;
                vm.percent_bolsa_extenso_4 = vm.papVencimentoDB[0].percentual_bolsa_extenso_4;

                vm.descricao_bolsa_1 = vm.papVencimentoDB[0].descricao_bolsa_1;
                vm.descricao_bolsa_2 = vm.papVencimentoDB[0].descricao_bolsa_2;
                vm.descricao_bolsa_3 = vm.papVencimentoDB[0].descricao_bolsa_3;
                vm.descricao_bolsa_4 = vm.papVencimentoDB[0].descricao_bolsa_4;

                vm.load = false;
            },
            function(result) {
                console.log(result);
            });

        Number.prototype.format = function(n, x) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
            return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
        };

        function update(lista) {
            for (var i = 0; i < lista.length; i++) {
                lista[i].valor = lista[i].valor.format(2).replace(".", ",");
            }
            return lista;
        }

        function cancelaPap() {
            var r = confirm("Desistir do PAP criará seu plano de pagamento baseado nas parcelas originais previstas na CLÁUSULA PRIMEIRA do termo de renovação.  Deseja realmente prosseguir ?");
            if (r) {
                vm.cancelamento = true;
                CancelaPapEmailService.query(vm.academico,
                    function(data) {},
                    function(result) {

                    });

                vm.load = true;
                var agora = new Date();

                if (vm.academico.data_rematricula == null) {
                    vm.academico.data_rematricula = agora;
                }
                if (vm.academico.dt_contrato == null) {
                    vm.academico.dt_contrato = agora;
                }
                if (vm.academico.dt_termo == null) {
                    vm.academico.dt_termo = agora;
                }
                vm.academico.status = "PAPCANCFINAL";

                RematriculaDadosCadastraisServiceController.save(vm.academico,
                    function(data) {
                        vm.load = false
                        if (!vm.cancelamento) {
                            //$state.go('rematriculaPapFinal');
                            $state.go('rematriculaBoleto');
                        } else {
                            $state.go('rematriculaBoleto');
                        }
                    },
                    function(result) {
                        console.log(result);
                    });

            } else {
                alert("Desistência Cancelada");
            }
        }



        function avancar() {
            vm.load = true;
            var agora = new Date();
            if (vm.academico.data_rematricula == null) {
                vm.academico.data_rematricula = agora;
            }
            if (vm.academico.dt_contrato == null) {
                vm.academico.dt_contrato = agora;
            }
            if (vm.academico.dt_termo == null) {
                vm.academico.dt_termo = agora;
            }
            vm.academico.status = "FINALIZADO";

            RematriculaPapFinalService.save(vm.academico,
                function(data) {
                    vm.load = false

                    if (!vm.cancelamento) {
                        $state.go('rematriculaBoleto');
                    } else {
                        $state.go('rematriculaBoleto');
                    }
                },
                function(result) {
                    console.log(result);
                });

            /*RematriculaDadosCadastraisServiceController.save(vm.academico,
                function(data) {
                },
                function(result) {
                    console.log(result);
                });*/
        }
    }
})();