(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ValoresCurso', ValoresCurso);

    ValoresCurso.$inject = ['$resource', 'DateUtils'];

    function ValoresCurso($resource, DateUtils) {
        var resourceUrl = 'api/valores-cursos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        //data.dataValidade = DateUtils.convertLocalDateFromServer(data.dataValidade);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    //copy.dataValidade = DateUtils.convertLocalDateToServer(copy.dataValidade);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    //copy.dataValidade = DateUtils.convertLocalDateToServer(copy.dataValidade);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();