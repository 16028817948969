(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('ResultadoVestibularOnlineService', ResultadoVestibularOnlineService);

    ResultadoVestibularOnlineService.$inject = ['$uibModal', '$rootScope'];

    function ResultadoVestibularOnlineService($uibModal, $rootScope) {
        var service = {
            open: open
        };

        var modalInstance = null;
        var retorno = null;

        return service;

        function open(resultado) {
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/resultado-vestibular/resultado.html',
                controller: 'ResultadoVestibularOnlineController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    param: function() {
                        return { 'data': resultado };
                    }
                }
            });
            /*modalInstance.result.then(function(selectedItem) {
                retorno = selectedItem;
                modalInstance = null;
            }, function() {
                modalInstance = null;
            });*/
        }
    }
})();