(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('GameCheckController', GameCheckController);

    GameCheckController.$inject = ['$timeout', 'Principal', 'SetGameCheckService', '$uibModalInstance', 'param'];

    function GameCheckController($timeout, Principal, SetGameCheckService, $uibModalInstance, param) {
        var vm = this;

        vm.confirm = confirm;
        vm.fechar = fechar;
        vm.salvar = salvar;
        vm.inscricao = param.data;

        function confirm() {
            $uibModalInstance.close(null);
        }

        function fechar() {
            $uibModalInstance.close(null);
        }


        function salvar() {

            if (vm.estande == null) {
                alert('Nenum código Válido Informado');
            } else {
                var send = {
                    codigoOficina: vm.estande,
                    codigoInscricao: vm.inscricao,
                    dataCriacao: new Date(),
                    usuarioCriacao: vm.inscricao
                }
                SetGameCheckService.query(send,
                    function(data) {
                        fechar();
                    },
                    function(status) {
                        console.log(status);
                        fechar();
                    });

            }
        }

        jQuery(document).on('keydown', 'input.insc', function(ev) {
            if (ev.which === 13) {
                salvar();
            }
        });
    }
})();