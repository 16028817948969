(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlanoEnsinoDialogController', PlanoEnsinoDialogController);

    PlanoEnsinoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PlanoEnsino'];

    function PlanoEnsinoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PlanoEnsino) {
        var vm = this;

        vm.planoEnsino = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.planoEnsino.id !== null) {
                PlanoEnsino.update(vm.planoEnsino, onSaveSuccess, onSaveError);
            } else {
                PlanoEnsino.save(vm.planoEnsino, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('vestibularApp:planoEnsinoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataCriacao = false;
        vm.datePickerOpenStatus.dataAlteracao = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
