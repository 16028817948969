(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaBoletoController', MatriculaBoletoController);

    MatriculaBoletoController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'Curso', 'LoginService', '$http', '$sessionStorage', 'Financeiro', 'ConsultaFinanceiroAberto'];

    function MatriculaBoletoController($timeout, $scope, $state, $stateParams, Curso, LoginService, $http, $sessionStorage, Financeiro, ConsultaFinanceiroAberto) {
        var vm = this;

        //vm.matricula = JSON.parse(sessionStorage.getItem('dados-matricula-contrato'));
        vm.matricula = null;
        vm.load = false;
        vm.gerarBoleto = gerarBoleto;
        vm.gerarContrato = gerarContrato;
        vm.gerarPgto = gerarPgto;
        vm.gerarPix = gerarPix;
        vm.startCountdown = startCountdown;
        vm.liberado = true;
        vm.pgtos = null;

        vm.pixGerado = false;
        vm.pagarPix = false;

        vm.tempo = new Number();
        vm.tempo = 120;

        init();

        function init() {
            loadDadosCandidato();
        }


        function loadDadosCandidato() {
            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", vm.resultadoRM)
                .success(function(data) {
                    vm.matricula = data;
                    console.log(vm.matricula);
                    // vamos consultar se existe algum pagamento para esta matrícula;
                    LoadFinanceiro();
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function LoadFinanceiro() {
            ConsultaFinanceiroAberto.query(vm.matricula,
                function(result) {
                    vm.pgtos = result;
                    console.log(result);
                    for (var i = 0; i < result.length; i++) {
                        var e = result[i];

                        if (e.statusCielo == 2 || e.statusCielo == 7 || e.statusCielo == 1 || e.statusCielo == 0 || e.statusCielo == 10) {
                            vm.liberado = false
                        }
                    }
                });
        }

        function gerarBoleto() {

            vm.load = true;

            //RematriculaBoletoServiceController.save(vm.academico, onSaveSuccess, onSaveError);
            $http.post('api/pre-matricula-getboleto/', vm.matricula, { responseType: 'arraybuffer' })
                .success(function(data) {

                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    a.href = fileURL;
                    a.download = vm.matricula.nome + " - Boleto.pdf";
                    a.click();

                    window.URL.revokeObjectURL(fileURL);

                    //var newWindow = window.open();
                    //newWindow.document.write('<iframe src="' + fileURL + '" frameborder="0" allowfullscreen></iframe>');
                    //newWindow.document.title = "Univel - Download Boleto em PDF";

                    //var file = new Blob([(data)], { type: 'application/pdf' });
                    //var fileURL = URL.createObjectURL(file);
                    //window.location.replace(fileURL);

                    vm.load = false;


                }).error(function(status) {
                    console.log(status);
                });;

        }

        function gerarContrato() {

            vm.load = true;

            $http.post('api/pre-matricula-getcontrato/', vm.matricula, { responseType: 'arraybuffer' })
                .success(function(data) {

                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    a.href = fileURL;
                    a.download = vm.matricula.nome + " - Contrato.pdf";
                    a.click();

                    window.URL.revokeObjectURL(fileURL);

                    vm.load = false;


                }).error(function(status) {
                    console.log(status);
                });;

        }

        function gerarPgto() {
            vm.liberado = false;
            $http.post("/api/createcheckout", vm.matricula)
                .success(function(result) {
                    console.log(result);

                    Financeiro.get({ id: result.orderNumber.substring(3) },
                        function(f) {

                            var a = result.settings.checkoutUrl.split("/");
                            var len = a.length;

                            f.idCheckOutCielo = a[len - 1];

                            Financeiro.update(f, function(result) {
                                //console.log(result);
                            }, function(status) {
                                console.log(status);
                            });
                        });


                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = result.settings.checkoutUrl;
                    a.click();
                    vm.load = false;
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function gerarPix() {
            vm.salvando = true;
            vm.pixGerado = "BEFORE";
            vm.pagarPix = true;

            console.log("iniciando processo de gerar qrcode");

            vm.salvando = false;
            $http.post("/api/createQrCode", vm.matricula)
                .success(function(result) {
                    vm.imgPix = result.data;
                    vm.pixGerado = "ON";
                    vm.salvando = false;
                    LoadFinanceiro();
                    startCountdown();
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        /* CONTADOR DO TEMPO DE PROVA */
        function startCountdown() {
            // Se o tempo não for zerado
            if (vm.tempo >= 1) {
                // Pega a parte inteira dos minutos
                var min = parseInt(vm.tempo / 60);
                var comp = ' minutos.';
                // Formata o número menor que dez, ex: 08, 07, ...
                if (min < 10) {
                    min = "0" + min;
                    min = min.substr(0, 2);
                }

                if (min == "01") {
                    comp = ' minuto.';
                }

                var horaImprimivel = min + comp;
                console.log(new Date() + "-" + horaImprimivel + "-" + vm.tempo);
                $("#tempoRestante").html(horaImprimivel);

                setTimeout(function() {
                    //salvarSessao();
                    $("#tempoRestante").html(horaImprimivel);
                    vm.tempo = (vm.tempo - 60);
                    console.log(vm.tempo);
                    startCountdown();
                }, 60000);

            } else {
                clearTimeout();
                clearInterval();
                console.log(new Date() + "-" + vm.tempo);
                location.reload();
            }
        }
    }
})();