(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('FinalProvaOnlineDetailController', FinalProvaOnlineDetailController);

    FinalProvaOnlineDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FinalProvaOnline'];

    function FinalProvaOnlineDetailController($scope, $rootScope, $stateParams, previousState, entity, FinalProvaOnline) {
        var vm = this;

        vm.finalProvaOnline = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:finalProvaOnlineUpdate', function(event, result) {
            vm.finalProvaOnline = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
