(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ProcessoSeletivoDialogController', ProcessoSeletivoDialogController);

    ProcessoSeletivoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProcessoSeletivo', 'Principal'];

    function ProcessoSeletivoDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, ProcessoSeletivo, Principal) {
        var vm = this;

        vm.processoSeletivo = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        console.log(vm.processoSeletivo);
        if (vm.processoSeletivo.ead) {
            vm.processoSeletivo.ead = "true";
        } else {
            vm.processoSeletivo.ead = "false";
        }

        if (vm.processoSeletivo.agendado) {
            vm.processoSeletivo.agendado = "true";
        } else {
            vm.processoSeletivo.agendado = "false";
        }

        if (vm.processoSeletivo.habilitado) {
            vm.processoSeletivo.habilitado = "true";
        } else {
            vm.processoSeletivo.habilitado = "false";
        }

        if (vm.processoSeletivo.habilitadoInscricao) {
            vm.processoSeletivo.habilitadoInscricao = "true";
        } else {
            vm.processoSeletivo.habilitadoInscricao = "false";
        }

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            //console.log(vm.processoSeletivo);
            vm.isSaving = true;
            vm.processoSeletivo.dataAlteracao = new Date();
            vm.processoSeletivo.usuarioAlteracao = vm.account.login;
            if (vm.processoSeletivo.id !== null) {
                ProcessoSeletivo.update(vm.processoSeletivo, onSaveSuccess, onSaveError);
            } else {
                vm.processoSeletivo.dataCriacao = new Date();
                vm.processoSeletivo.usuarioCriacao = vm.account.login;
                ProcessoSeletivo.save(vm.processoSeletivo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:processoSeletivoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataInicio = false;
        vm.datePickerOpenStatus.dataFinal = false;
        vm.datePickerOpenStatus.dataCriacao = false;
        vm.datePickerOpenStatus.dataAlteracao = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();