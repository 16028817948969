(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatrizTurmaDetailController', MatrizTurmaDetailController);

    MatrizTurmaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MatrizTurma'];

    function MatrizTurmaDetailController($scope, $rootScope, $stateParams, previousState, entity, MatrizTurma) {
        var vm = this;

        vm.matrizTurma = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:matrizTurmaUpdate', function(event, result) {
            vm.matrizTurma = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
