(function() {
    'use strict';


    angular.module('material.components.datepicker').config(["$provide", function($provide) {
        // TODO(jelbourn): Assert provided values are correctly formatted. Need assertions.

        //@constructor
        function DateLocaleProvider() {
            /** Array of full month names. E.g., ['January', 'February', ...] */
            this.months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

            /** Array of abbreviated month names. E.g., ['Jan', 'Feb', ...] */
            this.shortMonths = ['Jan', 'Fev', 'Mar', 'Abril', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

            /** Array of full day of the week names. E.g., ['Monday', 'Tuesday', ...] */
            this.days = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'];

            /** Array of abbreviated dat of the week names. E.g., ['M', 'T', ...] */
            this.shortDays = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

            /** Array of dates of a month (1 - 31). Characters might be different in some locales. */
            this.dates = null;

            /** Index of the first day of the week. 0 = Sunday, 1 = Monday, etc. */
            this.firstDayOfWeek = 1;

            /**
             * Function that converts the date portion of a Date to a string.
             * @type {(function(Date): string)}
             */
            this.formatDate = null;

            /**
             * Function that converts a date string to a Date object (the date portion)
             * @type {function(string): Date}
             */
            this.parseDate = null;

            /**
             * Function that formats a Date into a month header string.
             * @type {function(Date): string}
             */
            this.monthHeaderFormatter = null;

            /**
             * Function that formats a week number into a label for the week.
             * @type {function(number): string}
             */
            this.weekNumberFormatter = null;

            /**
             * Function that formats a date into a long aria-label that is read
             * when the focused date changes.
             * @type {function(Date): string}
             */
            this.longDateFormatter = null;

            /**
             * Function to determine whether a string makes sense to be
             * parsed to a Date object.
             * @type {function(string): boolean}
             */
            this.isDateComplete = null;

            /**
             * ARIA label for the calendar "dialog" used in the datepicker.
             * @type {string}
             */
            this.msgCalendar = '';

            /**
             * ARIA label for the datepicker's "Open calendar" buttons.
             * @type {string}
             */
            this.msgOpenCalendar = '';
        }

        /**
         * Factory function that returns an instance of the dateLocale service.
         * @ngInject
         * @param $locale
         * @param $filter
         * @returns {DateLocale}
         */
        DateLocaleProvider.prototype.$get = function($locale, $filter) {
            /**
             * Default date-to-string formatting function.
             * @param {!Date} date
             * @param {string=} timezone
             * @returns {string}
             */
            function defaultFormatDate(date, timezone) {
                if (!date) {
                    return '';
                }

                // All of the dates created through ng-material *should* be set to midnight.
                // If we encounter a date where the localeTime shows at 11pm instead of midnight,
                // we have run into an issue with DST where we need to increment the hour by one:
                // var d = new Date(1992, 9, 8, 0, 0, 0);
                // d.toLocaleString(); // == "10/7/1992, 11:00:00 PM"
                var localeTime = date.toLocaleTimeString();
                var formatDate = date;
                if (date.getHours() === 0 &&
                    (localeTime.indexOf('11:') !== -1 || localeTime.indexOf('23:') !== -1)) {
                    formatDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 1, 0, 0);
                }

                return $filter('date')(formatDate, 'dd/MM/yyyy', timezone);
            }

            /**
             * Default string-to-date parsing function.
             * @param {string|number} dateString
             * @returns {!Date}
             */
            function defaultParseDate(dateString) {
                return new Date(dateString);
            }

            /**
             * Default function to determine whether a string makes sense to be
             * parsed to a Date object.
             *
             * This is very permissive and is just a basic check to ensure that
             * things like single integers aren't able to be parsed into dates.
             * @param {string} dateString
             * @returns {boolean}
             */
            function defaultIsDateComplete(dateString) {
                dateString = dateString.trim();

                // Looks for three chunks of content (either numbers or text) separated
                // by delimiters.
                var re = /^(([a-zA-Z]{3,}|[0-9]{1,4})([ .,]+|[/-])){2}([a-zA-Z]{3,}|[0-9]{1,4})$/;
                return re.test(dateString);
            }

            /**
             * Default date-to-string formatter to get a month header.
             * @param {!Date} date
             * @returns {string}
             */
            function defaultMonthHeaderFormatter(date) {
                return service.shortMonths[date.getMonth()] + ' ' + date.getFullYear();
            }

            /**
             * Default formatter for a month.
             * @param {!Date} date
             * @returns {string}
             */
            function defaultMonthFormatter(date) {
                return service.months[date.getMonth()] + ' ' + date.getFullYear();
            }

            /**
             * Default week number formatter.
             * @param number
             * @returns {string}
             */
            function defaultWeekNumberFormatter(number) {
                return 'Week ' + number;
            }

            /**
             * Default formatter for date cell aria-labels.
             * @param {!Date} date
             * @returns {string}
             */
            function defaultLongDateFormatter(date) {
                // Example: 'Thursday June 18 2015'
                return [
                    service.days[date.getDay()],
                    service.months[date.getMonth()],
                    service.dates[date.getDate()],
                    date.getFullYear()
                ].join(' ');
            }

            // The default "short" day strings are the first character of each day,
            // e.g., "Monday" => "M".
            var defaultShortDays = $locale.DATETIME_FORMATS.SHORTDAY.map(function(day) {
                return day.substring(0, 1);
            });

            // The default dates are simply the numbers 1 through 31.
            var defaultDates = Array(32);
            for (var i = 1; i <= 31; i++) {
                defaultDates[i] = i;
            }

            // Default ARIA messages are in English (US).
            var defaultMsgCalendar = 'Calendário';
            var defaultMsgOpenCalendar = 'Abrir Calendário';

            // Default start/end dates that are rendered in the calendar.
            var today = new Date();
            var limit = today;
            limit.setDate(today.getDate() + 60);
            //console.log(limit);
            var defaultFirstRenderableDate = new Date(today.getFullYear() - 75, 0, 1);
            //var defaultLastRendereableDate = today.setDate(new Date().getDate() + 60);
            var defaultLastRendereableDate = new Date(limit.getFullYear(), limit.getMonth(), limit.getDate());

            var service = {
                months: this.months || $locale.DATETIME_FORMATS.MONTH,
                shortMonths: this.shortMonths || $locale.DATETIME_FORMATS.SHORTMONTH,
                days: this.days || $locale.DATETIME_FORMATS.DAY,
                shortDays: this.shortDays || defaultShortDays,
                dates: this.dates || defaultDates,
                firstDayOfWeek: this.firstDayOfWeek || 0,
                formatDate: this.formatDate || defaultFormatDate,
                parseDate: this.parseDate || defaultParseDate,
                isDateComplete: this.isDateComplete || defaultIsDateComplete,
                monthHeaderFormatter: this.monthHeaderFormatter || defaultMonthHeaderFormatter,
                monthFormatter: this.monthFormatter || defaultMonthFormatter,
                weekNumberFormatter: this.weekNumberFormatter || defaultWeekNumberFormatter,
                longDateFormatter: this.longDateFormatter || defaultLongDateFormatter,
                msgCalendar: this.msgCalendar || defaultMsgCalendar,
                msgOpenCalendar: this.msgOpenCalendar || defaultMsgOpenCalendar,
                firstRenderableDate: this.firstRenderableDate || defaultFirstRenderableDate,
                lastRenderableDate: this.lastRenderableDate || defaultLastRendereableDate
            };

            return service;
        };
        DateLocaleProvider.prototype.$get.$inject = ["$locale", "$filter"];

        $provide.provider('$mdDateLocale', new DateLocaleProvider());
    }]);
})();