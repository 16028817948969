(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoController', InscricaoController);

    InscricaoController.$inject = ['$scope', '$state', 'InscricaoLoginAdmService', 'Principal', '$http'];

    function InscricaoController($scope, $state, InscricaoLoginAdmService, Principal, $http) {
        var vm = this;

        vm.inscricaos = [];
        vm.idps = "63";
        vm.buscarInsc = loadAll;

        $http.post("api/get-modalidades-ativas-tradicional")
            .success(function(result) {
                vm.cursos = result;
                //console.log(vm.cursos);
                getAccount();
            })
            .error(function(status) {
                console.log(status);
            });

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.login = vm.account.login;
                vm.isAuthenticated = Principal.isAuthenticated;


                //loadAll();
                //console.log(vm.account);
            });
        }


        function loadAll() {
            InscricaoLoginAdmService.query({
                    login: vm.login,
                    idps: vm.idps
                },
                function(result) {
                    vm.inscricaos = result;
                    console.log(vm.inscricaos);

                },
                function(status) {
                    console.log(status);
                });
        }
    }
})();