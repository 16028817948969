(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RematriculaConsentimentoController', RematriculaConsentimentoController);

    RematriculaConsentimentoController.$inject = ['$state', 'RematriculaContratoServiceController', '$http'];

    function RematriculaConsentimentoController($state, RematriculaContratoServiceController, $http) {
        var vm = this;
        vm.avancar = avancar;
        vm.academico = JSON.parse(sessionStorage.getItem('dados-academico'));
        vm.load = false;
        vm.aceitei = false;
        vm.ip = null;
        vm.presencial = true;

        function avancar() {
            $http.post("/api/rematriculas-gera-termo-consentimento", vm.academico)
                .success(function(data) {
                    vm.load = true;
                    vm.academico.status = "TERMOCONSENTIMENTO";
                    sessionStorage.setItem('dados-academico', JSON.stringify(vm.academico));
                    RematriculaContratoServiceController.save(vm.academico, onSaveSuccess, onSaveError);
                }).error(function(status) {
                    console.log(status);
                });
        }

        function onSaveSuccess(result) {
            $state.go('rematriculaContrato');
        }

        function onSaveError(result) {
            vm.load = false;
            console.log(result);
        }
    }
})();