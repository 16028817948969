(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('InsertPropostaMatriculaDp', InsertPropostaMatriculaDp);

    InsertPropostaMatriculaDp.$inject = ['$resource', 'DateUtils'];

    function InsertPropostaMatriculaDp($resource, DateUtils) {
        var resourceUrl = 'api/insert-proposta-matric-dp';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true }
        });
    }
})();