(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RematriculaDadosCadastraisController', RematriculaDadosCadastraisController);

    RematriculaDadosCadastraisController.$inject = ['$rootScope', '$timeout', '$scope', '$state', '$stateParams', 'RematriculaDadosCadastraisServiceController', 'Curso', 'LoginService', '$http', '$sessionStorage', 'UpdatePPESSOAService', 'ModalImagemService'];

    function RematriculaDadosCadastraisController($rootScope, $timeout, $scope, $state, $stateParams, RematriculaDadosCadastraisServiceController, Curso, LoginService, $http, $sessionStorage, UpdatePPESSOAService, ModalImagemService) {
        var vm = this;

        //vm.inscricaoAgendado;
        vm.clear = clear;
        vm.salvar = save;
        vm.academico = JSON.parse(sessionStorage.getItem('dados-academico'));
        vm.getCEP = buscaCEP;
        vm.upload = upload;
        vm.imagemAlterada = false;

        $rootScope.$on('cropImage', function(event, value) {
            if (value != null && value != undefined) {
                vm.academico.foto = value.replace("data:image/png;base64,", "");
                vm.imagemAlterada = true;
            } else {

            }
        });

        function upload() {
            ModalImagemService.open();
        }

        function buscaCEP() {
            if (vm.academico.cep.length == 8) {
                $http({
                    method: 'GET',
                    url: 'http://api.postmon.com.br/v1/cep/' + vm.academico.cep
                }).then(function successCallback(response) {
                    vm.academico.uf = response.data.estado;
                    vm.academico.cidade = response.data.cidade;
                    vm.academico.bairro = response.data.bairro;
                    vm.academico.logradouro = response.data.logradouro;

                }, function errorCallback(response) {
                    //console.log("deu ruim.. " + response);
                });
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            if (vm.academico.email == null || vm.academico.email == undefined || vm.academico.email == "") {
                alert("Preencha o campo email!");
                return;
            }

            if (vm.academico.cep == null || vm.academico.cep == undefined || vm.academico.cep == "") {
                alert("Preencha o campo cep!");
                return;
            }

            if (vm.academico.logradouro == null || vm.academico.logradouro == undefined || vm.academico.logradouro == "") {
                alert("Preencha o campo logradouro!");
                return;
            }

            if (vm.academico.numero == null || vm.academico.numero == undefined || vm.academico.numero == "") {
                alert("Preencha o campo numero!");
                return;
            }

            if (vm.academico.telefone1 == null || vm.academico.telefone1 == undefined || vm.academico.telefone1 == "") {
                alert("Preencha o campo telefone 1!");
                return;
            }

            vm.academico.tipoMatricula = "VETERANO";
            vm.academico.pgtoOnline = "SIM";
            vm.academico.pgtoPix = "SIM";

            sessionStorage.setItem('dados-academico', JSON.stringify(vm.academico));

            UpdatePPESSOAService.query(vm.academico,
                function(data) {
                    vm.academico.status = "CONTRATO";
                    RematriculaDadosCadastraisServiceController.save(vm.academico,
                        function(data2) {
                            //$state.go('rematriculaContrato');
                            $state.go("rematricula-termo-consentimento");
                        },
                        function(result2) {
                            console.log(result2);
                        });
                },
                function(result) {
                    console.log(result);
                });
        }
    }
})();