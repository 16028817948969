(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RespostaProvaOnlineDeleteController',RespostaProvaOnlineDeleteController);

    RespostaProvaOnlineDeleteController.$inject = ['$uibModalInstance', 'entity', 'RespostaProvaOnline'];

    function RespostaProvaOnlineDeleteController($uibModalInstance, entity, RespostaProvaOnline) {
        var vm = this;

        vm.respostaProvaOnline = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RespostaProvaOnline.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
