(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlanoAulaDetailController', PlanoAulaDetailController);

    PlanoAulaDetailController.$inject = ['$scope', '$rootScope', '$state', 'previousState', 'entity', 'PlanoAula', 'PlanoAulaItens', 'BuscaItensPlanoAula', 'Principal'];

    function PlanoAulaDetailController($scope, $rootScope, $state, previousState, entity, PlanoAula, PlanoAulaItens, BuscaItensPlanoAula, Principal) {
        var vm = this;

        vm.planoAula = entity;
        console.log(vm.planoAula);
        vm.previousState = previousState.name;
        vm.refresh = atualizarDados;
        vm.salvarPlano = salvarPlano;
        vm.salvarItemPlano = salvarItemPlano;
        vm.deleteAula = deleteAula;

        vm.load = false;

        vm.listaSalvar = [];

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                console.log(vm.account);
                vm.etapa = 1;
                atualizarDados();
            });
        };

        function deleteAula(aula) {
            vm.msgLoad = "Salvando dados do plano alterado; \n Replicando para turmas espelhadas";
            vm.load = true;
            if (vm.listaSalvar.length > 0){
                for (var i = 0; i < vm.listaSalvar.length; i++) {
                    var item = vm.listaSalvar[i];
                    item.alterDate = new Date();
                    item.alterUser = vm.account.login;
                    PlanoAulaItens.update(item,
                        function(result) {
                            console.log(item);
                        },
                        function(status) {
                            console.log("Erro");
                            console.log(item);
                        });
                }
            }

            vm.listaSalvar = [];

            PlanoAulaItens.delete(aula, function(result) {
                atualizarDados();
            });
        }

        function salvarItemPlano(item) {

            var t = document.getElementById('contAula' + item.id);
            //console.log(t.value);
            t.style.backgroundColor = '#8cd1f1';

            for (var i = 0; i < vm.planoAulaItens.length; i++) {
                var e = vm.planoAulaItens[i];
                if (e.id == item.id) {
                    e.conteudo = t.value;
                    vm.listaSalvar.push(e);
                }
            }
            console.log(vm.listaSalvar);
        }

        function salvarPlano() {
            vm.msgLoad = "Salvando dados do plano alterado; \n Replicando para turmas espelhadas";
            vm.load = true;
            for (var i = 0; i < vm.listaSalvar.length; i++) {
                var item = vm.listaSalvar[i];
                item.alterDate = new Date();
                item.alterUser = vm.account.login;
                PlanoAulaItens.update(item,
                    function(result) {
                        console.log(item);
                    },
                    function(status) {
                        console.log("Erro");
                        console.log(item);
                    });
            }
            vm.planoAula.situacao = "REVISADO";
            vm.planoAula.alterUser = vm.account.login;
            PlanoAula.update(vm.planoAula, function(result) {
                vm.load = false;
                $state.go('plano-aula');
            });
        }

        function atualizarDados() {
            vm.msgLoad = "Carregando dados do plano";
            vm.load = true;
            BuscaItensPlanoAula.query({ id: vm.planoAula.id }, function(result) {
            vm.planoAulaItens = result;
                //console.log(vm.planoAulaItens);


                // separar as aulas sem vinculo
                var aulasSemVinc = [];

                var codTurma = "NC";
                vm.turmas = [];
                var etapa1 = [];
                var etapa2 = [];

                // rodando o array
                // < 10
                var lenArray = result.length;
                for (var i = 0; i < result.length; i++) {
                    var e = result[i];
                    //console.log(e);
                    // separando as aulas sem vinculo para depois.
                    if (e.aula == 0){
                        aulasSemVinc.push(e);
                    } else {
                        // verifica se é o ultimo registro
                        // 10 -1 9
                        if (i == (lenArray - 1)){
                            // aqui é o ultimo registro, finaliza a sub e aloca
                            if (e.etapa == 1){etapa1.push(e);}else{etapa2.push(e);}
                              vm.turmas.push(
                                {
                                    "codTurma": codTurma,
                                    "etapa1": etapa1,
                                    "etapa2": etapa2
                                });
                        } else {
                             // verificando se é o primeiro registro da lista
                            if (codTurma === "NC"){
                                // como é o primeiro a não ser aula = 0 aloca subturma.
                                codTurma = e.codSubTurma;
                                etapa1 = [];
                                etapa2 = [];

                                if (e.etapa == 1){etapa1.push(e);}else{etapa2.push(e);}
                            } else {
                                // já tem uma sub alocada, vamos validas se bate
                                if (e.codSubTurma === codTurma) {
                                    // aqui pertence a mesma sub, bora ver a etapa.
                                    if (e.etapa == 1){etapa1.push(e);}else{etapa2.push(e);}
                                } else {
                                    // aqui nao tem a mesma sub, finaliza a sub e começa denovo
                                    vm.turmas.push(
                                        {
                                            "codTurma": codTurma,
                                            "etapa1": etapa1,
                                            "etapa2": etapa2
                                        });

                                    // vamos zerar as etapas e alocar a nova subturma
                                    codTurma = e.codSubTurma;
                                    etapa1 = [];
                                    etapa2 = [];
                                    //bora ver a etapa.
                                    if (e.etapa == 1){etapa1.push(e);}else{etapa2.push(e);}
                                }
                            }
                        }
                    }
                }

                for (var j = 0; j < aulasSemVinc.length; j++) {
                    var aula = aulasSemVinc[j];
                                        
                    for (var k = 0; k < vm.turmas.length; k++) {
                        var turma = vm.turmas[k];

                        if (turma.codTurma === aula.codSubTurma){
                            if (aula.etapa == 1){
                                vm.turmas[k].etapa1.push(aula);
                            }else{
                                vm.turmas[k].etapa2.push(aula);
                            }
                        }
                    }
                }

                //console.log(aulasSemVinc);
                console.log(vm.turmas);

                vm.load = false;
            }, function(status) {
                vm.load = false;
                console.log(status);
            });
        }

        var unsubscribe = $rootScope.$on('vestibularApp:planoAulaUpdate', function(event, result) {
            vm.planoAula = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();