(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('HorarioPoloDialogController', HorarioPoloDialogController);

    HorarioPoloDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'HorarioPolo', 'PolosAtivos'];

    function HorarioPoloDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, HorarioPolo, PolosAtivos) {
        var vm = this;

        vm.horarioPolo = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.polos = PolosAtivos.query();

        vm.horarios = ['04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            var dt = vm.horarioPolo.dia;
            var dy = dt.getDate() + 1;
            var ms = dt.getMonth();
            var yr = dt.getFullYear();

            if (ms < 10) {
                ms = '0' + ms;
            }

            vm.horarioPolo.dia = new Date(yr, ms, dy);

            if (vm.horarioPolo.id !== null) {
                HorarioPolo.update(vm.horarioPolo, onSaveSuccess, onSaveError);
            } else {
                HorarioPolo.save(vm.horarioPolo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:horarioPoloUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dia = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();