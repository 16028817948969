(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaDiplomaAuthController', MatriculaDiplomaAuthController);

    MatriculaDiplomaAuthController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$http', '$sessionStorage', 'MatriculaDiplomaAuthService'];

    function MatriculaDiplomaAuthController($timeout, $scope, $state, $stateParams, $http, $sessionStorage, MatriculaDiplomaAuthService) {
        var vm = this;

        vm.load = false;
        vm.fecharAlert = fecharAlert;

        // Autenticação
        vm.autentica = autenticar;

        sessionStorage.removeItem('user-matric-diploma');

        function autenticar() {
            var infos = {
                'login': vm.email,
                'password': vm.cpf
            };

            vm.load = true;
            var r = validaDados(infos);
            if (r == 'OK') {

                infos.password = infos.password.replace(".", "").replace(".", "").replace("-", "");

                MatriculaDiplomaAuthService.query(
                    infos,
                    function(data) {
                        console.log(data);
                        if (data.academico == null || data.academico.cpf == "") {
                            showMessage('alert-danger', "Desculpe, não localizamos nenhuma matrícula disponível para os dados informados");
                            vm.load = false;
                        } else {
                            vm.load = false;
                            sessionStorage.setItem('user-matric-diploma', JSON.stringify(data));

                            if (data.academico.etapa == null || data.academico.etapa == 'INICIO') {
                                $state.go('matriculaDiplomaDadosCadastrais');
                            }
                            if (data.academico.etapa == 'CANDIDATO') {
                                $state.go('matriculaDiplomaDadosPais');
                            }
                            if (data.academico.etapa == 'PAIS') {
                                $state.go('matriculaDiplomaResponsavel');
                            }
                            if (data.academico.etapa == 'RESPONSAVEL') {
                                $state.go('matriculaDiplomaPlanoPagamento');
                            }
                            if (data.academico.etapa == 'PLANOPAGAMENTO') {
                                $state.go('matriculaDiplomaContrato');
                            }
                            if (data.academico.etapa == 'CONTRATO') {
                                $state.go('matriculaDiplomaBoleto');
                            }
                        }
                    },
                    function(status) {
                        console.log(status);
                        vm.load = false;
                        showMessage('alert-danger', "Desculpe não foi possível acessar o servidor, tente novamente mais tarde");
                    }
                );
            } else {
                showMessage('alert-danger', r);
            }
        }


        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function validaDados(infos) {
            if (infos.login == null || infos.login == "") {
                return "O campo EMAIL não pode estar vazio.";
            }
            if (!infos.login.indexOf("@")) {
                return "O campo EMAIL não está correto";
            }
            if (infos.password == null || infos.password == "") {
                return "O campo CPF não pode estar vazio";
            }
            if (infos.password.length < 11) {
                return "O campo CPF não está correto";
            }
            return 'OK';
        }
    }
})();