(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('relatorio-extracurricular', {
            parent: 'entity',
            url: '/relatorio-extracurricular?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'RelatorioExtracurriculars'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/relatorio-extracurricular/relatorio-extracurriculars.html',
                    controller: 'RelatorioExtracurricularController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }]
            }
        })
        .state('relatorio-extracurricular-detail', {
            parent: 'relatorio-extracurricular',
            url: '/relatorio-extracurricular/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'RelatorioExtracurricular'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/relatorio-extracurricular/relatorio-extracurricular-detail.html',
                    controller: 'RelatorioExtracurricularDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'RelatorioExtracurricular', function($stateParams, RelatorioExtracurricular) {
                    return RelatorioExtracurricular.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'relatorio-extracurricular',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('relatorio-extracurricular-detail.edit', {
            parent: 'relatorio-extracurricular-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/relatorio-extracurricular/relatorio-extracurricular-dialog.html',
                    controller: 'RelatorioExtracurricularDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['RelatorioExtracurricular', function(RelatorioExtracurricular) {
                            return RelatorioExtracurricular.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('relatorio-extracurricular.new', {
            parent: 'relatorio-extracurricular',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/relatorio-extracurricular/relatorio-extracurricular-dialog.html',
                    controller: 'RelatorioExtracurricularDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                titulo: null,
                                novo: null,
                                ano: null,
                                professor: null,
                                linkLattes: null,
                                modalidade: null,
                                nucleo: null,
                                dadosEmissao: null,
                                resumo: null,
                                objetivo: null,
                                objEspecifico: null,
                                acoes: null,
                                inicio: null,
                                termino: null,
                                createdDate: null,
                                createdUser: null,
                                alterDate: null,
                                alertUser: null,
                                tipo: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('relatorio-extracurricular', null, { reload: 'relatorio-extracurricular' });
                }, function() {
                    $state.go('relatorio-extracurricular');
                });
            }]
        })
        .state('relatorio-extracurricular.edit', {
            parent: 'relatorio-extracurricular',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/relatorio-extracurricular/relatorio-extracurricular-dialog.html',
                    controller: 'RelatorioExtracurricularDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['RelatorioExtracurricular', function(RelatorioExtracurricular) {
                            return RelatorioExtracurricular.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('relatorio-extracurricular', null, { reload: 'relatorio-extracurricular' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('relatorio-extracurricular.delete', {
            parent: 'relatorio-extracurricular',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/relatorio-extracurricular/relatorio-extracurricular-delete-dialog.html',
                    controller: 'RelatorioExtracurricularDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['RelatorioExtracurricular', function(RelatorioExtracurricular) {
                            return RelatorioExtracurricular.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('relatorio-extracurricular', null, { reload: 'relatorio-extracurricular' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
