(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('BarCodeController', BarCodeController);

    BarCodeController.$inject = ['$scope', '$rootScope', '$state', '$sessionStorage'];

    function BarCodeController($scope, $rootScope, $state, $sessionStorage) {
        var vm = this;
        console.log("Veio para o BARREADER");

        //variaveis
        vm.request = JSON.parse(sessionStorage.getItem('request-bar-reader'));
        console.log(vm.request);

        vm.leitura = "";
        console.log("letura -> " + vm.leitura);
    }

})();