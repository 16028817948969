(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('EditarInscricaoService', EditarInscricaoService);

    EditarInscricaoService.$inject = ['$uibModal', '$rootScope'];

    function EditarInscricaoService($uibModal, $rootScope) {
        var service = {
            open: open
        };

        var modalInstance = null;

        var resetModal = function() {
            modalInstance = null;
        };

        return service;

        function open(inscricao) {
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/consulta-vestibular/editar/EditaInsc.html',
                controller: 'EditaInscController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    param: function() {
                        return { 'data': inscricao };
                    }
                }
            });
            modalInstance.result.then(function(selectedItem) {
                modalInstance = null;
            }, function() {
                modalInstance = null;
            });
        }
    }
})();