(function () {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('relatorio-cpe', {
            parent: 'app',
            url: '/relatorios-cpe',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_PROFESSOR', 'ROLE_COORDENADOR'],
                pageTitle: 'Relatorios CPE'
            },
            views: {
                'content@': {
                    templateUrl: 'app/relatorio/home/home-relatorios.html',
                    controller: 'HomeRelatorioController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();