(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PosGradController', PosGradController);

    PosGradController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$http', 'PolosAtivos', 'CursoPosPoloService', 'LeadPosPoloService'];

    function PosGradController($scope, Principal, LoginService, $state, $http, PolosAtivos, CursoPosPoloService, LeadPosPoloService) {
        var vm = this;

        vm.alterInfo = alterInfo;
        vm.enviar = enviar;
        vm.fecharAlert = fecharAlert;
        vm.poloEscolhido = null;
        vm.cursoEscolhido = null;
        vm.confirmacao = false;

        vm.polos = PolosAtivos.query({
            page: 0,
            size: 50
        });


        function alterInfo(id, info) {

            if (id == 1) {
                vm.poloEscolhido = info;

                CursoPosPoloService.query({ id: vm.poloEscolhido.id },
                    function(data) {
                        vm.cursos = data;
                    },
                    function(status) {
                        console.log(status);
                    });
            }

            if (id == 2) {
                vm.cursoEscolhido = info;
            }
        }


        function enviar() {

            var v = verificaCampos();
            if (v == 'OK') {
                vm.pos.polo = vm.poloEscolhido;
                vm.pos.curso = vm.cursoEscolhido;

                console.log(vm.pos);

                LeadPosPoloService.save(vm.pos,
                    function(data) {
                        vm.confirmacao = true;
                    },
                    function(status) {
                        console.log(status);
                    });
            } else {
                showMessage('alert alert-danger', v);
            }
        }


        function verificaCampos() {

            // VALIDA SE O NOME ESTA VAZIO -- JA TEM O REQUIRED, MAS VALIDAÇÃO NUNCA É DEMAIS
            if (vm.pos.nome == null || vm.pos.nome == "") {
                return "O campo NOME não pode estar vazio.";
            }
            // VALIDA SE O NOME TEM PELO MENOS DOIS CARACTERES E PELO MENOS UM ESPAÇO
            if (vm.pos.nome.length <= 3 || !vm.pos.nome.indexOf(" ")) {
                return "Por favor, insira seu NOME COMPLETO. Ex: FULANO SILVA";
            }
            if (vm.pos.telefone == null || vm.pos.telefone == "") {
                return "O campo TELEFONE não pode ser vazio";
            }
            if (vm.pos.telefone.length < 10) {
                return "O campo TELEFONE deve ter ao menos 10 dígitos";
            }
            if (vm.pos.email == null || vm.pos.email == "") {
                return "O campo EMAIL não pode ser vazio";
            }
            if (!vm.pos.email.indexOf("@")) {
                return "O campo EMAIL não está correto";
            }
            if (vm.poloEscolhido == null) {
                return "Por favor selecione algum Polo";
            }
            if (vm.cursoEscolhido == null) {
                return "Por favor selecione o curso que tem interesse";
            }

            return "OK";
        };

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }
    }
})();