(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ValidaInputRegexService', ValidaInputRegexService);

    ValidaInputRegexService.$inject = [];

    function ValidaInputRegexService() {

        var service = {
            validate: validate
        }

        return service;

        function validate(input, type) {
            //input = input.toLocaleString();

            if (input == "") {
                return { status: 'error', msg: 'O campo não pode estar vazio.' };
            } else if (input == null) {
                return { status: 'error', msg: 'O campo não pode estar vazio.' };
            } else if (input == undefined) {
                return { status: 'error', msg: 'O campo não pode estar vazio.' };
            } else

            if (type === 'email') {
                var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(input)) {
                    return { status: 'error', msg: 'O E-mail informado está incorreto.' };;
                }
                return { status: 'success', msg: "" };
            } else
            if (type === 'alphanumeric') {
                var alphanumericRegex = /^[a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ"'°ºª \s]+$/;
                if (!alphanumericRegex.test(input)) {
                    return { status: 'error', msg: 'O campo deve conter apenas letras e números.' };
                }
                return { status: 'success', msg: "" };
            } else
            if (type === 'onlyString') {
                var stringRegex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ"'°ºª ]+$/;
                if (!stringRegex.test(input)) {
                    return { status: 'error', msg: 'O campo deve conter apenas letras.' };
                }
                return { status: 'success', msg: "" };
            } else
            if (type === 'onlyNumber') {
                var numberRegex = /^[0-9]+$/;
                if (!numberRegex.test(input)) {
                    return { status: 'error', msg: 'O campo deve conter apenas Números.' };
                }
                return { status: 'success', msg: "" };
            } else
            if (type === 'mobileNumber') {
                var mobileRegex = /^[+\s0-9]+$/;

                if (!mobileRegex.test(input)) {
                    return { status: 'error', msg: 'O campo deve conter apenas Números.' };
                }
                return { status: 'success', msg: "" };
            } else
            if (type === 'alphanumericWithSpecial') {
                var alphanumericSpecialRegex = /^[A-Za-z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ°ºª !@#$%&*()_+\-=\[\]{};':"\\|,.<>\/?\s]*$/;

                if (!alphanumericSpecialRegex.test(input)) {
                    return { status: 'error', msg: 'Algum caractere no texto não é aceito pelo sistema.' };
                }
                return { status: 'success', msg: "" };
            } else if (type === 'webURL') {
                var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

                if (!urlRegex.test(input)) {
                    return { status: 'error', msg: 'A url não está no padrão' };
                }
                return { status: 'success', msg: "" };
            } else if (type === 'confirmPassword') {
                if (input !== confirmPassword.toString()) {
                    return { status: 'error', msg: 'Passwords do not match.' };
                }
                return { status: 'success', msg: "" };
            }
            return { status: 'error', msg: "Invalid Type" };
        }
    }
})();