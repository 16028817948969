(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('VerLeadAgendadoService', VerLeadAgendadoService);

    VerLeadAgendadoService.$inject = ['$resource', 'DateUtils'];

    function VerLeadAgendadoService($resource, DateUtils) {
        var resourceUrl = 'api/lead-by-id/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
        });
    }
})();