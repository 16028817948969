(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('EditPlanoEnsinoController', EditPlanoEnsinoController);

    EditPlanoEnsinoController.$inject = ['$state', 'LoginService', '$http', 'Principal', 'Auth', 'BuscaItensPlanoService', 'BuscaBBPlanoService', 'PlanoEnsinoObs',
        'BuscaObsPlanoService', 'PlanoEnsino', 'PlanoEnsinoBibliografia', 'PlanoEnsinoItens', 'PlanoEnsinoLog', 'BuscaDiscsPlanoIdService'
    ];

    function EditPlanoEnsinoController($state, LoginService, $http, Principal, Auth, BuscaItensPlanoService, BuscaBBPlanoService,
        PlanoEnsinoObs, BuscaObsPlanoService, PlanoEnsino, PlanoEnsinoBibliografia, PlanoEnsinoItens, PlanoEnsinoLog, BuscaDiscsPlanoIdService) {
        var vm = this;

        vm.finalizaObs = finalizaObs;
        vm.salvarPlano = salvarPlano;
        vm.removeLivro = removeLivro;
        vm.insereAlt = insereAlt;
        vm.listaAlt = [];

        vm.loading = false;

        vm.bBasica = [];
        vm.bCompl = [];
        vm.bApro = [];

        vm.listaObs = [];
        vm.obs = {};

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                start();
            });
        };

        function insereAlt(campo, valor) {

            var obj = { plano: vm.plano.id, campo: campo, valor: valor };

            for (var i = 0; i < vm.listaAlt.length; i++) {
                var e = vm.listaAlt[i];
                if (e.campo == campo) {
                    vm.listaAlt.splice(i, 1);
                }
            }
            vm.listaAlt.push(obj);
            $('#' + campo).change(function() { document.getElementById(campo).style.backgroundColor = 'aquamarine'; });
        }

        function start() {
            vm.loading = true;
            vm.temp = JSON.parse(sessionStorage.getItem('plano-ensino-correcao'));
            console.log(vm.temp);
            BuscaItensPlanoService.query({ id: vm.temp.id },
                function(itens) {
                    vm.itensUpdate = itens;
                    console.log(itens);
                    for (var i = 0; i < itens.length; i++) {
                        var e = itens[i];

                        if (e.tipo == 'COMPETENCIA') { vm.temp.competencias = e.texto; };
                        if (e.tipo == 'EMENTA') { vm.temp.ementa = e.texto; };
                        if (e.tipo == 'AS1') { vm.temp.contAs1 = e.texto; };
                        if (e.tipo == 'AS2') { vm.temp.contAs2 = e.texto; };
                        if (e.tipo == 'INSTAVA1B') { vm.temp.inst1b = e.texto; };
                        if (e.tipo == 'RECINOV1') { vm.temp.recursos1 = e.texto; };
                        if (e.tipo == 'INSTAVA2B') { vm.temp.inst2b = e.texto; };
                        if (e.tipo == 'BBASICA') { vm.temp.bBasica = e.texto; };
                        if (e.tipo == 'BCOMPL') { vm.temp.bCompl = e.texto; };
                        if (e.tipo == 'BAPRO') { vm.temp.bApro = e.texto; };
                        if (e.tipo == 'OBSG') { vm.temp.obsg = e.texto; };


                        if (e.tipo == 'CONT1') { vm.temp.cont1 = e.texto; };
                        if (e.tipo == 'CONT2') { vm.temp.cont2 = e.texto; };
                        if (e.tipo == 'CONT3') { vm.temp.cont3 = e.texto; };
                        if (e.tipo == 'CONT4') { vm.temp.cont4 = e.texto; };
                        if (e.tipo == 'CONT5') { vm.temp.cont5 = e.texto; };
                        if (e.tipo == 'CONT6') { vm.temp.cont6 = e.texto; };
                        if (e.tipo == 'CONT7') { vm.temp.cont7 = e.texto; };
                        if (e.tipo == 'CONT8') { vm.temp.cont8 = e.texto; };
                        if (e.tipo == 'CONT9') { vm.temp.cont9 = e.texto; };
                        if (e.tipo == 'CONT10') { vm.temp.cont10 = e.texto; };
                        if (e.tipo == 'CONT11') { vm.temp.cont11 = e.texto; };
                        if (e.tipo == 'CONT12') { vm.temp.cont12 = e.texto; };
                        if (e.tipo == 'CONT13') { vm.temp.cont13 = e.texto; };
                        if (e.tipo == 'CONT14') { vm.temp.cont14 = e.texto; };
                        if (e.tipo == 'CONT15') { vm.temp.cont15 = e.texto; };
                        if (e.tipo == 'CONT16') { vm.temp.cont16 = e.texto; };
                        if (e.tipo == 'CONT17') { vm.temp.cont17 = e.texto; };
                        if (e.tipo == 'CONT18') { vm.temp.cont18 = e.texto; };
                        if (e.tipo == 'CONT19') { vm.temp.cont19 = e.texto; };
                        if (e.tipo == 'CONT20') { vm.temp.cont20 = e.texto; };
                        if (e.tipo == 'CONT21') { vm.temp.cont21 = e.texto; };
                        if (e.tipo == 'CONT22') { vm.temp.cont22 = e.texto; };
                        if (e.tipo == 'CONT23') { vm.temp.cont23 = e.texto; };
                        if (e.tipo == 'CONT24') { vm.temp.cont24 = e.texto; };
                        if (e.tipo == 'CONT25') { vm.temp.cont25 = e.texto; };
                        if (e.tipo == 'CONT26') { vm.temp.cont26 = e.texto; };
                        if (e.tipo == 'CONT27') { vm.temp.cont27 = e.texto; };
                        if (e.tipo == 'CONT28') { vm.temp.cont28 = e.texto; };
                        if (e.tipo == 'CONT29') { vm.temp.cont29 = e.texto; };
                        if (e.tipo == 'CONT30') { vm.temp.cont30 = e.texto; };
                        if (e.tipo == 'CONT31') { vm.temp.cont31 = e.texto; };
                        if (e.tipo == 'CONT32') { vm.temp.cont32 = e.texto; };
                        if (e.tipo == 'CONT33') { vm.temp.cont33 = e.texto; };
                        if (e.tipo == 'CONT34') { vm.temp.cont34 = e.texto; };
                        if (e.tipo == 'CONT35') { vm.temp.cont35 = e.texto; };
                        if (e.tipo == 'CONT36') { vm.temp.cont36 = e.texto; };
                        if (e.tipo == 'CONT37') { vm.temp.cont37 = e.texto; };
                        if (e.tipo == 'CONT38') { vm.temp.cont38 = e.texto; };
                        if (e.tipo == 'CONT39') { vm.temp.cont39 = e.texto; };
                        if (e.tipo == 'CONT40') { vm.temp.cont40 = e.texto; };
                        if (e.tipo == 'CONT41') { vm.temp.cont41 = e.texto; };
                        if (e.tipo == 'CONT42') { vm.temp.cont42 = e.texto; };
                        if (e.tipo == 'CONT43') { vm.temp.cont43 = e.texto; };
                        if (e.tipo == 'CONT44') { vm.temp.cont44 = e.texto; };
                        if (e.tipo == 'CONT45') { vm.temp.cont45 = e.texto; };
                        if (e.tipo == 'CONT46') { vm.temp.cont46 = e.texto; };
                        if (e.tipo == 'CONT47') { vm.temp.cont47 = e.texto; };
                        if (e.tipo == 'CONT48') { vm.temp.cont48 = e.texto; };
                        if (e.tipo == 'CONT49') { vm.temp.cont49 = e.texto; };
                        if (e.tipo == 'CONT50') { vm.temp.cont50 = e.texto; };
                        if (e.tipo == 'CONT51') { vm.temp.cont51 = e.texto; };
                        if (e.tipo == 'CONT52') { vm.temp.cont52 = e.texto; };
                        if (e.tipo == 'CONT53') { vm.temp.cont53 = e.texto; };
                        if (e.tipo == 'CONT54') { vm.temp.cont54 = e.texto; };
                        if (e.tipo == 'CONT55') { vm.temp.cont55 = e.texto; };
                        if (e.tipo == 'CONT56') { vm.temp.cont56 = e.texto; };
                        if (e.tipo == 'CONT57') { vm.temp.cont57 = e.texto; };
                        if (e.tipo == 'CONT58') { vm.temp.cont58 = e.texto; };
                        if (e.tipo == 'CONT59') { vm.temp.cont59 = e.texto; };
                        if (e.tipo == 'CONT60') { vm.temp.cont60 = e.texto; };

                        if (e.tipo == 'MET1') { vm.temp.met1 = e.texto; };
                        if (e.tipo == 'MET2') { vm.temp.met2 = e.texto; };
                        if (e.tipo == 'MET3') { vm.temp.met3 = e.texto; };
                        if (e.tipo == 'MET4') { vm.temp.met4 = e.texto; };
                        if (e.tipo == 'MET5') { vm.temp.met5 = e.texto; };
                        if (e.tipo == 'MET6') { vm.temp.met6 = e.texto; };
                        if (e.tipo == 'MET7') { vm.temp.met7 = e.texto; };
                        if (e.tipo == 'MET8') { vm.temp.met8 = e.texto; };
                        if (e.tipo == 'MET9') { vm.temp.met9 = e.texto; };
                        if (e.tipo == 'MET10') { vm.temp.met10 = e.texto; };
                        if (e.tipo == 'MET11') { vm.temp.met11 = e.texto; };
                        if (e.tipo == 'MET12') { vm.temp.met12 = e.texto; };
                        if (e.tipo == 'MET13') { vm.temp.met13 = e.texto; };
                        if (e.tipo == 'MET14') { vm.temp.met14 = e.texto; };
                        if (e.tipo == 'MET15') { vm.temp.met15 = e.texto; };
                        if (e.tipo == 'MET16') { vm.temp.met16 = e.texto; };
                        if (e.tipo == 'MET17') { vm.temp.met17 = e.texto; };
                        if (e.tipo == 'MET18') { vm.temp.met18 = e.texto; };
                        if (e.tipo == 'MET19') { vm.temp.met19 = e.texto; };
                        if (e.tipo == 'MET20') { vm.temp.met20 = e.texto; };
                        if (e.tipo == 'MET21') { vm.temp.met21 = e.texto; };
                        if (e.tipo == 'MET22') { vm.temp.met22 = e.texto; };
                        if (e.tipo == 'MET23') { vm.temp.met23 = e.texto; };
                        if (e.tipo == 'MET24') { vm.temp.met24 = e.texto; };
                        if (e.tipo == 'MET25') { vm.temp.met25 = e.texto; };
                        if (e.tipo == 'MET26') { vm.temp.met26 = e.texto; };
                        if (e.tipo == 'MET27') { vm.temp.met27 = e.texto; };
                        if (e.tipo == 'MET28') { vm.temp.met28 = e.texto; };
                        if (e.tipo == 'MET29') { vm.temp.met29 = e.texto; };
                        if (e.tipo == 'MET30') { vm.temp.met30 = e.texto; };
                        if (e.tipo == 'MET31') { vm.temp.met31 = e.texto; };
                        if (e.tipo == 'MET32') { vm.temp.met32 = e.texto; };
                        if (e.tipo == 'MET33') { vm.temp.met33 = e.texto; };
                        if (e.tipo == 'MET34') { vm.temp.met34 = e.texto; };
                        if (e.tipo == 'MET35') { vm.temp.met35 = e.texto; };
                        if (e.tipo == 'MET36') { vm.temp.met36 = e.texto; };
                        if (e.tipo == 'MET37') { vm.temp.met37 = e.texto; };
                        if (e.tipo == 'MET38') { vm.temp.met38 = e.texto; };
                        if (e.tipo == 'MET39') { vm.temp.met39 = e.texto; };
                        if (e.tipo == 'MET40') { vm.temp.met40 = e.texto; };
                        if (e.tipo == 'MET41') { vm.temp.met41 = e.texto; };
                        if (e.tipo == 'MET42') { vm.temp.met42 = e.texto; };
                        if (e.tipo == 'MET43') { vm.temp.met43 = e.texto; };
                        if (e.tipo == 'MET44') { vm.temp.met44 = e.texto; };
                        if (e.tipo == 'MET45') { vm.temp.met45 = e.texto; };
                        if (e.tipo == 'MET46') { vm.temp.met46 = e.texto; };
                        if (e.tipo == 'MET47') { vm.temp.met47 = e.texto; };
                        if (e.tipo == 'MET48') { vm.temp.met48 = e.texto; };
                        if (e.tipo == 'MET49') { vm.temp.met49 = e.texto; };
                        if (e.tipo == 'MET50') { vm.temp.met50 = e.texto; };
                        if (e.tipo == 'MET51') { vm.temp.met51 = e.texto; };
                        if (e.tipo == 'MET52') { vm.temp.met52 = e.texto; };
                        if (e.tipo == 'MET53') { vm.temp.met53 = e.texto; };
                        if (e.tipo == 'MET54') { vm.temp.met54 = e.texto; };
                        if (e.tipo == 'MET55') { vm.temp.met55 = e.texto; };
                        if (e.tipo == 'MET56') { vm.temp.met56 = e.texto; };
                        if (e.tipo == 'MET57') { vm.temp.met57 = e.texto; };
                        if (e.tipo == 'MET58') { vm.temp.met58 = e.texto; };
                        if (e.tipo == 'MET59') { vm.temp.met59 = e.texto; };
                        if (e.tipo == 'MET60') { vm.temp.met60 = e.texto; };

                    };


                    BuscaDiscsPlanoIdService.query({ id: vm.temp.id }, function(turmas) {
                        vm.disciplinasPlano = turmas;
                        //console.log(vm.disciplinasPlano);
                    }, function(status) { console.log(status) });

                    /*BuscaBBPlanoService.query({ id: vm.temp.id }, function(livros) {
                        for (var i = 0; i < livros.length; i++) {
                            var l = livros[i];
                            if (l.tipo == 'BASICA') { vm.bBasica.push(l); };
                            if (l.tipo == 'COMPLEMENTAR') { vm.bCompl.push(l); };
                        }
                    }, function(status) { console.log(status) });
                    */

                    BuscaObsPlanoService.query({ id: vm.temp.id }, function(obs) {
                        for (var h = 0; h < obs.length; h++) {
                            var i = obs[h];
                            vm.listaObs.push(i);
                            document.getElementById(i.tipo).style.backgroundColor = "yellow";
                        }
                    }, function(status) { console.log(status) });

                    switch (vm.temp.chTotal) {
                        case 35:
                            vm.temp.chTotal = "35";
                            vm.limiteAs = 10;
                            break;
                        case 40:
                            vm.temp.chTotal = "40";
                            vm.limiteAs = 10;
                            break;
                        case 70:
                            vm.temp.chTotal = "70";
                            vm.limiteAs = 20;
                            break;
                        case 80:
                            vm.temp.chTotal = "80";
                            vm.limiteAs = 20;
                            break;
                        case 100:
                            vm.temp.chTotal = "100";
                            vm.limiteAs = 0;
                            break;
                        case 120:
                            vm.temp.chTotal = "120";
                            vm.limiteAs = 20;
                            break;
                    }
                    vm.plano = vm.temp

                    vm.loading = false;
                },
                function(status) { console.log(status); });
        }

        function finalizaObs(obs) {
            obs.etapa = "FINALIZADO";
            obs.userAlteracao = vm.account.login;
            PlanoEnsinoObs.update(obs, function(data) {
                for (var o = 0; o < vm.listaObs.length; o++) {
                    var e = vm.listaObs[o];
                    if (obs.id == e.id) {
                        vm.listaObs.splice(o, 1);
                    }
                }
                vm.listaObs.push(data);
            });
        }

        function salvarPlano() {
            var valida = validaCampos();
            var etapaAnt = vm.plano.etapa;

            //updateCampos()

            if (valida == "OK") {

                if (vm.plano.etapa == "CORRECAOC") {
                    vm.plano.etapa = "PENDENTE";
                }

                if (vm.plano.etapa == "CORRECAON") {
                    vm.plano.etapa = "APROVADOC";
                }

                PlanoEnsino.update(vm.plano);
                insereLog({
                    idPlano: vm.plano.id,
                    item: "PLANO",
                    operacao: "UPDATE",
                    valor: vm.plano.etapa,
                    valorAnt: etapaAnt,
                    user: vm.account.login
                });
                updateCampos();
            } else {
                showMessage("alert alert-danger", valida);
            }
        }

        function removeLivro(tipo, item) {
            switch (tipo) {
                case "BASICA":
                    for (var i = 0; i < vm.bBasica.length; i++) {
                        var e = vm.bBasica[i];
                        if (e.id == item.id) {
                            vm.bBasica.splice(i, 1);
                        }
                    }
                    break;
                case "COMPLEMENTAR":
                    for (var i = 0; i < vm.bCompl.length; i++) {
                        var e = vm.bCompl[i];
                        if (e.id == item.id) {
                            vm.bCompl.splice(i, 1);
                        }
                    }
                    break;
            }

            PlanoEnsinoBibliografia.delete({ id: item.id });
        }


        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }



        function updateCampos() {

            var valorAnt = "";
            var achou = false;

            //console.log(vm.listaAlt);
            //console.log(vm.itensUpdate);

            // CAMPOS ALTERADOS
            for (var i = 0; i < vm.listaAlt.length; i++) {
                var e = vm.listaAlt[i];
                achou = false;

                // CAMPOS ITENS
                if (vm.itensUpdate.length > 0) {

                    for (var j = 0; j < vm.itensUpdate.length; j++) {
                        var a = vm.itensUpdate[j];
                        // se achou salva o resource itens

                        if (a.tipo == e.campo) {
                            achou = true;
                            valorAnt = a.texto;
                            a.texto = e.valor;
                            a.userAlteracao = vm.account.login;
                            PlanoEnsinoItens.update(a);
                        }

                        if (achou == false) {
                            switch (e.campo) {
                                case 'CHPRATICA':
                                    achou = true;
                                    valorAnt = vm.temp.chPratica;
                                    break;
                                case 'CHPTEORICA':
                                    achou = true;
                                    valorAnt = vm.temp.chTorica;
                                    break;
                                case 'CHEXTENSAO':
                                    achou = true;
                                    valorAnt = vm.temp.chExtensao;
                                    break;
                                case 'CHAS':
                                    achou = true;
                                    valorAnt = vm.temp.chAs;
                                    break;
                                case 'CHPRESENCIAL':
                                    achou = true;
                                    valorAnt = vm.temp.chPresencial;
                                    break;
                                case 'CHEAD':
                                    achou = true;
                                    valorAnt = vm.temp.chEad;
                                    break;
                                case 'CHONLINE':
                                    achou = true;
                                    valorAnt = vm.temp.chOnline;
                                    break;
                                case 'CHTOTAL':
                                    achou = true;
                                    valorAnt = vm.temp.chTotal;
                                    break;
                                case 'PONTAS1':
                                    achou = true;
                                    valorAnt = vm.temp.pontAs1;
                                    break;
                                case 'PONTAS2':
                                    achou = true;
                                    valorAnt = vm.temp.pontAs2;
                                    break;
                                case 'PONTAB1':
                                    achou = true;
                                    valorAnt = vm.temp.pontAb1;
                                    break;
                                case 'PONTAB2':
                                    achou = true;
                                    valorAnt = vm.temp.pontAb2;
                                    break;
                                case 'PONTTRAB1':
                                    achou = true;
                                    valorAnt = vm.temp.pontTrab1;
                                    break;
                                case 'PONTTRAB2':
                                    achou = true;
                                    valorAnt = vm.temp.pontTrab2;
                                    break;
                            }
                        }
                    }

                    if (achou == false) {
                        var item = {
                            id: null,
                            tipo: e.campo,
                            idPlano: vm.temp.id,
                            texto: e.valor,
                            userAlteracao: vm.account.login,
                            userCriacao: vm.account.login,
                            dataCriacao: new Date(),
                            dataAlteracao: new Date()
                        };

                        PlanoEnsinoItens.save(item);
                    }
                } else {
                    console.log("sem o campo vamos criar");
                    var item = {
                        id: null,
                        tipo: e.campo,
                        idPlano: vm.temp.id,
                        texto: e.valor,
                        userAlteracao: vm.account.login,
                        userCriacao: vm.account.login,
                        dataCriacao: new Date(),
                        dataAlteracao: new Date()
                    };
                    PlanoEnsinoItens.save(item);
                }

                // AQUI TEM Q TER O LOG POR QUE 
                insereLog({
                    idPlano: e.plano,
                    item: e.campo,
                    operacao: "UPDATE",
                    valor: e.valor,
                    valorAnt: valorAnt,
                    user: vm.account.login
                });

            }

            $state.go('plano-de-ensino');
        }

        function insereLog(log) {
            PlanoEnsinoLog.save(log);
        }

        function validaCampos() {

            if (vm.plano.professor == null) {
                return "O campo NOME PROFESSOR está incorreto";
            }
            if (vm.plano.chPratica == null) {
                document.getElementById('CHPRATICA').style.backgroundColor = 'yellow';
                document.getElementById('CHPRATICA').focus();
                return "O campo CH PRATICA está incorreto";
            }
            if (vm.plano.chTeorica == null) {
                document.getElementById('CHTEORICA').style.backgroundColor = 'yellow';
                document.getElementById('CHTEORICA').focus();
                return "O campo CH TEÓRICA está incorreto";
            }
            if (vm.plano.chExtensao == null) {
                document.getElementById('CHEXTENSAO').style.backgroundColor = 'yellow';
                document.getElementById('CHEXTENSAO').focus();
                return "O campo CH EXTENSÃO está incorreto";
            }
            if (vm.plano.chAs == null) {
                document.getElementById('CHAS').style.backgroundColor = 'yellow';
                document.getElementById('CHAS').focus();
                return "O campo CH AS está incorreto";
            }
            if (vm.plano.chPresencial == null) {
                document.getElementById('CHPRESENCIAL').style.backgroundColor = 'yellow';
                document.getElementById('CHPRESENCIAL').focus();
                return "O campo CH PRESENCIAL está incorreto";
            }
            if (vm.plano.chOnline == null) {
                document.getElementById('CHONLINE').style.backgroundColor = 'yellow';
                document.getElementById('CHONLINE').focus();
                return "O campo CH ONLINE está incorreto";
            }
            if (vm.plano.chEad == null) {
                document.getElementById('CHEAD').style.backgroundColor = 'yellow';
                document.getElementById('CHEAD').focus();
                return "O campo CH EAD está incorreto";
            }
            if (vm.plano.chTotal == null) {
                document.getElementById('CHTOTAL').style.backgroundColor = 'yellow';
                document.getElementById('CHTOTAL').focus();
                return "O campo CH TOTAL está incorreto";
            }
            if (vm.plano.ementa == null || vm.plano.ementa == "") {
                document.getElementById('EMENTA').style.backgroundColor = 'yellow';
                document.getElementById('EMENTA').focus();
                return "O campo EMENTA está incorreto";
            }
            if (vm.plano.competencias == null || vm.plano.competencias == "") {
                document.getElementById('COMPETENCIA').style.backgroundColor = 'yellow';
                document.getElementById('COMPETENCIA').focus();
                return "O campo COMPETÊNCIA está incorreto";
            }

            if (vm.plano.contAs1 == null || vm.plano.contAs1 == "") {
                document.getElementById('AS1').style.backgroundColor = 'yellow';
                document.getElementById('AS1').focus();
                return "O campo ATIVIDADES SUPERVISIONADAS 1 está incorreto";
            }
            if (vm.plano.contAs2 == null || vm.plano.contAs2 == "") {
                document.getElementById('AS2').style.backgroundColor = 'yellow';
                document.getElementById('AS2').focus();
                return "O campo ATIVIDADES SUPERVISIONADAS2 está incorreto";
            }

            if (vm.plano.recursos1 == null || vm.plano.recursos1 == "") {
                document.getElementById('RECINOV1').style.backgroundColor = 'yellow';
                document.getElementById('RECINOV1').focus();
                return "O campo RECURSOS INOVADORES 1 BIM está incorreto";
            }

            if (vm.plano.inst1b == null || vm.plano.inst1b == "") {
                document.getElementById('ISNTAVA1B').style.backgroundColor = 'yellow';
                document.getElementById('INSTAVA1B').focus();
                return "O campo INSTRUMENTOS AVALIATIVOS 1° BIMESTRE está incorreto";
            }
            if (vm.plano.inst2b == null || vm.plano.inst2b == "") {
                document.getElementById('INSTAVA2B').style.backgroundColor = 'yellow';
                document.getElementById('INSTAVA2B').focus();
                return "O campo INSTRUMENTOS AVALIATIVOS 2° BIMESTRE está incorreto";
            }

            if (vm.plano.pontAs1 == null) {
                document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                document.getElementById('PONTAS1').focus();
                return "O campo PONTUAÇÃO AS 1° BIMESTRE está incorreto";
            }
            if (vm.plano.pontAs2 == null) {
                document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                document.getElementById('PONTAS2').focus();
                return "O campo PONTUAÇÃO AS 2° BIMESTRE está incorreto";
            }

            if (vm.plano.pontAb1 == null) {
                document.getElementById('PONTAB1').style.backgroundColor = 'yellow';
                document.getElementById('PONTAB1').focus();
                return "O campo PONTUAÇÂO AVALIAÇÂO 1° BIMESTRE está incorreto";
            }

            if (vm.plano.pontAb2 == null) {
                document.getElementById('PONTAB2').style.backgroundColor = 'yellow';
                document.getElementById('PONTAB2').focus();
                return "O campo PONTUAÇÂO AVALIAÇÂO 2° BIMESTRE está incorreto";
            }


            if (vm.plano.pontTrab1 == null) {
                document.getElementById('PONTTRAB1').style.backgroundColor = 'yellow';
                document.getElementById('PONTTRAB1').focus();
                return "O campo PONTUAÇÃO DE TRABALHOS 1° BIMESTRE está incorreto";
            }

            if (vm.plano.pontTrab2 == null) {
                document.getElementById('PONTTRAB2').style.backgroundColor = 'yellow';
                document.getElementById('PONTTRAB2').focus();
                return "O campo PONTUAÇÃO DE TRABALHOS 1° BIMESTRE está incorreto";
            }

            var bim1 = vm.plano.pontAb1 + vm.plano.pontAs1 + vm.plano.pontTrab1;
            var bim2 = vm.plano.pontAb2 + vm.plano.pontAs2 + vm.plano.pontTrab2;
            var as = vm.plano.pontAs1 + vm.plano.pontAs2;

            if (as > vm.limiteAs) {
                document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                return "A soma da Pontuação das AS( " + as + " ) ultrapassa o limite da Carga Horária: (" + vm.limiteAs + ").";
            }

            if (bim1 != 100) {
                document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                document.getElementById('PONTAB1').style.backgroundColor = 'yellow';
                document.getElementById('PONTTRAB1').style.backgroundColor = 'yellow';
                return "A soma da Pontuação do 1° Bimestre( " + bim1 + " ) está diferente de 100";
            }

            if (bim2 != 100) {
                document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                document.getElementById('PONTAB2').style.backgroundColor = 'yellow';
                document.getElementById('PONTTRAB2').style.backgroundColor = 'yellow';
                return "A soma da Pontuação do 2° Bimestre( " + bim2 + " ) está diferente de 100";
            }

            if (vm.plano.chTotal >= 35) {

                if (vm.plano.cont1  ==  null   ||   vm.plano.cont1  ==  '')   {
                    document.getElementById('CONT1').style.backgroundColor = 'yellow';
                    document.getElementById('CONT1').focus();
                    return  'O campo CONTEÚDO PREVISTO 1 está incorreto'; 
                };
                if (vm.plano.met1  ==  null  ||  vm.plano.met1   ==   '')   { 
                    document.getElementById('MET1').style.backgroundColor = 'yellow';
                    document.getElementById('MET1').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 1 está incorreto'; 
                };
                if (vm.plano.cont2  ==  null   ||   vm.plano.cont2  ==  '')   {
                    document.getElementById('CONT2').style.backgroundColor = 'yellow';
                    document.getElementById('CONT2').focus();
                    return  'O campo CONTEÚDO PREVISTO 2 está incorreto'; 
                };
                if (vm.plano.met2  ==  null  ||  vm.plano.met2   ==   '')   { 
                    document.getElementById('MET2').style.backgroundColor = 'yellow';
                    document.getElementById('MET2').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 2 está incorreto'; 
                };
                if (vm.plano.cont3  ==  null   ||   vm.plano.cont3  ==  '')   {
                    document.getElementById('CONT3').style.backgroundColor = 'yellow';
                    document.getElementById('CONT3').focus();
                    return  'O campo CONTEÚDO PREVISTO 3 está incorreto'; 
                };
                if (vm.plano.met3  ==  null  ||  vm.plano.met3   ==   '')   { 
                    document.getElementById('MET3').style.backgroundColor = 'yellow';
                    document.getElementById('MET3').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 3 está incorreto'; 
                };
                if (vm.plano.cont4  ==  null   ||   vm.plano.cont4  ==  '')   {
                    document.getElementById('CONT4').style.backgroundColor = 'yellow';
                    document.getElementById('CONT4').focus();
                    return  'O campo CONTEÚDO PREVISTO 4 está incorreto'; 
                };
                if (vm.plano.met4  ==  null  ||  vm.plano.met4   ==   '')   { 
                    document.getElementById('MET4').style.backgroundColor = 'yellow';
                    document.getElementById('MET4').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 4 está incorreto'; 
                };
                if (vm.plano.cont5  ==  null   ||   vm.plano.cont5  ==  '')   {
                    document.getElementById('CONT5').style.backgroundColor = 'yellow';
                    document.getElementById('CONT5').focus();
                    return  'O campo CONTEÚDO PREVISTO 5 está incorreto'; 
                };
                if (vm.plano.met5  ==  null  ||  vm.plano.met5   ==   '')   { 
                    document.getElementById('MET5').style.backgroundColor = 'yellow';
                    document.getElementById('MET5').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 5 está incorreto'; 
                };
                if (vm.plano.cont6  ==  null   ||   vm.plano.cont6  ==  '')   {
                    document.getElementById('CONT6').style.backgroundColor = 'yellow';
                    document.getElementById('CONT6').focus();
                    return  'O campo CONTEÚDO PREVISTO 6 está incorreto'; 
                };
                if (vm.plano.met6  ==  null  ||  vm.plano.met6   ==   '')   { 
                    document.getElementById('MET6').style.backgroundColor = 'yellow';
                    document.getElementById('MET6').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 6 está incorreto'; 
                };
                if (vm.plano.cont7  ==  null   ||   vm.plano.cont7  ==  '')   {
                    document.getElementById('CONT7').style.backgroundColor = 'yellow';
                    document.getElementById('CONT7').focus();
                    return  'O campo CONTEÚDO PREVISTO 7 está incorreto'; 
                };
                if (vm.plano.met7  ==  null  ||  vm.plano.met7   ==   '')   { 
                    document.getElementById('MET7').style.backgroundColor = 'yellow';
                    document.getElementById('MET7').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 7 está incorreto'; 
                };
                if (vm.plano.cont8  ==  null   ||   vm.plano.cont8  ==  '')   {
                    document.getElementById('CONT8').style.backgroundColor = 'yellow';
                    document.getElementById('CONT8').focus();
                    return  'O campo CONTEÚDO PREVISTO 8 está incorreto'; 
                };
                if (vm.plano.met8  ==  null  ||  vm.plano.met8   ==   '')   { 
                    document.getElementById('MET8').style.backgroundColor = 'yellow';
                    document.getElementById('MET8').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 8 está incorreto'; 
                };
                if (vm.plano.cont9  ==  null   ||   vm.plano.cont9  ==  '')   {
                    document.getElementById('CONT9').style.backgroundColor = 'yellow';
                    document.getElementById('CONT9').focus();
                    return  'O campo CONTEÚDO PREVISTO 9 está incorreto'; 
                };
                if (vm.plano.met9  ==  null  ||  vm.plano.met9   ==   '')   { 
                    document.getElementById('MET9').style.backgroundColor = 'yellow';
                    document.getElementById('MET9').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 9 está incorreto'; 
                };
                if (vm.plano.cont10  ==  null   ||   vm.plano.cont10  ==  '')   {
                    document.getElementById('CONT10').style.backgroundColor = 'yellow';
                    document.getElementById('CONT10').focus();
                    return  'O campo CONTEÚDO PREVISTO 10 está incorreto'; 
                };
                if (vm.plano.met10  ==  null  ||  vm.plano.met10   ==   '')   { 
                    document.getElementById('MET10').style.backgroundColor = 'yellow';
                    document.getElementById('MET10').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 10 está incorreto'; 
                };


                if (vm.plano.cont31  ==  null   ||   vm.plano.cont31  ==  '')   {
                    document.getElementById('CONT31').style.backgroundColor = 'yellow';
                    document.getElementById('CONT31').focus();
                    return  'O campo CONTEÚDO PREVISTO 31 está incorreto'; 
                };
                if (vm.plano.met31  ==  null  ||  vm.plano.met31   ==   '')   { 
                    document.getElementById('MET31').style.backgroundColor = 'yellow';
                    document.getElementById('MET31').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 31 está incorreto'; 
                };
                if (vm.plano.cont32  ==  null   ||   vm.plano.cont32  ==  '')   {
                    document.getElementById('CONT32').style.backgroundColor = 'yellow';
                    document.getElementById('CONT32').focus();
                    return  'O campo CONTEÚDO PREVISTO 32 está incorreto'; 
                };
                if (vm.plano.met32  ==  null  ||  vm.plano.met32   ==   '')   { 
                    document.getElementById('MET32').style.backgroundColor = 'yellow';
                    document.getElementById('MET32').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 32 está incorreto'; 
                };
                if (vm.plano.cont33  ==  null   ||   vm.plano.cont33  ==  '')   {
                    document.getElementById('CONT33').style.backgroundColor = 'yellow';
                    document.getElementById('CONT33').focus();
                    return  'O campo CONTEÚDO PREVISTO 33 está incorreto'; 
                };
                if (vm.plano.met33  ==  null  ||  vm.plano.met33   ==   '')   { 
                    document.getElementById('MET33').style.backgroundColor = 'yellow';
                    document.getElementById('MET33').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 33 está incorreto'; 
                };
                if (vm.plano.cont34  ==  null   ||   vm.plano.cont34  ==  '')   {
                    document.getElementById('CONT34').style.backgroundColor = 'yellow';
                    document.getElementById('CONT34').focus();
                    return  'O campo CONTEÚDO PREVISTO 34 está incorreto'; 
                };
                if (vm.plano.met34  ==  null  ||  vm.plano.met34   ==   '')   { 
                    document.getElementById('MET34').style.backgroundColor = 'yellow';
                    document.getElementById('MET34').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 34 está incorreto'; 
                };
                if (vm.plano.cont35  ==  null   ||   vm.plano.cont35  ==  '')   {
                    document.getElementById('CONT35').style.backgroundColor = 'yellow';
                    document.getElementById('CONT35').focus();
                    return  'O campo CONTEÚDO PREVISTO 35 está incorreto'; 
                };
                if (vm.plano.met35  ==  null  ||  vm.plano.met35   ==   '')   { 
                    document.getElementById('MET35').style.backgroundColor = 'yellow';
                    document.getElementById('MET35').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 35 está incorreto'; 
                };
                if (vm.plano.cont36  ==  null   ||   vm.plano.cont36  ==  '')   {
                    document.getElementById('CONT36').style.backgroundColor = 'yellow';
                    document.getElementById('CONT36').focus();
                    return  'O campo CONTEÚDO PREVISTO 36 está incorreto'; 
                };
                if (vm.plano.met36  ==  null  ||  vm.plano.met36   ==   '')   { 
                    document.getElementById('MET36').style.backgroundColor = 'yellow';
                    document.getElementById('MET36').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 36 está incorreto'; 
                };
                if (vm.plano.cont37  ==  null   ||   vm.plano.cont37  ==  '')   {
                    document.getElementById('CONT37').style.backgroundColor = 'yellow';
                    document.getElementById('CONT37').focus();
                    return  'O campo CONTEÚDO PREVISTO 37 está incorreto'; 
                };
                if (vm.plano.met37  ==  null  ||  vm.plano.met37   ==   '')   { 
                    document.getElementById('MET37').style.backgroundColor = 'yellow';
                    document.getElementById('MET37').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 37 está incorreto'; 
                };
                if (vm.plano.cont38  ==  null   ||   vm.plano.cont38  ==  '')   {
                    document.getElementById('CONT38').style.backgroundColor = 'yellow';
                    document.getElementById('CONT38').focus();
                    return  'O campo CONTEÚDO PREVISTO 38 está incorreto'; 
                };
                if (vm.plano.met38  ==  null  ||  vm.plano.met38   ==   '')   { 
                    document.getElementById('MET38').style.backgroundColor = 'yellow';
                    document.getElementById('MET38').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 38 está incorreto'; 
                };
                if (vm.plano.cont39  ==  null   ||   vm.plano.cont39  ==  '')   {
                    document.getElementById('CONT39').style.backgroundColor = 'yellow';
                    document.getElementById('CONT39').focus();
                    return  'O campo CONTEÚDO PREVISTO 39 está incorreto'; 
                };
                if (vm.plano.met39  ==  null  ||  vm.plano.met39   ==   '')   { 
                    document.getElementById('MET39').style.backgroundColor = 'yellow';
                    document.getElementById('MET39').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 39 está incorreto'; 
                };
                if (vm.plano.cont40  ==  null   ||   vm.plano.cont40  ==  '')   {
                    document.getElementById('CONT40').style.backgroundColor = 'yellow';
                    document.getElementById('CONT40').focus();
                    return  'O campo CONTEÚDO PREVISTO 40 está incorreto'; 
                };
                if (vm.plano.met40  ==  null  ||  vm.plano.met40   ==   '')   { 
                    document.getElementById('MET40').style.backgroundColor = 'yellow';
                    document.getElementById('MET40').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 40 está incorreto'; 
                };
            }

            if (vm.plano.chTotal >= 70) {
                if (vm.plano.cont11  ==  null   ||   vm.plano.cont11  ==  '')   {
                    document.getElementById('CONT11').style.backgroundColor = 'yellow';
                    document.getElementById('CONT11').focus();
                    return  'O campo CONTEÚDO PREVISTO 11 está incorreto'; 
                };
                if (vm.plano.met11  ==  null  ||  vm.plano.met11   ==   '')   { 
                    document.getElementById('MET11').style.backgroundColor = 'yellow';
                    document.getElementById('MET11').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 11 está incorreto'; 
                };
                if (vm.plano.cont12  ==  null   ||   vm.plano.cont12  ==  '')   {
                    document.getElementById('CONT12').style.backgroundColor = 'yellow';
                    document.getElementById('CONT12').focus();
                    return  'O campo CONTEÚDO PREVISTO 12 está incorreto'; 
                };
                if (vm.plano.met12  ==  null  ||  vm.plano.met12   ==   '')   { 
                    document.getElementById('MET12').style.backgroundColor = 'yellow';
                    document.getElementById('MET12').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 12 está incorreto'; 
                };
                if (vm.plano.cont13  ==  null   ||   vm.plano.cont13  ==  '')   {
                    document.getElementById('CONT13').style.backgroundColor = 'yellow';
                    document.getElementById('CONT13').focus();
                    return  'O campo CONTEÚDO PREVISTO 13 está incorreto'; 
                };
                if (vm.plano.met13  ==  null  ||  vm.plano.met13   ==   '')   { 
                    document.getElementById('MET13').style.backgroundColor = 'yellow';
                    document.getElementById('MET13').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 13 está incorreto'; 
                };
                if (vm.plano.cont14  ==  null   ||   vm.plano.cont14  ==  '')   {
                    document.getElementById('CONT14').style.backgroundColor = 'yellow';
                    document.getElementById('CONT14').focus();
                    return  'O campo CONTEÚDO PREVISTO 14 está incorreto'; 
                };
                if (vm.plano.met14  ==  null  ||  vm.plano.met14   ==   '')   { 
                    document.getElementById('MET14').style.backgroundColor = 'yellow';
                    document.getElementById('MET14').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 14 está incorreto'; 
                };
                if (vm.plano.cont15  ==  null   ||   vm.plano.cont15  ==  '')   {
                    document.getElementById('CONT15').style.backgroundColor = 'yellow';
                    document.getElementById('CONT15').focus();
                    return  'O campo CONTEÚDO PREVISTO 15 está incorreto'; 
                };
                if (vm.plano.met15  ==  null  ||  vm.plano.met15   ==   '')   { 
                    document.getElementById('MET15').style.backgroundColor = 'yellow';
                    document.getElementById('MET15').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 15 está incorreto'; 
                };
                if (vm.plano.cont16  ==  null   ||   vm.plano.cont16  ==  '')   {
                    document.getElementById('CONT16').style.backgroundColor = 'yellow';
                    document.getElementById('CONT16').focus();
                    return  'O campo CONTEÚDO PREVISTO 16 está incorreto'; 
                };
                if (vm.plano.met16  ==  null  ||  vm.plano.met16   ==   '')   { 
                    document.getElementById('MET16').style.backgroundColor = 'yellow';
                    document.getElementById('MET16').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 16 está incorreto'; 
                };
                if (vm.plano.cont17  ==  null   ||   vm.plano.cont17  ==  '')   {
                    document.getElementById('CONT17').style.backgroundColor = 'yellow';
                    document.getElementById('CONT17').focus();
                    return  'O campo CONTEÚDO PREVISTO 17 está incorreto'; 
                };
                if (vm.plano.met17  ==  null  ||  vm.plano.met17   ==   '')   { 
                    document.getElementById('MET17').style.backgroundColor = 'yellow';
                    document.getElementById('MET17').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 17 está incorreto'; 
                };
                if (vm.plano.cont18  ==  null   ||   vm.plano.cont18  ==  '')   {
                    document.getElementById('CONT18').style.backgroundColor = 'yellow';
                    document.getElementById('CONT18').focus();
                    return  'O campo CONTEÚDO PREVISTO 18 está incorreto'; 
                };
                if (vm.plano.met18  ==  null  ||  vm.plano.met18   ==   '')   { 
                    document.getElementById('MET18').style.backgroundColor = 'yellow';
                    document.getElementById('MET18').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 18 está incorreto'; 
                };
                if (vm.plano.cont19  ==  null   ||   vm.plano.cont19  ==  '')   {
                    document.getElementById('CONT19').style.backgroundColor = 'yellow';
                    document.getElementById('CONT19').focus();
                    return  'O campo CONTEÚDO PREVISTO 19 está incorreto'; 
                };
                if (vm.plano.met19  ==  null  ||  vm.plano.met19   ==   '')   { 
                    document.getElementById('MET19').style.backgroundColor = 'yellow';
                    document.getElementById('MET19').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 19 está incorreto'; 
                };
                if (vm.plano.cont20  ==  null   ||   vm.plano.cont20  ==  '')   {
                    document.getElementById('CONT20').style.backgroundColor = 'yellow';
                    document.getElementById('CONT20').focus();
                    return  'O campo CONTEÚDO PREVISTO 20 está incorreto'; 
                };
                if (vm.plano.met20  ==  null  ||  vm.plano.met20   ==   '')   { 
                    document.getElementById('MET20').style.backgroundColor = 'yellow';
                    document.getElementById('MET20').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 20 está incorreto'; 
                };


                if (vm.plano.cont41  ==  null   ||   vm.plano.cont41  ==  '')   {
                    document.getElementById('CONT41').style.backgroundColor = 'yellow';
                    document.getElementById('CONT41').focus();
                    return  'O campo CONTEÚDO PREVISTO 41 está incorreto'; 
                };
                if (vm.plano.met41  ==  null  ||  vm.plano.met41   ==   '')   { 
                    document.getElementById('MET41').style.backgroundColor = 'yellow';
                    document.getElementById('MET41').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 41 está incorreto'; 
                };
                if (vm.plano.cont42  ==  null   ||   vm.plano.cont42  ==  '')   {
                    document.getElementById('CONT42').style.backgroundColor = 'yellow';
                    document.getElementById('CONT42').focus();
                    return  'O campo CONTEÚDO PREVISTO 42 está incorreto'; 
                };
                if (vm.plano.met42  ==  null  ||  vm.plano.met42   ==   '')   { 
                    document.getElementById('MET42').style.backgroundColor = 'yellow';
                    document.getElementById('MET42').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 42 está incorreto'; 
                };
                if (vm.plano.cont43  ==  null   ||   vm.plano.cont43  ==  '')   {
                    document.getElementById('CONT43').style.backgroundColor = 'yellow';
                    document.getElementById('CONT43').focus();
                    return  'O campo CONTEÚDO PREVISTO 43 está incorreto'; 
                };
                if (vm.plano.met43  ==  null  ||  vm.plano.met43   ==   '')   { 
                    document.getElementById('MET43').style.backgroundColor = 'yellow';
                    document.getElementById('MET43').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 43 está incorreto'; 
                };
                if (vm.plano.cont44  ==  null   ||   vm.plano.cont44  ==  '')   {
                    document.getElementById('CONT44').style.backgroundColor = 'yellow';
                    document.getElementById('CONT44').focus();
                    return  'O campo CONTEÚDO PREVISTO 44 está incorreto'; 
                };
                if (vm.plano.met44  ==  null  ||  vm.plano.met44   ==   '')   { 
                    document.getElementById('MET44').style.backgroundColor = 'yellow';
                    document.getElementById('MET44').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 44 está incorreto'; 
                };
                if (vm.plano.cont45  ==  null   ||   vm.plano.cont45  ==  '')   {
                    document.getElementById('CONT45').style.backgroundColor = 'yellow';
                    document.getElementById('CONT45').focus();
                    return  'O campo CONTEÚDO PREVISTO 45 está incorreto'; 
                };
                if (vm.plano.met45  ==  null  ||  vm.plano.met45   ==   '')   { 
                    document.getElementById('MET45').style.backgroundColor = 'yellow';
                    document.getElementById('MET45').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 45 está incorreto'; 
                };
                if (vm.plano.cont46  ==  null   ||   vm.plano.cont46  ==  '')   {
                    document.getElementById('CONT46').style.backgroundColor = 'yellow';
                    document.getElementById('CONT46').focus();
                    return  'O campo CONTEÚDO PREVISTO 46 está incorreto'; 
                };
                if (vm.plano.met46  ==  null  ||  vm.plano.met46   ==   '')   { 
                    document.getElementById('MET46').style.backgroundColor = 'yellow';
                    document.getElementById('MET46').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 46 está incorreto'; 
                };
                if (vm.plano.cont47  ==  null   ||   vm.plano.cont47  ==  '')   {
                    document.getElementById('CONT47').style.backgroundColor = 'yellow';
                    document.getElementById('CONT47').focus();
                    return  'O campo CONTEÚDO PREVISTO 47 está incorreto'; 
                };
                if (vm.plano.met47  ==  null  ||  vm.plano.met47   ==   '')   { 
                    document.getElementById('MET47').style.backgroundColor = 'yellow';
                    document.getElementById('MET47').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 47 está incorreto'; 
                };
                if (vm.plano.cont48  ==  null   ||   vm.plano.cont48  ==  '')   {
                    document.getElementById('CONT48').style.backgroundColor = 'yellow';
                    document.getElementById('CONT48').focus();
                    return  'O campo CONTEÚDO PREVISTO 48 está incorreto'; 
                };
                if (vm.plano.met48  ==  null  ||  vm.plano.met48   ==   '')   { 
                    document.getElementById('MET48').style.backgroundColor = 'yellow';
                    document.getElementById('MET48').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 48 está incorreto'; 
                };
                if (vm.plano.cont49  ==  null   ||   vm.plano.cont49  ==  '')   {
                    document.getElementById('CONT49').style.backgroundColor = 'yellow';
                    document.getElementById('CONT49').focus();
                    return  'O campo CONTEÚDO PREVISTO 49 está incorreto'; 
                };
                if (vm.plano.met49  ==  null  ||  vm.plano.met49   ==   '')   { 
                    document.getElementById('MET49').style.backgroundColor = 'yellow';
                    document.getElementById('MET49').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 49 está incorreto'; 
                };
                if (vm.plano.cont50  ==  null   ||   vm.plano.cont50  ==  '')   {
                    document.getElementById('CONT50').style.backgroundColor = 'yellow';
                    document.getElementById('CONT50').focus();
                    return  'O campo CONTEÚDO PREVISTO 50 está incorreto'; 
                };
                if (vm.plano.met50  ==  null  ||  vm.plano.met50   ==   '')   { 
                    document.getElementById('MET50').style.backgroundColor = 'yellow';
                    document.getElementById('MET50').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 50 está incorreto'; 
                };


            }

            if (vm.plano.chTotal >= 120) {

                if (vm.plano.cont21  ==  null   ||   vm.plano.cont21  ==  '')   {
                    document.getElementById('CONT21').style.backgroundColor = 'yellow';
                    document.getElementById('CONT21').focus();
                    return  'O campo CONTEÚDO PREVISTO 21 está incorreto'; 
                };
                if (vm.plano.met21  ==  null  ||  vm.plano.met21   ==   '')   { 
                    document.getElementById('MET21').style.backgroundColor = 'yellow';
                    document.getElementById('MET21').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 21 está incorreto'; 
                };
                if (vm.plano.cont22  ==  null   ||   vm.plano.cont22  ==  '')   {
                    document.getElementById('CONT22').style.backgroundColor = 'yellow';
                    document.getElementById('CONT22').focus();
                    return  'O campo CONTEÚDO PREVISTO 22 está incorreto'; 
                };
                if (vm.plano.met22  ==  null  ||  vm.plano.met22   ==   '')   { 
                    document.getElementById('MET22').style.backgroundColor = 'yellow';
                    document.getElementById('MET22').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 22 está incorreto'; 
                };
                if (vm.plano.cont23  ==  null   ||   vm.plano.cont23  ==  '')   {
                    document.getElementById('CONT23').style.backgroundColor = 'yellow';
                    document.getElementById('CONT23').focus();
                    return  'O campo CONTEÚDO PREVISTO 23 está incorreto'; 
                };
                if (vm.plano.met23  ==  null  ||  vm.plano.met23   ==   '')   { 
                    document.getElementById('MET23').style.backgroundColor = 'yellow';
                    document.getElementById('MET23').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 23 está incorreto'; 
                };
                if (vm.plano.cont24  ==  null   ||   vm.plano.cont24  ==  '')   {
                    document.getElementById('CONT24').style.backgroundColor = 'yellow';
                    document.getElementById('CONT24').focus();
                    return  'O campo CONTEÚDO PREVISTO 24 está incorreto'; 
                };
                if (vm.plano.met24  ==  null  ||  vm.plano.met24   ==   '')   { 
                    document.getElementById('MET24').style.backgroundColor = 'yellow';
                    document.getElementById('MET24').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 24 está incorreto'; 
                };
                if (vm.plano.cont25  ==  null   ||   vm.plano.cont25  ==  '')   {
                    document.getElementById('CONT25').style.backgroundColor = 'yellow';
                    document.getElementById('CONT25').focus();
                    return  'O campo CONTEÚDO PREVISTO 25 está incorreto'; 
                };
                if (vm.plano.met25  ==  null  ||  vm.plano.met25   ==   '')   { 
                    document.getElementById('MET25').style.backgroundColor = 'yellow';
                    document.getElementById('MET25').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 25 está incorreto'; 
                };
                if (vm.plano.cont26  ==  null   ||   vm.plano.cont26  ==  '')   {
                    document.getElementById('CONT26').style.backgroundColor = 'yellow';
                    document.getElementById('CONT26').focus();
                    return  'O campo CONTEÚDO PREVISTO 26 está incorreto'; 
                };
                if (vm.plano.met26  ==  null  ||  vm.plano.met26   ==   '')   { 
                    document.getElementById('MET26').style.backgroundColor = 'yellow';
                    document.getElementById('MET26').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 26 está incorreto'; 
                };
                if (vm.plano.cont27  ==  null   ||   vm.plano.cont27  ==  '')   {
                    document.getElementById('CONT27').style.backgroundColor = 'yellow';
                    document.getElementById('CONT27').focus();
                    return  'O campo CONTEÚDO PREVISTO 27 está incorreto'; 
                };
                if (vm.plano.met27  ==  null  ||  vm.plano.met27   ==   '')   { 
                    document.getElementById('MET27').style.backgroundColor = 'yellow';
                    document.getElementById('MET27').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 27 está incorreto'; 
                };
                if (vm.plano.cont28  ==  null   ||   vm.plano.cont28  ==  '')   {
                    document.getElementById('CONT28').style.backgroundColor = 'yellow';
                    document.getElementById('CONT28').focus();
                    return  'O campo CONTEÚDO PREVISTO 28 está incorreto'; 
                };
                if (vm.plano.met28  ==  null  ||  vm.plano.met28   ==   '')   { 
                    document.getElementById('MET28').style.backgroundColor = 'yellow';
                    document.getElementById('MET28').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 28 está incorreto'; 
                };
                if (vm.plano.cont29  ==  null   ||   vm.plano.cont29  ==  '')   {
                    document.getElementById('CONT29').style.backgroundColor = 'yellow';
                    document.getElementById('CONT29').focus();
                    return  'O campo CONTEÚDO PREVISTO 29 está incorreto'; 
                };
                if (vm.plano.met29  ==  null  ||  vm.plano.met29   ==   '')   { 
                    document.getElementById('MET29').style.backgroundColor = 'yellow';
                    document.getElementById('MET29').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 29 está incorreto'; 
                };
                if (vm.plano.cont30  ==  null   ||   vm.plano.cont30  ==  '')   {
                    document.getElementById('CONT30').style.backgroundColor = 'yellow';
                    document.getElementById('CONT30').focus();
                    return  'O campo CONTEÚDO PREVISTO 30 está incorreto'; 
                };
                if (vm.plano.met30  ==  null  ||  vm.plano.met30   ==   '')   { 
                    document.getElementById('MET30').style.backgroundColor = 'yellow';
                    document.getElementById('MET30').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 30 está incorreto'; 
                };


                if (vm.plano.cont51  ==  null   ||   vm.plano.cont51  ==  '')   {
                    document.getElementById('CONT51').style.backgroundColor = 'yellow';
                    document.getElementById('CONT51').focus();
                    return  'O campo CONTEÚDO PREVISTO 51 está incorreto'; 
                };
                if (vm.plano.met51  ==  null  ||  vm.plano.met51   ==   '')   { 
                    document.getElementById('MET51').style.backgroundColor = 'yellow';
                    document.getElementById('MET51').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 51 está incorreto'; 
                };
                if (vm.plano.cont52  ==  null   ||   vm.plano.cont52  ==  '')   {
                    document.getElementById('CONT52').style.backgroundColor = 'yellow';
                    document.getElementById('CONT52').focus();
                    return  'O campo CONTEÚDO PREVISTO 52 está incorreto'; 
                };
                if (vm.plano.met52  ==  null  ||  vm.plano.met52   ==   '')   { 
                    document.getElementById('MET52').style.backgroundColor = 'yellow';
                    document.getElementById('MET52').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 52 está incorreto'; 
                };
                if (vm.plano.cont53  ==  null   ||   vm.plano.cont53  ==  '')   {
                    document.getElementById('CONT53').style.backgroundColor = 'yellow';
                    document.getElementById('CONT53').focus();
                    return  'O campo CONTEÚDO PREVISTO 53 está incorreto'; 
                };
                if (vm.plano.met53  ==  null  ||  vm.plano.met53   ==   '')   { 
                    document.getElementById('MET53').style.backgroundColor = 'yellow';
                    document.getElementById('MET53').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 53 está incorreto'; 
                };
                if (vm.plano.cont54  ==  null   ||   vm.plano.cont54  ==  '')   {
                    document.getElementById('CONT54').style.backgroundColor = 'yellow';
                    document.getElementById('CONT54').focus();
                    return  'O campo CONTEÚDO PREVISTO 54 está incorreto'; 
                };
                if (vm.plano.met54  ==  null  ||  vm.plano.met54   ==   '')   { 
                    document.getElementById('MET54').style.backgroundColor = 'yellow';
                    document.getElementById('MET54').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 54 está incorreto'; 
                };
                if (vm.plano.cont55  ==  null   ||   vm.plano.cont55  ==  '')   {
                    document.getElementById('CONT55').style.backgroundColor = 'yellow';
                    document.getElementById('CONT55').focus();
                    return  'O campo CONTEÚDO PREVISTO 55 está incorreto'; 
                };
                if (vm.plano.met55  ==  null  ||  vm.plano.met55   ==   '')   { 
                    document.getElementById('MET55').style.backgroundColor = 'yellow';
                    document.getElementById('MET55').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 55 está incorreto'; 
                };
                if (vm.plano.cont56  ==  null   ||   vm.plano.cont56  ==  '')   {
                    document.getElementById('CONT56').style.backgroundColor = 'yellow';
                    document.getElementById('CONT56').focus();
                    return  'O campo CONTEÚDO PREVISTO 56 está incorreto'; 
                };
                if (vm.plano.met56  ==  null  ||  vm.plano.met56   ==   '')   { 
                    document.getElementById('MET56').style.backgroundColor = 'yellow';
                    document.getElementById('MET56').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 56 está incorreto'; 
                };
                if (vm.plano.cont57  ==  null   ||   vm.plano.cont57  ==  '')   {
                    document.getElementById('CONT57').style.backgroundColor = 'yellow';
                    document.getElementById('CONT57').focus();
                    return  'O campo CONTEÚDO PREVISTO 57 está incorreto'; 
                };
                if (vm.plano.met57  ==  null  ||  vm.plano.met57   ==   '')   { 
                    document.getElementById('MET57').style.backgroundColor = 'yellow';
                    document.getElementById('MET57').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 57 está incorreto'; 
                };
                if (vm.plano.cont58  ==  null   ||   vm.plano.cont58  ==  '')   {
                    document.getElementById('CONT58').style.backgroundColor = 'yellow';
                    document.getElementById('CONT58').focus();
                    return  'O campo CONTEÚDO PREVISTO 58 está incorreto'; 
                };
                if (vm.plano.met58  ==  null  ||  vm.plano.met58   ==   '')   { 
                    document.getElementById('MET58').style.backgroundColor = 'yellow';
                    document.getElementById('MET58').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 58 está incorreto'; 
                };
                if (vm.plano.cont59  ==  null   ||   vm.plano.cont59  ==  '')   {
                    document.getElementById('CONT59').style.backgroundColor = 'yellow';
                    document.getElementById('CONT59').focus();
                    return  'O campo CONTEÚDO PREVISTO 59 está incorreto'; 
                };
                if (vm.plano.met59  ==  null  ||  vm.plano.met59   ==   '')   { 
                    document.getElementById('MET59').style.backgroundColor = 'yellow';
                    document.getElementById('MET59').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 59 está incorreto'; 
                };
                if (vm.plano.cont60  ==  null   ||   vm.plano.cont60  ==  '')   {
                    document.getElementById('CONT60').style.backgroundColor = 'yellow';
                    document.getElementById('CONT60').focus();
                    return  'O campo CONTEÚDO PREVISTO 60 está incorreto'; 
                };
                if (vm.plano.met60  ==  null  ||  vm.plano.met60   ==   '')   { 
                    document.getElementById('MET60').style.backgroundColor = 'yellow';
                    document.getElementById('MET60').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 60 está incorreto'; 
                };

            }

            if (vm.plano.bBasica  ==  null  ||  vm.plano.bBasica ==   '')   { 
                document.getElementById('BBASICA').style.backgroundColor = 'yellow';
                document.getElementById('BBASICA').focus(); 
                return  'O campo Bibliografia Básica está incorreto'; 
            };

            if (vm.plano.bCompl ==  null  ||  vm.plano.bCompl ==   '')   { 
                document.getElementById('BCOMPL').style.backgroundColor = 'yellow';
                document.getElementById('BCOMPL').focus(); 
                return  'O campo Bibliografia Complementar está incorreto'; 
            };

            if (vm.plano.bApro ==  null  ||  vm.plano.bApro ==   '')   { 
                document.getElementById('BAPRO').style.backgroundColor = 'yellow';
                document.getElementById('BAPRO').focus(); 
                return  'O campo Bibliografia de Aprofundamento está incorreto'; 
            };

            for (var i = 0; i < vm.listaObs.length; i++) {
                var e = vm.listaObs[i];

                if (e.etapa != "FINALIZADO") {
                    return 'Existem observações não finalizadas na lista. clique em <span style="color: lightseagreen;" class="glyphicon glyphicon-ok"></span> para finalizá-las';
                }

            }

            return "OK";
        }

    }
})();