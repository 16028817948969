(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('VinculaAluno', {
            parent: 'app',
            url: '/vincula-aluno',
            data: {
                authorities: ['ROLE_UNIVEL_PLAY'],
                pageTitle: 'Vincular Aluno'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/prova-sala/vinculaAluno/vinculaAluno.html',
                    controller: 'VinculaAlunoController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();