(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('QuestaoProvaOnlineDetailController', QuestaoProvaOnlineDetailController);

    QuestaoProvaOnlineDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'QuestaoProvaOnline'];

    function QuestaoProvaOnlineDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, QuestaoProvaOnline) {
        var vm = this;

        vm.questaoProvaOnline = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('vestibularApp:questaoProvaOnlineUpdate', function(event, result) {
            vm.questaoProvaOnline = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
