(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('CursosPorPolo', CursosPorPolo);

    CursosPorPolo.$inject = ['$resource', 'DateUtils'];

    function CursosPorPolo($resource, DateUtils) {
        var resourceUrl = 'api/cursosPorPolo/:id/:idps';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataProva = DateUtils.convertLocalDateFromServer(data.dataProva);
                    }
                    return data;
                }
            }
        });
    }
})();