(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('PrevVendedorPainelService', PrevVendedorPainelService);

    PrevVendedorPainelService.$inject = ['$resource', 'DateUtils'];

    function PrevVendedorPainelService($resource, DateUtils) {
        var resourceUrl = '/api/prev-vend-painel/:campanha';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }
})();