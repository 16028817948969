(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('UserByID', UserByID);

    UserByID.$inject = ['$resource'];

    function UserByID($resource) {
        var service = $resource('api/users/:id', {}, {
            'query': { method: 'GET', isArray: true }
        });

        return service;
    }
})();