(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ProvaOnline', ProvaOnline);

    ProvaOnline.$inject = ['$resource', 'DateUtils'];

    function ProvaOnline($resource, DateUtils) {
        var resourceUrl = 'api/prova-onlines/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        /*data.dataCriacao = DateUtils.convertLocalDateFromServer(data.dataCriacao);
                        data.dataCorrecao = DateUtils.convertLocalDateFromServer(data.dataCorrecao);*/
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    /*copy.dataCriacao = DateUtils.convertLocalDateToServer(copy.dataCriacao);
                    copy.dataCorrecao = DateUtils.convertLocalDateToServer(copy.dataCorrecao);*/
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    /*copy.dataCriacao = DateUtils.convertLocalDateToServer(copy.dataCriacao);
                    copy.dataCorrecao = DateUtils.convertLocalDateToServer(copy.dataCorrecao);*/
                    return angular.toJson(copy);
                }
            }
        });
    }
})();