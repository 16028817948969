(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('TemaProvaOnlineController', TemaProvaOnlineController);

    TemaProvaOnlineController.$inject = ['$scope', '$state', '$http', 'BuscaTemasAtivoService'];

    function TemaProvaOnlineController($scope, $state, $http, BuscaTemasAtivoService) {
        var vm = this;

        vm.selecionar = selecionar;

        vm.temas = BuscaTemasAtivoService.query();

        console.log(vm.temas);

        function selecionar(tema) {
            sessionStorage.setItem('tema', JSON.stringify(tema));
            $state.go('provaOnline');
        }
    }
})();