(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('NovoPlanoEnsinoController', NovoPlanoEnsinoController);

    NovoPlanoEnsinoController.$inject = ['$state', 'Principal', 'BuscaDiscPlano', 'PlanoEnsino', 'PlanoEnsinoItens', 'BuscaLivrosPlanoService', 'PlanoEnsinoBibliografia', 'PlanoEnsinoLog', 'BuscaDiscCadastradas', 'PlanoEnsinoDisciplina', 'SalvaItensPlanoService', 'BuscaDiscsPlanoIdService', 'BuscaItensPlanoService'];

    function NovoPlanoEnsinoController($state, Principal, BuscaDiscPlano, PlanoEnsino, PlanoEnsinoItens, BuscaLivrosPlanoService, PlanoEnsinoBibliografia, PlanoEnsinoLog, BuscaDiscCadastradas, PlanoEnsinoDisciplina, SalvaItensPlanoService, BuscaDiscsPlanoIdService, BuscaItensPlanoService) {
        var vm = this;

        //vm.createPlano = create;
        vm.nextForm = nextForm;
        vm.getLivros = getLivros;
        vm.addLivro = addLivro;
        vm.removeLivro = removeLivro;
        vm.consultarDisc = consultarDisc;
        vm.addDisc = addDisc;
        vm.removeDisc = removeDisc;
        vm.addTxtCriterios = addTxtCriterios;

        vm.planos = null;
        vm.disciplinas = null;
        vm.livroSelecionado = null;
        vm.LNE = {};
        vm.plano = {};
        vm.rowspan = 0;
        vm.livrosB = null;
        vm.livrosC = null;
        vm.user = {};
        vm.cordenador = false;
        vm.nucleo = false;
        vm.disciplinas = [];
        vm.biblioApro = [];
        vm.biblioBasica = [];
        vm.biblioCompl = [];
        vm.disciplinaSelecionada = [];
        vm.itensPlano = [];
        vm.showResumo = false;
        vm.resultadoDisc = [];

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                init();
            });
        };

        vm.setConteudos = function() {

            switch (vm.plano.chTotal) {
                case "35":
                    vm.rowspan = 10;
                    vm.limiteAs = 10;
                    break;
                case "40":
                    vm.rowspan = 10;
                    vm.limiteAs = 10;
                    break;
                case "70":
                    vm.rowspan = 20;
                    vm.limiteAs = 20;
                    break;
                case "80":
                    vm.rowspan = 20;
                    vm.limiteAs = 20;
                    break;
                case "100":
                    vm.rowspan = 30;
                    vm.limiteAs = 20;
                    break;
                case "120":
                    vm.rowspan = 30;
                    vm.limiteAs = 20;
                    break;
                case "130":
                    vm.rowspan = 30;
                    vm.limiteAs = 20;
                    break;
                case "160":
                    vm.rowspan = 30;
                    vm.limiteAs = 20;
                    break;
            }
        }

        function init() {
            var temp = JSON.parse(sessionStorage.getItem('plano-ensino'));
            console.log(temp);


            vm.discTemp = JSON.parse(sessionStorage.getItem('plano-ensino-disciplinas'));
            vm.itensPlano = JSON.parse(sessionStorage.getItem('plano-ensino-itens'));

            if (temp != null) {
                switch (temp.chTotal) {
                    case 35:
                        vm.rowspan = 10;
                        vm.limiteAs = 10;
                        temp.chTotal = "35";
                        break;
                    case 40:
                        vm.rowspan = 10;
                        vm.limiteAs = 10;
                        temp.chTotal = "40";
                        break;
                    case 70:
                        vm.rowspan = 20;
                        vm.limiteAs = 20;
                        temp.chTotal = "70";
                        break;
                    case 80:
                        vm.rowspan = 20;
                        vm.limiteAs = 20;
                        temp.chTotal = "80";
                        break;
                    case 100:
                        vm.rowspan = 30;
                        vm.limiteAs = 20;
                        temp.chTotal = "100";
                        break;
                    case 120:
                        vm.rowspan = 30;
                        vm.limiteAs = 20;
                        temp.chTotal = "120";
                        break;
                    case 130:
                        vm.rowspan = 30;
                        vm.limiteAs = 20;
                        temp.chTotal = "130";
                        break;
                    case 160:
                        vm.rowspan = 30;
                        vm.limiteAs = 40;
                        temp.chTotal = "160";
                        break;
                }

                if (vm.discTemp != null) {
                    vm.disciplinaSelecionada = vm.discTemp;
                } else {
                    BuscaDiscsPlanoIdService.query({ id: temp.id }, function(turmas) {
                        vm.disciplinaSelecionada = turmas;
                    }, function(status) { console.log(status) });
                }

                if (vm.itensPlano == null) {
                    BuscaItensPlanoService.query({ id: temp.id },
                        function(itens) {
                            for (var i = 0; i < itens.length; i++) {
                                var e = itens[i];

                                if (e.tipo == 'COMPETENCIA') { temp.competencias = e.texto; };
                                if (e.tipo == 'EMENTA') { temp.ementa = e.texto; };
                                if (e.tipo == 'AS1') { temp.contAs1 = e.texto; };
                                if (e.tipo == 'AS2') { temp.contAs2 = e.texto; };
                                if (e.tipo == 'RECINOV1') { temp.recursos1 = e.texto; };
                                if (e.tipo == 'RECINOV2') { temp.recursos2 = e.texto; };
                                if (e.tipo == 'INSTAVA1B') { temp.inst1b = e.texto; };
                                if (e.tipo == 'INSTAVA2B') { temp.inst2b = e.texto; };
                                if (e.tipo == 'BBASICA') { temp.bBasica = e.texto; };
                                if (e.tipo == 'BCOMPL') { temp.bCompl = e.texto; };
                                if (e.tipo == 'BAPRO') { temp.bApro = e.texto; };
                                if (e.tipo == 'OBSG') { temp.obsg = e.texto; };


                                if (e.tipo == 'CONT1') { temp.cont1 = e.texto; };
                                if (e.tipo == 'CONT2') { temp.cont2 = e.texto; };
                                if (e.tipo == 'CONT3') { temp.cont3 = e.texto; };
                                if (e.tipo == 'CONT4') { temp.cont4 = e.texto; };
                                if (e.tipo == 'CONT5') { temp.cont5 = e.texto; };
                                if (e.tipo == 'CONT6') { temp.cont6 = e.texto; };
                                if (e.tipo == 'CONT7') { temp.cont7 = e.texto; };
                                if (e.tipo == 'CONT8') { temp.cont8 = e.texto; };
                                if (e.tipo == 'CONT9') { temp.cont9 = e.texto; };
                                if (e.tipo == 'CONT10') { temp.cont10 = e.texto; };
                                if (e.tipo == 'CONT11') { temp.cont11 = e.texto; };
                                if (e.tipo == 'CONT12') { temp.cont12 = e.texto; };
                                if (e.tipo == 'CONT13') { temp.cont13 = e.texto; };
                                if (e.tipo == 'CONT14') { temp.cont14 = e.texto; };
                                if (e.tipo == 'CONT15') { temp.cont15 = e.texto; };
                                if (e.tipo == 'CONT16') { temp.cont16 = e.texto; };
                                if (e.tipo == 'CONT17') { temp.cont17 = e.texto; };
                                if (e.tipo == 'CONT18') { temp.cont18 = e.texto; };
                                if (e.tipo == 'CONT19') { temp.cont19 = e.texto; };
                                if (e.tipo == 'CONT20') { temp.cont20 = e.texto; };
                                if (e.tipo == 'CONT21') { temp.cont21 = e.texto; };
                                if (e.tipo == 'CONT22') { temp.cont22 = e.texto; };
                                if (e.tipo == 'CONT23') { temp.cont23 = e.texto; };
                                if (e.tipo == 'CONT24') { temp.cont24 = e.texto; };
                                if (e.tipo == 'CONT25') { temp.cont25 = e.texto; };
                                if (e.tipo == 'CONT26') { temp.cont26 = e.texto; };
                                if (e.tipo == 'CONT27') { temp.cont27 = e.texto; };
                                if (e.tipo == 'CONT28') { temp.cont28 = e.texto; };
                                if (e.tipo == 'CONT29') { temp.cont29 = e.texto; };
                                if (e.tipo == 'CONT30') { temp.cont30 = e.texto; };
                                if (e.tipo == 'CONT31') { temp.cont31 = e.texto; };
                                if (e.tipo == 'CONT32') { temp.cont32 = e.texto; };
                                if (e.tipo == 'CONT33') { temp.cont33 = e.texto; };
                                if (e.tipo == 'CONT34') { temp.cont34 = e.texto; };
                                if (e.tipo == 'CONT35') { temp.cont35 = e.texto; };
                                if (e.tipo == 'CONT36') { temp.cont36 = e.texto; };
                                if (e.tipo == 'CONT37') { temp.cont37 = e.texto; };
                                if (e.tipo == 'CONT38') { temp.cont38 = e.texto; };
                                if (e.tipo == 'CONT39') { temp.cont39 = e.texto; };
                                if (e.tipo == 'CONT40') { temp.cont40 = e.texto; };
                                if (e.tipo == 'CONT41') { temp.cont41 = e.texto; };
                                if (e.tipo == 'CONT42') { temp.cont42 = e.texto; };
                                if (e.tipo == 'CONT43') { temp.cont43 = e.texto; };
                                if (e.tipo == 'CONT44') { temp.cont44 = e.texto; };
                                if (e.tipo == 'CONT45') { temp.cont45 = e.texto; };
                                if (e.tipo == 'CONT46') { temp.cont46 = e.texto; };
                                if (e.tipo == 'CONT47') { temp.cont47 = e.texto; };
                                if (e.tipo == 'CONT48') { temp.cont48 = e.texto; };
                                if (e.tipo == 'CONT49') { temp.cont49 = e.texto; };
                                if (e.tipo == 'CONT50') { temp.cont50 = e.texto; };
                                if (e.tipo == 'CONT51') { temp.cont51 = e.texto; };
                                if (e.tipo == 'CONT52') { temp.cont52 = e.texto; };
                                if (e.tipo == 'CONT53') { temp.cont53 = e.texto; };
                                if (e.tipo == 'CONT54') { temp.cont54 = e.texto; };
                                if (e.tipo == 'CONT55') { temp.cont55 = e.texto; };
                                if (e.tipo == 'CONT56') { temp.cont56 = e.texto; };
                                if (e.tipo == 'CONT57') { temp.cont57 = e.texto; };
                                if (e.tipo == 'CONT58') { temp.cont58 = e.texto; };
                                if (e.tipo == 'CONT59') { temp.cont59 = e.texto; };
                                if (e.tipo == 'CONT60') { temp.cont60 = e.texto; };

                                if (e.tipo == 'MET1') { temp.met1 = e.texto; };
                                if (e.tipo == 'MET2') { temp.met2 = e.texto; };
                                if (e.tipo == 'MET3') { temp.met3 = e.texto; };
                                if (e.tipo == 'MET4') { temp.met4 = e.texto; };
                                if (e.tipo == 'MET5') { temp.met5 = e.texto; };
                                if (e.tipo == 'MET6') { temp.met6 = e.texto; };
                                if (e.tipo == 'MET7') { temp.met7 = e.texto; };
                                if (e.tipo == 'MET8') { temp.met8 = e.texto; };
                                if (e.tipo == 'MET9') { temp.met9 = e.texto; };
                                if (e.tipo == 'MET10') { temp.met10 = e.texto; };
                                if (e.tipo == 'MET11') { temp.met11 = e.texto; };
                                if (e.tipo == 'MET12') { temp.met12 = e.texto; };
                                if (e.tipo == 'MET13') { temp.met13 = e.texto; };
                                if (e.tipo == 'MET14') { temp.met14 = e.texto; };
                                if (e.tipo == 'MET15') { temp.met15 = e.texto; };
                                if (e.tipo == 'MET16') { temp.met16 = e.texto; };
                                if (e.tipo == 'MET17') { temp.met17 = e.texto; };
                                if (e.tipo == 'MET18') { temp.met18 = e.texto; };
                                if (e.tipo == 'MET19') { temp.met19 = e.texto; };
                                if (e.tipo == 'MET20') { temp.met20 = e.texto; };
                                if (e.tipo == 'MET21') { temp.met21 = e.texto; };
                                if (e.tipo == 'MET22') { temp.met22 = e.texto; };
                                if (e.tipo == 'MET23') { temp.met23 = e.texto; };
                                if (e.tipo == 'MET24') { temp.met24 = e.texto; };
                                if (e.tipo == 'MET25') { temp.met25 = e.texto; };
                                if (e.tipo == 'MET26') { temp.met26 = e.texto; };
                                if (e.tipo == 'MET27') { temp.met27 = e.texto; };
                                if (e.tipo == 'MET28') { temp.met28 = e.texto; };
                                if (e.tipo == 'MET29') { temp.met29 = e.texto; };
                                if (e.tipo == 'MET30') { temp.met30 = e.texto; };
                                if (e.tipo == 'MET31') { temp.met31 = e.texto; };
                                if (e.tipo == 'MET32') { temp.met32 = e.texto; };
                                if (e.tipo == 'MET33') { temp.met33 = e.texto; };
                                if (e.tipo == 'MET34') { temp.met34 = e.texto; };
                                if (e.tipo == 'MET35') { temp.met35 = e.texto; };
                                if (e.tipo == 'MET36') { temp.met36 = e.texto; };
                                if (e.tipo == 'MET37') { temp.met37 = e.texto; };
                                if (e.tipo == 'MET38') { temp.met38 = e.texto; };
                                if (e.tipo == 'MET39') { temp.met39 = e.texto; };
                                if (e.tipo == 'MET40') { temp.met40 = e.texto; };
                                if (e.tipo == 'MET41') { temp.met41 = e.texto; };
                                if (e.tipo == 'MET42') { temp.met42 = e.texto; };
                                if (e.tipo == 'MET43') { temp.met43 = e.texto; };
                                if (e.tipo == 'MET44') { temp.met44 = e.texto; };
                                if (e.tipo == 'MET45') { temp.met45 = e.texto; };
                                if (e.tipo == 'MET46') { temp.met46 = e.texto; };
                                if (e.tipo == 'MET47') { temp.met47 = e.texto; };
                                if (e.tipo == 'MET48') { temp.met48 = e.texto; };
                                if (e.tipo == 'MET49') { temp.met49 = e.texto; };
                                if (e.tipo == 'MET50') { temp.met50 = e.texto; };
                                if (e.tipo == 'MET51') { temp.met51 = e.texto; };
                                if (e.tipo == 'MET52') { temp.met52 = e.texto; };
                                if (e.tipo == 'MET53') { temp.met53 = e.texto; };
                                if (e.tipo == 'MET54') { temp.met54 = e.texto; };
                                if (e.tipo == 'MET55') { temp.met55 = e.texto; };
                                if (e.tipo == 'MET56') { temp.met56 = e.texto; };
                                if (e.tipo == 'MET57') { temp.met57 = e.texto; };
                                if (e.tipo == 'MET58') { temp.met58 = e.texto; };
                                if (e.tipo == 'MET59') { temp.met59 = e.texto; };
                                if (e.tipo == 'MET60') { temp.met60 = e.texto; };

                            };
                        },
                        function(status) { console.log(status); });
                }

                console.log(vm.limiteAs);
                vm.plano = temp;
                vm.etapaForm = vm.plano.etapa;

            } else {
                vm.etapaForm = "INICIO";
                vm.plano.chPratica = 0;
                vm.plano.chTeorica = 0;
                vm.plano.chExtensao = 0;
                vm.plano.chAs = 0;
                vm.plano.chPresencial = 0;
                vm.plano.chOnline = 0;
                vm.plano.chEad = 0;

                vm.plano.pontAb1 = 0;
                vm.plano.pontAb2 = 0;

                vm.plano.pontAs1 = 0;
                vm.plano.pontAs2 = 0;

                vm.plano.pontTrab1 = 0;
                vm.plano.pontTrab2 = 0;

                vm.plano.inst1b = "";
                vm.plano.inst2b = "";

                vm.plano.tipoPlano = "DISCIPLINA";
            }
        }

        function addDisc(item) {
            var erro = false;

            for (var h = 0; h < vm.disciplinaSelecionada.length; h++) {
                var b = vm.disciplinaSelecionada[h];
                console.log(b);
                if (item.codCurso != b.codCurso) {
                    erro = true;
                    showMessage("alert alert-danger", "Desculpe, essa disciplina pertence a um curso diferente das selecionadas anteriormente.");
                } else if (item.turno != b.turno) {
                    erro = true;
                    showMessage("alert alert-danger", "Desculpe, essa disciplina pertence a um turno diferente das selecionadas anteriormente.");
                } else if (item.disciplina != b.disciplina) {
                    erro = true;
                    showMessage("alert alert-danger", "Desculpe, essa disciplina é diferente das selecionadas anteriormente.");
                }
            }

            if (erro == false) {
                for (var i = 0; i < vm.resultadoDisc.length; i++) {
                    var d = vm.resultadoDisc[i];
                    if (item.idTurmaDisc == d.idTurmaDisc) {
                        vm.resultadoDisc.splice(i, 1);
                        vm.disciplinaSelecionada.push(item);
                    }
                }
            }
        }

        function removeDisc(item) {
            for (var i = 0; i < vm.disciplinaSelecionada.length; i++) {
                var d = vm.disciplinaSelecionada[i];
                if (item.idTurmaDisc == d.idTurmaDisc) {
                    vm.disciplinaSelecionada.splice(i, 1);
                    vm.resultadoDisc.push(item);
                }
            }
        }

        function consultarDisc() {

            var obj = {
                disciplina: vm.consultaDisc.disc,
                curso: vm.consultaDisc.curso,
                tipoUser: vm.plano.tipoPlano
            }

            vm.resultadoDisc = [];
            BuscaDiscPlano.query(obj,
                function(data) {
                    console.log(data);
                    BuscaDiscCadastradas.query(
                        function(discs) {
                            for (var i = 0; i < data.length; i++) {
                                var d = data[i];
                                if (!discs.includes(d.idTurmaDisc)) {
                                    vm.resultadoDisc.push(d);
                                }
                            }
                            console.log(vm.resultadoDisc);
                        },
                        function(status) {
                            console.log(status);
                        });
                },
                function(status) {
                    console.log(status);
                });
        }

        function getLivros(tipo) {
            BuscaLivrosPlanoService.query({ chave: vm.consultaLivro },
                function(data) {

                    if (tipo == 'BASICA') {
                        vm.livrosB = data;
                    }

                    if (tipo == 'COMPLEMENTAR') {
                        vm.livrosC = data;
                    }

                    console.log(vm.livrosB);
                    console.log(vm.livrosC);
                },
                function(status) {
                    console.log(status);
                });
        }

        function insereLog(log) {
            PlanoEnsinoLog.save(log);
        }

        function addLivro(item, posse, tipo) {
            vm.livroSelecionado = item;
            vm.tipoLivro = tipo;
            var valida = validaCampos(10);

            if (valida == "OK") {

                switch (tipo) {
                    case "BASICA":
                        item.tipo = "BASICA";
                        item.posse = posse;
                        item.idPlano = vm.plano.id;
                        vm.biblioBasica.push(vm.livroSelecionado);
                        vm.livroSelecionado = null;
                        vm.LNE = null;
                        break;
                    case "COMPLEMENTAR":
                        item.tipo = "COMPLEMENTAR";
                        item.posse = posse;
                        item.idPlano = vm.plano.id;
                        vm.biblioCompl.push(vm.livroSelecionado);
                        vm.livroSelecionado = null;
                        vm.LNE = null;
                        break;
                    case "APROFUNDAMENTO":
                        item.tipo = "APROFUNDAMENTO";
                        item.posse = posse;
                        item.idPlano = vm.plano.id;
                        vm.biblioApro.push(vm.livroSelecionado);
                        vm.livroSelecionado = null;
                        vm.LNE = null;
                        break;
                }
            } else {
                showMessage("alert alert-warning", valida);
            }
        }

        function removeLivro(tipo, item) {
            switch (tipo) {
                case "BASICA":
                    for (var i = 0; i < vm.biblioBasica.length; i++) {
                        var element = vm.biblioBasica[i];
                        if (element.idTombo == item.idTombo) {
                            vm.biblioBasica.splice(i, 1);
                        }
                    }
                    break;
                case "COMPLEMENTAR":
                    for (var i = 0; i < vm.biblioCompl.length; i++) {
                        var element = vm.biblioCompl[i];
                        if (element.idTombo == item.idTombo) {
                            vm.biblioCompl.splice(i, 1);
                        }
                    }
                    break;
                case "APROFUNDAMENTO":
                    for (var i = 0; i < vm.biblioApro.length; i++) {
                        var element = vm.biblioApro[i];
                        if (element.idTombo == item.idTombo) {
                            vm.biblioApro.splice(i, 1);
                        }
                    }
                    break;
            }
        }

        function addTxtCriterios(tipo, bim) {
            switch (tipo) {
                case 'INTEGRADORA':
                    if (vm.controleCriteriosIntegradora1 == true) {
                        if (bim == 1) {
                            vm.plano.inst1b = vm.plano.inst1b + " Avaliação Integradora: 2,0 pontos, sendo a distribuição da nota de acordo com o aproveitamento do aluno: Alunos que acertarem 60% ou mais da avaliação integradora receberão 2,0 pontos; Alunos que acertarem entre 40% a 59,9% receberão 1,5 ponto; Alunos que acertarem entre 20% e 39,9% receberão 1,0 ponto; Alunos que acertarem menos que 20% receberão 0,5 ponto. ";
                        }
                    }
                    if (vm.controleCriteriosIntegradora2 == true) {
                        if (bim == 2) {
                            vm.plano.inst2b = vm.plano.inst2b + " Avaliação Integradora: 2,0 pontos, sendo a distribuição da nota de acordo com o aproveitamento do aluno: Alunos que acertarem 60% ou mais da avaliação integradora receberão 2,0 pontos; Alunos que acertarem entre 40% a 59,9% receberão 1,5 ponto; Alunos que acertarem entre 20% e 39,9% receberão 1,0 ponto; Alunos que acertarem menos que 20% receberão 0,5 ponto. ";
                        }
                    }
                    break;
                case 'AVA':
                    if (vm.controleCriteriosAva1 == true) {
                        if (bim == 1) {
                            vm.plano.inst1b = vm.plano.inst1b + " Atividades do AVA: Até 1,5, conforme o percentual de acerto das questões disponibizadas no Ambiente Virtual de Aprendizagem (AVA). ";
                        }
                    }
                    if (vm.controleCriteriosAva2 == true) {
                        if (bim == 2) {
                            vm.plano.inst2b = vm.plano.inst2b + " Atividades do AVA: Até 1,5, conforme o percentual de acerto das questões disponibizadas no Ambiente Virtual de Aprendizagem (AVA). ";
                        }
                    }
                    break;
            }
        }

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function nextForm(etapa) {
            console.log(vm.plano);
            var valida = validaCampos(etapa);

            if (valida == "OK") {

                if (etapa == 'FORM1') { // INICIO
                    if (vm.plano.etapa == null) {
                        var e = vm.disciplinaSelecionada[0];
                        vm.plano.curso = e.curso;
                        vm.plano.codCurso = e.codCurso;
                        vm.plano.disciplina = e.disciplina;
                        //vm.plano.codDisc = e.idTurmaDisc;
                        //vm.plano.turma = e.codTurma;
                        vm.plano.codPerlet = e.codPerlet;
                        vm.plano.idPerlet = e.idPerlet;
                        vm.plano.userCriacao = vm.account.login;
                        vm.plano.userAlteracao = vm.account.login;
                        vm.plano.professor = vm.account.firstName;
                        vm.plano.emailProfessor = vm.account.email;

                        sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                        sessionStorage.setItem('plano-ensino-disciplinas', JSON.stringify(vm.disciplinaSelecionada));
                    }
                }

                if (etapa == 'FORM2') { // CH
                    var etapaAnt = "FORM1";
                    vm.plano.etapa = "FORM2";
                    sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                    PlanoEnsino.save(vm.plano,
                        function(data) {
                            vm.plano.id = data.id;
                            vm.plano.dataCriacao = data.dataCriacao;
                            insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });

                            for (var i = 0; i < vm.disciplinaSelecionada.length; i++) {
                                var d = vm.disciplinaSelecionada[i];

                                PlanoEnsinoDisciplina.save({
                                    idPlano: vm.plano.id,
                                    codDisc: d.idTurmaDisc,
                                    idPerlet: d.idPerlet,
                                    turma: d.codTurma,
                                    disciplina: d.disciplina,
                                    createdDate: new Date(),
                                    createdUser: vm.account.login
                                }, function(data) {
                                    console.log(data);
                                }, function(result) {
                                    console.log(result);
                                });
                            }

                        });
                }

                if (etapa == 'FORM3') { // CONT
                    var etapaAnt = vm.plano.etapa;
                    vm.plano.etapa = etapa;
                    sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                    PlanoEnsino.update(vm.plano,
                        function(data) {
                            insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });
                        }
                    );
                }

                if (etapa == 'FORM4') { // INST
                    var etapaAnt = vm.plano.etapa;
                    vm.plano.etapa = etapa;
                    sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                    PlanoEnsino.update(vm.plano, function(data) {
                        insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });
                    });
                }

                if (etapa == 'FORM5') { // OBS
                    var etapaAnt = vm.plano.etapa;
                    vm.plano.etapa = etapa;
                    sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                    PlanoEnsino.update(vm.plano, function(data) {
                        insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });
                    });
                }

                if (etapa == 'FORM6') { // RESUMO
                    var etapaAnt = vm.plano.etapa;
                    vm.plano.etapa = etapa;
                    sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                    PlanoEnsino.update(vm.plano, function(data) {
                        insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });
                        addItensPlanoEnsino();
                    });
                }

                if (etapa == 'PENDENTE') { // FINAL
                    var etapaAnt = vm.plano.etapa;
                    vm.plano.etapa = etapa;
                    PlanoEnsino.update(vm.plano, function(data) {
                        insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });
                        vm.salvandoItens = true;
                        SalvaItensPlanoService.query(vm.itensPlano, function(data) {
                            vm.salvandoItens = false;
                            $state.go('plano-de-ensino');
                            showMessage("alert alert-success", data);
                        }, function(result) {
                            showMessage("alert alert-danger", result);
                        });
                    });
                }
                showMessage('invi', '');
                vm.etapaForm = etapa;

            } else {
                showMessage("alert alert-danger", valida);
            }
        }

        function validaCampos(etapa) {

            if (etapa == "FORM1") {
                if (vm.disciplinaSelecionada.length <= 0) {
                    return "Selecione ao menos uma disciplina para avançar.";
                }
            }

            if (etapa == "FORM2") {
                if (vm.plano.chPratica == null) {
                    document.getElementById('CHPRATICA').style.backgroundColor = 'yellow';
                    document.getElementById('CHPRATICA').focus();
                    return "O campo CH PRATICA está incorreto";
                }
                if (vm.plano.chTeorica == null) {
                    document.getElementById('CHTEORICA').style.backgroundColor = 'yellow';
                    document.getElementById('CHTEORICA').focus();
                    return "O campo CH TEÓRICA está incorreto";
                }
                if (vm.plano.chExtensao == null) {
                    document.getElementById('CHEXTENSAO').style.backgroundColor = 'yellow';
                    document.getElementById('CHEXTENSAO').focus();
                    return "O campo CH EXTENSÃO está incorreto";
                }
                if (vm.plano.chAs == null) {
                    document.getElementById('CHAS').style.backgroundColor = 'yellow';
                    document.getElementById('CHAS').focus();
                    return "O campo CH AS está incorreto";
                }
                if (vm.plano.chPresencial == null) {
                    document.getElementById('CHPRESENCIAL').style.backgroundColor = 'yellow';
                    document.getElementById('CHPRESENCIAL').focus();
                    return "O campo CH PRESENCIAL está incorreto";
                }
                if (vm.plano.chOnline == null) {
                    document.getElementById('CHONLINE').style.backgroundColor = 'yellow';
                    document.getElementById('CHONLINE').focus();
                    return "O campo CH ONLINE está incorreto";
                }
                if (vm.plano.chEad == null) {
                    document.getElementById('CHEAD').style.backgroundColor = 'yellow';
                    document.getElementById('CHEAD').focus();
                    return "O campo CH EAD está incorreto";
                }
                if (vm.plano.chTotal == null || vm.plano.chTotal == "") {
                    document.getElementById('CHTOTAL').style.backgroundColor = 'yellow';
                    document.getElementById('CHTOTAL').focus();
                    return "O campo CH TOTAL está incorreto";
                }

                var chCalculo1 = (vm.plano.chPresencial + vm.plano.chOnline + vm.plano.chEad);

                if (chCalculo1 != vm.plano.chTotal) {
                    document.getElementById('CHTOTAL').style.backgroundColor = 'yellow';
                    document.getElementById('CHTOTAL').focus();
                    return "O cálculo de Modalidade:(" + chCalculo1 + ") está divergente do campo CH TOTAL(" + vm.plano.chTotal + ").";
                }

                var chCalculo2 = (vm.plano.chPratica + vm.plano.chTeorica + vm.plano.chExtensao + vm.plano.chAs);

                if (chCalculo2 != vm.plano.chTotal) {
                    document.getElementById('CHTOTAL').style.backgroundColor = 'yellow';
                    document.getElementById('CHTOTAL').focus();
                    return "O cálculo de CH tipo: (" + chCalculo2 + ") está divergente do campo CH TOTAL(" + vm.plano.chTotal + ").";
                }

                if (vm.plano.ementa == null || vm.plano.ementa == "") {
                    document.getElementById('EMENTA').style.backgroundColor = 'yellow';
                    document.getElementById('EMENTA').focus();
                    return "O campo EMENTA está incorreto";
                }
                if (vm.plano.competencias == null || vm.plano.competencias == "") {
                    document.getElementById('COMPETENCIA').style.backgroundColor = 'yellow';
                    document.getElementById('COMPETENCIA').focus();
                    return "O campo COMPETÊNCIA está incorreto";
                }



            }

            if (etapa == "FORM3") {
                if (vm.rowspan >= 10) {

                    if (vm.plano.cont1  ==  null   ||   vm.plano.cont1  ==  '')   {
                        document.getElementById('CONT1').style.backgroundColor = 'yellow';
                        document.getElementById('CONT1').focus();
                        return  'O campo CONTEÚDO PREVISTO 1 está incorreto'; 
                    };
                    if (vm.plano.met1  ==  null  ||  vm.plano.met1   ==   '')   { 
                        document.getElementById('MET1').style.backgroundColor = 'yellow';
                        document.getElementById('MET1').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 1 está incorreto'; 
                    };
                    if (vm.plano.cont2  ==  null   ||   vm.plano.cont2  ==  '')   {
                        document.getElementById('CONT2').style.backgroundColor = 'yellow';
                        document.getElementById('CONT2').focus();
                        return  'O campo CONTEÚDO PREVISTO 2 está incorreto'; 
                    };
                    if (vm.plano.met2  ==  null  ||  vm.plano.met2   ==   '')   { 
                        document.getElementById('MET2').style.backgroundColor = 'yellow';
                        document.getElementById('MET2').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 2 está incorreto'; 
                    };
                    if (vm.plano.cont3  ==  null   ||   vm.plano.cont3  ==  '')   {
                        document.getElementById('CONT3').style.backgroundColor = 'yellow';
                        document.getElementById('CONT3').focus();
                        return  'O campo CONTEÚDO PREVISTO 3 está incorreto'; 
                    };
                    if (vm.plano.met3  ==  null  ||  vm.plano.met3   ==   '')   { 
                        document.getElementById('MET3').style.backgroundColor = 'yellow';
                        document.getElementById('MET3').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 3 está incorreto'; 
                    };
                    if (vm.plano.cont4  ==  null   ||   vm.plano.cont4  ==  '')   {
                        document.getElementById('CONT4').style.backgroundColor = 'yellow';
                        document.getElementById('CONT4').focus();
                        return  'O campo CONTEÚDO PREVISTO 4 está incorreto'; 
                    };
                    if (vm.plano.met4  ==  null  ||  vm.plano.met4   ==   '')   { 
                        document.getElementById('MET4').style.backgroundColor = 'yellow';
                        document.getElementById('MET4').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 4 está incorreto'; 
                    };
                    if (vm.plano.cont5  ==  null   ||   vm.plano.cont5  ==  '')   {
                        document.getElementById('CONT5').style.backgroundColor = 'yellow';
                        document.getElementById('CONT5').focus();
                        return  'O campo CONTEÚDO PREVISTO 5 está incorreto'; 
                    };
                    if (vm.plano.met5  ==  null  ||  vm.plano.met5   ==   '')   { 
                        document.getElementById('MET5').style.backgroundColor = 'yellow';
                        document.getElementById('MET5').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 5 está incorreto'; 
                    };
                    if (vm.plano.cont6  ==  null   ||   vm.plano.cont6  ==  '')   {
                        document.getElementById('CONT6').style.backgroundColor = 'yellow';
                        document.getElementById('CONT6').focus();
                        return  'O campo CONTEÚDO PREVISTO 6 está incorreto'; 
                    };
                    if (vm.plano.met6  ==  null  ||  vm.plano.met6   ==   '')   { 
                        document.getElementById('MET6').style.backgroundColor = 'yellow';
                        document.getElementById('MET6').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 6 está incorreto'; 
                    };
                    if (vm.plano.cont7  ==  null   ||   vm.plano.cont7  ==  '')   {
                        document.getElementById('CONT7').style.backgroundColor = 'yellow';
                        document.getElementById('CONT7').focus();
                        return  'O campo CONTEÚDO PREVISTO 7 está incorreto'; 
                    };
                    if (vm.plano.met7  ==  null  ||  vm.plano.met7   ==   '')   { 
                        document.getElementById('MET7').style.backgroundColor = 'yellow';
                        document.getElementById('MET7').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 7 está incorreto'; 
                    };
                    if (vm.plano.cont8  ==  null   ||   vm.plano.cont8  ==  '')   {
                        document.getElementById('CONT8').style.backgroundColor = 'yellow';
                        document.getElementById('CONT8').focus();
                        return  'O campo CONTEÚDO PREVISTO 8 está incorreto'; 
                    };
                    if (vm.plano.met8  ==  null  ||  vm.plano.met8   ==   '')   { 
                        document.getElementById('MET8').style.backgroundColor = 'yellow';
                        document.getElementById('MET8').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 8 está incorreto'; 
                    };
                    if (vm.plano.cont9  ==  null   ||   vm.plano.cont9  ==  '')   {
                        document.getElementById('CONT9').style.backgroundColor = 'yellow';
                        document.getElementById('CONT9').focus();
                        return  'O campo CONTEÚDO PREVISTO 9 está incorreto'; 
                    };
                    if (vm.plano.met9  ==  null  ||  vm.plano.met9   ==   '')   { 
                        document.getElementById('MET9').style.backgroundColor = 'yellow';
                        document.getElementById('MET9').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 9 está incorreto'; 
                    };
                    if (vm.plano.cont10  ==  null   ||   vm.plano.cont10  ==  '')   {
                        document.getElementById('CONT10').style.backgroundColor = 'yellow';
                        document.getElementById('CONT10').focus();
                        return  'O campo CONTEÚDO PREVISTO 10 está incorreto'; 
                    };
                    if (vm.plano.met10  ==  null  ||  vm.plano.met10   ==   '')   { 
                        document.getElementById('MET10').style.backgroundColor = 'yellow';
                        document.getElementById('MET10').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 10 está incorreto'; 
                    };

                    if (vm.plano.cont31  ==  null   ||   vm.plano.cont31  ==  '')   {
                        document.getElementById('CONT31').style.backgroundColor = 'yellow';
                        document.getElementById('CONT31').focus();
                        return  'O campo CONTEÚDO PREVISTO 31 está incorreto'; 
                    };
                    if (vm.plano.met31  ==  null  ||  vm.plano.met31   ==   '')   { 
                        document.getElementById('MET31').style.backgroundColor = 'yellow';
                        document.getElementById('MET31').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 31 está incorreto'; 
                    };
                    if (vm.plano.cont32  ==  null   ||   vm.plano.cont32  ==  '')   {
                        document.getElementById('CONT32').style.backgroundColor = 'yellow';
                        document.getElementById('CONT32').focus();
                        return  'O campo CONTEÚDO PREVISTO 32 está incorreto'; 
                    };
                    if (vm.plano.met32  ==  null  ||  vm.plano.met32   ==   '')   { 
                        document.getElementById('MET32').style.backgroundColor = 'yellow';
                        document.getElementById('MET32').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 32 está incorreto'; 
                    };
                    if (vm.plano.cont33  ==  null   ||   vm.plano.cont33  ==  '')   {
                        document.getElementById('CONT33').style.backgroundColor = 'yellow';
                        document.getElementById('CONT33').focus();
                        return  'O campo CONTEÚDO PREVISTO 33 está incorreto'; 
                    };
                    if (vm.plano.met33  ==  null  ||  vm.plano.met33   ==   '')   { 
                        document.getElementById('MET33').style.backgroundColor = 'yellow';
                        document.getElementById('MET33').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 33 está incorreto'; 
                    };
                    if (vm.plano.cont34  ==  null   ||   vm.plano.cont34  ==  '')   {
                        document.getElementById('CONT34').style.backgroundColor = 'yellow';
                        document.getElementById('CONT34').focus();
                        return  'O campo CONTEÚDO PREVISTO 34 está incorreto'; 
                    };
                    if (vm.plano.met34  ==  null  ||  vm.plano.met34   ==   '')   { 
                        document.getElementById('MET34').style.backgroundColor = 'yellow';
                        document.getElementById('MET34').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 34 está incorreto'; 
                    };
                    if (vm.plano.cont35  ==  null   ||   vm.plano.cont35  ==  '')   {
                        document.getElementById('CONT35').style.backgroundColor = 'yellow';
                        document.getElementById('CONT35').focus();
                        return  'O campo CONTEÚDO PREVISTO 35 está incorreto'; 
                    };
                    if (vm.plano.met35  ==  null  ||  vm.plano.met35   ==   '')   { 
                        document.getElementById('MET35').style.backgroundColor = 'yellow';
                        document.getElementById('MET35').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 35 está incorreto'; 
                    };
                    if (vm.plano.cont36  ==  null   ||   vm.plano.cont36  ==  '')   {
                        document.getElementById('CONT36').style.backgroundColor = 'yellow';
                        document.getElementById('CONT36').focus();
                        return  'O campo CONTEÚDO PREVISTO 36 está incorreto'; 
                    };
                    if (vm.plano.met36  ==  null  ||  vm.plano.met36   ==   '')   { 
                        document.getElementById('MET36').style.backgroundColor = 'yellow';
                        document.getElementById('MET36').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 36 está incorreto'; 
                    };
                    if (vm.plano.cont37  ==  null   ||   vm.plano.cont37  ==  '')   {
                        document.getElementById('CONT37').style.backgroundColor = 'yellow';
                        document.getElementById('CONT37').focus();
                        return  'O campo CONTEÚDO PREVISTO 37 está incorreto'; 
                    };
                    if (vm.plano.met37  ==  null  ||  vm.plano.met37   ==   '')   { 
                        document.getElementById('MET37').style.backgroundColor = 'yellow';
                        document.getElementById('MET37').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 37 está incorreto'; 
                    };
                    if (vm.plano.cont38  ==  null   ||   vm.plano.cont38  ==  '')   {
                        document.getElementById('CONT38').style.backgroundColor = 'yellow';
                        document.getElementById('CONT38').focus();
                        return  'O campo CONTEÚDO PREVISTO 38 está incorreto'; 
                    };
                    if (vm.plano.met38  ==  null  ||  vm.plano.met38   ==   '')   { 
                        document.getElementById('MET38').style.backgroundColor = 'yellow';
                        document.getElementById('MET38').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 38 está incorreto'; 
                    };
                    if (vm.plano.cont39  ==  null   ||   vm.plano.cont39  ==  '')   {
                        document.getElementById('CONT39').style.backgroundColor = 'yellow';
                        document.getElementById('CONT39').focus();
                        return  'O campo CONTEÚDO PREVISTO 39 está incorreto'; 
                    };
                    if (vm.plano.met39  ==  null  ||  vm.plano.met39   ==   '')   { 
                        document.getElementById('MET39').style.backgroundColor = 'yellow';
                        document.getElementById('MET39').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 39 está incorreto'; 
                    };
                    if (vm.plano.cont40  ==  null   ||   vm.plano.cont40  ==  '')   {
                        document.getElementById('CONT40').style.backgroundColor = 'yellow';
                        document.getElementById('CONT40').focus();
                        return  'O campo CONTEÚDO PREVISTO 40 está incorreto'; 
                    };
                    if (vm.plano.met40  ==  null  ||  vm.plano.met40   ==   '')   { 
                        document.getElementById('MET40').style.backgroundColor = 'yellow';
                        document.getElementById('MET40').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 40 está incorreto'; 
                    };
                }

                if (vm.rowspan >= 20) {
                    if (vm.plano.cont11  ==  null   ||   vm.plano.cont11  ==  '')   {
                        document.getElementById('CONT11').style.backgroundColor = 'yellow';
                        document.getElementById('CONT11').focus();
                        return  'O campo CONTEÚDO PREVISTO 11 está incorreto'; 
                    };
                    if (vm.plano.met11  ==  null  ||  vm.plano.met11   ==   '')   { 
                        document.getElementById('MET11').style.backgroundColor = 'yellow';
                        document.getElementById('MET11').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 11 está incorreto'; 
                    };
                    if (vm.plano.cont12  ==  null   ||   vm.plano.cont12  ==  '')   {
                        document.getElementById('CONT12').style.backgroundColor = 'yellow';
                        document.getElementById('CONT12').focus();
                        return  'O campo CONTEÚDO PREVISTO 12 está incorreto'; 
                    };
                    if (vm.plano.met12  ==  null  ||  vm.plano.met12   ==   '')   { 
                        document.getElementById('MET12').style.backgroundColor = 'yellow';
                        document.getElementById('MET12').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 12 está incorreto'; 
                    };
                    if (vm.plano.cont13  ==  null   ||   vm.plano.cont13  ==  '')   {
                        document.getElementById('CONT13').style.backgroundColor = 'yellow';
                        document.getElementById('CONT13').focus();
                        return  'O campo CONTEÚDO PREVISTO 13 está incorreto'; 
                    };
                    if (vm.plano.met13  ==  null  ||  vm.plano.met13   ==   '')   { 
                        document.getElementById('MET13').style.backgroundColor = 'yellow';
                        document.getElementById('MET13').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 13 está incorreto'; 
                    };
                    if (vm.plano.cont14  ==  null   ||   vm.plano.cont14  ==  '')   {
                        document.getElementById('CONT14').style.backgroundColor = 'yellow';
                        document.getElementById('CONT14').focus();
                        return  'O campo CONTEÚDO PREVISTO 14 está incorreto'; 
                    };
                    if (vm.plano.met14  ==  null  ||  vm.plano.met14   ==   '')   { 
                        document.getElementById('MET14').style.backgroundColor = 'yellow';
                        document.getElementById('MET14').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 14 está incorreto'; 
                    };
                    if (vm.plano.cont15  ==  null   ||   vm.plano.cont15  ==  '')   {
                        document.getElementById('CONT15').style.backgroundColor = 'yellow';
                        document.getElementById('CONT15').focus();
                        return  'O campo CONTEÚDO PREVISTO 15 está incorreto'; 
                    };
                    if (vm.plano.met15  ==  null  ||  vm.plano.met15   ==   '')   { 
                        document.getElementById('MET15').style.backgroundColor = 'yellow';
                        document.getElementById('MET15').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 15 está incorreto'; 
                    };
                    if (vm.plano.cont16  ==  null   ||   vm.plano.cont16  ==  '')   {
                        document.getElementById('CONT16').style.backgroundColor = 'yellow';
                        document.getElementById('CONT16').focus();
                        return  'O campo CONTEÚDO PREVISTO 16 está incorreto'; 
                    };
                    if (vm.plano.met16  ==  null  ||  vm.plano.met16   ==   '')   { 
                        document.getElementById('MET16').style.backgroundColor = 'yellow';
                        document.getElementById('MET16').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 16 está incorreto'; 
                    };
                    if (vm.plano.cont17  ==  null   ||   vm.plano.cont17  ==  '')   {
                        document.getElementById('CONT17').style.backgroundColor = 'yellow';
                        document.getElementById('CONT17').focus();
                        return  'O campo CONTEÚDO PREVISTO 17 está incorreto'; 
                    };
                    if (vm.plano.met17  ==  null  ||  vm.plano.met17   ==   '')   { 
                        document.getElementById('MET17').style.backgroundColor = 'yellow';
                        document.getElementById('MET17').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 17 está incorreto'; 
                    };
                    if (vm.plano.cont18  ==  null   ||   vm.plano.cont18  ==  '')   {
                        document.getElementById('CONT18').style.backgroundColor = 'yellow';
                        document.getElementById('CONT18').focus();
                        return  'O campo CONTEÚDO PREVISTO 18 está incorreto'; 
                    };
                    if (vm.plano.met18  ==  null  ||  vm.plano.met18   ==   '')   { 
                        document.getElementById('MET18').style.backgroundColor = 'yellow';
                        document.getElementById('MET18').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 18 está incorreto'; 
                    };
                    if (vm.plano.cont19  ==  null   ||   vm.plano.cont19  ==  '')   {
                        document.getElementById('CONT19').style.backgroundColor = 'yellow';
                        document.getElementById('CONT19').focus();
                        return  'O campo CONTEÚDO PREVISTO 19 está incorreto'; 
                    };
                    if (vm.plano.met19  ==  null  ||  vm.plano.met19   ==   '')   { 
                        document.getElementById('MET19').style.backgroundColor = 'yellow';
                        document.getElementById('MET19').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 19 está incorreto'; 
                    };
                    if (vm.plano.cont20  ==  null   ||   vm.plano.cont20  ==  '')   {
                        document.getElementById('CONT20').style.backgroundColor = 'yellow';
                        document.getElementById('CONT20').focus();
                        return  'O campo CONTEÚDO PREVISTO 20 está incorreto'; 
                    };
                    if (vm.plano.met20  ==  null  ||  vm.plano.met20   ==   '')   { 
                        document.getElementById('MET20').style.backgroundColor = 'yellow';
                        document.getElementById('MET20').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 20 está incorreto'; 
                    };


                    if (vm.plano.cont41  ==  null   ||   vm.plano.cont41  ==  '')   {
                        document.getElementById('CONT41').style.backgroundColor = 'yellow';
                        document.getElementById('CONT41').focus();
                        return  'O campo CONTEÚDO PREVISTO 41 está incorreto'; 
                    };
                    if (vm.plano.met41  ==  null  ||  vm.plano.met41   ==   '')   { 
                        document.getElementById('MET41').style.backgroundColor = 'yellow';
                        document.getElementById('MET41').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 41 está incorreto'; 
                    };
                    if (vm.plano.cont42  ==  null   ||   vm.plano.cont42  ==  '')   {
                        document.getElementById('CONT42').style.backgroundColor = 'yellow';
                        document.getElementById('CONT42').focus();
                        return  'O campo CONTEÚDO PREVISTO 42 está incorreto'; 
                    };
                    if (vm.plano.met42  ==  null  ||  vm.plano.met42   ==   '')   { 
                        document.getElementById('MET42').style.backgroundColor = 'yellow';
                        document.getElementById('MET42').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 42 está incorreto'; 
                    };
                    if (vm.plano.cont43  ==  null   ||   vm.plano.cont43  ==  '')   {
                        document.getElementById('CONT43').style.backgroundColor = 'yellow';
                        document.getElementById('CONT43').focus();
                        return  'O campo CONTEÚDO PREVISTO 43 está incorreto'; 
                    };
                    if (vm.plano.met43  ==  null  ||  vm.plano.met43   ==   '')   { 
                        document.getElementById('MET43').style.backgroundColor = 'yellow';
                        document.getElementById('MET43').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 43 está incorreto'; 
                    };
                    if (vm.plano.cont44  ==  null   ||   vm.plano.cont44  ==  '')   {
                        document.getElementById('CONT44').style.backgroundColor = 'yellow';
                        document.getElementById('CONT44').focus();
                        return  'O campo CONTEÚDO PREVISTO 44 está incorreto'; 
                    };
                    if (vm.plano.met44  ==  null  ||  vm.plano.met44   ==   '')   { 
                        document.getElementById('MET44').style.backgroundColor = 'yellow';
                        document.getElementById('MET44').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 44 está incorreto'; 
                    };
                    if (vm.plano.cont45  ==  null   ||   vm.plano.cont45  ==  '')   {
                        document.getElementById('CONT45').style.backgroundColor = 'yellow';
                        document.getElementById('CONT45').focus();
                        return  'O campo CONTEÚDO PREVISTO 45 está incorreto'; 
                    };
                    if (vm.plano.met45  ==  null  ||  vm.plano.met45   ==   '')   { 
                        document.getElementById('MET45').style.backgroundColor = 'yellow';
                        document.getElementById('MET45').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 45 está incorreto'; 
                    };
                    if (vm.plano.cont46  ==  null   ||   vm.plano.cont46  ==  '')   {
                        document.getElementById('CONT46').style.backgroundColor = 'yellow';
                        document.getElementById('CONT46').focus();
                        return  'O campo CONTEÚDO PREVISTO 46 está incorreto'; 
                    };
                    if (vm.plano.met46  ==  null  ||  vm.plano.met46   ==   '')   { 
                        document.getElementById('MET46').style.backgroundColor = 'yellow';
                        document.getElementById('MET46').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 46 está incorreto'; 
                    };
                    if (vm.plano.cont47  ==  null   ||   vm.plano.cont47  ==  '')   {
                        document.getElementById('CONT47').style.backgroundColor = 'yellow';
                        document.getElementById('CONT47').focus();
                        return  'O campo CONTEÚDO PREVISTO 47 está incorreto'; 
                    };
                    if (vm.plano.met47  ==  null  ||  vm.plano.met47   ==   '')   { 
                        document.getElementById('MET47').style.backgroundColor = 'yellow';
                        document.getElementById('MET47').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 47 está incorreto'; 
                    };
                    if (vm.plano.cont48  ==  null   ||   vm.plano.cont48  ==  '')   {
                        document.getElementById('CONT48').style.backgroundColor = 'yellow';
                        document.getElementById('CONT48').focus();
                        return  'O campo CONTEÚDO PREVISTO 48 está incorreto'; 
                    };
                    if (vm.plano.met48  ==  null  ||  vm.plano.met48   ==   '')   { 
                        document.getElementById('MET48').style.backgroundColor = 'yellow';
                        document.getElementById('MET48').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 48 está incorreto'; 
                    };
                    if (vm.plano.cont49  ==  null   ||   vm.plano.cont49  ==  '')   {
                        document.getElementById('CONT49').style.backgroundColor = 'yellow';
                        document.getElementById('CONT49').focus();
                        return  'O campo CONTEÚDO PREVISTO 49 está incorreto'; 
                    };
                    if (vm.plano.met49  ==  null  ||  vm.plano.met49   ==   '')   { 
                        document.getElementById('MET49').style.backgroundColor = 'yellow';
                        document.getElementById('MET49').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 49 está incorreto'; 
                    };
                    if (vm.plano.cont50  ==  null   ||   vm.plano.cont50  ==  '')   {
                        document.getElementById('CONT50').style.backgroundColor = 'yellow';
                        document.getElementById('CONT50').focus();
                        return  'O campo CONTEÚDO PREVISTO 50 está incorreto'; 
                    };
                    if (vm.plano.met50  ==  null  ||  vm.plano.met50   ==   '')   { 
                        document.getElementById('MET50').style.backgroundColor = 'yellow';
                        document.getElementById('MET50').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 50 está incorreto'; 
                    };
                }

                if (vm.rowspan >= 30) {

                    if (vm.plano.cont21  ==  null   ||   vm.plano.cont21  ==  '')   {
                        document.getElementById('CONT21').style.backgroundColor = 'yellow';
                        document.getElementById('CONT21').focus();
                        return  'O campo CONTEÚDO PREVISTO 21 está incorreto'; 
                    };
                    if (vm.plano.met21  ==  null  ||  vm.plano.met21   ==   '')   { 
                        document.getElementById('MET21').style.backgroundColor = 'yellow';
                        document.getElementById('MET21').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 21 está incorreto'; 
                    };
                    if (vm.plano.cont22  ==  null   ||   vm.plano.cont22  ==  '')   {
                        document.getElementById('CONT22').style.backgroundColor = 'yellow';
                        document.getElementById('CONT22').focus();
                        return  'O campo CONTEÚDO PREVISTO 22 está incorreto'; 
                    };
                    if (vm.plano.met22  ==  null  ||  vm.plano.met22   ==   '')   { 
                        document.getElementById('MET22').style.backgroundColor = 'yellow';
                        document.getElementById('MET22').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 22 está incorreto'; 
                    };
                    if (vm.plano.cont23  ==  null   ||   vm.plano.cont23  ==  '')   {
                        document.getElementById('CONT23').style.backgroundColor = 'yellow';
                        document.getElementById('CONT23').focus();
                        return  'O campo CONTEÚDO PREVISTO 23 está incorreto'; 
                    };
                    if (vm.plano.met23  ==  null  ||  vm.plano.met23   ==   '')   { 
                        document.getElementById('MET23').style.backgroundColor = 'yellow';
                        document.getElementById('MET23').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 23 está incorreto'; 
                    };
                    if (vm.plano.cont24  ==  null   ||   vm.plano.cont24  ==  '')   {
                        document.getElementById('CONT24').style.backgroundColor = 'yellow';
                        document.getElementById('CONT24').focus();
                        return  'O campo CONTEÚDO PREVISTO 24 está incorreto'; 
                    };
                    if (vm.plano.met24  ==  null  ||  vm.plano.met24   ==   '')   { 
                        document.getElementById('MET24').style.backgroundColor = 'yellow';
                        document.getElementById('MET24').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 24 está incorreto'; 
                    };
                    if (vm.plano.cont25  ==  null   ||   vm.plano.cont25  ==  '')   {
                        document.getElementById('CONT25').style.backgroundColor = 'yellow';
                        document.getElementById('CONT25').focus();
                        return  'O campo CONTEÚDO PREVISTO 25 está incorreto'; 
                    };
                    if (vm.plano.met25  ==  null  ||  vm.plano.met25   ==   '')   { 
                        document.getElementById('MET25').style.backgroundColor = 'yellow';
                        document.getElementById('MET25').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 25 está incorreto'; 
                    };
                    if (vm.plano.cont26  ==  null   ||   vm.plano.cont26  ==  '')   {
                        document.getElementById('CONT26').style.backgroundColor = 'yellow';
                        document.getElementById('CONT26').focus();
                        return  'O campo CONTEÚDO PREVISTO 26 está incorreto'; 
                    };
                    if (vm.plano.met26  ==  null  ||  vm.plano.met26   ==   '')   { 
                        document.getElementById('MET26').style.backgroundColor = 'yellow';
                        document.getElementById('MET26').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 26 está incorreto'; 
                    };
                    if (vm.plano.cont27  ==  null   ||   vm.plano.cont27  ==  '')   {
                        document.getElementById('CONT27').style.backgroundColor = 'yellow';
                        document.getElementById('CONT27').focus();
                        return  'O campo CONTEÚDO PREVISTO 27 está incorreto'; 
                    };
                    if (vm.plano.met27  ==  null  ||  vm.plano.met27   ==   '')   { 
                        document.getElementById('MET27').style.backgroundColor = 'yellow';
                        document.getElementById('MET27').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 27 está incorreto'; 
                    };
                    if (vm.plano.cont28  ==  null   ||   vm.plano.cont28  ==  '')   {
                        document.getElementById('CONT28').style.backgroundColor = 'yellow';
                        document.getElementById('CONT28').focus();
                        return  'O campo CONTEÚDO PREVISTO 28 está incorreto'; 
                    };
                    if (vm.plano.met28  ==  null  ||  vm.plano.met28   ==   '')   { 
                        document.getElementById('MET28').style.backgroundColor = 'yellow';
                        document.getElementById('MET28').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 28 está incorreto'; 
                    };
                    if (vm.plano.cont29  ==  null   ||   vm.plano.cont29  ==  '')   {
                        document.getElementById('CONT29').style.backgroundColor = 'yellow';
                        document.getElementById('CONT29').focus();
                        return  'O campo CONTEÚDO PREVISTO 29 está incorreto'; 
                    };
                    if (vm.plano.met29  ==  null  ||  vm.plano.met29   ==   '')   { 
                        document.getElementById('MET29').style.backgroundColor = 'yellow';
                        document.getElementById('MET29').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 29 está incorreto'; 
                    };
                    if (vm.plano.cont30  ==  null   ||   vm.plano.cont30  ==  '')   {
                        document.getElementById('CONT30').style.backgroundColor = 'yellow';
                        document.getElementById('CONT30').focus();
                        return  'O campo CONTEÚDO PREVISTO 30 está incorreto'; 
                    };
                    if (vm.plano.met30  ==  null  ||  vm.plano.met30   ==   '')   { 
                        document.getElementById('MET30').style.backgroundColor = 'yellow';
                        document.getElementById('MET30').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 30 está incorreto'; 
                    };

                    if (vm.plano.cont51  ==  null   ||   vm.plano.cont51  ==  '')   {
                        document.getElementById('CONT51').style.backgroundColor = 'yellow';
                        document.getElementById('CONT51').focus();
                        return  'O campo CONTEÚDO PREVISTO 51 está incorreto'; 
                    };
                    if (vm.plano.met51  ==  null  ||  vm.plano.met51   ==   '')   { 
                        document.getElementById('MET51').style.backgroundColor = 'yellow';
                        document.getElementById('MET51').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 51 está incorreto'; 
                    };
                    if (vm.plano.cont52  ==  null   ||   vm.plano.cont52  ==  '')   {
                        document.getElementById('CONT52').style.backgroundColor = 'yellow';
                        document.getElementById('CONT52').focus();
                        return  'O campo CONTEÚDO PREVISTO 52 está incorreto'; 
                    };
                    if (vm.plano.met52  ==  null  ||  vm.plano.met52   ==   '')   { 
                        document.getElementById('MET52').style.backgroundColor = 'yellow';
                        document.getElementById('MET52').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 52 está incorreto'; 
                    };
                    if (vm.plano.cont53  ==  null   ||   vm.plano.cont53  ==  '')   {
                        document.getElementById('CONT53').style.backgroundColor = 'yellow';
                        document.getElementById('CONT53').focus();
                        return  'O campo CONTEÚDO PREVISTO 53 está incorreto'; 
                    };
                    if (vm.plano.met53  ==  null  ||  vm.plano.met53   ==   '')   { 
                        document.getElementById('MET53').style.backgroundColor = 'yellow';
                        document.getElementById('MET53').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 53 está incorreto'; 
                    };
                    if (vm.plano.cont54  ==  null   ||   vm.plano.cont54  ==  '')   {
                        document.getElementById('CONT54').style.backgroundColor = 'yellow';
                        document.getElementById('CONT54').focus();
                        return  'O campo CONTEÚDO PREVISTO 54 está incorreto'; 
                    };
                    if (vm.plano.met54  ==  null  ||  vm.plano.met54   ==   '')   { 
                        document.getElementById('MET54').style.backgroundColor = 'yellow';
                        document.getElementById('MET54').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 54 está incorreto'; 
                    };
                    if (vm.plano.cont55  ==  null   ||   vm.plano.cont55  ==  '')   {
                        document.getElementById('CONT55').style.backgroundColor = 'yellow';
                        document.getElementById('CONT55').focus();
                        return  'O campo CONTEÚDO PREVISTO 55 está incorreto'; 
                    };
                    if (vm.plano.met55  ==  null  ||  vm.plano.met55   ==   '')   { 
                        document.getElementById('MET55').style.backgroundColor = 'yellow';
                        document.getElementById('MET55').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 55 está incorreto'; 
                    };
                    if (vm.plano.cont56  ==  null   ||   vm.plano.cont56  ==  '')   {
                        document.getElementById('CONT56').style.backgroundColor = 'yellow';
                        document.getElementById('CONT56').focus();
                        return  'O campo CONTEÚDO PREVISTO 56 está incorreto'; 
                    };
                    if (vm.plano.met56  ==  null  ||  vm.plano.met56   ==   '')   { 
                        document.getElementById('MET56').style.backgroundColor = 'yellow';
                        document.getElementById('MET56').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 56 está incorreto'; 
                    };
                    if (vm.plano.cont57  ==  null   ||   vm.plano.cont57  ==  '')   {
                        document.getElementById('CONT57').style.backgroundColor = 'yellow';
                        document.getElementById('CONT57').focus();
                        return  'O campo CONTEÚDO PREVISTO 57 está incorreto'; 
                    };
                    if (vm.plano.met57  ==  null  ||  vm.plano.met57   ==   '')   { 
                        document.getElementById('MET57').style.backgroundColor = 'yellow';
                        document.getElementById('MET57').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 57 está incorreto'; 
                    };
                    if (vm.plano.cont58  ==  null   ||   vm.plano.cont58  ==  '')   {
                        document.getElementById('CONT58').style.backgroundColor = 'yellow';
                        document.getElementById('CONT58').focus();
                        return  'O campo CONTEÚDO PREVISTO 58 está incorreto'; 
                    };
                    if (vm.plano.met58  ==  null  ||  vm.plano.met58   ==   '')   { 
                        document.getElementById('MET58').style.backgroundColor = 'yellow';
                        document.getElementById('MET58').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 58 está incorreto'; 
                    };
                    if (vm.plano.cont59  ==  null   ||   vm.plano.cont59  ==  '')   {
                        document.getElementById('CONT59').style.backgroundColor = 'yellow';
                        document.getElementById('CONT59').focus();
                        return  'O campo CONTEÚDO PREVISTO 59 está incorreto'; 
                    };
                    if (vm.plano.met59  ==  null  ||  vm.plano.met59   ==   '')   { 
                        document.getElementById('MET59').style.backgroundColor = 'yellow';
                        document.getElementById('MET59').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 59 está incorreto'; 
                    };
                    if (vm.plano.cont60  ==  null   ||   vm.plano.cont60  ==  '')   {
                        document.getElementById('CONT60').style.backgroundColor = 'yellow';
                        document.getElementById('CONT60').focus();
                        return  'O campo CONTEÚDO PREVISTO 60 está incorreto'; 
                    };
                    if (vm.plano.met60  ==  null  ||  vm.plano.met60   ==   '')   { 
                        document.getElementById('MET60').style.backgroundColor = 'yellow';
                        document.getElementById('MET60').focus(); 
                        return  'O campo METODOLOGIA PREVISTA 60 está incorreto'; 
                    };

                }
            }

            if (etapa == "FORM4") {
                if (vm.plano.contAs1 == null || vm.plano.contAs1 == "") {
                    document.getElementById('AS1').style.backgroundColor = 'yellow';
                    document.getElementById('AS1').focus();
                    return "O campo ATIVIDADES SUPERVISIONADAS 1 BIM está incorreto";
                }
                if (vm.plano.contAs2 == null || vm.plano.contAs2 == "") {
                    document.getElementById('AS2').style.backgroundColor = 'yellow';
                    document.getElementById('AS2').focus();
                    return "O campo ATIVIDADES SUPERVISIONADAS 2 BIM está incorreto";
                }

                if (vm.plano.recursos1 == null || vm.plano.recursos1 == "") {
                    document.getElementById('RECINOV1').style.backgroundColor = 'yellow';
                    document.getElementById('RECINOV1').focus();
                    return "O campo RECURSOS INOVADORES 1 BIM está incorreto";
                }
                // if (vm.plano.recursos2 == null || vm.plano.recursos2 == "") {
                //     document.getElementById('RECINOV2').style.backgroundColor = 'yellow';
                //     document.getElementById('RECINOV2').focus();
                //     return "O campo RECURSOS INOVADORES 2 BIM bim está incorreto";
                // }

                if (vm.plano.inst1b == null || vm.plano.inst1b == "") {
                    document.getElementById('INSTAVA1B').style.backgroundColor = 'yellow';
                    document.getElementById('INSTAVA1B').focus();
                    return "O campo INSTRUMENTOS AVALIATIVOS 1° BIMESTRE está incorreto";
                }
                if (vm.plano.inst2b == null || vm.plano.inst2b == "") {
                    document.getElementById('INSTAVA2B').style.backgroundColor = 'yellow';
                    document.getElementById('INSTAVA2B').focus();
                    return "O campo INSTRUMENTOS AVALIATIVOS 2° BIMESTRE está incorreto";
                }


                if (vm.plano.pontAs1 == null) {
                    document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAS1').focus();
                    return "O campo PONTUAÇÃO AS 1° BIMESTRE está incorreto";
                }
                if (vm.plano.pontAs2 == null) {
                    document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAS2').focus();
                    return "O campo PONTUAÇÃO AS 2° BIMESTRE está incorreto";
                }

                if (vm.plano.pontAb1 == null) {
                    document.getElementById('PONTAB1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAB1').focus();
                    return "O campo PONTUAÇÂO AVALIAÇÂO 1° BIMESTRE está incorreto";
                }

                if (vm.plano.pontAb2 == null) {
                    document.getElementById('PONTAB2').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAB2').focus();
                    return "O campo PONTUAÇÂO AVALIAÇÂO 2° BIMESTRE está incorreto";
                }


                if (vm.plano.pontTrab1 == null) {
                    document.getElementById('PONTTRAB1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTTRAB1').focus();
                    return "O campo PONTUAÇÃO DE TRABALHOS 1° BIMESTRE está incorreto";
                }

                if (vm.plano.pontTrab2 == null) {
                    document.getElementById('PONTTRAB2').style.backgroundColor = 'yellow';
                    document.getElementById('PONTTRAB2').focus();
                    return "O campo PONTUAÇÃO DE TRABALHOS 1° BIMESTRE está incorreto";
                }

                var bim1 = vm.plano.pontAb1 + vm.plano.pontAs1 + vm.plano.pontTrab1;
                var bim2 = vm.plano.pontAb2 + vm.plano.pontAs2 + vm.plano.pontTrab2;
                var as = vm.plano.pontAs1 + vm.plano.pontAs2;

                if (as > vm.limiteAs) {
                    document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                    return "A soma da Pontuação das AS( " + as + " ) ultrapassa o limite da Carga Horária: (" + vm.limiteAs + ").";
                }

                if (bim1 != 100) {
                    document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAB1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTTRAB1').style.backgroundColor = 'yellow';
                    return "A soma da Pontuação do 1° Bimestre( " + bim1 + " ) está diferente de 100";
                }

                if (bim2 != 100) {
                    document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAB2').style.backgroundColor = 'yellow';
                    document.getElementById('PONTTRAB2').style.backgroundColor = 'yellow';
                    return "A soma da Pontuação do 2° Bimestre( " + bim2 + " ) está diferente de 100";
                }
            }

            if (etapa == "FORM5") {
                if (vm.plano.bBasica  ==  null  ||  vm.plano.bBasica ==   '')   { 
                    document.getElementById('BBASICA').style.backgroundColor = 'yellow';
                    document.getElementById('BBASICA').focus(); 
                    return  'O campo Bibliografia Básica está incorreto'; 
                };

                if (vm.plano.bCompl ==  null  ||  vm.plano.bCompl ==   '')   { 
                    document.getElementById('BCOMPL').style.backgroundColor = 'yellow';
                    document.getElementById('BCOMPL').focus(); 
                    return  'O campo Bibliografia Complementar está incorreto'; 
                };

                if (vm.plano.bApro ==  null  ||  vm.plano.bApro ==   '')   { 
                    document.getElementById('BAPRO').style.backgroundColor = 'yellow';
                    document.getElementById('BAPRO').focus(); 
                    return  'O campo Bibliografia de Aprofundamento está incorreto'; 
                };

            }

            return "OK";
        }

        function addItensPlanoEnsino() {
            vm.itensPlano = [];

            vm.itensPlano.push({ tipo: "EMENTA", idPlano: vm.plano.id, texto: vm.plano.ementa, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "COMPETENCIA", idPlano: vm.plano.id, texto: vm.plano.competencias, userCriacao: vm.account.login, userAlteracao: vm.account.login });

            if (vm.rowspan >= 10) {
                vm.itensPlano.push({  tipo:   'CONT1',  idPlano:  vm.plano.id,  texto:  vm.plano.cont1,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET1',  idPlano:  vm.plano.id,  texto:  vm.plano.met1,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT2',  idPlano:  vm.plano.id,  texto:  vm.plano.cont2,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET2',  idPlano:  vm.plano.id,  texto:  vm.plano.met2,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT3',  idPlano:  vm.plano.id,  texto:  vm.plano.cont3,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET3',  idPlano:  vm.plano.id,  texto:  vm.plano.met3,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT4',  idPlano:  vm.plano.id,  texto:  vm.plano.cont4,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET4',  idPlano:  vm.plano.id,  texto:  vm.plano.met4,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT5',  idPlano:  vm.plano.id,  texto:  vm.plano.cont5,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET5',  idPlano:  vm.plano.id,  texto:  vm.plano.met5,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT6',  idPlano:  vm.plano.id,  texto:  vm.plano.cont6,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET6',  idPlano:  vm.plano.id,  texto:  vm.plano.met6,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT7',  idPlano:  vm.plano.id,  texto:  vm.plano.cont7,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET7',  idPlano:  vm.plano.id,  texto:  vm.plano.met7,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT8',  idPlano:  vm.plano.id,  texto:  vm.plano.cont8,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET8',  idPlano:  vm.plano.id,  texto:  vm.plano.met8,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT9',  idPlano:  vm.plano.id,  texto:  vm.plano.cont9,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET9',  idPlano:  vm.plano.id,  texto:  vm.plano.met9,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT10',  idPlano:  vm.plano.id,  texto:  vm.plano.cont10,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET10',  idPlano:  vm.plano.id,  texto:  vm.plano.met10,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT31',  idPlano:  vm.plano.id,  texto:  vm.plano.cont31,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET31',  idPlano:  vm.plano.id,  texto:  vm.plano.met31,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT32',  idPlano:  vm.plano.id,  texto:  vm.plano.cont32,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET32',  idPlano:  vm.plano.id,  texto:  vm.plano.met32,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT33',  idPlano:  vm.plano.id,  texto:  vm.plano.cont33,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET33',  idPlano:  vm.plano.id,  texto:  vm.plano.met33,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT34',  idPlano:  vm.plano.id,  texto:  vm.plano.cont34,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET34',  idPlano:  vm.plano.id,  texto:  vm.plano.met34,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT35',  idPlano:  vm.plano.id,  texto:  vm.plano.cont35,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET35',  idPlano:  vm.plano.id,  texto:  vm.plano.met35,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT36',  idPlano:  vm.plano.id,  texto:  vm.plano.cont36,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET36',  idPlano:  vm.plano.id,  texto:  vm.plano.met36,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT37',  idPlano:  vm.plano.id,  texto:  vm.plano.cont37,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET37',  idPlano:  vm.plano.id,  texto:  vm.plano.met37,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT38',  idPlano:  vm.plano.id,  texto:  vm.plano.cont38,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET38',  idPlano:  vm.plano.id,  texto:  vm.plano.met38,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT39',  idPlano:  vm.plano.id,  texto:  vm.plano.cont39,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET39',  idPlano:  vm.plano.id,  texto:  vm.plano.met39,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT40',  idPlano:  vm.plano.id,  texto:  vm.plano.cont40,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET40',  idPlano:  vm.plano.id,  texto:  vm.plano.met40,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
            }
            if (vm.rowspan >= 20) {
                vm.itensPlano.push({  tipo:   'CONT11',  idPlano:  vm.plano.id,  texto:  vm.plano.cont11,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET11',  idPlano:  vm.plano.id,  texto:  vm.plano.met11,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT12',  idPlano:  vm.plano.id,  texto:  vm.plano.cont12,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET12',  idPlano:  vm.plano.id,  texto:  vm.plano.met12,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT13',  idPlano:  vm.plano.id,  texto:  vm.plano.cont13,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET13',  idPlano:  vm.plano.id,  texto:  vm.plano.met13,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT14',  idPlano:  vm.plano.id,  texto:  vm.plano.cont14,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET14',  idPlano:  vm.plano.id,  texto:  vm.plano.met14,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT15',  idPlano:  vm.plano.id,  texto:  vm.plano.cont15,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET15',  idPlano:  vm.plano.id,  texto:  vm.plano.met15,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT16',  idPlano:  vm.plano.id,  texto:  vm.plano.cont16,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET16',  idPlano:  vm.plano.id,  texto:  vm.plano.met16,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT17',  idPlano:  vm.plano.id,  texto:  vm.plano.cont17,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET17',  idPlano:  vm.plano.id,  texto:  vm.plano.met17,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT18',  idPlano:  vm.plano.id,  texto:  vm.plano.cont18,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET18',  idPlano:  vm.plano.id,  texto:  vm.plano.met18,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT19',  idPlano:  vm.plano.id,  texto:  vm.plano.cont19,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET19',  idPlano:  vm.plano.id,  texto:  vm.plano.met19,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT20',  idPlano:  vm.plano.id,  texto:  vm.plano.cont20,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET20',  idPlano:  vm.plano.id,  texto:  vm.plano.met20,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT41',  idPlano:  vm.plano.id,  texto:  vm.plano.cont41,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET41',  idPlano:  vm.plano.id,  texto:  vm.plano.met41,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT42',  idPlano:  vm.plano.id,  texto:  vm.plano.cont42,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET42',  idPlano:  vm.plano.id,  texto:  vm.plano.met42,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT43',  idPlano:  vm.plano.id,  texto:  vm.plano.cont43,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET43',  idPlano:  vm.plano.id,  texto:  vm.plano.met43,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT44',  idPlano:  vm.plano.id,  texto:  vm.plano.cont44,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET44',  idPlano:  vm.plano.id,  texto:  vm.plano.met44,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT45',  idPlano:  vm.plano.id,  texto:  vm.plano.cont45,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET45',  idPlano:  vm.plano.id,  texto:  vm.plano.met45,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT46',  idPlano:  vm.plano.id,  texto:  vm.plano.cont46,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET46',  idPlano:  vm.plano.id,  texto:  vm.plano.met46,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT47',  idPlano:  vm.plano.id,  texto:  vm.plano.cont47,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET47',  idPlano:  vm.plano.id,  texto:  vm.plano.met47,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT48',  idPlano:  vm.plano.id,  texto:  vm.plano.cont48,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET48',  idPlano:  vm.plano.id,  texto:  vm.plano.met48,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT49',  idPlano:  vm.plano.id,  texto:  vm.plano.cont49,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET49',  idPlano:  vm.plano.id,  texto:  vm.plano.met49,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT50',  idPlano:  vm.plano.id,  texto:  vm.plano.cont50,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET50',  idPlano:  vm.plano.id,  texto:  vm.plano.met50,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
            }
            if (vm.rowspan >= 30) {
                vm.itensPlano.push({  tipo:   'CONT21',  idPlano:  vm.plano.id,  texto:  vm.plano.cont21,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET21',  idPlano:  vm.plano.id,  texto:  vm.plano.met21,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT22',  idPlano:  vm.plano.id,  texto:  vm.plano.cont22,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET22',  idPlano:  vm.plano.id,  texto:  vm.plano.met22,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT23',  idPlano:  vm.plano.id,  texto:  vm.plano.cont23,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET23',  idPlano:  vm.plano.id,  texto:  vm.plano.met23,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT24',  idPlano:  vm.plano.id,  texto:  vm.plano.cont24,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET24',  idPlano:  vm.plano.id,  texto:  vm.plano.met24,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT25',  idPlano:  vm.plano.id,  texto:  vm.plano.cont25,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET25',  idPlano:  vm.plano.id,  texto:  vm.plano.met25,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT26',  idPlano:  vm.plano.id,  texto:  vm.plano.cont26,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET26',  idPlano:  vm.plano.id,  texto:  vm.plano.met26,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT27',  idPlano:  vm.plano.id,  texto:  vm.plano.cont27,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET27',  idPlano:  vm.plano.id,  texto:  vm.plano.met27,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT28',  idPlano:  vm.plano.id,  texto:  vm.plano.cont28,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET28',  idPlano:  vm.plano.id,  texto:  vm.plano.met28,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT29',  idPlano:  vm.plano.id,  texto:  vm.plano.cont29,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET29',  idPlano:  vm.plano.id,  texto:  vm.plano.met29,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT30',  idPlano:  vm.plano.id,  texto:  vm.plano.cont30,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET30',  idPlano:  vm.plano.id,  texto:  vm.plano.met30,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT51',  idPlano:  vm.plano.id,  texto:  vm.plano.cont51,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET51',  idPlano:  vm.plano.id,  texto:  vm.plano.met51,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT52',  idPlano:  vm.plano.id,  texto:  vm.plano.cont52,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET52',  idPlano:  vm.plano.id,  texto:  vm.plano.met52,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT53',  idPlano:  vm.plano.id,  texto:  vm.plano.cont53,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET53',  idPlano:  vm.plano.id,  texto:  vm.plano.met53,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT54',  idPlano:  vm.plano.id,  texto:  vm.plano.cont54,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET54',  idPlano:  vm.plano.id,  texto:  vm.plano.met54,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT55',  idPlano:  vm.plano.id,  texto:  vm.plano.cont55,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET55',  idPlano:  vm.plano.id,  texto:  vm.plano.met55,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT56',  idPlano:  vm.plano.id,  texto:  vm.plano.cont56,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET56',  idPlano:  vm.plano.id,  texto:  vm.plano.met56,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT57',  idPlano:  vm.plano.id,  texto:  vm.plano.cont57,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET57',  idPlano:  vm.plano.id,  texto:  vm.plano.met57,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT58',  idPlano:  vm.plano.id,  texto:  vm.plano.cont58,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET58',  idPlano:  vm.plano.id,  texto:  vm.plano.met58,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT59',  idPlano:  vm.plano.id,  texto:  vm.plano.cont59,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET59',  idPlano:  vm.plano.id,  texto:  vm.plano.met59,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'CONT60',  idPlano:  vm.plano.id,  texto:  vm.plano.cont60,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
                vm.itensPlano.push({  tipo:   'MET60',  idPlano:  vm.plano.id,  texto:  vm.plano.met60,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
            }

            vm.itensPlano.push({ tipo: "AS1", idPlano: vm.plano.id, texto: vm.plano.contAs1, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "AS2", idPlano: vm.plano.id, texto: vm.plano.contAs2, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "RECINOV1", idPlano: vm.plano.id, texto: vm.plano.recursos1, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "RECINOV2", idPlano: vm.plano.id, texto: vm.plano.recursos2, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "INSTAVA1B", idPlano: vm.plano.id, texto: vm.plano.inst1b, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "INSTAVA2B", idPlano: vm.plano.id, texto: vm.plano.inst2b, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "BBASICA", idPlano: vm.plano.id, texto: vm.plano.bBasica, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "BCOMPL", idPlano: vm.plano.id, texto: vm.plano.bCompl, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "BAPRO", idPlano: vm.plano.id, texto: vm.plano.bApro, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "OBSG",  idPlano:  vm.plano.id,  texto:  vm.plano.obsg,  userCriacao:  vm.account.login,  userAlteracao:  vm.account.login  });
            sessionStorage.setItem('plano-ensino-itens', JSON.stringify(vm.itensPlano));
            vm.showResumo = true;
        }

        $(document).ready(function() {

            $('#CONT1').change(function() { document.getElementById('CONT1').style.backgroundColor = 'transparent'; });
            $('#MET1').change(function() { document.getElementById('MET1').style.backgroundColor = 'transparent'; });
            $('#CONT2').change(function() { document.getElementById('CONT2').style.backgroundColor = 'transparent'; });
            $('#MET2').change(function() { document.getElementById('MET2').style.backgroundColor = 'transparent'; });
            $('#CONT3').change(function() { document.getElementById('CONT3').style.backgroundColor = 'transparent'; });
            $('#MET3').change(function() { document.getElementById('MET3').style.backgroundColor = 'transparent'; });
            $('#CONT4').change(function() { document.getElementById('CONT4').style.backgroundColor = 'transparent'; });
            $('#MET4').change(function() { document.getElementById('MET4').style.backgroundColor = 'transparent'; });
            $('#CONT5').change(function() { document.getElementById('CONT5').style.backgroundColor = 'transparent'; });
            $('#MET5').change(function() { document.getElementById('MET5').style.backgroundColor = 'transparent'; });
            $('#CONT6').change(function() { document.getElementById('CONT6').style.backgroundColor = 'transparent'; });
            $('#MET6').change(function() { document.getElementById('MET6').style.backgroundColor = 'transparent'; });
            $('#CONT7').change(function() { document.getElementById('CONT7').style.backgroundColor = 'transparent'; });
            $('#MET7').change(function() { document.getElementById('MET7').style.backgroundColor = 'transparent'; });
            $('#CONT8').change(function() { document.getElementById('CONT8').style.backgroundColor = 'transparent'; });
            $('#MET8').change(function() { document.getElementById('MET8').style.backgroundColor = 'transparent'; });
            $('#CONT9').change(function() { document.getElementById('CONT9').style.backgroundColor = 'transparent'; });
            $('#MET9').change(function() { document.getElementById('MET9').style.backgroundColor = 'transparent'; });
            $('#CONT10').change(function() { document.getElementById('CONT10').style.backgroundColor = 'transparent'; });
            $('#MET10').change(function() { document.getElementById('MET10').style.backgroundColor = 'transparent'; });
            $('#CONT11').change(function() { document.getElementById('CONT11').style.backgroundColor = 'transparent'; });
            $('#MET11').change(function() { document.getElementById('MET11').style.backgroundColor = 'transparent'; });
            $('#CONT12').change(function() { document.getElementById('CONT12').style.backgroundColor = 'transparent'; });
            $('#MET12').change(function() { document.getElementById('MET12').style.backgroundColor = 'transparent'; });
            $('#CONT13').change(function() { document.getElementById('CONT13').style.backgroundColor = 'transparent'; });
            $('#MET13').change(function() { document.getElementById('MET13').style.backgroundColor = 'transparent'; });
            $('#CONT14').change(function() { document.getElementById('CONT14').style.backgroundColor = 'transparent'; });
            $('#MET14').change(function() { document.getElementById('MET14').style.backgroundColor = 'transparent'; });
            $('#CONT15').change(function() { document.getElementById('CONT15').style.backgroundColor = 'transparent'; });
            $('#MET15').change(function() { document.getElementById('MET15').style.backgroundColor = 'transparent'; });
            $('#CONT16').change(function() { document.getElementById('CONT16').style.backgroundColor = 'transparent'; });
            $('#MET16').change(function() { document.getElementById('MET16').style.backgroundColor = 'transparent'; });
            $('#CONT17').change(function() { document.getElementById('CONT17').style.backgroundColor = 'transparent'; });
            $('#MET17').change(function() { document.getElementById('MET17').style.backgroundColor = 'transparent'; });
            $('#CONT18').change(function() { document.getElementById('CONT18').style.backgroundColor = 'transparent'; });
            $('#MET18').change(function() { document.getElementById('MET18').style.backgroundColor = 'transparent'; });
            $('#CONT19').change(function() { document.getElementById('CONT19').style.backgroundColor = 'transparent'; });
            $('#MET19').change(function() { document.getElementById('MET19').style.backgroundColor = 'transparent'; });
            $('#CONT20').change(function() { document.getElementById('CONT20').style.backgroundColor = 'transparent'; });
            $('#MET20').change(function() { document.getElementById('MET20').style.backgroundColor = 'transparent'; });
            $('#CONT21').change(function() { document.getElementById('CONT21').style.backgroundColor = 'transparent'; });
            $('#MET21').change(function() { document.getElementById('MET21').style.backgroundColor = 'transparent'; });
            $('#CONT22').change(function() { document.getElementById('CONT22').style.backgroundColor = 'transparent'; });
            $('#MET22').change(function() { document.getElementById('MET22').style.backgroundColor = 'transparent'; });
            $('#CONT23').change(function() { document.getElementById('CONT23').style.backgroundColor = 'transparent'; });
            $('#MET23').change(function() { document.getElementById('MET23').style.backgroundColor = 'transparent'; });
            $('#CONT24').change(function() { document.getElementById('CONT24').style.backgroundColor = 'transparent'; });
            $('#MET24').change(function() { document.getElementById('MET24').style.backgroundColor = 'transparent'; });
            $('#CONT25').change(function() { document.getElementById('CONT25').style.backgroundColor = 'transparent'; });
            $('#MET25').change(function() { document.getElementById('MET25').style.backgroundColor = 'transparent'; });
            $('#CONT26').change(function() { document.getElementById('CONT26').style.backgroundColor = 'transparent'; });
            $('#MET26').change(function() { document.getElementById('MET26').style.backgroundColor = 'transparent'; });
            $('#CONT27').change(function() { document.getElementById('CONT27').style.backgroundColor = 'transparent'; });
            $('#MET27').change(function() { document.getElementById('MET27').style.backgroundColor = 'transparent'; });
            $('#CONT28').change(function() { document.getElementById('CONT28').style.backgroundColor = 'transparent'; });
            $('#MET28').change(function() { document.getElementById('MET28').style.backgroundColor = 'transparent'; });
            $('#CONT29').change(function() { document.getElementById('CONT29').style.backgroundColor = 'transparent'; });
            $('#MET29').change(function() { document.getElementById('MET29').style.backgroundColor = 'transparent'; });
            $('#CONT30').change(function() { document.getElementById('CONT30').style.backgroundColor = 'transparent'; });
            $('#MET30').change(function() { document.getElementById('MET30').style.backgroundColor = 'transparent'; });
            $('#CONT31').change(function() { document.getElementById('CONT31').style.backgroundColor = 'transparent'; });
            $('#MET31').change(function() { document.getElementById('MET31').style.backgroundColor = 'transparent'; });
            $('#CONT32').change(function() { document.getElementById('CONT32').style.backgroundColor = 'transparent'; });
            $('#MET32').change(function() { document.getElementById('MET32').style.backgroundColor = 'transparent'; });
            $('#CONT33').change(function() { document.getElementById('CONT33').style.backgroundColor = 'transparent'; });
            $('#MET33').change(function() { document.getElementById('MET33').style.backgroundColor = 'transparent'; });
            $('#CONT34').change(function() { document.getElementById('CONT34').style.backgroundColor = 'transparent'; });
            $('#MET34').change(function() { document.getElementById('MET34').style.backgroundColor = 'transparent'; });
            $('#CONT35').change(function() { document.getElementById('CONT35').style.backgroundColor = 'transparent'; });
            $('#MET35').change(function() { document.getElementById('MET35').style.backgroundColor = 'transparent'; });
            $('#CONT36').change(function() { document.getElementById('CONT36').style.backgroundColor = 'transparent'; });
            $('#MET36').change(function() { document.getElementById('MET36').style.backgroundColor = 'transparent'; });
            $('#CONT37').change(function() { document.getElementById('CONT37').style.backgroundColor = 'transparent'; });
            $('#MET37').change(function() { document.getElementById('MET37').style.backgroundColor = 'transparent'; });
            $('#CONT38').change(function() { document.getElementById('CONT38').style.backgroundColor = 'transparent'; });
            $('#MET38').change(function() { document.getElementById('MET38').style.backgroundColor = 'transparent'; });
            $('#CONT39').change(function() { document.getElementById('CONT39').style.backgroundColor = 'transparent'; });
            $('#MET39').change(function() { document.getElementById('MET39').style.backgroundColor = 'transparent'; });
            $('#CONT40').change(function() { document.getElementById('CONT40').style.backgroundColor = 'transparent'; });
            $('#MET40').change(function() { document.getElementById('MET40').style.backgroundColor = 'transparent'; });
            $('#CONT41').change(function() { document.getElementById('CONT41').style.backgroundColor = 'transparent'; });
            $('#MET41').change(function() { document.getElementById('MET41').style.backgroundColor = 'transparent'; });
            $('#CONT42').change(function() { document.getElementById('CONT42').style.backgroundColor = 'transparent'; });
            $('#MET42').change(function() { document.getElementById('MET42').style.backgroundColor = 'transparent'; });
            $('#CONT43').change(function() { document.getElementById('CONT43').style.backgroundColor = 'transparent'; });
            $('#MET43').change(function() { document.getElementById('MET43').style.backgroundColor = 'transparent'; });
            $('#CONT44').change(function() { document.getElementById('CONT44').style.backgroundColor = 'transparent'; });
            $('#MET44').change(function() { document.getElementById('MET44').style.backgroundColor = 'transparent'; });
            $('#CONT45').change(function() { document.getElementById('CONT45').style.backgroundColor = 'transparent'; });
            $('#MET45').change(function() { document.getElementById('MET45').style.backgroundColor = 'transparent'; });
            $('#CONT46').change(function() { document.getElementById('CONT46').style.backgroundColor = 'transparent'; });
            $('#MET46').change(function() { document.getElementById('MET46').style.backgroundColor = 'transparent'; });
            $('#CONT47').change(function() { document.getElementById('CONT47').style.backgroundColor = 'transparent'; });
            $('#MET47').change(function() { document.getElementById('MET47').style.backgroundColor = 'transparent'; });
            $('#CONT48').change(function() { document.getElementById('CONT48').style.backgroundColor = 'transparent'; });
            $('#MET48').change(function() { document.getElementById('MET48').style.backgroundColor = 'transparent'; });
            $('#CONT49').change(function() { document.getElementById('CONT49').style.backgroundColor = 'transparent'; });
            $('#MET49').change(function() { document.getElementById('MET49').style.backgroundColor = 'transparent'; });
            $('#CONT50').change(function() { document.getElementById('CONT50').style.backgroundColor = 'transparent'; });
            $('#MET50').change(function() { document.getElementById('MET50').style.backgroundColor = 'transparent'; });
            $('#CONT51').change(function() { document.getElementById('CONT51').style.backgroundColor = 'transparent'; });
            $('#MET51').change(function() { document.getElementById('MET51').style.backgroundColor = 'transparent'; });
            $('#CONT52').change(function() { document.getElementById('CONT52').style.backgroundColor = 'transparent'; });
            $('#MET52').change(function() { document.getElementById('MET52').style.backgroundColor = 'transparent'; });
            $('#CONT53').change(function() { document.getElementById('CONT53').style.backgroundColor = 'transparent'; });
            $('#MET53').change(function() { document.getElementById('MET53').style.backgroundColor = 'transparent'; });
            $('#CONT54').change(function() { document.getElementById('CONT54').style.backgroundColor = 'transparent'; });
            $('#MET54').change(function() { document.getElementById('MET54').style.backgroundColor = 'transparent'; });
            $('#CONT55').change(function() { document.getElementById('CONT55').style.backgroundColor = 'transparent'; });
            $('#MET55').change(function() { document.getElementById('MET55').style.backgroundColor = 'transparent'; });
            $('#CONT56').change(function() { document.getElementById('CONT56').style.backgroundColor = 'transparent'; });
            $('#MET56').change(function() { document.getElementById('MET56').style.backgroundColor = 'transparent'; });
            $('#CONT57').change(function() { document.getElementById('CONT57').style.backgroundColor = 'transparent'; });
            $('#MET57').change(function() { document.getElementById('MET57').style.backgroundColor = 'transparent'; });
            $('#CONT58').change(function() { document.getElementById('CONT58').style.backgroundColor = 'transparent'; });
            $('#MET58').change(function() { document.getElementById('MET58').style.backgroundColor = 'transparent'; });
            $('#CONT59').change(function() { document.getElementById('CONT59').style.backgroundColor = 'transparent'; });
            $('#MET59').change(function() { document.getElementById('MET59').style.backgroundColor = 'transparent'; });
            $('#CONT60').change(function() { document.getElementById('CONT60').style.backgroundColor = 'transparent'; });
            $('#MET60').change(function() { document.getElementById('MET60').style.backgroundColor = 'transparent'; });
            $('#EMENTA').change(function() { document.getElementById('EMENTA').style.backgroundColor = 'transparent'; });
            $('#COMPETENCIA').change(function() { document.getElementById('COMPETENCIA').style.backgroundColor = 'transparent'; });
            $('#INSTAVA1B').change(function() { document.getElementById('INSTAVA1B').style.backgroundColor = 'transparent'; });
            $('#INSTAVA2B').change(function() { document.getElementById('INSTAVA2B').style.backgroundColor = 'transparent'; });
            $('#AS1').change(function() { document.getElementById('AS1').style.backgroundColor = 'transparent'; });
            $('#AS2').change(function() { document.getElementById('AS2').style.backgroundColor = 'transparent'; });
            $('#RECINOV1').change(function() { document.getElementById('RECINOV1').style.backgroundColor = 'transparent'; });
            $('#RECINOV2').change(function() { document.getElementById('RECINOV2').style.backgroundColor = 'transparent'; });
            $('#TPBB').change(function() { document.getElementById('TPBB').style.backgroundColor = 'transparent'; });
            $('#OBSG').change(function() { document.getElementById('OBSG').style.backgroundColor = 'transparent'; });
            $('#CHTOTAL').change(function() { document.getElementById('CHTOTAL').style.backgroundColor = 'white'; });
            $('#PONTAS1').change(function() { document.getElementById('PONTAS1').style.backgroundColor = 'transparent'; });
            $('#PONTAS2').change(function() { document.getElementById('PONTAS2').style.backgroundColor = 'transparent'; });
            $('#PONTAB1').change(function() { document.getElementById('PONTAB1').style.backgroundColor = 'transparent'; });
            $('#PONTAB2').change(function() { document.getElementById('PONTAB2').style.backgroundColor = 'transparent'; });
            $('#PONTTRAB1').change(function() { document.getElementById('PONTTRAB1').style.backgroundColor = 'transparent'; });
            $('#PONTTRAB2').change(function() { document.getElementById('PONTTRAB2').style.backgroundColor = 'transparent'; });

            $('#CHTOTAL').focus(function() { document.getElementById('divMsg').innerText = 'Informe o total de Carga Horária da Disciplina'; });
            $('#CHPRESENCIAL').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma presencial?'; });
            $('#CHONLINE').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma online?'; });
            $('#CHEAD').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma EAD?'; });
            $('#CHPRATICA').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma prática?'; });
            $('#CHTEORICA').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma teorica?'; });
            $('#CHEXTENSAO').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas contarão como extensão?'; });
            $('#CHAS').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma Atividade supervisionada?'; });
            $('#EMENTA').focus(function() { document.getElementById('divMsg').innerText = 'Informe neste campo a ementa do curso'; });
            $('#COMPETENCIA').focus(function() { document.getElementById('divMsg').innerText = 'Informe neste campo as Competências e Habilidades Específicas da Disciplina.'; });
            $('#AS1').focus(function() { document.getElementById('divMsg').innerText = 'Descreva as Atividades Supervisionadas do 1° Bimestre neste campo.'; });
            $('#AS2').focus(function() { document.getElementById('divMsg').innerText = 'Descreva as Atividades Supervisionadas do 2° Bimestre neste campo.'; });
            $('#RECINOV1').focus(function() { document.getElementById('divMsg').innerText = 'Descreva os Recursos Inovadores do 1° Bimestre neste campo.'; });
            $('#RECINOV2').focus(function() { document.getElementById('divMsg').innerText = 'Descreva as Recursos Inovadores do 2° Bimestre neste campo.'; });

            $('#INSTAVA1').focus(function() { document.getElementById('divMsg').innerText = 'Descreva os Instrumentos Avaliativos e Critérios de Avaliação do 1° Bimestre neste campo.'; });
            $('#INSTAVA2').focus(function() { document.getElementById('divMsg').innerText = 'Descreva os Instrumentos Avaliativos e Critérios de Avaliação do 2° Bimestre neste campo.'; });
            $('#OBSG').focus(function() { document.getElementById('divMsg').innerText = 'Caso seja necessário informe alguma observação observação a respeito do Plano de Ensino.'; });

            $('#PONTAS1').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 1° Bimestre a ser preenchida por Atividades Supervisionadas.'; });
            $('#PONTAS2').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 2° Bimestre a ser preenchida por Atividades Supervisionadas.'; });
            $('#PONTAB1').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 1° Bimestre a ser preenchida por Avaliação.'; });
            $('#PONTAB2').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 2° Bimestre a ser preenchida por Avaliação.'; });
            $('#PONTTRAB1').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 1° Bimestre a ser preenchida por Trabalhos.'; });
            $('#PONTTRAB2').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 2° Bimestre a ser preenchida por Trabalhos.'; });

            $('.contjq').focus(function() { document.getElementById('divMsg').innerText = 'Descreva os conteúdos previstos em cada aula (todos os campos devem ser preenchidos).'; });
            $('.metjq').focus(function() { document.getElementById('divMsg').innerText = 'Descreva as metodologias e recursos de cada aula (todos os campos devem ser preenchidos).'; });

        });
    }
})();