(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('OnibusPlayPainelService', OnibusPlayPainelService);

    OnibusPlayPainelService.$inject = ['$resource', 'DateUtils'];

    function OnibusPlayPainelService($resource, DateUtils) {
        var resourceUrl = 'api/get-onibus-play-balanco';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true }
        });
    }
})();