(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConfirmacaoInscricaoAgendadoUserController', ConfirmacaoInscricaoAgendadoUserController);

    ConfirmacaoInscricaoAgendadoUserController.$inject = ['$timeout', 'DateUtils', '$scope', '$state', '$stateParams', '$sessionStorage'];

    function ConfirmacaoInscricaoAgendadoUserController($timeout, DateUtils, $scope, $state, $stateParams, $sessionStorage) {
        var vm = this;

        vm.inscricaoAgendado;
        vm.clear = clear;
        vm.redirect = redirect;

        vm.dados = JSON.parse(sessionStorage.getItem('candidato-vestibular'));

        switch (vm.dados.codPessoaIndicacao) {

            case '03827':
                vm.vendedorLink = 'https://wa.me/5545988429444';
                vm.vendedorNumero = "45 98842-9444";
                vm.vendedorEmail = "vestibular@univel.br";
                break;

            case '04213':
                vm.vendedorLink = 'https://wa.me/5545988024441';
                vm.vendedorNumero = "45 98802-4441";
                vm.vendedorEmail = "carlos.lemes@univel.br";
                break;

            case '03893':
                vm.vendedorLink = 'https://wa.me/5545988023092';
                vm.vendedorNumero = "45 98802-3092";
                vm.vendedorEmail = "claudineias@univel.br";
                break;

            case '03997':
                vm.vendedorLink = 'https://wa.me/5545988429540';
                vm.vendedorNumero = "45 98842-9540";
                vm.vendedorEmail = "felipe.andre@univel.br";
                break;

            case '03993':
                vm.vendedorLink = 'https://wa.me/5545988429476';
                vm.vendedorNumero = "45 98842-9476";
                vm.vendedorEmail = "luis.eduardo@univel.br ";
                break;

            case '03965':
                vm.vendedorLink = 'https://wa.me/5545988014153';
                vm.vendedorNumero = "45 98801-4153";
                vm.vendedorEmail = "tania@univel.br";
                break;

            case '04656':
                vm.vendedorLink = 'https://wa.me/5545988023036';
                vm.vendedorNumero = "45 98802-3036";
                vm.vendedorEmail = "mayara.freitas@univel.br";
                break;

            case '055861':
                vm.vendedorLink = 'https://wa.me/5545988429444';
                vm.vendedorNumero = "45 98842-9444";
                vm.vendedorEmail = "vestibular@univel.br";
                break;

            default:
                vm.vendedorLink = 'https://wa.me/5545988429444';
                vm.vendedorNumero = "45 98842-9444";
                vm.vendedorEmail = "vestibular@univel.br";
                break;
        }
        /*vm.dados.horaProva = vm.dados.horaProva.replace("H", "") + ":00 Horas";
        vm.ano = vm.dados.dataProva.substring(0, 4);
        vm.mes = vm.dados.dataProva.substring(5, 7);
        vm.dia = vm.dados.dataProva.substring(8, 10);*/


        /*vm.isEmpty = function isEmptyObject(obj) {
             var name;
             for (name in obj) {
                 return obj;
             }
             return 'Não';
         }

         vm.dados.dataProva = vm.dia + "/" + vm.mes + "/" + vm.ano;
         vm.aux = vm.dados.nome.indexOf(" ");

         if (vm.aux <= 0) {
             vm.dados.firstname = vm.dados.nome;
         } else {
             vm.dados.firstname = vm.dados.nome.substring(0, vm.aux);
         }*/


        //vm.dados.dataProva = vm.dados.dataProva.replace();
        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function redirect(value) {
            $state.go(value);
        }



    }
})();