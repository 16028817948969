(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('CursoDialogController', CursoDialogController);

    CursoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Curso'];

    function CursoDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Curso) {
        var vm = this;

        vm.curso = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            if (vm.curso.id !== null) {
                Curso.update(vm.curso, onSaveSuccess, onSaveError);
            } else {
                Curso.save(vm.curso, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:cursoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();