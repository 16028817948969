(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('OnibusPlayController', OnibusPlayController);

    OnibusPlayController.$inject = ['$scope', '$state', 'DateUtils', 'Principal', 'OnibusPlayPainelService'];

    function OnibusPlayController($scope, $state, DateUtils, Principal, OnibusPlayPainelService) {

        var vm = this;
        getVagas();
        vm.final = [];
        vm.vagas = [];
        vm.top = {};

        vm.insertColegios = insertCol;

        /*setInterval(function() {
            chrome
            getVagas();
        }, 30000);*/


        function getVagas() {
            vm.colegios = [];
            vm.cidades = [];

            OnibusPlayPainelService.query(
                function(result) {
                    //console.log(result);

                    vm.baseColegios = result;

                    var cidadeAtual = null;
                    var temp = [];
                    var cdd = [];

                    for (var i = 0; i < result.length; i++) {
                        var element = result[i];

                        if (i == 0) {
                            cidadeAtual = element.cidade;
                            cdd.push({'nome':cidadeAtual});
                            vm.top = element;
                        }

                        if (element.cidade != cidadeAtual) {
                            cidadeAtual = element.cidade;
                            cdd.push({'nome':cidadeAtual});
                        } 
                        
                        if (element.vagas > vm.top.vagas) {
                            vm.top = element;
                        }
                        
                        temp.push(element);
                    }
                    vm.colegios = temp;
                    vm.cidades = cdd;
                }
            );
        }

        function insertCol() {
            var temp = [];

            for (var i = 0; i < vm.baseColegios.length; i++) {
                var e = vm.baseColegios[i];
                if (e.cidade == vm.selectCidade.nome){
                    temp.push(e);
                }
                
            }
            
            vm.colegios = temp;
        }
    }
})();