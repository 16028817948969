(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('SugestaoNewController', SugestaoNewController);

    SugestaoNewController.$inject = ['$scope', '$state', 'Principal', 'SugestaoNew', '$http'];

    function SugestaoNewController($scope, $state, Principal, SugestaoNew, $http) {
        var vm = this;

        vm.sugestao = {
            data_envio: null,
            mensagem: null,
            login: null,
            id: null
        };

        vm.account = null;
        vm.isAuthenticated = null;
        vm.save = save;


        function save() {
            vm.sugestao.data_envio = null;
            vm.sugestao.login = vm.account.login;

            //SugestaoNew.save(vm.sugestao, onSaveSuccess(), onSaveError());

            $http.post("/api/sugestaos", vm.sugestao)
                .success(function(data) {
                    $state.go('confirmacao-sugestao');
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function onSaveSuccess(result) {
            console.log(result);
        }

        function onSaveError(status) {
            console.log(status);
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

    }
})();