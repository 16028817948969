(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('FinalProvaService', FinalProvaService);

    FinalProvaService.$inject = ['$resource', 'DateUtils'];

    function FinalProvaService($resource, DateUtils) {
        var resourceUrl = '/api/final-prova-online';

        return $resource(resourceUrl, {}, {
            'query': { method: 'post', isArray: true }
        });
    }
})();