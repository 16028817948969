(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoPulseiraController', InscricaoPulseiraController);

    InscricaoPulseiraController.$inject = ['$scope', '$state', 'InscricaoPulseira'];

    function InscricaoPulseiraController($scope, $state, InscricaoPulseira) {
        var vm = this;

        vm.inscricaoPulseiras = [];

        //loadAll();

        function loadAll() {
            InscricaoPulseira.query(function(result) {
                vm.inscricaoPulseiras = result;
            });
        }
    }
})();