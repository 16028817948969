(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RelatorioCapController', RelatorioCapController);

    RelatorioCapController.$inject = ['$scope', 'Principal', '$state', '$http', 'RelatorioCapacitacao', 'DataUtils', 'RelatorioExtensaoFile', 'RelatorioCurso', 'RelatorioParticipante', '$sessionStorage', 'ValidaInputRegexService'];

    function RelatorioCapController($scope, Principal, $state, $http, RelatorioCapacitacao, DataUtils, RelatorioExtensaoFile, RelatorioCurso, RelatorioParticipante, $sessionStorage, ValidaInputRegexService) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;

        vm.itemsPerPage = 50;

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        vm.consultaRelatorios = consultaRelatorios;

        vm.arrayEvidencias = [];
        vm.cursosSelecionados = [];
        vm.participantes = [];
        vm.participante = {};
        vm.idEvidencia = 0;

        vm.consulta = 'TODOS';

        vm.agora = new Date();

        vm.relatorio = {};
        vm.relatorio.novo = 'true';
        vm.relatorio.ano = '2024';

        vm.cadastroAluno = 'auto';
        vm.mostraLerArquivo = true;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                console.log(account);
                getSession();
                consultaRelatorios();
                loadCursos();

                vm.etapa = 1;
            });
        }

        function loadCursos() {

            $http.post("/api/cursos/get-cursos-novos").success(function(data) {
                vm.cursos = data;
                console.log(data);
            }).error(function(status) {
                console.log(status);
            });

            // $http.post('api/cursosGraduacao')
            //     .success(function(data) {
            //         vm.cursos = data;
            //         //console.log(vm.cursos)
            //     }).error(function(status) {
            //         console.log(status);
            //     });

        }

        function consultaRelatorios() {
            var relatorio = {
                titulo: vm.consulta,
                createdUser: vm.account.login
            }

            $http.post('api/get-relatorio-capacitacao-consulta', relatorio)
                .success(function(data) {
                    vm.relatoriosCapacitacao = data;
                }).error(function(status) {
                    console.log(status);
                });
        }

        // cursos
        vm.addCurso = function(curso) {

            var liberado = true;
            if (vm.cursosSelecionados.length > 0) {
                for (var x = 0; x < vm.cursosSelecionados.length; x++) {
                    var y = vm.cursosSelecionados[x];

                    if (curso.id == y.id) {
                        liberado = false;
                    }
                }
            }

            if (liberado) {
                var obj = {
                    id: curso.id,
                    nome: curso.nome
                };
                vm.cursosSelecionados.push(obj);
                //console.log(vm.cursosSelecionados);
                sessionStorage.setItem('cursos-capacitacao', JSON.stringify(vm.cursosSelecionados));
            }

        }

        vm.removeCurso = function(curso) {
            for (var i = 0; i < vm.cursosSelecionados.length; i++) {
                var e = vm.cursosSelecionados[i];
                if (e.id == curso.id) {
                    vm.cursosSelecionados.splice(i, 1);
                    sessionStorage.setItem('cursos-capacitacao', JSON.stringify(vm.cursosSelecionados));
                }
            }
        }

        // participantes
        vm.addParticipante = function() {
            var valida = validaParticipante(vm.participante);
            if (valida == "OK") {
                vm.participantes.push(vm.participante);
                vm.participante = {};
                sessionStorage.setItem('participantes-capacitacao', JSON.stringify(vm.participantes));
            } else {
                alert(valida);
            }
        }

        vm.removeParticipante = function(p) {
            for (var i = 0; i < vm.participantes.length; i++) {
                var e = vm.participantes[i];
                if (e.cpf == p.cpf) {
                    vm.participantes.splice(i, 1);
                    sessionStorage.setItem('participantes-capacitacao', JSON.stringify(vm.participantes));
                }
            }
        }

        // evidencias
        vm.removeEvidencia = function(id) {
            for (var i = 0; i < vm.arrayEvidencias.length; i++) {
                var e = vm.arrayEvidencias[i];
                if (e.id == id) {
                    vm.arrayEvidencias.splice(i, 1);
                }
            }
        }

        vm.setEvidencia = function($file, relatorioExtensaoFile) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {

                        if (vm.arrayEvidencias.length == 10) {
                            alert("Não é possível inserir mais de 10 imagens");
                        } else {
                            vm.idEvidencia++;
                            var ev = {
                                id: vm.idEvidencia,
                                file: base64Data,
                                fileType: $file.type
                            }
                            vm.arrayEvidencias.push(ev);
                            console.log(vm.arrayEvidencias.length);
                        }

                    });
                });
            }
            console.log(vm.arrayEvidencias);
        };

        function validaParticipante(participante) {
            var valida = null;

            valida = ValidaInputRegexService.validate(participante.nome, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Nome Participante: " + valida.msg; }

            valida = ValidaInputRegexService.validate(participante.cpf, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Cpf Pariticipante: " + valida.msg; }

            valida = ValidaInputRegexService.validate(participante.curso, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Curso Participante: " + valida.msg; }

            valida = ValidaInputRegexService.validate(participante.ch, "onlyNumber");
            if (valida.status == "error") { return "Ch do participante: " + valida.msg; }

            // if (participante.nome == null || participante.nome == undefined || participante.nome == "") { return "Informe o nome do participante."; }
            if (participante.tipoPart == null || participante.tipoPart == undefined || participante.tipoPart == "") { return "Selecione o tipo de participante."; }
            if (participante.funcao == null || participante.funcao == undefined || participante.funcao == "") { return "Selecione a função do participante."; }
            // if (participante.cpf == null || participante.cpf == undefined || participante.cpf == "") { return "Informe o cpf do participante."; }
            // if (participante.curso == null || participante.curso == undefined || participante.curso == "") { return "Selecione o curso do participante."; }
            if (participante.ch == null || participante.ch == undefined || participante.ch == "") { return "Informe a CH do participante."; }
            //if (participante.email == null || participante.email == undefined || participante.email == "") { return "Informe o email do participante."; }

            return "OK";
        }

        function removaCharErro(str) {
            var charCode = "";
            if (str == null || str == undefined || str.length <= 0) {
                return "";
            } else {
                for (var i = 0; i < str.length; ++i) {
                    charCode = str.charCodeAt(i);
                    if (charCode == "8211") {
                        str = str.substring(0, i) + str.substring(i + 1);
                    }
                }
                return str;
            }
        }

        function validaCampos() {
            // validar os campos aqui 

            var valida = null;

            valida = ValidaInputRegexService.validate(vm.relatorio.titulo, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Titulo: " + valida.msg; }

            if (vm.relatorio.tipo == null || vm.relatorio.tipo == undefined || vm.relatorio.tipo == "") { return "O campo TIPO DE PROJETO está incorreto."; }
            if (vm.relatorio.novo == null || vm.relatorio.novo == undefined || vm.relatorio.novo == "") { return "O campo DURAÇÃO DO PROJETO está incorreto."; }
            if (vm.relatorio.ano == null || vm.relatorio.ano == undefined || vm.relatorio.ano == "") { return "O campo ANO DE PROJETO está incorreto."; }
            if (vm.relatorio.modalidade == null || vm.relatorio.modalidade == undefined || vm.relatorio.modalidade == "") { return "O campo MODALIDADE está incorreto."; }
            if (vm.relatorio.nucleo == null || vm.relatorio.nucleo == undefined || vm.relatorio.nucleo == "") { return "O campo NUCLEO DO PROJETO está incorreto."; }
            if (vm.relatorio.inicio == null || vm.relatorio.inicio == undefined || vm.relatorio.inicio == "") { return "O campo DATA INICIO está incorreto."; }
            if (vm.relatorio.termino == null || vm.relatorio.termino == undefined || vm.relatorio.termino == "") { return "O campo DATA FINAL está incorreto."; }

            vm.relatorio.professor = removaCharErro(vm.relatorio.professor);
            vm.relatorio.linkLattes = removaCharErro(vm.relatorio.linkLattes);
            vm.relatorio.dadosEmissao = removaCharErro(vm.relatorio.dadosEmissao);
            vm.relatorio.resumo = removaCharErro(vm.relatorio.resumo);
            vm.relatorio.objetivo = removaCharErro(vm.relatorio.objetivo);
            vm.relatorio.programacao = removaCharErro(vm.relatorio.programacao);
            vm.relatorio.acoes = removaCharErro(vm.relatorio.acoes);

            valida = ValidaInputRegexService.validate(vm.relatorio.professor, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Nome Professor: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorio.linkLattes, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Link Professor: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorio.dadosEmissao, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Dados de Emissão: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorio.resumo, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Resumo: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorio.objetivo, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Objetivos: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorio.programacao, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Programação: " + valida.msg; }

            if (vm.relatorio.tipo != 'Visita Técnica') {
                valida = ValidaInputRegexService.validate(vm.relatorio.acoes, "alphanumericWithSpecial");
                if (valida.status == "error") { return "Programação: " + valida.msg; }

            }

            if (vm.cursosSelecionados == null || vm.cursosSelecionados == "" || vm.cursosSelecionados.length == 0) {
                return "por favor selecione ao menos 1 curso relacionado ao projeto";
            }

            if (vm.participantes == null || vm.participantes == "" || vm.participantes.length == 0) {
                return "por favor informe ao menos 1 participante ao projeto, quantidade atual: " + vm.participantes.length;
            }

            if (vm.arrayEvidencias == null || vm.arrayEvidencias == "" || vm.arrayEvidencias.length == 0 || vm.arrayEvidencias.length < 8 || vm.arrayEvidencias.length > 10) {
                return "por favor informe de 8 a 10 imagens de evidências, quantidade atual: " + vm.arrayEvidencias.length;
            }

            for (var i = 0; i < vm.arrayEvidencias.length; i++) {
                var foto = vm.arrayEvidencias[i];
                if (foto.file.length > 10000000) {
                    return "A foto de ID: " + foto.id + " está muito grande. tamanho atual: " + ((foto.file.length / 1024) / 1024).toFixed(2);
                }
            }

            return "OK";
        }

        vm.salvar = function() {
            vm.isSaving = true;

            vm.relatorio.createdUser = vm.account.login;
            vm.relatorio.createdDate = new Date();
            vm.relatorio.alterUser = vm.account.login;
            vm.relatorio.alterDate = new Date();

            vm.isSaving = false;

            var valida = validaCampos();
            if (valida == "OK") {
                //console.log(vm.relatorio);
                RelatorioCapacitacao.save(vm.relatorio, onSaveSuccess, onSaveError);
            } else {
                vm.isSaving = false;
                alert(valida);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:relatorioExtensaoUpdate', result);

            for (var a = 0; a < vm.participantes.length; a++) {

                var b = vm.participantes[a];

                var c = {
                    tipoRep: 'CAPACITACAO',
                    idRep: result.id,
                    nome: b.nome,
                    funcao: b.funcao,
                    tipoPart: b.tipoPart,
                    curso: b.curso,
                    cpf: b.cpf,
                    email: b.email,
                    ch: b.ch,
                    complemento: "",
                    createdDate: result.createdDate,
                    createdUser: result.createdUser,
                    alterDate: result.createdDate,
                    alterUser: result.createdUser
                }

                RelatorioParticipante.save(c, function(pSalvo) {
                    console.log("participante " + pSalvo.nome + " salvo com sucesso!");
                }, function(status) {
                    alert("Erro ao salvar o participante: " + c.nome + " -> " + status);
                });
            }

            for (var d = 0; d < vm.cursosSelecionados.length; d++) {

                var e = vm.cursosSelecionados[d];

                var f = {
                    tipoRep: 'CAPACITACAO',
                    idRep: result.id,
                    idCurso: e.id,
                    curso: e.nome,
                    createdDate: result.createdDate,
                    createdUser: result.createdUser,
                    alterDate: result.createdDate,
                    alterUser: result.createdUser
                }

                RelatorioCurso.save(f, function(cSalvo) {
                    console.log("Curso " + cSalvo.curso + " salvo com sucesso!");
                }, function(status) {
                    alert("Erro ao salvar o curso: " + f.curso + " -> " + status);
                });
            }

            for (var g = 0; g < vm.arrayEvidencias.length; g++) {

                var h = vm.arrayEvidencias[g];

                var i = {
                    idRelatorio: result.id,
                    evidencia: h.file,
                    evidenciaContentType: h.fileType,
                    createdDate: result.createdDate,
                    createdUser: result.createdUser,
                    tamanho: vm.byteSize(h.file),
                    tipoRelatorio: 'CAPACITACAO'
                }

                ///console.log(i);

                RelatorioExtensaoFile.save(i, function(iSalva) {
                    console.log("imagem " + iSalva.id + " salva com sucesso!");
                }, function(status) {
                    alert("Erro ao salvar a imagem: " + h.id + " -> " + status);
                });
            }

            vm.isSaving = false;

            vm.relatorio = {};
            vm.cursosSelecionados = [];
            vm.participantes = [];
            vm.arrayEvidencias = [];
            sessionStorage.removeItem('relatorio-capacitacao');
            sessionStorage.removeItem('participantes-capacitacao');
            sessionStorage.removeItem('cursos-capacitacao');
            vm.etapa = 1;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.download = function(relatorio) {
            console.log(relatorio);
            $http.post('api/get-arquivo-capacitacao-extensao', relatorio, { responseType: 'arraybuffer' })
                .success(function(data) {
                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = "Relatorio-Extensao-" + relatorio.titulo + "-" + relatorio.professor + ".pdf";
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }).error(function(status) {
                    console.log(status);
                });

        }

        vm.downloadAlunos = function(relatorio) {
            console.log(relatorio);
            $http.post('api/get-arquivo-capacitacao-alunos', relatorio.id, { responseType: 'arraybuffer' })
                .success(function(data) {
                    var file = new Blob([(data)], { type: 'application/csv' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = "Alunos-Relatorio-" + relatorio.titulo + "-" + relatorio.professor + ".csv";
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }).error(function(status) {
                    console.log(status);
                });

        }

        vm.downloadModelo = function() {
            $http.post('api/get-arquivo-modelo-import-alunos', { responseType: 'arraybuffer' })
                .success(function(data) {
                    var file = new Blob([(data)], { type: 'application/csv' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = "ModeloImportAlunosRelatorio.csv";
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }).error(function(status) {
                    console.log(status);
                });
        }

        vm.deleteImg = function(relatorio) {
            var r = confirm("Deseja realmente apagar as imagens do relatorio de capacitação número:" + relatorio.id + "?, esta ação não poderá ser desfeita.");
            if (r) {
                $http.post('api/delete-img-relatorio-capacitacao', relatorio)
                    .success(function(data) {
                        alert("Imagens deletadas");
                    }).error(function(status) {
                        alert("Erro ao deletar as Imagens");
                    });
            } else {
                alert("Operação cancelada.");
            }
        }

        vm.deleteRelatorio = function(relatorio) {
            var r = confirm("Deseja realmente deletar o relatorio:" + relatorio.id + " e todos seus conteúdos?, esta ação não poderá ser desfeita.");
            if (r) {
                $http.post('api/delete-relatorio-capacitacao', relatorio)
                    .success(function(data) {
                        alert("Reletório deletado");
                        consultaRelatorios();
                    }).error(function(status) {
                        alert("Erro ao deletar o relatorio");
                    });
            } else {
                alert("Operação cancelada.");
            }
        }

        vm.carregaArquivo = function() {
            var teste = loadCsv();
            //console.log(teste);
            vm.mostraLerArquivo = false;
            vm.participantesTemp = teste;
            vm.participantes = vm.participantesTemp;
        }

        vm.mostrarAlunos = function() {
            vm.participantes = vm.participantesTemp;
            sessionStorage.setItem('participantes-capacitacao', JSON.stringify(vm.participantes));
            vm.mostraLerArquivo = true;
        }

        function loadCsv() {
            var json = [];
            var upCsv = document.getElementById('csv');
            var csv = upCsv.files[0];
            if (csv.type == "text/csv") {
                var file = new FileReader();
                file.readAsBinaryString(csv);
                file.onload = function(f) {
                    var rows = f.target.result.split('\r\n');
                    while (rows.length > 0 && !rows[rows.length - 1].trim()) {
                        rows.pop();
                    }
                    var builder = "[";
                    for (var i = 0; i < rows.length; i++) {
                        var cells = rows[i].split(';');
                        if (i === 0) {
                            if (cells[0] !== "nome") {
                                alert("A primeira coluna do arquivo não está como: nome");
                                return null;
                            }
                            if (cells[1] !== "cpf") {
                                alert("A segunda coluna do arquivo não está como: cpf");
                                return null;
                            }
                            if (cells[2] !== "ch") {
                                alert("A terceira coluna do arquivo não está como: ch");
                                return null;
                            }
                            if (cells[3] !== "tipoPart") {
                                alert("A quarta coluna do arquivo não está como: tipoPart");
                                return null;
                            }
                            if (cells[4] !== "funcao") {
                                alert("A quinta coluna do arquivo não está como: funcao");
                                return null;
                            }
                            if (cells[5] !== "curso") {
                                alert("A última coluna do arquivo não está como: curso");
                                return null;
                            }
                        } else {
                            if (i === 1) {
                                builder += '{ "nome": "' + cells[0] + '", "cpf": "' + removeDotAndDash(cells[1]) + '", "ch": ' + cells[2] + ', "tipoPart": "' + CorrigeCaseTipoPart(cells[3]) + '", "funcao": "' + CorrigeCaseFuncao(cells[4]) + '", "curso": "' + cells[5] + '" }';
                            } else {
                                builder += ', { "nome": "' + cells[0] + '", "cpf": "' + removeDotAndDash(cells[1]) + '", "ch": ' + cells[2] + ', "tipoPart": "' + CorrigeCaseTipoPart(cells[3]) + '", "funcao": "' + CorrigeCaseFuncao(cells[4]) + '", "curso": "' + cells[5] + '" }';
                            }
                        }
                    }
                    builder += "]";
                    json = JSON.parse(builder);
                    vm.participantesTemp = json;
                    //console.log(json);
                    return json;
                }
            } else {
                alert("O arquivo inserido não está em formato CSV");
            }

        }

        // function converteObj(a) {
        //     for (var i = 0; i < a.length; i++) {
        //         a[i].cpf = removeDotAndDash(a[i].cpf);
        //         if (a[i].cpf.length == 11) {
        //             a[i].valido = true;
        //         } else {
        //             var c = 11 - a[i].cpf.length;
        //             for (var j = 0; j < c; j++) {
        //                 var b = 0 + a[i].cpf;
        //                 a[i].cpf = b;
        //             }
        //             a[i].valido = false;
        //         }
        //     }
        //     return a;
        // }

        function removeDotAndDash(cpf) {
            return cpf.replaceAll(".", "").replaceAll("-", "");
        }

        function CorrigeCaseTipoPart(info) {
            var ret = info;
            switch (info) {
                case "docente":
                    ret = "Docente";
                    break;
                case "discente":
                    ret = "Discente";
                    break;
                case "egresso":
                    ret = "Egresso";
                    break;
            }
            return ret;
        }

        function CorrigeCaseFuncao(info) {
            var ret = info;
            switch (info) {
                case "organizador":
                    ret = "Organizador";
                    break;
                case "coordernador":
                    ret = "Coordernador";
                    break;
                case "colaborador":
                    ret = "Colaborador";
                    break;
                case "participante":
                    ret = "Participante";
                    break;
                case "palestrante":
                    ret = "Palestrante";
                    break;
                case "mediador":
                    ret = "Mediador";
                    break;
                case "palestrante externo":
                    ret = "Palestrante Externo";
                    break;
            }
            return ret;
        }

        function getSession() {
            var rel = JSON.parse(sessionStorage.getItem('relatorio-capacitacao'));
            if (rel != null && rel != undefined) {
                rel.inicio = compilaData(rel.inicio);
                rel.termino = compilaData(rel.termino);
                vm.relatorio = rel;
            }

            var participantes = JSON.parse(sessionStorage.getItem('participantes-capacitacao'));
            if (participantes != null && participantes != undefined) {
                vm.participantes = participantes;
            }

            var cursos = JSON.parse(sessionStorage.getItem('cursos-capacitacao'));
            if (cursos != null && cursos != undefined) {
                vm.cursosSelecionados = cursos;
            }
        }

        vm.salvaSessao = function() {
            vm.relatorio.inicio = formatDate(vm.relatorio.inicio);
            vm.relatorio.termino = formatDate(vm.relatorio.termino);
            sessionStorage.setItem('relatorio-capacitacao', JSON.stringify(vm.relatorio));
            sessionStorage.setItem('participantes-capacitacao', JSON.stringify(vm.participantes));
            sessionStorage.setItem('cursos-capacitacao', JSON.stringify(vm.cursosSelecionados));
        }

        function formatDate(date) {
            var ret = null;
            if (date.length > 11) {
                ret = date.toISOString().slice(0, 10);
            } else {
                ret = date;
            }
            return ret;
        }

        function compilaData(data) {
            //console.log(data);
            var ano = data.substring(0, 4);
            var mes = data.substring(5, 7);
            var dia = data.substring(8, 10);
            return new Date(ano, mes - 1, dia);
        }

    }
})();