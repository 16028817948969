(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('PoloSituacaoAlter', PoloSituacaoAlter);

    PoloSituacaoAlter.$inject = ['$resource', 'DateUtils'];

    function PoloSituacaoAlter($resource, DateUtils) {
        var resourceUrl = 'api/polo-situacao-alter/:idPolo/:situacao/:login';

        return $resource(resourceUrl, {}, {
            'update': { method: 'GET', isArray: false }
        });
    }
})();