(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('PSAtivosAgendado', PSAtivosAgendado);

        PSAtivosAgendado.$inject = ['$resource'];

    function PSAtivosAgendado($resource) {
        var resourceUrl = 'api/processos-ativos-agendado/{agendado}';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();