(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ResultadoVestibularEndController', ResultadoVestibularEndController);

    ResultadoVestibularEndController.$inject = ['$rootScope', '$timeout', '$scope', '$state', '$stateParams', 'LoginService', '$http', '$sessionStorage', 'Principal', 'Auth'];

    function ResultadoVestibularEndController($rootScope, $timeout, $scope, $state, $stateParams, LoginService, $http, $sessionStorage, Principal, Auth) {
        var vm = this;


        // variables
        vm.resultado = "EMAIL";
        vm.cons = {
            'email': null,
            'celular': null,
            'cpf': null
        }

        //        vm.show = false;
        vm.show = true;
        vm.authenticationError = false;
        vm.password = null;
        vm.rememberMe = true;
        vm.username = null;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.showEnem = false;
        vm.matriculado = false;
        vm.encerrado = false;
        vm.habilitado = false;
        vm.agendado = false;
        vm.aguardando = false;

        vm.requestResetPassword = requestResetPassword;

        //functions
        vm.login = login;

        function login(event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function() {
                vm.authenticationError = false;
                $rootScope.$broadcast('authenticationSuccess');
            }).catch(function() {
                vm.authenticationError = true;
            });
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
            $state.go('painelAdministrativo');
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                mostrar();
            });
        }

        init();


        function init() {
            vm.resultado = JSON.parse(sessionStorage.getItem('dados-resultado'));

            vm.habilitado = false;
            vm.ead = false;
            var today = new Date();
            var inicio = null;

            if (vm.resultado.cursoPrimeiraOpcao.includes('EAD')) {
                inicio = new Date('2019', '09', '14', '08', '00', '0000');
                vm.ead = true;
            } else {
                inicio = new Date('2019', '09', '10', '08', '00', '0000');
            }

            //console.log(inicio);

            if (today < inicio) {
                vm.habilitado = false;
                //console.log(vm.habilitado);
            } else {
                //console.log(vm.habilitado);
                vm.habilitado = true;
            }


            if (vm.resultado != null) {
                vm.show = true;
                vm.username = vm.resultado.login;
                vm.password = vm.resultado.senha;

                aprovado();
                //showEnem();
            }
        }

        function mostrar() {
            if (vm.isAuthenticated()) {
                vm.autenticado = true;
            }
        }

        function aprovado() {
            //console.log(vm.resultado);
            //  VERIFICA O PROCESSO
            if (vm.resultado.processo == 'AGENDADO') {
                vm.agendado = true;
            }

            // PRIMEIRAMENTE DEVE-SE VERIFICAR O PS
            if (vm.resultado.resultado == "ENCERRADO") {
                vm.aprovado = false;
                vm.showEnem = false;
                vm.encerrado = true;
            } else {
                // VAMOS VERIFICAR SE ESTÁ AGUARDANDO
                if (vm.resultado.resultado == "AGUARDANDO") {
                    vm.aprovado = true;
                    vm.showEnem = false;
                    vm.encerrado = false;
                    vm.aguardando = true;
                } else {
                    // DEVE-SE VERIFICAR A NOTA DO ENEM
                    if (vm.resultado.pontuacaoEnem > 0) {
                        vm.resultado.resultado = "APROVADO ENEM";
                        vm.aprovado = true;
                        vm.showEnem = true;
                    } else {
                        // CASO NAO TENHA NOTA NO ENEM, VERIFICA CLASSIFICACAO
                        if (vm.resultado.resultado == "CLASSIFICADO") {
                            // VERIFICA TREINEIRO
                            if (vm.resultado.treineiro.includes('T')) {
                                vm.resultado.resultado = "APROVADO TREINEIRO";
                                vm.aprovado = false;
                            } else {
                                if (vm.resultado.cursoPrimeiraOpcao.includes("DIREITO")) {
                                    if (vm.resultado.posicaoPrimeiraOpcao > 470) {
                                        vm.resultado.resultado = "APROVADO 2° CHAMADA";
                                    } else {
                                        vm.resultado.resultado = "APROVADO 1° CHAMADA";
                                    }
                                } else {
                                    if (vm.resultado.posicaoPrimeiraOpcao > 150) {
                                        vm.resultado.resultado = "APROVADO 2° CHAMADA";
                                    } else {
                                        vm.resultado.resultado = "APROVADO 1° CHAMADA";
                                    }
                                }
                                vm.aprovado = true;
                            }

                        } else {
                            vm.aprovado = false;
                            if (vm.resultado.resultado == "DESCLASS") {
                                vm.resultado.resultado = "DESCLASSIFICADO";
                            }
                            if (vm.resultado.resultado == "MATRIC") {
                                vm.resultado.resultado = "CLASSIFICADO";
                                vm.matriculado = true;
                            }
                        }

                    }
                }
            }
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }
    }
})();