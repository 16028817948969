(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('AtaProvaController', AtaProvaController);

    AtaProvaController.$inject = ['GeraAtaProvaService', 'GetSalasFechadasService'];

    function AtaProvaController(GeraAtaProvaService, GetSalasFechadasService) {

        var vm = this;
        vm.consultar = consultar;
        vm.CriaPDF = CriaPDF;
        vm.geraAta = geraAta;


        consultar();

        function consultar() {
            GetSalasFechadasService.query(function(data) { vm.salas = data; }, function(status) { console.log(status); });
        }

        function geraAta() {
            GeraAtaProvaService.query({ idSala: vm.salaSelecionada.id },
                function(data) {
                    vm.alunos = data;
                    console.log(vm.alunos);

                },
                function(status) {
                    console.log(status);
                });
        }

        function CriaPDF() {
            var minhaTabela = document.getElementById('tabela').innerHTML;

            var style = "<style>";
            style = style + "table {width:100%;}";
            style = style + ".titulo {text-align:center;font-size:20px;}";
            style = style + "table, th, td {border: solid 1px black;border-collapse: collapse;padding: 0px 0px;text-align: center;font-size:10px;font-family: Arial;} td {padding:5px;}";
            style = style + "</style>";

            // CRIA UM OBJETO WINDOW
            var win = window.open('', '', 'height=700,width=700');

            win.document.write('<html><head>');
            win.document.write('<title>Ata de Prova</title>'); // <title> CABEÇALHO DO PDF.
            win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
            win.document.write('</head>');
            win.document.write('<body>');
            win.document.write(minhaTabela); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
            win.document.write('</body></html>');

            win.document.close(); // FECHA A JANELA

            win.print(); // IMPRIME O CONTEUDO
        }
    }
})();