(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('new-matricula-plano-pagamento', {
            parent: 'app',
            url: '/new-matricula-plano-pagamento',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Matrícula Online - Plano Pagamento'
            },
            views: {
                'content@': {
                    templateUrl: 'app/matricula/calouro/planopgto/plano-pagamento.html',
                    controller: 'MatriculaOnlinePlanoPagamentoController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();