(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatrizCurricularDialogController', MatrizCurricularDialogController);

    MatrizCurricularDialogController.$inject = ['$http', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MatrizCurricular', 'Principal', '$state'];

    function MatrizCurricularDialogController($http, $timeout, $scope, $stateParams, $uibModalInstance, entity, MatrizCurricular, Principal, $state) {
        var vm = this;

        //vm.matrizCurricular = { id: 1 };
        vm.matrizCurricular = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.importarMatrizRm = importarMatrizRm;
        vm.setInfosCurso = setInfosCurso;
        vm.setInfosPeriodo = setInfosPeriodo;
        vm.setInfosHabilitacao = setInfosHabilitacao;
        vm.setInfosMatriz = setInfosMatriz;
        vm.setInfosTurno = setInfosTurno;
        vm.novaHabilitacao = novaHabilitacao;
        vm.cursosRM = null;
        vm.criarMatriz = false;

        vm.turnos = [
            { codTurno: "8", codFilial: "2", nome: "EAD" },
            { codTurno: "1", codFilial: "1", nome: "Matutino" },
            { codTurno: "3", codFilial: "1", nome: "Noturno" }
        ];

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

                if (vm.matrizCurricular == null) {
                    // se a matriz ta null GETCURSOSRM!
                    vm.matrizCurricular.codColigada = "1";
                    vm.matrizCurricular.codHabilitacao = "0";
                    vm.matrizCurricular.codGrade = "0";
                    vm.matrizCurricular.idHabilitacaoFilial = "0";
                    cursosRm();
                    periodosLetivosRm();
                } else {
                    if (vm.matrizCurricular.id == null) {
                        // se a matriz ta null GETCURSOSRM!
                        vm.matrizCurricular.codColigada = "1";
                        vm.matrizCurricular.codHabilitacao = "0";
                        vm.matrizCurricular.codGrade = "0";
                        vm.matrizCurricular.idHabilitacaoFilial = "0";
                        cursosRm();
                        periodosLetivosRm();
                    }
                }
            });
        };


        function save() {
            vm.isSaving = true;

            vm.matrizCurricular.alterDate = new Date();
            vm.matrizCurricular.alterUser = vm.account.login;

            if (vm.matrizCurricular.id !== null) {
                MatrizCurricular.update(vm.matrizCurricular, onSaveSuccess, onSaveError);
            } else {
                vm.matrizCurricular.createdDate = new Date();
                vm.matrizCurricular.createdUser = vm.account.login;
                MatrizCurricular.save(vm.matrizCurricular, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:matrizCurricularUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function importarMatrizRm() {

            vm.matrizCurricular.createdDate = new Date();
            vm.matrizCurricular.createdUser = vm.account.login;

            vm.matrizCurricular.alterDate = new Date();
            vm.matrizCurricular.alterUser = vm.account.login;

            console.log(vm.matrizCurricular);

            $http.post('api/matriz-curriculars-importada', vm.matrizCurricular)
                .success(function(data) {
                    $state.go("matriz-curricular-detail({id:" + data.id + "})");
                }).error(function(status) {
                    console.log(status);
                });




        }

        function cursosRm() {
            $http.post('api/matriz-getcursos-rm')
                .success(function(data) {
                    vm.cursosRM = data;
                    //console.log(vm.cursosRM);
                }).error(function(status) {
                    console.log(status);
                });
        }

        function periodosLetivosRm() {
            $http.post('api/matriz-getperiodosletivos-rm')
                .success(function(data) {
                    vm.periodosLetivos = data;
                    //console.log(vm.periodosLetivos);
                }).error(function(status) {
                    console.log(status);
                });
        }

        function getHabilitacaoCurso(curso) {
            $http.get('api/matriz-gethabilitacao-curso/' + curso)
                .success(function(data) {
                    vm.habsCurso = data;
                    //console.log(vm.habsCurso);
                }).error(function(status) {
                    console.log(status);
                });
        }

        function getMatrizCurso(curso) {
            $http.get('api/matriz-getMatriz-curso/' + curso)
                .success(function(data) {
                    vm.matrizCurso = data;
                    //console.log(vm.matrizCurso);
                }).error(function(status) {
                    console.log(status);
                });
        }
        // ta vamos lá, se o cara selecionar uma matriz, já tem habilitação e tudo então troca a etapa.

        function setInfosCurso() {
            vm.matrizCurricular.codCurso = vm.cursoRmSelected.codCursoRm;
            vm.matrizCurricular.curso = vm.cursoRmSelected.nomeRm;
            getHabilitacaoCurso(vm.matrizCurricular.codCurso);
            getMatrizCurso(vm.matrizCurricular.codCurso);
        };

        function setInfosPeriodo() {
            vm.matrizCurricular.idPerlet = vm.periodoSelected.idPerlet;
            vm.matrizCurricular.codPerlet = vm.periodoSelected.codPerlet;
        };

        function setInfosMatriz() {
            //console.log(vm.matrizRmSelected);
            vm.matrizCurricular.codColigada = vm.matrizRmSelected.codcoligada;
            vm.matrizCurricular.codHabilitacao = vm.matrizRmSelected.codhabilitacao;
            vm.matrizCurricular.codGrade = vm.matrizRmSelected.codgrade;
            vm.matrizCurricular.descricao = vm.matrizRmSelected.descricao;
        }

        function setInfosHabilitacao() {
            vm.matrizCurricular.codHabilitacao = vm.habSelected.codhabilitacao;
            vm.matrizCurricular.nomeHabilitacao = vm.habSelected.nome;
            var ano = vm.matrizCurricular.codPerlet.substring(2, 4);
            var curso = vm.matrizCurricular.curso.substring(0, 3);
            vm.matrizCurricular.descricao = curso;

            console.log(vm.matrizCurricular);
        }

        function setInfosTurno() {
            vm.matrizCurricular.codFilial = vm.turnoSelected.codFilial;
            vm.matrizCurricular.codTurno = vm.turnoSelected.codTurno;
            vm.matrizCurricular.turno = vm.turnoSelected.nome;
        }

        function novaHabilitacao() {
            var r = confirm('deseja criar uma nova habilitação para o curso ' + vm.cursoRmSelected.nome);
            if (r) {
                $http.post('api/matriz-saveEduHabilitacao-rm', vm.matrizCurricular)
                    .success(function(data) {
                        //console.log(data);
                        alert('Aguarde recarregarmos as habilitações para selecionar a nova.');
                        vm.habsCurso = null;
                        getHabilitacaoCurso(vm.matrizCurricular.codCurso);
                    }).error(function(status) {
                        console.log(status);
                    });
            }
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.alterDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        /** REFERENTE AO MD AUTOCOMPLETE */

        vm.querySearch = querySearch;
        vm.selectedItemChange = selectedItemChange;
        vm.searchTextChange = searchTextChange;

        function querySearch(query) {

            var results = query ? vm.cursosRM.filter(createFilterFor(query)) : vm.cursosRM,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChange(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChange(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterFor(query) {
            var str = query.toUpperCase();
            return function filterFn(curso) {
                return curso.nome.toUpperCase().includes(str);
            };
        }
    }
})();