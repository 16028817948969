(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('CursoCordenadorDialogController', CursoCordenadorDialogController);

    CursoCordenadorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CursoCordenador', 'Principal', 'Curso'];

    function CursoCordenadorDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, CursoCordenador, Principal, Curso) {
        var vm = this;

        vm.cursoCordenador = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                getCursos();
            });
        };

        function getCursos() {

            Curso.query(function(data) {
                vm.cursos = data;
            });
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {

            vm.cursoCordenador.idCurso = vm.cursoSelecionado.id;
            vm.cursoCordenador.nomeCurso = vm.cursoSelecionado.nome;
            vm.cursoCordenador.dataAlteracao = new Date();
            vm.cursoCordenador.userAlteracao = vm.account.login;

            vm.isSaving = true;
            if (vm.cursoCordenador.id !== null) {
                CursoCordenador.update(vm.cursoCordenador, onSaveSuccess, onSaveError);
            } else {
                vm.cursoCordenador.dataCriacao = new Date();
                vm.cursoCordenador.userCriacao = vm.account.login;
                CursoCordenador.save(vm.cursoCordenador, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:cursoCordenadorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataCriacao = false;
        vm.datePickerOpenStatus.dataAlteracao = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();