(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConsultaAgendadoController', ConsultaAgendadoController);

    ConsultaAgendadoController.$inject = ['$scope', '$state', 'DateUtils', 'Principal', 'ConsultaAgendamento', '$uibModal', 'EmailAprovacao', 'CancelaAgendamentoService', 'EditarInscricaoService', 'VerLeadAgendadoService'];

    function ConsultaAgendadoController($scope, $state, DateUtils, Principal, ConsultaAgendamento, $uibModal, EmailAprovacao, CancelaAgendamentoService, EditarInscricaoService, VerLeadAgendadoService) {

        var vm = this;
        vm.tableCandidatos = [];

        vm.consulta = {
            tipo: "NC",
            valor: "",
            data: new Date()
        }

        vm.account = null;
        vm.isAuthenticated = null;
        getAccount();

        vm.editarOpen = function(inscricao) {
            var dia = inscricao.dataProva.substring(8, 10);
            var mes = inscricao.dataProva.substring(5, 7);
            var ano = inscricao.dataProva.substring(0, 4);

            inscricao.dataProva = new Date(ano, mes - 1, dia);
            EditarInscricaoService.open(inscricao);
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        vm.consultar = function() {

            var tipoPesquisado = "empty";
            var valorPesquisada = "empty";
            var dataPesquisada = "empty";

            if (vm.consulta.tipo == undefined || vm.consulta.tipo == null || vm.consulta.tipo == "NC") {
                tipoPesquisado = "empty";
                valorPesquisada = "empty";
            } else {
                tipoPesquisado = vm.consulta.tipo;
                valorPesquisada = vm.consulta.valor;
            }

            if (vm.consulta.data != undefined) {
                var dia = vm.consulta.data.getDate();
                var mes = (vm.consulta.data.getMonth() + 1);
                var ano = vm.consulta.data.getFullYear();

                for (var i = 1; i < 10; i++) {
                    if (vm.consulta.data.getDate() == i) {
                        dia = "0" + i;
                    }
                    if ((vm.consulta.data.getMonth() + 1) == i) {
                        mes = "0" + i;
                    }
                }
                var dataPesquisada = ano + "" + mes + "" + dia;
            }

            var obj = {
                param: tipoPesquisado,
                param2: valorPesquisada,
                param3: dataPesquisada,
                param4: vm.account.login
            };

            console.log(obj);

            ConsultaAgendamento.query(obj, function(result) {
                    vm.tableCandidatos = result;
                    console.log(vm.tableCandidatos);
                },
                function(error) {
                    console.log(error);
                });
        }

        vm.open = function(candidato) {
            sessionStorage.setItem('candidato', JSON.stringify(candidato));
            $state.go('ficha-inscricao');
        };

        vm.sendmail = function(id) {
            var r = confirm("Deseja realmente enviar e-mail para este candidado?");
            if (r == true) {
                alert('Enviando e-mail para o candidato!');
                EmailAprovacao.query({ param: id });
            } else {
                // NO
            }
        };

        vm.cancelaAgendamento = function(id) {
            alert('Cancelando agendamento de: ' + id);
            CancelaAgendamentoService.delete({ param: id },
                function(result) {
                    alert('Cancelando com sucesso');
                },
                function(status) {
                    alert('Erro ao cancelar');
                });
        }

        vm.verLead = function(id) {
            alert('Ver Lead de: ' + id);
            VerLeadAgendadoService.query({ id: id },
                function(result) {
                    vm.tablePesquisa = result;
                    console.log(vm.tablePesquisa);
                },
                function(status) {
                    console.log(status);
                });
        };

        vm.provaCandidato = function(inscricao) {
            sessionStorage.setItem('insc-prova-online-detail', JSON.stringify(inscricao));
            $state.go('prova-online');

        };
    }
})();