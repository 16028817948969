(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('LinkVendedorDialogController', LinkVendedorDialogController);

    LinkVendedorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LinkVendedor', 'Principal'];

    function LinkVendedorDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, LinkVendedor, Principal) {
        var vm = this;

        vm.linkVendedor = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.linkVendedor.createdUser = vm.account.login;

            if (vm.linkVendedor.id !== null) {
                LinkVendedor.update(vm.linkVendedor, onSaveSuccess, onSaveError);
            } else {
                LinkVendedor.save(vm.linkVendedor, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:linkVendedorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();