(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('NovoPlanoEstagioController', NovoPlanoEstagioController);

    NovoPlanoEstagioController.$inject = ['$state', 'Principal', 'BuscaDiscPlano', 'PlanoEnsino', 'PlanoEnsinoItens', 'BuscaLivrosPlanoService', 'PlanoEnsinoBibliografia', 'PlanoEnsinoLog', 'BuscaDiscCadastradas', 'PlanoEnsinoDisciplina', 'SalvaItensPlanoService', 'BuscaDiscsPlanoIdService', 'BuscaItensPlanoService'];

    function NovoPlanoEstagioController($state, Principal, BuscaDiscPlano, PlanoEnsino, PlanoEnsinoItens, BuscaLivrosPlanoService, PlanoEnsinoBibliografia, PlanoEnsinoLog, BuscaDiscCadastradas, PlanoEnsinoDisciplina, SalvaItensPlanoService, BuscaDiscsPlanoIdService, BuscaItensPlanoService) {
        var vm = this;

        //vm.createPlano = create;
        vm.nextForm = nextForm;
        vm.getLivros = getLivros;
        /*vm.addLivro = addLivro;
        vm.removeLivro = removeLivro;*/
        vm.consultarDisc = consultarDisc;
        vm.addDisc = addDisc;
        vm.removeDisc = removeDisc;
        vm.addTxtCriterios = addTxtCriterios;

        vm.planos = null;
        vm.disciplinas = null;
        vm.livroSelecionado = null;
        vm.LNE = {};
        vm.plano = {};
        vm.rowspan = 0;
        vm.livrosB = null;
        vm.livrosC = null;
        vm.user = {};
        vm.cordenador = false;
        vm.nucleo = false;
        vm.disciplinas = [];
        vm.biblioApro = [];
        vm.biblioBasica = [];
        vm.biblioCompl = [];
        vm.disciplinaSelecionada = [];
        vm.itensPlano = [];
        vm.showResumo = false;
        vm.resultadoDisc = [];

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                init();
            });
        };


        function addTxtCriterios(tipo, bim) {
            switch (tipo) {
                case 'INTEGRADORA':
                    if (vm.controleCriteriosIntegradora1 == true) {
                        if (bim == 1) {
                            vm.plano.inst1b = vm.plano.inst1b + " Avaliação Integradora: 2,0 pontos, sendo a distribuição da nota de acordo com o aproveitamento do aluno: Alunos que acertarem 60% ou mais da avaliação integradora receberão 2,0 pontos; Alunos que acertarem entre 40% a 59,9% receberão 1,5 ponto; Alunos que acertarem entre 20% e 39,9% receberão 1,0 ponto; Alunos que acertarem menos que 20% receberão 0,5 ponto. ";
                        }
                    }
                    if (vm.controleCriteriosIntegradora2 == true) {
                        if (bim == 2) {
                            vm.plano.inst2b = vm.plano.inst2b + " Avaliação Integradora: 2,0 pontos, sendo a distribuição da nota de acordo com o aproveitamento do aluno: Alunos que acertarem 60% ou mais da avaliação integradora receberão 2,0 pontos; Alunos que acertarem entre 40% a 59,9% receberão 1,5 ponto; Alunos que acertarem entre 20% e 39,9% receberão 1,0 ponto; Alunos que acertarem menos que 20% receberão 0,5 ponto. ";
                        }
                    }
                    break;
                case 'AVA':
                    if (vm.controleCriteriosAva1 == true) {
                        if (bim == 1) {
                            vm.plano.inst1b = vm.plano.inst1b + " Atividades do AVA: Até 1,5, conforme o percentual de acerto das questões disponibizadas no Ambiente Virtual de Aprendizagem (AVA). ";
                        }
                    }
                    if (vm.controleCriteriosAva2 == true) {
                        if (bim == 2) {
                            vm.plano.inst2b = vm.plano.inst2b + " Atividades do AVA: Até 1,5, conforme o percentual de acerto das questões disponibizadas no Ambiente Virtual de Aprendizagem (AVA). ";
                        }
                    }
                    break;
            }
        }

        function init() {
            var temp = JSON.parse(sessionStorage.getItem('plano-ensino'));
            console.log(temp);


            vm.discTemp = JSON.parse(sessionStorage.getItem('plano-ensino-disciplinas'));
            vm.itensPlano = JSON.parse(sessionStorage.getItem('plano-ensino-itens'));

            if (temp != null) {
                switch (temp.chTotal) {
                    case 35:
                        temp.chTotal = "35";
                        break;
                    case 40:
                        temp.chTotal = "40";
                        break;
                    case 70:
                        temp.chTotal = "70";
                        break;
                    case 80:
                        temp.chTotal = "80";
                        break;
                    case 100:
                        temp.chTotal = "100";
                        break;
                    case 120:
                        temp.chTotal = "120";
                        break;
                    case 130:
                        temp.chTotal = "130";
                        break;
                    case 160:
                        temp.chTotal = "160";
                        break;
                }

                if (vm.discTemp != null) {
                    vm.disciplinaSelecionada = vm.discTemp;
                } else {
                    BuscaDiscsPlanoIdService.query({ id: temp.id }, function(turmas) {
                        vm.disciplinaSelecionada = turmas;
                    }, function(status) { console.log(status) });
                }

                if (vm.itensPlano == null) {
                    BuscaItensPlanoService.query({ id: temp.id },
                        function(itens) {
                            for (var i = 0; i < itens.length; i++) {
                                var e = itens[i];

                                if (e.tipo == 'COMPETENCIA') { temp.competencias = e.texto; };
                                if (e.tipo == 'EMENTA') { temp.ementa = e.texto; };
                                if (e.tipo == 'INSTAVA1B') { temp.inst1b = e.texto; };
                                if (e.tipo == 'INSTAVA2B') { temp.inst2b = e.texto; };
                                if (e.tipo == 'BBASICA') { temp.bBasica = e.texto; };
                                if (e.tipo == 'BCOMPL') { temp.bCompl = e.texto; };
                                if (e.tipo == 'BAPRO') { temp.bApro = e.texto; };
                                if (e.tipo == 'CONT1') { temp.cont1 = e.texto; };
                                if (e.tipo == 'MET1') { temp.met1 = e.texto; };
                            };
                        },
                        function(status) { console.log(status); });
                }

                vm.plano = temp;
                vm.etapaForm = vm.plano.etapa;

            } else {
                vm.etapaForm = "INICIO";
                vm.plano.chPratica = 0;
                vm.plano.chTeorica = 0;
                vm.plano.chExtensao = 0;
                vm.plano.chAs = 0;
                vm.plano.chPresencial = 0;
                vm.plano.chOnline = 0;
                vm.plano.chEad = 0;

                vm.plano.pontAb1 = 0;
                vm.plano.pontAb2 = 0;

                vm.plano.pontAs1 = 0;
                vm.plano.pontAs2 = 0;

                vm.plano.pontTrab1 = 0;
                vm.plano.pontTrab2 = 0;

                vm.plano.inst1b = "";
                vm.plano.inst2b = "";

                vm.plano.tipoPlano = "ESTAGIO";
            }
        }

        function addDisc(item) {
            for (var i = 0; i < vm.resultadoDisc.length; i++) {
                var d = vm.resultadoDisc[i];
                if (item.idTurmaDisc == d.idTurmaDisc) {
                    vm.resultadoDisc.splice(i, 1);
                    vm.disciplinaSelecionada.push(item);
                }
            }
        }

        function removeDisc(item) {
            for (var i = 0; i < vm.disciplinaSelecionada.length; i++) {
                var d = vm.disciplinaSelecionada[i];
                if (item.idTurmaDisc == d.idTurmaDisc) {
                    vm.disciplinaSelecionada.splice(i, 1);
                    vm.resultadoDisc.push(item);
                }
            }
        }

        function consultarDisc() {
            console.log("consultando: " + vm.consultaDisc.curso + " - " + vm.consultaDisc.disc);
            var obj = {
                disciplina: vm.consultaDisc.disc,
                curso: vm.consultaDisc.curso,
                tipoUser: vm.plano.tipoPlano
            }

            vm.resultadoDisc = [];
            BuscaDiscPlano.query(obj,
                function(data) {
                    console.log(data);
                    BuscaDiscCadastradas.query(
                        function(discs) {
                            for (var i = 0; i < data.length; i++) {
                                var d = data[i];
                                if (!discs.includes(d.idTurmaDisc)) {
                                    vm.resultadoDisc.push(d);
                                }
                            }
                            //console.log(vm.resultadoDisc);    
                        },
                        function(status) {
                            console.log(status);
                        });
                },
                function(status) {
                    console.log(status);
                });
        }

        function getLivros(tipo) {
            BuscaLivrosPlanoService.query({ chave: vm.consultaLivro },
                function(data) {

                    if (tipo == 'BASICA') {
                        vm.livrosB = data;
                    }

                    if (tipo == 'COMPLEMENTAR') {
                        vm.livrosC = data;
                    }

                    console.log(vm.livrosB);
                    console.log(vm.livrosC);
                },
                function(status) {
                    console.log(status);
                });
        }

        function insereLog(log) {
            PlanoEnsinoLog.save(log);
        }

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function nextForm(etapa) {
            console.log(vm.plano);
            var valida = validaCampos(etapa);

            if (valida == "OK") {

                if (etapa == 'FORM1') { // INICIO
                    if (vm.plano.etapa == null) {
                        var e = vm.disciplinaSelecionada[0];
                        vm.plano.curso = e.curso;
                        vm.plano.codCurso = e.codCurso;
                        vm.plano.disciplina = e.disciplina;
                        //vm.plano.codDisc = e.idTurmaDisc;
                        //vm.plano.turma = e.codTurma;
                        vm.plano.codPerlet = e.codPerlet;
                        vm.plano.idPerlet = e.idPerlet;
                        vm.plano.userCriacao = vm.account.login;
                        vm.plano.userAlteracao = vm.account.login;
                        vm.plano.professor = vm.account.firstName;
                        vm.plano.emailProfessor = vm.account.email;

                        sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                        sessionStorage.setItem('plano-ensino-disciplinas', JSON.stringify(vm.disciplinaSelecionada));
                    }
                }

                if (etapa == 'FORM2') { // CH
                    var etapaAnt = "FORM1";
                    vm.plano.etapa = "FORM2";
                    sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                    PlanoEnsino.save(vm.plano,
                        function(data) {
                            vm.plano.id = data.id;
                            vm.plano.dataCriacao = data.dataCriacao;
                            insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });

                            for (var i = 0; i < vm.disciplinaSelecionada.length; i++) {
                                var d = vm.disciplinaSelecionada[i];

                                PlanoEnsinoDisciplina.save({
                                    idPlano: vm.plano.id,
                                    codDisc: d.idTurmaDisc,
                                    idPerlet: d.idPerlet,
                                    turma: d.codTurma,
                                    disciplina: d.disciplina,
                                    createdDate: new Date(),
                                    createdUser: vm.account.login
                                }, function(data) {
                                    console.log(data);
                                }, function(result) {
                                    console.log(result);
                                });
                            }

                        });
                }

                if (etapa == 'FORM3') { // CONT
                    var etapaAnt = vm.plano.etapa;
                    vm.plano.etapa = etapa;
                    sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                    PlanoEnsino.update(vm.plano,
                        function(data) {
                            insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });
                        }
                    );
                }

                if (etapa == 'FORM4') { // INST
                    var etapaAnt = vm.plano.etapa;
                    vm.plano.etapa = etapa;
                    sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                    PlanoEnsino.update(vm.plano, function(data) {
                        insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });
                    });
                }

                if (etapa == 'FORM5') { // OBS
                    var etapaAnt = vm.plano.etapa;
                    vm.plano.etapa = etapa;
                    sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                    PlanoEnsino.update(vm.plano, function(data) {
                        insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });
                    });
                }

                if (etapa == 'FORM6') { // RESUMO
                    var etapaAnt = vm.plano.etapa;
                    vm.plano.etapa = etapa;
                    sessionStorage.setItem('plano-ensino', JSON.stringify(vm.plano));
                    PlanoEnsino.update(vm.plano, function(data) {
                        insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });
                        addItensPlanoEnsino();
                    });
                }

                if (etapa == 'PENDENTE') { // FINAL
                    var etapaAnt = vm.plano.etapa;
                    vm.plano.etapa = etapa;
                    PlanoEnsino.update(vm.plano, function(data) {
                        insereLog({ idPlano: vm.plano.id, item: "PLANO", operacao: "UPDATE", valor: vm.plano.etapa, valorAnt: etapaAnt, user: vm.account.login });
                        vm.salvandoItens = true;
                        SalvaItensPlanoService.query(vm.itensPlano, function(data) {
                            vm.salvandoItens = false;
                            $state.go('plano-de-ensino');
                            showMessage("alert alert-success", data);
                        }, function(result) {
                            showMessage("alert alert-danger", result);
                        });
                    });
                }
                showMessage('invi', '');
                vm.etapaForm = etapa;

            } else {
                showMessage("alert alert-danger", valida);
            }
        }

        function validaCampos(etapa) {

            if (etapa == "FORM1") {
                if (vm.disciplinaSelecionada.length <= 0) {
                    return "Selecione ao menos uma disciplina para avançar.";
                }
            }

            if (etapa == "FORM2") {
                if (vm.plano.chPratica == null) {
                    document.getElementById('CHPRATICA').style.backgroundColor = 'yellow';
                    document.getElementById('CHPRATICA').focus();
                    return "O campo CH PRATICA está incorreto";
                }
                if (vm.plano.chTeorica == null) {
                    document.getElementById('CHTEORICA').style.backgroundColor = 'yellow';
                    document.getElementById('CHTEORICA').focus();
                    return "O campo CH TEÓRICA está incorreto";
                }
                if (vm.plano.chExtensao == null) {
                    document.getElementById('CHEXTENSAO').style.backgroundColor = 'yellow';
                    document.getElementById('CHEXTENSAO').focus();
                    return "O campo CH EXTENSÃO está incorreto";
                }
                if (vm.plano.chAs == null) {
                    document.getElementById('CHAS').style.backgroundColor = 'yellow';
                    document.getElementById('CHAS').focus();
                    return "O campo CH AS está incorreto";
                }
                if (vm.plano.chPresencial == null) {
                    document.getElementById('CHPRESENCIAL').style.backgroundColor = 'yellow';
                    document.getElementById('CHPRESENCIAL').focus();
                    return "O campo CH PRESENCIAL está incorreto";
                }
                if (vm.plano.chOnline == null) {
                    document.getElementById('CHONLINE').style.backgroundColor = 'yellow';
                    document.getElementById('CHONLINE').focus();
                    return "O campo CH ONLINE está incorreto";
                }
                if (vm.plano.chEad == null) {
                    document.getElementById('CHEAD').style.backgroundColor = 'yellow';
                    document.getElementById('CHEAD').focus();
                    return "O campo CH EAD está incorreto";
                }
                if (vm.plano.chTotal == null || vm.plano.chTotal == "") {
                    document.getElementById('CHTOTAL').style.backgroundColor = 'yellow';
                    document.getElementById('CHTOTAL').focus();
                    return "O campo CH TOTAL está incorreto";
                }

                var chCalculo1 = (vm.plano.chPresencial + vm.plano.chOnline + vm.plano.chEad);

                if (chCalculo1 != vm.plano.chTotal) {
                    document.getElementById('CHTOTAL').style.backgroundColor = 'yellow';
                    document.getElementById('CHTOTAL').focus();
                    return "O cálculo de Modalidade:(" + chCalculo1 + ") está divergente do campo CH TOTAL(" + vm.plano.chTotal + ").";
                }

                var chCalculo2 = (vm.plano.chPratica + vm.plano.chTeorica + vm.plano.chExtensao + vm.plano.chAs);

                if (chCalculo2 != vm.plano.chTotal) {
                    document.getElementById('CHTOTAL').style.backgroundColor = 'yellow';
                    document.getElementById('CHTOTAL').focus();
                    return "O cálculo de CH tipo: (" + chCalculo2 + ") está divergente do campo CH TOTAL(" + vm.plano.chTotal + ").";
                }

                if (vm.plano.ementa == null || vm.plano.ementa == "") {
                    document.getElementById('EMENTA').style.backgroundColor = 'yellow';
                    document.getElementById('EMENTA').focus();
                    return "O campo EMENTA está incorreto";
                }
                if (vm.plano.competencias == null || vm.plano.competencias == "") {
                    document.getElementById('COMPETENCIA').style.backgroundColor = 'yellow';
                    document.getElementById('COMPETENCIA').focus();
                    return "O campo COMPETÊNCIA está incorreto";
                }



            }

            if (etapa == "FORM3") {
                if (vm.plano.cont1  ==  null   ||   vm.plano.cont1  ==  '')   {
                    document.getElementById('CONT1').style.backgroundColor = 'yellow';
                    document.getElementById('CONT1').focus();
                    return  'O campo CONTEÚDO PREVISTO 1 está incorreto'; 
                };
                if (vm.plano.met1  ==  null  ||  vm.plano.met1   ==   '')   { 
                    document.getElementById('MET1').style.backgroundColor = 'yellow';
                    document.getElementById('MET1').focus(); 
                    return  'O campo METODOLOGIA PREVISTA 1 está incorreto'; 
                };
            }

            if (etapa == "FORM4") {
                if (vm.plano.inst1b == null || vm.plano.inst1b == "") {
                    document.getElementById('INSTAVA1B').style.backgroundColor = 'yellow';
                    document.getElementById('INSTAVA1B').focus();
                    return "O campo INSTRUMENTOS AVALIATIVOS 1° BIMESTRE está incorreto";
                }
                if (vm.plano.inst2b == null || vm.plano.inst2b == "") {
                    document.getElementById('INSTAVA2B').style.backgroundColor = 'yellow';
                    document.getElementById('INSTAVA2B').focus();
                    return "O campo INSTRUMENTOS AVALIATIVOS 2° BIMESTRE está incorreto";
                }


                /*if (vm.plano.pontAs1 == null) {
                    document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAS1').focus();
                    return "O campo PONTUAÇÃO AS 1° BIMESTRE está incorreto";
                }
                if (vm.plano.pontAs2 == null) {
                    document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAS2').focus();
                    return "O campo PONTUAÇÃO AS 2° BIMESTRE está incorreto";
                }

                if (vm.plano.pontAb1 == null) {
                    document.getElementById('PONTAB1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAB1').focus();
                    return "O campo PONTUAÇÂO AVALIAÇÂO 1° BIMESTRE está incorreto";
                }

                if (vm.plano.pontAb2 == null) {
                    document.getElementById('PONTAB2').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAB2').focus();
                    return "O campo PONTUAÇÂO AVALIAÇÂO 2° BIMESTRE está incorreto";
                }


                if (vm.plano.pontTrab1 == null) {
                    document.getElementById('PONTTRAB1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTTRAB1').focus();
                    return "O campo PONTUAÇÃO DE TRABALHOS 1° BIMESTRE está incorreto";
                }

                if (vm.plano.pontTrab2 == null) {
                    document.getElementById('PONTTRAB2').style.backgroundColor = 'yellow';
                    document.getElementById('PONTTRAB2').focus();
                    return "O campo PONTUAÇÃO DE TRABALHOS 1° BIMESTRE está incorreto";
                }

                var bim1 = vm.plano.pontAb1 + vm.plano.pontAs1 + vm.plano.pontTrab1;
                var bim2 = vm.plano.pontAb2 + vm.plano.pontAs2 + vm.plano.pontTrab2;
                var as = vm.plano.pontAs1 + vm.plano.pontAs2;

                if (as > vm.limiteAs) {
                    document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                    return "A soma da Pontuação das AS( " + as + " ) ultrapassa o limite da Carga Horária: (" + vm.limiteAs + ").";
                }

                if (bim1 != 100) {
                    document.getElementById('PONTAS1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAB1').style.backgroundColor = 'yellow';
                    document.getElementById('PONTTRAB1').style.backgroundColor = 'yellow';
                    return "A soma da Pontuação do 1° Bimestre( " + bim1 + " ) está diferente de 100";
                }

                if (bim2 != 100) {
                    document.getElementById('PONTAS2').style.backgroundColor = 'yellow';
                    document.getElementById('PONTAB2').style.backgroundColor = 'yellow';
                    document.getElementById('PONTTRAB2').style.backgroundColor = 'yellow';
                    return "A soma da Pontuação do 2° Bimestre( " + bim2 + " ) está diferente de 100";
                }*/
            }

            if (etapa == "FORM6") {
                if (vm.plano.bBasica  ==  null  ||  vm.plano.bBasica ==   '')   { 
                    document.getElementById('BBASICA').style.backgroundColor = 'yellow';
                    document.getElementById('BBASICA').focus(); 
                    return  'O campo Bibliografia Básica está incorreto'; 
                };

                if (vm.plano.bCompl ==  null  ||  vm.plano.bCompl ==   '')   { 
                    document.getElementById('BCOMPL').style.backgroundColor = 'yellow';
                    document.getElementById('BCOMPL').focus(); 
                    return  'O campo Bibliografia Complementar está incorreto'; 
                };

                if (vm.plano.bApro ==  null  ||  vm.plano.bApro ==   '')   { 
                    document.getElementById('BAPRO').style.backgroundColor = 'yellow';
                    document.getElementById('BAPRO').focus(); 
                    return  'O campo Bibliografia de Aprofundamento está incorreto'; 
                };

            }

            return "OK";
        }

        function addItensPlanoEnsino() {
            vm.itensPlano = [];

            vm.itensPlano.push({ tipo: "EMENTA", idPlano: vm.plano.id, texto: vm.plano.ementa, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "COMPETENCIA", idPlano: vm.plano.id, texto: vm.plano.competencias, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "CONT1", idPlano: vm.plano.id, texto: vm.plano.cont1, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "MET1", idPlano: vm.plano.id, texto: vm.plano.met1, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "AS1", idPlano: vm.plano.id, texto: vm.plano.contAs1, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "AS2", idPlano: vm.plano.id, texto: vm.plano.contAs2, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "INSTAVA1B", idPlano: vm.plano.id, texto: vm.plano.inst1b, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "INSTAVA2B", idPlano: vm.plano.id, texto: vm.plano.inst2b, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "BBASICA", idPlano: vm.plano.id, texto: vm.plano.bBasica, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "BCOMPL", idPlano: vm.plano.id, texto: vm.plano.bCompl, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "BAPRO", idPlano: vm.plano.id, texto: vm.plano.bApro, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            vm.itensPlano.push({ tipo: "OBSG", idPlano: vm.plano.id, texto: vm.plano.obsg, userCriacao: vm.account.login, userAlteracao: vm.account.login });
            sessionStorage.setItem('plano-ensino-itens', JSON.stringify(vm.itensPlano));
            vm.showResumo = true;
        }

        $(document).ready(function() {

            $('#CONT1').change(function() { document.getElementById('CONT1').style.backgroundColor = 'transparent'; });
            $('#MET1').change(function() { document.getElementById('MET1').style.backgroundColor = 'transparent'; });

            $('#EMENTA').change(function() { document.getElementById('EMENTA').style.backgroundColor = 'transparent'; });
            $('#COMPETENCIA').change(function() { document.getElementById('COMPETENCIA').style.backgroundColor = 'transparent'; });
            $('#INSTAVA1B').change(function() { document.getElementById('INSTAVA1B').style.backgroundColor = 'transparent'; });
            $('#INSTAVA2B').change(function() { document.getElementById('INSTAVA2B').style.backgroundColor = 'transparent'; });
            $('#AS1').change(function() { document.getElementById('AS1').style.backgroundColor = 'transparent'; });
            $('#AS2').change(function() { document.getElementById('AS2').style.backgroundColor = 'transparent'; });
            $('#TPBB').change(function() { document.getElementById('TPBB').style.backgroundColor = 'transparent'; });
            $('#OBSG').change(function() { document.getElementById('OBSG').style.backgroundColor = 'transparent'; });
            $('#CHTOTAL').change(function() { document.getElementById('CHTOTAL').style.backgroundColor = 'white'; });
            $('#PONTAS1').change(function() { document.getElementById('PONTAS1').style.backgroundColor = 'transparent'; });
            $('#PONTAS2').change(function() { document.getElementById('PONTAS2').style.backgroundColor = 'transparent'; });
            $('#PONTAB1').change(function() { document.getElementById('PONTAB1').style.backgroundColor = 'transparent'; });
            $('#PONTAB2').change(function() { document.getElementById('PONTAB2').style.backgroundColor = 'transparent'; });
            $('#PONTTRAB1').change(function() { document.getElementById('PONTTRAB1').style.backgroundColor = 'transparent'; });
            $('#PONTTRAB2').change(function() { document.getElementById('PONTTRAB2').style.backgroundColor = 'transparent'; });

            $('#CHTOTAL').focus(function() { document.getElementById('divMsg').innerText = 'Informe o total de Carga Horária da Disciplina'; });
            $('#CHPRESENCIAL').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma presencial?'; });
            $('#CHONLINE').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma online?'; });
            $('#CHEAD').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma EAD?'; });
            $('#CHPRATICA').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma prática?'; });
            $('#CHTEORICA').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma teorica?'; });
            $('#CHEXTENSAO').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas contarão como extensão?'; });
            $('#CHAS').focus(function() { document.getElementById('divMsg').innerText = 'Do total de CH, quantas horas serão lecionadas de forma Atividade supervisionada?'; });
            $('#EMENTA').focus(function() { document.getElementById('divMsg').innerText = 'Informe neste campo a ementa do curso'; });
            $('#COMPETENCIA').focus(function() { document.getElementById('divMsg').innerText = 'Informe neste campo as Competências e Habilidades Específicas da Disciplina.'; });
            $('#AS1').focus(function() { document.getElementById('divMsg').innerText = 'Descreva as Atividades Supervisionadas do 1° Bimestre neste campo.'; });
            $('#AS2').focus(function() { document.getElementById('divMsg').innerText = 'Descreva as Atividades Supervisionadas do 2° Bimestre neste campo.'; });
            $('#INSTAVA1').focus(function() { document.getElementById('divMsg').innerText = 'Descreva os Instrumentos Avaliativos e Critérios de Avaliação do 1° Bimestre neste campo.'; });
            $('#INSTAVA2').focus(function() { document.getElementById('divMsg').innerText = 'Descreva os Instrumentos Avaliativos e Critérios de Avaliação do 2° Bimestre neste campo.'; });
            $('#OBSG').focus(function() { document.getElementById('divMsg').innerText = 'Caso seja necessário informe alguma observação observação a respeito do Plano de Ensino.'; });

            $('#PONTAS1').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 1° Bimestre a ser preenchida por Atividades Supervisionadas.'; });
            $('#PONTAS2').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 2° Bimestre a ser preenchida por Atividades Supervisionadas.'; });
            $('#PONTAB1').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 1° Bimestre a ser preenchida por Avaliação.'; });
            $('#PONTAB2').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 2° Bimestre a ser preenchida por Avaliação.'; });
            $('#PONTTRAB1').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 1° Bimestre a ser preenchida por Trabalhos.'; });
            $('#PONTTRAB2').focus(function() { document.getElementById('divMsg').innerText = 'Descreva a Pontuação total do 2° Bimestre a ser preenchida por Trabalhos.'; });

            $('.contjq').focus(function() { document.getElementById('divMsg').innerText = 'Descreva os conteúdos previstos em cada aula (todos os campos devem ser preenchidos).'; });
            $('.metjq').focus(function() { document.getElementById('divMsg').innerText = 'Descreva as metodologias e recursos de cada aula (todos os campos devem ser preenchidos).'; });

        });
    }
})();





/*
   biblioteca integrada next form
   for (var i = 0; i < vm.biblioBasica.length; i++) {
                       var element = vm.biblioBasica[i];
                       PlanoEnsinoBibliografia.save(element);
                       insereLog({
                           idPlano: vm.plano.id,
                           item: "BBASICA",
                           operacao: "INSERT",
                           valor: element.nome,
                           valorAnt: "",
                           user: vm.account.login
                       });
                   }
                   for (var j = 0; j < vm.biblioCompl.length; j++) {
                       var element = vm.biblioCompl[j];
                       PlanoEnsinoBibliografia.save(element);
                       insereLog({
                           idPlano: vm.plano.id,
                           item: "BCOMPL",
                           operacao: "INSERT",
                           valor: element.nome,
                           valorAnt: "",
                           user: vm.account.login
                       });
                   }
               

   biblioteca integrada valida validaCampos
    if (etapa == 10) {
               if (vm.livroSelecionado == null) {
                   return "Por favor, selecione um livro para adicionar.";
               }
               switch (vm.tipoLivro) {
                   case "BASICA":
                       for (var i = 0; i < vm.biblioBasica.length; i++) {
                           var element = vm.biblioBasica[i];
                           if (vm.livroSelecionado.posse == true) {
                               if (element.idTombo == vm.livroSelecionado.idTombo) {
                                   return "Este livro já está inserido na bibliografia básica";
                               }
                           } else {
                               if (element.nome == vm.livroSelecionado.nome) {
                                   return "Este livro já está inserido na bibliografia básica";
                               }
                           }
                       }
                       break;
                   case "COMPLEMENTAR":
                       for (var i = 0; i < vm.biblioCompl.length; i++) {
                           var element = vm.biblioCompl[i];
                           if (vm.livroSelecionado.posse == true) {
                               if (element.idTombo == vm.livroSelecionado.idTombo) {
                                   return "Este livro já está inserido na bibliografia Complementar";
                               }
                           } else {
                               if (element.nome == vm.livroSelecionado.nome) {
                                   return "Este livro já está inserido na bibliografia Complementar";
                               }
                           }
                       }
                   break;
               }
           }
                   */
/*   referente aos estados   */
/*vm.querySearchDisciplina = querySearchDisciplina;
vm.selectedItemChangeDisciplina = selectedItemChangeDisciplina;
vm.searchTextChangeDisciplina = searchTextChangeDisciplina;

function querySearchDisciplina(query) {

    var results = query ? vm.disciplinas.filter(createFilterForDisciplina(query)) : vm.disciplinas,
        deferred;

    if (vm.simulateQuery) {
        deferred = $q.defer();
        $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
        return deferred.promise;
    } else {
        return results;
    }
}

function searchTextChangeDisciplina(text) {
    //$log.info('Text changed to ' + text);
}

function selectedItemChangeDisciplina(item) {
    vm.plano.curso = vm.disciplinaSelecionada.curso;
    vm.plano.codCurso = vm.disciplinaSelecionada.codCurso;
    vm.plano.disciplina = vm.disciplinaSelecionada.disciplina;
    vm.plano.codDisc = vm.disciplinaSelecionada.idTurmaDisc;
    vm.plano.turma = vm.disciplinaSelecionada.codTurma;
    vm.plano.codPerlet = vm.disciplinaSelecionada.codPerlet;
    vm.plano.idPerlet = vm.disciplinaSelecionada.idPerlet;
    vm.plano.userCriacao = vm.account.login;
    vm.plano.userAlteracao = vm.account.login;
    vm.plano.professor = vm.account.firstName;
    vm.plano.emailProfessor = vm.account.email;
    vm.plano.etapa = "FORM1";
}

function createFilterForDisciplina(query) {
    var lowercaseQuery = angular.uppercase(query);

    return function filterFn(disc) {
        //var a = disc.disciplina + " - " + disc.codTurma + " - " + disc.curso + " - " + disc.codPerlet;
        var a = disc.disciplina + ' - ' + disc.curso + ' - ' + disc.periodo + '° ' + disc.codTurma + ' ' + disc.turno + ' de ' + disc.codPerlet;
        return a.includes(lowercaseQuery);
    };

}*/