(function () {
	'use strict';

	angular
		.module('vestibularApp')
		.controller('HomeRelatorioController', HomeRelatorioController);

	HomeRelatorioController.$inject = ['$scope', 'Principal', '$state', '$http', 'DataUtils', 'GetAllRelatoriosService', 'GetRelatoriosByTipoService'];

	function HomeRelatorioController($scope, Principal, $state, $http, DataUtils, GetAllRelatoriosService, GetRelatoriosByTipoService) {
		var vm = this;

		vm.account = null;
		vm.isAuthenticated = null;

		vm.byteSize = DataUtils.byteSize;
		vm.openFile = DataUtils.openFile;

		getAccount();

		function getAccount() {
			Principal.identity().then(function (account) {
				vm.account = account;
				vm.isAuthenticated = Principal.isAuthenticated;
				vm.loadAllRelatorios(20);
			});
		}
		vm.loadAllRelatorios = function (limit) {
			console.log(limit);
			vm.relatorios = [];
			GetAllRelatoriosService.get({ limit: limit }, function (response) {
				vm.relatorios = response;
			});
		}

		vm.filterByTipo = function (tipo) {
			vm.limit = null;
			vm.relatorios = [];
			GetRelatoriosByTipoService.get({ tipo: tipo }, function (response) {
				vm.relatorios = response;
			});
		}

		vm.searchByProfessorOrTitulo = function () {
			var obj = {
				tipo: vm.tipoPesquisa,
				text: vm.data
			};

			if (vm.tipoPesquisa == null || vm.tipoPesquisa == '' || vm.tipoPesquisa == undefined) {
				alert("Selecione o tipo de pesquisa!");
			}
			if (vm.data == null || vm.data == '' || vm.data == undefined) {
				if (vm.tipoPesquisa.includes("professor")) {
					alert("Informe o nome do professor que deseja pesquisar.");
					return null;
				}
				if (vm.tipoPesquisa.includes("titulo")) {
					alert("Informe o titulo do relatorio que deseja pesquisar.");
					return null;
				}
			}
			getRelatoriosSearch(obj);

		}

		function getRelatoriosSearch(obj) {
			$http.post("/api/relatorios-cpe/search", obj)
				.success(function (response) {
					console.log(response);
					vm.relatorios = response;
				}).error(function (status) {
					console.log(status);
				});
		}


		vm.removeRelatorio = function (relatorio) {
			var msg = "Deseja realmente deletar o  relatorio " + relatorio.titulo + "?"
			if (confirm(msg)) {
				$http.post("/api/relatorios-cpe/remove-relatorio", relatorio)
					.success(function (response) {
						location.reload();
					}).error(function (status) {
						console.log(status);
					});
			}

		}

		vm.removeImagesRelatorio = function (relatorio) {
			var msg = "Deseja realmente deletar as imagens do relatorio " + relatorio.titulo + "?"
			if (confirm(msg)) {
				$http.post("/api/relatorios-cpe/remove-images", relatorio)
					.success(function (response) {
						console.log(response);
					}).error(function (status) {
						console.log(status);
					});
			}
		}


		vm.dowlaodFileRelatorio = function (relatorio) {
			$http.post("/api/relatorios-cpe/download", relatorio, { responseType: 'arraybuffer' })
				.success(function (response) {
					console.log(response);
					var file = new Blob([(response)], { type: 'application/pdf' });
					var fileURL = URL.createObjectURL(file);
					var a = document.createElement("a");
					document.body.appendChild(a);
					a.style = "display: none";
					a.href = fileURL;
					a.download = "Relatorio-" + relatorio.titulo + "-" + relatorio.professor + ".pdf";
					a.click();
					window.URL.revokeObjectURL(fileURL);
				}).error(function (status) {
					console.log(status);
				});
		}

		vm.downloadFileListParticipantes = function (relatorio) {
			$http.post("/api/relatorios-cpe/csv-list-participantes", relatorio, { responseType: 'arraybuffer' })
				.success(function (response) {
					console.log(response);
					var file = new Blob([(response)], { type: 'application/csv' });
					var fileURL = URL.createObjectURL(file);
					var a = document.createElement("a");
					document.body.appendChild(a);
					a.style = "display: none";
					a.href = fileURL;
					a.download = "ListaParticipantes-" + relatorio.titulo + "-" + relatorio.professor + ".csv";
					a.click();
					window.URL.revokeObjectURL(fileURL);
				}).error(function (status) {
					console.log(status);
				});
		}
	}
})();