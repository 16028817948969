(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaContratoController', MatriculaContratoController);

    MatriculaContratoController.$inject = ['$state', 'PreMatriculaDadosCadastraisServiceController', '$http', 'GetContratoByIdps'];

    function MatriculaContratoController($state, PreMatriculaDadosCadastraisServiceController, $http, GetContratoByIdps) {
        var vm = this;
        vm.avancar = avancar;
        vm.ip = null;

        vm.isSaving = false;

        vm.plano = JSON.parse(sessionStorage.getItem('plano-pagamento'));

        vm.ip = JSON.parse(sessionStorage.getItem('ip-user-vestibular'));
        console.log("IP ATUAL:" + vm.ip);
        setTimeout(getIpUser(), 1000);

        function getIpUser() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                }, function(status) {
                    return status;
                });
            }
        };

        vm.getIp = function() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                    avancar();
                }, function(status) {
                    return status;
                });
            } else {
                avancar();
            }
        }

        function avancar() {
            vm.isSaving = true;
            save();
        }

        init();

        function init() {
            // vm.isSaving = false;
            vm.aceitei = false;
            loadDadosCandidato();
        }

        function loadDadosCandidato() {
            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", vm.resultadoRM)
                .success(function(data) {
                    vm.matricula = data;
                    //console.log(vm.matricula);
                    $http.post("/api/get-contrato-pre-matricula", vm.matricula)
                        .success(function(data) {
                            console.log(data);
                            vm.clausulas = data;
                        })
                        .error(function(status) {
                            console.log(status);
                        });

                    /*    GetContratoByIdps.query({idps: vm.matricula.idps},
                        function(result) {
                            vm.clausulas = result;
                            //console.log(vm.clausulas);
                        }, function(status) {
                            console.log(status);
                      })*/

                    if (vm.matricula.nomeCurso.includes('EAD')) {
                        vm.presencial = false;
                        //console.log(vm.presencial);
                    } else {
                        vm.presencial = true;
                    }

                    // vm.isSaving=false;
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function save() {
            vm.isSaving = true;
            vm.matricula.etapa = "CONTRATO";
            vm.matricula.dataContrato = null;
            vm.matricula.ipAluno = vm.ip;
            vm.matricula.presencial = vm.presencial;
            //console.log(vm.matricula);
            PreMatriculaDadosCadastraisServiceController.save(vm.matricula, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.matricula = result;
            //sessionStorage.setItem('dados-matricula-contrato', JSON.stringify(vm.matricula));
            sessionStorage.setItem('cod-pessoa', JSON.stringify(vm.resultadoRM));
            $state.go('pre-matricula-boleto');
        }

        function onSaveError(result) {
            vm.isSaving = false;
            console.log(result);
        }
    }
})();