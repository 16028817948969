(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('IndicacaoController', IndicacaoController);

    IndicacaoController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', '$sessionStorage', 'IndicacaoConsulta', 'param'];

    function IndicacaoController($scope, $rootScope, $state, $timeout, Auth, $uibModalInstance, $sessionStorage, IndicacaoConsulta, param) {
        var vm = this;

        // variables
        vm.load = false;
        vm.selected = false;
        vm.selecionado = {
            codPessoa: null,
            nome: null,
            possuiIndicacao: param.data
        };
        vm.obj = {
            nome: null,
            sobrenome: null,
            indicacao: param.data
        }

        $scope.$watch('vm.selecionado', function(newvalue) {
            $rootScope.$emit('selecionou', newvalue);
        });

        // functions
        vm.confirm = confirm;
        vm.consultar = consultar;
        vm.selecionando = selecionando;
        vm.fechar = fechar;

        function selecionando(people) {
            people.possuiIndicacao = param.data;
            vm.selecionado = people;
        }

        function confirm() {
            $uibModalInstance.close(vm.selecionado);
        }

        function fechar() {
            vm.selecionado = null;
            $uibModalInstance.close(null);
        }

        function consultar() {

            if (vm.obj.nome == null || vm.obj.nome == undefined) {
                alert("Informe o nome da pessoa que indicou");
                return;
            }

            if (vm.obj.sobrenome == null || vm.obj.sobrenome == undefined) {
                alert("Informe o sobrenome da pessoa que indicou");
                return;
            }

            vm.load = true;

            IndicacaoConsulta.query({
                    param1: vm.obj.nome,
                    param2: vm.obj.sobrenome,
                    param3: vm.obj.indicacao
                },
                function(result) {
                    vm.pessoas = result;
                    vm.totalItems = vm.pessoas.lenght;

                    vm.load = false;

                },
                function(result) {
                    vm.load = false;
                    console.log(result);
                });
        }


        // Tabela
        vm.predicate = 'nome';
        vm.reverse = false;
        vm.currentPage = 1;

        vm.order = function(predicate) {
            vm.reverse = (vm.predicate === predicate) ? !vm.reverse : false;
            vm.predicate = predicate;
        };

        vm.numPerPage = 5000;

        vm.paginate = function(value) {
            var begin, end, index;
            begin = (vm.currentPage - 1) * vm.numPerPage;
            end = begin + vm.numPerPage;
            index = vm.pessoas.indexOf(value);
            return (begin <= index && index < end);
        };

        vm.setPage = function(pageNo) {
            vm.currentPage = pageNo;
        };

    }
})();