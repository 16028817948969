(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscritosColegioController', InscritosColegioController);

    InscritosColegioController.$inject = ['$scope', '$http', '$state', 'DateUtils', 'Principal', 'OnibusPlayPainelService'];

    function InscritosColegioController($scope, $http, $state, DateUtils, Principal, OnibusPlayPainelService) {

        var vm = this;

        vm.consultores = [
            { codigo: '04656', nome: 'MAYARA DE FREITAS' },
            { codigo: '055861', nome: 'INSTITUCIONAL' },
            { codigo: '04814', nome: 'RUTE MARIA RODRIGUES COMIM' },
            { codigo: '05035', nome: 'GUSTAVO FELIPE SILVA DE OLIVEIRA' },
            { codigo: '05273', nome: 'Mario Bonato Júnior' },
            { codigo: '05287', nome: 'Tainara Santos' },
            { codigo: '05338', nome: 'Barbara Quintiliano' },
        ];

        vm.consultar = function() {
            // console.log(vm.idps);
            // console.log(vm.consultor);
            $http.get('api/play/lista-inscritos-colegio-vendedor/' + vm.consultor.codigo + '/' + vm.idps)
                .success(function(data) {
                    console.log(data);
                    vm.lista = data;
                    vm.total = vm.lista.length;
                }).error(function(status) {
                    console.log(status);
                });
        }


    }
})();