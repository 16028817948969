(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('VinculaAlunoController', VinculaAlunoController);

    VinculaAlunoController.$inject = ['$http', '$state', '$sessionStorage', 'ProvaSala', 'ConsultaAlunoPlay2018', 'GetProvaPorSalaService', 'GetSalaPorCpfService',
        'SalasPlayService', 'FechaSalaService', 'Curso', 'Inscricao', 'ValidaAlunoVinculoService', 'TrocaCursoTradService'
    ];

    function VinculaAlunoController($http, $state, $sessionStorage, ProvaSala, ConsultaAlunoPlay2018, GetProvaPorSalaService, GetSalaPorCpfService,
        SalasPlayService, FechaSalaService, Curso, Inscricao, ValidaAlunoVinculoService, TrocaCursoTradService) {

        var vm = this;
        vm.codsala = JSON.parse(sessionStorage.getItem('codsala-fiscal'));

        if (vm.codsala == null || vm.codsala == "") {
            vm.etapa = 1;
        } else {
            GetSalaPorCpfService.query({ cpf: vm.codsala },
                function(data) {
                    vm.sala = data;
                    GetProvaPorSalaService.query({ idSala: vm.sala.id },
                        function(data) {
                            for (var i = 0; i < data.length; i++) {
                                var element = data[i];
                                if (element.codigoInscricao == null) {
                                    vm.provas.push(element);
                                } else {
                                    vm.provasUsadas.push(element);
                                }
                            }
                            vm.etapa = 4;
                        },
                        function(status) {
                            console.log(status);
                        });
                },
                function(status) {
                    console.log(status);
                    if (status.status == 404) {
                        showMessage('alert alert-info', 'Sucesso! - o processo de alocação de candidatos para a sala associada foi concluído');
                        vm.etapa = 1;
                    }
                });
        }

        vm.cursos = [
            { id: 2, idPlay: 1, nome: "Administração (noite)" },
            { id: 3, idPlay: 2, nome: "Agronomia (manhã)" },
            { id: 4, idPlay: 3, nome: "Agronomia (noite)" },
            { id: 5, idPlay: 4, nome: "Arquitetura e Urbanismo (manhã)" },
            { id: 6, idPlay: 5, nome: "Arquitetura e Urbanismo (noite)" },
            { id: 50, idPlay: 6, nome: "Biomedicina (manhã)" },
            { id: 33, idPlay: 7, nome: "Biomedicina (noite)" },
            { id: 7, idPlay: 8, nome: "Ciências Contábeis (noite)" },
            { id: 18, idPlay: 9, nome: "Design Gráfico (noite)" },
            { id: 8, idPlay: 10, nome: "Direito (manhã)" },
            { id: 9, idPlay: 11, nome: "Direito (noite)" },
            { id: 34, idPlay: 12, nome: "Educação Física (manhã)" },
            { id: 35, idPlay: 13, nome: "Educação Física (noite)" },
            { id: 84, idPlay: 14, nome: "Enfermagem (manhã)" },
            { id: 47, idPlay: 15, nome: "Enfermagem (noite)" },
            { id: 10, idPlay: 16, nome: "Engenharia Civil (noite)" },
            { id: 12, idPlay: 17, nome: "Engenharia de Produção (noite)" },
            { id: 11, idPlay: 18, nome: "Engenharia Mecânica (noite)" },
            { id: 49, idPlay: 19, nome: "Fisioterapia (manhã)" },
            { id: 36, idPlay: 20, nome: "Fisioterapia (noite)" },
            { id: 20, idPlay: 21, nome: "Gastronomia (manhã)" },
            { id: 21, idPlay: 22, nome: "Gastronomia (noite)" },
            { id: 23, idPlay: 23, nome: "Gestão Comercial (noite)" },
            { id: 37, idPlay: 24, nome: "Medicina Veterinária (manhã)" },
            { id: 38, idPlay: 25, nome: "Medicina Veterinária (noite)" },
            { id: 48, idPlay: 26, nome: "Nutrição (manhã)" },
            { id: 39, idPlay: 27, nome: "Nutrição (noite)" },

            { id: 89, idPlay: 28, nome: "Odontologia (manhã)" },

            { id: 52, idPlay: 29, nome: "Odontologia (noite)" },
            { id: 16, idPlay: 30, nome: "Pedagogia (noite)" },

            { id: 15, idPlay: 31, nome: "Produção Audiovisual (noite)" },

            { id: 54, idPlay: 32, nome: "Psicologia (manhã)" },
            { id: 53, idPlay: 33, nome: "Psicologia (noite)" },
            { id: 14, idPlay: 34, nome: "Publicidade e Propaganda (noite)" },
            { id: 17, idPlay: 35, nome: "Tecnologia em Análise e Desenvolvimento de Sistemas (noite)" },
            { id: 40, idPlay: 36, nome: "Administração (EAD)" },
            { id: 41, idPlay: 37, nome: "Ciências Contábeis (EAD)" },

            { id: 87, idPlay: 38, nome: "Educação Física Bacharelado (EAD)" },
            { id: 88, idPlay: 38, nome: "Educação Física Licenciatura (EAD)" },

            { id: 24, idPlay: 39, nome: "Gestão Comercial (EAD)" },
            { id: 32, idPlay: 40, nome: "Recursos Humanos (EAD)" },
            { id: 26, idPlay: 41, nome: "Gestão Financeira (EAD)" },
            { id: 28, idPlay: 42, nome: "Logística (EAD)" },
            { id: 55, idPlay: 43, nome: "Marketing (EAD)" },
            { id: 44, idPlay: 44, nome: "Pedagogia (EAD)" },
            { id: 30, idPlay: 45, nome: "Processos Gerenciais (EAD)" },

            { id: 86, idPlay: 46, nome: "Tecnologia em Análise e Desenvolvimento de Sistemas (EAD)" },
            //{ id: 89, idPlay: 47, nome: "Odontologia (Manhã)"}
        ];


        vm.alterEtapa = alterEtapa;
        vm.fecharAlert = fecharAlert;
        vm.alterInfo = alterInfo;
        vm.consultarAluno = consultarAluno;
        vm.vincularProva = vincularProva;
        vm.selecionarAluno = selecionarAluno;
        vm.finalizarVinculo = finalizarVinculo;
        vm.alterarProva = alterarProva;
        vm.limparProva = limparProva;
        vm.fecharSala = fecharSala;
        vm.realmenteFecharSala = realmenteFecharSala;
        vm.alteraInfos = false;
        vm.alterarAluno = alterarAluno;
        vm.cancelaAltera = cancelaAltera;
        vm.provas = [];
        vm.provasUsadas = [];

        /*vm.cursos = CursoTradicional.query({
            page: 0,
            size: 50
        });*/


        function alterEtapa(e) {
            if (e == 2) { // Validando o cpf e buscando a sala
                if (vm.codsala == null || vm.codsala == "") {
                    showMessage('alert alert-warning', 'Por favor, verifique o cpf informado.');
                    return;
                }
                GetSalaPorCpfService.query({ cpf: vm.codsala },
                    function(data) {
                        vm.sala = data;
                        sessionStorage.setItem('codsala-fiscal', JSON.stringify(vm.codsala));
                        vm.etapa = e;

                    },
                    function(status) {
                        if (status.status == 404) {
                            showMessage('alert alert-info', 'Sucesso! - o processo de alocação de candidatos para a sala associada foi concluído');
                            vm.etapa = 1;
                        }
                    });
                return;
            }


            if (e == 3) { // a sala estava errada vamos trazer todas para seleção
                SalasPlayService.query(
                    function(data) {
                        vm.salasPlay = data;
                        vm.etapa = e;
                    },
                    function(status) {
                        console.log(status);
                    });
            }

            if (e == 4) { // a sala estava correta então vamos para as provas.
                GetProvaPorSalaService.query({ idSala: vm.sala.id },
                    function(data) {
                        for (var i = 0; i < data.length; i++) {
                            var element = data[i];

                            if (element.codigoInscricao == null) {
                                vm.provas.push(element);
                            } else {
                                vm.provasUsadas.push(element);
                            }
                        }
                        vm.etapa = e;
                    },
                    function(status) {
                        console.log(status);
                    });
            }
            if (e == 5) {
                if (vm.provaSelecionada != null) {
                    vm.etapa = e;
                }
            }
            if (e == 6) {
                if (vm.alunoSelecionado != null) {
                    vm.etapa = e;
                }
            }
            if (e == 7) {

                if (vm.etapa = 5) {
                    vm.alteraInfos = false;
                }

                vm.etapa = 4;
            }
            if (e == 8) {
                vm.etapa = e;
            }
        }

        function vincularProva(prova) {
            vm.provaSelecionada = prova;
            alterEtapa(5);
        }

        function cancelaAltera() {
            vm.alteraInfos = false;
        }

        function alterarAluno() {
            if (vm.alteraInfos == false) {
                vm.alteraInfos = true;
            } else {
                var r = confirm('Deseja relamente alterar as informações do candidato:' + vm.alunoSelecionado.inscricao.nome + ' ?');
                if (r) {
                    vm.alteraInfos = false;
                    Inscricao.update(vm.alunoSelecionado.inscricao, function(data) {
                        console.log(data);
                    }, function(status) {
                        console.log(status);
                    });
                }
            }
        }

        function selecionarAluno(aluno) {
            alterEtapa(6);
        }

        function alterarProva(prova) {
            vm.provaSelecionada = prova;
            alterEtapa(5);
        }

        function fecharSala() {
            alterEtapa(8);
        }

        function realmenteFecharSala() {
            FechaSalaService.query({ idSala: vm.sala.id }, function(data) {
                showMessage('alert alert-info', 'sala ' + vm.sala.bloco + '' + vm.sala.numero + ' foi encerrada com sucesso!');
                vm.etapa = 1;
                sessionStorage.removeItem('cpf-fiscal');
            }, function(status) {
                showMessage('alert alert-warning', 'Ocorreu um erro ao fechar a sala ' + vm.sala.bloco + '' + vm.sala.numero + '!');
                console.log(status);
            });

        }

        function limparProva() {
            vm.alunoSelecionado = null;
            vm.provaSelecionada.codigoInscricao = null;

            ProvaSala.update(vm.provaSelecionada,
                function(data) {
                    for (var i = 0; i < vm.provasUsadas.length; i++) {
                        var element = vm.provasUsadas[i];
                        if (element.codigoProva == data.codigoProva) {
                            vm.provas.push(element);
                            vm.provasUsadas.splice(i, 1);
                        }
                    }
                    vm.provaSelecionada = null;
                    vm.alunoSelecionado = null;

                    alterEtapa(7);
                },
                function(status) {
                    console.log(status);
                })
        }


        function finalizarVinculo() {
            vm.provaSelecionada.codigoInscricao = vm.alunoSelecionado.inscricao.codigo;
            //console.log(vm.alunoSelecionado.inscricao);
            //console.log(vm.provaSelecionada);

            ValidaAlunoVinculoService.query({ codigo: vm.alunoSelecionado.inscricao.codigo },
                function(data) {
                    //console.log(data);
                    if (data.codigoProva == null || data.codigoProva == "") {
                        ProvaSala.update(vm.provaSelecionada,
                            function(data) {
                                for (var i = 0; i < vm.provas.length; i++) {
                                    var element = vm.provas[i];
                                    if (element.codigoProva == data.codigoProva) {
                                        vm.provasUsadas.push(element);
                                        vm.provas.splice(i, 1);
                                    }
                                }
                                vm.provaSelecionada = null;
                                vm.alunoSelecionado = null;

                                alterEtapa(7);
                            },
                            function(status) {
                                console.log(status);
                            });
                    } else {
                        alert('O candidato ' + vm.alunoSelecionado.inscricao.codigo + ' já está vinculado com a folha de respostas: ' + data.codigoProva + ' que pertence a sala ' + data.salaProva + ', acesse a folha correspondente e clique em desvincular');
                        alterEtapa(7);
                    }
                },
                function(status) {
                    console.log(status);

                });
        }

        function consultarAluno() {
            ConsultaAlunoPlay2018.query({
                tipo: 'INSCRICAO',
                valor: vm.valor,
                idps: '54'
            }, function(data) {
                console.log(data);
                vm.alunoSelecionado = data[0];
            }, function(status) {
                console.log(status);
            });
        }

        function alterInfo(cod, i) {
            if (cod == 1) {
                vm.sala = i;
                vm.etapa = 2;
            }

            if (cod == 2) {
                vm.alunoSelecionado.inscricao.idCurso1 = i.id;
                alterarCursoCand(i.id, vm.alunoSelecionado.inscricao.id, 1);
            }
            if (cod == 3) {
                vm.alunoSelecionado.inscricao.idCurso2 = i.id;
                alterarCursoCand(i.id, vm.alunoSelecionado.inscricao.id, 2);
            }
        }

        function alterarCursoCand(idCurso, idInsc, op) {

            TrocaCursoTradService.query({
                op: op,
                curso: idCurso,
                insc: idInsc
            }, function(data) {
                vm.result = data;
            }, function(status) {
                console.log(status);
            });
        }

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }
    }
})();