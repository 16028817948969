(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ProfessoresController', ProfessoresController);

    ProfessoresController.$inject = ['$http'];

    function ProfessoresController($http) {
        var vm = this;
        vm.professor = {};
        vm.cadastrar = cadastrar;
        vm.fecharAlert = fecharAlert;

        function cadastrar() {
            vm.stop = false;

            console.log(vm.professor);

            //VALIDAÇÂO NOME
            if (vm.professor.nome.length < 3) { //@MAURICIO -> VERIFICAR QUANTIDADE MINIMA NECESSARIA
                showMessage('alert alert-danger', "Nome deve conter no mínimo 3 caracteres!");
                vm.stop = true;
            }
            if (vm.professor.nome.length > 100) {
                showMessage('alert alert-danger', "Nome deve conter no máximo 100 caracteres!");
                vm.stop = true;
            }

            //VALIDAÇÃO EMAIL
            if (vm.professor.email.length < 5) {
                showMessage('alert alert-danger', "E-mail deve conter no mínimo 5 caracteres!");
                vm.stop = true;
            }

            //VALIDAÇÃO TELEFONE
            if (vm.professor.telefone != undefined) {
                if (vm.professor.telefone.length < 8) {
                    showMessage('alert alert-danger', "Telefone deve conter no mínimo 8 números!");
                    vm.stop = true;
                }
            } else {
                showMessage('alert alert-danger', "Preencha o campo Telefone corretamente!");
                vm.stop = true;
            }

            //VALIDAÇÃO SENHA
            if (vm.professor.senha !== vm.professor.senhaConfirmacao) {
                showMessage('alert alert-danger', "Senha e Senha Confirmação não coincidem!");
                vm.stop = true;
            } else {
                if (vm.professor.senha.length < 6) {
                    showMessage('alert alert-danger', "Senha deve conter no mínimo 6 caracteres!");
                    vm.stop = true;
                }
                if (vm.professor.senha.length > 10) {
                    showMessage('alert alert-danger', "Senha deve conter no máximo 10 caracteres!");
                    vm.stop = true;
                }
            }

            console.log(vm.stop);

            if (vm.stop == false) { //SOMENTE ENTRA SE NÃO CAIR EM NENHUM IF DE VALIDAÇÃO


                var user = {
                    id: null,
                    login: vm.professor.telefone,
                    email: vm.professor.email,
                    firstName: vm.professor.nome,
                    lastName: vm.professor.senha
                }

                console.log(user);

                $http.post("/api/cadastrar-prof", user)
                    .success(function(result) {
                        console.log(result);
                        showMessage('alert alert-info', 'Sua inscrição foi finalizada, em breve você receberá um email com as instruções de acesso.');
                        vm.professor = {};
                    })
                    .error(function(status) {
                        alert("Algo de Errado aconteceu ao cadastrar!")
                    });
            }

        }

        /* Referente ao sistema de mensagens */
        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }
    }
})();