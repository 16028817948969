(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('UserManagementDialogController', UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'AuthoritiesService'];

    function UserManagementDialogController($stateParams, $uibModalInstance, entity, User, AuthoritiesService) {
        var vm = this;

        //vm.authorities = ['ROLE_ADMIN', 'ROLE_ADM_UNIVEL_PLAY', 'ROLE_EAD', 'ROLE_SECRETARIA', 'ROLE_SUPER_ADMIN', 'ROLE_SUPER_ADM_UNIVEL_PLAY', 'ROLE_SUPER_EAD', 'ROLE_SUPER_SECRETARIA', 'ROLE_SUPER_VESTIBULAR', 'ROLE_UNIVEL_PLAY', 'ROLE_USER', 'ROLE_VESTIBULAR', 'ROLE_UNIVEL_PLAY_CHECK'];
        vm.authorities = AuthoritiesService.query();
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;



        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                vm.user.langKey = 'en';
                console.log(vm.user);
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();