(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('CursoPoloController', CursoPoloController);

    CursoPoloController.$inject = ['CursoPoloDash', 'PolosAtivos', 'Curso', 'CursoPoloSave', 'CursoPoloDelete'];

    function CursoPoloController(CursoPoloDash, PolosAtivos, Curso, CursoPoloSave, CursoPoloDelete) {

        var vm = this;

        vm.cursos = Curso.query();

        init();

        function init() {
            vm.final = new Array();
            PolosAtivos.query(
                function(result) {
                    tratarDados(result);
                },
                function(status) {
                    console.log(status);
                }
            );
        }


        function tratarDados(lista) {
            for (var p = 0; p < lista.length; p++) {
                var polo = lista[p];
                var listaVinc = consultaCursosPolo(polo.id);
                vm.final.push({
                    idPolo: polo.id,
                    cidade: polo.cidade,
                    email1: polo.email1,
                    telefone1: polo.telefone1,
                    lista: listaVinc
                });
            }
        }


        function consultaCursosPolo(id) {
            var retorno = CursoPoloDash.query({ idPolo: id });
            return retorno;
        }

        vm.removeCursoPolo = function(item, polo) {
            CursoPoloDelete.delete({
                id: item.id
            });
            init();
        }

        vm.inserirVinculo = function() {
            CursoPoloSave.save({
                idPolo: vm.poloEscolhido.idPolo,
                idCurso: vm.cursoEscolhido.id
            });
            init();
        }
    }
})();