(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('polo', {
            parent: 'entity',
            url: '/polo',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Polos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/polo/polos.html',
                    controller: 'PoloController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('polo-detail', {
            parent: 'polo',
            url: '/polo/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Polo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/polo/polo-detail.html',
                    controller: 'PoloDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Polo', function($stateParams, Polo) {
                    return Polo.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'polo',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('polo-detail.edit', {
            parent: 'polo-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/polo/polo-dialog.html',
                    controller: 'PoloDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Polo', function(Polo) {
                            return Polo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('polo.new', {
            parent: 'polo',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/polo/polo-dialog.html',
                    controller: 'PoloDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                rua: null,
                                numero: null,
                                bairro: null,
                                cidade: null,
                                estado: null,
                                telefone1: null,
                                telefone2: null,
                                email1: null,
                                email2: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('polo', null, { reload: 'polo' });
                }, function() {
                    $state.go('polo');
                });
            }]
        })
        .state('polo.edit', {
            parent: 'polo',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/polo/polo-dialog.html',
                    controller: 'PoloDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Polo', function(Polo) {
                            return Polo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('polo', null, { reload: 'polo' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('polo.delete', {
            parent: 'polo',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/polo/polo-delete-dialog.html',
                    controller: 'PoloDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Polo', function(Polo) {
                            return Polo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('polo', null, { reload: 'polo' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
