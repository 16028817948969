(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaObsPlanoService', BuscaObsPlanoService);

    BuscaObsPlanoService.$inject = ['$resource', 'DateUtils'];

    function BuscaObsPlanoService($resource, DateUtils) {
        var resourceUrl = '/api/busca-obs-plano/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();