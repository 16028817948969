(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ResultadoVestibularController', ResultadoVestibularController);

    ResultadoVestibularController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'LoginService', '$http', '$sessionStorage', 'Principal', 'Auth'];

    function ResultadoVestibularController($timeout, $scope, $state, $stateParams, LoginService, $http, $sessionStorage, Principal, Auth) {
        var vm = this;

        $state.go('painelAdministrativo');

        // variables
        vm.resultado = "CPF";
        vm.processo_seletivo = "TRADICIONAL";
        vm.etapa = 1;
        vm.cons = {
            'email': null,
            'celular': null,
            'cpf': null,
            'codigo': null,
            'processo': null
        }

        // functions
        vm.validate = validate;
        vm.alteraEtapa = alteraEtapa;
        vm.login = login;

        function login() {
            LoginService.open();
            $state.go('painelAdministrativo');
        }


        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.cons.email = vm.account.login;
            });
        }

        function alteraEtapa(etapa) {
            vm.etapa = etapa;
        }

        function validate() {

            vm.obj = {};

            if (vm.resultado == 'EMAIL') {
                vm.obj = {
                    'email': vm.cons.email,
                    'celular': null,
                    'cpf': null,
                    'codigo': null,
                    'processo': vm.processo_seletivo
                }
            }

            if (vm.resultado == 'CELULAR') {
                vm.obj = {
                    'email': null,
                    'celular': vm.cons.celular,
                    'cpf': null,
                    'codigo': null,
                    'processo': vm.processo_seletivo
                }
            }

            if (vm.resultado == 'CPF') {
                vm.obj = {
                    'email': null,
                    'celular': null,
                    'cpf': vm.cons.cpf,
                    'codigo': null,
                    'processo': vm.processo_seletivo
                }
            }

            if (vm.resultado == 'CODIGO') {
                vm.obj = {
                    'email': null,
                    'celular': null,
                    'cpf': null,
                    'codigo': vm.cons.codigo,
                    'processo': vm.processo_seletivo
                }
            }

            console.log(vm.obj);

            $http.post("/api/get-resultado-by-info", vm.obj)
                .success(function(data) {
                    data.email = vm.cons.email;

                    // Limpando sessões que possam ter ficados abertas.. e deslogando!
                    sessionStorage.removeItem('cod-pessoa');
                    sessionStorage.removeItem('dados-resultado');
                    Auth.logout();

                    sessionStorage.setItem('dados-resultado', JSON.stringify(data));

                    $state.go('resultado-vestibular-end');
                })
                .error(function(status) {
                    console.log(status);
                });
        }
    }
})();