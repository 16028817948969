(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('link-vendedor', {
            parent: 'entity',
            url: '/link-vendedor',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'LinkVendedors'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/link-vendedor/link-vendedors.html',
                    controller: 'LinkVendedorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('link-vendedor-detail', {
            parent: 'link-vendedor',
            url: '/link-vendedor/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'LinkVendedor'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/link-vendedor/link-vendedor-detail.html',
                    controller: 'LinkVendedorDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'LinkVendedor', function($stateParams, LinkVendedor) {
                    return LinkVendedor.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'link-vendedor',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('link-vendedor-detail.edit', {
            parent: 'link-vendedor-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/link-vendedor/link-vendedor-dialog.html',
                    controller: 'LinkVendedorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['LinkVendedor', function(LinkVendedor) {
                            return LinkVendedor.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('link-vendedor.new', {
            parent: 'link-vendedor',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/link-vendedor/link-vendedor-dialog.html',
                    controller: 'LinkVendedorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                tipo: null,
                                codigo: null,
                                nome: null,
                                link: null,
                                numero: null,
                                email: null,
                                createdDate: null,
                                createdUser: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('link-vendedor', null, { reload: 'link-vendedor' });
                }, function() {
                    $state.go('link-vendedor');
                });
            }]
        })
        .state('link-vendedor.edit', {
            parent: 'link-vendedor',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/link-vendedor/link-vendedor-dialog.html',
                    controller: 'LinkVendedorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['LinkVendedor', function(LinkVendedor) {
                            return LinkVendedor.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('link-vendedor', null, { reload: 'link-vendedor' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('link-vendedor.delete', {
            parent: 'link-vendedor',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/link-vendedor/link-vendedor-delete-dialog.html',
                    controller: 'LinkVendedorDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['LinkVendedor', function(LinkVendedor) {
                            return LinkVendedor.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('link-vendedor', null, { reload: 'link-vendedor' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
