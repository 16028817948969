(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RelatorioCapacitacaoDetailController', RelatorioCapacitacaoDetailController);

    RelatorioCapacitacaoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RelatorioCapacitacao'];

    function RelatorioCapacitacaoDetailController($scope, $rootScope, $stateParams, previousState, entity, RelatorioCapacitacao) {
        var vm = this;

        vm.relatorioCapacitacao = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:relatorioCapacitacaoUpdate', function(event, result) {
            vm.relatorioCapacitacao = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
