(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('inscricao-tradicional', {
            parent: 'entity',
            url: '/inscricao-tradicional?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'InscricaoTradicionals'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/inscricao-tradicional/inscricao-tradicionals.html',
                    controller: 'InscricaoTradicionalController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('inscricao-tradicional-detail', {
            parent: 'entity',
            url: '/inscricao-tradicional/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'InscricaoTradicional'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/inscricao-tradicional/inscricao-tradicional-detail.html',
                    controller: 'InscricaoTradicionalDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'InscricaoTradicional', function($stateParams, InscricaoTradicional) {
                    return InscricaoTradicional.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'inscricao-tradicional',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('inscricao-tradicional-detail.edit', {
            parent: 'inscricao-tradicional-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/inscricao-tradicional/inscricao-tradicional-dialog.html',
                    controller: 'InscricaoTradicionalDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InscricaoTradicional', function(InscricaoTradicional) {
                            return InscricaoTradicional.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('inscricao-tradicional.new', {
            parent: 'inscricao-tradicional',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/inscricao-tradicional/inscricao-tradicional-dialog.html',
                    controller: 'InscricaoTradicionalDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                cpf: null,
                                telefone1: null,
                                telefone2: null,
                                curso1: null,
                                curso2: null,
                                necessidades_especiais: null,
                                necessidades_especiais_texto: null,
                                recebeu_indicacao: null,
                                nome_indicacao: null,
                                treineiro: null,
                                ano_conclusao_ensino_medio: null,
                                univel_play: null,
                                data_criacao: null,
                                data_modificacao: null,
                                user_id: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('inscricao-tradicional', null, { reload: 'inscricao-tradicional' });
                }, function() {
                    $state.go('inscricao-tradicional');
                });
            }]
        })
        .state('inscricao-tradicional.edit', {
            parent: 'inscricao-tradicional',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/inscricao-tradicional/inscricao-tradicional-dialog.html',
                    controller: 'InscricaoTradicionalDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InscricaoTradicional', function(InscricaoTradicional) {
                            return InscricaoTradicional.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('inscricao-tradicional', null, { reload: 'inscricao-tradicional' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('inscricao-tradicional.delete', {
            parent: 'inscricao-tradicional',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/inscricao-tradicional/inscricao-tradicional-delete-dialog.html',
                    controller: 'InscricaoTradicionalDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InscricaoTradicional', function(InscricaoTradicional) {
                            return InscricaoTradicional.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('inscricao-tradicional', null, { reload: 'inscricao-tradicional' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
