(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoPulseiraDetailController', InscricaoPulseiraDetailController);

    InscricaoPulseiraDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InscricaoPulseira'];

    function InscricaoPulseiraDetailController($scope, $rootScope, $stateParams, previousState, entity, InscricaoPulseira) {
        var vm = this;

        vm.inscricaoPulseira = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:inscricaoPulseiraUpdate', function(event, result) {
            vm.inscricaoPulseira = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
