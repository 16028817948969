(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('inscricao-tradicional-mobile', {
                parent: 'app',
                url: '/inscricao-tradicional-mobile',
                data: {
                    pageTitle: 'Inscrição Vestibular Tradicional'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/inscricaoTradicional/new_inscricao_mobile/inscricaoTradicionalM.html',
                        controller: 'InscricaoTradicionalMController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                }
            });
    }

})();
