(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatrizCurricularDisciplinaDetailController', MatrizCurricularDisciplinaDetailController);

    MatrizCurricularDisciplinaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MatrizCurricularDisciplina'];

    function MatrizCurricularDisciplinaDetailController($scope, $rootScope, $stateParams, previousState, entity, MatrizCurricularDisciplina) {
        var vm = this;

        vm.matrizCurricularDisciplina = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:matrizCurricularDisciplinaUpdate', function(event, result) {
            vm.matrizCurricularDisciplina = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
