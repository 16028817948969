(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatrizCurricularDeleteController',MatrizCurricularDeleteController);

    MatrizCurricularDeleteController.$inject = ['$uibModalInstance', 'entity', 'MatrizCurricular'];

    function MatrizCurricularDeleteController($uibModalInstance, entity, MatrizCurricular) {
        var vm = this;

        vm.matrizCurricular = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MatrizCurricular.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
