(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlanoPagamentoMatriculaController', PlanoPagamentoMatriculaController);

    PlanoPagamentoMatriculaController.$inject = ['$state', 'PreMatriculaDadosCadastraisServiceController', '$http', 'BuscaOpcoesPlanoPagamento'];

    function PlanoPagamentoMatriculaController($state, PreMatriculaDadosCadastraisServiceController, $http, BuscaOpcoesPlanoPagamento) {
        var vm = this;
        vm.save = save;
        vm.promoTemp = false;

        init();

        function init() {
            vm.isSaving = true;
            vm.aceitei = false;
            loadDadosCandidato();
        }

        function loadDadosCandidato() {
            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", vm.resultadoRM)
                .success(function(data) {
                    vm.matricula = data;
                    var today = new Date();
                    var iPalo = new Date('2021', '03', '07', '01', '00', '0000');
                    var fPalo = new Date('2021', '03', '09', '23', '59', '0000');
                    var iAss = new Date('2021', '03', '14', '01', '00', '0000');
                    var fAss = new Date('2021', '03', '16', '23', '59', '0000');

                    if (vm.matricula.polo == 13 && today >= iAss && today <= fAss) {
                        vm.promoTemp = true;
                    }

                    if (vm.matricula.polo == 3 && today >= iPalo && today <= fPalo) {
                        vm.promoTemp = true;
                    }

                    BuscaOpcoesPlanoPagamento.send(vm.matricula,
                        function(data) {
                            vm.planos = data;
                            vm.isSaving = false;
                        },
                        function(status) {
                            console.log(status);
                            vm.isSaving = false;
                        });
                })
                .error(function(status) {
                    console.log(status);
                    vm.isSaving = false;
                });
        }

        function save(item) {
            vm.isSaving = true;
            vm.matricula.etapa = "PLANOPAGAMENTO";
            vm.matricula.codPlanoPgto = item.codPlanoPagamento;
            sessionStorage.setItem('plano-pagamento', JSON.stringify(item));
            PreMatriculaDadosCadastraisServiceController.save(vm.matricula, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.matricula = result;
            sessionStorage.setItem('cod-pessoa', JSON.stringify(vm.resultadoRM));
            $state.go('pre-matricula-contrato');
        }

        function onSaveError(result) {
            vm.isSaving = false;
            console.log(result);
        }
    }
})();