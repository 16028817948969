(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PainelPlanoEnsinoController', PainelPlanoEnsinoController);

    PainelPlanoEnsinoController.$inject = ['$state', 'LoginService', '$http', 'Principal', 'Auth', 'BuscaPlanosLoginService', 'BuscaDiscPlano',
        'PlanoEnsino', 'PlanoEnsinoItens', 'BuscaLivrosPlanoService', 'UpdateUserPlanoService', 'PlanoEnsinoBibliografia', 'BuscaPlanosCursoService', 'BuscaPlanosNucleoService',
        'BuscaHistoricoPlano', 'ExcluiPlanoEnsino', 'BuscaContexto'
    ];

    function PainelPlanoEnsinoController($state, LoginService, $http, Principal, Auth, BuscaPlanosLoginService, BuscaDiscPlano,
        PlanoEnsino, PlanoEnsinoItens, BuscaLivrosPlanoService, UpdateUserPlanoService, PlanoEnsinoBibliografia, BuscaPlanosCursoService, BuscaPlanosNucleoService,
        BuscaHistoricoPlano, ExcluiPlanoEnsino, BuscaContexto
    ) {
        var vm = this;

        sessionStorage.removeItem('plano-ensino');
        sessionStorage.removeItem('plano-ensino-disciplinas');

        vm.verPlanoOnline = verPlanoOnline;
        vm.atualizaUser = atualizaUser;
        vm.avaliaPlano = avaliaPlano;
        vm.editPlano = editPlano;
        vm.cadastraPlano = cadastraPlano;
        vm.excluirPlano = excluirPlano;
        vm.refresh = refresh;
        vm.abreModal = abreModal;
        vm.geraPdf = geraPdf;
        vm.geraListaConferencia = geraListaConferencia;

        vm.planos = null;
        vm.disciplinas = null;
        vm.disciplinaSelecionada = null;
        vm.livroSelecionado = null;
        vm.plano = {};
        vm.rowspan = 0;
        vm.livros = null;
        vm.user = {};
        vm.sec = false;
        vm.professor = false;
        vm.cordenador = false;
        vm.nucleo = false;
        vm.consulta = {};

        vm.biblioApro = [];
        vm.biblioBasica = [];
        vm.biblioCompl = [];


        var date = new Date();
        var mon = date.getMonth() + 1;

        if (mon >= 6) {
            vm.codPerlet = date.getFullYear() + "/2";
        } else {
            vm.codPerlet = date.getFullYear() + "/1";
        }

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

                console.log(vm.account);

                getContexto();

                if (vm.account.email.includes("temp")) {
                    vm.etapa = 1;
                } else {
                    vm.consulta.etapa = "TODOS";
                    vm.consulta.disciplina = "TODOS";
                    vm.consulta.curso = "TODOS";
                    vm.consulta.codPerlet = vm.codPerlet;
                    // vamos verificar a permissao
                    if (vm.account.authorities.includes("ROLE_SECRETARIA")) {
                        vm.sec = true;
                        if (vm.account.authorities.includes("ROLE_PROFESSOR")) {
                            vm.sec = false;
                            vm.professor = true;
                            if (vm.account.authorities.includes("ROLE_COORDENADOR")) {
                                if (vm.account.authorities.includes("ROLE_NUCLEO")) {
                                    vm.nucleo = true;
                                } else {
                                    vm.cordenador = true;
                                }
                            }
                        }
                    }
                    vm.consultaPlanos();
                    vm.etapa = 2;
                }
            });
        };

        vm.consultaPlanos = function() {

            if (vm.consulta.curso == "") {
                vm.consulta.curso = "TODOS";
            }
            if (vm.consulta.disciplina == "") {
                vm.consulta.disciplina = "TODOS";
            }
            if (vm.consulta.codPerlet == "") {
                vm.consulta.codPerlet = "2024/2";
            }

            vm.consulta.user = vm.account;
            //vm.consulta.user.login = 'thiago.guerra';

            getPlanosUser();

            if (vm.nucleo == true) {
                vm.consulta.tipoUser = "NUCLEO";
                getPlanosNucleo();
            }
            if (vm.cordenador == true) {
                vm.consulta.tipoUser = "COORDENADOR";
                getPlanosCoord();
            }
            if (vm.sec == true) {
                vm.consulta.tipoUser = "SECRETARIA";
                getPlanosNucleo();
            }
        }

        function geraListaConferencia() {
            vm.consulta.user = vm.account;
            console.log(vm.consulta);

            $http.post('api/get-lista-conferencia-cordenador', vm.consulta)
                .success(function(data) {
                    vm.controle = data;
                }).error(function(status) {
                    console.log(status);
                });
        }

        function geraPdf(plano) {
            $http.post('api/plano-ensino-get-pdf', plano, { responseType: 'arraybuffer' })
                .success(function(data) {

                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    a.href = fileURL;
                    a.download = plano.disciplina + " - " + plano.professor;
                    a.click();

                    window.URL.revokeObjectURL(fileURL);
                    vm.load = false;
                }).error(function(status) {
                    console.log(status);
                });
        }

        function refresh() {
            if (vm.account.authorities.includes("ROLE_COORDENADOR")) {
                if (vm.account.authorities.includes("ROLE_NUCLEO")) {
                    vm.nucleo = true;
                    getPlanosNucleo();
                } else {
                    vm.cordenador = true;
                    getPlanosCoord();
                }
            }
            getPlanosUser();
        }

        function getContexto() {
            BuscaContexto.query(function(result) {
                //console.log(result);
                vm.periodos = result;
                //console.log(vm.periodos);

                vm.contexto = {};
                vm.contexto.cadastro = [];
                vm.contexto.aval = [];

                var hoje = new Date();

                for (var i = 0; i < vm.periodos.length; i++) {
                    var p = vm.periodos[i];

                    if (dateCheck(p.inicioCadastro, p.finalCadastro, hoje)) {
                        vm.contexto.cadastro.push(p.periodoLetivo);
                    }

                    if (dateCheck(p.inicioAvaliacao, p.finalAvaliacao, hoje)) {
                        vm.contexto.aval.push(p.periodoLetivo);
                    }
                }

                //console.log(vm.contexto);
            }, function(status) {
                alert('Não foi possível carregar o contexto.');
            });
        }

        function dateCheck(from, to, check) {
            var fDate, lDate, cDate;
            fDate = Date.parse(from);
            lDate = Date.parse(to);
            cDate = Date.parse(check);
            if ((cDate <= lDate && cDate >= fDate)) {
                return true;
            }
            return false;
        }

        function atualizaUser() {
            vm.account.email = vm.user.email;
            vm.account.lastName = vm.account.firstName;
            vm.account.firstName = vm.user.firstName;
            UpdateUserPlanoService.query(vm.account, function(data) {
                vm.account = data;
                vm.plano.professor = vm.account.firstName;
                vm.etapa = 2;
            }, function(status) {
                alert("ocorreu um erro ao atualizar o usuário.");
                console.log(status);
            });
        }

        function getPlanosUser() {
            BuscaPlanosLoginService.query(vm.consulta, function(result) {
                vm.planos = result;
                //console.log(vm.planos);
            }, function(status) {
                console.log(status);
            });
        };

        function getPlanosCoord() {
            BuscaPlanosCursoService.query(vm.consulta, function(result) {
                vm.planosCurso = result;
                //console.log(vm.planosCurso);
            }, function(status) {
                console.log(status);
            });
        };

        function getPlanosNucleo() {
            BuscaPlanosNucleoService.query(vm.consulta, function(result) {
                vm.planosNucleo = result;
                //console.log(vm.planosNucleo);
            }, function(status) {
                console.log(status);
            });
        };

        function avaliaPlano(plano) {
            sessionStorage.setItem('plano-ensino-correcao', JSON.stringify(plano));

            console.log(plano.tipoPlano);

            if (plano.tipoPlano == "DISCIPLINA") {
                $state.go('avaliaPlanoEnsino');
            }

            if (plano.tipoPlano == "ESTAGIO") {
                $state.go('avaliaPlanoEstagio');
            }

            if (plano.tipoPlano == "EAD") {
                $state.go('avaliaPlanoEad');
            }
        }

        function verPlanoOnline(plano) {
            sessionStorage.setItem('plano-ensino-view', JSON.stringify(plano));
            $state.go('viewPlanoProfessor');
        }

        function editPlano(plano) {
            sessionStorage.setItem('plano-ensino-correcao', JSON.stringify(plano));

            console.log(plano.tipoPlano);

            if (plano.tipoPlano == "DISCIPLINA") {
                $state.go('editPlanoEnsino');
            }

            if (plano.tipoPlano == "ESTAGIO") {
                $state.go('editPlanoEstagio');
            }

            if (plano.tipoPlano == "EAD") {
                $state.go('editPlanoEad');
            }
        }

        function excluirPlano(plano) {
            ExcluiPlanoEnsino.query(plano, function() {
                if (vm.nucleo == true) {
                    getPlanosNucleo();
                } else {
                    getPlanosUser();
                }
            });
        }

        function cadastraPlano(plano) {
            sessionStorage.setItem('plano-ensino', JSON.stringify(plano));
            $state.go('novoPlanoEnsino');
        }

        function abreModal(p) {

            console.log(p);

            BuscaHistoricoPlano.query({ id: p.id },
                function(data) {
                    vm.historico = data;
                    console.log(vm.historico);

                    vm.planoView = p;

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.setAttribute('data-target', '#modalAgendado');
                    a.setAttribute('data-toggle', 'modal');
                    a.click();

                    vm.modal = true;
                });
        }

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }


        /*   referente aos estados   */
        vm.querySearchDisciplina = querySearchDisciplina;
        vm.selectedItemChangeDisciplina = selectedItemChangeDisciplina;
        vm.searchTextChangeDisciplina = searchTextChangeDisciplina;

        function querySearchDisciplina(query) {

            var results = query ? vm.disciplinas.filter(createFilterForDisciplina(query)) : vm.disciplinas,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangeDisciplina(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangeDisciplina(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForDisciplina(query) {
            var lowercaseQuery = angular.uppercase(query);

            return function filterFn(disc) {
                var a = disc.disciplina + " - " + disc.codTurma + " - " + disc.curso;
                return a.includes(lowercaseQuery);
            };

        }
    }
})();