(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RelatorioExtController', RelatorioExtController);

    RelatorioExtController.$inject = ['$scope', 'Principal', '$state', '$http', 'RelatorioExtensao', 'RelatorioExtensaoFile', 'DataUtils', '$sessionStorage', 'ValidaInputRegexService'];

    function RelatorioExtController($scope, Principal, $state, $http, RelatorioExtensao, RelatorioExtensaoFile, DataUtils, $sessionStorage, ValidaInputRegexService) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;

        vm.itemsPerPage = 50;

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        vm.arrayEvidencias = [];

        vm.idEvidencia = 0;

        vm.consulta = 'TODOS';

        vm.agora = new Date();

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                console.log(account);
                vm.etapa = 1;
                //consultaRelatorios();
                getSession();
            });
        }

        vm.consultaRelatorios = function() {

            var relatorio = {
                titulo: vm.consulta,
                tipo: 'EXTENSAO',
                createdUser: vm.account.login
            }
            $http.post('api/get-relatorio-extensao-consulta', relatorio)
                .success(function(data) {
                    vm.relatorioExtensaos = data;
                }).error(function(status) {
                    console.log(status);
                });
        }

        function loadAll() {
            RelatorioExtensao.query({ size: vm.itemsPerPage }, onSuccess, onError);

            function onSuccess(data, headers) {
                console.log(data);
                vm.relatorioExtensaos = data;
            }

            function onError(error) {
                console.log(error.data.message);
            }
        }

        vm.removeEvidencia = function(id) {
            for (var i = 0; i < vm.arrayEvidencias.length; i++) {
                var e = vm.arrayEvidencias[i];
                if (e.id == id) {
                    vm.arrayEvidencias.splice(i, 1);
                }
            }
        }

        vm.setEvidencia = function($file, relatorioExtensaoFile) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {

                        if (vm.arrayEvidencias.length == 10) {
                            alert("Não é possível inserir mais de 10 imagens");
                        } else {
                            vm.idEvidencia++;
                            var ev = {
                                id: vm.idEvidencia,
                                file: base64Data,
                                fileType: $file.type
                            }
                            vm.arrayEvidencias.push(ev);
                            console.log(vm.arrayEvidencias.length);
                        }

                    });
                });
            }
            // console.log(vm.arrayEvidencias);
        };

        function removaCharErro(str) {
            var charCode = "";
            if (str == null || str == undefined || str.length <= 0) {
                return "";
            } else {
                for (var i = 0; i < str.length; ++i) {
                    charCode = str.charCodeAt(i);
                    if (charCode == "8211") {
                        str = str.substring(0, i) + str.substring(i + 1);
                    }
                }
                return str;
            }
        }

        function validaCampos() {
            // validar os campos aqui 

            var valida = null;

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.curso, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Curso: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.disciplina, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Disciplina: " + valida.msg; }

            if (vm.relatorioExtensao.modalidade == null || vm.relatorioExtensao.modalidade == undefined || vm.relatorioExtensao.modalidade == "") { return "O campo MODALIDADE está incorreto."; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.semestre, "onlyNumber");
            if (valida.status == "error") { return "Semestre: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.ano, "onlyNumber");
            if (valida.status == "error") { return "Ano: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.chExt, "onlyNumber");
            if (valida.status == "error") { return "Carga Horária Extensão: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.chDisc, "onlyNumber");
            if (valida.status == "error") { return "Carga Horária total da disciplina: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.turma, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Turma: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.titulo, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Titulo: " + valida.msg; }


            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.participantes, "onlyNumber");
            if (valida.status == "error") { return "Participantes: " + valida.msg; }

            if (vm.relatorioExtensao.dataInicio == null || vm.relatorioExtensao.dataInicio == undefined || vm.relatorioExtensao.dataInicio == "") { return "O campo DATA INICIO está incorreto."; }
            if (vm.relatorioExtensao.dataFinal == null || vm.relatorioExtensao.dataFinal == undefined || vm.relatorioExtensao.dataFinal == "") { return "O campo DATA FINAL está incorreto."; }

            if (vm.relatorioExtensao.nomeProfessor != null) {
                vm.relatorioExtensao.nomeProfessor = removaCharErro(vm.relatorioExtensao.nomeProfessor);
            }
            if (vm.relatorioExtensao.linkProfessor != null) {
                vm.relatorioExtensao.linkProfessor = removaCharErro(vm.relatorioExtensao.linkProfessor);
            }
            vm.relatorioExtensao.ementa = removaCharErro(vm.relatorioExtensao.ementa);
            vm.relatorioExtensao.resumo = removaCharErro(vm.relatorioExtensao.resumo);
            vm.relatorioExtensao.objetivo = removaCharErro(vm.relatorioExtensao.objetivo);
            vm.relatorioExtensao.objEsp = removaCharErro(vm.relatorioExtensao.objEsp);
            vm.relatorioExtensao.resultados = removaCharErro(vm.relatorioExtensao.resultados);
            vm.relatorioExtensao.bibiografia = removaCharErro(vm.relatorioExtensao.bibiografia);


            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.nomeProfessor, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Nome Professor: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.linkProfessor, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Link Professor: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.ementa, "alphanumericWithSpecial");
            if (valida.status == "error") { return "ementa: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.resumo, "alphanumericWithSpecial");
            if (valida.status == "error") { return "resumo: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.objetivo, "alphanumericWithSpecial");
            if (valida.status == "error") { return "objetivo: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.objEsp, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Objetivos Específicos: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.resultados, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Resultados: " + valida.msg; }

            valida = ValidaInputRegexService.validate(vm.relatorioExtensao.bibiografia, "alphanumericWithSpecial");
            if (valida.status == "error") { return "Bibliografia: " + valida.msg; }

            if (vm.arrayEvidencias == null || vm.arrayEvidencias == "" || vm.arrayEvidencias.length == 0 || vm.arrayEvidencias.length < 8 || vm.arrayEvidencias.length > 10) {
                return "por favor informe de 8 a 10 imagens de evidências, quantidade atual: " + vm.arrayEvidencias.length;
            }

            for (var i = 0; i < vm.arrayEvidencias.length; i++) {
                var foto = vm.arrayEvidencias[i];
                if (foto.file.length > 5000000) {
                    return "A foto de ID: " + foto.id + " está muito grande.";
                }
            }
            return "OK";
        }

        vm.salvar = function() {
            vm.isSaving = true;

            vm.relatorioExtensao.createdUser = vm.account.login;
            vm.relatorioExtensao.createdDate = new Date();

            var valida = validaCampos();
            //console.log(vm.relatorioExtensao);

            if (valida == "OK") {
                RelatorioExtensao.save(vm.relatorioExtensao, onSaveSuccess, onSaveError);
            } else {
                vm.isSaving = false;
                alert(valida);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('vestibularApp:relatorioExtensaoUpdate', result);

            for (var i = 0; i < vm.arrayEvidencias.length; i++) {

                var e = vm.arrayEvidencias[i];

                var img = {
                    idRelatorio: result.id,
                    evidencia: e.file,
                    evidenciaContentType: e.fileType,
                    createdDate: result.createdDate,
                    createdUser: result.createdUser,
                    tamanho: vm.byteSize(e.file),
                    tipoRelatorio: 'EXTENSAO'
                }

                RelatorioExtensaoFile.save(img, function(imgSalva) {
                    console.log("imagem " + e.id + " salva com sucesso!");
                }, function(status) {
                    alert("Erro ao salvar a imagem: " + e.id + " -> " + status);
                });
            }
            vm.isSaving = false;
            sessionStorage.removeItem('relatorio-extensao');
            vm.relatorioExtensao = {};
            vm.arrayEvidencias = [];
            vm.etapa = 1;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.download = function(relatorio) {
            console.log(relatorio);
            $http.post('api/get-arquivo-relatorio-extensao', relatorio, { responseType: 'arraybuffer' })
                .success(function(data) {
                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = "Relatorio-Extensao-" + relatorio.disciplina + "-" + relatorio.nomeProfessor;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }).error(function(status) {
                    console.log(status);
                });

        }

        vm.deleteImg = function(relatorio) {
            var r = confirm("Deseja realmente apagar as imagens do relatorio de capacitação número:" + relatorio.id + "?, esta ação não poderá ser desfeita.");
            if (r) {
                $http.post('api/delete-img-relatorio-extensao', relatorio)
                    .success(function(data) {
                        alert("Imagens deletadas");
                    }).error(function(status) {
                        alert("Erro ao deletar as Imagens");
                    });
            } else {
                alert("Operação cancelada.");
            }
        }

        vm.deleteRelatorio = function(relatorio) {
            var r = confirm("Deseja realmente deletar o relatorio:" + relatorio.id + " e todos seus conteúdos?, esta ação não poderá ser desfeita.");
            if (r) {
                $http.post('api/delete-relatorio-extensao', relatorio)
                    .success(function(data) {
                        alert("Reletório deletado");
                        vm.consultaRelatorios();
                    }).error(function(status) {
                        alert("Erro ao deletar o relatorio");
                    });
            } else {
                alert("Operação cancelada.");
            }
        }


        function getSession() {
            var rel = JSON.parse(sessionStorage.getItem('relatorio-extensao'));
            if (rel != null && rel != undefined) {
                rel.dataInicio = compilaData(rel.dataInicio);
                rel.dataFinal = compilaData(rel.dataFinal);
                vm.relatorioExtensao = rel;
            }
        }

        vm.salvaSessao = function() {
            vm.relatorioExtensao.dataInicio = formatDate(vm.relatorioExtensao.dataInicio);
            vm.relatorioExtensao.dataFinal = formatDate(vm.relatorioExtensao.dataFinal);
            sessionStorage.setItem('relatorio-extensao', JSON.stringify(vm.relatorioExtensao));
        }

        function formatDate(date) {
            var ret = null;
            if (date.length > 11) {
                ret = date.toISOString().slice(0, 10);
            } else {
                ret = date;
            }
            return ret;
        }

        function compilaData(data) {
            //console.log(data);
            var ano = data.substring(0, 4);
            var mes = data.substring(5, 7);
            var dia = data.substring(8, 10);
            return new Date(ano, mes - 1, dia);
        }
    }
})();