(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PreMatriculaDadosCadastraisController', PreMatriculaDadosCadastraisController);

    PreMatriculaDadosCadastraisController.$inject = ['$rootScope', '$timeout', '$scope', '$state', '$stateParams',
        'PreMatriculaDadosCadastraisServiceController', 'PolosAtivos', '$http', '$sessionStorage', 'Principal', '$resource', 'ModalImagemService', 'CidadesMDJsonService', 'ConfirmaNomeService', 'CursoModalidade'
    ];

    function PreMatriculaDadosCadastraisController($rootScope, $timeout, $scope, $state, $stateParams, PreMatriculaDadosCadastraisServiceController, PolosAtivos, $http, $sessionStorage, Principal, $resource, ModalImagemService, CidadesMDJsonService, ConfirmaNomeService, CursoModalidade) {
        var vm = this;

        // functions
        vm.clear = clear;
        vm.salvar = save;
        vm.getCEP = buscaCEP;
        vm.change = change;
        vm.calculaIdade = calculaIdade;
        vm.carregaCursos = carregaCursos;
        //vm.alterInfo = alterInfo;

        vm.isSaving = false;
        //vm.isSaving = true;


        // variables
        vm.matricula = {};
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.account = null;
        vm.cursos = null;
        vm.upload = upload;
        vm.imagemAlterada = false;
        vm.cepsearch = false;
        vm.nomeTemp = "";
        vm.cursosAcademico = [];
        vm.cursoSelecionado = null;
        vm.cursoEad = false;

        vm.polos = PolosAtivos.query({
            page: 0,
            size: 50
        });

        // vm.validaPolo = function(curso) {

        //     if (curso.nome.includes('híbrido')) {
        //         vm.cursoEad = false;
        //         vm.matricula.polo = 1;
        //         showMessage('alert alert-info', 'cursos híbridos somente serão ofertados em Cascavel');
        //     } else {
        //         if (curso.ead) {
        //             vm.cursoEad = true;
        //         } else {
        //             vm.cursoEad = false;
        //         }
        //     }
        //     console.log(vm.cursoEad);
        //     vm.cursoSelecionado = curso;
        // }

        vm.validaPoloModalidade = function(modalidade) {
            if (modalidade.modalidade.includes('híbrido')) {
                vm.cursoEad = false;
                vm.matricula.polo = 1;
                vm.matricula.codFilial = 2;
                showMessage('alert alert-info', 'cursos híbridos somente serão ofertados em Cascavel');
            } else {
                if (modalidade.modalidade.includes('EAD')) {
                    vm.cursoEad = true;
                    vm.matricula.codFilial = 2;
                } else {
                    vm.matricula.codFilial = 1;
                    vm.cursoEad = false;
                }
            }
            console.log(vm.cursoEad);
            vm.modalidadeSelecionada = modalidade;
        }

        function upload() {
            ModalImagemService.open();
        }

        $rootScope.$on('cropImage', function(event, value) {
            if (value != null && value != undefined) {
                vm.matricula.foto = value.replace("data:image/png;base64,", "");
                vm.imagemAlterada = true;
            } else {

            }
        });

        $rootScope.$on('selecionou', function(event, value) {
            if (value != null && value != undefined) {
                console.log(value);

                vm.matricula.nome = value;
                vm.nomeTemp = value;
            }
        });


        // chamada de funções..
        getAccount();


        // functions
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.dataNascimentoMax = new Date(new Date().getFullYear() - 16, new Date().getMonth(), new Date().getDate());

                loadColegios();
                loadCidadesByJSON();
                setTimeout(loadDadosCandidato(), 2000);
            });
        }

        function showErrorForm(valida) {
            vm.msgError = valida.msg;
            document.getElementById('error-campo').style.display = "block";
            document.getElementById(valida.campo).classList.add('inputForm-error');
            setTimeout(function() {
                document.getElementById('error-campo' + valida.campo).style.display = "none";
                document.getElementById(valida.campo).classList.remove('inputForm-error');
            }, 5000);
        }

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function loadColegios() {
            $http.post("/api/get-all-colegios", vm.obj)
                .success(function(data) {
                    vm.colegios = data;

                    vm.allStates = vm.colegios;

                    if (vm.matricula.instituicaoConclusao != null) {
                        for (var i = 0; i < vm.colegios.length; i++) {
                            var element = vm.colegios[i];

                            if (vm.matricula.instituicaoConclusao == element.id) {
                                vm.instituicaoSelected = element;
                            }
                        }
                    }
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function buscaCEP() {
            vm.cepsearch = true;
            if (vm.matricula.cep !== null && vm.matricula.cep !== undefined) {
                $http.post("/api/getCepInfos", { cep: vm.matricula.cep })
                    .success(function(data) {

                        console.log(data);

                        vm.matricula.uf = data.uf;
                        vm.matricula.cidade = data.localidade;
                        vm.matricula.bairro = data.bairro;
                        vm.matricula.logradouro = data.logradouro;
                        vm.cepsearch = false;
                    })
                    .error(function(status) {
                        console.log(status);
                        vm.cepsearch = false;
                    });
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.matricula.etapa = "CANDIDATO";
            var valida = verificaCampos();
            console.log(valida);
            if (valida == "OK") {
                vm.matricula.idAreaInteresse = vm.modalidadeSelecionada.idAreaInteresse;
                vm.matricula.codCursoRm = vm.modalidadeSelecionada.codCursoRm;
                vm.matricula.modalidade = vm.modalidadeSelecionada.modalidade;
                vm.matricula.idModalidade = vm.modalidadeSelecionada.id;
                vm.matricula.nomeCurso = vm.modalidadeSelecionada.nomeRm;
                vm.matricula.curso = vm.modalidadeSelecionada.idCurso;

                vm.matricula.instituicaoConclusao = vm.instituicaoSelected.id;
                vm.matricula.estadoNatal = vm.naturalidade.uf;
                vm.matricula.cidadeNatal = vm.naturalidade.cidade;
                //console.log(vm.matricula);
                PreMatriculaDadosCadastraisServiceController.save(vm.matricula, onSaveSuccess, onSaveError);
            } else {
                //showMessage('alert alert-danger', valida);
                vm.isSaving = false;
                vm.load = false;
                showErrorForm(valida);
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $state.go('pre-matricula-dados-cadastrais-pais');
        }

        function onSaveError(status) {
            vm.load = false;
            vm.isSaving = false;
            console.log(status);
            showErrorForm({ campo: "erro", msg: "Ocorreu algum problema ao processar os dados, por favor entre em contato com seu consultor." });
        }

        function verificaCampos() {

            //console.log(vm.naturalidade);

            if (vm.modalidadeSelecionada == null || vm.modalidadeSelecionada == "") {
                //return "Por favor selecione o curso que deseja.";
                return { campo: "modalidade", msg: "Por favor, selecione o curso de matricula." }
            }

            if (vm.matricula.polo == null || vm.matricula.polo == "") {
                return { campo: "polo", msg: "Por favor, selecione o polo de atendimento." }
            }



            // VALIDA SE O NOME ESTA VAZIO -- JA TEM O REQUIRED, MAS VALIDAÇÃO NUNCA É DEMAIS
            if (vm.matricula.nome == null || vm.matricula.nome == "") {
                //return "O campo NOME não pode estar vazio.";
                return { campo: "nome", msg: "Por favor, informe seu nome completo." }
            }

            // VALIDA SE O NOME TEM PELO MENOS DOIS CARACTERES E PELO MENOS UM ESPAÇO
            if (vm.matricula.nome.length <= 2 || !vm.matricula.nome.includes(" ")) {
                //return "O campo NOME deve receber no mínimo um sobrenome.";
                return { campo: "nome", msg: "Por favor, informe seu nome completo." }
            }

            if (vm.matricula.nome !== vm.nomeTemp) {
                ConfirmaNomeService.open(vm.matricula.nome, vm.nomeTemp);
                //return "O campo NOME foi alterado";
                return { campo: "nome", msg: "O campo nome foi alterado." }
            }

            if (vm.matricula.dataNascimento <= new Date(1943, 0, 1)) {
                //return "A data de Nascimento informada ultrapassa os 80 anos de idade;";
                return { campo: "dataNascimento", msg: "A data de Nascimento informada ultrapassa os 80 anos de idade." }
            }

            if (vm.matricula.dataNascimento >= vm.dataNascimentoMax) {
                //return "A data de Nascimento informada é menor que 16 anos de idade;";
                return { campo: "dataNascimento", msg: "A data de Nascimento informada é menor que 16 anos de idade." }
            }

            //calculaIdade();

            // if (vm.matricula.nacionalidade == null || vm.matricula.nacionalidade == "") {
            //     return "O campo NACIONALIDADE não pode ser vazio";
            // }
            // if (vm.matricula.nacionalidade.length <= 2) {
            //     return "O campo NACIONALIDADE precisa ter no mínimo 2 caracteres";
            // }

            if (vm.matricula.cpf == null || vm.matricula.cpf == "") {
                //return "O campo CPF não pode ser vazio";
                return { campo: "cpf", msg: "Por favor, verifique o CPF informado." }
            }
            if (vm.matricula.cpf.length < 11) {
                //return "O campo CPF deve ter ao menos 11 dígitos";
                return { campo: "cpf", msg: "O campo CPF deve ter ao menos 11 dígitos." }
            }

            if (vm.naturalidade == null) {
                //return "Por favor informe a cidade que você nasceu.";
                return { campo: "naturalidade", msg: "Por favor informe a cidade que você nasceu." }
            }

            if (vm.matricula.cep == null || vm.matricula.cep == "") {
                //return "O campo CEP não pode ser vazio";
                return { campo: "cep", msg: "Por favor, verifique o CEP informado." }
            }
            if (vm.matricula.cep.length < 7) {
                //return "O campo CEP deve ter ao menos 7 dígitos";
                return { campo: "cep", msg: "O CEP deve ter ao menos 7 dígitos." }
            }

            if (vm.matricula.uf == null || vm.matricula.uf == "") {
                //return "O campo Estado não pode ser vazio";
                return { campo: "uf", msg: "Por favor, verfique o estado do endereço." }
            }
            if (vm.matricula.uf.length < 2) {
                //return "O campo Estado deve ter ao menos 2 dígitos";
                return { campo: "uf", msg: "O estado do endereço deve ter ao menos 2 dígitos." }
            }

            if (vm.matricula.cidade == null || vm.matricula.cidade == "") {
                //return "O campo CIDADE não pode ser vazio";
                return { campo: "cidade", msg: "Por favor, verfique a cidade do endereço." }
            }
            if (vm.matricula.cidade.length < 2) {
                //return "O campo CIDADE deve ter ao menos 2 dígitos";
                return { campo: "cidade", msg: "A cidade do endereço deve conter ao menos 2 dígitos." }
            }

            if (vm.matricula.bairro == null || vm.matricula.bairro == "") {
                //return "O campo BAIRRO não pode ser vazio";
                return { campo: "bairro", msg: "Por favor, verifique o bairro do endereço." }
            }
            if (vm.matricula.bairro.length < 2) {
                //return "O campo BAIRRO deve ter ao menos 2 dígitos";
                return { campo: "bairro", msg: "O bairro do endereço deve conter ao menos 2 dígitos." }
            }

            if (vm.matricula.logradouro == null || vm.matricula.logradouro == "") {
                //return "O campo Rua ou Avenida não pode ser vazio";
                return { campo: "logradouro", msg: "Por favor, verifique a rua do endereço." }
            }
            if (vm.matricula.logradouro.length < 2) {
                //return "O campo Rua ou Avenida deve ter ao menos 2 dígitos";
                return { campo: "logradouro", msg: "A rua do endereço deve conter ao menos 2 dígitos." }
            }

            if (vm.matricula.numero == null || vm.matricula.numero == "") {
                //return "O campo NUMERO DO ENDEREÇO não pode ser vazio";
                return { campo: "numero", msg: "Por favor, verifique o numero do endereço." }
            }
            if (vm.matricula.numero.length < 1) {
                //return "O campo NUMERO DO ENDEREÇO deve ter ao menos 1 dgito";
                return { campo: "numero", msg: "O número do endereço deve conter ao menos 1 dígito." }
            }
            if (vm.matricula.numero == 0) {
                vm.matricula.numero = 1;
            }

            if (vm.matricula.telefone1 == null || vm.matricula.telefone1 == "") {
                vm.matricula.telefone1 = vm.matricula.telefone2;
            }

            if (vm.matricula.telefone2 == null || vm.matricula.telefone2 == "") {
                //return "O campo CELULAR não pode ser vazio";
                return { campo: "telefone2", msg: "Por favor, verifique o celular cadastrado." }
            }
            if (vm.matricula.telefone2.length < 10) {
                //return "O campo CELULAR deve ter ao menos 10 dígitos";
                return { campo: "telefone2", msg: "O celular deve conter ao menos 10 dígitos." }
            }

            if (vm.possuiNecessidadesEspeciais == 'SIM') {
                if (vm.matricula.necessidadesEspeciais == null | vm.matricula.necessidadesEspeciais == "") {
                    return { campo: "necessidadesEspeciais", msg: "Por favor, informe qual a condição física." }
                }
            }

            if (vm.notfind == true) {
                vm.instituicaoSelected = {};
                vm.instituicaoSelected.id = 3421; // NAO ENCONTREI MINHA ESCOLA
            } else {
                if (vm.instituicaoSelected == null) {
                    //return "O campo Instituição de Conclusão do Ensino Médio precisa ser preenchido.";
                    return { campo: "instituicao", msg: "O campo Instituição de Conclusão do Ensino Médio precisa ser preenchido." }
                }
            }

            return "OK";

        };

        function calculaIdade() {
            var today = new Date();
            vm.idadeAcademico = Math.floor(Math.ceil(Math.abs(vm.matricula.dataNascimento.getTime() - today.getTime()) / (1000 * 3600 * 24)) / 365.25);
            //console.log(vm.idadeAcademico);

        }

        function loadDadosCandidato() {

            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));

            // vamos validar o contexto
            if (vm.resultadoRM.codPessoa == null || vm.resultadoRM.codPessoa == undefined || vm.resultadoRM.codPessoa == "") {
                showMessage("alert alert-danger", "Desculpe não foi possível carregar o contexto");
                vm.isSaving = true;
            };
            if (vm.resultadoRM.codVestibular == null || vm.resultadoRM.codVestibular == undefined || vm.resultadoRM.codVestibular == "") {
                showMessage("alert alert-danger", "Desculpe não foi possível carregar o contexto");
                vm.isSaving = true;
            };

            vm.processoSeletivo = vm.resultadoRM.processo;

            // ResultadoVestibularResource
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", vm.resultadoRM)
                .success(function(data) {
                    //console.log(data);
                    vm.matricula = data;
                    vm.nomeTemp = vm.matricula.nome;

                    switch (vm.matricula.etapa) {
                        case 'CANDIDATO':
                            $state.go('pre-matricula-dados-cadastrais-pais');
                            break;

                        case 'PAIS':
                            $state.go('pre-matricula-dados-cadastrais-responsavel');
                            break;

                        case 'RESPONSAVEL':
                            $state.go('pre-matricula-consentimento');
                            break;

                        case 'TERMOCONSENTIMENTO':
                            $state.go('pre-matricula-plano-pagamento');
                            break;
                        case 'PLANOPAGAMENTO':
                            $state.go('pre-matricula-contrato');
                            break;
                        case 'CONTRATO':
                            $state.go('pre-matricula-boleto');
                            break;

                        default:
                            break;
                    }

                    if (vm.matricula.etapa == 'INICIO') {
                        CursoModalidade.get({ id: vm.matricula.idModalidade },
                            function(modalidade) {
                                console.log(modalidade);
                                vm.cursosAcademico.push(modalidade);
                                vm.validaPoloModalidade(modalidade);
                            },
                            function(status) {
                                console.log(status);
                            });
                    } else {
                        vm.matricula.codPessoa = vm.resultadoRM.codPessoa;
                        vm.matricula.idInscricao = vm.resultadoRM.codVestibular;
                        vm.matricula.polo = vm.resultadoRM.codPolo;
                        vm.matricula.idps = vm.resultadoRM.idps;

                        if (vm.processoSeletivo == 'TRADICIONAL') {
                            vm.cursosAcademico.push(vm.resultadoRM.modalidade1);
                            vm.validaPoloModalidade(vm.resultadoRM.modalidade1);

                            if (vm.resultadoRM.modalidade2 != null) {
                                vm.cursosAcademico.push(vm.resultadoRM.modalidade2);
                            }

                            vm.matricula.dataNascimento = vm.resultadoRM.dataNascimento;
                            vm.matricula.cidadeNatal = vm.resultadoRM.cidadeNatal;
                            vm.matricula.estadoNatal = vm.resultadoRM.estadoNatal;
                        } else {
                            vm.cursosAcademico.push(vm.resultadoRM.modalidade1);
                            vm.validaPoloModalidade(vm.resultadoRM.modalidade1);
                        }
                    }

                    vm.matricula.telefone2 = vm.matricula.telefone1;
                    vm.matricula.telefone1 = "";

                    if (vm.matricula.necessidadesEspeciais == null || vm.matricula.necessidadesEspeciais == "") {
                        vm.possuiNecessidadesEspeciais == "NAO";
                    } else {
                        vm.possuiNecessidadesEspeciais == "SIM";
                    }

                    if (vm.matricula.sexo == null) {
                        vm.matricula.sexo = 'Masculino';
                    }
                    if (vm.matricula.estadoCivil == null) {
                        vm.matricula.estadoCivil = 'Solteiro';
                    }
                    if (vm.matricula.raca == null) {
                        vm.matricula.raca = 'Amarela';
                    }

                    if (vm.matricula.nacionalidade == null) {
                        vm.matricula.nacionalidade = 'BRASILEIRA';
                    }

                    if (vm.matricula.cidadeNatal != null) {
                        var b = vm.matricula.cidadeNatal + ' - ' + vm.matricula.estadoNatal;
                        //console.log(b);
                        for (var i = 0; i < vm.cidades.length; i++) {
                            var element = vm.cidades[i];
                            var a = element.cidade + ' - ' + element.uf;

                            if (a == b) {
                                //console.log(a);
                                vm.naturalidade = element;
                            }
                        }
                    }

                    if (vm.matricula.dataNascimento != null) {
                        var dateString = vm.matricula.dataNascimento;
                        var year = dateString.substring(0, 4);
                        var month = dateString.substring(5, 7);
                        var day = dateString.substring(8, 10);

                        var date = new Date(year, month - 1, day);

                        vm.matricula.dataNascimento = date;

                        calculaIdade();
                    }

                    vm.matricula.tipoMatricula = 'CALOURO';
                    vm.matricula.pgtoOnline = 'SIM';
                    vm.matricula.pgtoPix = 'SIM';

                    if (vm.matricula.adiantamento == null || vm.matricula.adiantamento == undefined || vm.matricula.adiantamento == "") {
                        vm.matricula.adiantamento = "NAO";
                    }

                    // vm.isSaving = false;
                    // console.log(vm.isSaving);
                    // console.log(vm.matricula);

                    if (vm.matricula.instituicaoConclusao != null) {
                        for (var i = 0; i < vm.colegios.length; i++) {
                            var element = vm.colegios[i];

                            if (vm.matricula.instituicaoConclusao == element.id) {
                                vm.instituicaoSelected = element;
                            }
                        }
                    }

                    // vamos validar o contexto
                    if (vm.matricula.codPessoa == null || vm.matricula.codPessoa == undefined || vm.matricula.codPessoa == "") { showMessage("alert alert-danger", "desculpe não foi possível carregar o contexto") };
                    if (vm.matricula.idInscricao == null || vm.matricula.idInscricao == undefined || vm.matricula.idInscricao == "") { showMessage("alert alert-danger", "desculpe não foi possível carregar o contexto") };
                    if (vm.matricula.idps == null || vm.matricula.idps == undefined || vm.matricula.idps == "") { showMessage("alert alert-danger", "desculpe não foi possível carregar o contexto") };

                })
                .error(function(status) {
                    console.log(status);
                });
        }

        // metodo bkp para cursos
        function carregaCursos() {
            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            vm.processoSeletivo = vm.resultadoRM.processo;

            vm.cursosAcademico = [];

            if (vm.processoSeletivo == 'TRADICIONAL') {
                vm.cursosAcademico.push(vm.resultadoRM.modalidade1);
                vm.validaPoloModalidade(vm.resultadoRM.modalidade1);
                if (vm.resultadoRM.curso2 != null) {
                    vm.cursosAcademico.push(vm.resultadoRM.modalidade2);
                }
            } else {
                vm.cursosAcademico.push(vm.resultadoRM.modalidade1);
                vm.validaPoloModalidade(vm.resultadoRM.modalidade1);
            }
        }

        function change() {
            for (var i = 0; i < vm.estados.length; i++) {
                var estado = vm.estados[i];

                if (estado.sigla == vm.estado) {
                    vm.cidades = estado.cidades;
                    break;
                }
            }
        }


        /** REFERENTE AO MD AUTOCOMPLETE */

        vm.querySearch = querySearch;
        vm.selectedItemChange = selectedItemChange;
        vm.searchTextChange = searchTextChange;

        function querySearch(query) {

            var results = query ? vm.allStates.filter(createFilterFor(query)) : vm.allStates,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChange(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChange(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterFor(query) {
            //var str = accentFold(query).toUpperCase();
            var str = query.toUpperCase();
            return function filterFn(state) {
                //return (state.nome.toUpperCase().replace('COLEGIO', '').replace('ESTADUAL', '').replace('ESCOLA', '') + '-' + state.cidade).includes(str);~
                return accentFold(state.nome).toUpperCase().includes(str);
            };
        }


        /*   referente aos estados   */

        vm.querySearchEstado = querySearchEstado;
        vm.selectedItemChangeEstado = selectedItemChangeEstado;
        vm.searchTextChangeEstado = searchTextChangeEstado;

        function querySearchEstado(query) {

            var results = query ? vm.cidades.filter(createFilterForEstado(query)) : vm.cidades,
                deferred;

            if (vm.simulateQuery) {
                deferred = $q.defer();
                $timeout(function() { deferred.resolve(results); }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        }

        function searchTextChangeEstado(text) {
            //$log.info('Text changed to ' + text);
        }

        function selectedItemChangeEstado(item) {
            // $log.info('Item changed to ' + JSON.stringify(item));
        }

        function createFilterForEstado(query) {
            var str = accentFold(query.toUpperCase());

            return function filterFn(state) {
                return accentFold(state.cidade).toUpperCase().includes(str);
            };

        }

        function accentFold(inStr) {
            return inStr.replace(/([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
                function(str, a, c, e, i, n, o, s, u, y, ae) {
                    if (a) return 'a';
                    if (c) return 'c';
                    if (e) return 'e';
                    if (i) return 'i';
                    if (n) return 'n';
                    if (o) return 'o';
                    if (s) return 's';
                    if (u) return 'u';
                    if (y) return 'y';
                    if (ae) return 'ae';
                }
            );
        }

        function loadCidadesByJSON() {
            vm.cidades = CidadesMDJsonService;
        }
    }
})();