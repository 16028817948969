(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('colegio-play', {
                parent: 'entity',
                url: '/colegio-play',
                data: {
                    authorities: ['ROLE_UNIVEL_PLAY'],
                    pageTitle: 'ColegioPlays'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/colegio-play/colegio-plays.html',
                        controller: 'ColegioPlayController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('colegio-play-detail', {
                parent: 'entity',
                url: '/colegio-play/{id}',
                data: {
                    authorities: ['ROLE_UNIVEL_PLAY'],
                    pageTitle: 'ColegioPlay'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/colegio-play/colegio-play-detail.html',
                        controller: 'ColegioPlayDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'ColegioPlay', function($stateParams, ColegioPlay) {
                        return ColegioPlay.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'colegio-play',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('colegio-play-detail.edit', {
                parent: 'colegio-play-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/colegio-play/colegio-play-dialog.html',
                        controller: 'ColegioPlayDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ColegioPlay', function(ColegioPlay) {
                                return ColegioPlay.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('colegio-play.new', {
                parent: 'colegio-play',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/colegio-play/colegio-play-dialog.html',
                        controller: 'ColegioPlayDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    nome: null,
                                    cidade: null,
                                    ambito: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('colegio-play', null, { reload: 'colegio-play' });
                    }, function() {
                        $state.go('colegio-play');
                    });
                }]
            })
            .state('colegio-play.edit', {
                parent: 'colegio-play',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/colegio-play/colegio-play-dialog.html',
                        controller: 'ColegioPlayDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ColegioPlay', function(ColegioPlay) {
                                return ColegioPlay.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('colegio-play', null, { reload: 'colegio-play' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('colegio-play.delete', {
                parent: 'colegio-play',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SUPER_ADM_UNIVEL_PLAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/colegio-play/colegio-play-delete-dialog.html',
                        controller: 'ColegioPlayDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ColegioPlay', function(ColegioPlay) {
                                return ColegioPlay.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('colegio-play', null, { reload: 'colegio-play' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();