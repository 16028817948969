(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('DiarioClasseController', DiarioClasseController);

    DiarioClasseController.$inject = ['$http', 'DiarioClasse', 'Principal', 'BuscaPlanoForDiario', 'ImportDiarioPlanoAula', 'BuscaDiariosByUser'];

    function DiarioClasseController($http, DiarioClasse, Principal, BuscaPlanoForDiario, ImportDiarioPlanoAula, BuscaDiariosByUser) {

        var vm = this;

        vm.diarios = null;
        vm.etapa = 1;

        vm.consulta = {};
        vm.consulta.etapa = "1";
        vm.consulta.codPerlet = "2024/1";
        vm.consulta.disciplina = "TODOS";
        vm.consulta.curso = "TODOS";
        vm.consulta.tipoUser = "TODOS";

        vm.parametros = {};
        //vm.parametros.idReport = '2044';
        vm.parametros.periodoLetivo = new Date().getFullYear() + "/" + 1;

        vm.relatorios = [
            { filtro: 1, idReport: 2044, codReport: "01.001.2.01.1", descricao: "Presencial por IdTurmaDisc" },
            { filtro: 2, idReport: 2044, codReport: "01.001.2.01.1", descricao: "Presencial por Professor" },
            { filtro: 3, idReport: 2044, codReport: "01.001.2.01.1", descricao: "Presencial por Curso" },
            { filtro: 4, idReport: 2044, codReport: "01.001.2.01.1", descricao: "Presencial por Período" },

            { filtro: 5, idReport: 1648, codReport: "01.001.2.7", descricao: "Atvs e Reoferta por Professor" },
            // { filtro: 6, idReport: 1648, codReport: "01.001.2.7", descricao: "Atvs e Reoferta por Curso" },
            { filtro: 7, idReport: 1648, codReport: "01.001.2.7", descricao: "Atvs e Reoferta por IdTurmaDisc" },

            { filtro: 8, idReport: 2238, codReport: "01.001.11", descricao: "EAD 20% por Professor" }
        ];

        vm.selectReport = function() {
            console.log(vm.relatorioSelecionado);
            vm.parametros.idReport = vm.relatorioSelecionado.idReport
            vm.parametros.filtro = vm.relatorioSelecionado.filtro;
        }

        getAccount();

        function getAccount() {
            vm.load = true;
            vm.msgLoad = "Carregando Contexto";
            Principal.identity().then(function(account) {
                vm.load = false;
                vm.account = account;
                vm.consulta.user = vm.account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.etapa = 2;
            });
        };

        vm.gerarRelatorio = function() {
            console.log(vm.parametros);
            $http.post('api/gerar-novo-diario', vm.parametros)
                .success(function(data) {
                    vm.diarios = data;
                    vm.load = false;
                    vm.etapa = 2;
                }).error(function(status) {
                    console.log(status);
                });
        }

        vm.consultaDiarios = function() {
            console.log(vm.consulta);
            $http.post('api/consulta-diarios-gerados', vm.consulta)
                .success(function(data) {
                    vm.diarios = data;
                }).error(function(status) {
                    console.log(status);
                });
        }

        vm.baixarDiarioArq = function(diario) {
            $http.post('api/diario-classe-get-pdf', diario, { responseType: 'arraybuffer' })
                .success(function(data) {

                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);

                    var fileName = diario.codPerlet.replace("/", "-") + "-" + diario.bimestre + "-Bimestre-" + diario.curso + "-" + diario.codTurma + "-" + diario.disciplina +
                        "-" + diario.idTurmaDisc + "-DIARIO-CLASSE.pdf";

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    a.href = fileURL;
                    a.download = fileName;
                    a.click();

                    window.URL.revokeObjectURL(fileURL);
                    vm.load = false;
                }).error(function(status) {
                    console.log(status);
                });
        }
    }
})();