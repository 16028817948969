(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ContratoClausulaController', ContratoClausulaController);

    ContratoClausulaController.$inject = ['$state', 'ContratoClausula', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'PSAtivos', 'ConsultaContratoIdPerlet'];

    function ContratoClausulaController($state, ContratoClausula, ParseLinks, AlertService, paginationConstants, pagingParams, PSAtivos, ConsultaContratoIdPerlet) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;


        PSAtivos.query(function(result) {
            vm.processos = result;
        }, function(status) {
            console.log(status);
        });


        vm.consultar = function() {
            if (vm.processoSelecionado == null){
                showMessage('alert-warning', 'Por favor selecione um Processo Seletivo.')
            }else{
                ConsultaContratoIdPerlet.query({ idPerlet: vm.processoSelecionado.idPerlet},
                    function(result) {
                        vm.clausulas = result;
                    },
                    function(status) {
                        console.log(status);
                    });    
            }
        }
        
        /*loadAll();

        function loadAll () {
            ContratoClausula.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contratoClausulas = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }*/

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }
    }
})();
