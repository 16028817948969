(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RelatorioExtensaoFileController', RelatorioExtensaoFileController);

    RelatorioExtensaoFileController.$inject = ['DataUtils', 'RelatorioExtensaoFile'];

    function RelatorioExtensaoFileController(DataUtils, RelatorioExtensaoFile) {

        var vm = this;

        vm.relatorioExtensaoFiles = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        //loadAll();

        function loadAll() {
            RelatorioExtensaoFile.query(function(result) {
                vm.relatorioExtensaoFiles = result;
                vm.searchQuery = null;
            });
        }
    }
})();