(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlayVipController', PlayVipController);

    PlayVipController.$inject = ['$scope', '$rootScope', '$http', '$state', 'DateUtils', 'Principal', 'ModalImagemService', 'ByteArrayImgService', 'TrocaCursoTradService', 'PlayFoto', 'ColegioPlay', 'LinkVendedorService', 'Inscricao'];

    function PlayVipController($scope, $rootScope, $http, $state, DateUtils, Principal, ModalImagemService, ByteArrayImgService, TrocaCursoTradService, PlayFoto, ColegioPlay, LinkVendedorService, Inscricao) {

        var vm = this;
        //$state.go('home');

        vm.fecharAlert = fecharAlert;
        vm.gerarCartao = gerarCartao;
        vm.alterInfo = alterInfo;

        vm.cidades = [];
        vm.colegiosCidade = [];
        vm.cidadeSelecionada = cidadeSelecionada;
        vm.colegioSelecionado = colegioSelecionado;

        // ColegioPlay.query(function(result) {
        //     vm.colegios = result;
        //     console.log(result);
        //     onload();
        // });

        vm.etapa = 1;

        vm.foto = ByteArrayImgService;

        vm.cursos = [
            { id: 2, idPlay: 1, nome: "Administração (noite)" },
            { id: 3, idPlay: 2, nome: "Agronomia (manhã)" },
            { id: 4, idPlay: 3, nome: "Agronomia (noite)" },
            { id: 5, idPlay: 4, nome: "Arquitetura e Urbanismo (manhã)" },
            { id: 6, idPlay: 5, nome: "Arquitetura e Urbanismo (noite)" },
            { id: 50, idPlay: 6, nome: "Biomedicina (manhã)" },
            { id: 33, idPlay: 7, nome: "Biomedicina (noite)" },
            { id: 7, idPlay: 8, nome: "Ciências Contábeis (noite)" },
            { id: 18, idPlay: 9, nome: "Design Gráfico (noite)" },
            { id: 8, idPlay: 10, nome: "Direito (manhã)" },
            { id: 9, idPlay: 11, nome: "Direito (noite)" },
            { id: 34, idPlay: 12, nome: "Educação Física (manhã)" },
            { id: 35, idPlay: 13, nome: "Educação Física (noite)" },
            { id: 84, idPlay: 14, nome: "Enfermagem (manhã)" },
            { id: 47, idPlay: 15, nome: "Enfermagem (noite)" },
            { id: 10, idPlay: 16, nome: "Engenharia Civil (noite)" },
            { id: 12, idPlay: 17, nome: "Engenharia de Produção (noite)" },
            { id: 11, idPlay: 18, nome: "Engenharia Mecânica (noite)" },
            { id: 49, idPlay: 19, nome: "Fisioterapia (manhã)" },
            { id: 36, idPlay: 20, nome: "Fisioterapia (noite)" },
            { id: 20, idPlay: 21, nome: "Gastronomia (manhã)" },
            { id: 21, idPlay: 22, nome: "Gastronomia (noite)" },
            { id: 23, idPlay: 23, nome: "Gestão Comercial (noite)" },
            { id: 37, idPlay: 24, nome: "Medicina Veterinária (manhã)" },
            { id: 38, idPlay: 25, nome: "Medicina Veterinária (noite)" },
            { id: 48, idPlay: 26, nome: "Nutrição (manhã)" },
            { id: 39, idPlay: 27, nome: "Nutrição (noite)" },
            { id: 89, idPlay: 28, nome: "Odontologia (Manhã)" },
            { id: 52, idPlay: 29, nome: "Odontologia (noite)" },
            { id: 16, idPlay: 30, nome: "Pedagogia (noite)" },
            { id: 54, idPlay: 31, nome: "Psicologia (manhã)" },
            { id: 53, idPlay: 32, nome: "Psicologia (noite)" },
            { id: 14, idPlay: 33, nome: "Publicidade e Propaganda (noite)" },
            { id: 17, idPlay: 34, nome: "Tecnologia em Análise e Desenvolvimento de Sistemas (noite)" },

            { id: 40, idPlay: 35, nome: "Administração (EAD)" },
            { id: 41, idPlay: 36, nome: "Ciências Contábeis (EAD)" },
            { id: 87, idPlay: 37, nome: "Educação Física Bacharelado (EAD)" },
            { id: 24, idPlay: 38, nome: "Gestão Comercial (EAD)" },
            { id: 32, idPlay: 39, nome: "Recursos Humanos (EAD)" },
            { id: 26, idPlay: 40, nome: "Gestão Financeira (EAD)" },
            { id: 28, idPlay: 41, nome: "Logística (EAD)" },
            { id: 55, idPlay: 42, nome: "Marketing (EAD)" },
            { id: 44, idPlay: 43, nome: "Pedagogia (EAD)" },
            { id: 30, idPlay: 44, nome: "Processos Gerenciais (EAD)" },
            { id: 86, idPlay: 45, nome: "Tecnologia em Análise e Desenvolvimento de Sistemas (EAD)" },
            { id: 90, idPlay: 99, nome: "INDECISO" }
        ];

        vm.upload = function() {
            ModalImagemService.open();
        }

        vm.imagemAlterada = false;

        $rootScope.$on('cropImage', function(event, value) {
            if (value != null && value != undefined) {
                vm.fotoTeste = value;
                vm.foto = value.replace("data:image/png;base64,", "");
                vm.imagemAlterada = true;

                console.log(vm.fotoTeste);
                console.log(vm.foto);

                if (vm.playFoto != null) {
                    if (vm.playFoto.id != null) {
                        vm.playFoto.foto = vm.foto;
                        vm.playFoto.fotoContentType = "image/png";
                        vm.playFoto.createdData = new Date();
                        vm.playFoto.createdUser = 'pagVip';
                    } else {
                        vm.playFoto = {
                            codigo: vm.inscricao.codigo,
                            idps: vm.inscricao.idps,
                            foto: vm.foto,
                            fotoContentType: "image/png",
                            createdData: new Date(),
                            createdUser: 'pagVip'
                        }
                    }
                } else {
                    vm.playFoto = {
                        codigo: vm.inscricao.codigo,
                        idps: vm.inscricao.idps,
                        foto: vm.foto,
                        fotoContentType: "image/png",
                        createdData: new Date(),
                        createdUser: 'pagVip'
                    }
                }

                PlayFoto.save(vm.playFoto, function(result) {
                    showMessage('alert alert-info', 'Foto salva com sucesso.');
                    console.log(result);
                }, function(status) {
                    console.log(status);
                    showMessage('alert alert-warning', 'Erro ao salvar a foto.');
                })

            } else {
                showMessage('alert alert-warning', 'Erro ao salvar a foto.');
            }
        });

        vm.consulta = function() {

            if (vm.celular == null && vm.cpf == null && vm.dataNascimento == null) {
                showMessage('alert alert-info', 'Por favor informe o celular, cpf ou data de nascimento para validar a inscrição.');
            } else {
                vm.obj = {
                    codigo: vm.codigo,
                    celular: vm.celular,
                    cpf: vm.cpf,
                    data_nascimento: vm.dataNascimento,
                    idps: '54'
                }

                $http.post("/api/get-candidato-play-vip", vm.obj)
                    .success(function(data) {

                        console.log(data);

                        if (data.inscricao == null || data.inscricao == undefined || data.inscricao == "") {
                            vm.etapa = 4;
                        } else {

                            if (data.inscricao.tipoInscricao == "CONVIDADOVIP") {
                                showMessage('alert alert-info', 'Olá ' + data.inscricao.nome + ', você já é nosso convidado VIP!!');
                            } else {
                                $http.post("/api/get-foto-play-vip", vm.obj)
                                    .success(function(rs) {
                                        vm.fotoPlay = rs;
                                        vm.foto = vm.fotoPlay.foto.replace("data:image/png;base64,", "");
                                        vm.imagemAlterada = true;
                                    }).error(function(status) {
                                        console.log("nao possui foto");
                                    });

                                vm.foto = ByteArrayImgService;
                                var dateString = data.inscricao.dataNascimento;
                                var year = dateString.substring(0, 4);
                                var month = dateString.substring(5, 7);
                                var day = dateString.substring(8, 10);
                                var date = new Date(year, month - 1, day);

                                data.inscricao.dataNascimento = date;
                                vm.inscricao = data.inscricao;
                                vm.userAluno = data.user;
                                vm.etapa = 2;
                            }
                        }
                    })
                    .error(function(status) {
                        if (status == null || status == "") {
                            showMessage('alert alert-danger', 'Desculpe, não foi possível encontrar uma inscrição com os dados informados, por favor entre em contato com a Univel para solucionar.');
                        }
                    });
            }
        }

        function gerarCartao() {

            console.log(vm.inscricao);

            vm.load = true;

            var valida = validaInfos();

            vm.inscricao.tipoInscricao = "CONVIDADOVIP";

            if (valida == 'OK') {
                Inscricao.update(vm.inscricao, function(result) {
                    $http.post('api/get-play-vipcard/', result, { responseType: 'arraybuffer' })
                        .success(function(data) {
                            vm.etapa = 1;
                            var file = new Blob([(data)], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
                            var a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            a.href = fileURL;
                            a.download = "UNIVELVIPCARD-" + result.codigo;
                            a.click();
                            window.URL.revokeObjectURL(fileURL);
                            vm.load = false;
                        }).error(function(status) {
                            vm.load = false;
                            console.log(status);
                        });
                }, function(status) {
                    console.log(status);
                });
            } else {
                vm.isSaving = false;
                showMessage('alert alert-danger', valida);
            }
        }

        function validaInfos() {

            if (vm.inscricao.senha == null || vm.inscricao.senha == "") {
                return "A senha não pode ser vazia";
            } else {
                if (vm.inscricao.senha.length <= 5) {
                    return "A senha deve conter no mínimo 6 dígitos";
                }
            }

            if (vm.inscricao.email == null || vm.inscricao.email == "") {
                return "Por favor, informe um e-mail válido";
            } else {
                if (!vm.inscricao.email.indexOf("@")) {
                    return "Por favor, informe um e-mail válido";
                }
            }

            if (vm.inscricao.nome == null || vm.inscricao.nome == "") {
                return "O nome deve ser preenchido.";
            } else {
                if (vm.inscricao.nome.length <= 3 || !vm.inscricao.nome.includes(" ")) {
                    return "Por favor, insira seu nome completo. Ex: FULANO SILVA";
                }
            }
            if (vm.inscricao.telefone1 == null || vm.inscricao.telefone1 == "") {
                return "O CELULAR parece estar incorreto";
            } else {
                if (vm.inscricao.telefone1.length < 10) {
                    vm.inscricao.telefone1 = "";
                    return "O CELULAR deve conter código de àrea + número de telefone.";
                }
            }

            if (vm.inscricao.cpf == null || vm.inscricao.cpf == "") {
                return "O CPF informado é inválido.";
            } else {
                if (vm.inscricao.cpf.length < 11) {
                    return "O campo CPF deve ter ao menos 11 dígitos";
                }
            }

            return "OK";
        }

        function alterInfo(opcao, i) {

            if (opcao == 2) {
                vm.inscricao.idCurso1 = i.id;

                TrocaCursoTradService.query({
                    op: 1,
                    curso: i.id,
                    insc: vm.inscricao.id
                }, function(data) {
                    vm.result = data;
                }, function(status) {
                    console.log(status);
                });

            }

            // segunda opção de curso 2022 / 2023
            if (opcao === 7) {
                if (i.id == vm.inscricao.idCurso1) {
                    showMessage('alert alert-danger', "A segunda opção de curso deve ser diferente da primeira.");
                } else {
                    vm.inscricao.idCurso2 = i.id;
                    TrocaCursoTradService.query({
                        op: 2,
                        curso: i.id,
                        insc: vm.inscricao.id
                    }, function(data) {
                        vm.result = data;
                    }, function(status) {
                        console.log(status);
                    });
                }
            }
        }

        function onload() {
            var arrayT = new Array();
            for (var i = 0; i < vm.colegios.length; i++) {
                var e = vm.colegios[i];

                if (i == 0) {
                    arrayT.push(e);
                } else {
                    var salva = true;
                    for (var a = 0; a < arrayT.length; a++) {
                        var teste = arrayT[a];
                        if (teste.cidade == e.cidade) {
                            salva = false;
                        }
                    }
                    if (salva) {
                        arrayT.push(e);
                    }
                }
            }
            vm.cidades = arrayT;
            //console.log(arrayT);
            //vm.etapa = 4;
        }

        function colegioSelecionado(col) {
            vm.colSelecionado = col;


            if (vm.colSelecionado.vendedor != null && vm.colSelecionado.vendedor != undefined) {
                LinkVendedorService.query({ codigo: vm.colSelecionado.vendedor }, function(result) {
                    console.log(result);
                    vm.vendedorLink = result.link;
                    vm.vendedorNumero = result.numero;
                    vm.vendedorEmail = result.email;

                });
            } else {
                vm.vendedorLink = 'https://wa.me/5545988429444';
                vm.vendedorNumero = "45 98842-9444";
                vm.vendedorEmail = "vestibular@univel.br";
            }

        }

        function cidadeSelecionada(col) {
            vm.cidadeCol = col.cidade;

            var array = [];
            for (var i = 0; i < vm.colegios.length; i++) {
                var e = vm.colegios[i];
                if (e.cidade == vm.cidadeCol) {
                    array.push(e);
                }
            }
            vm.colegiosCidade = array;
            console.log(vm.colegiosCidade);
        }

        /* Referente ao sistema de mensagens */
        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

    }
})();