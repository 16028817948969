(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ColegioPlayController', ColegioPlayController);

    ColegioPlayController.$inject = ['$scope', '$http', '$state', 'ColegioPlay', 'LinkVendedor'];

    function ColegioPlayController($scope, $http, $state, ColegioPlay, LinkVendedor) {
        var vm = this;

        vm.colegioPlays = [];

        loadAll();


        function loadAll() {
            ColegioPlay.query(function(result) {
                vm.colegioPlays = result;
            });


        }
    }
})();