(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ProvaOnlineRedacaoAlunoController', ProvaOnlineRedacaoAlunoController);

    ProvaOnlineRedacaoAlunoController.$inject = ['$scope', '$state', '$http', 'ProvaOnline', '$sessionStorage', 'BuscaTemaRandonService', 'InscricaoAgendadoUser', 'BuscaTemaIDService', 'SessaoProvaOnlineService'];

    function ProvaOnlineRedacaoAlunoController($scope, $state, $http, ProvaOnline, $sessionStorage, BuscaTemaRandonService, InscricaoAgendadoUser, BuscaTemaIDService, SessaoProvaOnlineService) {
        var vm = this;

        vm.enviar = enviar;
        vm.iniciarProva = iniciarProva;
        vm.startCountdown = startCountdown;
        vm.fecharAlert = fecharAlert;
        vm.buscarSessao = buscarSessao;

        vm.tempo = new Number();
        vm.liberado = true;
        vm.iniciar = false;
        vm.isSaving = false;
        vm.horaImprimivel = "";

        vm.finalizado = false;
        vm.etapa = 1;

        init();

        function init() {
            /* VAMOS LÁ */
            /* TEMOS QUE VERIFICAR A PROVA DO CARA agr vai ter o tempo decorrido na insc, temos que voltar de onde parou */

            /** Aqui o cara ja tem uma prova, recarrega */
            vm.prova = JSON.parse(sessionStorage.getItem('prova-online'));

            /** Aqui o cara veio de uma inscricao Agendada. */
            vm.inscricao = JSON.parse(sessionStorage.getItem('prova-online-inscricao'));
            vm.aceite = JSON.parse(sessionStorage.getItem('aceite'));
            //vm.tema = JSON.parse(sessionStorage.getItem('tema'));

            vm.tempo = 3540;

            console.log(vm.prova);
            console.log(vm.inscricao);

            /** BLOCO DE TESTE */
            //vm.prova = {};
            //vm.prova.tema = 5;
            //vm.prova.tempo = vm.tempo;
            //vm.prova.id = 514;

            if (vm.prova != null) {

                /* O CARA VEIO RECARREGADO VAMOS ALOCAR AS INFORMAÇÕES */

                /** verifica se ele tem ip de aceite */
                if (vm.prova.ip == null) {
                    vm.ip = null;
                    $(function() {
                        $.getJSON("https://api.ipify.org?format=jsonp&callback=?",
                            function(json) {
                                vm.ip = json.ip;
                            }
                        );
                    });

                    vm.prova.ip = vm.ip;
                    vm.prova.dataAceite = new Date();
                }

                /** verifica se ele ja tem tema na prova */
                //if (vm.tema == null) {
                if (vm.prova.tema != null) {
                    BuscaTemaIDService.query({ id: vm.prova.tema }, function(result) {
                        vm.tema = result[0];
                        console.log(vm.tema);
                        if (vm.prova.tempo != null && vm.prova.tempo !== 0) {
                            vm.tempo = vm.prova.tempo;
                        }

                        vm.iniciar = true;

                    }, function(status) {
                        console.log(status);
                    });

                } else {

                    var e = ""

                    if (vm.prova.tipo == "PRESENCIAL" || vm.prova.tipo == "TRANSFERENCIA" || vm.prova.tipo == "EGRESSO") {
                        e = "AGENDADO";
                    } else {
                        e = vm.prova.tipo;
                    }

                    BuscaTemaRandonService.query({ tipo: e }, function(result) {
                        vm.tema = result;
                        console.log(result);
                        vm.prova.tema = vm.tema.id;
                        vm.iniciar = true;

                    }, function(status) {
                        console.log(status);
                    });
                }

                //}

            } else {
                /* ele não veio carregado vamos ver se é inscrição */

                if (vm.inscricao != null) {
                    /* O CARA VEIO DA INSCRICAO, VAMOS INICIAR O PROCESSO */
                    vm.prova = {};
                    vm.prova.id = null;

                    /** verifica se a prova ja tem ip */
                    if (vm.aceite !== null) {
                        vm.prova.ip = vm.aceite.ip;
                        vm.prova.dataAceite = vm.aceite.data;
                    } else {
                        vm.ip = null;
                        $(function() {
                            $.getJSON("https://api.ipify.org?format=jsonp&callback=?",
                                function(json) {
                                    vm.ip = json.ip;
                                }
                            );
                        });

                        vm.prova.ip = vm.ip;
                        vm.prova.dataAceite = new Date();

                    }

                    vm.prova.inscricao = vm.inscricao.id;
                    vm.prova.nome = vm.inscricao.nome;
                    vm.prova.email = vm.inscricao.email;
                    vm.prova.telefone = vm.inscricao.telefone;
                    vm.prova.cpf = vm.inscricao.cpf;
                    vm.prova.senha = vm.inscricao.cpf;

                    if (vm.inscricao.inscricaoPlay == true) {
                        vm.prova.tipo = "TRADICIONAL";
                    } else {
                        vm.prova.tipo = "AGENDADO";
                    }

                    if (vm.inscricao.idps != null) {
                        vm.prova.idps = vm.inscricao.idps;
                    }

                    if (vm.inscricao.tempo != null) {
                        vm.tempo = vm.inscricao.tempo;
                    }

                    /** verifica se a prova já tem tema */

                    if (vm.inscricao.tema != null) {
                        /** já tem entao pegamos pelo id */
                        BuscaTemaIDService.query({ id: vm.inscricao.tema }, function(result) {
                            vm.tema = result[0];
                            vm.iniciar = true;

                        }, function(status) {
                            console.log(status);
                        });
                    } else {

                        /** nao tem então vemos se é play ou agendado para pegar o novo tema */

                        var e = ""

                        if (vm.prova.tipo == "PRESENCIAL" || vm.prova.tipo == "TRANSFERENCIA" || vm.prova.tipo == "EGRESSO") {
                            e = "AGENDADO";
                        } else {
                            e = vm.prova.tipo;
                        }

                        BuscaTemaRandonService.query({ tipo: e }, function(result) {
                            vm.tema = result[0];
                            vm.prova.tema = vm.tema.id;
                            vm.iniciar = true;

                        }, function(status) {
                            console.log(status);
                        });

                    }
                } else {
                    /* ALGO DEU ERRADO VAMOS PEDIR OS DADOS E BUSCAR A SESSAO */
                    vm.prova = {};
                    vm.prova.inscricao = null;
                    vm.prova.nome = null;
                    vm.prova.email = null;
                    vm.prova.telefone = null;
                    vm.prova.cpf = null;
                    vm.dados = false;
                    vm.etapa = 2;
                }
            }
        }

        function iniciarProva() {
            var x = document.getElementById("gbt-frame");

            if (x !== null) {
                document.getElementById("gbt-frame").style.display = 'none';
            }
            var valida = validaInfos();
            if (valida == "OK") {
                salvarInicio();
                vm.etapa = 3;
                startCountdown();
            } else {
                showErrorForm(valida);
            }
        }

        function salvarInicio() {
            vm.prova.situacao = 'INICIO';
            vm.prova.tempo = vm.tempo;

            if (vm.prova.id == null) {
                vm.prova.nota = 0;
                vm.prova.notaObj = 0;
                vm.prova.notaGeral = 0;
                vm.prova.tempoObj = 0;
                vm.prova.situacaoObj = 'Não Iniciada';
                vm.prova.dataCriacao = new Date();

                ProvaOnline.save(vm.prova, function(result) {
                    vm.prova.id = result.id
                    vm.prova.situacao = 'ANDAMENTO';
                }, function(status) {
                    console.log(status);
                });

            } else {
                vm.prova.dataAlteracao = new Date();
                ProvaOnline.update(vm.prova, function(result) {
                    vm.prova.situacao = 'ANDAMENTO';
                }, function(status) {
                    console.log(status);
                });
            }

            sessionStorage.setItem('tema', JSON.stringify(vm.tema));
            sessionStorage.setItem('prova-online', JSON.stringify(vm.prova));
        }

        /* SALVA A SESSÃO PARA EVITAR A PERCA DE INFORMAÇÕES DO ALUNO */
        function salvarSessao() {
            if (vm.finalizado == true) {

            } else {

                vm.prova.situacao = 'ANDAMENTO';
                vm.prova.tempo = vm.tempo;

                if (vm.prova.ip == null) {
                    vm.ip = null;
                    $(function() {
                        $.getJSON("https://api.ipify.org?format=jsonp&callback=?",
                            function(json) {
                                vm.ip = json.ip;
                            }
                        );
                    });

                    vm.prova.ip = vm.ip;
                    vm.prova.dataAceite = new Date();
                }


                if (vm.prova.id == null) {
                    vm.prova.nota = 0;
                    vm.prova.notaObj = 0;
                    vm.prova.notaGeral = 0;
                    vm.prova.tempoObj = 0;
                    vm.prova.situacaoObj = 'Não Iniciada';
                    vm.prova.dataCriacao = new Date();

                    ProvaOnline.save(vm.prova, function(result) {
                        vm.prova.id = result.id
                    }, function(status) {
                        console.log(status);
                    });

                } else {
                    vm.prova.dataAlteracao = new Date();
                    ProvaOnline.update(vm.prova);
                }

                sessionStorage.setItem('tema', JSON.stringify(vm.tema));
                sessionStorage.setItem('prova-online', JSON.stringify(vm.prova));
            }

        }

        /* ENVIA A PROVA */
        function enviar() {
            vm.tempo = 0;
            vm.liberado = false;
            vm.finalizado = true;
            vm.isSaving = true;

            if (vm.inscricao != null) {
                if (vm.inscricao.id != null) {
                    if (vm.inscricao.provaOnline == false) {
                        vm.inscricao.provaOnline = true;
                        vm.inscricao.dataProva = new Date();
                        vm.inscricao.horaProva = "H13";
                        InscricaoAgendadoUser.update(vm.inscricao);
                    }
                }
            } else {
                vm.inscricao = InscricaoAgendadoUser.get({ id: vm.prova.inscricao });
            }

            vm.prova.situacao = 'PENDENTE';

            ProvaOnline.update(vm.prova, function(result) {
                vm.isSaving = false;

                vm.prova = {};
                clearInterval();
                clearTimeout();

                sessionStorage.setItem('prova-online', JSON.stringify(result));
                sessionStorage.setItem('tema', JSON.stringify(vm.tema));

                $state.go('provaOnlineFinal');


            }, function(status) {
                console.log(status);
            });
        }

        String.prototype.replaceAll = function(search, replacement) {
            var target = this;
            return target.split(search).join(replacement);
        };

        function buscarSessao() {

            var r = validaInfos();

            //console.log(vm.prova);

            if (r == "OK") {
                SessaoProvaOnlineService.query(vm.prova,
                    function(result) {
                        console.log(result);
                        if (result.cpf != null || result.telefone != null || result.email != null) {
                            if (result.situacao == 'ANDAMENTO' || result.situacao == 'NAOINICIADA' || result.situacao == "ANONIMA") {
                                vm.prova = result;


                                if (vm.prova.tipo == "AGENDADO") {

                                    vm.prova.id = null;
                                    vm.prova.tempo = 3540;
                                    vm.prova.titulo = "";
                                    vm.prova.texto = "";
                                    vm.prova.tema = null;

                                }


                                sessionStorage.setItem('prova-online', JSON.stringify(vm.prova));
                                vm.etapa = 1;
                                init();
                            } else {
                                showMessage('alert-danger', 'A redação desta inscrição já foi concluída, qualquer dúvida entre em contato com vestibular@univel.br.');
                            }
                        } else {
                            showMessage('alert-danger', 'Desculpe, não foi possível recuperar os dados da sua inscricão, por favor entre em contato com vestibular@univel.br e informe o problema.');
                        }
                    },
                    function(status) {
                        showMessage('alert-danger', 'Desculpe, não foi possível recuperar os dados da sua inscricão, por favor entre em contato com vestibular@univel.br e informe o problema.');
                    });
            } else {
                showErrorForm(r);
            }
        }

        /* CONTADOR DO TEMPO DE PROVA */
        function startCountdown() {

            // Se o tempo não for zerado
            if (vm.tempo >= 1) {
                // Pega a parte inteira dos minutos
                var min = parseInt(vm.tempo / 60);
                var comp = ' minutos.';
                // Formata o número menor que dez, ex: 08, 07, ...
                if (min < 10) {
                    min = "0" + min;
                    min = min.substr(0, 2);
                }

                if (min == "01") {
                    comp = ' minuto.';
                }

                var horaImprimivel = min + comp;
                $("#tempoRestante").html(horaImprimivel);

                setTimeout(function() {
                    salvarSessao();
                    $("#tempoRestante").html(horaImprimivel);
                    vm.tempo = (vm.tempo - 60);
                    startCountdown();
                }, 60000);

            } else {
                enviar();
            }

        }

        /* VALIDAÇÃO DE FORMULÁRIO E MOSTRA ERRO */
        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }


        function showErrorForm(valida) {
            vm.msgError = valida.msg;
            document.getElementById('error-' + valida.campo).style.display = "block";
            document.getElementById(valida.campo).classList.add('inputForm-error');
            setTimeout(function() {
                document.getElementById('error-' + valida.campo).style.display = "none";
                document.getElementById(valida.campo).classList.remove('inputForm-error');
            }, 5000);
        }

        function validaInfos() {

            if (vm.prova.email == null || vm.prova.email == "") {
                return { campo: "email", msg: "E-mail incorreto. por favor verifique e tente novamente." };
            }
            if (!vm.prova.email.indexOf("@")) {
                return { campo: "email", msg: "E-mail incorreto. por favor verifique e tente novamente." };
            }
            if (vm.prova.telefone == null || vm.prova.telefone == "") {
                return { campo: "telefone", msg: "Telefone Inválido. Digite os números corretos." };
            }
            if (vm.prova.telefone.substring(0, 1) == '0') {
                return { campo: "telefone", msg: "Telefone Inválido. Não pode iniciar com 0." };
            }
            if (vm.prova.telefone.length < 11) {
                return { campo: "telefone", msg: "Telefone incompleto, insira 11 dígitos." };
            }
            if (vm.prova.senha == null || vm.prova.senha == "") {
                return { campo: "senha", msg: "este campo deve ser preenchido" };
            }
            return "OK";

        }

        /* EVITA QUE O USUÁRIO COPIE OU COLE O TEXTO NA REDAÇÃO; */
        $(document).ready(function() {

            $("#textoProva").bind('paste', function(e) {
                e.preventDefault();
            });

        });


    }
})();