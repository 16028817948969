(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('RematriculaPapFinalDesistController', RematriculaPapFinalDesistController);

    RematriculaPapFinalDesistController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'LoginService', '$http', '$sessionStorage', 'RematriculaPapService', 'RematriculaSaveParcelasService', 'RematriculaDadosCadastraisServiceController'];

    function RematriculaPapFinalDesistController($timeout, $scope, $state, $stateParams, LoginService, $http, $sessionStorage, RematriculaPapService, RematriculaSaveParcelasService, RematriculaDadosCadastraisServiceController) {
        var vm = this;

        vm.academico = JSON.parse(sessionStorage.getItem('dados-academico'));

        sessionStorage.removeItem('dados-academico');
        sessionStorage.removeItem('disciplinas-academico');
    }
})();