(function () {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('GetAllRelatoriosService', GetAllRelatoriosService);

    GetAllRelatoriosService.$inject = ['$resource', 'DateUtils'];

    function GetAllRelatoriosService($resource, DateUtils) {
        var resourceUrl = 'api/relatorios-cpe/:limit';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: true }
        });
    }    
})();