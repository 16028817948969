(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlanoEnsinoDisciplinaDeleteController',PlanoEnsinoDisciplinaDeleteController);

    PlanoEnsinoDisciplinaDeleteController.$inject = ['$uibModalInstance', 'entity', 'PlanoEnsinoDisciplina'];

    function PlanoEnsinoDisciplinaDeleteController($uibModalInstance, entity, PlanoEnsinoDisciplina) {
        var vm = this;

        vm.planoEnsinoDisciplina = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PlanoEnsinoDisciplina.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
