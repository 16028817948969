(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('AprovaPropostaPendente', AprovaPropostaPendente);

    AprovaPropostaPendente.$inject = ['$resource', 'DateUtils'];

    function AprovaPropostaPendente($resource, DateUtils) {
        var resourceUrl = 'api/aprova-proposta-matric-dp';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: false }
        });
    }
})();