(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('FichaInscricaoController', FichaInscricaoController);

    FichaInscricaoController.$inject = ['$scope'];


    function FichaInscricaoController($scope) {
        var vm = this;
        vm.candidato = JSON.parse(sessionStorage.getItem('candidato'));
        sessionStorage.removeItem('candidato');

        vm.close = function() {}

        vm.imprimir = imprimir;


        function imprimir() {
            window.print();
        }


        vm.isEmpty = function isEmptyObject(obj) {
            console.log(obj);
            var name;
            for (name in obj) {
                return obj;
            }
            return 'Não - Possui';
        }


        vm.corrige = function corrige() {

            if (vm.candidato.telefone.length == 10) {

                vm.candidato.telefone = format10(vm.candidato.telefone);
            } else {
                // Com o nono digito
                if (vm.candidato.telefone.length == 11) {
                    vm.candidato.telefone = format11(vm.candidato.telefone);
                } else {
                    // num faz nada.. =/
                }
            }
        }

        vm.corrige();

        function format10(telefone) {
            vm.thisTelephone = "(" + telefone.substring(0, 2) + ") ";
            vm.thisTelephone += telefone.substring(2, 6) + " - ";
            vm.thisTelephone += telefone.substring(6, 10);

            return vm.thisTelephone;
        }

        function format11(telefone) {
            vm.thisTelephone = "(" + telefone.substring(0, 2) + ") ";
            vm.thisTelephone += telefone.substring(2, 7) + " - ";
            vm.thisTelephone += telefone.substring(7, 11);

            return vm.thisTelephone;
        }
    };
})();