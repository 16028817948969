(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaDiplomaContratoController', MatriculaDiplomaContratoController);

    MatriculaDiplomaContratoController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$http', '$sessionStorage', 'MatriculaDiplomaService', 'ConsultaContratoIdPerlet'];

    function MatriculaDiplomaContratoController($timeout, $scope, $state, $stateParams, $http, $sessionStorage, MatriculaDiplomaService, ConsultaContratoIdPerlet) {
        var vm = this;

        vm.avancar = avancar;
        vm.matricula = JSON.parse(sessionStorage.getItem('user-matric-diploma'));

        vm.load = false;
        vm.presencial = false;
        vm.aceitei = false;


        vm.ip = JSON.parse(sessionStorage.getItem('ip-user-vestibular'));
        console.log("IP ATUAL:" + vm.ip);
        setTimeout(getIpUser(), 1000);

        function getIpUser() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                }, function(status) {
                    return status;
                });
            }
        };

        vm.getIp = function() {
            if (vm.ip == null) {
                $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function(json) {
                    sessionStorage.setItem('ip-user-vestibular', JSON.stringify(json.ip));
                    vm.ip = json.ip;
                    avancar();
                }, function(status) {
                    return status;
                });
            } else {
                avancar();
            }
        }

        function avancar() {
            vm.load = true;
            vm.matricula.academico.etapa = "CONTRATO";
            vm.matricula.academico.dataContrato = new Date();
            vm.matricula.academico.presencial = vm.presencial;
            vm.matricula.academico.ip = vm.ip;
            vm.matricula.academico.ipAluno = vm.ip;
            console.log(vm.matricula);
            sessionStorage.setItem('user-matric-diploma', JSON.stringify(vm.matricula));

            if (vm.matricula.tipoIngresso == "9") {
                if (vm.habilitado == "OPEN") {
                    vm.load = false;
                    $state.go('matriculaDiplomaTermo');
                } else {
                    save();
                }
            } else {
                save();
            }
        }

        function save() {
            MatriculaDiplomaService.save(
                vm.matricula,
                function(data) {
                    vm.load = false;
                    sessionStorage.setItem('user-matric-diploma', JSON.stringify(data));
                    $state.go('matriculaDiplomaBoleto');
                },
                function(status) {
                    vm.load = false;
                    console.log(status);
                }
            );
        }

        $http.post("/api/get-contrato-pre-matricula", vm.matricula.academico)
            .success(function(data) {
                vm.clausulas = data;
            })
            .error(function(status) {
                console.log(status);
            });

        // validacao de tipo ingresso
        var today = new Date();
        var inicio = new Date('2021', '06', '19', '00', '00', '0000');
        var fim = new Date('2021', '07', '12', '23', '59', '0000');

        console.log(inicio);
        console.log(fim);

        if (today < inicio) {
            vm.habilitado = "BEFORE";
        } else {
            if (today >= fim) {
                vm.habilitado = "AFTER";
            } else {
                vm.habilitado = "OPEN";
            }
        }
    }
})();