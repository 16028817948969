(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('InscricaoLoginAdmService', InscricaoLoginAdmService);

    InscricaoLoginAdmService.$inject = ['$resource', 'DateUtils'];

    function InscricaoLoginAdmService($resource, DateUtils) {
        var resourceUrl = 'api/inscricaos/login/:login/:idps';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
        });
    }
})();