(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('DataPolo', DataPolo);

    DataPolo.$inject = ['$resource'];

    function DataPolo($resource) {
        var resourceUrl = 'api/data-polos/:idPolo';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
        });
    }
})();