(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ProcessoSeletivoDetailController', ProcessoSeletivoDetailController);

    ProcessoSeletivoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProcessoSeletivo'];

    function ProcessoSeletivoDetailController($scope, $rootScope, $stateParams, previousState, entity, ProcessoSeletivo) {
        var vm = this;

        vm.processoSeletivo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:processoSeletivoUpdate', function(event, result) {
            vm.processoSeletivo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
