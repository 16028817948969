(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('ConfirmacaoSugestaoController', ConfirmacaoSugestaoController);

    ConfirmacaoSugestaoController.$inject = ['$timeout', 'DateUtils', '$scope', '$state', '$stateParams', '$sessionStorage'];

    function ConfirmacaoSugestaoController($timeout, DateUtils, $scope, $state, $stateParams, $sessionStorage) {
        var vm = this;

        vm.redirect = redirect;

        function redirect(value) {
            $state.go(value);
        }
    }
})();