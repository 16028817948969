(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('ConsultaRangePoloHab', ConsultaRangePoloHab);

    ConsultaRangePoloHab.$inject = ['$resource', 'DateUtils'];

    function ConsultaRangePoloHab($resource, DateUtils) {
        var resourceUrl = 'api/horario-disp-polos-hab/:idPolo';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();