(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('MatriculaOnlinePlanoPagamentoController', MatriculaOnlinePlanoPagamentoController);

    MatriculaOnlinePlanoPagamentoController.$inject = ['$state', 'MatriculaOnlineServiceController', '$http', 'BuscaOpcoesPlanoPagamento'];

    function MatriculaOnlinePlanoPagamentoController($state, MatriculaOnlineServiceController, $http, BuscaOpcoesPlanoPagamento) {
        var vm = this;
        vm.save = save;
        vm.promoTemp = false;

        init();

        function init() {
            vm.load = true;
            vm.aceitei = false;
            loadDadosCandidato();
        }

        function loadDadosCandidato() {
            vm.resultadoRM = JSON.parse(sessionStorage.getItem('cod-pessoa'));
            $http.post("/api/inscricao-matricula-tradicionals-by-codPessoa", vm.resultadoRM)
                .success(function(data) {
                    vm.matricula = data;

                    switch (vm.matricula.etapa) {
                        case 'INICIO':
                            $state.go('new-matricula-inicio');
                            break;

                        case 'CANDIDATO':
                            $state.go('new-matricula-complemento');
                            break;

                        case 'COMPLEMENTO':
                            $state.go('new-matricula-filiacao');
                            break;

                        case 'PAIS':
                            $state.go('new-matricula-consentimento');
                            break;

                            // case 'TERMOCONSENTIMENTO':
                            //     $state.go('new-matricula-plano-pagamento');
                            //     break;

                        case 'PLANOPAGAMENTO':
                            $state.go('new-matricula-contrato');
                            break;
                        case 'CONTRATO':
                            $state.go('new-matricula-boleto');
                            break;

                        default:
                            console.log(vm.matricula);
                            var today = new Date();
                            var iPalo = new Date('2021', '03', '07', '01', '00', '0000');
                            var fPalo = new Date('2021', '03', '09', '23', '59', '0000');
                            var iAss = new Date('2021', '03', '14', '01', '00', '0000');
                            var fAss = new Date('2021', '03', '16', '23', '59', '0000');



                            if (vm.matricula.polo == 13 && today >= iAss && today <= fAss) {
                                vm.promoTemp = true;
                            }

                            if (vm.matricula.polo == 3 && today >= iPalo && today <= fPalo) {
                                vm.promoTemp = true;
                            }

                            BuscaOpcoesPlanoPagamento.send(vm.matricula,
                                function(data) {
                                    vm.planos = data;
                                    vm.load = false;
                                },
                                function(status) {
                                    console.log(status);
                                });
                            break;
                    }
                })
                .error(function(status) {
                    console.log(status);
                });
        }

        function save(item) {
            vm.matricula.etapa = "PLANOPAGAMENTO";
            vm.matricula.codPlanoPgto = item.codPlanoPagamento;
            sessionStorage.setItem('plano-pagamento', JSON.stringify(item));
            MatriculaOnlineServiceController.save(vm.matricula, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.load = false;
            vm.matricula = result;
            sessionStorage.setItem('cod-pessoa', JSON.stringify(vm.resultadoRM));
            $state.go('new-matricula-contrato');
        }

        function onSaveError(result) {
            vm.load = false;
            console.log(result);
        }
    }
})();