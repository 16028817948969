(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('ConsultaInscricaoTradicionalAluno', ConsultaInscricaoTradicionalAluno);

    ConsultaInscricaoTradicionalAluno.$inject = ['$resource', 'DateUtils'];

    function ConsultaInscricaoTradicionalAluno($resource, DateUtils) {
        var resourceUrl = 'api/consulta-inscricao-tradicional-aluno/:param/:param2';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();