(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('LeadsController', LeadsController);

    LeadsController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'LeadsPainelService', 'PrevVendedorPainelService', 'NextVendedorPainelService'];

    function LeadsController($timeout, $scope, $state, $stateParams, LeadsPainelService, PrevVendedorPainelService, NextVendedorPainelService) {
        var vm = this;

        /*loadData();

        vm.voltarVendedor = voltarVendedor;
        vm.avancarVendedor = avancarVendedor
        vm.reload = loadData;

        function avancarVendedor(cmp) {
            alert("Avançar vendedor de : " + cmp);

            NextVendedorPainelService.query({
                campanha: cmp
            }, function(data) {
                console.log(data);
                loadData();
            }, function(status) {
                console.log(status);
            });
        }

        function voltarVendedor(cmp) {
            alert("Voltar vendedor de : " + cmp);
            PrevVendedorPainelService.query({
                campanha: cmp
            }, function(data) {
                console.log(data);
                loadData();
            }, function(status) {
                console.log(status);
            });
        }




        function loadData() {
            vm.data = LeadsPainelService.query();
            console.log(vm.data);
        }
        */
    }
})();