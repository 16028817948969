(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PlayFotoDetailController', PlayFotoDetailController);

    PlayFotoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'PlayFoto'];

    function PlayFotoDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, PlayFoto) {
        var vm = this;

        vm.playFoto = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('vestibularApp:playFotoUpdate', function(event, result) {
            vm.playFoto = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
