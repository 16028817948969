(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('UpdatePPESSOAService', UpdatePPESSOAService);

    UpdatePPESSOAService.$inject = ['$resource', 'DateUtils'];

    function UpdatePPESSOAService($resource, DateUtils) {
        var resourceUrl = 'api/rematricula-update-ppessoa';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: false }
        });
    }
})();