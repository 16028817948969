(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoAgendadoUserController', InscricaoAgendadoUserController);

    InscricaoAgendadoUserController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'LinkVendedorService',
        'InscricaoAgendadoUser', 'CursosPorPolo', '$http', '$sessionStorage', 'IndicacaoService', 'PolosAtivos',
        'DatasPorPolo', 'ConsultaHorarioPolo', 'ValidaInscricao', 'LoginService', 'ValidaHorariosRM', 'ProvaOnline', 'ConsultaRangePoloHab', 'PSAtivosAgendado', 'CursosAgendadoGeral', 'ProcessoSeletivo'
    ];

    function InscricaoAgendadoUserController($timeout, $scope, $rootScope, $state, $stateParams, LinkVendedorService,
        InscricaoAgendadoUser, CursosPorPolo, $http, $sessionStorage, IndicacaoService, PolosAtivos,
        DatasPorPolo, ConsultaHorarioPolo, ValidaInscricao, LoginService, ValidaHorariosRM, ProvaOnline, ConsultaRangePoloHab, PSAtivosAgendado, CursosAgendadoGeral, ProcessoSeletivo) {
        var vm = this;

        //$state.go('home');

        // functions
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.isSaturday = saturday;
        vm.openIndicacao = openIndicacao;
        //vm.buscaCursos = buscaCursos;
        vm.login = login;


        // variables
        vm.inscricaoAgendado = {};

        /*vm.inscricaoAgendado.nome = 'MAURICIO OURIQUES TONETO';
        vm.inscricaoAgendado.cpf = '08726252902';
        vm.inscricaoAgendado.telefone = '45999023808';
        vm.inscricaoAgendado.dataNascimento = new Date(1997, 0, 12);
        vm.inscricaoAgendado.email = 'mauricio.toneto@univel.br';
        vm.inscricaoAgendado.formaIngresso = 1;*/

        vm.cursoSelecionado = {};
        vm.inscricaoAgendado.possuiNecessidadesEspeciais = 'NAO';
        vm.inscricaoAgendado.possuiIndicacao = 'NAO';
        vm.inscricaoAgendado.provaOnline = 'NAO';
        vm.inscricaoAgendado.interno = false;
        vm.inscricaoAgendado.anoEnem = 0;
        vm.inscricaoAgendado.enem = 0;

        vm.maxDate = new Date(2018, 8, 28);
        vm.poloEad = false;
        vm.interno = false;
        vm.poloEscolhido = null;

        vm.formasIngresso = [
            { id: 1, nome: 'Vestibular Online' },
            { id: 2, nome: 'Vestibular Agendado' },
            { id: 3, nome: 'ENEM' },
            { id: 4, nome: 'Transferência' },
            { id: 5, nome: 'Segunda Graduação' }
        ];

        vm.anosEnem = [
            { ano: 2023 },
            { ano: 2022 },
            { ano: 2021 },
            { ano: 2020 },
            { ano: 2019 }
        ];

        vm.cursos = CursosAgendadoGeral.query();

        //console.log(vm.cursos);

        /*PSAtivosAgendado.query({ agendado: true }, function(result) {
            vm.ps = result;
        }, function(status) {
            console.log(status);
        });*/

        vm.polos = PolosAtivos.query({
            page: 0,
            size: 50
        });

        //vm.isSaving = true;

        if (location.href.includes('?')) {

            var query = location.href.slice(1);
            var partes = query.split('?');
            var chaveValor = partes[1].split('=');
            var valor = chaveValor[1];
            setaVendedorLink(valor);
        } else {
            setaVendedorLink();
        }

        // controle de etapass
        vm.etapa = 1;
        vm.mandaPost = true;
        //vm.mandaPost = false;

        vm.datePickerOpenStatus = {};
        vm.recebeu_indicacao_texto = null;
        vm.necessidades_especiais_texto = null;

        function openIndicacao() {
            IndicacaoService.open(vm.inscricaoAgendado.possuiIndicacao);
        }

        $rootScope.$on('selecionou', function(event, value) {
            if (value != null && value != undefined) {
                vm.inscricaoAgendado.codPessoaIndicacao = value.codPessoa;
                vm.inscricaoAgendado.indicacaoTexto = value.nome;
                vm.inscricaoAgendado.possuiIndicacao = value.possuiIndicacao;
            } else {
                vm.inscricaoAgendado.possuiIndicacao = "NAO";
                vm.inscricaoAgendado.indicacaoTexto = null;
                vm.inscricaoAgendado.codPessoaIndicacao = null;
            }
        });

        /*
                CONTROLE DE DATA
        */

        function areDatesEqual(date1, date2) {
            return date1 == date2.toLocaleDateString()
        }

        function saturday() {
            showMessage('invi', '');
            vm.horariosProva = [];
            var data = vm.inscricaoAgendado.dataProva.toString();

            var validateIE = validaDataInternetExplorer(vm.inscricaoAgendado.dataProva);

            if (!validateIE) {
                showMessage('alert-warning', 'Este polo não terá atendimento nesta data.');
            } else {

                if (validaRecesso()) {
                    showMessage('alert-warning', 'Nesta data a Univel estará em recesso, agendamentos disponíveis a partir de 05/01/2022, ou então pode realizar o vestibular online.');
                } else {
                    // VALIDANDO SE É DOMINGO
                    if (data.substring(0, 3) == "Sun") {
                        showMessage('alert-warning', 'Desculpe, Não existe expediente aos domingos.');
                    } else {
                        /*VALIDANDO SE É POLO OU MATRIZ*/
                        if (vm.poloEad) {
                            //É POLO, VERIFICANDO SE É FINAL DE SEMANA
                            if (data.substring(0, 3) == "Sat") {
                                //COMO É FINAL DE SEMANA NÃO TEMOS ATENDIMENTO.
                                showMessage('alert-warning', 'Os polos parceiros não possuem atendimento no final de semana.');
                            } else {
                                if (vm.inscricaoAgendado.dataProva > vm.PSEscolhido.dataFinalCompare) {
                                    showMessage('alert-warning', formatDate(vm.PSEscolhido.dataFinal) + " é a data limite para " + vm.PSEscolhido.nome);
                                } else {
                                    if (validaHorarioEspecial()) {
                                        popula('ESPECIAL');
                                    } else {
                                        popula('NORMAL');
                                    }
                                }
                            }
                        } else {
                            if (vm.inscricaoAgendado.dataProva > vm.PSEscolhido.dataFinalCompare) {
                                showMessage('alert-warning', formatDate(vm.PSEscolhido.dataFinal) + " é a data limite para " + vm.PSEscolhido.nome);
                            } else {
                                if (data.substring(0, 3) == "Sat") {
                                    popula('SABADO');
                                } else {
                                    if (validaHorarioEspecial()) {
                                        popula('ESPECIAL');
                                    } else {
                                        popula('NORMAL');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        function validaRecesso() {
            if ((vm.inscricaoAgendado.dataProva >= new Date(2023, 11, 20)) && (vm.inscricaoAgendado.dataProva <= new Date(2024, 0, 3))) {
                return true;
            } else {
                return false;
            }
        }

        function validaHorarioEspecial() {
            if ((vm.inscricaoAgendado.dataProva >= new Date(2024, 0, 4)) && (vm.inscricaoAgendado.dataProva <= new Date(2024, 1, 16))) {
                return true;
            } else {
                return false;
            }
        }

        function popula(situacao) {
            ValidaHorariosRM.query({ dataProva: vm.inscricaoAgendado.dataProva, idps: vm.inscricaoAgendado.idps });

            if (situacao == 'NORMAL') {
                for (var i = 0; i < vm.horariosDefault.length; i++) {
                    var element = vm.horariosDefault[i];
                    if ((element.idHorario > 7 && element.idHorario < 12) || (element.idHorario > 13 && element.idHorario < 22)) {
                        vm.horariosProva.push(element);
                    }
                }
            }

            if (situacao == 'SABADO') {
                for (var i = 0; i < vm.horariosDefault.length; i++) {
                    var element = vm.horariosDefault[i];
                    if ((element.idHorario > 7 && element.idHorario < 12)) {
                        vm.horariosProva.push(element);
                    }
                }
            }

            if (situacao == 'ESPECIAL') {
                for (var i = 0; i < vm.horariosDefault.length; i++) {
                    var element = vm.horariosDefault[i];
                    if ((element.idHorario > 7 && element.idHorario < 12) || (element.idHorario > 13 && element.idHorario < 20)) {
                        vm.horariosProva.push(element);
                    }
                }
            }

            consultaBloqueados();
        }

        function consultaBloqueados() {
            var dt = vm.inscricaoAgendado.dataProva;
            var dy = dt.getDate();
            var ms = dt.getMonth() + 1;
            var yr = dt.getFullYear();

            if (ms < 10) {
                ms = '0' + ms;
            }

            if (dy < 10) {
                dy = '0' + dy;
            }

            var dia = yr + '-' + ms + '-' + dy;
            ConsultaHorarioPolo.query({ idPolo: vm.poloEscolhido.id, dia: dia },
                function(result) {
                    vm.horariosBloqueados = result;
                    for (var j = 0; j < vm.horariosBloqueados.length; j++) {
                        var bl = vm.horariosBloqueados[j];
                        for (var k = 0; k < vm.horariosProva.length; k++) {
                            var hp = vm.horariosProva[k];
                            if (bl.hora == hp.idHorario) {
                                vm.horariosProva.splice(k, 1);
                            }
                        };
                    };
                });
        }

        function validaDataInternetExplorer(d) {
            var dy2 = d.getDate();
            var ms2 = d.getMonth() + 1;
            var yr2 = d.getFullYear();

            if (ms2 < 10) {
                ms2 = '0' + ms2;
            }

            if (dy2 < 10) {
                dy2 = '0' + dy2;
            }

            var dia2 = dy2 + '/' + ms2 + '/' + yr2;

            for (var i = 0; i < vm.datas.length; i++) {
                if (vm.datas[i] === dia2) {
                    return false;
                }
            }
            return true;
        }

        vm.fecharAlert = fecharAlert;

        function showMessage(css, msg) {
            var x = document.getElementById("erro-show-message");
            var y = document.getElementById("fecharAlert");
            var message = '<div class="alert ' + css + '">' + msg + '</div>';
            x.innerHTML = message;
            if (css == 'invi') {
                y.style.display = "none";
            } else {
                y.style.display = "block";
            }
        }

        function fecharAlert() {
            showMessage('invi', '');
        }

        function getMinDate() {
            var atual = new Date();
            var matricDate = new Date(2019, 9, 7);

            if (atual > matricDate) {
                var minDateAt = new Date(atual.getFullYear(), atual.getMonth(), atual.getDate());
            } else {
                var minDateAt = matricDate;
            }

            return minDateAt;
        }

        vm.dateOptions = {
            minDate: getMinDate(),
            dateDisabled: myDisabledDates,
            showWeeks: false,
            showButtonPanel: false
        }

        function myDisabledDates(dateAndMode) {
            var date = dateAndMode.date,
                mode = dateAndMode.mode;
            var isRealDate = false;

            if (vm.datas == undefined) {
                showMessage('alert-danger', 'Por favor, selecione um Polo antes de selecionar a Data.');
            }

            if (vm.inscricaoAgendado.curso == null) {
                showMessage('alert-danger', 'Por favor, selecione um Curso antes de selecionar a Data.');
            }
            for (var i = 0; i < vm.datas.length; i++) {
                if (areDatesEqual(vm.datas[i], date)) {
                    isRealDate = true;
                }
            }

            return ((dateAndMode.mode === 'day' && (dateAndMode.date.getDay() === 0)) || isRealDate);
        }

        function disabled(date, mode) {
            return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
        };

        vm.datePickerOpenStatus.dataProva = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.keyup = function(keyEvent) {
            if (keyEvent.ctrlKey && keyEvent.keyCode == 73) {
                vm.interno = !vm.interno;
                vm.inscricaoAgendado.interno = vm.interno;
            }
        };

        vm.avancaEtapa = function(etapa) {

            var v = verificaCampos(etapa);

            if (v == 'OK') {
                if (etapa == 1) {
                    vm.etapa = 1;
                }
                if (etapa == 2) {

                    ValidaInscricao.query({ email: vm.inscricaoAgendado.email, cpf: vm.inscricaoAgendado.cpf },
                        function(result) {
                            vm.inscricaoValidada = result;
                            //console.log(vm.inscricaoValidada);
                            if (vm.inscricaoValidada.existe == true) {
                                if (vm.inscricaoValidada.pendente == true) {
                                    if (vm.inscricaoValidada.email == true) {
                                        showMessage('alert-info', "Identificamos uma inscricão com este email, favor acesse o Portal do Candidato para verificar.");
                                    } else {
                                        showMessage('alert-info', "Identificamos uma inscricão com este CPF, por favor entre em contato para mais informações - 45 3036 3664");
                                    }
                                } else {
                                    vm.inscricaoAgendado.id = vm.inscricaoValidada.idInsc;
                                    sendPostRdInteresse();
                                    //console.log('3');
                                    if (vm.ead == true) {
                                        vm.etapa = 2;
                                    } else {
                                        vm.poloEscolhido = {};
                                        vm.poloEscolhido.id = 1;
                                        vm.poloEscolhido.cidade = 'Cascavel'
                                        vm.inscricaoAgendado.polo = vm.poloEscolhido;

                                        vm.datas = DatasPorPolo.query({
                                            id: vm.poloEscolhido.id
                                        });

                                        vm.horariosDefault = ConsultaRangePoloHab.query({
                                            idPolo: vm.poloEscolhido.id
                                        });

                                        //console.log(vm.poloEscolhido);

                                        vm.etapa = 3;
                                    }
                                }
                            } else {
                                sendPostRdInteresse();
                                if (vm.ead == true) {
                                    vm.etapa = 2;
                                } else {
                                    vm.poloEscolhido = {};
                                    vm.poloEscolhido.id = 1;
                                    vm.poloEscolhido.cidade = 'Cascavel'
                                    vm.inscricaoAgendado.polo = vm.poloEscolhido;

                                    vm.datas = DatasPorPolo.query({
                                        id: vm.poloEscolhido.id
                                    });

                                    vm.horariosDefault = ConsultaRangePoloHab.query({
                                        idPolo: vm.poloEscolhido.id
                                    });

                                    //console.log(vm.poloEscolhido);

                                    vm.etapa = 3;
                                }
                            }
                        },
                        function(status) {
                            console.log(status);
                        });

                }

                if (etapa == 3) {
                    vm.etapa = 3;
                }

                if (etapa == 4) {
                    save();
                }

            } else {
                showMessage('alert alert-danger', v);
            }
        }

        function sendPostRdInteresse() {
            //console.log(vm.inscricaoAgendado);

            var a = vm.inscricaoAgendado;
            a.provaOnline = false;
            if (vm.mandaPost == true) {
                $http.post("/api/post-rd-interesse-agendado", a);
            }
        }

        vm.alterInfo = function(op, info) {

            if (op === 1) {
                vm.poloEscolhido = info;
                vm.inscricaoAgendado.polo = vm.poloEscolhido;

                vm.datas = DatasPorPolo.query({
                    id: vm.poloEscolhido.id
                });

                vm.horariosDefault = ConsultaRangePoloHab.query({
                    idPolo: vm.poloEscolhido.id
                });
            }

            if (op === 2) {
                vm.inscricaoAgendado.horaProva = info.idHorario;
            }

            if (op === 3) {
                vm.cursoSelecionado.id = info.id;
                vm.cursoSelecionado.nome = info.nome;
                vm.inscricaoAgendado.curso = vm.cursoSelecionado;
                vm.inscricaoAgendado.idps = info.idps;
                vm.ead = info.ead;

                //console.log(info.idProcessoMysql);
                ProcessoSeletivo.get({ id: info.idProcessoMysql },
                    function(result) {
                        vm.PSEscolhido = result;
                        vm.PSEscolhido.dataFinalCompare = vm.PSEscolhido.dataFinal;
                        console.log(vm.PSEscolhido);
                    });
                //buscaCursos();
            }

            if (op === 5) {
                vm.formaIngressoEscolhida = info;
                vm.inscricaoAgendado.formaIngresso = vm.formaIngressoEscolhida.nome;

                if (vm.formaIngressoEscolhida.id !== 2) {
                    //console.log('a');
                    vm.provaOnlineValidador = 'SIM';
                    //vm.inscricaoAgendado.provaOnline = 'SIM';
                } else {
                    //console.log('B');
                    vm.provaOnlineValidador = 'NAO';
                    //vm.inscricaoAgendado.provaOnline = 'NAO';
                }

                //console.log(vm.formaIngressoEscolhida);
                //console.log(vm.provaOnlineValidador);
            }
        }

        function verificaCampos(etapa) {

            if (etapa == 2) {
                // VALIDA SE O NOME ESTA VAZIO -- JA TEM O REQUIRED, MAS VALIDAÇÃO NUNCA É DEMAIS
                if (vm.inscricaoAgendado.nome == null || vm.inscricaoAgendado.nome == "") {
                    return "O campo NOME não pode estar vazio.";
                }
                // VALIDA SE O NOME TEM PELO MENOS DOIS CARACTERES E PELO MENOS UM ESPAÇO
                if (vm.inscricaoAgendado.nome.indexOf(" ") == -1) {
                    return "Por favor, insira seu NOME COMPLETO. Ex: FULANO SILVA";
                }

                if (vm.inscricaoAgendado.nome.length <= 3) {
                    return "Por favor, insira seu NOME COMPLETO. Ex: FULANO SILVA";
                }

                if (vm.inscricaoAgendado.cpf == null || vm.inscricaoAgendado.cpf == "") {
                    return "O CPF informado é inválido.";
                }
                if (vm.inscricaoAgendado.cpf.length < 11) {
                    return "O campo CPF deve ter ao menos 11 dígitos";
                }

                if (vm.inscricaoAgendado.telefone == null || vm.inscricaoAgendado.telefone == "") {
                    return "O campo CELULAR não pode ser vazio";
                }
                if (vm.inscricaoAgendado.telefone.length < 11) {
                    return "O campo CELULAR deve ter ao menos 11 dígitos";
                }

                if (vm.inscricaoAgendado.telefone.substring(0, 1) == '0') {
                    return "O campo CELULAR não pode iniciar com 0";
                }

                if (vm.inscricaoAgendado.email == null || vm.inscricaoAgendado.email == "") {
                    return "O campo EMAIL não pode ser vazio";
                }

                if (!vm.inscricaoAgendado.email.indexOf("@")) {
                    return "O campo EMAIL não está correto";
                }

                if (vm.inscricaoAgendado.dataNascimento == null || vm.inscricaoAgendado.dataNascimento == "") {
                    return "O campo Data de Nascimento não está correto";
                }

                if (vm.formaIngressoEscolhida == null || vm.formaIngressoEscolhida == "") {
                    return "Por favor, selecione uma FORMA DE INGRESSO";
                }

                if (vm.inscricaoAgendado.curso == null) {
                    return "Por favor, selecione o curso que deseja na lista";
                }
                vm.inscricaoAgendado.nome = vm.inscricaoAgendado.nome.toUpperCase();
                vm.inscricaoAgendado.email = vm.inscricaoAgendado.email.toUpperCase();
            }

            if (etapa == 3) {

                if (vm.ead == true) {
                    if (vm.poloEscolhido == null) {
                        return "Por favor selecione em qual polo deseja realizar a prova";
                    }
                }

            }

            if (etapa == 4) {
                if (vm.provaOnlineValidador == 'NAO') {
                    if (vm.inscricaoAgendado.dataProva == null) {
                        return "Por favor informe a data que deseja realizar a prova";
                    }

                    if (vm.inscricaoAgendado.horaProva == null) {
                        return "Por favor selecione qual horária deseja realizar a prova";
                    }
                }

                if (vm.inscricaoAgendado.formaIngresso == 'ENEM') {
                    if (vm.inscricaoAgendado.anoEnem == null) {
                        return "Por favor informe o ano que prestou o ENEM.";
                    }

                    /*if (vm.inscricaoAgendado.enem == 0) {
                        return "Por favor informe a nota geral do ENEM.";
                    }*/
                }
            }

            if (vm.inscricaoAgendado.recebeu_indicacao == undefined) {
                vm.inscricaoAgendado.recebeu_indicacao = 'NAO';
            }
            if (vm.inscricaoAgendado.possuiNecessidadesEspeciais == undefined) {
                vm.inscricaoAgendado.possuiNecessidadesEspeciais = 'NAO';
            }
            if (vm.inscricaoAgendado.necessidadesEspeciaisTexto != null) {
                vm.inscricaoAgendado.necessidadesEspeciaisTexto = vm.inscricaoAgendado.necessidadesEspeciaisTexto.toUpperCase();
            }
            if (vm.inscricaoAgendado.indicacaoTexto != null) {
                vm.inscricaoAgendado.indicacaoTexto = vm.inscricaoAgendado.indicacaoTexto.toUpperCase();
            }

            return "OK";
        };

        function save() {
            showMessage('invi', '');
            var apto = false;

            if (vm.inscricaoAgendado.possuiNecessidadesEspeciais == 'SIM') {
                if (vm.inscricaoAgendado.necessidadesEspeciaisTexto == null) {
                    showMessage('alert alert-danger', "Por favor informe qual sua necessidade especial.");
                } else {
                    apto = true;
                }
            } else {
                apto = true;
            }


            if (apto) {
                /*  COMPLEMENTO PARA PROVA ONLINE  */

                if (vm.formaIngressoEscolhida.id != 2) {
                    vm.inscricaoAgendado.dataProva = new Date();

                    var a = vm.inscricaoAgendado.dataProva.getHours();

                    if (a <= 9) {
                        vm.inscricaoAgendado.horaProva = 'H0' + a;
                    } else {
                        vm.inscricaoAgendado.horaProva = 'H' + a;
                    }

                    vm.inscricaoAgendado.provaOnline = true;
                } else {
                    vm.inscricaoAgendado.provaOnline = false;
                }

                vm.inscricaoAgendado.inscricaoPlay = false;

                //vm.inscricaoAgendado.formaIngresso = vm.formaIngressoEscolhida.nome;

                vm.isSaving = true;

                console.log(vm.inscricaoAgendado);
                sessionStorage.setItem('candidato-vestibular', JSON.stringify(vm.inscricaoAgendado));

                if (vm.inscricaoAgendado.id != null) {
                    InscricaoAgendadoUser.update(vm.inscricaoAgendado, onSaveSuccess, onSaveError);
                } else {
                    InscricaoAgendadoUser.save(vm.inscricaoAgendado, onSaveSuccess, onSaveError);
                }
            }

        }

        function onSaveSuccess(result) {

            var prova = {
                id: null,
                inscricao: result.id,
                email: result.email,
                telefone: result.telefone,
                cpf: result.cpf,
                nota: 0,
                situacao: 'ANONIMA',
                nome: result.nome,
                tempo: 3600,
                questao: 1,
                tempoObj: 0,
                situacaoObj: 'ANONIMA',
                notaObj: 0,
                tipo: null,
                notaGeral: 0,
                idps: result.idps,
                senha: result.cpf
            }

            if (result.provaOnline == true) {
                prova.tipo = 'AGENDADO';
            } else {
                prova.tipo = 'PRESENCIAL';
            }

            ProvaOnline.save(prova, function(r2) {
                console.log(r2);
            }, function(status) {
                console.log(status);
            });

            $state.go('confirmacao-inscricao-agendado');
            vm.isSaving = false;
        }

        function onSaveError(error) {
            console.log(error);
            showMessage('alert alert-info', 'Desculpe, aconteceu algo de errado na sua inscrição, entre em contato pelos telefones no final da página para solucionar');
            vm.isSaving = false;
        }

        function login() {
            LoginService.open();
            $state.go('painelAdministrativo');
        }

        function setaVendedorLink(valor) {
            //console.log(valor);
            if (valor != null && valor != undefined) {
                vm.interno = true;
                vm.vendLink = true;
                LinkVendedorService.query({ codigo: valor }, function(result) {
                    //console.log(result);
                    vm.inscricaoAgendado.possuiIndicacao = result.tipo;
                    vm.inscricaoAgendado.codPessoaIndicacao = result.codigo;
                    vm.inscricaoAgendado.indicacaoTexto = result.nome;
                    vm.vendedorLink = result.link;
                    vm.vendedorNumero = result.numero;
                    vm.vendedorEmail = result.email;

                });
            } else {
                vm.interno = false;
                vm.vendLink = false;
                vm.inscricaoAgendado.possuiIndicacao = 'NAO';
                vm.inscricaoAgendado.codPessoaIndicacao = '';
                vm.inscricaoAgendado.indicacaoTexto = '';
                vm.vendedorLink = 'https://wa.me/5545988429444';
                vm.vendedorNumero = "45 98842-9444";
                vm.vendedorEmail = "vestibular@univel.br";
            }
        }

        function formatDate(dateString) {
            var dia = dateString.getDate();
            var mes = dateString.getMonth();
            var ano = dateString.getFullYear();
            var last = ano + '-' + (mes + 1) + '-' + dia;
            var thisDate = last.split('-');
            var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
            return newDate;
        }
    }
})();