(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('FechaSalaService', FechaSalaService);

    FechaSalaService.$inject = ['$resource', 'DateUtils'];

    function FechaSalaService($resource, DateUtils) {
        var resourceUrl = 'api/fechaSalaService/:idSala';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }
})();