(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('FinanceiroDetailController', FinanceiroDetailController);

    FinanceiroDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Financeiro'];

    function FinanceiroDetailController($scope, $rootScope, $stateParams, previousState, entity, Financeiro) {
        var vm = this;

        vm.financeiro = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:financeiroUpdate', function(event, result) {
            vm.financeiro = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
