(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaItensPlanoAula', BuscaItensPlanoAula);

    BuscaItensPlanoAula.$inject = ['$resource', 'DateUtils'];

    function BuscaItensPlanoAula($resource, DateUtils) {
        var resourceUrl = '/api/plano-aula-itens/busca-by-plano/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();