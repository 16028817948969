(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('CidadesMDJsonService', CidadesMDJsonService);

    CidadesMDJsonService.$inject = ['$resource', 'DateUtils'];

    function CidadesMDJsonService() {

        return [{
                "uf": "AC",
                "estado": "Acre",
                "id": "1",
                "cidade": "Acrelândia"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "2",
                "cidade": "Assis Brasil"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "3",
                "cidade": "Brasiléia"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "4",
                "cidade": "Bujari"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "5",
                "cidade": "Capixaba"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "6",
                "cidade": "Cruzeiro do Sul"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "7",
                "cidade": "Epitaciolândia"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "8",
                "cidade": "Feijó"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "9",
                "cidade": "Jordão"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "10",
                "cidade": "Mâncio Lima"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "11",
                "cidade": "Manoel Urbano"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "12",
                "cidade": "Marechal Thaumaturgo"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "13",
                "cidade": "Plácido de Castro"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "14",
                "cidade": "Porto Acre"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "15",
                "cidade": "Porto Walter"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "16",
                "cidade": "Rio Branco"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "17",
                "cidade": "Rodrigues Alves"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "18",
                "cidade": "Santa Rosa do Purus"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "19",
                "cidade": "Sena Madureira"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "20",
                "cidade": "Senador Guiomard"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "21",
                "cidade": "Tarauacá"
            },
            {
                "uf": "AC",
                "estado": "Acre",
                "id": "22",
                "cidade": "Xapuri"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "23",
                "cidade": "Água Branca"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "24",
                "cidade": "Anadia"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "25",
                "cidade": "Arapiraca"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "26",
                "cidade": "Atalaia"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "27",
                "cidade": "Barra de Santo Antônio"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "28",
                "cidade": "Barra de São Miguel"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "29",
                "cidade": "Batalha"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "30",
                "cidade": "Belém"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "31",
                "cidade": "Belo Monte"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "32",
                "cidade": "Boca da Mata"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "33",
                "cidade": "Branquinha"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "34",
                "cidade": "Cacimbinhas"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "35",
                "cidade": "Cajueiro"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "36",
                "cidade": "Campestre"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "37",
                "cidade": "Campo Alegre"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "38",
                "cidade": "Campo Grande"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "39",
                "cidade": "Canapi"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "40",
                "cidade": "Capela"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "41",
                "cidade": "Carneiros"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "42",
                "cidade": "Chã Preta"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "43",
                "cidade": "Coité do Nóia"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "44",
                "cidade": "Colônia Leopoldina"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "45",
                "cidade": "Coqueiro Seco"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "46",
                "cidade": "Coruripe"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "47",
                "cidade": "Craíbas"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "48",
                "cidade": "Delmiro Gouveia"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "49",
                "cidade": "Dois Riachos"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "50",
                "cidade": "Estrela de Alagoas"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "51",
                "cidade": "Feira Grande"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "52",
                "cidade": "Feliz Deserto"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "53",
                "cidade": "Flexeiras"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "54",
                "cidade": "Girau do Ponciano"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "55",
                "cidade": "Ibateguara"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "56",
                "cidade": "Igaci"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "57",
                "cidade": "Igreja Nova"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "58",
                "cidade": "Inhapi"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "59",
                "cidade": "Jacaré dos Homens"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "60",
                "cidade": "Jacuípe"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "61",
                "cidade": "Japaratinga"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "62",
                "cidade": "Jaramataia"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "63",
                "cidade": "Jequiá da Praia"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "64",
                "cidade": "Joaquim Gomes"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "65",
                "cidade": "Jundiá"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "66",
                "cidade": "Junqueiro"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "67",
                "cidade": "Lagoa da Canoa"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "68",
                "cidade": "Limoeiro de Anadia"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "69",
                "cidade": "Maceió"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "70",
                "cidade": "Major Isidoro"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "71",
                "cidade": "Mar Vermelho"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "72",
                "cidade": "Maragogi"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "73",
                "cidade": "Maravilha"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "74",
                "cidade": "Marechal Deodoro"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "75",
                "cidade": "Maribondo"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "76",
                "cidade": "Mata Grande"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "77",
                "cidade": "Matriz de Camaragibe"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "78",
                "cidade": "Messias"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "79",
                "cidade": "Minador do Negrão"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "80",
                "cidade": "Monteirópolis"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "81",
                "cidade": "Murici"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "82",
                "cidade": "Novo Lino"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "83",
                "cidade": "Olho d'Água das Flores"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "84",
                "cidade": "Olho d'Água do Casado"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "85",
                "cidade": "Olho d'Água Grande"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "86",
                "cidade": "Olivença"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "87",
                "cidade": "Ouro Branco"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "88",
                "cidade": "Palestina"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "89",
                "cidade": "Palmeira dos Índios"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "90",
                "cidade": "Pão de Açúcar"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "91",
                "cidade": "Pariconha"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "92",
                "cidade": "Paripueira"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "93",
                "cidade": "Passo de Camaragibe"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "94",
                "cidade": "Paulo Jacinto"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "95",
                "cidade": "Penedo"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "96",
                "cidade": "Piaçabuçu"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "97",
                "cidade": "Pilar"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "98",
                "cidade": "Pindoba"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "99",
                "cidade": "Piranhas"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "100",
                "cidade": "Poço das Trincheiras"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "101",
                "cidade": "Porto Calvo"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "102",
                "cidade": "Porto de Pedras"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "103",
                "cidade": "Porto Real do Colégio"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "104",
                "cidade": "Quebrangulo"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "105",
                "cidade": "Rio Largo"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "106",
                "cidade": "Roteiro"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "107",
                "cidade": "Santa Luzia do Norte"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "108",
                "cidade": "Santana do Ipanema"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "109",
                "cidade": "Santana do Mundaú"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "110",
                "cidade": "São Brás"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "111",
                "cidade": "São José da Laje"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "112",
                "cidade": "São José da Tapera"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "113",
                "cidade": "São Luís do Quitunde"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "114",
                "cidade": "São Miguel dos Campos"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "115",
                "cidade": "São Miguel dos Milagres"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "116",
                "cidade": "São Sebastião"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "117",
                "cidade": "Satuba"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "118",
                "cidade": "Senador Rui Palmeira"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "119",
                "cidade": "Tanque d'Arca"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "120",
                "cidade": "Taquarana"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "121",
                "cidade": "Teotônio Vilela"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "122",
                "cidade": "Traipu"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "123",
                "cidade": "União dos Palmares"
            },
            {
                "uf": "AL",
                "estado": "Alagoas",
                "id": "124",
                "cidade": "Viçosa"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "125",
                "cidade": "Alvarães"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "126",
                "cidade": "Amaturá"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "127",
                "cidade": "Anamã"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "128",
                "cidade": "Anori"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "129",
                "cidade": "Apuí"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "130",
                "cidade": "Atalaia do Norte"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "131",
                "cidade": "Autazes"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "132",
                "cidade": "Barcelos"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "133",
                "cidade": "Barreirinha"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "134",
                "cidade": "Benjamin Constant"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "135",
                "cidade": "Beruri"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "136",
                "cidade": "Boa Vista do Ramos"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "137",
                "cidade": "Boca do Acre"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "138",
                "cidade": "Borba"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "139",
                "cidade": "Caapiranga"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "140",
                "cidade": "Canutama"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "141",
                "cidade": "Carauari"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "142",
                "cidade": "Careiro"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "143",
                "cidade": "Careiro da Várzea"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "144",
                "cidade": "Coari"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "145",
                "cidade": "Codajás"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "146",
                "cidade": "Eirunepé"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "147",
                "cidade": "Envira"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "148",
                "cidade": "Fonte Boa"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "149",
                "cidade": "Guajará"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "150",
                "cidade": "Humaitá"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "151",
                "cidade": "Ipixuna"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "152",
                "cidade": "Iranduba"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "153",
                "cidade": "Itacoatiara"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "154",
                "cidade": "Itamarati"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "155",
                "cidade": "Itapiranga"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "156",
                "cidade": "Japurá"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "157",
                "cidade": "Juruá"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "158",
                "cidade": "Jutaí"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "159",
                "cidade": "Lábrea"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "160",
                "cidade": "Manacapuru"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "161",
                "cidade": "Manaquiri"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "162",
                "cidade": "Manaus"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "163",
                "cidade": "Manicoré"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "164",
                "cidade": "Maraã"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "165",
                "cidade": "Maués"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "166",
                "cidade": "Nhamundá"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "167",
                "cidade": "Nova Olinda do Norte"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "168",
                "cidade": "Novo Airão"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "169",
                "cidade": "Novo Aripuanã"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "170",
                "cidade": "Parintins"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "171",
                "cidade": "Pauini"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "172",
                "cidade": "Presidente Figueiredo"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "173",
                "cidade": "Rio Preto da Eva"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "174",
                "cidade": "Santa Isabel do Rio Negro"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "175",
                "cidade": "Santo Antônio do Içá"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "176",
                "cidade": "São Gabriel da Cachoeira"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "177",
                "cidade": "São Paulo de Olivença"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "178",
                "cidade": "São Sebastião do Uatumã"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "179",
                "cidade": "Silves"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "180",
                "cidade": "Tabatinga"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "181",
                "cidade": "Tapauá"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "182",
                "cidade": "Tefé"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "183",
                "cidade": "Tonantins"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "184",
                "cidade": "Uarini"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "185",
                "cidade": "Urucará"
            },
            {
                "uf": "AM",
                "estado": "Amazonas",
                "id": "186",
                "cidade": "Urucurituba"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "187",
                "cidade": "Amapá"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "188",
                "cidade": "Calçoene"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "189",
                "cidade": "Cutias"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "190",
                "cidade": "Ferreira Gomes"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "191",
                "cidade": "Itaubal"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "192",
                "cidade": "Laranjal do Jari"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "193",
                "cidade": "Macapá"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "194",
                "cidade": "Mazagão"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "195",
                "cidade": "Oiapoque"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "196",
                "cidade": "Pedra Branca do Amapari"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "197",
                "cidade": "Porto Grande"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "198",
                "cidade": "Pracuúba"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "199",
                "cidade": "Santana"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "200",
                "cidade": "Serra do Navio"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "201",
                "cidade": "Tartarugalzinho"
            },
            {
                "uf": "AP",
                "estado": "Amapá",
                "id": "202",
                "cidade": "Vitória do Jari"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "203",
                "cidade": "Abaíra"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "204",
                "cidade": "Abaré"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "205",
                "cidade": "Acajutiba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "206",
                "cidade": "Adustina"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "207",
                "cidade": "Água Fria"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "208",
                "cidade": "Aiquara"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "209",
                "cidade": "Alagoinhas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "210",
                "cidade": "Alcobaça"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "211",
                "cidade": "Almadina"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "212",
                "cidade": "Amargosa"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "213",
                "cidade": "Amélia Rodrigues"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "214",
                "cidade": "América Dourada"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "215",
                "cidade": "Anagé"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "216",
                "cidade": "Andaraí"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "217",
                "cidade": "Andorinha"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "218",
                "cidade": "Angical"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "219",
                "cidade": "Anguera"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "220",
                "cidade": "Antas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "221",
                "cidade": "Antônio Cardoso"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "222",
                "cidade": "Antônio Gonçalves"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "223",
                "cidade": "Aporá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "224",
                "cidade": "Apuarema"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "225",
                "cidade": "Araças"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "226",
                "cidade": "Aracatu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "227",
                "cidade": "Araci"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "228",
                "cidade": "Aramari"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "229",
                "cidade": "Arataca"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "230",
                "cidade": "Aratuípe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "231",
                "cidade": "Aurelino Leal"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "232",
                "cidade": "Baianópolis"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "233",
                "cidade": "Baixa Grande"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "234",
                "cidade": "Banzaê"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "235",
                "cidade": "Barra"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "236",
                "cidade": "Barra da Estiva"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "237",
                "cidade": "Barra do Choça"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "238",
                "cidade": "Barra do Mendes"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "239",
                "cidade": "Barra do Rocha"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "240",
                "cidade": "Barreiras"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "241",
                "cidade": "Barro Alto"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "242",
                "cidade": "Barrocas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "243",
                "cidade": "Barro Preto"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "244",
                "cidade": "Belmonte"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "245",
                "cidade": "Belo Campo"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "246",
                "cidade": "Biritinga"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "247",
                "cidade": "Boa Nova"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "248",
                "cidade": "Boa Vista do Tupim"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "249",
                "cidade": "Bom Jesus da Lapa"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "250",
                "cidade": "Bom Jesus da Serra"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "251",
                "cidade": "Boninal"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "252",
                "cidade": "Bonito"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "253",
                "cidade": "Boquira"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "254",
                "cidade": "Botuporã"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "255",
                "cidade": "Brejões"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "256",
                "cidade": "Brejolândia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "257",
                "cidade": "Brotas de Macaúbas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "258",
                "cidade": "Brumado"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "259",
                "cidade": "Buerarema"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "260",
                "cidade": "Buritirama"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "261",
                "cidade": "Caatiba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "262",
                "cidade": "Cabaceiras do Paraguaçu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "263",
                "cidade": "Cachoeira"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "264",
                "cidade": "Caculé"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "265",
                "cidade": "Caém"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "266",
                "cidade": "Caetanos"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "267",
                "cidade": "Caetité"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "268",
                "cidade": "Cafarnaum"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "269",
                "cidade": "Cairu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "270",
                "cidade": "Caldeirão Grande"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "271",
                "cidade": "Camacan"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "272",
                "cidade": "Camaçari"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "273",
                "cidade": "Camamu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "274",
                "cidade": "Campo Alegre de Lourdes"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "275",
                "cidade": "Campo Formoso"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "276",
                "cidade": "Canápolis"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "277",
                "cidade": "Canarana"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "278",
                "cidade": "Canavieiras"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "279",
                "cidade": "Candeal"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "280",
                "cidade": "Candeias"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "281",
                "cidade": "Candiba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "282",
                "cidade": "Cândido Sales"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "283",
                "cidade": "Cansanção"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "284",
                "cidade": "Canudos"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "285",
                "cidade": "Capela do Alto Alegre"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "286",
                "cidade": "Capim Grosso"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "287",
                "cidade": "Caraíbas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "288",
                "cidade": "Caravelas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "289",
                "cidade": "Cardeal da Silva"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "290",
                "cidade": "Carinhanha"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "291",
                "cidade": "Casa Nova"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "292",
                "cidade": "Castro Alves"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "293",
                "cidade": "Catolândia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "294",
                "cidade": "Catu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "295",
                "cidade": "Caturama"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "296",
                "cidade": "Central"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "297",
                "cidade": "Chorrochó"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "298",
                "cidade": "Cícero Dantas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "299",
                "cidade": "Cipó"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "300",
                "cidade": "Coaraci"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "301",
                "cidade": "Cocos"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "302",
                "cidade": "Conceição da Feira"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "303",
                "cidade": "Conceição do Almeida"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "304",
                "cidade": "Conceição do Coité"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "305",
                "cidade": "Conceição do Jacuípe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "306",
                "cidade": "Conde"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "307",
                "cidade": "Condeúba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "308",
                "cidade": "Contendas do Sincorá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "309",
                "cidade": "Coração de Maria"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "310",
                "cidade": "Cordeiros"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "311",
                "cidade": "Coribe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "312",
                "cidade": "Coronel João Sá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "313",
                "cidade": "Correntina"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "314",
                "cidade": "Cotegipe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "315",
                "cidade": "Cravolândia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "316",
                "cidade": "Crisópolis"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "317",
                "cidade": "Cristópolis"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "318",
                "cidade": "Cruz das Almas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "319",
                "cidade": "Curaçá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "320",
                "cidade": "Dário Meira"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "321",
                "cidade": "Dias d'Ávila"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "322",
                "cidade": "Dom Basílio"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "323",
                "cidade": "Dom Macedo Costa"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "324",
                "cidade": "Elísio Medrado"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "325",
                "cidade": "Encruzilhada"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "326",
                "cidade": "Entre Rios"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "327",
                "cidade": "Érico Cardoso"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "328",
                "cidade": "Esplanada"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "329",
                "cidade": "Euclides da Cunha"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "330",
                "cidade": "Eunápolis"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "331",
                "cidade": "Fátima"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "332",
                "cidade": "Feira da Mata"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "333",
                "cidade": "Feira de Santana"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "334",
                "cidade": "Filadélfia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "335",
                "cidade": "Firmino Alves"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "336",
                "cidade": "Floresta Azul"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "337",
                "cidade": "Formosa do Rio Preto"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "338",
                "cidade": "Gandu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "339",
                "cidade": "Gavião"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "340",
                "cidade": "Gentio do Ouro"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "341",
                "cidade": "Glória"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "342",
                "cidade": "Gongogi"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "343",
                "cidade": "Governador Mangabeira"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "344",
                "cidade": "Guajeru"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "345",
                "cidade": "Guanambi"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "346",
                "cidade": "Guaratinga"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "347",
                "cidade": "Heliópolis"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "348",
                "cidade": "Iaçu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "349",
                "cidade": "Ibiassucê"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "350",
                "cidade": "Ibicaraí"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "351",
                "cidade": "Ibicoara"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "352",
                "cidade": "Ibicuí"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "353",
                "cidade": "Ibipeba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "354",
                "cidade": "Ibipitanga"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "355",
                "cidade": "Ibiquera"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "356",
                "cidade": "Ibirapitanga"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "357",
                "cidade": "Ibirapuã"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "358",
                "cidade": "Ibirataia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "359",
                "cidade": "Ibitiara"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "360",
                "cidade": "Ibititá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "361",
                "cidade": "Ibotirama"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "362",
                "cidade": "Ichu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "363",
                "cidade": "Igaporã"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "364",
                "cidade": "Igrapiúna"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "365",
                "cidade": "Iguaí"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "366",
                "cidade": "Ilhéus"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "367",
                "cidade": "Inhambupe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "368",
                "cidade": "Ipecaetá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "369",
                "cidade": "Ipiaú"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "370",
                "cidade": "Ipirá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "371",
                "cidade": "Ipupiara"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "372",
                "cidade": "Irajuba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "373",
                "cidade": "Iramaia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "374",
                "cidade": "Iraquara"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "375",
                "cidade": "Irará"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "376",
                "cidade": "Irecê"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "377",
                "cidade": "Itabela"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "378",
                "cidade": "Itaberaba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "379",
                "cidade": "Itabuna"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "380",
                "cidade": "Itacaré"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "381",
                "cidade": "Itaeté"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "382",
                "cidade": "Itagi"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "383",
                "cidade": "Itagibá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "384",
                "cidade": "Itagimirim"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "385",
                "cidade": "Itaguaçu da Bahia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "386",
                "cidade": "Itaju do Colônia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "387",
                "cidade": "Itajuípe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "388",
                "cidade": "Itamaraju"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "389",
                "cidade": "Itamari"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "390",
                "cidade": "Itambé"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "391",
                "cidade": "Itanagra"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "392",
                "cidade": "Itanhém"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "393",
                "cidade": "Itaparica"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "394",
                "cidade": "Itapé"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "395",
                "cidade": "Itapebi"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "396",
                "cidade": "Itapetinga"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "397",
                "cidade": "Itapicuru"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "398",
                "cidade": "Itapitanga"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "399",
                "cidade": "Itaquara"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "400",
                "cidade": "Itarantim"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "401",
                "cidade": "Itatim"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "402",
                "cidade": "Itiruçu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "403",
                "cidade": "Itiúba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "404",
                "cidade": "Itororó"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "405",
                "cidade": "Ituaçu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "406",
                "cidade": "Ituberá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "407",
                "cidade": "Iuiú"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "408",
                "cidade": "Jaborandi"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "409",
                "cidade": "Jacaraci"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "410",
                "cidade": "Jacobina"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "411",
                "cidade": "Jaguaquara"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "412",
                "cidade": "Jaguarari"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "413",
                "cidade": "Jaguaripe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "414",
                "cidade": "Jandaíra"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "415",
                "cidade": "Jequié"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "416",
                "cidade": "Jeremoabo"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "417",
                "cidade": "Jiquiriçá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "418",
                "cidade": "Jitaúna"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "419",
                "cidade": "João Dourado"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "420",
                "cidade": "Juazeiro"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "421",
                "cidade": "Jucuruçu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "422",
                "cidade": "Jussara"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "423",
                "cidade": "Jussari"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "424",
                "cidade": "Jussiape"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "425",
                "cidade": "Lafaiete Coutinho"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "426",
                "cidade": "Lagoa Real"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "427",
                "cidade": "Laje"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "428",
                "cidade": "Lajedão"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "429",
                "cidade": "Lajedinho"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "430",
                "cidade": "Lajedo do Tabocal"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "431",
                "cidade": "Lamarão"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "432",
                "cidade": "Lapão"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "433",
                "cidade": "Lauro de Freitas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "434",
                "cidade": "Lençóis"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "435",
                "cidade": "Licínio de Almeida"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "436",
                "cidade": "Livramento de Nossa Senhora"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "437",
                "cidade": "Luís Eduardo Magalhães"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "438",
                "cidade": "Macajuba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "439",
                "cidade": "Macarani"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "440",
                "cidade": "Macaúbas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "441",
                "cidade": "Macururé"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "442",
                "cidade": "Madre de Deus"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "443",
                "cidade": "Maetinga"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "444",
                "cidade": "Maiquinique"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "445",
                "cidade": "Mairi"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "446",
                "cidade": "Malhada"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "447",
                "cidade": "Malhada de Pedras"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "448",
                "cidade": "Manoel Vitorino"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "449",
                "cidade": "Mansidão"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "450",
                "cidade": "Maracás"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "451",
                "cidade": "Maragogipe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "452",
                "cidade": "Maraú"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "453",
                "cidade": "Marcionílio Souza"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "454",
                "cidade": "Mascote"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "455",
                "cidade": "Mata de São João"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "456",
                "cidade": "Matina"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "457",
                "cidade": "Medeiros Neto"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "458",
                "cidade": "Miguel Calmon"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "459",
                "cidade": "Milagres"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "460",
                "cidade": "Mirangaba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "461",
                "cidade": "Mirante"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "462",
                "cidade": "Monte Santo"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "463",
                "cidade": "Morpará"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "464",
                "cidade": "Morro do Chapéu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "465",
                "cidade": "Mortugaba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "466",
                "cidade": "Mucugê"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "467",
                "cidade": "Mucuri"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "468",
                "cidade": "Mulungu do Morro"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "469",
                "cidade": "Mundo Novo"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "470",
                "cidade": "Muniz Ferreira"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "471",
                "cidade": "Muquém de São Francisco"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "472",
                "cidade": "Muritiba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "473",
                "cidade": "Mutuípe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "474",
                "cidade": "Nazaré"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "475",
                "cidade": "Nilo Peçanha"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "476",
                "cidade": "Nordestina"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "477",
                "cidade": "Nova Canaã"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "478",
                "cidade": "Nova Fátima"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "479",
                "cidade": "Nova Ibiá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "480",
                "cidade": "Nova Itarana"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "481",
                "cidade": "Nova Redenção"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "482",
                "cidade": "Nova Soure"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "483",
                "cidade": "Nova Viçosa"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "484",
                "cidade": "Novo Horizonte"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "485",
                "cidade": "Novo Triunfo"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "486",
                "cidade": "Olindina"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "487",
                "cidade": "Oliveira dos Brejinhos"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "488",
                "cidade": "Ouriçangas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "489",
                "cidade": "Ourolândia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "490",
                "cidade": "Palmas de Monte Alto"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "491",
                "cidade": "Palmeiras"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "492",
                "cidade": "Paramirim"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "493",
                "cidade": "Paratinga"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "494",
                "cidade": "Paripiranga"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "495",
                "cidade": "Pau Brasil"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "496",
                "cidade": "Paulo Afonso"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "497",
                "cidade": "Pé de Serra"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "498",
                "cidade": "Pedrão"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "499",
                "cidade": "Pedro Alexandre"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "500",
                "cidade": "Piatã"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "501",
                "cidade": "Pilão Arcado"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "502",
                "cidade": "Pindaí"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "503",
                "cidade": "Pindobaçu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "504",
                "cidade": "Pintadas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "505",
                "cidade": "Piraí do Norte"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "506",
                "cidade": "Piripá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "507",
                "cidade": "Piritiba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "508",
                "cidade": "Planaltino"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "509",
                "cidade": "Planalto"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "510",
                "cidade": "Poções"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "511",
                "cidade": "Pojuca"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "512",
                "cidade": "Ponto Novo"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "513",
                "cidade": "Porto Seguro"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "514",
                "cidade": "Potiraguá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "515",
                "cidade": "Prado"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "516",
                "cidade": "Presidente Dutra"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "517",
                "cidade": "Presidente Jânio Quadros"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "518",
                "cidade": "Presidente Tancredo Neves"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "519",
                "cidade": "Queimadas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "520",
                "cidade": "Quijingue"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "521",
                "cidade": "Quixabeira"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "522",
                "cidade": "Rafael Jambeiro"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "523",
                "cidade": "Remanso"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "524",
                "cidade": "Retirolândia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "525",
                "cidade": "Riachão das Neves"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "526",
                "cidade": "Riachão do Jacuípe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "527",
                "cidade": "Riacho de Santana"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "528",
                "cidade": "Ribeira do Amparo"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "529",
                "cidade": "Ribeira do Pombal"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "530",
                "cidade": "Ribeirão do Largo"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "531",
                "cidade": "Rio de Contas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "532",
                "cidade": "Rio do Antônio"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "533",
                "cidade": "Rio do Pires"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "534",
                "cidade": "Rio Real"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "535",
                "cidade": "Rodelas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "536",
                "cidade": "Ruy Barbosa"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "537",
                "cidade": "Salinas da Margarida"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "538",
                "cidade": "Salvador"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "539",
                "cidade": "Santa Bárbara"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "540",
                "cidade": "Santa Brígida"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "541",
                "cidade": "Santa Cruz Cabrália"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "542",
                "cidade": "Santa Cruz da Vitória"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "543",
                "cidade": "Santa Inês"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "544",
                "cidade": "Santa Luzia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "545",
                "cidade": "Santa Maria da Vitória"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "546",
                "cidade": "Santa Rita de Cássia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "547",
                "cidade": "Santa Teresinha"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "548",
                "cidade": "Santaluz"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "549",
                "cidade": "Santana"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "550",
                "cidade": "Santanópolis"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "551",
                "cidade": "Santo Amaro"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "552",
                "cidade": "Santo Antônio de Jesus"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "553",
                "cidade": "Santo Estêvão"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "554",
                "cidade": "São Desidério"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "555",
                "cidade": "São Domingos"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "556",
                "cidade": "São Felipe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "557",
                "cidade": "São Félix"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "558",
                "cidade": "São Félix do Coribe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "559",
                "cidade": "São Francisco do Conde"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "560",
                "cidade": "São Gabriel"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "561",
                "cidade": "São Gonçalo dos Campos"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "562",
                "cidade": "São José da Vitória"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "563",
                "cidade": "São José do Jacuípe"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "564",
                "cidade": "São Miguel das Matas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "565",
                "cidade": "São Sebastião do Passé"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "566",
                "cidade": "Sapeaçu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "567",
                "cidade": "Sátiro Dias"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "568",
                "cidade": "Saubara"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "569",
                "cidade": "Saúde"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "570",
                "cidade": "Seabra"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "571",
                "cidade": "Sebastião Laranjeiras"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "572",
                "cidade": "Senhor do Bonfim"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "573",
                "cidade": "Sento Sé"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "574",
                "cidade": "Serra do Ramalho"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "575",
                "cidade": "Serra Dourada"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "576",
                "cidade": "Serra Preta"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "577",
                "cidade": "Serrinha"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "578",
                "cidade": "Serrolândia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "579",
                "cidade": "Simões Filho"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "580",
                "cidade": "Sítio do Mato"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "581",
                "cidade": "Sítio do Quinto"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "582",
                "cidade": "Sobradinho"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "583",
                "cidade": "Souto Soares"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "584",
                "cidade": "Tabocas do Brejo Velho"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "585",
                "cidade": "Tanhaçu"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "586",
                "cidade": "Tanque Novo"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "587",
                "cidade": "Tanquinho"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "588",
                "cidade": "Taperoá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "589",
                "cidade": "Tapiramutá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "590",
                "cidade": "Teixeira de Freitas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "591",
                "cidade": "Teodoro Sampaio"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "592",
                "cidade": "Teofilândia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "593",
                "cidade": "Teolândia"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "594",
                "cidade": "Terra Nova"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "595",
                "cidade": "Tremedal"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "596",
                "cidade": "Tucano"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "597",
                "cidade": "Uauá"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "598",
                "cidade": "Ubaíra"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "599",
                "cidade": "Ubaitaba"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "600",
                "cidade": "Ubatã"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "601",
                "cidade": "Uibaí"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "602",
                "cidade": "Umburanas"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "603",
                "cidade": "Una"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "604",
                "cidade": "Urandi"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "605",
                "cidade": "Uruçuca"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "606",
                "cidade": "Utinga"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "607",
                "cidade": "Valença"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "608",
                "cidade": "Valente"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "609",
                "cidade": "Várzea da Roça"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "610",
                "cidade": "Várzea do Poço"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "611",
                "cidade": "Várzea Nova"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "612",
                "cidade": "Varzedo"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "613",
                "cidade": "Vera Cruz"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "614",
                "cidade": "Vereda"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "615",
                "cidade": "Vitória da Conquista"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "616",
                "cidade": "Wagner"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "617",
                "cidade": "Wanderley"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "618",
                "cidade": "Wenceslau Guimarães"
            },
            {
                "uf": "BA",
                "estado": "Bahia",
                "id": "619",
                "cidade": "Xique-Xique"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "620",
                "cidade": "Abaiara"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "621",
                "cidade": "Acarapé"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "622",
                "cidade": "Acaraú"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "623",
                "cidade": "Acopiara"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "624",
                "cidade": "Aiuaba"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "625",
                "cidade": "Alcântaras"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "626",
                "cidade": "Altaneira"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "627",
                "cidade": "Alto Santo"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "628",
                "cidade": "Amontada"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "629",
                "cidade": "Antonina do Norte"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "630",
                "cidade": "Apuiarés"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "631",
                "cidade": "Aquiraz"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "632",
                "cidade": "Aracati"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "633",
                "cidade": "Aracoiaba"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "634",
                "cidade": "Ararendá"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "635",
                "cidade": "Araripe"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "636",
                "cidade": "Aratuba"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "637",
                "cidade": "Arneiroz"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "638",
                "cidade": "Assaré"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "639",
                "cidade": "Aurora"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "640",
                "cidade": "Baixio"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "641",
                "cidade": "Banabuiú"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "642",
                "cidade": "Barbalha"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "643",
                "cidade": "Barreira"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "644",
                "cidade": "Barro"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "645",
                "cidade": "Barroquinha"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "646",
                "cidade": "Baturité"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "647",
                "cidade": "Beberibe"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "648",
                "cidade": "Bela Cruz"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "649",
                "cidade": "Boa Viagem"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "650",
                "cidade": "Brejo Santo"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "651",
                "cidade": "Camocim"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "652",
                "cidade": "Campos Sales"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "653",
                "cidade": "Canindé"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "654",
                "cidade": "Capistrano"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "655",
                "cidade": "Caridade"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "656",
                "cidade": "Cariré"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "657",
                "cidade": "Caririaçu"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "658",
                "cidade": "Cariús"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "659",
                "cidade": "Carnaubal"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "660",
                "cidade": "Cascavel"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "661",
                "cidade": "Catarina"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "662",
                "cidade": "Catunda"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "663",
                "cidade": "Caucaia"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "664",
                "cidade": "Cedro"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "665",
                "cidade": "Chaval"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "666",
                "cidade": "Choró"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "667",
                "cidade": "Chorozinho"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "668",
                "cidade": "Coreaú"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "669",
                "cidade": "Crateús"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "670",
                "cidade": "Crato"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "671",
                "cidade": "Croatá"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "672",
                "cidade": "Cruz"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "673",
                "cidade": "Deputado Irapuan Pinheiro"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "674",
                "cidade": "Ererê"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "675",
                "cidade": "Eusébio"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "676",
                "cidade": "Farias Brito"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "677",
                "cidade": "Forquilha"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "678",
                "cidade": "Fortaleza"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "679",
                "cidade": "Fortim"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "680",
                "cidade": "Frecheirinha"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "681",
                "cidade": "General Sampaio"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "682",
                "cidade": "Graça"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "683",
                "cidade": "Granja"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "684",
                "cidade": "Granjeiro"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "685",
                "cidade": "Groaíras"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "686",
                "cidade": "Guaiúba"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "687",
                "cidade": "Guaraciaba do Norte"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "688",
                "cidade": "Guaramiranga"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "689",
                "cidade": "Hidrolândia"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "690",
                "cidade": "Horizonte"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "691",
                "cidade": "Ibaretama"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "692",
                "cidade": "Ibiapina"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "693",
                "cidade": "Ibicuitinga"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "694",
                "cidade": "Icapuí"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "695",
                "cidade": "Icó"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "696",
                "cidade": "Iguatu"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "697",
                "cidade": "Independência"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "698",
                "cidade": "Ipaporanga"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "699",
                "cidade": "Ipaumirim"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "700",
                "cidade": "Ipu"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "701",
                "cidade": "Ipueiras"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "702",
                "cidade": "Iracema"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "703",
                "cidade": "Irauçuba"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "704",
                "cidade": "Itaiçaba"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "705",
                "cidade": "Itaitinga"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "706",
                "cidade": "Itapagé"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "707",
                "cidade": "Itapipoca"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "708",
                "cidade": "Itapiúna"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "709",
                "cidade": "Itarema"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "710",
                "cidade": "Itatira"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "711",
                "cidade": "Jaguaretama"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "712",
                "cidade": "Jaguaribara"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "713",
                "cidade": "Jaguaribe"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "714",
                "cidade": "Jaguaruana"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "715",
                "cidade": "Jardim"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "716",
                "cidade": "Jati"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "717",
                "cidade": "Jijoca de Jericoaroara"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "718",
                "cidade": "Juazeiro do Norte"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "719",
                "cidade": "Jucás"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "720",
                "cidade": "Lavras da Mangabeira"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "721",
                "cidade": "Limoeiro do Norte"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "722",
                "cidade": "Madalena"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "723",
                "cidade": "Maracanaú"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "724",
                "cidade": "Maranguape"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "725",
                "cidade": "Marco"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "726",
                "cidade": "Martinópole"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "727",
                "cidade": "Massapê"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "728",
                "cidade": "Mauriti"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "729",
                "cidade": "Meruoca"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "730",
                "cidade": "Milagres"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "731",
                "cidade": "Milhã"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "732",
                "cidade": "Miraíma"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "733",
                "cidade": "Missão Velha"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "734",
                "cidade": "Mombaça"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "735",
                "cidade": "Monsenhor Tabosa"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "736",
                "cidade": "Morada Nova"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "737",
                "cidade": "Moraújo"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "738",
                "cidade": "Morrinhos"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "739",
                "cidade": "Mucambo"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "740",
                "cidade": "Mulungu"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "741",
                "cidade": "Nova Olinda"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "742",
                "cidade": "Nova Russas"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "743",
                "cidade": "Novo Oriente"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "744",
                "cidade": "Ocara"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "745",
                "cidade": "Orós"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "746",
                "cidade": "Pacajus"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "747",
                "cidade": "Pacatuba"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "748",
                "cidade": "Pacoti"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "749",
                "cidade": "Pacujá"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "750",
                "cidade": "Palhano"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "751",
                "cidade": "Palmácia"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "752",
                "cidade": "Paracuru"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "753",
                "cidade": "Paraipaba"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "754",
                "cidade": "Parambu"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "755",
                "cidade": "Paramoti"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "756",
                "cidade": "Pedra Branca"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "757",
                "cidade": "Penaforte"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "758",
                "cidade": "Pentecoste"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "759",
                "cidade": "Pereiro"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "760",
                "cidade": "Pindoretama"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "761",
                "cidade": "Piquet Carneiro"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "762",
                "cidade": "Pires Ferreira"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "763",
                "cidade": "Poranga"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "764",
                "cidade": "Porteiras"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "765",
                "cidade": "Potengi"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "766",
                "cidade": "Potiretama"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "767",
                "cidade": "Quiterianópolis"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "768",
                "cidade": "Quixadá"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "769",
                "cidade": "Quixelô"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "770",
                "cidade": "Quixeramobim"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "771",
                "cidade": "Quixeré"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "772",
                "cidade": "Redenção"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "773",
                "cidade": "Reriutaba"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "774",
                "cidade": "Russas"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "775",
                "cidade": "Saboeiro"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "776",
                "cidade": "Salitre"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "777",
                "cidade": "Santa Quitéria"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "778",
                "cidade": "Santana do Acaraú"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "779",
                "cidade": "Santana do Cariri"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "780",
                "cidade": "São Benedito"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "781",
                "cidade": "São Gonçalo do Amarante"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "782",
                "cidade": "São João do Jaguaribe"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "783",
                "cidade": "São Luís do Curu"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "784",
                "cidade": "Senador Pompeu"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "785",
                "cidade": "Senador Sá"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "786",
                "cidade": "Sobral"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "787",
                "cidade": "Solonópole"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "788",
                "cidade": "Tabuleiro do Norte"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "789",
                "cidade": "Tamboril"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "790",
                "cidade": "Tarrafas"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "791",
                "cidade": "Tauá"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "792",
                "cidade": "Tejuçuoca"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "793",
                "cidade": "Tianguá"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "794",
                "cidade": "Trairi"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "795",
                "cidade": "Tururu"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "796",
                "cidade": "Ubajara"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "797",
                "cidade": "Umari"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "798",
                "cidade": "Umirim"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "799",
                "cidade": "Uruburetama"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "800",
                "cidade": "Uruoca"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "801",
                "cidade": "Varjota"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "802",
                "cidade": "Várzea Alegre"
            },
            {
                "uf": "CE",
                "estado": "Ceará",
                "id": "803",
                "cidade": "Viçosa do Ceará"
            },
            {
                "uf": "DF",
                "estado": "Distrito Federal",
                "id": "804",
                "cidade": "Brasília"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "805",
                "cidade": "Afonso Cláudio"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "806",
                "cidade": "Água Doce do Norte"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "807",
                "cidade": "Águia Branca"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "808",
                "cidade": "Alegre"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "809",
                "cidade": "Alfredo Chaves"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "810",
                "cidade": "Alto Rio Novo"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "811",
                "cidade": "Anchieta"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "812",
                "cidade": "Apiacá"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "813",
                "cidade": "Aracruz"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "814",
                "cidade": "Atilio Vivacqua"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "815",
                "cidade": "Baixo Guandu"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "816",
                "cidade": "Barra de São Francisco"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "817",
                "cidade": "Boa Esperança"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "818",
                "cidade": "Bom Jesus do Norte"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "819",
                "cidade": "Brejetuba"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "820",
                "cidade": "Cachoeiro de Itapemirim"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "821",
                "cidade": "Cariacica"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "822",
                "cidade": "Castelo"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "823",
                "cidade": "Colatina"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "824",
                "cidade": "Conceição da Barra"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "825",
                "cidade": "Conceição do Castelo"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "826",
                "cidade": "Divino de São Lourenço"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "827",
                "cidade": "Domingos Martins"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "828",
                "cidade": "Dores do Rio Preto"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "829",
                "cidade": "Ecoporanga"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "830",
                "cidade": "Fundão"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "831",
                "cidade": "Governador Lindenberg"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "832",
                "cidade": "Guaçuí"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "833",
                "cidade": "Guarapari"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "834",
                "cidade": "Ibatiba"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "835",
                "cidade": "Ibiraçu"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "836",
                "cidade": "Ibitirama"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "837",
                "cidade": "Iconha"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "838",
                "cidade": "Irupi"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "839",
                "cidade": "Itaguaçu"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "840",
                "cidade": "Itapemirim"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "841",
                "cidade": "Itarana"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "842",
                "cidade": "Iúna"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "843",
                "cidade": "Jaguaré"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "844",
                "cidade": "Jerônimo Monteiro"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "845",
                "cidade": "João Neiva"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "846",
                "cidade": "Laranja da Terra"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "847",
                "cidade": "Linhares"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "848",
                "cidade": "Mantenópolis"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "849",
                "cidade": "Marataizes"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "850",
                "cidade": "Marechal Floriano"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "851",
                "cidade": "Marilândia"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "852",
                "cidade": "Mimoso do Sul"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "853",
                "cidade": "Montanha"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "854",
                "cidade": "Mucurici"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "855",
                "cidade": "Muniz Freire"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "856",
                "cidade": "Muqui"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "857",
                "cidade": "Nova Venécia"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "858",
                "cidade": "Pancas"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "859",
                "cidade": "Pedro Canário"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "860",
                "cidade": "Pinheiros"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "861",
                "cidade": "Piúma"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "862",
                "cidade": "Ponto Belo"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "863",
                "cidade": "Presidente Kennedy"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "864",
                "cidade": "Rio Bananal"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "865",
                "cidade": "Rio Novo do Sul"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "866",
                "cidade": "Santa Leopoldina"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "867",
                "cidade": "Santa Maria de Jetibá"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "868",
                "cidade": "Santa Teresa"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "869",
                "cidade": "São Domingos do Norte"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "870",
                "cidade": "São Gabriel da Palha"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "871",
                "cidade": "São José do Calçado"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "872",
                "cidade": "São Mateus"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "873",
                "cidade": "São Roque do Canaã"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "874",
                "cidade": "Serra"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "875",
                "cidade": "Sooretama"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "876",
                "cidade": "Vargem Alta"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "877",
                "cidade": "Venda Nova do Imigrante"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "878",
                "cidade": "Viana"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "879",
                "cidade": "Vila Pavão"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "880",
                "cidade": "Vila Valério"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "881",
                "cidade": "Vila Velha"
            },
            {
                "uf": "ES",
                "estado": "Espírito Santo",
                "id": "882",
                "cidade": "Vitória"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "883",
                "cidade": "Abadia de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "884",
                "cidade": "Abadiânia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "885",
                "cidade": "Acreúna"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "886",
                "cidade": "Adelândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "887",
                "cidade": "Água Fria de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "888",
                "cidade": "Água Limpa"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "889",
                "cidade": "Águas Lindas de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "890",
                "cidade": "Alexânia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "891",
                "cidade": "Aloândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "892",
                "cidade": "Alto Horizonte"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "893",
                "cidade": "Alto Paraíso de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "894",
                "cidade": "Alvorada do Norte"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "895",
                "cidade": "Amaralina"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "896",
                "cidade": "Americano do Brasil"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "897",
                "cidade": "Amorinópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "898",
                "cidade": "Anápolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "899",
                "cidade": "Anhanguera"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "900",
                "cidade": "Anicuns"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "901",
                "cidade": "Aparecida de Goiânia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "902",
                "cidade": "Aparecida do Rio Doce"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "903",
                "cidade": "Aporé"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "904",
                "cidade": "Araçu"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "905",
                "cidade": "Aragarças"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "906",
                "cidade": "Aragoiânia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "907",
                "cidade": "Araguapaz"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "908",
                "cidade": "Arenópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "909",
                "cidade": "Aruanã"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "910",
                "cidade": "Aurilândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "911",
                "cidade": "Avelinópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "912",
                "cidade": "Baliza"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "913",
                "cidade": "Barro Alto"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "914",
                "cidade": "Bela Vista de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "915",
                "cidade": "Bom Jardim de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "916",
                "cidade": "Bom Jesus de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "917",
                "cidade": "Bonfinópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "918",
                "cidade": "Bonópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "919",
                "cidade": "Brazabrantes"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "920",
                "cidade": "Britânia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "921",
                "cidade": "Buriti Alegre"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "922",
                "cidade": "Buriti de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "923",
                "cidade": "Buritinópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "924",
                "cidade": "Cabeceiras"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "925",
                "cidade": "Cachoeira Alta"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "926",
                "cidade": "Cachoeira de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "927",
                "cidade": "Cachoeira Dourada"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "928",
                "cidade": "Caçu"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "929",
                "cidade": "Caiapônia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "930",
                "cidade": "Caldas Novas"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "931",
                "cidade": "Caldazinha"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "932",
                "cidade": "Campestre de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "933",
                "cidade": "Campinaçu"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "934",
                "cidade": "Campinorte"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "935",
                "cidade": "Campo Alegre de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "936",
                "cidade": "Campos Limpo de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "937",
                "cidade": "Campos Belos"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "938",
                "cidade": "Campos Verdes"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "939",
                "cidade": "Carmo do Rio Verde"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "940",
                "cidade": "Castelândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "941",
                "cidade": "Catalão"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "942",
                "cidade": "Caturaí"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "943",
                "cidade": "Cavalcante"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "944",
                "cidade": "Ceres"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "945",
                "cidade": "Cezarina"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "946",
                "cidade": "Chapadão do Céu"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "947",
                "cidade": "Cidade Ocidental"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "948",
                "cidade": "Cocalzinho de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "949",
                "cidade": "Colinas do Sul"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "950",
                "cidade": "Córrego do Ouro"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "951",
                "cidade": "Corumbá de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "952",
                "cidade": "Corumbaíba"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "953",
                "cidade": "Cristalina"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "954",
                "cidade": "Cristianópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "955",
                "cidade": "Crixás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "956",
                "cidade": "Cromínia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "957",
                "cidade": "Cumari"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "958",
                "cidade": "Damianópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "959",
                "cidade": "Damolândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "960",
                "cidade": "Davinópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "961",
                "cidade": "Diorama"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "962",
                "cidade": "Divinópolis de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "963",
                "cidade": "Doverlândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "964",
                "cidade": "Edealina"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "965",
                "cidade": "Edéia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "966",
                "cidade": "Estrela do Norte"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "967",
                "cidade": "Faina"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "968",
                "cidade": "Fazenda Nova"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "969",
                "cidade": "Firminópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "970",
                "cidade": "Flores de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "971",
                "cidade": "Formosa"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "972",
                "cidade": "Formoso"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "973",
                "cidade": "Gameleira de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "974",
                "cidade": "Goianápolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "975",
                "cidade": "Goiandira"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "976",
                "cidade": "Goianésia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "977",
                "cidade": "Goiânia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "978",
                "cidade": "Goianira"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "979",
                "cidade": "Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "980",
                "cidade": "Goiatuba"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "981",
                "cidade": "Gouvelândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "982",
                "cidade": "Guapó"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "983",
                "cidade": "Guaraíta"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "984",
                "cidade": "Guarani de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "985",
                "cidade": "Guarinos"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "986",
                "cidade": "Heitoraí"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "987",
                "cidade": "Hidrolândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "988",
                "cidade": "Hidrolina"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "989",
                "cidade": "Iaciara"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "990",
                "cidade": "Inaciolândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "991",
                "cidade": "Indiara"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "992",
                "cidade": "Inhumas"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "993",
                "cidade": "Ipameri"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "994",
                "cidade": "Ipiranga de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "995",
                "cidade": "Iporá"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "996",
                "cidade": "Israelândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "997",
                "cidade": "Itaberaí"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "998",
                "cidade": "Itaguari"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "999",
                "cidade": "Itaguaru"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1000",
                "cidade": "Itajá"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1001",
                "cidade": "Itapaci"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1002",
                "cidade": "Itapirapuã"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1003",
                "cidade": "Itapuranga"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1004",
                "cidade": "Itarumã"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1005",
                "cidade": "Itauçu"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1006",
                "cidade": "Itumbiara"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1007",
                "cidade": "Ivolândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1008",
                "cidade": "Jandaia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1009",
                "cidade": "Jaraguá"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1010",
                "cidade": "Jataí"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1011",
                "cidade": "Jaupaci"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1012",
                "cidade": "Jesúpolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1013",
                "cidade": "Joviânia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1014",
                "cidade": "Jussara"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1015",
                "cidade": "Lagoa Santa"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1016",
                "cidade": "Leopoldo de Bulhões"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1017",
                "cidade": "Luziânia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1018",
                "cidade": "Mairipotaba"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1019",
                "cidade": "Mambaí"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1020",
                "cidade": "Mara Rosa"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1021",
                "cidade": "Marzagão"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1022",
                "cidade": "Matrinchã"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1023",
                "cidade": "Maurilândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1024",
                "cidade": "Mimoso de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1025",
                "cidade": "Minaçu"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1026",
                "cidade": "Mineiros"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1027",
                "cidade": "Moiporá"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1028",
                "cidade": "Monte Alegre de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1029",
                "cidade": "Montes Claros de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1030",
                "cidade": "Montividiu"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1031",
                "cidade": "Montividiu do Norte"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1032",
                "cidade": "Morrinhos"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1033",
                "cidade": "Morro Agudo de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1034",
                "cidade": "Mossâmedes"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1035",
                "cidade": "Mozarlândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1036",
                "cidade": "Mundo Novo"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1037",
                "cidade": "Mutunópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1038",
                "cidade": "Nazário"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1039",
                "cidade": "Nerópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1040",
                "cidade": "Niquelândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1041",
                "cidade": "Nova América"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1042",
                "cidade": "Nova Aurora"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1043",
                "cidade": "Nova Crixás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1044",
                "cidade": "Nova Glória"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1045",
                "cidade": "Nova Iguaçu de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1046",
                "cidade": "Nova Roma"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1047",
                "cidade": "Nova Veneza"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1048",
                "cidade": "Novo Brasil"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1049",
                "cidade": "Novo Gama"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1050",
                "cidade": "Novo Planalto"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1051",
                "cidade": "Orizona"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1052",
                "cidade": "Ouro Verde de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1053",
                "cidade": "Ouvidor"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1054",
                "cidade": "Padre Bernardo"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1055",
                "cidade": "Palestina de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1056",
                "cidade": "Palmeiras de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1057",
                "cidade": "Palmelo"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1058",
                "cidade": "Palminópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1059",
                "cidade": "Panamá"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1060",
                "cidade": "Paranaiguara"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1061",
                "cidade": "Paraúna"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1062",
                "cidade": "Perolândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1063",
                "cidade": "Petrolina de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1064",
                "cidade": "Pilar de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1065",
                "cidade": "Piracanjuba"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1066",
                "cidade": "Piranhas"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1067",
                "cidade": "Pirenópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1068",
                "cidade": "Pires do Rio"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1069",
                "cidade": "Planaltina"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1070",
                "cidade": "Pontalina"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1071",
                "cidade": "Porangatu"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1072",
                "cidade": "Porteirão"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1073",
                "cidade": "Portelândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1074",
                "cidade": "Posse"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1075",
                "cidade": "Professor Jamil"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1076",
                "cidade": "Quirinópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1077",
                "cidade": "Rialma"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1078",
                "cidade": "Rianápolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1079",
                "cidade": "Rio Quente"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1080",
                "cidade": "Rio Verde"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1081",
                "cidade": "Rubiataba"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1082",
                "cidade": "Sanclerlândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1083",
                "cidade": "Santa Bárbara de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1084",
                "cidade": "Santa Cruz de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1085",
                "cidade": "Santa Fé de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1086",
                "cidade": "Santa Helena de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1087",
                "cidade": "Santa Isabel"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1088",
                "cidade": "Santa Rita do Araguaia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1089",
                "cidade": "Santa Rita do Novo Destino"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1090",
                "cidade": "Santa Rosa de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1091",
                "cidade": "Santa Tereza de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1092",
                "cidade": "Santa Terezinha de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1093",
                "cidade": "Santo Antônio da Barra"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1094",
                "cidade": "Santo Antônio de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1095",
                "cidade": "Santo Antônio do Descoberto"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1096",
                "cidade": "São Domingos"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1097",
                "cidade": "São Francisco de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1098",
                "cidade": "São João d'Aliança"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1099",
                "cidade": "São João da Paraúna"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1100",
                "cidade": "São Luís de Montes Belos"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1101",
                "cidade": "São Luíz do Norte"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1102",
                "cidade": "São Miguel do Araguaia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1103",
                "cidade": "São Miguel do Passa Quatro"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1104",
                "cidade": "São Patrício"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1105",
                "cidade": "São Simão"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1106",
                "cidade": "Senador Canedo"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1107",
                "cidade": "Serranópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1108",
                "cidade": "Silvânia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1109",
                "cidade": "Simolândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1110",
                "cidade": "Sítio d'Abadia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1111",
                "cidade": "Taquaral de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1112",
                "cidade": "Teresina de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1113",
                "cidade": "Terezópolis de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1114",
                "cidade": "Três Ranchos"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1115",
                "cidade": "Trindade"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1116",
                "cidade": "Trombas"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1117",
                "cidade": "Turvânia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1118",
                "cidade": "Turvelândia"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1119",
                "cidade": "Uirapuru"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1120",
                "cidade": "Uruaçu"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1121",
                "cidade": "Uruana"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1122",
                "cidade": "Urutaí"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1123",
                "cidade": "Valparaíso de Goiás"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1124",
                "cidade": "Varjão"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1125",
                "cidade": "Vianópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1126",
                "cidade": "Vicentinópolis"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1127",
                "cidade": "Vila Boa"
            },
            {
                "uf": "GO",
                "estado": "Goiás",
                "id": "1128",
                "cidade": "Vila Propício"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1129",
                "cidade": "Açailândia"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1130",
                "cidade": "Afonso Cunha"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1131",
                "cidade": "Água Doce do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1132",
                "cidade": "Alcântara"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1133",
                "cidade": "Aldeias Altas"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1134",
                "cidade": "Altamira do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1135",
                "cidade": "Alto Alegre do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1136",
                "cidade": "Alto Alegre do Pindaré"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1137",
                "cidade": "Alto Parnaíba"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1138",
                "cidade": "Amapá do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1139",
                "cidade": "Amarante do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1140",
                "cidade": "Anajatuba"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1141",
                "cidade": "Anapurus"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1142",
                "cidade": "Apicum-Açu"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1143",
                "cidade": "Araguanã"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1144",
                "cidade": "Araioses"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1145",
                "cidade": "Arame"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1146",
                "cidade": "Arari"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1147",
                "cidade": "Axixá"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1148",
                "cidade": "Bacabal"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1149",
                "cidade": "Bacabeira"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1150",
                "cidade": "Bacuri"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1151",
                "cidade": "Bacurituba"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1152",
                "cidade": "Balsas"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1153",
                "cidade": "Barão de Grajaú"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1154",
                "cidade": "Barra do Corda"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1155",
                "cidade": "Barreirinhas"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1156",
                "cidade": "Bela Vista do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1157",
                "cidade": "Belágua"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1158",
                "cidade": "Benedito Leite"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1159",
                "cidade": "Bequimão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1160",
                "cidade": "Bernardo do Mearim"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1161",
                "cidade": "Boa Vista do Gurupi"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1162",
                "cidade": "Bom Jardim"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1163",
                "cidade": "Bom Jesus das Selvas"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1164",
                "cidade": "Bom Lugar"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1165",
                "cidade": "Brejo"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1166",
                "cidade": "Brejo de Areia"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1167",
                "cidade": "Buriti"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1168",
                "cidade": "Buriti Bravo"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1169",
                "cidade": "Buriticupu"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1170",
                "cidade": "Buritirana"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1171",
                "cidade": "Cachoeira Grande"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1172",
                "cidade": "Cajapió"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1173",
                "cidade": "Cajari"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1174",
                "cidade": "Campestre do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1175",
                "cidade": "Cândido Mendes"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1176",
                "cidade": "Cantanhede"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1177",
                "cidade": "Capinzal do Norte"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1178",
                "cidade": "Carolina"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1179",
                "cidade": "Carutapera"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1180",
                "cidade": "Caxias"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1181",
                "cidade": "Cedral"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1182",
                "cidade": "Central do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1183",
                "cidade": "Centro do Guilherme"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1184",
                "cidade": "Centro Novo do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1185",
                "cidade": "Chapadinha"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1186",
                "cidade": "Cidelândia"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1187",
                "cidade": "Codó"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1188",
                "cidade": "Coelho Neto"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1189",
                "cidade": "Colinas"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1190",
                "cidade": "Conceição do Lago-Açu"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1191",
                "cidade": "Coroatá"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1192",
                "cidade": "Cururupu"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1193",
                "cidade": "Davinópolis"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1194",
                "cidade": "Dom Pedro"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1195",
                "cidade": "Duque Bacelar"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1196",
                "cidade": "Esperantinópolis"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1197",
                "cidade": "Estreito"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1198",
                "cidade": "Feira Nova do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1199",
                "cidade": "Fernando Falcão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1200",
                "cidade": "Formosa da Serra Negra"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1201",
                "cidade": "Fortaleza dos Nogueiras"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1202",
                "cidade": "Fortuna"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1203",
                "cidade": "Godofredo Viana"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1204",
                "cidade": "Gonçalves Dias"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1205",
                "cidade": "Governador Archer"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1206",
                "cidade": "Governador Edison Lobão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1207",
                "cidade": "Governador Eugênio Barros"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1208",
                "cidade": "Governador Luiz Rocha"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1209",
                "cidade": "Governador Newton Bello"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1210",
                "cidade": "Governador Nunes Freire"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1211",
                "cidade": "Graça Aranha"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1212",
                "cidade": "Grajaú"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1213",
                "cidade": "Guimarães"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1214",
                "cidade": "Humberto de Campos"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1215",
                "cidade": "Icatu"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1216",
                "cidade": "Igarapé do Meio"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1217",
                "cidade": "Igarapé Grande"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1218",
                "cidade": "Imperatriz"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1219",
                "cidade": "Itaipava do Grajaú"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1220",
                "cidade": "Itapecuru Mirim"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1221",
                "cidade": "Itinga do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1222",
                "cidade": "Jatobá"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1223",
                "cidade": "Jenipapo dos Vieiras"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1224",
                "cidade": "João Lisboa"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1225",
                "cidade": "Joselândia"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1226",
                "cidade": "Junco do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1227",
                "cidade": "Lago da Pedra"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1228",
                "cidade": "Lago do Junco"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1229",
                "cidade": "Lago dos Rodrigues"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1230",
                "cidade": "Lago Verde"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1231",
                "cidade": "Lagoa do Mato"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1232",
                "cidade": "Lagoa Grande do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1233",
                "cidade": "Lajeado Novo"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1234",
                "cidade": "Lima Campos"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1235",
                "cidade": "Loreto"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1236",
                "cidade": "Luís Domingues"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1237",
                "cidade": "Magalhães de Almeida"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1238",
                "cidade": "Maracaçumé"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1239",
                "cidade": "Marajá do Sena"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1240",
                "cidade": "Maranhãozinho"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1241",
                "cidade": "Mata Roma"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1242",
                "cidade": "Matinha"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1243",
                "cidade": "Matões"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1244",
                "cidade": "Matões do Norte"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1245",
                "cidade": "Milagres do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1246",
                "cidade": "Mirador"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1247",
                "cidade": "Miranda do Norte"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1248",
                "cidade": "Mirinzal"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1249",
                "cidade": "Monção"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1250",
                "cidade": "Montes Altos"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1251",
                "cidade": "Morros"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1252",
                "cidade": "Nina Rodrigues"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1253",
                "cidade": "Nova Colinas"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1254",
                "cidade": "Nova Iorque"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1255",
                "cidade": "Nova Olinda do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1256",
                "cidade": "Olho d'Água das Cunhãs"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1257",
                "cidade": "Olinda Nova do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1258",
                "cidade": "Paço do Lumiar"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1259",
                "cidade": "Palmeirândia"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1260",
                "cidade": "Paraibano"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1261",
                "cidade": "Parnarama"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1262",
                "cidade": "Passagem Franca"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1263",
                "cidade": "Pastos Bons"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1264",
                "cidade": "Paulino Neves"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1265",
                "cidade": "Paulo Ramos"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1266",
                "cidade": "Pedreiras"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1267",
                "cidade": "Pedro do Rosário"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1268",
                "cidade": "Penalva"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1269",
                "cidade": "Peri Mirim"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1270",
                "cidade": "Peritoró"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1271",
                "cidade": "Pindaré Mirim"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1272",
                "cidade": "Pinheiro"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1273",
                "cidade": "Pio XII"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1274",
                "cidade": "Pirapemas"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1275",
                "cidade": "Poção de Pedras"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1276",
                "cidade": "Porto Franco"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1277",
                "cidade": "Porto Rico do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1278",
                "cidade": "Presidente Dutra"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1279",
                "cidade": "Presidente Juscelino"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1280",
                "cidade": "Presidente Médici"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1281",
                "cidade": "Presidente Sarney"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1282",
                "cidade": "Presidente Vargas"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1283",
                "cidade": "Primeira Cruz"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1284",
                "cidade": "Raposa"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1285",
                "cidade": "Riachão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1286",
                "cidade": "Ribamar Fiquene"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1287",
                "cidade": "Rosário"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1288",
                "cidade": "Sambaíba"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1289",
                "cidade": "Santa Filomena do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1290",
                "cidade": "Santa Helena"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1291",
                "cidade": "Santa Inês"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1292",
                "cidade": "Santa Luzia"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1293",
                "cidade": "Santa Luzia do Paruá"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1294",
                "cidade": "Santa Quitéria do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1295",
                "cidade": "Santa Rita"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1296",
                "cidade": "Santana do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1297",
                "cidade": "Santo Amaro do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1298",
                "cidade": "Santo Antônio dos Lopes"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1299",
                "cidade": "São Benedito do Rio Preto"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1300",
                "cidade": "São Bento"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1301",
                "cidade": "São Bernardo"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1302",
                "cidade": "São Domingos do Azeitão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1303",
                "cidade": "São Domingos do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1304",
                "cidade": "São Félix de Balsas"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1305",
                "cidade": "São Francisco do Brejão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1306",
                "cidade": "São Francisco do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1307",
                "cidade": "São João Batista"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1308",
                "cidade": "São João do Carú"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1309",
                "cidade": "São João do Paraíso"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1310",
                "cidade": "São João do Soter"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1311",
                "cidade": "São João dos Patos"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1312",
                "cidade": "São José de Ribamar"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1313",
                "cidade": "São José dos Basílios"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1314",
                "cidade": "São Luís"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1315",
                "cidade": "São Luís Gonzaga do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1316",
                "cidade": "São Mateus do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1317",
                "cidade": "São Pedro da Água Branca"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1318",
                "cidade": "São Pedro dos Crentes"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1319",
                "cidade": "São Raimundo das Mangabeiras"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1320",
                "cidade": "São Raimundo do Doca Bezerra"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1321",
                "cidade": "São Roberto"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1322",
                "cidade": "São Vicente Ferrer"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1323",
                "cidade": "Satubinha"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1324",
                "cidade": "Senador Alexandre Costa"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1325",
                "cidade": "Senador La Rocque"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1326",
                "cidade": "Serrano do Maranhão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1327",
                "cidade": "Sítio Novo"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1328",
                "cidade": "Sucupira do Norte"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1329",
                "cidade": "Sucupira do Riachão"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1330",
                "cidade": "Tasso Fragoso"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1331",
                "cidade": "Timbiras"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1332",
                "cidade": "Timon"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1333",
                "cidade": "Trizidela do Vale"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1334",
                "cidade": "Tufilândia"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1335",
                "cidade": "Tuntum"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1336",
                "cidade": "Turiaçu"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1337",
                "cidade": "Turilândia"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1338",
                "cidade": "Tutóia"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1339",
                "cidade": "Urbano Santos"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1340",
                "cidade": "Vargem Grande"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1341",
                "cidade": "Viana"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1342",
                "cidade": "Vila Nova dos Martírios"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1343",
                "cidade": "Vitória do Mearim"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1344",
                "cidade": "Vitorino Freire"
            },
            {
                "uf": "MA",
                "estado": "Maranhão",
                "id": "1345",
                "cidade": "Zé Doca"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1346",
                "cidade": "Abadia dos Dourados"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1347",
                "cidade": "Abaeté"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1348",
                "cidade": "Abre Campo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1349",
                "cidade": "Acaiaca"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1350",
                "cidade": "Açucena"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1351",
                "cidade": "Água Boa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1352",
                "cidade": "Água Comprida"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1353",
                "cidade": "Aguanil"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1354",
                "cidade": "Águas Formosas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1355",
                "cidade": "Águas Vermelhas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1356",
                "cidade": "Aimorés"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1357",
                "cidade": "Aiuruoca"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1358",
                "cidade": "Alagoa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1359",
                "cidade": "Albertina"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1360",
                "cidade": "Além Paraíba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1361",
                "cidade": "Alfenas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1362",
                "cidade": "Alfredo Vasconcelos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1363",
                "cidade": "Almenara"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1364",
                "cidade": "Alpercata"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1365",
                "cidade": "Alpinópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1366",
                "cidade": "Alterosa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1367",
                "cidade": "Alto Caparaó"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1368",
                "cidade": "Alto Jequitibá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1369",
                "cidade": "Alto Rio Doce"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1370",
                "cidade": "Alvarenga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1371",
                "cidade": "Alvinópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1372",
                "cidade": "Alvorada de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1373",
                "cidade": "Amparo do Serra"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1374",
                "cidade": "Andradas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1375",
                "cidade": "Andrelândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1376",
                "cidade": "Angelândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1377",
                "cidade": "Antônio Carlos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1378",
                "cidade": "Antônio Dias"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1379",
                "cidade": "Antônio Prado de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1380",
                "cidade": "Araçaí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1381",
                "cidade": "Aracitaba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1382",
                "cidade": "Araçuaí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1383",
                "cidade": "Araguari"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1384",
                "cidade": "Arantina"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1385",
                "cidade": "Araponga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1386",
                "cidade": "Araporã"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1387",
                "cidade": "Arapuá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1388",
                "cidade": "Araújos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1389",
                "cidade": "Araxá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1390",
                "cidade": "Arceburgo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1391",
                "cidade": "Arcos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1392",
                "cidade": "Areado"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1393",
                "cidade": "Argirita"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1394",
                "cidade": "Aricanduva"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1395",
                "cidade": "Arinos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1396",
                "cidade": "Astolfo Dutra"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1397",
                "cidade": "Ataléia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1398",
                "cidade": "Augusto de Lima"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1399",
                "cidade": "Baependi"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1400",
                "cidade": "Baldim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1401",
                "cidade": "Bambuí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1402",
                "cidade": "Bandeira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1403",
                "cidade": "Bandeira do Sul"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1404",
                "cidade": "Barão de Cocais"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1405",
                "cidade": "Barão de Monte Alto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1406",
                "cidade": "Barbacena"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1407",
                "cidade": "Barra Longa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1408",
                "cidade": "Barroso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1409",
                "cidade": "Bela Vista de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1410",
                "cidade": "Belmiro Braga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1411",
                "cidade": "Belo Horizonte"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1412",
                "cidade": "Belo Oriente"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1413",
                "cidade": "Belo Vale"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1414",
                "cidade": "Berilo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1415",
                "cidade": "Berizal"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1416",
                "cidade": "Bertópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1417",
                "cidade": "Betim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1418",
                "cidade": "Bias Fortes"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1419",
                "cidade": "Bicas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1420",
                "cidade": "Biquinhas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1421",
                "cidade": "Boa Esperança"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1422",
                "cidade": "Bocaina de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1423",
                "cidade": "Bocaiúva"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1424",
                "cidade": "Bom Despacho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1425",
                "cidade": "Bom Jardim de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1426",
                "cidade": "Bom Jesus da Penha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1427",
                "cidade": "Bom Jesus do Amparo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1428",
                "cidade": "Bom Jesus do Galho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1429",
                "cidade": "Bom Repouso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1430",
                "cidade": "Bom Sucesso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1431",
                "cidade": "Bonfim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1432",
                "cidade": "Bonfinópolis de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1433",
                "cidade": "Bonito de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1434",
                "cidade": "Borda da Mata"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1435",
                "cidade": "Botelhos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1436",
                "cidade": "Botumirim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1437",
                "cidade": "Brás Pires"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1438",
                "cidade": "Brasilândia de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1439",
                "cidade": "Brasília de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1440",
                "cidade": "Brasópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1441",
                "cidade": "Braúnas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1442",
                "cidade": "Brumadinho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1443",
                "cidade": "Bueno Brandão"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1444",
                "cidade": "Buenópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1445",
                "cidade": "Bugre"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1446",
                "cidade": "Buritis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1447",
                "cidade": "Buritizeiro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1448",
                "cidade": "Cabeceira Grande"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1449",
                "cidade": "Cabo Verde"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1450",
                "cidade": "Cachoeira da Prata"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1451",
                "cidade": "Cachoeira de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1452",
                "cidade": "Cachoeira de Pajeú"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1453",
                "cidade": "Cachoeira Dourada"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1454",
                "cidade": "Caetanópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1455",
                "cidade": "Caeté"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1456",
                "cidade": "Caiana"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1457",
                "cidade": "Cajuri"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1458",
                "cidade": "Caldas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1459",
                "cidade": "Camacho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1460",
                "cidade": "Camanducaia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1461",
                "cidade": "Cambuí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1462",
                "cidade": "Cambuquira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1463",
                "cidade": "Campanário"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1464",
                "cidade": "Campanha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1465",
                "cidade": "Campestre"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1466",
                "cidade": "Campina Verde"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1467",
                "cidade": "Campo Azul"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1468",
                "cidade": "Campo Belo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1469",
                "cidade": "Campo do Meio"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1470",
                "cidade": "Campo Florido"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1471",
                "cidade": "Campos Altos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1472",
                "cidade": "Campos Gerais"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1473",
                "cidade": "Cana Verde"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1474",
                "cidade": "Canaã"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1475",
                "cidade": "Canápolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1476",
                "cidade": "Candeias"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1477",
                "cidade": "Cantagalo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1478",
                "cidade": "Caparaó"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1479",
                "cidade": "Capela Nova"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1480",
                "cidade": "Capelinha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1481",
                "cidade": "Capetinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1482",
                "cidade": "Capim Branco"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1483",
                "cidade": "Capinópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1484",
                "cidade": "Capitão Andrade"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1485",
                "cidade": "Capitão Enéas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1486",
                "cidade": "Capitólio"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1487",
                "cidade": "Caputira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1488",
                "cidade": "Caraí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1489",
                "cidade": "Caranaíba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1490",
                "cidade": "Carandaí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1491",
                "cidade": "Carangola"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1492",
                "cidade": "Caratinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1493",
                "cidade": "Carbonita"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1494",
                "cidade": "Careaçu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1495",
                "cidade": "Carlos Chagas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1496",
                "cidade": "Carmésia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1497",
                "cidade": "Carmo da Cachoeira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1498",
                "cidade": "Carmo da Mata"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1499",
                "cidade": "Carmo de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1500",
                "cidade": "Carmo do Cajuru"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1501",
                "cidade": "Carmo do Paranaíba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1502",
                "cidade": "Carmo do Rio Claro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1503",
                "cidade": "Carmópolis de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1504",
                "cidade": "Carneirinho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1505",
                "cidade": "Carrancas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1506",
                "cidade": "Carvalhópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1507",
                "cidade": "Carvalhos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1508",
                "cidade": "Casa Grande"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1509",
                "cidade": "Cascalho Rico"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1510",
                "cidade": "Cássia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1511",
                "cidade": "Cataguases"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1512",
                "cidade": "Catas Altas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1513",
                "cidade": "Catas Altas da Noruega"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1514",
                "cidade": "Catuji"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1515",
                "cidade": "Catuti"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1516",
                "cidade": "Caxambu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1517",
                "cidade": "Cedro do Abaeté"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1518",
                "cidade": "Central de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1519",
                "cidade": "Centralina"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1520",
                "cidade": "Chácara"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1521",
                "cidade": "Chalé"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1522",
                "cidade": "Chapada do Norte"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1523",
                "cidade": "Chapada Gaúcha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1524",
                "cidade": "Chiador"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1525",
                "cidade": "Cipotânea"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1526",
                "cidade": "Claraval"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1527",
                "cidade": "Claro dos Poções"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1528",
                "cidade": "Cláudio"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1529",
                "cidade": "Coimbra"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1530",
                "cidade": "Coluna"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1531",
                "cidade": "Comendador Gomes"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1532",
                "cidade": "Comercinho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1533",
                "cidade": "Conceição da Aparecida"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1534",
                "cidade": "Conceição da Barra de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1535",
                "cidade": "Conceição das Alagoas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1536",
                "cidade": "Conceição das Pedras"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1537",
                "cidade": "Conceição de Ipanema"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1538",
                "cidade": "Conceição do Mato Dentro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1539",
                "cidade": "Conceição do Pará"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1540",
                "cidade": "Conceição do Rio Verde"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1541",
                "cidade": "Conceição dos Ouros"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1542",
                "cidade": "Cônego Marinho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1543",
                "cidade": "Confins"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1544",
                "cidade": "Congonhal"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1545",
                "cidade": "Congonhas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1546",
                "cidade": "Congonhas do Norte"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1547",
                "cidade": "Conquista"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1548",
                "cidade": "Conselheiro Lafaiete"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1549",
                "cidade": "Conselheiro Pena"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1550",
                "cidade": "Consolação"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1551",
                "cidade": "Contagem"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1552",
                "cidade": "Coqueiral"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1553",
                "cidade": "Coração de Jesus"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1554",
                "cidade": "Cordisburgo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1555",
                "cidade": "Cordislândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1556",
                "cidade": "Corinto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1557",
                "cidade": "Coroaci"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1558",
                "cidade": "Coromandel"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1559",
                "cidade": "Coronel Fabriciano"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1560",
                "cidade": "Coronel Murta"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1561",
                "cidade": "Coronel Pacheco"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1562",
                "cidade": "Coronel Xavier Chaves"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1563",
                "cidade": "Córrego Danta"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1564",
                "cidade": "Córrego do Bom Jesus"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1565",
                "cidade": "Córrego Fundo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1566",
                "cidade": "Córrego Novo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1567",
                "cidade": "Couto de Magalhães de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1568",
                "cidade": "Crisólita"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1569",
                "cidade": "Cristais"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1570",
                "cidade": "Cristália"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1571",
                "cidade": "Cristiano Otoni"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1572",
                "cidade": "Cristina"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1573",
                "cidade": "Crucilândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1574",
                "cidade": "Cruzeiro da Fortaleza"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1575",
                "cidade": "Cruzília"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1576",
                "cidade": "Cuparaque"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1577",
                "cidade": "Curral de Dentro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1578",
                "cidade": "Curvelo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1579",
                "cidade": "Datas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1580",
                "cidade": "Delfim Moreira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1581",
                "cidade": "Delfinópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1582",
                "cidade": "Delta"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1583",
                "cidade": "Descoberto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1584",
                "cidade": "Desterro de Entre Rios"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1585",
                "cidade": "Desterro do Melo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1586",
                "cidade": "Diamantina"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1587",
                "cidade": "Diogo de Vasconcelos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1588",
                "cidade": "Dionísio"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1589",
                "cidade": "Divinésia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1590",
                "cidade": "Divino"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1591",
                "cidade": "Divino das Laranjeiras"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1592",
                "cidade": "Divinolândia de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1593",
                "cidade": "Divinópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1594",
                "cidade": "Divisa Alegre"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1595",
                "cidade": "Divisa Nova"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1596",
                "cidade": "Divisópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1597",
                "cidade": "Dom Bosco"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1598",
                "cidade": "Dom Cavati"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1599",
                "cidade": "Dom Joaquim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1600",
                "cidade": "Dom Silvério"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1601",
                "cidade": "Dom Viçoso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1602",
                "cidade": "Dona Euzébia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1603",
                "cidade": "Dores de Campos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1604",
                "cidade": "Dores de Guanhães"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1605",
                "cidade": "Dores do Indaiá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1606",
                "cidade": "Dores do Turvo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1607",
                "cidade": "Doresópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1608",
                "cidade": "Douradoquara"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1609",
                "cidade": "Durandé"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1610",
                "cidade": "Elói Mendes"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1611",
                "cidade": "Engenheiro Caldas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1612",
                "cidade": "Engenheiro Navarro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1613",
                "cidade": "Entre Folhas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1614",
                "cidade": "Entre Rios de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1615",
                "cidade": "Ervália"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1616",
                "cidade": "Esmeraldas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1617",
                "cidade": "Espera Feliz"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1618",
                "cidade": "Espinosa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1619",
                "cidade": "Espírito Santo do Dourado"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1620",
                "cidade": "Estiva"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1621",
                "cidade": "Estrela Dalva"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1622",
                "cidade": "Estrela do Indaiá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1623",
                "cidade": "Estrela do Sul"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1624",
                "cidade": "Eugenópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1625",
                "cidade": "Ewbank da Câmara"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1626",
                "cidade": "Extrema"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1627",
                "cidade": "Fama"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1628",
                "cidade": "Faria Lemos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1629",
                "cidade": "Felício dos Santos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1630",
                "cidade": "Felisburgo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1631",
                "cidade": "Felixlândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1632",
                "cidade": "Fernandes Tourinho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1633",
                "cidade": "Ferros"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1634",
                "cidade": "Fervedouro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1635",
                "cidade": "Florestal"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1636",
                "cidade": "Formiga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1637",
                "cidade": "Formoso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1638",
                "cidade": "Fortaleza de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1639",
                "cidade": "Fortuna de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1640",
                "cidade": "Francisco Badaró"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1641",
                "cidade": "Francisco Dumont"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1642",
                "cidade": "Francisco Sá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1643",
                "cidade": "Franciscópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1644",
                "cidade": "Frei Gaspar"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1645",
                "cidade": "Frei Inocêncio"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1646",
                "cidade": "Frei Lagonegro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1647",
                "cidade": "Fronteira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1648",
                "cidade": "Fronteira dos Vales"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1649",
                "cidade": "Fruta de Leite"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1650",
                "cidade": "Frutal"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1651",
                "cidade": "Funilândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1652",
                "cidade": "Galiléia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1653",
                "cidade": "Gameleiras"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1654",
                "cidade": "Glaucilândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1655",
                "cidade": "Goiabeira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1656",
                "cidade": "Goianá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1657",
                "cidade": "Gonçalves"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1658",
                "cidade": "Gonzaga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1659",
                "cidade": "Gouveia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1660",
                "cidade": "Governador Valadares"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1661",
                "cidade": "Grão Mogol"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1662",
                "cidade": "Grupiara"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1663",
                "cidade": "Guanhães"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1664",
                "cidade": "Guapé"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1665",
                "cidade": "Guaraciaba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1666",
                "cidade": "Guaraciama"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1667",
                "cidade": "Guaranésia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1668",
                "cidade": "Guarani"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1669",
                "cidade": "Guarará"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1670",
                "cidade": "Guarda-Mor"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1671",
                "cidade": "Guaxupé"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1672",
                "cidade": "Guidoval"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1673",
                "cidade": "Guimarânia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1674",
                "cidade": "Guiricema"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1675",
                "cidade": "Gurinhatã"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1676",
                "cidade": "Heliodora"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1677",
                "cidade": "Iapu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1678",
                "cidade": "Ibertioga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1679",
                "cidade": "Ibiá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1680",
                "cidade": "Ibiaí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1681",
                "cidade": "Ibiracatu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1682",
                "cidade": "Ibiraci"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1683",
                "cidade": "Ibirité"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1684",
                "cidade": "Ibitiúra de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1685",
                "cidade": "Ibituruna"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1686",
                "cidade": "Icaraí de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1687",
                "cidade": "Igarapé"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1688",
                "cidade": "Igaratinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1689",
                "cidade": "Iguatama"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1690",
                "cidade": "Ijaci"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1691",
                "cidade": "Ilicínea"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1692",
                "cidade": "Imbé de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1693",
                "cidade": "Inconfidentes"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1694",
                "cidade": "Indaiabira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1695",
                "cidade": "Indianópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1696",
                "cidade": "Ingaí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1697",
                "cidade": "Inhapim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1698",
                "cidade": "Inhaúma"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1699",
                "cidade": "Inimutaba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1700",
                "cidade": "Ipaba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1701",
                "cidade": "Ipanema"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1702",
                "cidade": "Ipatinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1703",
                "cidade": "Ipiaçu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1704",
                "cidade": "Ipuiúna"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1705",
                "cidade": "Iraí de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1706",
                "cidade": "Itabira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1707",
                "cidade": "Itabirinha de Mantena"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1708",
                "cidade": "Itabirito"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1709",
                "cidade": "Itacambira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1710",
                "cidade": "Itacarambi"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1711",
                "cidade": "Itaguara"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1712",
                "cidade": "Itaipé"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1713",
                "cidade": "Itajubá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1714",
                "cidade": "Itamarandiba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1715",
                "cidade": "Itamarati de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1716",
                "cidade": "Itambacuri"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1717",
                "cidade": "Itambé do Mato Dentro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1718",
                "cidade": "Itamogi"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1719",
                "cidade": "Itamonte"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1720",
                "cidade": "Itanhandu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1721",
                "cidade": "Itanhomi"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1722",
                "cidade": "Itaobim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1723",
                "cidade": "Itapagipe"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1724",
                "cidade": "Itapecerica"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1725",
                "cidade": "Itapeva"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1726",
                "cidade": "Itatiaiuçu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1727",
                "cidade": "Itaú de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1728",
                "cidade": "Itaúna"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1729",
                "cidade": "Itaverava"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1730",
                "cidade": "Itinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1731",
                "cidade": "Itueta"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1732",
                "cidade": "Ituiutaba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1733",
                "cidade": "Itumirim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1734",
                "cidade": "Iturama"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1735",
                "cidade": "Itutinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1736",
                "cidade": "Jaboticatubas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1737",
                "cidade": "Jacinto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1738",
                "cidade": "Jacuí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1739",
                "cidade": "Jacutinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1740",
                "cidade": "Jaguaraçu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1741",
                "cidade": "Jaíba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1742",
                "cidade": "Jampruca"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1743",
                "cidade": "Janaúba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1744",
                "cidade": "Januária"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1745",
                "cidade": "Japaraíba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1746",
                "cidade": "Japonvar"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1747",
                "cidade": "Jeceaba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1748",
                "cidade": "Jenipapo de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1749",
                "cidade": "Jequeri"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1750",
                "cidade": "Jequitaí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1751",
                "cidade": "Jequitibá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1752",
                "cidade": "Jequitinhonha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1753",
                "cidade": "Jesuânia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1754",
                "cidade": "Joaíma"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1755",
                "cidade": "Joanésia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1756",
                "cidade": "João Monlevade"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1757",
                "cidade": "João Pinheiro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1758",
                "cidade": "Joaquim Felício"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1759",
                "cidade": "Jordânia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1760",
                "cidade": "José Gonçalves de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1761",
                "cidade": "José Raydan"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1762",
                "cidade": "Josenópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1763",
                "cidade": "Juatuba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1764",
                "cidade": "Juiz de Fora"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1765",
                "cidade": "Juramento"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1766",
                "cidade": "Juruaia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1767",
                "cidade": "Juvenília"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1768",
                "cidade": "Ladainha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1769",
                "cidade": "Lagamar"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1770",
                "cidade": "Lagoa da Prata"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1771",
                "cidade": "Lagoa dos Patos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1772",
                "cidade": "Lagoa Dourada"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1773",
                "cidade": "Lagoa Formosa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1774",
                "cidade": "Lagoa Grande"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1775",
                "cidade": "Lagoa Santa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1776",
                "cidade": "Lajinha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1777",
                "cidade": "Lambari"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1778",
                "cidade": "Lamim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1779",
                "cidade": "Laranjal"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1780",
                "cidade": "Lassance"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1781",
                "cidade": "Lavras"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1782",
                "cidade": "Leandro Ferreira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1783",
                "cidade": "Leme do Prado"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1784",
                "cidade": "Leopoldina"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1785",
                "cidade": "Liberdade"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1786",
                "cidade": "Lima Duarte"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1787",
                "cidade": "Limeira do Oeste"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1788",
                "cidade": "Lontra"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1789",
                "cidade": "Luisburgo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1790",
                "cidade": "Luislândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1791",
                "cidade": "Luminárias"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1792",
                "cidade": "Luz"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1793",
                "cidade": "Machacalis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1794",
                "cidade": "Machado"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1795",
                "cidade": "Madre de Deus de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1796",
                "cidade": "Malacacheta"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1797",
                "cidade": "Mamonas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1798",
                "cidade": "Manga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1799",
                "cidade": "Manhuaçu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1800",
                "cidade": "Manhumirim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1801",
                "cidade": "Mantena"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1802",
                "cidade": "Mar de Espanha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1803",
                "cidade": "Maravilhas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1804",
                "cidade": "Maria da Fé"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1805",
                "cidade": "Mariana"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1806",
                "cidade": "Marilac"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1807",
                "cidade": "Mário Campos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1808",
                "cidade": "Maripá de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1809",
                "cidade": "Marliéria"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1810",
                "cidade": "Marmelópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1811",
                "cidade": "Martinho Campos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1812",
                "cidade": "Martins Soares"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1813",
                "cidade": "Mata Verde"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1814",
                "cidade": "Materlândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1815",
                "cidade": "Mateus Leme"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1816",
                "cidade": "Mathias Lobato"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1817",
                "cidade": "Matias Barbosa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1818",
                "cidade": "Matias Cardoso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1819",
                "cidade": "Matipó"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1820",
                "cidade": "Mato Verde"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1821",
                "cidade": "Matozinhos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1822",
                "cidade": "Matutina"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1823",
                "cidade": "Medeiros"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1824",
                "cidade": "Medina"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1825",
                "cidade": "Mendes Pimentel"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1826",
                "cidade": "Mercês"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1827",
                "cidade": "Mesquita"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1828",
                "cidade": "Minas Novas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1829",
                "cidade": "Minduri"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1830",
                "cidade": "Mirabela"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1831",
                "cidade": "Miradouro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1832",
                "cidade": "Miraí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1833",
                "cidade": "Miravânia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1834",
                "cidade": "Moeda"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1835",
                "cidade": "Moema"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1836",
                "cidade": "Monjolos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1837",
                "cidade": "Monsenhor Paulo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1838",
                "cidade": "Montalvânia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1839",
                "cidade": "Monte Alegre de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1840",
                "cidade": "Monte Azul"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1841",
                "cidade": "Monte Belo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1842",
                "cidade": "Monte Carmelo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1843",
                "cidade": "Monte Formoso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1844",
                "cidade": "Monte Santo de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1845",
                "cidade": "Monte Sião"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1846",
                "cidade": "Montes Claros"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1847",
                "cidade": "Montezuma"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1848",
                "cidade": "Morada Nova de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1849",
                "cidade": "Morro da Garça"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1850",
                "cidade": "Morro do Pilar"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1851",
                "cidade": "Munhoz"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1852",
                "cidade": "Muriaé"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1853",
                "cidade": "Mutum"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1854",
                "cidade": "Muzambinho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1855",
                "cidade": "Nacip Raydan"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1856",
                "cidade": "Nanuque"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1857",
                "cidade": "Naque"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1858",
                "cidade": "Natalândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1859",
                "cidade": "Natércia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1860",
                "cidade": "Nazareno"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1861",
                "cidade": "Nepomuceno"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1862",
                "cidade": "Ninheira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1863",
                "cidade": "Nova Belém"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1864",
                "cidade": "Nova Era"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1865",
                "cidade": "Nova Lima"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1866",
                "cidade": "Nova Módica"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1867",
                "cidade": "Nova Ponte"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1868",
                "cidade": "Nova Porteirinha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1869",
                "cidade": "Nova Resende"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1870",
                "cidade": "Nova Serrana"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1871",
                "cidade": "Nova União"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1872",
                "cidade": "Novo Cruzeiro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1873",
                "cidade": "Novo Oriente de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1874",
                "cidade": "Novorizonte"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1875",
                "cidade": "Olaria"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1876",
                "cidade": "Olhos-d'Água"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1877",
                "cidade": "Olímpio Noronha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1878",
                "cidade": "Oliveira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1879",
                "cidade": "Oliveira Fortes"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1880",
                "cidade": "Onça de Pitangui"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1881",
                "cidade": "Oratórios"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1882",
                "cidade": "Orizânia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1883",
                "cidade": "Ouro Branco"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1884",
                "cidade": "Ouro Fino"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1885",
                "cidade": "Ouro Preto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1886",
                "cidade": "Ouro Verde de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1887",
                "cidade": "Padre Carvalho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1888",
                "cidade": "Padre Paraíso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1889",
                "cidade": "Pai Pedro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1890",
                "cidade": "Paineiras"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1891",
                "cidade": "Pains"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1892",
                "cidade": "Paiva"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1893",
                "cidade": "Palma"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1894",
                "cidade": "Palmópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1895",
                "cidade": "Papagaios"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1896",
                "cidade": "Pará de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1897",
                "cidade": "Paracatu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1898",
                "cidade": "Paraguaçu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1899",
                "cidade": "Paraisópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1900",
                "cidade": "Paraopeba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1901",
                "cidade": "Passa Quatro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1902",
                "cidade": "Passa Tempo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1903",
                "cidade": "Passa-Vinte"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1904",
                "cidade": "Passabém"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1905",
                "cidade": "Passos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1906",
                "cidade": "Patis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1907",
                "cidade": "Patos de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1908",
                "cidade": "Patrocínio"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1909",
                "cidade": "Patrocínio do Muriaé"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1910",
                "cidade": "Paula Cândido"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1911",
                "cidade": "Paulistas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1912",
                "cidade": "Pavão"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1913",
                "cidade": "Peçanha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1914",
                "cidade": "Pedra Azul"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1915",
                "cidade": "Pedra Bonita"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1916",
                "cidade": "Pedra do Anta"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1917",
                "cidade": "Pedra do Indaiá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1918",
                "cidade": "Pedra Dourada"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1919",
                "cidade": "Pedralva"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1920",
                "cidade": "Pedras de Maria da Cruz"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1921",
                "cidade": "Pedrinópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1922",
                "cidade": "Pedro Leopoldo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1923",
                "cidade": "Pedro Teixeira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1924",
                "cidade": "Pequeri"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1925",
                "cidade": "Pequi"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1926",
                "cidade": "Perdigão"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1927",
                "cidade": "Perdizes"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1928",
                "cidade": "Perdões"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1929",
                "cidade": "Periquito"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1930",
                "cidade": "Pescador"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1931",
                "cidade": "Piau"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1932",
                "cidade": "Piedade de Caratinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1933",
                "cidade": "Piedade de Ponte Nova"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1934",
                "cidade": "Piedade do Rio Grande"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1935",
                "cidade": "Piedade dos Gerais"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1936",
                "cidade": "Pimenta"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1937",
                "cidade": "Pingo-d'Água"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1938",
                "cidade": "Pintópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1939",
                "cidade": "Piracema"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1940",
                "cidade": "Pirajuba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1941",
                "cidade": "Piranga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1942",
                "cidade": "Piranguçu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1943",
                "cidade": "Piranguinho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1944",
                "cidade": "Pirapetinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1945",
                "cidade": "Pirapora"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1946",
                "cidade": "Piraúba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1947",
                "cidade": "Pitangui"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1948",
                "cidade": "Piumhi"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1949",
                "cidade": "Planura"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1950",
                "cidade": "Poço Fundo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1951",
                "cidade": "Poços de Caldas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1952",
                "cidade": "Pocrane"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1953",
                "cidade": "Pompéu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1954",
                "cidade": "Ponte Nova"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1955",
                "cidade": "Ponto Chique"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1956",
                "cidade": "Ponto dos Volantes"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1957",
                "cidade": "Porteirinha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1958",
                "cidade": "Porto Firme"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1959",
                "cidade": "Poté"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1960",
                "cidade": "Pouso Alegre"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1961",
                "cidade": "Pouso Alto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1962",
                "cidade": "Prados"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1963",
                "cidade": "Prata"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1964",
                "cidade": "Pratápolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1965",
                "cidade": "Pratinha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1966",
                "cidade": "Presidente Bernardes"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1967",
                "cidade": "Presidente Juscelino"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1968",
                "cidade": "Presidente Kubitschek"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1969",
                "cidade": "Presidente Olegário"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1970",
                "cidade": "Prudente de Morais"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1971",
                "cidade": "Quartel Geral"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1972",
                "cidade": "Queluzito"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1973",
                "cidade": "Raposos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1974",
                "cidade": "Raul Soares"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1975",
                "cidade": "Recreio"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1976",
                "cidade": "Reduto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1977",
                "cidade": "Resende Costa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1978",
                "cidade": "Resplendor"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1979",
                "cidade": "Ressaquinha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1980",
                "cidade": "Riachinho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1981",
                "cidade": "Riacho dos Machados"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1982",
                "cidade": "Ribeirão das Neves"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1983",
                "cidade": "Ribeirão Vermelho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1984",
                "cidade": "Rio Acima"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1985",
                "cidade": "Rio Casca"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1986",
                "cidade": "Rio do Prado"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1987",
                "cidade": "Rio Doce"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1988",
                "cidade": "Rio Espera"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1989",
                "cidade": "Rio Manso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1990",
                "cidade": "Rio Novo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1991",
                "cidade": "Rio Paranaíba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1992",
                "cidade": "Rio Pardo de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1993",
                "cidade": "Rio Piracicaba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1994",
                "cidade": "Rio Pomba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1995",
                "cidade": "Rio Preto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1996",
                "cidade": "Rio Vermelho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1997",
                "cidade": "Ritápolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1998",
                "cidade": "Rochedo de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "1999",
                "cidade": "Rodeiro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2000",
                "cidade": "Romaria"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2001",
                "cidade": "Rosário da Limeira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2002",
                "cidade": "Rubelita"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2003",
                "cidade": "Rubim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2004",
                "cidade": "Sabará"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2005",
                "cidade": "Sabinópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2006",
                "cidade": "Sacramento"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2007",
                "cidade": "Salinas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2008",
                "cidade": "Salto da Divisa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2009",
                "cidade": "Santa Bárbara"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2010",
                "cidade": "Santa Bárbara do Leste"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2011",
                "cidade": "Santa Bárbara do Monte Verde"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2012",
                "cidade": "Santa Bárbara do Tugúrio"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2013",
                "cidade": "Santa Cruz de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2014",
                "cidade": "Santa Cruz de Salinas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2015",
                "cidade": "Santa Cruz do Escalvado"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2016",
                "cidade": "Santa Efigênia de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2017",
                "cidade": "Santa Fé de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2018",
                "cidade": "Santa Helena de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2019",
                "cidade": "Santa Juliana"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2020",
                "cidade": "Santa Luzia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2021",
                "cidade": "Santa Margarida"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2022",
                "cidade": "Santa Maria de Itabira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2023",
                "cidade": "Santa Maria do Salto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2024",
                "cidade": "Santa Maria do Suaçuí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2025",
                "cidade": "Santa Rita de Caldas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2026",
                "cidade": "Santa Rita de Ibitipoca"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2027",
                "cidade": "Santa Rita de Jacutinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2028",
                "cidade": "Santa Rita de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2029",
                "cidade": "Santa Rita do Itueto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2030",
                "cidade": "Santa Rita do Sapucaí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2031",
                "cidade": "Santa Rosa da Serra"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2032",
                "cidade": "Santa Vitória"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2033",
                "cidade": "Santana da Vargem"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2034",
                "cidade": "Santana de Cataguases"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2035",
                "cidade": "Santana de Pirapama"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2036",
                "cidade": "Santana do Deserto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2037",
                "cidade": "Santana do Garambéu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2038",
                "cidade": "Santana do Jacaré"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2039",
                "cidade": "Santana do Manhuaçu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2040",
                "cidade": "Santana do Paraíso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2041",
                "cidade": "Santana do Riacho"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2042",
                "cidade": "Santana dos Montes"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2043",
                "cidade": "Santo Antônio do Amparo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2044",
                "cidade": "Santo Antônio do Aventureiro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2045",
                "cidade": "Santo Antônio do Grama"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2046",
                "cidade": "Santo Antônio do Itambé"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2047",
                "cidade": "Santo Antônio do Jacinto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2048",
                "cidade": "Santo Antônio do Monte"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2049",
                "cidade": "Santo Antônio do Retiro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2050",
                "cidade": "Santo Antônio do Rio Abaixo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2051",
                "cidade": "Santo Hipólito"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2052",
                "cidade": "Santos Dumont"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2053",
                "cidade": "São Bento Abade"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2054",
                "cidade": "São Brás do Suaçuí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2055",
                "cidade": "São Domingos das Dores"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2056",
                "cidade": "São Domingos do Prata"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2057",
                "cidade": "São Félix de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2058",
                "cidade": "São Francisco"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2059",
                "cidade": "São Francisco de Paula"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2060",
                "cidade": "São Francisco de Sales"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2061",
                "cidade": "São Francisco do Glória"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2062",
                "cidade": "São Geraldo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2063",
                "cidade": "São Geraldo da Piedade"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2064",
                "cidade": "São Geraldo do Baixio"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2065",
                "cidade": "São Gonçalo do Abaeté"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2066",
                "cidade": "São Gonçalo do Pará"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2067",
                "cidade": "São Gonçalo do Rio Abaixo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2068",
                "cidade": "São Gonçalo do Rio Preto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2069",
                "cidade": "São Gonçalo do Sapucaí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2070",
                "cidade": "São Gotardo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2071",
                "cidade": "São João Batista do Glória"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2072",
                "cidade": "São João da Lagoa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2073",
                "cidade": "São João da Mata"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2074",
                "cidade": "São João da Ponte"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2075",
                "cidade": "São João das Missões"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2076",
                "cidade": "São João del Rei"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2077",
                "cidade": "São João do Manhuaçu"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2078",
                "cidade": "São João do Manteninha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2079",
                "cidade": "São João do Oriente"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2080",
                "cidade": "São João do Pacuí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2081",
                "cidade": "São João do Paraíso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2082",
                "cidade": "São João Evangelista"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2083",
                "cidade": "São João Nepomuceno"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2084",
                "cidade": "São Joaquim de Bicas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2085",
                "cidade": "São José da Barra"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2086",
                "cidade": "São José da Lapa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2087",
                "cidade": "São José da Safira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2088",
                "cidade": "São José da Varginha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2089",
                "cidade": "São José do Alegre"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2090",
                "cidade": "São José do Divino"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2091",
                "cidade": "São José do Goiabal"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2092",
                "cidade": "São José do Jacuri"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2093",
                "cidade": "São José do Mantimento"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2094",
                "cidade": "São Lourenço"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2095",
                "cidade": "São Miguel do Anta"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2096",
                "cidade": "São Pedro da União"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2097",
                "cidade": "São Pedro do Suaçuí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2098",
                "cidade": "São Pedro dos Ferros"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2099",
                "cidade": "São Romão"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2100",
                "cidade": "São Roque de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2101",
                "cidade": "São Sebastião da Bela Vista"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2102",
                "cidade": "São Sebastião da Vargem Alegre"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2103",
                "cidade": "São Sebastião do Anta"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2104",
                "cidade": "São Sebastião do Maranhão"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2105",
                "cidade": "São Sebastião do Oeste"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2106",
                "cidade": "São Sebastião do Paraíso"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2107",
                "cidade": "São Sebastião do Rio Preto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2108",
                "cidade": "São Sebastião do Rio Verde"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2109",
                "cidade": "São Thomé das Letras"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2110",
                "cidade": "São Tiago"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2111",
                "cidade": "São Tomás de Aquino"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2112",
                "cidade": "São Vicente de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2113",
                "cidade": "Sapucaí-Mirim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2114",
                "cidade": "Sardoá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2115",
                "cidade": "Sarzedo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2116",
                "cidade": "Sem-Peixe"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2117",
                "cidade": "Senador Amaral"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2118",
                "cidade": "Senador Cortes"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2119",
                "cidade": "Senador Firmino"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2120",
                "cidade": "Senador José Bento"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2121",
                "cidade": "Senador Modestino Gonçalves"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2122",
                "cidade": "Senhora de Oliveira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2123",
                "cidade": "Senhora do Porto"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2124",
                "cidade": "Senhora dos Remédios"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2125",
                "cidade": "Sericita"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2126",
                "cidade": "Seritinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2127",
                "cidade": "Serra Azul de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2128",
                "cidade": "Serra da Saudade"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2129",
                "cidade": "Serra do Salitre"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2130",
                "cidade": "Serra dos Aimorés"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2131",
                "cidade": "Serrania"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2132",
                "cidade": "Serranópolis de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2133",
                "cidade": "Serranos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2134",
                "cidade": "Serro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2135",
                "cidade": "Sete Lagoas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2136",
                "cidade": "Setubinha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2137",
                "cidade": "Silveirânia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2138",
                "cidade": "Silvianópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2139",
                "cidade": "Simão Pereira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2140",
                "cidade": "Simonésia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2141",
                "cidade": "Sobrália"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2142",
                "cidade": "Soledade de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2143",
                "cidade": "Tabuleiro"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2144",
                "cidade": "Taiobeiras"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2145",
                "cidade": "Taparuba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2146",
                "cidade": "Tapira"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2147",
                "cidade": "Tapiraí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2148",
                "cidade": "Taquaraçu de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2149",
                "cidade": "Tarumirim"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2150",
                "cidade": "Teixeiras"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2151",
                "cidade": "Teófilo Otoni"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2152",
                "cidade": "Timóteo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2153",
                "cidade": "Tiradentes"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2154",
                "cidade": "Tiros"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2155",
                "cidade": "Tocantins"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2156",
                "cidade": "Tocos do Moji"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2157",
                "cidade": "Toledo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2158",
                "cidade": "Tombos"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2159",
                "cidade": "Três Corações"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2160",
                "cidade": "Três Marias"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2161",
                "cidade": "Três Pontas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2162",
                "cidade": "Tumiritinga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2163",
                "cidade": "Tupaciguara"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2164",
                "cidade": "Turmalina"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2165",
                "cidade": "Turvolândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2166",
                "cidade": "Ubá"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2167",
                "cidade": "Ubaí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2168",
                "cidade": "Ubaporanga"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2169",
                "cidade": "Uberaba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2170",
                "cidade": "Uberlândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2171",
                "cidade": "Umburatiba"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2172",
                "cidade": "Unaí"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2173",
                "cidade": "União de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2174",
                "cidade": "Uruana de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2175",
                "cidade": "Urucânia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2176",
                "cidade": "Urucuia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2177",
                "cidade": "Vargem Alegre"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2178",
                "cidade": "Vargem Bonita"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2179",
                "cidade": "Vargem Grande do Rio Pardo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2180",
                "cidade": "Varginha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2181",
                "cidade": "Varjão de Minas"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2182",
                "cidade": "Várzea da Palma"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2183",
                "cidade": "Varzelândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2184",
                "cidade": "Vazante"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2185",
                "cidade": "Verdelândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2186",
                "cidade": "Veredinha"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2187",
                "cidade": "Veríssimo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2188",
                "cidade": "Vermelho Novo"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2189",
                "cidade": "Vespasiano"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2190",
                "cidade": "Viçosa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2191",
                "cidade": "Vieiras"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2192",
                "cidade": "Virgem da Lapa"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2193",
                "cidade": "Virgínia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2194",
                "cidade": "Virginópolis"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2195",
                "cidade": "Virgolândia"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2196",
                "cidade": "Visconde do Rio Branco"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2197",
                "cidade": "Volta Grande"
            },
            {
                "uf": "MG",
                "estado": "Minas Gerais",
                "id": "2198",
                "cidade": "Wenceslau Braz"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2199",
                "cidade": "Água Clara"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2200",
                "cidade": "Alcinópolis"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2201",
                "cidade": "Amambaí"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2202",
                "cidade": "Anastácio"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2203",
                "cidade": "Anaurilândia"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2204",
                "cidade": "Angélica"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2205",
                "cidade": "Antônio João"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2206",
                "cidade": "Aparecida do Taboado"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2207",
                "cidade": "Aquidauana"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2208",
                "cidade": "Aral Moreira"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2209",
                "cidade": "Bandeirantes"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2210",
                "cidade": "Bataguassu"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2211",
                "cidade": "Bataiporã"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2212",
                "cidade": "Bela Vista"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2213",
                "cidade": "Bodoquena"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2214",
                "cidade": "Bonito"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2215",
                "cidade": "Brasilândia"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2216",
                "cidade": "Caarapó"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2217",
                "cidade": "Camapuã"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2218",
                "cidade": "Campo Grande"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2219",
                "cidade": "Caracol"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2220",
                "cidade": "Cassilândia"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2221",
                "cidade": "Chapadão do Sul"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2222",
                "cidade": "Corguinho"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2223",
                "cidade": "Coronel Sapucaia"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2224",
                "cidade": "Corumbá"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2225",
                "cidade": "Costa Rica"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2226",
                "cidade": "Coxim"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2227",
                "cidade": "Deodápolis"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2228",
                "cidade": "Dois Irmãos do Buriti"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2229",
                "cidade": "Douradina"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2230",
                "cidade": "Dourados"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2231",
                "cidade": "Eldorado"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2232",
                "cidade": "Fátima do Sul"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2233",
                "cidade": "Glória de Dourados"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2234",
                "cidade": "Guia Lopes da Laguna"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2235",
                "cidade": "Iguatemi"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2236",
                "cidade": "Inocência"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2237",
                "cidade": "Itaporã"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2238",
                "cidade": "Itaquiraí"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2239",
                "cidade": "Ivinhema"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2240",
                "cidade": "Japorã"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2241",
                "cidade": "Jaraguari"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2242",
                "cidade": "Jardim"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2243",
                "cidade": "Jateí"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2244",
                "cidade": "Juti"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2245",
                "cidade": "Ladário"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2246",
                "cidade": "Laguna Carapã"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2247",
                "cidade": "Maracaju"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2248",
                "cidade": "Miranda"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2249",
                "cidade": "Mundo Novo"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2250",
                "cidade": "Naviraí"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2251",
                "cidade": "Nioaque"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2252",
                "cidade": "Nova Alvorada do Sul"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2253",
                "cidade": "Nova Andradina"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2254",
                "cidade": "Novo Horizonte do Sul"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2255",
                "cidade": "Paranaíba"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2256",
                "cidade": "Paranhos"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2257",
                "cidade": "Pedro Gomes"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2258",
                "cidade": "Ponta Porã"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2259",
                "cidade": "Porto Murtinho"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2260",
                "cidade": "Ribas do Rio Pardo"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2261",
                "cidade": "Rio Brilhante"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2262",
                "cidade": "Rio Negro"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2263",
                "cidade": "Rio Verde de Mato Grosso"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2264",
                "cidade": "Rochedo"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2265",
                "cidade": "Santa Rita do Pardo"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2266",
                "cidade": "São Gabriel do Oeste"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2267",
                "cidade": "Selvíria"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2268",
                "cidade": "Sete Quedas"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2269",
                "cidade": "Sidrolândia"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2270",
                "cidade": "Sonora"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2271",
                "cidade": "Tacuru"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2272",
                "cidade": "Taquarussu"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2273",
                "cidade": "Terenos"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2274",
                "cidade": "Três Lagoas"
            },
            {
                "uf": "MS",
                "estado": "Mato Grosso do Sul",
                "id": "2275",
                "cidade": "Vicentina"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2276",
                "cidade": "Acorizal"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2277",
                "cidade": "Água Boa"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2278",
                "cidade": "Alta Floresta"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2279",
                "cidade": "Alto Araguaia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2280",
                "cidade": "Alto Boa Vista"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2281",
                "cidade": "Alto Garças"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2282",
                "cidade": "Alto Paraguai"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2283",
                "cidade": "Alto Taquari"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2284",
                "cidade": "Apiacás"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2285",
                "cidade": "Araguaiana"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2286",
                "cidade": "Araguainha"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2287",
                "cidade": "Araputanga"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2288",
                "cidade": "Arenápolis"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2289",
                "cidade": "Aripuanã"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2290",
                "cidade": "Barão de Melgaço"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2291",
                "cidade": "Barra do Bugres"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2292",
                "cidade": "Barra do Garças"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2293",
                "cidade": "Bom Jesus do Araguaia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2294",
                "cidade": "Brasnorte"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2295",
                "cidade": "Cáceres"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2296",
                "cidade": "Campinápolis"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2297",
                "cidade": "Campo Novo do Parecis"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2298",
                "cidade": "Campo Verde"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2299",
                "cidade": "Campos de Júlio"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2300",
                "cidade": "Canabrava do Norte"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2301",
                "cidade": "Canarana"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2302",
                "cidade": "Carlinda"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2303",
                "cidade": "Castanheira"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2304",
                "cidade": "Chapada dos Guimarães"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2305",
                "cidade": "Cláudia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2306",
                "cidade": "Cocalinho"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2307",
                "cidade": "Colíder"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2308",
                "cidade": "Colniza"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2309",
                "cidade": "Comodoro"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2310",
                "cidade": "Confresa"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2311",
                "cidade": "Conquista d'Oeste"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2312",
                "cidade": "Cotriguaçu"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2313",
                "cidade": "Curvelândia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2314",
                "cidade": "Cuiabá"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2315",
                "cidade": "Denise"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2316",
                "cidade": "Diamantino"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2317",
                "cidade": "Dom Aquino"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2318",
                "cidade": "Feliz Natal"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2319",
                "cidade": "Figueirópolis d'Oeste"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2320",
                "cidade": "Gaúcha do Norte"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2321",
                "cidade": "General Carneiro"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2322",
                "cidade": "Glória d'Oeste"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2323",
                "cidade": "Guarantã do Norte"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2324",
                "cidade": "Guiratinga"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2325",
                "cidade": "Indiavaí"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2326",
                "cidade": "Itaúba"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2327",
                "cidade": "Itiquira"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2328",
                "cidade": "Jaciara"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2329",
                "cidade": "Jangada"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2330",
                "cidade": "Jauru"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2331",
                "cidade": "Juara"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2332",
                "cidade": "Juína"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2333",
                "cidade": "Juruena"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2334",
                "cidade": "Juscimeira"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2335",
                "cidade": "Lambari d'Oeste"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2336",
                "cidade": "Lucas do Rio Verde"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2337",
                "cidade": "Luciára"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2338",
                "cidade": "Marcelândia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2339",
                "cidade": "Matupá"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2340",
                "cidade": "Mirassol d'Oeste"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2341",
                "cidade": "Nobres"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2342",
                "cidade": "Nortelândia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2343",
                "cidade": "Nossa Senhora do Livramento"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2344",
                "cidade": "Nova Bandeirantes"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2345",
                "cidade": "Nova Brasilândia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2346",
                "cidade": "Nova Canãa do Norte"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2347",
                "cidade": "Nova Guarita"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2348",
                "cidade": "Nova Lacerda"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2349",
                "cidade": "Nova Marilândia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2350",
                "cidade": "Nova Maringá"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2351",
                "cidade": "Nova Monte Verde"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2352",
                "cidade": "Nova Mutum"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2353",
                "cidade": "Nova Nazaré"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2354",
                "cidade": "Nova Olímpia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2355",
                "cidade": "Nova Santa Helena"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2356",
                "cidade": "Nova Ubiratã"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2357",
                "cidade": "Nova Xavantina"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2358",
                "cidade": "Novo Horizonte do Norte"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2359",
                "cidade": "Novo Mundo"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2360",
                "cidade": "Novo Santo Antônio"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2361",
                "cidade": "Novo São Joaquim"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2362",
                "cidade": "Paranaíta"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2363",
                "cidade": "Paranatinga"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2364",
                "cidade": "Pedra Preta"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2365",
                "cidade": "Peixoto de Azevedo"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2366",
                "cidade": "Planalto da Serra"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2367",
                "cidade": "Poconé"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2368",
                "cidade": "Pontal do Araguaia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2369",
                "cidade": "Ponte Branca"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2370",
                "cidade": "Pontes e Lacerda"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2371",
                "cidade": "Porto Alegre do Norte"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2372",
                "cidade": "Porto dos Gaúchos"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2373",
                "cidade": "Porto Esperidião"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2374",
                "cidade": "Porto Estrela"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2375",
                "cidade": "Poxoréo"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2376",
                "cidade": "Primavera do Leste"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2377",
                "cidade": "Querência"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2378",
                "cidade": "Reserva do Cabaçal"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2379",
                "cidade": "Ribeirão Cascalheira"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2380",
                "cidade": "Ribeirãozinho"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2381",
                "cidade": "Rio Branco"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2382",
                "cidade": "Rondolândia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2383",
                "cidade": "Rondonópolis"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2384",
                "cidade": "Rosário Oeste"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2385",
                "cidade": "Salto do Céu"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2386",
                "cidade": "Santa Carmem"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2387",
                "cidade": "Santa Cruz do Xingu"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2388",
                "cidade": "Santa Rita do Trivelato"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2389",
                "cidade": "Santa Terezinha"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2390",
                "cidade": "Santo Afonso"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2391",
                "cidade": "Santo Antônio do Leste"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2392",
                "cidade": "Santo Antônio do Leverger"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2393",
                "cidade": "São Félix do Araguaia"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2394",
                "cidade": "São José do Povo"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2395",
                "cidade": "São José do Rio Claro"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2396",
                "cidade": "São José do Xingu"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2397",
                "cidade": "São José dos Quatro Marcos"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2398",
                "cidade": "São Pedro da Cipa"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2399",
                "cidade": "Sapezal"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2400",
                "cidade": "Serra Nova Dourada"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2401",
                "cidade": "Sinop"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2402",
                "cidade": "Sorriso"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2403",
                "cidade": "Tabaporã"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2404",
                "cidade": "Tangará da Serra"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2405",
                "cidade": "Tapurah"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2406",
                "cidade": "Terra Nova do Norte"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2407",
                "cidade": "Tesouro"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2408",
                "cidade": "Torixoréu"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2409",
                "cidade": "União do Sul"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2410",
                "cidade": "Vale de São Domingos"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2411",
                "cidade": "Várzea Grande"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2412",
                "cidade": "Vera"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2413",
                "cidade": "Vila Bela da Santíssima Trindade"
            },
            {
                "uf": "MT",
                "estado": "Mato Grosso",
                "id": "2414",
                "cidade": "Vila Rica"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2415",
                "cidade": "Abaetetuba"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2416",
                "cidade": "Abel Figueiredo"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2417",
                "cidade": "Acará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2418",
                "cidade": "Afuá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2419",
                "cidade": "Água Azul do Norte"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2420",
                "cidade": "Alenquer"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2421",
                "cidade": "Almeirim"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2422",
                "cidade": "Altamira"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2423",
                "cidade": "Anajás"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2424",
                "cidade": "Ananindeua"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2425",
                "cidade": "Anapu"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2426",
                "cidade": "Augusto Corrêa"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2427",
                "cidade": "Aurora do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2428",
                "cidade": "Aveiro"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2429",
                "cidade": "Bagre"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2430",
                "cidade": "Baião"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2431",
                "cidade": "Bannach"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2432",
                "cidade": "Barcarena"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2433",
                "cidade": "Belém"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2434",
                "cidade": "Belterra"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2435",
                "cidade": "Benevides"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2436",
                "cidade": "Bom Jesus do Tocantins"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2437",
                "cidade": "Bonito"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2438",
                "cidade": "Bragança"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2439",
                "cidade": "Brasil Novo"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2440",
                "cidade": "Brejo Grande do Araguaia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2441",
                "cidade": "Breu Branco"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2442",
                "cidade": "Breves"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2443",
                "cidade": "Bujaru"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2444",
                "cidade": "Cachoeira do Arari"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2445",
                "cidade": "Cachoeira do Piriá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2446",
                "cidade": "Cametá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2447",
                "cidade": "Canaã dos Carajás"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2448",
                "cidade": "Capanema"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2449",
                "cidade": "Capitão Poço"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2450",
                "cidade": "Castanhal"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2451",
                "cidade": "Chaves"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2452",
                "cidade": "Colares"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2453",
                "cidade": "Conceição do Araguaia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2454",
                "cidade": "Concórdia do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2455",
                "cidade": "Cumaru do Norte"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2456",
                "cidade": "Curionópolis"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2457",
                "cidade": "Curralinho"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2458",
                "cidade": "Curuá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2459",
                "cidade": "Curuçá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2460",
                "cidade": "Dom Eliseu"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2461",
                "cidade": "Eldorado dos Carajás"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2462",
                "cidade": "Faro"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2463",
                "cidade": "Floresta do Araguaia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2464",
                "cidade": "Garrafão do Norte"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2465",
                "cidade": "Goianésia do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2466",
                "cidade": "Gurupá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2467",
                "cidade": "Igarapé-Açu"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2468",
                "cidade": "Igarapé-Miri"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2469",
                "cidade": "Inhangapi"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2470",
                "cidade": "Ipixuna do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2471",
                "cidade": "Irituia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2472",
                "cidade": "Itaituba"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2473",
                "cidade": "Itupiranga"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2474",
                "cidade": "Jacareacanga"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2475",
                "cidade": "Jacundá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2476",
                "cidade": "Juruti"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2477",
                "cidade": "Limoeiro do Ajuru"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2478",
                "cidade": "Mãe do Rio"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2479",
                "cidade": "Magalhães Barata"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2480",
                "cidade": "Marabá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2481",
                "cidade": "Maracanã"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2482",
                "cidade": "Marapanim"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2483",
                "cidade": "Marituba"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2484",
                "cidade": "Medicilândia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2485",
                "cidade": "Melgaço"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2486",
                "cidade": "Mocajuba"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2487",
                "cidade": "Moju"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2488",
                "cidade": "Monte Alegre"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2489",
                "cidade": "Muaná"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2490",
                "cidade": "Nova Esperança do Piriá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2491",
                "cidade": "Nova Ipixuna"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2492",
                "cidade": "Nova Timboteua"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2493",
                "cidade": "Novo Progresso"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2494",
                "cidade": "Novo Repartimento"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2495",
                "cidade": "Óbidos"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2496",
                "cidade": "Oeiras do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2497",
                "cidade": "Oriximiná"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2498",
                "cidade": "Ourém"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2499",
                "cidade": "Ourilândia do Norte"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2500",
                "cidade": "Pacajá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2501",
                "cidade": "Palestina do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2502",
                "cidade": "Paragominas"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2503",
                "cidade": "Parauapebas"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2504",
                "cidade": "Pau d'Arco"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2505",
                "cidade": "Peixe-Boi"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2506",
                "cidade": "Piçarra"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2507",
                "cidade": "Placas"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2508",
                "cidade": "Ponta de Pedras"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2509",
                "cidade": "Portel"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2510",
                "cidade": "Porto de Moz"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2511",
                "cidade": "Prainha"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2512",
                "cidade": "Primavera"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2513",
                "cidade": "Quatipuru"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2514",
                "cidade": "Redenção"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2515",
                "cidade": "Rio Maria"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2516",
                "cidade": "Rondon do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2517",
                "cidade": "Rurópolis"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2518",
                "cidade": "Salinópolis"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2519",
                "cidade": "Salvaterra"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2520",
                "cidade": "Santa Bárbara do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2521",
                "cidade": "Santa Cruz do Arari"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2522",
                "cidade": "Santa Isabel do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2523",
                "cidade": "Santa Luzia do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2524",
                "cidade": "Santa Maria das Barreiras"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2525",
                "cidade": "Santa Maria do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2526",
                "cidade": "Santana do Araguaia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2527",
                "cidade": "Santarém"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2528",
                "cidade": "Santarém Novo"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2529",
                "cidade": "Santo Antônio do Tauá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2530",
                "cidade": "São Caetano de Odivela"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2531",
                "cidade": "São Domingos do Araguaia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2532",
                "cidade": "São Domingos do Capim"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2533",
                "cidade": "São Félix do Xingu"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2534",
                "cidade": "São Francisco do Pará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2535",
                "cidade": "São Geraldo do Araguaia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2536",
                "cidade": "São João da Ponta"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2537",
                "cidade": "São João de Pirabas"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2538",
                "cidade": "São João do Araguaia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2539",
                "cidade": "São Miguel do Guamá"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2540",
                "cidade": "São Sebastião da Boa Vista"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2541",
                "cidade": "Sapucaia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2542",
                "cidade": "Senador José Porfírio"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2543",
                "cidade": "Soure"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2544",
                "cidade": "Tailândia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2545",
                "cidade": "Terra Alta"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2546",
                "cidade": "Terra Santa"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2547",
                "cidade": "Tomé-Açu"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2548",
                "cidade": "Tracuateua"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2549",
                "cidade": "Trairão"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2550",
                "cidade": "Tucumã"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2551",
                "cidade": "Tucuruí"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2552",
                "cidade": "Ulianópolis"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2553",
                "cidade": "Uruará"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2554",
                "cidade": "Vigia"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2555",
                "cidade": "Viseu"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2556",
                "cidade": "Vitória do Xingu"
            },
            {
                "uf": "PA",
                "estado": "Pará",
                "id": "2557",
                "cidade": "Xinguara"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2558",
                "cidade": "Água Branca"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2559",
                "cidade": "Aguiar"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2560",
                "cidade": "Alagoa Grande"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2561",
                "cidade": "Alagoa Nova"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2562",
                "cidade": "Alagoinha"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2563",
                "cidade": "Alcantil"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2564",
                "cidade": "Algodão de Jandaíra"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2565",
                "cidade": "Alhandra"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2566",
                "cidade": "Amparo"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2567",
                "cidade": "Aparecida"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2568",
                "cidade": "Araçagi"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2569",
                "cidade": "Arara"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2570",
                "cidade": "Araruna"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2571",
                "cidade": "Areia"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2572",
                "cidade": "Areia de Baraúnas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2573",
                "cidade": "Areial"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2574",
                "cidade": "Aroeiras"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2575",
                "cidade": "Assunção"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2576",
                "cidade": "Baía da Traição"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2577",
                "cidade": "Bananeiras"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2578",
                "cidade": "Baraúna"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2579",
                "cidade": "Barra de Santa Rosa"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2580",
                "cidade": "Barra de Santana"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2581",
                "cidade": "Barra de São Miguel"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2582",
                "cidade": "Bayeux"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2583",
                "cidade": "Belém"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2584",
                "cidade": "Belém do Brejo do Cruz"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2585",
                "cidade": "Bernardino Batista"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2586",
                "cidade": "Boa Ventura"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2587",
                "cidade": "Boa Vista"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2588",
                "cidade": "Bom Jesus"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2589",
                "cidade": "Bom Sucesso"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2590",
                "cidade": "Bonito de Santa Fé"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2591",
                "cidade": "Boqueirão"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2592",
                "cidade": "Borborema"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2593",
                "cidade": "Brejo do Cruz"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2594",
                "cidade": "Brejo dos Santos"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2595",
                "cidade": "Caaporã"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2596",
                "cidade": "Cabaceiras"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2597",
                "cidade": "Cabedelo"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2598",
                "cidade": "Cachoeira dos Índios"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2599",
                "cidade": "Cacimba de Areia"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2600",
                "cidade": "Cacimba de Dentro"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2601",
                "cidade": "Cacimbas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2602",
                "cidade": "Caiçara"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2603",
                "cidade": "Cajazeiras"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2604",
                "cidade": "Cajazeirinhas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2605",
                "cidade": "Caldas Brandão"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2606",
                "cidade": "Camalaú"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2607",
                "cidade": "Campina Grande"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2608",
                "cidade": "Campo de Santana"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2609",
                "cidade": "Capim"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2610",
                "cidade": "Caraúbas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2611",
                "cidade": "Carrapateira"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2612",
                "cidade": "Casserengue"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2613",
                "cidade": "Catingueira"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2614",
                "cidade": "Catolé do Rocha"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2615",
                "cidade": "Caturité"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2616",
                "cidade": "Conceição"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2617",
                "cidade": "Condado"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2618",
                "cidade": "Conde"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2619",
                "cidade": "Congo"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2620",
                "cidade": "Coremas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2621",
                "cidade": "Coxixola"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2622",
                "cidade": "Cruz do Espírito Santo"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2623",
                "cidade": "Cubati"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2624",
                "cidade": "Cuité"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2625",
                "cidade": "Cuité de Mamanguape"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2626",
                "cidade": "Cuitegi"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2627",
                "cidade": "Curral de Cima"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2628",
                "cidade": "Curral Velho"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2629",
                "cidade": "Damião"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2630",
                "cidade": "Desterro"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2631",
                "cidade": "Diamante"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2632",
                "cidade": "Dona Inês"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2633",
                "cidade": "Duas Estradas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2634",
                "cidade": "Emas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2635",
                "cidade": "Esperança"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2636",
                "cidade": "Fagundes"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2637",
                "cidade": "Frei Martinho"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2638",
                "cidade": "Gado Bravo"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2639",
                "cidade": "Guarabira"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2640",
                "cidade": "Gurinhém"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2641",
                "cidade": "Gurjão"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2642",
                "cidade": "Ibiara"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2643",
                "cidade": "Igaracy"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2644",
                "cidade": "Imaculada"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2645",
                "cidade": "Ingá"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2646",
                "cidade": "Itabaiana"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2647",
                "cidade": "Itaporanga"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2648",
                "cidade": "Itapororoca"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2649",
                "cidade": "Itatuba"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2650",
                "cidade": "Jacaraú"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2651",
                "cidade": "Jericó"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2652",
                "cidade": "João Pessoa"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2653",
                "cidade": "Juarez Távora"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2654",
                "cidade": "Juazeirinho"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2655",
                "cidade": "Junco do Seridó"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2656",
                "cidade": "Juripiranga"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2657",
                "cidade": "Juru"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2658",
                "cidade": "Lagoa"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2659",
                "cidade": "Lagoa de Dentro"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2660",
                "cidade": "Lagoa Seca"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2661",
                "cidade": "Lastro"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2662",
                "cidade": "Livramento"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2663",
                "cidade": "Logradouro"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2664",
                "cidade": "Lucena"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2665",
                "cidade": "Mãe d'Água"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2666",
                "cidade": "Malta"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2667",
                "cidade": "Mamanguape"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2668",
                "cidade": "Manaíra"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2669",
                "cidade": "Marcação"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2670",
                "cidade": "Mari"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2671",
                "cidade": "Marizópolis"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2672",
                "cidade": "Massaranduba"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2673",
                "cidade": "Mataraca"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2674",
                "cidade": "Matinhas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2675",
                "cidade": "Mato Grosso"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2676",
                "cidade": "Maturéia"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2677",
                "cidade": "Mogeiro"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2678",
                "cidade": "Montadas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2679",
                "cidade": "Monte Horebe"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2680",
                "cidade": "Monteiro"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2681",
                "cidade": "Mulungu"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2682",
                "cidade": "Natuba"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2683",
                "cidade": "Nazarezinho"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2684",
                "cidade": "Nova Floresta"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2685",
                "cidade": "Nova Olinda"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2686",
                "cidade": "Nova Palmeira"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2687",
                "cidade": "Olho d'Água"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2688",
                "cidade": "Olivedos"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2689",
                "cidade": "Ouro Velho"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2690",
                "cidade": "Parari"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2691",
                "cidade": "Passagem"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2692",
                "cidade": "Patos"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2693",
                "cidade": "Paulista"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2694",
                "cidade": "Pedra Branca"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2695",
                "cidade": "Pedra Lavrada"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2696",
                "cidade": "Pedras de Fogo"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2697",
                "cidade": "Pedro Régis"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2698",
                "cidade": "Piancó"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2699",
                "cidade": "Picuí"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2700",
                "cidade": "Pilar"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2701",
                "cidade": "Pilões"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2702",
                "cidade": "Pilõezinhos"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2703",
                "cidade": "Pirpirituba"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2704",
                "cidade": "Pitimbu"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2705",
                "cidade": "Pocinhos"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2706",
                "cidade": "Poço Dantas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2707",
                "cidade": "Poço de José de Moura"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2708",
                "cidade": "Pombal"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2709",
                "cidade": "Prata"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2710",
                "cidade": "Princesa Isabel"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2711",
                "cidade": "Puxinanã"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2712",
                "cidade": "Queimadas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2713",
                "cidade": "Quixabá"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2714",
                "cidade": "Remígio"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2715",
                "cidade": "Riachão"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2716",
                "cidade": "Riachão do Bacamarte"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2717",
                "cidade": "Riachão do Poço"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2718",
                "cidade": "Riacho de Santo Antônio"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2719",
                "cidade": "Riacho dos Cavalos"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2720",
                "cidade": "Rio Tinto"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2721",
                "cidade": "Salgadinho"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2722",
                "cidade": "Salgado de São Félix"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2723",
                "cidade": "Santa Cecília"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2724",
                "cidade": "Santa Cruz"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2725",
                "cidade": "Santa Helena"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2726",
                "cidade": "Santa Inês"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2727",
                "cidade": "Santa Luzia"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2728",
                "cidade": "Santa Rita"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2729",
                "cidade": "Santa Teresinha"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2730",
                "cidade": "Santana de Mangueira"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2731",
                "cidade": "Santana dos Garrotes"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2732",
                "cidade": "Santarém"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2733",
                "cidade": "Santo André"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2734",
                "cidade": "São Bentinho"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2735",
                "cidade": "São Bento"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2736",
                "cidade": "São Domingos de Pombal"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2737",
                "cidade": "São Domingos do Cariri"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2738",
                "cidade": "São Francisco"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2739",
                "cidade": "São João do Cariri"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2740",
                "cidade": "São João do Rio do Peixe"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2741",
                "cidade": "São João do Tigre"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2742",
                "cidade": "São José da Lagoa Tapada"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2743",
                "cidade": "São José de Caiana"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2744",
                "cidade": "São José de Espinharas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2745",
                "cidade": "São José de Piranhas"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2746",
                "cidade": "São José de Princesa"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2747",
                "cidade": "São José do Bonfim"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2748",
                "cidade": "São José do Brejo do Cruz"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2749",
                "cidade": "São José do Sabugi"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2750",
                "cidade": "São José dos Cordeiros"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2751",
                "cidade": "São José dos Ramos"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2752",
                "cidade": "São Mamede"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2753",
                "cidade": "São Miguel de Taipu"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2754",
                "cidade": "São Sebastião de Lagoa de Roça"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2755",
                "cidade": "São Sebastião do Umbuzeiro"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2756",
                "cidade": "Sapé"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2757",
                "cidade": "Seridó"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2758",
                "cidade": "Serra Branca"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2759",
                "cidade": "Serra da Raiz"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2760",
                "cidade": "Serra Grande"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2761",
                "cidade": "Serra Redonda"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2762",
                "cidade": "Serraria"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2763",
                "cidade": "Sertãozinho"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2764",
                "cidade": "Sobrado"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2765",
                "cidade": "Solânea"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2766",
                "cidade": "Soledade"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2767",
                "cidade": "Sossêgo"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2768",
                "cidade": "Sousa"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2769",
                "cidade": "Sumé"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2770",
                "cidade": "Taperoá"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2771",
                "cidade": "Tavares"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2772",
                "cidade": "Teixeira"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2773",
                "cidade": "Tenório"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2774",
                "cidade": "Triunfo"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2775",
                "cidade": "Uiraúna"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2776",
                "cidade": "Umbuzeiro"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2777",
                "cidade": "Várzea"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2778",
                "cidade": "Vieirópolis"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2779",
                "cidade": "Vista Serrana"
            },
            {
                "uf": "PB",
                "estado": "Paraíba",
                "id": "2780",
                "cidade": "Zabelê"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2781",
                "cidade": "Abreu e Lima"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2782",
                "cidade": "Afogados da Ingazeira"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2783",
                "cidade": "Afrânio"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2784",
                "cidade": "Agrestina"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2785",
                "cidade": "Água Preta"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2786",
                "cidade": "Águas Belas"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2787",
                "cidade": "Alagoinha"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2788",
                "cidade": "Aliança"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2789",
                "cidade": "Altinho"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2790",
                "cidade": "Amaraji"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2791",
                "cidade": "Angelim"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2792",
                "cidade": "Araçoiaba"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2793",
                "cidade": "Araripina"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2794",
                "cidade": "Arcoverde"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2795",
                "cidade": "Barra de Guabiraba"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2796",
                "cidade": "Barreiros"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2797",
                "cidade": "Belém de Maria"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2798",
                "cidade": "Belém de São Francisco"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2799",
                "cidade": "Belo Jardim"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2800",
                "cidade": "Betânia"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2801",
                "cidade": "Bezerros"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2802",
                "cidade": "Bodocó"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2803",
                "cidade": "Bom Conselho"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2804",
                "cidade": "Bom Jardim"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2805",
                "cidade": "Bonito"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2806",
                "cidade": "Brejão"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2807",
                "cidade": "Brejinho"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2808",
                "cidade": "Brejo da Madre de Deus"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2809",
                "cidade": "Buenos Aires"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2810",
                "cidade": "Buíque"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2811",
                "cidade": "Cabo de Santo Agostinho"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2812",
                "cidade": "Cabrobó"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2813",
                "cidade": "Cachoeirinha"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2814",
                "cidade": "Caetés"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2815",
                "cidade": "Calçado"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2816",
                "cidade": "Calumbi"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2817",
                "cidade": "Camaragibe"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2818",
                "cidade": "Camocim de São Félix"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2819",
                "cidade": "Camutanga"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2820",
                "cidade": "Canhotinho"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2821",
                "cidade": "Capoeiras"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2822",
                "cidade": "Carnaíba"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2823",
                "cidade": "Carnaubeira da Penha"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2824",
                "cidade": "Carpina"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2825",
                "cidade": "Caruaru"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2826",
                "cidade": "Casinhas"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2827",
                "cidade": "Catende"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2828",
                "cidade": "Cedro"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2829",
                "cidade": "Chã de Alegria"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2830",
                "cidade": "Chã Grande"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2831",
                "cidade": "Condado"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2832",
                "cidade": "Correntes"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2833",
                "cidade": "Cortês"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2834",
                "cidade": "Cumaru"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2835",
                "cidade": "Cupira"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2836",
                "cidade": "Custódia"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2837",
                "cidade": "Dormentes"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2838",
                "cidade": "Escada"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2839",
                "cidade": "Exu"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2840",
                "cidade": "Feira Nova"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2841",
                "cidade": "Fernando de Noronha"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2842",
                "cidade": "Ferreiros"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2843",
                "cidade": "Flores"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2844",
                "cidade": "Floresta"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2845",
                "cidade": "Frei Miguelinho"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2846",
                "cidade": "Gameleira"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2847",
                "cidade": "Garanhuns"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2848",
                "cidade": "Glória do Goitá"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2849",
                "cidade": "Goiana"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2850",
                "cidade": "Granito"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2851",
                "cidade": "Gravatá"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2852",
                "cidade": "Iati"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2853",
                "cidade": "Ibimirim"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2854",
                "cidade": "Ibirajuba"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2855",
                "cidade": "Igarassu"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2856",
                "cidade": "Iguaraci"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2857",
                "cidade": "Inajá"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2858",
                "cidade": "Ingazeira"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2859",
                "cidade": "Ipojuca"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2860",
                "cidade": "Ipubi"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2861",
                "cidade": "Itacuruba"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2862",
                "cidade": "Itaíba"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2863",
                "cidade": "Itamaracá"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2864",
                "cidade": "Itambé"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2865",
                "cidade": "Itapetim"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2866",
                "cidade": "Itapissuma"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2867",
                "cidade": "Itaquitinga"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2868",
                "cidade": "Jaboatão dos Guararapes"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2869",
                "cidade": "Jaqueira"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2870",
                "cidade": "Jataúba"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2871",
                "cidade": "Jatobá"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2872",
                "cidade": "João Alfredo"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2873",
                "cidade": "Joaquim Nabuco"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2874",
                "cidade": "Jucati"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2875",
                "cidade": "Jupi"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2876",
                "cidade": "Jurema"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2877",
                "cidade": "Lagoa do Carro"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2878",
                "cidade": "Lagoa do Itaenga"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2879",
                "cidade": "Lagoa do Ouro"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2880",
                "cidade": "Lagoa dos Gatos"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2881",
                "cidade": "Lagoa Grande"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2882",
                "cidade": "Lajedo"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2883",
                "cidade": "Limoeiro"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2884",
                "cidade": "Macaparana"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2885",
                "cidade": "Machados"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2886",
                "cidade": "Manari"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2887",
                "cidade": "Maraial"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2888",
                "cidade": "Mirandiba"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2889",
                "cidade": "Moreilândia"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2890",
                "cidade": "Moreno"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2891",
                "cidade": "Nazaré da Mata"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2892",
                "cidade": "Olinda"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2893",
                "cidade": "Orobó"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2894",
                "cidade": "Orocó"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2895",
                "cidade": "Ouricuri"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2896",
                "cidade": "Palmares"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2897",
                "cidade": "Palmeirina"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2898",
                "cidade": "Panelas"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2899",
                "cidade": "Paranatama"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2900",
                "cidade": "Parnamirim"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2901",
                "cidade": "Passira"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2902",
                "cidade": "Paudalho"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2903",
                "cidade": "Paulista"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2904",
                "cidade": "Pedra"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2905",
                "cidade": "Pesqueira"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2906",
                "cidade": "Petrolândia"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2907",
                "cidade": "Petrolina"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2908",
                "cidade": "Poção"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2909",
                "cidade": "Pombos"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2910",
                "cidade": "Primavera"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2911",
                "cidade": "Quipapá"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2912",
                "cidade": "Quixaba"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2913",
                "cidade": "Recife"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2914",
                "cidade": "Riacho das Almas"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2915",
                "cidade": "Ribeirão"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2916",
                "cidade": "Rio Formoso"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2917",
                "cidade": "Sairé"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2918",
                "cidade": "Salgadinho"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2919",
                "cidade": "Salgueiro"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2920",
                "cidade": "Saloá"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2921",
                "cidade": "Sanharó"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2922",
                "cidade": "Santa Cruz"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2923",
                "cidade": "Santa Cruz da Baixa Verde"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2924",
                "cidade": "Santa Cruz do Capibaribe"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2925",
                "cidade": "Santa Filomena"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2926",
                "cidade": "Santa Maria da Boa Vista"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2927",
                "cidade": "Santa Maria do Cambucá"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2928",
                "cidade": "Santa Terezinha"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2929",
                "cidade": "São Benedito do Sul"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2930",
                "cidade": "São Bento do Una"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2931",
                "cidade": "São Caitano"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2932",
                "cidade": "São João"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2933",
                "cidade": "São Joaquim do Monte"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2934",
                "cidade": "São José da Coroa Grande"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2935",
                "cidade": "São José do Belmonte"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2936",
                "cidade": "São José do Egito"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2937",
                "cidade": "São Lourenço da Mata"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2938",
                "cidade": "São Vicente Ferrer"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2939",
                "cidade": "Serra Talhada"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2940",
                "cidade": "Serrita"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2941",
                "cidade": "Sertânia"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2942",
                "cidade": "Sirinhaém"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2943",
                "cidade": "Solidão"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2944",
                "cidade": "Surubim"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2945",
                "cidade": "Tabira"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2946",
                "cidade": "Tacaimbó"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2947",
                "cidade": "Tacaratu"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2948",
                "cidade": "Tamandaré"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2949",
                "cidade": "Taquaritinga do Norte"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2950",
                "cidade": "Terezinha"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2951",
                "cidade": "Terra Nova"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2952",
                "cidade": "Timbaúba"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2953",
                "cidade": "Toritama"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2954",
                "cidade": "Tracunhaém"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2955",
                "cidade": "Trindade"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2956",
                "cidade": "Triunfo"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2957",
                "cidade": "Tupanatinga"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2958",
                "cidade": "Tuparetama"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2959",
                "cidade": "Venturosa"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2960",
                "cidade": "Verdejante"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2961",
                "cidade": "Vertente do Lério"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2962",
                "cidade": "Vertentes"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2963",
                "cidade": "Vicência"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2964",
                "cidade": "Vitória de Santo Antão"
            },
            {
                "uf": "PE",
                "estado": "Pernambuco",
                "id": "2965",
                "cidade": "Xexéu"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2966",
                "cidade": "Acauã"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2967",
                "cidade": "Agricolândia"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2968",
                "cidade": "Água Branca"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2969",
                "cidade": "Alagoinha do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2970",
                "cidade": "Alegrete do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2971",
                "cidade": "Alto Longá"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2972",
                "cidade": "Altos"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2973",
                "cidade": "Alvorada do Gurguéia"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2974",
                "cidade": "Amarante"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2975",
                "cidade": "Angical do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2976",
                "cidade": "Anísio de Abreu"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2977",
                "cidade": "Antônio Almeida"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2978",
                "cidade": "Aroazes"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2979",
                "cidade": "Arraial"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2980",
                "cidade": "Assunção do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2981",
                "cidade": "Avelino Lopes"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2982",
                "cidade": "Baixa Grande do Ribeiro"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2983",
                "cidade": "Barra d'Alcântara"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2984",
                "cidade": "Barras"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2985",
                "cidade": "Barreiras do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2986",
                "cidade": "Barro Duro"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2987",
                "cidade": "Batalha"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2988",
                "cidade": "Bela Vista do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2989",
                "cidade": "Belém do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2990",
                "cidade": "Beneditinos"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2991",
                "cidade": "Bertolínia"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2992",
                "cidade": "Betânia do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2993",
                "cidade": "Boa Hora"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2994",
                "cidade": "Bocaina"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2995",
                "cidade": "Bom Jesus"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2996",
                "cidade": "Bom Princípio do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2997",
                "cidade": "Bonfim do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2998",
                "cidade": "Boqueirão do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "2999",
                "cidade": "Brasileira"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3000",
                "cidade": "Brejo do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3001",
                "cidade": "Buriti dos Lopes"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3002",
                "cidade": "Buriti dos Montes"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3003",
                "cidade": "Cabeceiras do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3004",
                "cidade": "Cajazeiras do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3005",
                "cidade": "Cajueiro da Praia"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3006",
                "cidade": "Caldeirão Grande do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3007",
                "cidade": "Campinas do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3008",
                "cidade": "Campo Alegre do Fidalgo"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3009",
                "cidade": "Campo Grande do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3010",
                "cidade": "Campo Largo do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3011",
                "cidade": "Campo Maior"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3012",
                "cidade": "Canavieira"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3013",
                "cidade": "Canto do Buriti"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3014",
                "cidade": "Capitão de Campos"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3015",
                "cidade": "Capitão Gervásio Oliveira"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3016",
                "cidade": "Caracol"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3017",
                "cidade": "Caraúbas do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3018",
                "cidade": "Caridade do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3019",
                "cidade": "Castelo do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3020",
                "cidade": "Caxingó"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3021",
                "cidade": "Cocal"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3022",
                "cidade": "Cocal de Telha"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3023",
                "cidade": "Cocal dos Alves"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3024",
                "cidade": "Coivaras"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3025",
                "cidade": "Colônia do Gurguéia"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3026",
                "cidade": "Colônia do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3027",
                "cidade": "Conceição do Canindé"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3028",
                "cidade": "Coronel José Dias"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3029",
                "cidade": "Corrente"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3030",
                "cidade": "Cristalândia do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3031",
                "cidade": "Cristino Castro"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3032",
                "cidade": "Curimatá"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3033",
                "cidade": "Currais"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3034",
                "cidade": "Curral Novo do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3035",
                "cidade": "Curralinhos"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3036",
                "cidade": "Demerval Lobão"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3037",
                "cidade": "Dirceu Arcoverde"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3038",
                "cidade": "Dom Expedito Lopes"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3039",
                "cidade": "Dom Inocêncio"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3040",
                "cidade": "Domingos Mourão"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3041",
                "cidade": "Elesbão Veloso"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3042",
                "cidade": "Eliseu Martins"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3043",
                "cidade": "Esperantina"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3044",
                "cidade": "Fartura do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3045",
                "cidade": "Flores do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3046",
                "cidade": "Floresta do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3047",
                "cidade": "Floriano"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3048",
                "cidade": "Francinópolis"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3049",
                "cidade": "Francisco Ayres"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3050",
                "cidade": "Francisco Macedo"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3051",
                "cidade": "Francisco Santos"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3052",
                "cidade": "Fronteiras"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3053",
                "cidade": "Geminiano"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3054",
                "cidade": "Gilbués"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3055",
                "cidade": "Guadalupe"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3056",
                "cidade": "Guaribas"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3057",
                "cidade": "Hugo Napoleão"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3058",
                "cidade": "Ilha Grande"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3059",
                "cidade": "Inhuma"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3060",
                "cidade": "Ipiranga do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3061",
                "cidade": "Isaías Coelho"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3062",
                "cidade": "Itainópolis"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3063",
                "cidade": "Itaueira"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3064",
                "cidade": "Jacobina do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3065",
                "cidade": "Jaicós"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3066",
                "cidade": "Jardim do Mulato"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3067",
                "cidade": "Jatobá do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3068",
                "cidade": "Jerumenha"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3069",
                "cidade": "João Costa"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3070",
                "cidade": "Joaquim Pires"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3071",
                "cidade": "Joca Marques"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3072",
                "cidade": "José de Freitas"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3073",
                "cidade": "Juazeiro do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3074",
                "cidade": "Júlio Borges"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3075",
                "cidade": "Jurema"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3076",
                "cidade": "Lagoa Alegre"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3077",
                "cidade": "Lagoa de São Francisco"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3078",
                "cidade": "Lagoa do Barro do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3079",
                "cidade": "Lagoa do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3080",
                "cidade": "Lagoa do Sítio"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3081",
                "cidade": "Lagoinha do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3082",
                "cidade": "Landri Sales"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3083",
                "cidade": "Luís Correia"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3084",
                "cidade": "Luzilândia"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3085",
                "cidade": "Madeiro"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3086",
                "cidade": "Manoel Emídio"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3087",
                "cidade": "Marcolândia"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3088",
                "cidade": "Marcos Parente"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3089",
                "cidade": "Massapê do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3090",
                "cidade": "Matias Olímpio"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3091",
                "cidade": "Miguel Alves"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3092",
                "cidade": "Miguel Leão"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3093",
                "cidade": "Milton Brandão"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3094",
                "cidade": "Monsenhor Gil"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3095",
                "cidade": "Monsenhor Hipólito"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3096",
                "cidade": "Monte Alegre do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3097",
                "cidade": "Morro Cabeça no Tempo"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3098",
                "cidade": "Morro do Chapéu do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3099",
                "cidade": "Murici dos Portelas"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3100",
                "cidade": "Nazaré do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3101",
                "cidade": "Nossa Senhora de Nazaré"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3102",
                "cidade": "Nossa Senhora dos Remédios"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3103",
                "cidade": "Nova Santa Rita"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3104",
                "cidade": "Novo Oriente do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3105",
                "cidade": "Novo Santo Antônio"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3106",
                "cidade": "Oeiras"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3107",
                "cidade": "Olho d'Água do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3108",
                "cidade": "Padre Marcos"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3109",
                "cidade": "Paes Landim"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3110",
                "cidade": "Pajeú do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3111",
                "cidade": "Palmeira do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3112",
                "cidade": "Palmeirais"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3113",
                "cidade": "Paquetá"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3114",
                "cidade": "Parnaguá"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3115",
                "cidade": "Parnaíba"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3116",
                "cidade": "Passagem Franca do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3117",
                "cidade": "Patos do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3118",
                "cidade": "Pau d'Arco do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3119",
                "cidade": "Paulistana"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3120",
                "cidade": "Pavussu"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3121",
                "cidade": "Pedro II"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3122",
                "cidade": "Pedro Laurentino"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3123",
                "cidade": "Picos"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3124",
                "cidade": "Pimenteiras"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3125",
                "cidade": "Pio IX"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3126",
                "cidade": "Piracuruca"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3127",
                "cidade": "Piripiri"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3128",
                "cidade": "Porto"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3129",
                "cidade": "Porto Alegre do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3130",
                "cidade": "Prata do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3131",
                "cidade": "Queimada Nova"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3132",
                "cidade": "Redenção do Gurguéia"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3133",
                "cidade": "Regeneração"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3134",
                "cidade": "Riacho Frio"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3135",
                "cidade": "Ribeira do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3136",
                "cidade": "Ribeiro Gonçalves"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3137",
                "cidade": "Rio Grande do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3138",
                "cidade": "Santa Cruz do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3139",
                "cidade": "Santa Cruz dos Milagres"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3140",
                "cidade": "Santa Filomena"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3141",
                "cidade": "Santa Luz"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3142",
                "cidade": "Santa Rosa do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3143",
                "cidade": "Santana do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3144",
                "cidade": "Santo Antônio de Lisboa"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3145",
                "cidade": "Santo Antônio dos Milagres"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3146",
                "cidade": "Santo Inácio do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3147",
                "cidade": "São Braz do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3148",
                "cidade": "São Félix do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3149",
                "cidade": "São Francisco de Assis do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3150",
                "cidade": "São Francisco do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3151",
                "cidade": "São Gonçalo do Gurguéia"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3152",
                "cidade": "São Gonçalo do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3153",
                "cidade": "São João da Canabrava"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3154",
                "cidade": "São João da Fronteira"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3155",
                "cidade": "São João da Serra"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3156",
                "cidade": "São João da Varjota"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3157",
                "cidade": "São João do Arraial"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3158",
                "cidade": "São João do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3159",
                "cidade": "São José do Divino"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3160",
                "cidade": "São José do Peixe"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3161",
                "cidade": "São José do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3162",
                "cidade": "São Julião"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3163",
                "cidade": "São Lourenço do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3164",
                "cidade": "São Luis do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3165",
                "cidade": "São Miguel da Baixa Grande"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3166",
                "cidade": "São Miguel do Fidalgo"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3167",
                "cidade": "São Miguel do Tapuio"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3168",
                "cidade": "São Pedro do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3169",
                "cidade": "São Raimundo Nonato"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3170",
                "cidade": "Sebastião Barros"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3171",
                "cidade": "Sebastião Leal"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3172",
                "cidade": "Sigefredo Pacheco"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3173",
                "cidade": "Simões"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3174",
                "cidade": "Simplício Mendes"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3175",
                "cidade": "Socorro do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3176",
                "cidade": "Sussuapara"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3177",
                "cidade": "Tamboril do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3178",
                "cidade": "Tanque do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3179",
                "cidade": "Teresina"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3180",
                "cidade": "União"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3181",
                "cidade": "Uruçuí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3182",
                "cidade": "Valença do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3183",
                "cidade": "Várzea Branca"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3184",
                "cidade": "Várzea Grande"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3185",
                "cidade": "Vera Mendes"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3186",
                "cidade": "Vila Nova do Piauí"
            },
            {
                "uf": "PI",
                "estado": "Piauí",
                "id": "3187",
                "cidade": "Wall Ferraz"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3188",
                "cidade": "Abatiá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3189",
                "cidade": "Adrianópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3190",
                "cidade": "Agudos do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3191",
                "cidade": "Almirante Tamandaré"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3192",
                "cidade": "Altamira do Paraná"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3193",
                "cidade": "Alto Paraná"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3194",
                "cidade": "Alto Piquiri"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3195",
                "cidade": "Altônia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3196",
                "cidade": "Alvorada do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3197",
                "cidade": "Amaporã"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3198",
                "cidade": "Ampére"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3199",
                "cidade": "Anahy"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3200",
                "cidade": "Andirá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3201",
                "cidade": "Ângulo"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3202",
                "cidade": "Antonina"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3203",
                "cidade": "Antônio Olinto"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3204",
                "cidade": "Apucarana"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3205",
                "cidade": "Arapongas"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3206",
                "cidade": "Arapoti"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3207",
                "cidade": "Arapuã"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3208",
                "cidade": "Araruna"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3209",
                "cidade": "Araucária"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3210",
                "cidade": "Ariranha do Ivaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3211",
                "cidade": "Assaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3212",
                "cidade": "Assis Chateaubriand"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3213",
                "cidade": "Astorga"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3214",
                "cidade": "Atalaia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3215",
                "cidade": "Balsa Nova"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3216",
                "cidade": "Bandeirantes"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3217",
                "cidade": "Barbosa Ferraz"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3218",
                "cidade": "Barra do Jacaré"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3219",
                "cidade": "Barracão"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3220",
                "cidade": "Bela Vista da Caroba"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3221",
                "cidade": "Bela Vista do Paraíso"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3222",
                "cidade": "Bituruna"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3223",
                "cidade": "Boa Esperança"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3224",
                "cidade": "Boa Esperança do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3225",
                "cidade": "Boa Ventura de São Roque"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3226",
                "cidade": "Boa Vista da Aparecida"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3227",
                "cidade": "Bocaiúva do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3228",
                "cidade": "Bom Jesus do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3229",
                "cidade": "Bom Sucesso"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3230",
                "cidade": "Bom Sucesso do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3231",
                "cidade": "Borrazópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3232",
                "cidade": "Braganey"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3233",
                "cidade": "Brasilândia do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3234",
                "cidade": "Cafeara"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3235",
                "cidade": "Cafelândia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3236",
                "cidade": "Cafezal do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3237",
                "cidade": "Califórnia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3238",
                "cidade": "Cambará"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3239",
                "cidade": "Cambé"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3240",
                "cidade": "Cambira"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3241",
                "cidade": "Campina da Lagoa"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3242",
                "cidade": "Campina do Simão"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3243",
                "cidade": "Campina Grande do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3244",
                "cidade": "Campo Bonito"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3245",
                "cidade": "Campo do Tenente"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3246",
                "cidade": "Campo Largo"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3247",
                "cidade": "Campo Magro"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3248",
                "cidade": "Campo Mourão"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3249",
                "cidade": "Cândido de Abreu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3250",
                "cidade": "Candói"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3251",
                "cidade": "Cantagalo"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3252",
                "cidade": "Capanema"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3253",
                "cidade": "Capitão Leônidas Marques"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3254",
                "cidade": "Carambeí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3255",
                "cidade": "Carlópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3256",
                "cidade": "Cascavel"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3257",
                "cidade": "Castro"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3258",
                "cidade": "Catanduvas"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3259",
                "cidade": "Centenário do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3260",
                "cidade": "Cerro Azul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3261",
                "cidade": "Céu Azul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3262",
                "cidade": "Chopinzinho"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3263",
                "cidade": "Cianorte"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3264",
                "cidade": "Cidade Gaúcha"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3265",
                "cidade": "Clevelândia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3266",
                "cidade": "Colombo"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3267",
                "cidade": "Colorado"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3268",
                "cidade": "Congonhinhas"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3269",
                "cidade": "Conselheiro Mairinck"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3270",
                "cidade": "Contenda"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3271",
                "cidade": "Corbélia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3272",
                "cidade": "Cornélio Procópio"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3273",
                "cidade": "Coronel Domingos Soares"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3274",
                "cidade": "Coronel Vivida"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3275",
                "cidade": "Corumbataí do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3276",
                "cidade": "Cruz Machado"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3277",
                "cidade": "Cruzeiro do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3278",
                "cidade": "Cruzeiro do Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3279",
                "cidade": "Cruzeiro do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3280",
                "cidade": "Cruzmaltina"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3281",
                "cidade": "Curitiba"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3282",
                "cidade": "Curiúva"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3283",
                "cidade": "Diamante d'Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3284",
                "cidade": "Diamante do Norte"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3285",
                "cidade": "Diamante do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3286",
                "cidade": "Dois Vizinhos"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3287",
                "cidade": "Douradina"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3288",
                "cidade": "Doutor Camargo"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3289",
                "cidade": "Doutor Ulysses"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3290",
                "cidade": "Enéas Marques"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3291",
                "cidade": "Engenheiro Beltrão"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3292",
                "cidade": "Entre Rios do Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3293",
                "cidade": "Esperança Nova"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3294",
                "cidade": "Espigão Alto do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3295",
                "cidade": "Farol"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3296",
                "cidade": "Faxinal"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3297",
                "cidade": "Fazenda Rio Grande"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3298",
                "cidade": "Fênix"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3299",
                "cidade": "Fernandes Pinheiro"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3300",
                "cidade": "Figueira"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3301",
                "cidade": "Flor da Serra do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3302",
                "cidade": "Floraí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3303",
                "cidade": "Floresta"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3304",
                "cidade": "Florestópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3305",
                "cidade": "Flórida"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3306",
                "cidade": "Formosa do Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3307",
                "cidade": "Foz do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3308",
                "cidade": "Foz do Jordão"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3309",
                "cidade": "Francisco Alves"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3310",
                "cidade": "Francisco Beltrão"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3311",
                "cidade": "General Carneiro"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3312",
                "cidade": "Godoy Moreira"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3313",
                "cidade": "Goioerê"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3314",
                "cidade": "Goioxim"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3315",
                "cidade": "Grandes Rios"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3316",
                "cidade": "Guaíra"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3317",
                "cidade": "Guairaçá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3318",
                "cidade": "Guamiranga"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3319",
                "cidade": "Guapirama"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3320",
                "cidade": "Guaporema"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3321",
                "cidade": "Guaraci"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3322",
                "cidade": "Guaraniaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3323",
                "cidade": "Guarapuava"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3324",
                "cidade": "Guaraqueçaba"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3325",
                "cidade": "Guaratuba"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3326",
                "cidade": "Honório Serpa"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3327",
                "cidade": "Ibaiti"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3328",
                "cidade": "Ibema"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3329",
                "cidade": "Ibiporã"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3330",
                "cidade": "Icaraíma"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3331",
                "cidade": "Iguaraçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3332",
                "cidade": "Iguatu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3333",
                "cidade": "Imbaú"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3334",
                "cidade": "Imbituva"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3335",
                "cidade": "Inácio Martins"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3336",
                "cidade": "Inajá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3337",
                "cidade": "Indianópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3338",
                "cidade": "Ipiranga"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3339",
                "cidade": "Iporã"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3340",
                "cidade": "Iracema do Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3341",
                "cidade": "Irati"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3342",
                "cidade": "Iretama"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3343",
                "cidade": "Itaguajé"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3344",
                "cidade": "Itaipulândia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3345",
                "cidade": "Itambaracá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3346",
                "cidade": "Itambé"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3347",
                "cidade": "Itapejara d'Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3348",
                "cidade": "Itaperuçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3349",
                "cidade": "Itaúna do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3350",
                "cidade": "Ivaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3351",
                "cidade": "Ivaiporã"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3352",
                "cidade": "Ivaté"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3353",
                "cidade": "Ivatuba"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3354",
                "cidade": "Jaboti"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3355",
                "cidade": "Jacarezinho"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3356",
                "cidade": "Jaguapitã"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3357",
                "cidade": "Jaguariaíva"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3358",
                "cidade": "Jandaia do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3359",
                "cidade": "Janiópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3360",
                "cidade": "Japira"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3361",
                "cidade": "Japurá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3362",
                "cidade": "Jardim Alegre"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3363",
                "cidade": "Jardim Olinda"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3364",
                "cidade": "Jataizinho"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3365",
                "cidade": "Jesuítas"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3366",
                "cidade": "Joaquim Távora"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3367",
                "cidade": "Jundiaí do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3368",
                "cidade": "Juranda"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3369",
                "cidade": "Jussara"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3370",
                "cidade": "Kaloré"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3371",
                "cidade": "Lapa"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3372",
                "cidade": "Laranjal"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3373",
                "cidade": "Laranjeiras do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3374",
                "cidade": "Leópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3375",
                "cidade": "Lidianópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3376",
                "cidade": "Lindoeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3377",
                "cidade": "Loanda"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3378",
                "cidade": "Lobato"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3379",
                "cidade": "Londrina"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3380",
                "cidade": "Luiziana"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3381",
                "cidade": "Lunardelli"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3382",
                "cidade": "Lupionópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3383",
                "cidade": "Mallet"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3384",
                "cidade": "Mamborê"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3385",
                "cidade": "Mandaguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3386",
                "cidade": "Mandaguari"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3387",
                "cidade": "Mandirituba"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3388",
                "cidade": "Manfrinópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3389",
                "cidade": "Mangueirinha"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3390",
                "cidade": "Manoel Ribas"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3391",
                "cidade": "Marechal Cândido Rondon"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3392",
                "cidade": "Maria Helena"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3393",
                "cidade": "Marialva"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3394",
                "cidade": "Marilândia do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3395",
                "cidade": "Marilena"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3396",
                "cidade": "Mariluz"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3397",
                "cidade": "Maringá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3398",
                "cidade": "Mariópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3399",
                "cidade": "Maripá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3400",
                "cidade": "Marmeleiro"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3401",
                "cidade": "Marquinho"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3402",
                "cidade": "Marumbi"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3403",
                "cidade": "Matelândia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3404",
                "cidade": "Matinhos"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3405",
                "cidade": "Mato Rico"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3406",
                "cidade": "Mauá da Serra"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3407",
                "cidade": "Medianeira"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3408",
                "cidade": "Mercedes"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3409",
                "cidade": "Mirador"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3410",
                "cidade": "Miraselva"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3411",
                "cidade": "Missal"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3412",
                "cidade": "Moreira Sales"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3413",
                "cidade": "Morretes"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3414",
                "cidade": "Munhoz de Melo"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3415",
                "cidade": "Nossa Senhora das Graças"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3416",
                "cidade": "Nova Aliança do Ivaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3417",
                "cidade": "Nova América da Colina"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3418",
                "cidade": "Nova Aurora"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3419",
                "cidade": "Nova Cantu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3420",
                "cidade": "Nova Esperança"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3421",
                "cidade": "Nova Esperança do Sudoeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3422",
                "cidade": "Nova Fátima"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3423",
                "cidade": "Nova Laranjeiras"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3424",
                "cidade": "Nova Londrina"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3425",
                "cidade": "Nova Olímpia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3426",
                "cidade": "Nova Prata do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3427",
                "cidade": "Nova Santa Bárbara"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3428",
                "cidade": "Nova Santa Rosa"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3429",
                "cidade": "Nova Tebas"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3430",
                "cidade": "Novo Itacolomi"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3431",
                "cidade": "Ortigueira"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3432",
                "cidade": "Ourizona"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3433",
                "cidade": "Ouro Verde do Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3434",
                "cidade": "Paiçandu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3435",
                "cidade": "Palmas"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3436",
                "cidade": "Palmeira"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3437",
                "cidade": "Palmital"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3438",
                "cidade": "Palotina"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3439",
                "cidade": "Paraíso do Norte"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3440",
                "cidade": "Paranacity"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3441",
                "cidade": "Paranaguá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3442",
                "cidade": "Paranapoema"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3443",
                "cidade": "Paranavaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3444",
                "cidade": "Pato Bragado"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3445",
                "cidade": "Pato Branco"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3446",
                "cidade": "Paula Freitas"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3447",
                "cidade": "Paulo Frontin"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3448",
                "cidade": "Peabiru"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3449",
                "cidade": "Perobal"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3450",
                "cidade": "Pérola"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3451",
                "cidade": "Pérola d'Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3452",
                "cidade": "Piên"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3453",
                "cidade": "Pinhais"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3454",
                "cidade": "Pinhal de São Bento"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3455",
                "cidade": "Pinhalão"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3456",
                "cidade": "Pinhão"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3457",
                "cidade": "Piraí do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3458",
                "cidade": "Piraquara"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3459",
                "cidade": "Pitanga"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3460",
                "cidade": "Pitangueiras"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3461",
                "cidade": "Planaltina do Paraná"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3462",
                "cidade": "Planalto"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3463",
                "cidade": "Ponta Grossa"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3464",
                "cidade": "Pontal do Paraná"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3465",
                "cidade": "Porecatu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3466",
                "cidade": "Porto Amazonas"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3467",
                "cidade": "Porto Barreiro"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3468",
                "cidade": "Porto Rico"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3469",
                "cidade": "Porto Vitória"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3470",
                "cidade": "Prado Ferreira"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3471",
                "cidade": "Pranchita"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3472",
                "cidade": "Presidente Castelo Branco"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3473",
                "cidade": "Primeiro de Maio"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3474",
                "cidade": "Prudentópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3475",
                "cidade": "Quarto Centenário"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3476",
                "cidade": "Quatiguá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3477",
                "cidade": "Quatro Barras"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3478",
                "cidade": "Quatro Pontes"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3479",
                "cidade": "Quedas do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3480",
                "cidade": "Querência do Norte"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3481",
                "cidade": "Quinta do Sol"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3482",
                "cidade": "Quitandinha"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3483",
                "cidade": "Ramilândia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3484",
                "cidade": "Rancho Alegre"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3485",
                "cidade": "Rancho Alegre d'Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3486",
                "cidade": "Realeza"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3487",
                "cidade": "Rebouças"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3488",
                "cidade": "Renascença"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3489",
                "cidade": "Reserva"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3490",
                "cidade": "Reserva do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3491",
                "cidade": "Ribeirão Claro"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3492",
                "cidade": "Ribeirão do Pinhal"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3493",
                "cidade": "Rio Azul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3494",
                "cidade": "Rio Bom"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3495",
                "cidade": "Rio Bonito do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3496",
                "cidade": "Rio Branco do Ivaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3497",
                "cidade": "Rio Branco do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3498",
                "cidade": "Rio Negro"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3499",
                "cidade": "Rolândia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3500",
                "cidade": "Roncador"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3501",
                "cidade": "Rondon"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3502",
                "cidade": "Rosário do Ivaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3503",
                "cidade": "Sabáudia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3504",
                "cidade": "Salgado Filho"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3505",
                "cidade": "Salto do Itararé"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3506",
                "cidade": "Salto do Lontra"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3507",
                "cidade": "Santa Amélia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3508",
                "cidade": "Santa Cecília do Pavão"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3509",
                "cidade": "Santa Cruz Monte Castelo"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3510",
                "cidade": "Santa Fé"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3511",
                "cidade": "Santa Helena"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3512",
                "cidade": "Santa Inês"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3513",
                "cidade": "Santa Isabel do Ivaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3514",
                "cidade": "Santa Izabel do Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3515",
                "cidade": "Santa Lúcia"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3516",
                "cidade": "Santa Maria do Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3517",
                "cidade": "Santa Mariana"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3518",
                "cidade": "Santa Mônica"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3519",
                "cidade": "Santa Tereza do Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3520",
                "cidade": "Santa Terezinha de Itaipu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3521",
                "cidade": "Santana do Itararé"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3522",
                "cidade": "Santo Antônio da Platina"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3523",
                "cidade": "Santo Antônio do Caiuá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3524",
                "cidade": "Santo Antônio do Paraíso"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3525",
                "cidade": "Santo Antônio do Sudoeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3526",
                "cidade": "Santo Inácio"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3527",
                "cidade": "São Carlos do Ivaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3528",
                "cidade": "São Jerônimo da Serra"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3529",
                "cidade": "São João"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3530",
                "cidade": "São João do Caiuá"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3531",
                "cidade": "São João do Ivaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3532",
                "cidade": "São João do Triunfo"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3533",
                "cidade": "São Jorge d'Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3534",
                "cidade": "São Jorge do Ivaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3535",
                "cidade": "São Jorge do Patrocínio"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3536",
                "cidade": "São José da Boa Vista"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3537",
                "cidade": "São José das Palmeiras"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3538",
                "cidade": "São José dos Pinhais"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3539",
                "cidade": "São Manoel do Paraná"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3540",
                "cidade": "São Mateus do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3541",
                "cidade": "São Miguel do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3542",
                "cidade": "São Pedro do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3543",
                "cidade": "São Pedro do Ivaí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3544",
                "cidade": "São Pedro do Paraná"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3545",
                "cidade": "São Sebastião da Amoreira"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3546",
                "cidade": "São Tomé"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3547",
                "cidade": "Sapopema"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3548",
                "cidade": "Sarandi"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3549",
                "cidade": "Saudade do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3550",
                "cidade": "Sengés"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3551",
                "cidade": "Serranópolis do Iguaçu"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3552",
                "cidade": "Sertaneja"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3553",
                "cidade": "Sertanópolis"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3554",
                "cidade": "Siqueira Campos"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3555",
                "cidade": "Sulina"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3556",
                "cidade": "Tamarana"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3557",
                "cidade": "Tamboara"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3558",
                "cidade": "Tapejara"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3559",
                "cidade": "Tapira"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3560",
                "cidade": "Teixeira Soares"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3561",
                "cidade": "Telêmaco Borba"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3562",
                "cidade": "Terra Boa"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3563",
                "cidade": "Terra Rica"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3564",
                "cidade": "Terra Roxa"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3565",
                "cidade": "Tibagi"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3566",
                "cidade": "Tijucas do Sul"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3567",
                "cidade": "Toledo"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3568",
                "cidade": "Tomazina"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3569",
                "cidade": "Três Barras do Paraná"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3570",
                "cidade": "Tunas do Paraná"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3571",
                "cidade": "Tuneiras do Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3572",
                "cidade": "Tupãssi"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3573",
                "cidade": "Turvo"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3574",
                "cidade": "Ubiratã"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3575",
                "cidade": "Umuarama"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3576",
                "cidade": "União da Vitória"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3577",
                "cidade": "Uniflor"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3578",
                "cidade": "Uraí"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3579",
                "cidade": "Ventania"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3580",
                "cidade": "Vera Cruz do Oeste"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3581",
                "cidade": "Verê"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3582",
                "cidade": "Vila Alta"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3583",
                "cidade": "Virmond"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3584",
                "cidade": "Vitorino"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3585",
                "cidade": "Wenceslau Braz"
            },
            {
                "uf": "PR",
                "estado": "Paraná",
                "id": "3586",
                "cidade": "Xambrê"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3587",
                "cidade": "Angra dos Reis"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3588",
                "cidade": "Aperibé"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3589",
                "cidade": "Araruama"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3590",
                "cidade": "Areal"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3591",
                "cidade": "Armação de Búzios"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3592",
                "cidade": "Arraial do Cabo"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3593",
                "cidade": "Barra do Piraí"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3594",
                "cidade": "Barra Mansa"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3595",
                "cidade": "Belford Roxo"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3596",
                "cidade": "Bom Jardim"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3597",
                "cidade": "Bom Jesus do Itabapoana"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3598",
                "cidade": "Cabo Frio"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3599",
                "cidade": "Cachoeiras de Macacu"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3600",
                "cidade": "Cambuci"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3601",
                "cidade": "Campos dos Goytacazes"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3602",
                "cidade": "Cantagalo"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3603",
                "cidade": "Carapebus"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3604",
                "cidade": "Cardoso Moreira"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3605",
                "cidade": "Carmo"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3606",
                "cidade": "Casimiro de Abreu"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3607",
                "cidade": "Comendador Levy Gasparian"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3608",
                "cidade": "Conceição de Macabu"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3609",
                "cidade": "Cordeiro"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3610",
                "cidade": "Duas Barras"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3611",
                "cidade": "Duque de Caxias"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3612",
                "cidade": "Engenheiro Paulo de Frontin"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3613",
                "cidade": "Guapimirim"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3614",
                "cidade": "Iguaba Grande"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3615",
                "cidade": "Itaboraí"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3616",
                "cidade": "Itaguaí"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3617",
                "cidade": "Italva"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3618",
                "cidade": "Itaocara"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3619",
                "cidade": "Itaperuna"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3620",
                "cidade": "Itatiaia"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3621",
                "cidade": "Japeri"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3622",
                "cidade": "Laje do Muriaé"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3623",
                "cidade": "Macaé"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3624",
                "cidade": "Macuco"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3625",
                "cidade": "Magé"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3626",
                "cidade": "Mangaratiba"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3627",
                "cidade": "Maricá"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3628",
                "cidade": "Mendes"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3629",
                "cidade": "Mesquita"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3630",
                "cidade": "Miguel Pereira"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3631",
                "cidade": "Miracema"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3632",
                "cidade": "Natividade"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3633",
                "cidade": "Nilópolis"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3634",
                "cidade": "Niterói"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3635",
                "cidade": "Nova Friburgo"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3636",
                "cidade": "Nova Iguaçu"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3637",
                "cidade": "Paracambi"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3638",
                "cidade": "Paraíba do Sul"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3639",
                "cidade": "Parati"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3640",
                "cidade": "Paty do Alferes"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3641",
                "cidade": "Petrópolis"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3642",
                "cidade": "Pinheiral"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3643",
                "cidade": "Piraí"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3644",
                "cidade": "Porciúncula"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3645",
                "cidade": "Porto Real"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3646",
                "cidade": "Quatis"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3647",
                "cidade": "Queimados"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3648",
                "cidade": "Quissamã"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3649",
                "cidade": "Resende"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3650",
                "cidade": "Rio Bonito"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3651",
                "cidade": "Rio Claro"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3652",
                "cidade": "Rio das Flores"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3653",
                "cidade": "Rio das Ostras"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3654",
                "cidade": "Rio de Janeiro"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3655",
                "cidade": "Santa Maria Madalena"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3656",
                "cidade": "Santo Antônio de Pádua"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3657",
                "cidade": "São Fidélis"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3658",
                "cidade": "São Francisco de Itabapoana"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3659",
                "cidade": "São Gonçalo"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3660",
                "cidade": "São João da Barra"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3661",
                "cidade": "São João de Meriti"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3662",
                "cidade": "São José de Ubá"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3663",
                "cidade": "São José do Vale do Rio Preto"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3664",
                "cidade": "São Pedro da Aldeia"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3665",
                "cidade": "São Sebastião do Alto"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3666",
                "cidade": "Sapucaia"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3667",
                "cidade": "Saquarema"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3668",
                "cidade": "Seropédica"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3669",
                "cidade": "Silva Jardim"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3670",
                "cidade": "Sumidouro"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3671",
                "cidade": "Tanguá"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3672",
                "cidade": "Teresópolis"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3673",
                "cidade": "Trajano de Morais"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3674",
                "cidade": "Três Rios"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3675",
                "cidade": "Valença"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3676",
                "cidade": "Varre-Sai"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3677",
                "cidade": "Vassouras"
            },
            {
                "uf": "RJ",
                "estado": "Rio de Janeiro",
                "id": "3678",
                "cidade": "Volta Redonda"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3679",
                "cidade": "Acari"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3680",
                "cidade": "Açu"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3681",
                "cidade": "Afonso Bezerra"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3682",
                "cidade": "Água Nova"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3683",
                "cidade": "Alexandria"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3684",
                "cidade": "Almino Afonso"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3685",
                "cidade": "Alto do Rodrigues"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3686",
                "cidade": "Angicos"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3687",
                "cidade": "Antônio Martins"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3688",
                "cidade": "Apodi"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3689",
                "cidade": "Areia Branca"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3690",
                "cidade": "Arês"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3691",
                "cidade": "Augusto Severo"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3692",
                "cidade": "Baía Formosa"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3693",
                "cidade": "Baraúna"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3694",
                "cidade": "Barcelona"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3695",
                "cidade": "Bento Fernandes"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3696",
                "cidade": "Bodó"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3697",
                "cidade": "Bom Jesus"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3698",
                "cidade": "Brejinho"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3699",
                "cidade": "Caiçara do Norte"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3700",
                "cidade": "Caiçara do Rio do Vento"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3701",
                "cidade": "Caicó"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3702",
                "cidade": "Campo Redondo"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3703",
                "cidade": "Canguaretama"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3704",
                "cidade": "Caraúbas"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3705",
                "cidade": "Carnaúba dos Dantas"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3706",
                "cidade": "Carnaubais"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3707",
                "cidade": "Ceará-Mirim"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3708",
                "cidade": "Cerro Corá"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3709",
                "cidade": "Coronel Ezequiel"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3710",
                "cidade": "Coronel João Pessoa"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3711",
                "cidade": "Cruzeta"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3712",
                "cidade": "Currais Novos"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3713",
                "cidade": "Doutor Severiano"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3714",
                "cidade": "Encanto"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3715",
                "cidade": "Equador"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3716",
                "cidade": "Espírito Santo"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3717",
                "cidade": "Extremoz"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3718",
                "cidade": "Felipe Guerra"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3719",
                "cidade": "Fernando Pedroza"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3720",
                "cidade": "Florânia"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3721",
                "cidade": "Francisco Dantas"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3722",
                "cidade": "Frutuoso Gomes"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3723",
                "cidade": "Galinhos"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3724",
                "cidade": "Goianinha"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3725",
                "cidade": "Governador Dix-Sept Rosado"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3726",
                "cidade": "Grossos"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3727",
                "cidade": "Guamaré"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3728",
                "cidade": "Ielmo Marinho"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3729",
                "cidade": "Ipanguaçu"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3730",
                "cidade": "Ipueira"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3731",
                "cidade": "Itajá"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3732",
                "cidade": "Itaú"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3733",
                "cidade": "Jaçanã"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3734",
                "cidade": "Jandaíra"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3735",
                "cidade": "Janduís"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3736",
                "cidade": "Januário Cicco"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3737",
                "cidade": "Japi"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3738",
                "cidade": "Jardim de Angicos"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3739",
                "cidade": "Jardim de Piranhas"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3740",
                "cidade": "Jardim do Seridó"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3741",
                "cidade": "João Câmara"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3742",
                "cidade": "João Dias"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3743",
                "cidade": "José da Penha"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3744",
                "cidade": "Jucurutu"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3745",
                "cidade": "Jundiá"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3746",
                "cidade": "Lagoa d'Anta"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3747",
                "cidade": "Lagoa de Pedras"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3748",
                "cidade": "Lagoa de Velhos"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3749",
                "cidade": "Lagoa Nova"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3750",
                "cidade": "Lagoa Salgada"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3751",
                "cidade": "Lajes"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3752",
                "cidade": "Lajes Pintadas"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3753",
                "cidade": "Lucrécia"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3754",
                "cidade": "Luís Gomes"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3755",
                "cidade": "Macaíba"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3756",
                "cidade": "Macau"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3757",
                "cidade": "Major Sales"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3758",
                "cidade": "Marcelino Vieira"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3759",
                "cidade": "Martins"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3760",
                "cidade": "Maxaranguape"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3761",
                "cidade": "Messias Targino"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3762",
                "cidade": "Montanhas"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3763",
                "cidade": "Monte Alegre"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3764",
                "cidade": "Monte das Gameleiras"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3765",
                "cidade": "Mossoró"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3766",
                "cidade": "Natal"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3767",
                "cidade": "Nísia Floresta"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3768",
                "cidade": "Nova Cruz"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3769",
                "cidade": "Olho-d'Água do Borges"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3770",
                "cidade": "Ouro Branco"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3771",
                "cidade": "Paraná"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3772",
                "cidade": "Paraú"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3773",
                "cidade": "Parazinho"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3774",
                "cidade": "Parelhas"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3775",
                "cidade": "Parnamirim"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3776",
                "cidade": "Passa e Fica"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3777",
                "cidade": "Passagem"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3778",
                "cidade": "Patu"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3779",
                "cidade": "Pau dos Ferros"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3780",
                "cidade": "Pedra Grande"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3781",
                "cidade": "Pedra Preta"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3782",
                "cidade": "Pedro Avelino"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3783",
                "cidade": "Pedro Velho"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3784",
                "cidade": "Pendências"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3785",
                "cidade": "Pilões"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3786",
                "cidade": "Poço Branco"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3787",
                "cidade": "Portalegre"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3788",
                "cidade": "Porto do Mangue"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3789",
                "cidade": "Presidente Juscelino"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3790",
                "cidade": "Pureza"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3791",
                "cidade": "Rafael Fernandes"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3792",
                "cidade": "Rafael Godeiro"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3793",
                "cidade": "Riacho da Cruz"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3794",
                "cidade": "Riacho de Santana"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3795",
                "cidade": "Riachuelo"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3796",
                "cidade": "Rio do Fogo"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3797",
                "cidade": "Rodolfo Fernandes"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3798",
                "cidade": "Ruy Barbosa"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3799",
                "cidade": "Santa Cruz"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3800",
                "cidade": "Santa Maria"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3801",
                "cidade": "Santana do Matos"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3802",
                "cidade": "Santana do Seridó"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3803",
                "cidade": "Santo Antônio"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3804",
                "cidade": "São Bento do Norte"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3805",
                "cidade": "São Bento do Trairí"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3806",
                "cidade": "São Fernando"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3807",
                "cidade": "São Francisco do Oeste"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3808",
                "cidade": "São Gonçalo do Amarante"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3809",
                "cidade": "São João do Sabugi"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3810",
                "cidade": "São José de Mipibu"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3811",
                "cidade": "São José do Campestre"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3812",
                "cidade": "São José do Seridó"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3813",
                "cidade": "São Miguel"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3814",
                "cidade": "São Miguel de Touros"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3815",
                "cidade": "São Paulo do Potengi"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3816",
                "cidade": "São Pedro"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3817",
                "cidade": "São Rafael"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3818",
                "cidade": "São Tomé"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3819",
                "cidade": "São Vicente"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3820",
                "cidade": "Senador Elói de Souza"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3821",
                "cidade": "Senador Georgino Avelino"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3822",
                "cidade": "Serra de São Bento"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3823",
                "cidade": "Serra do Mel"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3824",
                "cidade": "Serra Negra do Norte"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3825",
                "cidade": "Serrinha"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3826",
                "cidade": "Serrinha dos Pintos"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3827",
                "cidade": "Severiano Melo"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3828",
                "cidade": "Sítio Novo"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3829",
                "cidade": "Taboleiro Grande"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3830",
                "cidade": "Taipu"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3831",
                "cidade": "Tangará"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3832",
                "cidade": "Tenente Ananias"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3833",
                "cidade": "Tenente Laurentino Cruz"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3834",
                "cidade": "Tibau"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3835",
                "cidade": "Tibau do Sul"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3836",
                "cidade": "Timbaúba dos Batistas"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3837",
                "cidade": "Touros"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3838",
                "cidade": "Triunfo Potiguar"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3839",
                "cidade": "Umarizal"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3840",
                "cidade": "Upanema"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3841",
                "cidade": "Várzea"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3842",
                "cidade": "Venha-Ver"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3843",
                "cidade": "Vera Cruz"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3844",
                "cidade": "Viçosa"
            },
            {
                "uf": "RN",
                "estado": "Rio Grande do Norte",
                "id": "3845",
                "cidade": "Vila Flor"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3846",
                "cidade": "Alta Floresta d'Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3847",
                "cidade": "Alto Alegre do Parecis"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3848",
                "cidade": "Alto Paraíso"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3849",
                "cidade": "Alvorada d'Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3850",
                "cidade": "Ariquemes"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3851",
                "cidade": "Buritis"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3852",
                "cidade": "Cabixi"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3853",
                "cidade": "Cacaulândia"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3854",
                "cidade": "Cacoal"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3855",
                "cidade": "Campo Novo de Rondônia"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3856",
                "cidade": "Candeias do Jamari"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3857",
                "cidade": "Castanheiras"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3858",
                "cidade": "Cerejeiras"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3859",
                "cidade": "Chupinguaia"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3860",
                "cidade": "Colorado do Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3861",
                "cidade": "Corumbiara"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3862",
                "cidade": "Costa Marques"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3863",
                "cidade": "Cujubim"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3864",
                "cidade": "Espigão d'Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3865",
                "cidade": "Governador Jorge Teixeira"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3866",
                "cidade": "Guajará-Mirim"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3867",
                "cidade": "Itapuã do Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3868",
                "cidade": "Jaru"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3869",
                "cidade": "Ji-Paraná"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3870",
                "cidade": "Machadinho d'Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3871",
                "cidade": "Ministro Andreazza"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3872",
                "cidade": "Mirante da Serra"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3873",
                "cidade": "Monte Negro"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3874",
                "cidade": "Nova Brasilândia d'Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3875",
                "cidade": "Nova Mamoré"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3876",
                "cidade": "Nova União"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3877",
                "cidade": "Novo Horizonte do Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3878",
                "cidade": "Ouro Preto do Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3879",
                "cidade": "Parecis"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3880",
                "cidade": "Pimenta Bueno"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3881",
                "cidade": "Pimenteiras do Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3882",
                "cidade": "Porto Velho"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3883",
                "cidade": "Presidente Médici"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3884",
                "cidade": "Primavera de Rondônia"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3885",
                "cidade": "Rio Crespo"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3886",
                "cidade": "Rolim de Moura"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3887",
                "cidade": "Santa Luzia d'Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3888",
                "cidade": "São Felipe d'Oeste"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3889",
                "cidade": "São Francisco do Guaporé"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3890",
                "cidade": "São Miguel do Guaporé"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3891",
                "cidade": "Seringueiras"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3892",
                "cidade": "Teixeirópolis"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3893",
                "cidade": "Theobroma"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3894",
                "cidade": "Urupá"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3895",
                "cidade": "Vale do Anari"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3896",
                "cidade": "Vale do Paraíso"
            },
            {
                "uf": "RO",
                "estado": "Rondônia",
                "id": "3897",
                "cidade": "Vilhena"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3898",
                "cidade": "Alto Alegre"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3899",
                "cidade": "Amajari"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3900",
                "cidade": "Boa Vista"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3901",
                "cidade": "Bonfim"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3902",
                "cidade": "Cantá"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3903",
                "cidade": "Caracaraí"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3904",
                "cidade": "Caroebe"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3905",
                "cidade": "Iracema"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3906",
                "cidade": "Mucajaí"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3907",
                "cidade": "Normandia"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3908",
                "cidade": "Pacaraima"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3909",
                "cidade": "Rorainópolis"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3910",
                "cidade": "São João da Baliza"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3911",
                "cidade": "São Luiz"
            },
            {
                "uf": "RR",
                "estado": "Roraima",
                "id": "3912",
                "cidade": "Uiramutã"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3913",
                "cidade": "Aceguá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3914",
                "cidade": "Água Santa"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3915",
                "cidade": "Agudo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3916",
                "cidade": "Ajuricaba"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3917",
                "cidade": "Alecrim"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3918",
                "cidade": "Alegrete"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3919",
                "cidade": "Alegria"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3920",
                "cidade": "Almirante Tamandaré do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3921",
                "cidade": "Alpestre"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3922",
                "cidade": "Alto Alegre"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3923",
                "cidade": "Alto Feliz"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3924",
                "cidade": "Alvorada"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3925",
                "cidade": "Amaral Ferrador"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3926",
                "cidade": "Ametista do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3927",
                "cidade": "André da Rocha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3928",
                "cidade": "Anta Gorda"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3929",
                "cidade": "Antônio Prado"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3930",
                "cidade": "Arambaré"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3931",
                "cidade": "Araricá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3932",
                "cidade": "Aratiba"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3933",
                "cidade": "Arroio do Meio"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3934",
                "cidade": "Arroio do Padre"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3935",
                "cidade": "Arroio do Sal"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3936",
                "cidade": "Arroio do Tigre"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3937",
                "cidade": "Arroio dos Ratos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3938",
                "cidade": "Arroio Grande"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3939",
                "cidade": "Arvorezinha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3940",
                "cidade": "Augusto Pestana"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3941",
                "cidade": "Áurea"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3942",
                "cidade": "Bagé"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3943",
                "cidade": "Balneário Pinhal"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3944",
                "cidade": "Barão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3945",
                "cidade": "Barão de Cotegipe"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3946",
                "cidade": "Barão do Triunfo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3947",
                "cidade": "Barra do Guarita"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3948",
                "cidade": "Barra do Quaraí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3949",
                "cidade": "Barra do Ribeiro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3950",
                "cidade": "Barra do Rio Azul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3951",
                "cidade": "Barra Funda"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3952",
                "cidade": "Barracão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3953",
                "cidade": "Barros Cassal"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3954",
                "cidade": "Benjamin Constan do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3955",
                "cidade": "Bento Gonçalves"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3956",
                "cidade": "Boa Vista das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3957",
                "cidade": "Boa Vista do Buricá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3958",
                "cidade": "Boa Vista do Cadeado"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3959",
                "cidade": "Boa Vista do Incra"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3960",
                "cidade": "Boa Vista do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3961",
                "cidade": "Bom Jesus"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3962",
                "cidade": "Bom Princípio"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3963",
                "cidade": "Bom Progresso"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3964",
                "cidade": "Bom Retiro do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3965",
                "cidade": "Boqueirão do Leão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3966",
                "cidade": "Bossoroca"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3967",
                "cidade": "Bozano"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3968",
                "cidade": "Braga"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3969",
                "cidade": "Brochier"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3970",
                "cidade": "Butiá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3971",
                "cidade": "Caçapava do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3972",
                "cidade": "Cacequi"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3973",
                "cidade": "Cachoeira do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3974",
                "cidade": "Cachoeirinha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3975",
                "cidade": "Cacique Doble"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3976",
                "cidade": "Caibaté"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3977",
                "cidade": "Caiçara"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3978",
                "cidade": "Camaquã"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3979",
                "cidade": "Camargo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3980",
                "cidade": "Cambará do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3981",
                "cidade": "Campestre da Serra"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3982",
                "cidade": "Campina das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3983",
                "cidade": "Campinas do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3984",
                "cidade": "Campo Bom"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3985",
                "cidade": "Campo Novo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3986",
                "cidade": "Campos Borges"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3987",
                "cidade": "Candelária"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3988",
                "cidade": "Cândido Godói"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3989",
                "cidade": "Candiota"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3990",
                "cidade": "Canela"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3991",
                "cidade": "Canguçu"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3992",
                "cidade": "Canoas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3993",
                "cidade": "Canudos do Vale"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3994",
                "cidade": "Capão Bonito do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3995",
                "cidade": "Capão da Canoa"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3996",
                "cidade": "Capão do Cipó"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3997",
                "cidade": "Capão do Leão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3998",
                "cidade": "Capela de Santana"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "3999",
                "cidade": "Capitão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4000",
                "cidade": "Capivari do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4001",
                "cidade": "Caraá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4002",
                "cidade": "Carazinho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4003",
                "cidade": "Carlos Barbosa"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4004",
                "cidade": "Carlos Gomes"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4005",
                "cidade": "Casca"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4006",
                "cidade": "Caseiros"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4007",
                "cidade": "Catuípe"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4008",
                "cidade": "Caxias do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4009",
                "cidade": "Centenário"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4010",
                "cidade": "Cerrito"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4011",
                "cidade": "Cerro Branco"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4012",
                "cidade": "Cerro Grande"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4013",
                "cidade": "Cerro Grande do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4014",
                "cidade": "Cerro Largo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4015",
                "cidade": "Chapada"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4016",
                "cidade": "Charqueadas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4017",
                "cidade": "Charrua"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4018",
                "cidade": "Chiapeta"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4019",
                "cidade": "Chuí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4020",
                "cidade": "Chuvisca"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4021",
                "cidade": "Cidreira"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4022",
                "cidade": "Ciríaco"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4023",
                "cidade": "Colinas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4024",
                "cidade": "Colorado"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4025",
                "cidade": "Condor"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4026",
                "cidade": "Constantina"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4027",
                "cidade": "Coqueiro Baixo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4028",
                "cidade": "Coqueiros do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4029",
                "cidade": "Coronel Barros"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4030",
                "cidade": "Coronel Bicaco"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4031",
                "cidade": "Coronel Pilar"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4032",
                "cidade": "Cotiporã"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4033",
                "cidade": "Coxilha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4034",
                "cidade": "Crissiumal"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4035",
                "cidade": "Cristal"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4036",
                "cidade": "Cristal do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4037",
                "cidade": "Cruz Alta"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4038",
                "cidade": "Cruzaltense"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4039",
                "cidade": "Cruzeiro do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4040",
                "cidade": "David Canabarro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4041",
                "cidade": "Derrubadas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4042",
                "cidade": "Dezesseis de Novembro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4043",
                "cidade": "Dilermando de Aguiar"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4044",
                "cidade": "Dois Irmãos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4045",
                "cidade": "Dois Irmãos das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4046",
                "cidade": "Dois Lajeados"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4047",
                "cidade": "Dom Feliciano"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4048",
                "cidade": "Dom Pedrito"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4049",
                "cidade": "Dom Pedro de Alcântara"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4050",
                "cidade": "Dona Francisca"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4051",
                "cidade": "Doutor Maurício Cardoso"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4052",
                "cidade": "Doutor Ricardo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4053",
                "cidade": "Eldorado do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4054",
                "cidade": "Encantado"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4055",
                "cidade": "Encruzilhada do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4056",
                "cidade": "Engenho Velho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4057",
                "cidade": "Entre Rios do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4058",
                "cidade": "Entre-Ijuís"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4059",
                "cidade": "Erebango"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4060",
                "cidade": "Erechim"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4061",
                "cidade": "Ernestina"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4062",
                "cidade": "Erval Grande"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4063",
                "cidade": "Erval Seco"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4064",
                "cidade": "Esmeralda"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4065",
                "cidade": "Esperança do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4066",
                "cidade": "Espumoso"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4067",
                "cidade": "Estação"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4068",
                "cidade": "Estância Velha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4069",
                "cidade": "Esteio"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4070",
                "cidade": "Estrela"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4071",
                "cidade": "Estrela Velha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4072",
                "cidade": "Eugênio de Castro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4073",
                "cidade": "Fagundes Varela"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4074",
                "cidade": "Farroupilha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4075",
                "cidade": "Faxinal do Soturno"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4076",
                "cidade": "Faxinalzinho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4077",
                "cidade": "Fazenda Vilanova"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4078",
                "cidade": "Feliz"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4079",
                "cidade": "Flores da Cunha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4080",
                "cidade": "Floriano Peixoto"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4081",
                "cidade": "Fontoura Xavier"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4082",
                "cidade": "Formigueiro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4083",
                "cidade": "Forquetinha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4084",
                "cidade": "Fortaleza dos Valos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4085",
                "cidade": "Frederico Westphalen"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4086",
                "cidade": "Garibaldi"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4087",
                "cidade": "Garruchos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4088",
                "cidade": "Gaurama"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4089",
                "cidade": "General Câmara"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4090",
                "cidade": "Gentil"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4091",
                "cidade": "Getúlio Vargas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4092",
                "cidade": "Giruá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4093",
                "cidade": "Glorinha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4094",
                "cidade": "Gramado"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4095",
                "cidade": "Gramado dos Loureiros"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4096",
                "cidade": "Gramado Xavier"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4097",
                "cidade": "Gravataí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4098",
                "cidade": "Guabiju"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4099",
                "cidade": "Guaíba"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4100",
                "cidade": "Guaporé"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4101",
                "cidade": "Guarani das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4102",
                "cidade": "Harmonia"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4103",
                "cidade": "Herval"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4104",
                "cidade": "Herveiras"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4105",
                "cidade": "Horizontina"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4106",
                "cidade": "Hulha Negra"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4107",
                "cidade": "Humaitá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4108",
                "cidade": "Ibarama"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4109",
                "cidade": "Ibiaçá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4110",
                "cidade": "Ibiraiaras"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4111",
                "cidade": "Ibirapuitã"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4112",
                "cidade": "Ibirubá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4113",
                "cidade": "Igrejinha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4114",
                "cidade": "Ijuí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4115",
                "cidade": "Ilópolis"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4116",
                "cidade": "Imbé"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4117",
                "cidade": "Imigrante"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4118",
                "cidade": "Independência"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4119",
                "cidade": "Inhacorá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4120",
                "cidade": "Ipê"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4121",
                "cidade": "Ipiranga do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4122",
                "cidade": "Iraí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4123",
                "cidade": "Itaara"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4124",
                "cidade": "Itacurubi"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4125",
                "cidade": "Itapuca"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4126",
                "cidade": "Itaqui"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4127",
                "cidade": "Itati"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4128",
                "cidade": "Itatiba do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4129",
                "cidade": "Ivorá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4130",
                "cidade": "Ivoti"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4131",
                "cidade": "Jaboticaba"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4132",
                "cidade": "Jacuizinho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4133",
                "cidade": "Jacutinga"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4134",
                "cidade": "Jaguarão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4135",
                "cidade": "Jaguari"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4136",
                "cidade": "Jaquirana"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4137",
                "cidade": "Jari"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4138",
                "cidade": "Jóia"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4139",
                "cidade": "Júlio de Castilhos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4140",
                "cidade": "Lagoa Bonita do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4141",
                "cidade": "Lagoa dos Três Cantos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4142",
                "cidade": "Lagoa Vermelha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4143",
                "cidade": "Lagoão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4144",
                "cidade": "Lajeado"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4145",
                "cidade": "Lajeado do Bugre"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4146",
                "cidade": "Lavras do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4147",
                "cidade": "Liberato Salzano"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4148",
                "cidade": "Lindolfo Collor"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4149",
                "cidade": "Linha Nova"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4150",
                "cidade": "Maçambara"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4151",
                "cidade": "Machadinho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4152",
                "cidade": "Mampituba"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4153",
                "cidade": "Manoel Viana"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4154",
                "cidade": "Maquiné"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4155",
                "cidade": "Maratá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4156",
                "cidade": "Marau"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4157",
                "cidade": "Marcelino Ramos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4158",
                "cidade": "Mariana Pimentel"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4159",
                "cidade": "Mariano Moro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4160",
                "cidade": "Marques de Souza"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4161",
                "cidade": "Mata"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4162",
                "cidade": "Mato Castelhano"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4163",
                "cidade": "Mato Leitão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4164",
                "cidade": "Mato Queimado"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4165",
                "cidade": "Maximiliano de Almeida"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4166",
                "cidade": "Minas do Leão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4167",
                "cidade": "Miraguaí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4168",
                "cidade": "Montauri"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4169",
                "cidade": "Monte Alegre dos Campos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4170",
                "cidade": "Monte Belo do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4171",
                "cidade": "Montenegro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4172",
                "cidade": "Mormaço"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4173",
                "cidade": "Morrinhos do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4174",
                "cidade": "Morro Redondo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4175",
                "cidade": "Morro Reuter"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4176",
                "cidade": "Mostardas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4177",
                "cidade": "Muçum"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4178",
                "cidade": "Muitos Capões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4179",
                "cidade": "Muliterno"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4180",
                "cidade": "Não-Me-Toque"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4181",
                "cidade": "Nicolau Vergueiro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4182",
                "cidade": "Nonoai"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4183",
                "cidade": "Nova Alvorada"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4184",
                "cidade": "Nova Araçá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4185",
                "cidade": "Nova Bassano"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4186",
                "cidade": "Nova Boa Vista"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4187",
                "cidade": "Nova Bréscia"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4188",
                "cidade": "Nova Candelária"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4189",
                "cidade": "Nova Esperança do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4190",
                "cidade": "Nova Hartz"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4191",
                "cidade": "Nova Pádua"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4192",
                "cidade": "Nova Palma"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4193",
                "cidade": "Nova Petrópolis"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4194",
                "cidade": "Nova Prata"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4195",
                "cidade": "Nova Ramada"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4196",
                "cidade": "Nova Roma do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4197",
                "cidade": "Nova Santa Rita"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4198",
                "cidade": "Novo Barreiro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4199",
                "cidade": "Novo Cabrais"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4200",
                "cidade": "Novo Hamburgo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4201",
                "cidade": "Novo Machado"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4202",
                "cidade": "Novo Tiradentes"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4203",
                "cidade": "Novo Xingu"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4204",
                "cidade": "Osório"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4205",
                "cidade": "Paim Filho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4206",
                "cidade": "Palmares do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4207",
                "cidade": "Palmeira das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4208",
                "cidade": "Palmitinho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4209",
                "cidade": "Panambi"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4210",
                "cidade": "Pântano Grande"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4211",
                "cidade": "Paraí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4212",
                "cidade": "Paraíso do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4213",
                "cidade": "Pareci Novo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4214",
                "cidade": "Parobé"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4215",
                "cidade": "Passa Sete"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4216",
                "cidade": "Passo do Sobrado"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4217",
                "cidade": "Passo Fundo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4218",
                "cidade": "Paulo Bento"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4219",
                "cidade": "Paverama"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4220",
                "cidade": "Pedras Altas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4221",
                "cidade": "Pedro Osório"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4222",
                "cidade": "Pejuçara"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4223",
                "cidade": "Pelotas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4224",
                "cidade": "Picada Café"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4225",
                "cidade": "Pinhal"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4226",
                "cidade": "Pinhal da Serra"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4227",
                "cidade": "Pinhal Grande"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4228",
                "cidade": "Pinheirinho do Vale"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4229",
                "cidade": "Pinheiro Machado"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4230",
                "cidade": "Pirapó"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4231",
                "cidade": "Piratini"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4232",
                "cidade": "Planalto"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4233",
                "cidade": "Poço das Antas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4234",
                "cidade": "Pontão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4235",
                "cidade": "Ponte Preta"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4236",
                "cidade": "Portão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4237",
                "cidade": "Porto Alegre"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4238",
                "cidade": "Porto Lucena"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4239",
                "cidade": "Porto Mauá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4240",
                "cidade": "Porto Vera Cruz"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4241",
                "cidade": "Porto Xavier"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4242",
                "cidade": "Pouso Novo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4243",
                "cidade": "Presidente Lucena"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4244",
                "cidade": "Progresso"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4245",
                "cidade": "Protásio Alves"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4246",
                "cidade": "Putinga"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4247",
                "cidade": "Quaraí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4248",
                "cidade": "Quatro Irmãos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4249",
                "cidade": "Quevedos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4250",
                "cidade": "Quinze de Novembro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4251",
                "cidade": "Redentora"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4252",
                "cidade": "Relvado"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4253",
                "cidade": "Restinga Seca"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4254",
                "cidade": "Rio dos Índios"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4255",
                "cidade": "Rio Grande"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4256",
                "cidade": "Rio Pardo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4257",
                "cidade": "Riozinho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4258",
                "cidade": "Roca Sales"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4259",
                "cidade": "Rodeio Bonito"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4260",
                "cidade": "Rolador"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4261",
                "cidade": "Rolante"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4262",
                "cidade": "Ronda Alta"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4263",
                "cidade": "Rondinha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4264",
                "cidade": "Roque Gonzales"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4265",
                "cidade": "Rosário do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4266",
                "cidade": "Sagrada Família"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4267",
                "cidade": "Saldanha Marinho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4268",
                "cidade": "Salto do Jacuí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4269",
                "cidade": "Salvador das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4270",
                "cidade": "Salvador do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4271",
                "cidade": "Sananduva"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4272",
                "cidade": "Santa Bárbara do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4273",
                "cidade": "Santa Cecília do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4274",
                "cidade": "Santa Clara do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4275",
                "cidade": "Santa Cruz do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4276",
                "cidade": "Santa Margarida do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4277",
                "cidade": "Santa Maria"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4278",
                "cidade": "Santa Maria do Herval"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4279",
                "cidade": "Santa Rosa"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4280",
                "cidade": "Santa Tereza"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4281",
                "cidade": "Santa Vitória do Palmar"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4282",
                "cidade": "Santana da Boa Vista"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4283",
                "cidade": "Santana do Livramento"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4284",
                "cidade": "Santiago"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4285",
                "cidade": "Santo Ângelo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4286",
                "cidade": "Santo Antônio da Patrulha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4287",
                "cidade": "Santo Antônio das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4288",
                "cidade": "Santo Antônio do Palma"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4289",
                "cidade": "Santo Antônio do Planalto"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4290",
                "cidade": "Santo Augusto"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4291",
                "cidade": "Santo Cristo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4292",
                "cidade": "Santo Expedito do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4293",
                "cidade": "São Borja"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4294",
                "cidade": "São Domingos do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4295",
                "cidade": "São Francisco de Assis"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4296",
                "cidade": "São Francisco de Paula"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4297",
                "cidade": "São Gabriel"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4298",
                "cidade": "São Jerônimo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4299",
                "cidade": "São João da Urtiga"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4300",
                "cidade": "São João do Polêsine"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4301",
                "cidade": "São Jorge"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4302",
                "cidade": "São José das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4303",
                "cidade": "São José do Herval"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4304",
                "cidade": "São José do Hortêncio"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4305",
                "cidade": "São José do Inhacorá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4306",
                "cidade": "São José do Norte"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4307",
                "cidade": "São José do Ouro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4308",
                "cidade": "São José do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4309",
                "cidade": "São José dos Ausentes"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4310",
                "cidade": "São Leopoldo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4311",
                "cidade": "São Lourenço do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4312",
                "cidade": "São Luiz Gonzaga"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4313",
                "cidade": "São Marcos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4314",
                "cidade": "São Martinho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4315",
                "cidade": "São Martinho da Serra"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4316",
                "cidade": "São Miguel das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4317",
                "cidade": "São Nicolau"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4318",
                "cidade": "São Paulo das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4319",
                "cidade": "São Pedro da Serra"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4320",
                "cidade": "São Pedro das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4321",
                "cidade": "São Pedro do Butiá"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4322",
                "cidade": "São Pedro do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4323",
                "cidade": "São Sebastião do Caí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4324",
                "cidade": "São Sepé"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4325",
                "cidade": "São Valentim"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4326",
                "cidade": "São Valentim do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4327",
                "cidade": "São Valério do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4328",
                "cidade": "São Vendelino"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4329",
                "cidade": "São Vicente do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4330",
                "cidade": "Sapiranga"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4331",
                "cidade": "Sapucaia do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4332",
                "cidade": "Sarandi"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4333",
                "cidade": "Seberi"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4334",
                "cidade": "Sede Nova"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4335",
                "cidade": "Segredo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4336",
                "cidade": "Selbach"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4337",
                "cidade": "Senador Salgado Filho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4338",
                "cidade": "Sentinela do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4339",
                "cidade": "Serafina Corrêa"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4340",
                "cidade": "Sério"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4341",
                "cidade": "Sertão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4342",
                "cidade": "Sertão Santana"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4343",
                "cidade": "Sete de Setembro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4344",
                "cidade": "Severiano de Almeida"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4345",
                "cidade": "Silveira Martins"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4346",
                "cidade": "Sinimbu"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4347",
                "cidade": "Sobradinho"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4348",
                "cidade": "Soledade"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4349",
                "cidade": "Tabaí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4350",
                "cidade": "Tapejara"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4351",
                "cidade": "Tapera"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4352",
                "cidade": "Tapes"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4353",
                "cidade": "Taquara"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4354",
                "cidade": "Taquari"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4355",
                "cidade": "Taquaruçu do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4356",
                "cidade": "Tavares"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4357",
                "cidade": "Tenente Portela"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4358",
                "cidade": "Terra de Areia"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4359",
                "cidade": "Teutônia"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4360",
                "cidade": "Tio Hugo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4361",
                "cidade": "Tiradentes do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4362",
                "cidade": "Toropi"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4363",
                "cidade": "Torres"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4364",
                "cidade": "Tramandaí"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4365",
                "cidade": "Travesseiro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4366",
                "cidade": "Três Arroios"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4367",
                "cidade": "Três Cachoeiras"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4368",
                "cidade": "Três Coroas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4369",
                "cidade": "Três de Maio"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4370",
                "cidade": "Três Forquilhas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4371",
                "cidade": "Três Palmeiras"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4372",
                "cidade": "Três Passos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4373",
                "cidade": "Trindade do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4374",
                "cidade": "Triunfo"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4375",
                "cidade": "Tucunduva"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4376",
                "cidade": "Tunas"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4377",
                "cidade": "Tupanci do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4378",
                "cidade": "Tupanciretã"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4379",
                "cidade": "Tupandi"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4380",
                "cidade": "Tuparendi"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4381",
                "cidade": "Turuçu"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4382",
                "cidade": "Ubiretama"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4383",
                "cidade": "União da Serra"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4384",
                "cidade": "Unistalda"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4385",
                "cidade": "Uruguaiana"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4386",
                "cidade": "Vacaria"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4387",
                "cidade": "Vale do Sol"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4388",
                "cidade": "Vale Real"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4389",
                "cidade": "Vale Verde"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4390",
                "cidade": "Vanini"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4391",
                "cidade": "Venâncio Aires"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4392",
                "cidade": "Vera Cruz"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4393",
                "cidade": "Veranópolis"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4394",
                "cidade": "Vespasiano Correa"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4395",
                "cidade": "Viadutos"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4396",
                "cidade": "Viamão"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4397",
                "cidade": "Vicente Dutra"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4398",
                "cidade": "Victor Graeff"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4399",
                "cidade": "Vila Flores"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4400",
                "cidade": "Vila Lângaro"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4401",
                "cidade": "Vila Maria"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4402",
                "cidade": "Vila Nova do Sul"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4403",
                "cidade": "Vista Alegre"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4404",
                "cidade": "Vista Alegre do Prata"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4405",
                "cidade": "Vista Gaúcha"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4406",
                "cidade": "Vitória das Missões"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4407",
                "cidade": "Westfália"
            },
            {
                "uf": "RS",
                "estado": "Rio Grande do Sul",
                "id": "4408",
                "cidade": "Xangri-lá"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4409",
                "cidade": "Abdon Batista"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4410",
                "cidade": "Abelardo Luz"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4411",
                "cidade": "Agrolândia"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4412",
                "cidade": "Agronômica"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4413",
                "cidade": "Água Doce"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4414",
                "cidade": "Águas de Chapecó"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4415",
                "cidade": "Águas Frias"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4416",
                "cidade": "Águas Mornas"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4417",
                "cidade": "Alfredo Wagner"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4418",
                "cidade": "Alto Bela Vista"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4419",
                "cidade": "Anchieta"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4420",
                "cidade": "Angelina"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4421",
                "cidade": "Anita Garibaldi"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4422",
                "cidade": "Anitápolis"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4423",
                "cidade": "Antônio Carlos"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4424",
                "cidade": "Apiúna"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4425",
                "cidade": "Arabutã"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4426",
                "cidade": "Araquari"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4427",
                "cidade": "Araranguá"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4428",
                "cidade": "Armazém"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4429",
                "cidade": "Arroio Trinta"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4430",
                "cidade": "Arvoredo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4431",
                "cidade": "Ascurra"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4432",
                "cidade": "Atalanta"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4433",
                "cidade": "Aurora"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4434",
                "cidade": "Balneário Arroio do Silva"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4435",
                "cidade": "Balneário Barra do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4436",
                "cidade": "Balneário Camboriú"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4437",
                "cidade": "Balneário Gaivota"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4438",
                "cidade": "Bandeirante"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4439",
                "cidade": "Barra Bonita"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4440",
                "cidade": "Barra Velha"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4441",
                "cidade": "Bela Vista do Toldo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4442",
                "cidade": "Belmonte"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4443",
                "cidade": "Benedito Novo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4444",
                "cidade": "Biguaçu"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4445",
                "cidade": "Blumenau"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4446",
                "cidade": "Bocaina do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4447",
                "cidade": "Bom Jardim da Serra"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4448",
                "cidade": "Bom Jesus"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4449",
                "cidade": "Bom Jesus do Oeste"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4450",
                "cidade": "Bom Retiro"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4451",
                "cidade": "Bombinhas"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4452",
                "cidade": "Botuverá"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4453",
                "cidade": "Braço do Norte"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4454",
                "cidade": "Braço do Trombudo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4455",
                "cidade": "Brunópolis"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4456",
                "cidade": "Brusque"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4457",
                "cidade": "Caçador"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4458",
                "cidade": "Caibi"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4459",
                "cidade": "Calmon"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4460",
                "cidade": "Camboriú"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4461",
                "cidade": "Campo Alegre"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4462",
                "cidade": "Campo Belo do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4463",
                "cidade": "Campo Erê"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4464",
                "cidade": "Campos Novos"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4465",
                "cidade": "Canelinha"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4466",
                "cidade": "Canoinhas"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4467",
                "cidade": "Capão Alto"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4468",
                "cidade": "Capinzal"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4469",
                "cidade": "Capivari de Baixo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4470",
                "cidade": "Catanduvas"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4471",
                "cidade": "Caxambu do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4472",
                "cidade": "Celso Ramos"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4473",
                "cidade": "Cerro Negro"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4474",
                "cidade": "Chapadão do Lageado"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4475",
                "cidade": "Chapecó"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4476",
                "cidade": "Cocal do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4477",
                "cidade": "Concórdia"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4478",
                "cidade": "Cordilheira Alta"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4479",
                "cidade": "Coronel Freitas"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4480",
                "cidade": "Coronel Martins"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4481",
                "cidade": "Correia Pinto"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4482",
                "cidade": "Corupá"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4483",
                "cidade": "Criciúma"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4484",
                "cidade": "Cunha Porã"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4485",
                "cidade": "Cunhataí"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4486",
                "cidade": "Curitibanos"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4487",
                "cidade": "Descanso"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4488",
                "cidade": "Dionísio Cerqueira"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4489",
                "cidade": "Dona Emma"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4490",
                "cidade": "Doutor Pedrinho"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4491",
                "cidade": "Entre Rios"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4492",
                "cidade": "Ermo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4493",
                "cidade": "Erval Velho"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4494",
                "cidade": "Faxinal dos Guedes"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4495",
                "cidade": "Flor do Sertão"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4496",
                "cidade": "Florianópolis"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4497",
                "cidade": "Formosa do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4498",
                "cidade": "Forquilhinha"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4499",
                "cidade": "Fraiburgo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4500",
                "cidade": "Frei Rogério"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4501",
                "cidade": "Galvão"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4502",
                "cidade": "Garopaba"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4503",
                "cidade": "Garuva"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4504",
                "cidade": "Gaspar"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4505",
                "cidade": "Governador Celso Ramos"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4506",
                "cidade": "Grão Pará"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4507",
                "cidade": "Gravatal"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4508",
                "cidade": "Guabiruba"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4509",
                "cidade": "Guaraciaba"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4510",
                "cidade": "Guaramirim"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4511",
                "cidade": "Guarujá do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4512",
                "cidade": "Guatambú"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4513",
                "cidade": "Herval d'Oeste"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4514",
                "cidade": "Ibiam"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4515",
                "cidade": "Ibicaré"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4516",
                "cidade": "Ibirama"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4517",
                "cidade": "Içara"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4518",
                "cidade": "Ilhota"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4519",
                "cidade": "Imaruí"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4520",
                "cidade": "Imbituba"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4521",
                "cidade": "Imbuia"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4522",
                "cidade": "Indaial"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4523",
                "cidade": "Iomerê"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4524",
                "cidade": "Ipira"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4525",
                "cidade": "Iporã do Oeste"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4526",
                "cidade": "Ipuaçu"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4527",
                "cidade": "Ipumirim"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4528",
                "cidade": "Iraceminha"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4529",
                "cidade": "Irani"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4530",
                "cidade": "Irati"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4531",
                "cidade": "Irineópolis"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4532",
                "cidade": "Itá"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4533",
                "cidade": "Itaiópolis"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4534",
                "cidade": "Itajaí"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4535",
                "cidade": "Itapema"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4536",
                "cidade": "Itapiranga"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4537",
                "cidade": "Itapoá"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4538",
                "cidade": "Ituporanga"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4539",
                "cidade": "Jaborá"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4540",
                "cidade": "Jacinto Machado"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4541",
                "cidade": "Jaguaruna"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4542",
                "cidade": "Jaraguá do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4543",
                "cidade": "Jardinópolis"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4544",
                "cidade": "Joaçaba"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4545",
                "cidade": "Joinville"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4546",
                "cidade": "José Boiteux"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4547",
                "cidade": "Jupiá"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4548",
                "cidade": "Lacerdópolis"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4549",
                "cidade": "Lages"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4550",
                "cidade": "Laguna"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4551",
                "cidade": "Lajeado Grande"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4552",
                "cidade": "Laurentino"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4553",
                "cidade": "Lauro Muller"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4554",
                "cidade": "Lebon Régis"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4555",
                "cidade": "Leoberto Leal"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4556",
                "cidade": "Lindóia do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4557",
                "cidade": "Lontras"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4558",
                "cidade": "Luiz Alves"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4559",
                "cidade": "Luzerna"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4560",
                "cidade": "Macieira"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4561",
                "cidade": "Mafra"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4562",
                "cidade": "Major Gercino"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4563",
                "cidade": "Major Vieira"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4564",
                "cidade": "Maracajá"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4565",
                "cidade": "Maravilha"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4566",
                "cidade": "Marema"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4567",
                "cidade": "Massaranduba"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4568",
                "cidade": "Matos Costa"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4569",
                "cidade": "Meleiro"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4570",
                "cidade": "Mirim Doce"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4571",
                "cidade": "Modelo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4572",
                "cidade": "Mondaí"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4573",
                "cidade": "Monte Carlo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4574",
                "cidade": "Monte Castelo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4575",
                "cidade": "Morro da Fumaça"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4576",
                "cidade": "Morro Grande"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4577",
                "cidade": "Navegantes"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4578",
                "cidade": "Nova Erechim"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4579",
                "cidade": "Nova Itaberaba"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4580",
                "cidade": "Nova Trento"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4581",
                "cidade": "Nova Veneza"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4582",
                "cidade": "Novo Horizonte"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4583",
                "cidade": "Orleans"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4584",
                "cidade": "Otacílio Costa"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4585",
                "cidade": "Ouro"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4586",
                "cidade": "Ouro Verde"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4587",
                "cidade": "Paial"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4588",
                "cidade": "Painel"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4589",
                "cidade": "Palhoça"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4590",
                "cidade": "Palma Sola"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4591",
                "cidade": "Palmeira"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4592",
                "cidade": "Palmitos"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4593",
                "cidade": "Papanduva"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4594",
                "cidade": "Paraíso"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4595",
                "cidade": "Passo de Torres"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4596",
                "cidade": "Passos Maia"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4597",
                "cidade": "Paulo Lopes"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4598",
                "cidade": "Pedras Grandes"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4599",
                "cidade": "Penha"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4600",
                "cidade": "Peritiba"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4601",
                "cidade": "Petrolândia"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4602",
                "cidade": "Piçarras"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4603",
                "cidade": "Pinhalzinho"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4604",
                "cidade": "Pinheiro Preto"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4605",
                "cidade": "Piratuba"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4606",
                "cidade": "Planalto Alegre"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4607",
                "cidade": "Pomerode"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4608",
                "cidade": "Ponte Alta"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4609",
                "cidade": "Ponte Alta do Norte"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4610",
                "cidade": "Ponte Serrada"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4611",
                "cidade": "Porto Belo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4612",
                "cidade": "Porto União"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4613",
                "cidade": "Pouso Redondo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4614",
                "cidade": "Praia Grande"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4615",
                "cidade": "Presidente Castelo Branco"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4616",
                "cidade": "Presidente Getúlio"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4617",
                "cidade": "Presidente Nereu"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4618",
                "cidade": "Princesa"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4619",
                "cidade": "Quilombo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4620",
                "cidade": "Rancho Queimado"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4621",
                "cidade": "Rio das Antas"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4622",
                "cidade": "Rio do Campo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4623",
                "cidade": "Rio do Oeste"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4624",
                "cidade": "Rio do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4625",
                "cidade": "Rio dos Cedros"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4626",
                "cidade": "Rio Fortuna"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4627",
                "cidade": "Rio Negrinho"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4628",
                "cidade": "Rio Rufino"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4629",
                "cidade": "Riqueza"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4630",
                "cidade": "Rodeio"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4631",
                "cidade": "Romelândia"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4632",
                "cidade": "Salete"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4633",
                "cidade": "Saltinho"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4634",
                "cidade": "Salto Veloso"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4635",
                "cidade": "Sangão"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4636",
                "cidade": "Santa Cecília"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4637",
                "cidade": "Santa Helena"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4638",
                "cidade": "Santa Rosa de Lima"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4639",
                "cidade": "Santa Rosa do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4640",
                "cidade": "Santa Terezinha"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4641",
                "cidade": "Santa Terezinha do Progresso"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4642",
                "cidade": "Santiago do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4643",
                "cidade": "Santo Amaro da Imperatriz"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4644",
                "cidade": "São Bento do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4645",
                "cidade": "São Bernardino"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4646",
                "cidade": "São Bonifácio"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4647",
                "cidade": "São Carlos"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4648",
                "cidade": "São Cristovão do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4649",
                "cidade": "São Domingos"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4650",
                "cidade": "São Francisco do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4651",
                "cidade": "São João Batista"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4652",
                "cidade": "São João do Itaperiú"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4653",
                "cidade": "São João do Oeste"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4654",
                "cidade": "São João do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4655",
                "cidade": "São Joaquim"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4656",
                "cidade": "São José"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4657",
                "cidade": "São José do Cedro"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4658",
                "cidade": "São José do Cerrito"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4659",
                "cidade": "São Lourenço do Oeste"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4660",
                "cidade": "São Ludgero"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4661",
                "cidade": "São Martinho"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4662",
                "cidade": "São Miguel da Boa Vista"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4663",
                "cidade": "São Miguel do Oeste"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4664",
                "cidade": "São Pedro de Alcântara"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4665",
                "cidade": "Saudades"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4666",
                "cidade": "Schroeder"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4667",
                "cidade": "Seara"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4668",
                "cidade": "Serra Alta"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4669",
                "cidade": "Siderópolis"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4670",
                "cidade": "Sombrio"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4671",
                "cidade": "Sul Brasil"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4672",
                "cidade": "Taió"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4673",
                "cidade": "Tangará"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4674",
                "cidade": "Tigrinhos"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4675",
                "cidade": "Tijucas"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4676",
                "cidade": "Timbé do Sul"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4677",
                "cidade": "Timbó"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4678",
                "cidade": "Timbó Grande"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4679",
                "cidade": "Três Barras"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4680",
                "cidade": "Treviso"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4681",
                "cidade": "Treze de Maio"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4682",
                "cidade": "Treze Tílias"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4683",
                "cidade": "Trombudo Central"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4684",
                "cidade": "Tubarão"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4685",
                "cidade": "Tunápolis"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4686",
                "cidade": "Turvo"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4687",
                "cidade": "União do Oeste"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4688",
                "cidade": "Urubici"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4689",
                "cidade": "Urupema"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4690",
                "cidade": "Urussanga"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4691",
                "cidade": "Vargeão"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4692",
                "cidade": "Vargem"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4693",
                "cidade": "Vargem Bonita"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4694",
                "cidade": "Vidal Ramos"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4695",
                "cidade": "Videira"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4696",
                "cidade": "Vitor Meireles"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4697",
                "cidade": "Witmarsum"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4698",
                "cidade": "Xanxerê"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4699",
                "cidade": "Xavantina"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4700",
                "cidade": "Xaxim"
            },
            {
                "uf": "SC",
                "estado": "Santa Catarina",
                "id": "4701",
                "cidade": "Zortéa"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4702",
                "cidade": "Amparo de São Francisco"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4703",
                "cidade": "Aquidabã"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4704",
                "cidade": "Aracaju"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4705",
                "cidade": "Arauá"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4706",
                "cidade": "Areia Branca"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4707",
                "cidade": "Barra dos Coqueiros"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4708",
                "cidade": "Boquim"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4709",
                "cidade": "Brejo Grande"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4710",
                "cidade": "Campo do Brito"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4711",
                "cidade": "Canhoba"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4712",
                "cidade": "Canindé de São Francisco"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4713",
                "cidade": "Capela"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4714",
                "cidade": "Carira"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4715",
                "cidade": "Carmópolis"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4716",
                "cidade": "Cedro de São João"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4717",
                "cidade": "Cristinápolis"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4718",
                "cidade": "Cumbe"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4719",
                "cidade": "Divina Pastora"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4720",
                "cidade": "Estância"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4721",
                "cidade": "Feira Nova"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4722",
                "cidade": "Frei Paulo"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4723",
                "cidade": "Gararu"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4724",
                "cidade": "General Maynard"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4725",
                "cidade": "Gracho Cardoso"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4726",
                "cidade": "Ilha das Flores"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4727",
                "cidade": "Indiaroba"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4728",
                "cidade": "Itabaiana"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4729",
                "cidade": "Itabaianinha"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4730",
                "cidade": "Itabi"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4731",
                "cidade": "Itaporanga d'Ajuda"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4732",
                "cidade": "Japaratuba"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4733",
                "cidade": "Japoatã"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4734",
                "cidade": "Lagarto"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4735",
                "cidade": "Laranjeiras"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4736",
                "cidade": "Macambira"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4737",
                "cidade": "Malhada dos Bois"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4738",
                "cidade": "Malhador"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4739",
                "cidade": "Maruim"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4740",
                "cidade": "Moita Bonita"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4741",
                "cidade": "Monte Alegre de Sergipe"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4742",
                "cidade": "Muribeca"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4743",
                "cidade": "Neópolis"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4744",
                "cidade": "Nossa Senhora Aparecida"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4745",
                "cidade": "Nossa Senhora da Glória"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4746",
                "cidade": "Nossa Senhora das Dores"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4747",
                "cidade": "Nossa Senhora de Lourdes"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4748",
                "cidade": "Nossa Senhora do Socorro"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4749",
                "cidade": "Pacatuba"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4750",
                "cidade": "Pedra Mole"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4751",
                "cidade": "Pedrinhas"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4752",
                "cidade": "Pinhão"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4753",
                "cidade": "Pirambu"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4754",
                "cidade": "Poço Redondo"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4755",
                "cidade": "Poço Verde"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4756",
                "cidade": "Porto da Folha"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4757",
                "cidade": "Propriá"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4758",
                "cidade": "Riachão do Dantas"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4759",
                "cidade": "Riachuelo"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4760",
                "cidade": "Ribeirópolis"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4761",
                "cidade": "Rosário do Catete"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4762",
                "cidade": "Salgado"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4763",
                "cidade": "Santa Luzia do Itanhy"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4764",
                "cidade": "Santa Rosa de Lima"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4765",
                "cidade": "Santana do São Francisco"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4766",
                "cidade": "Santo Amaro das Brotas"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4767",
                "cidade": "São Cristóvão"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4768",
                "cidade": "São Domingos"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4769",
                "cidade": "São Francisco"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4770",
                "cidade": "São Miguel do Aleixo"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4771",
                "cidade": "Simão Dias"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4772",
                "cidade": "Siriri"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4773",
                "cidade": "Telha"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4774",
                "cidade": "Tobias Barreto"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4775",
                "cidade": "Tomar do Geru"
            },
            {
                "uf": "SE",
                "estado": "Sergipe",
                "id": "4776",
                "cidade": "Umbaúba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4777",
                "cidade": "Adamantina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4778",
                "cidade": "Adolfo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4779",
                "cidade": "Aguaí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4780",
                "cidade": "Águas da Prata"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4781",
                "cidade": "Águas de Lindóia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4782",
                "cidade": "Águas de Santa Bárbara"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4783",
                "cidade": "Águas de São Pedro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4784",
                "cidade": "Agudos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4785",
                "cidade": "Alambari"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4786",
                "cidade": "Alfredo Marcondes"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4787",
                "cidade": "Altair"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4788",
                "cidade": "Altinópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4789",
                "cidade": "Alto Alegre"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4790",
                "cidade": "Alumínio"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4791",
                "cidade": "Álvares Florence"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4792",
                "cidade": "Álvares Machado"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4793",
                "cidade": "Álvaro de Carvalho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4794",
                "cidade": "Alvinlândia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4795",
                "cidade": "Americana"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4796",
                "cidade": "Américo Brasiliense"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4797",
                "cidade": "Américo de Campos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4798",
                "cidade": "Amparo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4799",
                "cidade": "Analândia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4800",
                "cidade": "Andradina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4801",
                "cidade": "Angatuba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4802",
                "cidade": "Anhembi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4803",
                "cidade": "Anhumas"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4804",
                "cidade": "Aparecida"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4805",
                "cidade": "Aparecida d'Oeste"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4806",
                "cidade": "Apiaí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4807",
                "cidade": "Araçariguama"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4808",
                "cidade": "Araçatuba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4809",
                "cidade": "Araçoiaba da Serra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4810",
                "cidade": "Aramina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4811",
                "cidade": "Arandu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4812",
                "cidade": "Arapeí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4813",
                "cidade": "Araraquara"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4814",
                "cidade": "Araras"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4815",
                "cidade": "Arco-Íris"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4816",
                "cidade": "Arealva"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4817",
                "cidade": "Areias"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4818",
                "cidade": "Areiópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4819",
                "cidade": "Ariranha"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4820",
                "cidade": "Artur Nogueira"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4821",
                "cidade": "Arujá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4822",
                "cidade": "Aspásia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4823",
                "cidade": "Assis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4824",
                "cidade": "Atibaia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4825",
                "cidade": "Auriflama"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4826",
                "cidade": "Avaí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4827",
                "cidade": "Avanhandava"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4828",
                "cidade": "Avaré"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4829",
                "cidade": "Bady Bassitt"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4830",
                "cidade": "Balbinos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4831",
                "cidade": "Bálsamo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4832",
                "cidade": "Bananal"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4833",
                "cidade": "Barão de Antonina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4834",
                "cidade": "Barbosa"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4835",
                "cidade": "Bariri"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4836",
                "cidade": "Barra Bonita"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4837",
                "cidade": "Barra do Chapéu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4838",
                "cidade": "Barra do Turvo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4839",
                "cidade": "Barretos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4840",
                "cidade": "Barrinha"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4841",
                "cidade": "Barueri"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4842",
                "cidade": "Bastos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4843",
                "cidade": "Batatais"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4844",
                "cidade": "Bauru"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4845",
                "cidade": "Bebedouro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4846",
                "cidade": "Bento de Abreu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4847",
                "cidade": "Bernardino de Campos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4848",
                "cidade": "Bertioga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4849",
                "cidade": "Bilac"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4850",
                "cidade": "Birigui"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4851",
                "cidade": "Biritiba-Mirim"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4852",
                "cidade": "Boa Esperança do Sul"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4853",
                "cidade": "Bocaina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4854",
                "cidade": "Bofete"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4855",
                "cidade": "Boituva"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4856",
                "cidade": "Bom Jesus dos Perdões"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4857",
                "cidade": "Bom Sucesso de Itararé"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4858",
                "cidade": "Borá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4859",
                "cidade": "Boracéia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4860",
                "cidade": "Borborema"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4861",
                "cidade": "Borebi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4862",
                "cidade": "Botucatu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4863",
                "cidade": "Bragança Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4864",
                "cidade": "Braúna"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4865",
                "cidade": "Brejo Alegre"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4866",
                "cidade": "Brodowski"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4867",
                "cidade": "Brotas"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4868",
                "cidade": "Buri"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4869",
                "cidade": "Buritama"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4870",
                "cidade": "Buritizal"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4871",
                "cidade": "Cabrália Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4872",
                "cidade": "Cabreúva"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4873",
                "cidade": "Caçapava"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4874",
                "cidade": "Cachoeira Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4875",
                "cidade": "Caconde"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4876",
                "cidade": "Cafelândia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4877",
                "cidade": "Caiabu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4878",
                "cidade": "Caieiras"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4879",
                "cidade": "Caiuá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4880",
                "cidade": "Cajamar"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4881",
                "cidade": "Cajati"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4882",
                "cidade": "Cajobi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4883",
                "cidade": "Cajuru"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4884",
                "cidade": "Campina do Monte Alegre"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4885",
                "cidade": "Campinas"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4886",
                "cidade": "Campo Limpo Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4887",
                "cidade": "Campos do Jordão"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4888",
                "cidade": "Campos Novos Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4889",
                "cidade": "Cananéia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4890",
                "cidade": "Canas"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4891",
                "cidade": "Cândido Mota"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4892",
                "cidade": "Cândido Rodrigues"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4893",
                "cidade": "Canitar"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4894",
                "cidade": "Capão Bonito"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4895",
                "cidade": "Capela do Alto"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4896",
                "cidade": "Capivari"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4897",
                "cidade": "Caraguatatuba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4898",
                "cidade": "Carapicuíba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4899",
                "cidade": "Cardoso"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4900",
                "cidade": "Casa Branca"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4901",
                "cidade": "Cássia dos Coqueiros"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4902",
                "cidade": "Castilho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4903",
                "cidade": "Catanduva"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4904",
                "cidade": "Catiguá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4905",
                "cidade": "Cedral"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4906",
                "cidade": "Cerqueira César"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4907",
                "cidade": "Cerquilho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4908",
                "cidade": "Cesário Lange"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4909",
                "cidade": "Charqueada"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4910",
                "cidade": "Chavantes"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4911",
                "cidade": "Clementina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4912",
                "cidade": "Colina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4913",
                "cidade": "Colômbia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4914",
                "cidade": "Conchal"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4915",
                "cidade": "Conchas"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4916",
                "cidade": "Cordeirópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4917",
                "cidade": "Coroados"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4918",
                "cidade": "Coronel Macedo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4919",
                "cidade": "Corumbataí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4920",
                "cidade": "Cosmópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4921",
                "cidade": "Cosmorama"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4922",
                "cidade": "Cotia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4923",
                "cidade": "Cravinhos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4924",
                "cidade": "Cristais Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4925",
                "cidade": "Cruzália"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4926",
                "cidade": "Cruzeiro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4927",
                "cidade": "Cubatão"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4928",
                "cidade": "Cunha"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4929",
                "cidade": "Descalvado"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4930",
                "cidade": "Diadema"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4931",
                "cidade": "Dirce Reis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4932",
                "cidade": "Divinolândia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4933",
                "cidade": "Dobrada"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4934",
                "cidade": "Dois Córregos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4935",
                "cidade": "Dolcinópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4936",
                "cidade": "Dourado"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4937",
                "cidade": "Dracena"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4938",
                "cidade": "Duartina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4939",
                "cidade": "Dumont"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4940",
                "cidade": "Echaporã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4941",
                "cidade": "Eldorado"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4942",
                "cidade": "Elias Fausto"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4943",
                "cidade": "Elisiário"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4944",
                "cidade": "Embaúba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4945",
                "cidade": "Embu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4946",
                "cidade": "Embu-Guaçu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4947",
                "cidade": "Emilianópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4948",
                "cidade": "Engenheiro Coelho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4949",
                "cidade": "Espírito Santo do Pinhal"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4950",
                "cidade": "Espírito Santo do Turvo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4951",
                "cidade": "Estiva Gerbi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4952",
                "cidade": "Estrela d'Oeste"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4953",
                "cidade": "Estrela do Norte"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4954",
                "cidade": "Euclides da Cunha Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4955",
                "cidade": "Fartura"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4956",
                "cidade": "Fernando Prestes"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4957",
                "cidade": "Fernandópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4958",
                "cidade": "Fernão"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4959",
                "cidade": "Ferraz de Vasconcelos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4960",
                "cidade": "Flora Rica"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4961",
                "cidade": "Floreal"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4962",
                "cidade": "Florínia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4963",
                "cidade": "Flórida Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4964",
                "cidade": "Franca"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4965",
                "cidade": "Francisco Morato"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4966",
                "cidade": "Franco da Rocha"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4967",
                "cidade": "Gabriel Monteiro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4968",
                "cidade": "Gália"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4969",
                "cidade": "Garça"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4970",
                "cidade": "Gastão Vidigal"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4971",
                "cidade": "Gavião Peixoto"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4972",
                "cidade": "General Salgado"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4973",
                "cidade": "Getulina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4974",
                "cidade": "Glicério"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4975",
                "cidade": "Guaiçara"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4976",
                "cidade": "Guaimbê"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4977",
                "cidade": "Guaíra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4978",
                "cidade": "Guapiaçu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4979",
                "cidade": "Guapiara"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4980",
                "cidade": "Guará"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4981",
                "cidade": "Guaraçaí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4982",
                "cidade": "Guaraci"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4983",
                "cidade": "Guarani d'Oeste"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4984",
                "cidade": "Guarantã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4985",
                "cidade": "Guararapes"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4986",
                "cidade": "Guararema"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4987",
                "cidade": "Guaratinguetá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4988",
                "cidade": "Guareí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4989",
                "cidade": "Guariba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4990",
                "cidade": "Guarujá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4991",
                "cidade": "Guarulhos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4992",
                "cidade": "Guatapará"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4993",
                "cidade": "Guzolândia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4994",
                "cidade": "Herculândia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4995",
                "cidade": "Holambra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4996",
                "cidade": "Hortolândia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4997",
                "cidade": "Iacanga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4998",
                "cidade": "Iacri"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "4999",
                "cidade": "Iaras"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5000",
                "cidade": "Ibaté"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5001",
                "cidade": "Ibirá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5002",
                "cidade": "Ibirarema"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5003",
                "cidade": "Ibitinga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5004",
                "cidade": "Ibiúna"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5005",
                "cidade": "Icém"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5006",
                "cidade": "Iepê"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5007",
                "cidade": "Igaraçu do Tietê"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5008",
                "cidade": "Igarapava"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5009",
                "cidade": "Igaratá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5010",
                "cidade": "Iguape"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5011",
                "cidade": "Ilha Comprida"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5012",
                "cidade": "Ilha Solteira"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5013",
                "cidade": "Ilhabela"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5014",
                "cidade": "Indaiatuba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5015",
                "cidade": "Indiana"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5016",
                "cidade": "Indiaporã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5017",
                "cidade": "Inúbia Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5018",
                "cidade": "Ipauçu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5019",
                "cidade": "Iperó"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5020",
                "cidade": "Ipeúna"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5021",
                "cidade": "Ipiguá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5022",
                "cidade": "Iporanga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5023",
                "cidade": "Ipuã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5024",
                "cidade": "Iracemápolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5025",
                "cidade": "Irapuã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5026",
                "cidade": "Irapuru"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5027",
                "cidade": "Itaberá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5028",
                "cidade": "Itaí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5029",
                "cidade": "Itajobi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5030",
                "cidade": "Itaju"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5031",
                "cidade": "Itanhaém"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5032",
                "cidade": "Itaóca"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5033",
                "cidade": "Itapecerica da Serra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5034",
                "cidade": "Itapetininga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5035",
                "cidade": "Itapeva"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5036",
                "cidade": "Itapevi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5037",
                "cidade": "Itapira"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5038",
                "cidade": "Itapirapuã Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5039",
                "cidade": "Itápolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5040",
                "cidade": "Itaporanga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5041",
                "cidade": "Itapuí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5042",
                "cidade": "Itapura"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5043",
                "cidade": "Itaquaquecetuba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5044",
                "cidade": "Itararé"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5045",
                "cidade": "Itariri"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5046",
                "cidade": "Itatiba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5047",
                "cidade": "Itatinga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5048",
                "cidade": "Itirapina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5049",
                "cidade": "Itirapuã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5050",
                "cidade": "Itobi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5051",
                "cidade": "Itu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5052",
                "cidade": "Itupeva"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5053",
                "cidade": "Ituverava"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5054",
                "cidade": "Jaborandi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5055",
                "cidade": "Jaboticabal"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5056",
                "cidade": "Jacareí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5057",
                "cidade": "Jaci"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5058",
                "cidade": "Jacupiranga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5059",
                "cidade": "Jaguariúna"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5060",
                "cidade": "Jales"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5061",
                "cidade": "Jambeiro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5062",
                "cidade": "Jandira"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5063",
                "cidade": "Jardinópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5064",
                "cidade": "Jarinu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5065",
                "cidade": "Jaú"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5066",
                "cidade": "Jeriquara"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5067",
                "cidade": "Joanópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5068",
                "cidade": "João Ramalho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5069",
                "cidade": "José Bonifácio"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5070",
                "cidade": "Júlio Mesquita"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5071",
                "cidade": "Jumirim"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5072",
                "cidade": "Jundiaí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5073",
                "cidade": "Junqueirópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5074",
                "cidade": "Juquiá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5075",
                "cidade": "Juquitiba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5076",
                "cidade": "Lagoinha"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5077",
                "cidade": "Laranjal Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5078",
                "cidade": "Lavínia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5079",
                "cidade": "Lavrinhas"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5080",
                "cidade": "Leme"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5081",
                "cidade": "Lençóis Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5082",
                "cidade": "Limeira"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5083",
                "cidade": "Lindóia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5084",
                "cidade": "Lins"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5085",
                "cidade": "Lorena"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5086",
                "cidade": "Lourdes"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5087",
                "cidade": "Louveira"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5088",
                "cidade": "Lucélia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5089",
                "cidade": "Lucianópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5090",
                "cidade": "Luís Antônio"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5091",
                "cidade": "Luiziânia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5092",
                "cidade": "Lupércio"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5093",
                "cidade": "Lutécia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5094",
                "cidade": "Macatuba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5095",
                "cidade": "Macaubal"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5096",
                "cidade": "Macedônia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5097",
                "cidade": "Magda"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5098",
                "cidade": "Mairinque"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5099",
                "cidade": "Mairiporã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5100",
                "cidade": "Manduri"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5101",
                "cidade": "Marabá Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5102",
                "cidade": "Maracaí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5103",
                "cidade": "Marapoama"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5104",
                "cidade": "Mariápolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5105",
                "cidade": "Marília"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5106",
                "cidade": "Marinópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5107",
                "cidade": "Martinópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5108",
                "cidade": "Matão"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5109",
                "cidade": "Mauá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5110",
                "cidade": "Mendonça"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5111",
                "cidade": "Meridiano"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5112",
                "cidade": "Mesópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5113",
                "cidade": "Miguelópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5114",
                "cidade": "Mineiros do Tietê"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5115",
                "cidade": "Mira Estrela"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5116",
                "cidade": "Miracatu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5117",
                "cidade": "Mirandópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5118",
                "cidade": "Mirante do Paranapanema"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5119",
                "cidade": "Mirassol"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5120",
                "cidade": "Mirassolândia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5121",
                "cidade": "Mococa"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5122",
                "cidade": "Mogi das Cruzes"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5123",
                "cidade": "Mogi-Guaçu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5124",
                "cidade": "Mogi-Mirim"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5125",
                "cidade": "Mombuca"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5126",
                "cidade": "Monções"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5127",
                "cidade": "Mongaguá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5128",
                "cidade": "Monte Alegre do Sul"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5129",
                "cidade": "Monte Alto"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5130",
                "cidade": "Monte Aprazível"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5131",
                "cidade": "Monte Azul Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5132",
                "cidade": "Monte Castelo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5133",
                "cidade": "Monte Mor"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5134",
                "cidade": "Monteiro Lobato"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5135",
                "cidade": "Morro Agudo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5136",
                "cidade": "Morungaba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5137",
                "cidade": "Motuca"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5138",
                "cidade": "Murutinga do Sul"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5139",
                "cidade": "Nantes"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5140",
                "cidade": "Narandiba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5141",
                "cidade": "Natividade da Serra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5142",
                "cidade": "Nazaré Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5143",
                "cidade": "Neves Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5144",
                "cidade": "Nhandeara"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5145",
                "cidade": "Nipoã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5146",
                "cidade": "Nova Aliança"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5147",
                "cidade": "Nova Campina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5148",
                "cidade": "Nova Canaã Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5149",
                "cidade": "Nova Castilho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5150",
                "cidade": "Nova Europa"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5151",
                "cidade": "Nova Granada"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5152",
                "cidade": "Nova Guataporanga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5153",
                "cidade": "Nova Independência"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5154",
                "cidade": "Nova Luzitânia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5155",
                "cidade": "Nova Odessa"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5156",
                "cidade": "Novais"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5157",
                "cidade": "Novo Horizonte"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5158",
                "cidade": "Nuporanga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5159",
                "cidade": "Ocauçu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5160",
                "cidade": "Óleo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5161",
                "cidade": "Olímpia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5162",
                "cidade": "Onda Verde"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5163",
                "cidade": "Oriente"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5164",
                "cidade": "Orindiúva"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5165",
                "cidade": "Orlândia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5166",
                "cidade": "Osasco"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5167",
                "cidade": "Oscar Bressane"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5168",
                "cidade": "Osvaldo Cruz"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5169",
                "cidade": "Ourinhos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5170",
                "cidade": "Ouro Verde"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5171",
                "cidade": "Ouroeste"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5172",
                "cidade": "Pacaembu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5173",
                "cidade": "Palestina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5174",
                "cidade": "Palmares Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5175",
                "cidade": "Palmeira d'Oeste"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5176",
                "cidade": "Palmital"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5177",
                "cidade": "Panorama"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5178",
                "cidade": "Paraguaçu Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5179",
                "cidade": "Paraibuna"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5180",
                "cidade": "Paraíso"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5181",
                "cidade": "Paranapanema"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5182",
                "cidade": "Paranapuã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5183",
                "cidade": "Parapuã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5184",
                "cidade": "Pardinho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5185",
                "cidade": "Pariquera-Açu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5186",
                "cidade": "Parisi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5187",
                "cidade": "Patrocínio Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5188",
                "cidade": "Paulicéia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5189",
                "cidade": "Paulínia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5190",
                "cidade": "Paulistânia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5191",
                "cidade": "Paulo de Faria"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5192",
                "cidade": "Pederneiras"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5193",
                "cidade": "Pedra Bela"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5194",
                "cidade": "Pedranópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5195",
                "cidade": "Pedregulho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5196",
                "cidade": "Pedreira"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5197",
                "cidade": "Pedrinhas Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5198",
                "cidade": "Pedro de Toledo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5199",
                "cidade": "Penápolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5200",
                "cidade": "Pereira Barreto"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5201",
                "cidade": "Pereiras"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5202",
                "cidade": "Peruíbe"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5203",
                "cidade": "Piacatu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5204",
                "cidade": "Piedade"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5205",
                "cidade": "Pilar do Sul"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5206",
                "cidade": "Pindamonhangaba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5207",
                "cidade": "Pindorama"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5208",
                "cidade": "Pinhalzinho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5209",
                "cidade": "Piquerobi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5210",
                "cidade": "Piquete"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5211",
                "cidade": "Piracaia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5212",
                "cidade": "Piracicaba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5213",
                "cidade": "Piraju"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5214",
                "cidade": "Pirajuí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5215",
                "cidade": "Pirangi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5216",
                "cidade": "Pirapora do Bom Jesus"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5217",
                "cidade": "Pirapozinho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5218",
                "cidade": "Pirassununga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5219",
                "cidade": "Piratininga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5220",
                "cidade": "Pitangueiras"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5221",
                "cidade": "Planalto"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5222",
                "cidade": "Platina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5223",
                "cidade": "Poá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5224",
                "cidade": "Poloni"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5225",
                "cidade": "Pompéia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5226",
                "cidade": "Pongaí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5227",
                "cidade": "Pontal"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5228",
                "cidade": "Pontalinda"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5229",
                "cidade": "Pontes Gestal"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5230",
                "cidade": "Populina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5231",
                "cidade": "Porangaba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5232",
                "cidade": "Porto Feliz"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5233",
                "cidade": "Porto Ferreira"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5234",
                "cidade": "Potim"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5235",
                "cidade": "Potirendaba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5236",
                "cidade": "Pracinha"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5237",
                "cidade": "Pradópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5238",
                "cidade": "Praia Grande"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5239",
                "cidade": "Pratânia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5240",
                "cidade": "Presidente Alves"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5241",
                "cidade": "Presidente Bernardes"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5242",
                "cidade": "Presidente Epitácio"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5243",
                "cidade": "Presidente Prudente"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5244",
                "cidade": "Presidente Venceslau"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5245",
                "cidade": "Promissão"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5246",
                "cidade": "Quadra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5247",
                "cidade": "Quatá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5248",
                "cidade": "Queiroz"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5249",
                "cidade": "Queluz"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5250",
                "cidade": "Quintana"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5251",
                "cidade": "Rafard"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5252",
                "cidade": "Rancharia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5253",
                "cidade": "Redenção da Serra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5254",
                "cidade": "Regente Feijó"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5255",
                "cidade": "Reginópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5256",
                "cidade": "Registro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5257",
                "cidade": "Restinga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5258",
                "cidade": "Ribeira"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5259",
                "cidade": "Ribeirão Bonito"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5260",
                "cidade": "Ribeirão Branco"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5261",
                "cidade": "Ribeirão Corrente"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5262",
                "cidade": "Ribeirão do Sul"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5263",
                "cidade": "Ribeirão dos Índios"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5264",
                "cidade": "Ribeirão Grande"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5265",
                "cidade": "Ribeirão Pires"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5266",
                "cidade": "Ribeirão Preto"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5267",
                "cidade": "Rifaina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5268",
                "cidade": "Rincão"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5269",
                "cidade": "Rinópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5270",
                "cidade": "Rio Claro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5271",
                "cidade": "Rio das Pedras"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5272",
                "cidade": "Rio Grande da Serra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5273",
                "cidade": "Riolândia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5274",
                "cidade": "Riversul"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5275",
                "cidade": "Rosana"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5276",
                "cidade": "Roseira"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5277",
                "cidade": "Rubiácea"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5278",
                "cidade": "Rubinéia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5279",
                "cidade": "Sabino"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5280",
                "cidade": "Sagres"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5281",
                "cidade": "Sales"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5282",
                "cidade": "Sales Oliveira"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5283",
                "cidade": "Salesópolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5284",
                "cidade": "Salmourão"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5285",
                "cidade": "Saltinho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5286",
                "cidade": "Salto"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5287",
                "cidade": "Salto de Pirapora"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5288",
                "cidade": "Salto Grande"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5289",
                "cidade": "Sandovalina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5290",
                "cidade": "Santa Adélia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5291",
                "cidade": "Santa Albertina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5292",
                "cidade": "Santa Bárbara d'Oeste"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5293",
                "cidade": "Santa Branca"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5294",
                "cidade": "Santa Clara d'Oeste"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5295",
                "cidade": "Santa Cruz da Conceição"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5296",
                "cidade": "Santa Cruz da Esperança"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5297",
                "cidade": "Santa Cruz das Palmeiras"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5298",
                "cidade": "Santa Cruz do Rio Pardo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5299",
                "cidade": "Santa Ernestina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5300",
                "cidade": "Santa Fé do Sul"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5301",
                "cidade": "Santa Gertrudes"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5302",
                "cidade": "Santa Isabel"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5303",
                "cidade": "Santa Lúcia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5304",
                "cidade": "Santa Maria da Serra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5305",
                "cidade": "Santa Mercedes"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5306",
                "cidade": "Santa Rita d'Oeste"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5307",
                "cidade": "Santa Rita do Passa Quatro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5308",
                "cidade": "Santa Rosa de Viterbo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5309",
                "cidade": "Santa Salete"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5310",
                "cidade": "Santana da Ponte Pensa"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5311",
                "cidade": "Santana de Parnaíba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5312",
                "cidade": "Santo Anastácio"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5313",
                "cidade": "Santo André"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5314",
                "cidade": "Santo Antônio da Alegria"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5315",
                "cidade": "Santo Antônio de Posse"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5316",
                "cidade": "Santo Antônio do Aracanguá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5317",
                "cidade": "Santo Antônio do Jardim"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5318",
                "cidade": "Santo Antônio do Pinhal"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5319",
                "cidade": "Santo Expedito"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5320",
                "cidade": "Santópolis do Aguapeí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5321",
                "cidade": "Santos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5322",
                "cidade": "São Bento do Sapucaí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5323",
                "cidade": "São Bernardo do Campo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5324",
                "cidade": "São Caetano do Sul"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5325",
                "cidade": "São Carlos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5326",
                "cidade": "São Francisco"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5327",
                "cidade": "São João da Boa Vista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5328",
                "cidade": "São João das Duas Pontes"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5329",
                "cidade": "São João de Iracema"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5330",
                "cidade": "São João do Pau d'Alho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5331",
                "cidade": "São Joaquim da Barra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5332",
                "cidade": "São José da Bela Vista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5333",
                "cidade": "São José do Barreiro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5334",
                "cidade": "São José do Rio Pardo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5335",
                "cidade": "São José do Rio Preto"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5336",
                "cidade": "São José dos Campos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5337",
                "cidade": "São Lourenço da Serra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5338",
                "cidade": "São Luís do Paraitinga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5339",
                "cidade": "São Manuel"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5340",
                "cidade": "São Miguel Arcanjo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5341",
                "cidade": "São Paulo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5342",
                "cidade": "São Pedro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5343",
                "cidade": "São Pedro do Turvo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5344",
                "cidade": "São Roque"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5345",
                "cidade": "São Sebastião"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5346",
                "cidade": "São Sebastião da Grama"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5347",
                "cidade": "São Simão"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5348",
                "cidade": "São Vicente"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5349",
                "cidade": "Sarapuí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5350",
                "cidade": "Sarutaiá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5351",
                "cidade": "Sebastianópolis do Sul"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5352",
                "cidade": "Serra Azul"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5353",
                "cidade": "Serra Negra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5354",
                "cidade": "Serrana"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5355",
                "cidade": "Sertãozinho"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5356",
                "cidade": "Sete Barras"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5357",
                "cidade": "Severínia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5358",
                "cidade": "Silveiras"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5359",
                "cidade": "Socorro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5360",
                "cidade": "Sorocaba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5361",
                "cidade": "Sud Mennucci"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5362",
                "cidade": "Sumaré"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5363",
                "cidade": "Suzanápolis"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5364",
                "cidade": "Suzano"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5365",
                "cidade": "Tabapuã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5366",
                "cidade": "Tabatinga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5367",
                "cidade": "Taboão da Serra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5368",
                "cidade": "Taciba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5369",
                "cidade": "Taguaí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5370",
                "cidade": "Taiaçu"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5371",
                "cidade": "Taiúva"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5372",
                "cidade": "Tambaú"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5373",
                "cidade": "Tanabi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5374",
                "cidade": "Tapiraí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5375",
                "cidade": "Tapiratiba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5376",
                "cidade": "Taquaral"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5377",
                "cidade": "Taquaritinga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5378",
                "cidade": "Taquarituba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5379",
                "cidade": "Taquarivaí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5380",
                "cidade": "Tarabai"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5381",
                "cidade": "Tarumã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5382",
                "cidade": "Tatuí"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5383",
                "cidade": "Taubaté"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5384",
                "cidade": "Tejupá"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5385",
                "cidade": "Teodoro Sampaio"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5386",
                "cidade": "Terra Roxa"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5387",
                "cidade": "Tietê"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5388",
                "cidade": "Timburi"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5389",
                "cidade": "Torre de Pedra"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5390",
                "cidade": "Torrinha"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5391",
                "cidade": "Trabiju"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5392",
                "cidade": "Tremembé"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5393",
                "cidade": "Três Fronteiras"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5394",
                "cidade": "Tuiuti"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5395",
                "cidade": "Tupã"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5396",
                "cidade": "Tupi Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5397",
                "cidade": "Turiúba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5398",
                "cidade": "Turmalina"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5399",
                "cidade": "Ubarana"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5400",
                "cidade": "Ubatuba"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5401",
                "cidade": "Ubirajara"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5402",
                "cidade": "Uchoa"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5403",
                "cidade": "União Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5404",
                "cidade": "Urânia"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5405",
                "cidade": "Uru"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5406",
                "cidade": "Urupês"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5407",
                "cidade": "Valentim Gentil"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5408",
                "cidade": "Valinhos"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5409",
                "cidade": "Valparaíso"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5410",
                "cidade": "Vargem"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5411",
                "cidade": "Vargem Grande do Sul"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5412",
                "cidade": "Vargem Grande Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5413",
                "cidade": "Várzea Paulista"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5414",
                "cidade": "Vera Cruz"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5415",
                "cidade": "Vinhedo"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5416",
                "cidade": "Viradouro"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5417",
                "cidade": "Vista Alegre do Alto"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5418",
                "cidade": "Vitória Brasil"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5419",
                "cidade": "Votorantim"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5420",
                "cidade": "Votuporanga"
            },
            {
                "uf": "SP",
                "estado": "São Paulo",
                "id": "5421",
                "cidade": "Zacarias"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5422",
                "cidade": "Abreulândia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5423",
                "cidade": "Aguiarnópolis"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5424",
                "cidade": "Aliança do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5425",
                "cidade": "Almas"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5426",
                "cidade": "Alvorada"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5427",
                "cidade": "Ananás"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5428",
                "cidade": "Angico"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5429",
                "cidade": "Aparecida do Rio Negro"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5430",
                "cidade": "Aragominas"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5431",
                "cidade": "Araguacema"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5432",
                "cidade": "Araguaçu"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5433",
                "cidade": "Araguaína"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5434",
                "cidade": "Araguanã"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5435",
                "cidade": "Araguatins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5436",
                "cidade": "Arapoema"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5437",
                "cidade": "Arraias"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5438",
                "cidade": "Augustinópolis"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5439",
                "cidade": "Aurora do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5440",
                "cidade": "Axixá do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5441",
                "cidade": "Babaçulândia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5442",
                "cidade": "Bandeirantes do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5443",
                "cidade": "Barra do Ouro"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5444",
                "cidade": "Barrolândia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5445",
                "cidade": "Bernardo Sayão"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5446",
                "cidade": "Bom Jesus do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5447",
                "cidade": "Brasilândia do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5448",
                "cidade": "Brejinho de Nazaré"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5449",
                "cidade": "Buriti do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5450",
                "cidade": "Cachoeirinha"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5451",
                "cidade": "Campos Lindos"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5452",
                "cidade": "Cariri do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5453",
                "cidade": "Carmolândia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5454",
                "cidade": "Carrasco Bonito"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5455",
                "cidade": "Caseara"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5456",
                "cidade": "Centenário"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5457",
                "cidade": "Chapada da Natividade"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5458",
                "cidade": "Chapada de Areia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5459",
                "cidade": "Colinas do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5460",
                "cidade": "Colméia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5461",
                "cidade": "Combinado"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5462",
                "cidade": "Conceição do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5463",
                "cidade": "Couto de Magalhães"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5464",
                "cidade": "Cristalândia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5465",
                "cidade": "Crixás do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5466",
                "cidade": "Darcinópolis"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5467",
                "cidade": "Dianópolis"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5468",
                "cidade": "Divinópolis do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5469",
                "cidade": "Dois Irmãos do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5470",
                "cidade": "Dueré"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5471",
                "cidade": "Esperantina"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5472",
                "cidade": "Fátima"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5473",
                "cidade": "Figueirópolis"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5474",
                "cidade": "Filadélfia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5475",
                "cidade": "Formoso do Araguaia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5476",
                "cidade": "Fortaleza do Tabocão"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5477",
                "cidade": "Goianorte"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5478",
                "cidade": "Goiatins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5479",
                "cidade": "Guaraí"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5480",
                "cidade": "Gurupi"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5481",
                "cidade": "Ipueiras"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5482",
                "cidade": "Itacajá"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5483",
                "cidade": "Itaguatins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5484",
                "cidade": "Itapiratins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5485",
                "cidade": "Itaporã do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5486",
                "cidade": "Jaú do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5487",
                "cidade": "Juarina"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5488",
                "cidade": "Lagoa da Confusão"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5489",
                "cidade": "Lagoa do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5490",
                "cidade": "Lajeado"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5491",
                "cidade": "Lavandeira"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5492",
                "cidade": "Lizarda"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5493",
                "cidade": "Luzinópolis"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5494",
                "cidade": "Marianópolis do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5495",
                "cidade": "Mateiros"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5496",
                "cidade": "Maurilândia do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5497",
                "cidade": "Miracema do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5498",
                "cidade": "Miranorte"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5499",
                "cidade": "Monte do Carmo"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5500",
                "cidade": "Monte Santo do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5501",
                "cidade": "Muricilândia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5502",
                "cidade": "Natividade"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5503",
                "cidade": "Nazaré"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5504",
                "cidade": "Nova Olinda"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5505",
                "cidade": "Nova Rosalândia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5506",
                "cidade": "Novo Acordo"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5507",
                "cidade": "Novo Alegre"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5508",
                "cidade": "Novo Jardim"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5509",
                "cidade": "Oliveira de Fátima"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5510",
                "cidade": "Palmas"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5511",
                "cidade": "Palmeirante"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5512",
                "cidade": "Palmeiras do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5513",
                "cidade": "Palmeirópolis"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5514",
                "cidade": "Paraíso do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5515",
                "cidade": "Paranã"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5516",
                "cidade": "Pau d'Arco"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5517",
                "cidade": "Pedro Afonso"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5518",
                "cidade": "Peixe"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5519",
                "cidade": "Pequizeiro"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5520",
                "cidade": "Pindorama do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5521",
                "cidade": "Piraquê"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5522",
                "cidade": "Pium"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5523",
                "cidade": "Ponte Alta do Bom Jesus"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5524",
                "cidade": "Ponte Alta do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5525",
                "cidade": "Porto Alegre do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5526",
                "cidade": "Porto Nacional"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5527",
                "cidade": "Praia Norte"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5528",
                "cidade": "Presidente Kennedy"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5529",
                "cidade": "Pugmil"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5530",
                "cidade": "Recursolândia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5531",
                "cidade": "Riachinho"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5532",
                "cidade": "Rio da Conceição"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5533",
                "cidade": "Rio dos Bois"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5534",
                "cidade": "Rio Sono"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5535",
                "cidade": "Sampaio"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5536",
                "cidade": "Sandolândia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5537",
                "cidade": "Santa Fé do Araguaia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5538",
                "cidade": "Santa Maria do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5539",
                "cidade": "Santa Rita do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5540",
                "cidade": "Santa Rosa do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5541",
                "cidade": "Santa Tereza do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5542",
                "cidade": "Santa Terezinha Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5543",
                "cidade": "São Bento do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5544",
                "cidade": "São Félix do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5545",
                "cidade": "São Miguel do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5546",
                "cidade": "São Salvador do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5547",
                "cidade": "São Sebastião do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5548",
                "cidade": "São Valério da Natividade"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5549",
                "cidade": "Silvanópolis"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5550",
                "cidade": "Sítio Novo do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5551",
                "cidade": "Sucupira"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5552",
                "cidade": "Taguatinga"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5553",
                "cidade": "Taipas do Tocantins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5554",
                "cidade": "Talismã"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5555",
                "cidade": "Tocantínia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5556",
                "cidade": "Tocantinópolis"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5557",
                "cidade": "Tupirama"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5558",
                "cidade": "Tupiratins"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5559",
                "cidade": "Wanderlândia"
            },
            {
                "uf": "TO",
                "estado": "Tocantins",
                "id": "5560",
                "cidade": "Xambioá"
            }
        ];
    }
})();