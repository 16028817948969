(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('CancelaAgendamentoService', CancelaAgendamentoService);

    CancelaAgendamentoService.$inject = ['$resource', 'DateUtils'];

    function CancelaAgendamentoService($resource, DateUtils) {
        var resourceUrl = 'api/consulta-agendamento/cancelar-agendamento/:param';

        return $resource(resourceUrl, {}, {
            'delete': { method: 'GET', isArray: false }
        });
    }
})();