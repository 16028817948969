(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaItensPlanoService', BuscaItensPlanoService);

    BuscaItensPlanoService.$inject = ['$resource', 'DateUtils'];

    function BuscaItensPlanoService($resource, DateUtils) {
        var resourceUrl = '/api/busca-itens-plano/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();