(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('AcademicoDetailController', AcademicoDetailController);

    AcademicoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Academico'];

    function AcademicoDetailController($scope, $rootScope, $stateParams, previousState, entity, Academico) {
        var vm = this;

        vm.academico = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vestibularApp:academicoUpdate', function(event, result) {
            vm.academico = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
