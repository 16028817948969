(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .factory('BuscaPropostasPendentes', BuscaPropostasPendentes);

    BuscaPropostasPendentes.$inject = ['$resource', 'DateUtils'];

    function BuscaPropostasPendentes($resource, DateUtils) {
        var resourceUrl = 'api/consulta-proposta-matric-dp/:campo/:valor/:ano/:limite';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();