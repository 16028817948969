(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('InscricaoAgendadoDetailController', InscricaoAgendadoDetailController);

    InscricaoAgendadoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InscricaoAgendado', 'Curso'];

    function InscricaoAgendadoDetailController($scope, $rootScope, $stateParams, previousState, entity, InscricaoAgendado, Curso) {
        var vm = this;

        vm.inscricaoAgendado = entity;
        vm.previousState = previousState.name;


        vm.corrige = function() {
            if (vm.inscricaoAgendado.telefone.length == 10) {
                vm.inscricaoAgendado.telefone = format10(vm.inscricaoAgendado.telefone);
            } else {
                if (vm.inscricaoAgendado.telefone.length == 11) {
                    vm.inscricaoAgendado.telefone = format11(vm.inscricaoAgendado.telefone);
                }
            }
        }

        vm.corrige();

        function format10(telefone) {
            vm.thisTelephone = "(" + telefone.substring(0, 2) + ") ";
            vm.thisTelephone += telefone.substring(2, 6) + " - ";
            vm.thisTelephone += telefone.substring(6, 10);

            return vm.thisTelephone;
        }

        function format11(telefone) {
            vm.thisTelephone = "(" + telefone.substring(0, 2) + ") ";
            vm.thisTelephone += telefone.substring(2, 7) + " - ";
            vm.thisTelephone += telefone.substring(7, 11);

            return vm.thisTelephone;
        }


        var unsubscribe = $rootScope.$on('vestibularApp:inscricaoAgendadoUpdate', function(event, result) {
            vm.inscricaoAgendado = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();