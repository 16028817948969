(function() {
    'use strict';

    angular
        .module('vestibularApp')
        .controller('PainelMatriculaController', PainelMatriculaController);

    PainelMatriculaController.$inject = ['$http', '$scope', '$state', 'DateUtils', 'Principal', 'Curso', 'ConsultaMatriculaService', 'PreMatriculaDadosCadastraisServiceController', 'ConsultaFinanceiroAberto'];

    function PainelMatriculaController($http, $scope, $state, DateUtils, Principal, Curso, ConsultaMatriculaService, PreMatriculaDadosCadastraisServiceController, ConsultaFinanceiroAberto) {

        var vm = this;
        vm.consultar = consultar;
        vm.salvar = salvar;
        vm.verMatricula = verMatricula;
        vm.gerarContrato = gerarContrato;
        vm.cursos = Curso.query();
        vm.pgtos = null;

        vm.tipo = 'RA';

        vm.blockCampos = true;

        Principal.identity().then(function(account) {
            vm.user = account.login;
            vm.isAuthenticated = Principal.isAuthenticated;
        });

        function consultar() {
            ConsultaMatriculaService.query({
                tipo: vm.consulta.tipo,
                valor: vm.consulta.valor,
                idPerlet: vm.consulta.idPerlet
            }, function(data) {
                vm.result = data;
                console.log(vm.result);

                ConsultaFinanceiroAberto.query(vm.result[0],
                    function(p) {
                        console.log(p);
                        vm.pgtos = p;
                    });
            }, function(result) {
                console.log(result);
            });
        }

        function salvar(matricula) {
            PreMatriculaDadosCadastraisServiceController.save(matricula,
                function(data) {
                    console.log(data);
                    vm.result = data;
                },
                function(status) {
                    console.log(status);
                })
        }

        function verMatricula(dados) {
            vm.matricula = dados;

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.setAttribute('data-target', '#modalMatricula');
            a.setAttribute('data-toggle', 'modal');
            a.click();

            vm.modal = true;
        }

        function gerarContrato(academico) {

            vm.load = true;

            $http.post('api/pre-matricula-getcontrato/', academico, { responseType: 'arraybuffer' })
                .success(function(data) {

                    var file = new Blob([(data)], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    a.href = fileURL;
                    a.download = academico.nome + " - Contrato.pdf";
                    a.click();

                    window.URL.revokeObjectURL(fileURL);

                    vm.load = false;


                }).error(function(status) {
                    console.log(status);
                });;

        }
    }
})();