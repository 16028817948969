(function() {
    'use strict';
    angular
        .module('vestibularApp')
        .factory('BuscaDiscCadastradas', BuscaDiscCadastradas);

    BuscaDiscCadastradas.$inject = ['$resource', 'DateUtils'];

    function BuscaDiscCadastradas($resource, DateUtils) {
        var resourceUrl = '/api/busca-cod-disc-cadastrados';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true }
        });
    }
})();